import { ReactNode } from "react";

import { useParams } from "react-router-dom";
import { BsMetaUtil, RoleView, useBsPrincipalTokens } from "@bridgesplit/abf-react";
import { useAppNavigate } from "@bridgesplit/react";

import { MissingDataPage } from "../wrappers";
import { MarketProvider, QuotesProvider, useMarketContext, useMarketViewController } from "./common";
import { BorrowCard, MarketBorrowProvider } from "./borrow";
import { LendCard, MarketLendProvider } from "./lend";
import { MarketCardWrapper } from "./market";
import { getMarketPath } from "../common";

export default function MarketDetail() {
    const { symbol } = useParams<"symbol">();

    const { tokens, isLoading } = useBsPrincipalTokens();
    const token = tokens?.find((m) => BsMetaUtil.getSymbol(m).toLowerCase() === symbol?.toLowerCase());
    const { view, setView } = useMarketViewController();
    const navigate = useAppNavigate();

    if (!isLoading && !token) {
        return <MissingDataPage />;
    }

    return (
        <MarketProvider
            marketProps={{
                isDialog: false,
                token,
                symbol: BsMetaUtil.getSymbol(token) || symbol || "",
                loading: !token,
                principalMint: token?.mint,
                view,
                setView,
                selectToken: (token) => navigate(getMarketPath({ view, token }))
            }}
        >
            <QuotesProvider>
                <Provider>
                    <MarketCardWrapper card={<Card />} />
                </Provider>
            </QuotesProvider>
        </MarketProvider>
    );
}

function Provider({ children }: { children: ReactNode }) {
    const { view } = useMarketContext();

    if (view === RoleView.Lend) return <MarketLendProvider>{children}</MarketLendProvider>;

    return <MarketBorrowProvider>{children}</MarketBorrowProvider>;
}

function Card() {
    const { view } = useMarketContext();
    if (view === RoleView.Lend) return <LendCard />;

    return <BorrowCard />;
}
