import { StatProps } from "@bridgesplit/ui";
import { formatPercent, formatUsd } from "@bridgesplit/utils";

import { usePortfolioStats } from "./util";
import { PortfolioTableStats } from "./common";

export default function BorrowLoanStats() {
    const borrowStats = usePortfolioStats()?.borrowStats;
    const stats: StatProps[] = [
        {
            value: formatUsd(borrowStats?.collateralUsd),
            caption: "Total collateral"
        },
        {
            value: formatUsd(borrowStats?.totalBorrowedUsd),
            caption: "Total borrowed"
        },
        {
            value: formatUsd(borrowStats?.netPositionUsd),
            caption: "Net value"
        },
        {
            value: formatPercent(borrowStats?.wAvgApy),
            caption: "Avg. APY",
            tooltip: "Weighted average APY of all active loans based on USD loan size"
        },
        {
            value: formatUsd(borrowStats?.interestAccruedUsd),
            caption: "Interest accrued"
        }
    ];

    return <PortfolioTableStats stats={stats} loading={!borrowStats} />;
}
