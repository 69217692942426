import { ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { STRATEGY_TEMPLATE_ALL_IDENTIFIER } from "@bridgesplit/abf-react";

export type MarketLendForm = {
    collateral: string[];
    amount?: number;
    yieldSource: ExternalYieldSource;
    templateId: string;
    templateApyInitializedForMint: string | null;
    collateralInitializedForMint: string | null;
    strategyDurationToApy: Map<string, number | undefined>; // serialized strategy duration => val
    originationFee: number | undefined;
    liquidityBuffer: number | undefined;
    interestFee: number | undefined;
    originationCap: number | undefined;
};

export const initialLendForm: MarketLendForm = {
    strategyDurationToApy: new Map(),
    collateral: [],
    templateApyInitializedForMint: null,
    collateralInitializedForMint: null,
    yieldSource: ExternalYieldSource.MarginFi,
    templateId: STRATEGY_TEMPLATE_ALL_IDENTIFIER,
    originationFee: undefined,
    liquidityBuffer: undefined,
    interestFee: undefined,
    originationCap: undefined
};
