import { useMemo } from "react";

import {
    LendStats,
    useLenderStrategies,
    StrategyExpanded,
    useLoopPositionStatsQuery,
    LoopPositionStats,
    calculateTotalInterestAccrued,
    BorrowStats,
    PortfolioStats,
    usePortfolioStatsQuery,
    useLendVaultPositionStatsQuery,
    LendVaultStatsResponse,
    useOraclePrices,
    useTokenListMetadataByMints,
    StrategyRewards,
    LedgerRewards,
    useHistoricalPortfolioRewards,
    useActivePortfolioRewards,
    ParsedLedgerReward
} from "@bridgesplit/abf-react";
import { bpsToUiDecimals, getUnixTs, lamportsToUiAmount, percentUiToDecimals, TIME } from "@bridgesplit/utils";

import {
    BorrowStatsExpanded,
    LendingVaultStats,
    LendStatsExpanded,
    OverviewStats,
    PointSummary,
    SummarizedActivePortfolioRewards,
    SummarizedHistoricalPortfolioRewards,
    SummarizedStats
} from "./type";

export function usePortfolioStats() {
    const { data: portfolioStats } = usePortfolioStatsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const { data: loopPositionStats } = useLoopPositionStatsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });
    const { data: strategies } = useLenderStrategies({});

    const { data: lendVaultsPositionStats } = useLendVaultPositionStatsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });
    const lendVaultStats = useCalculateLendVaultStats(lendVaultsPositionStats);

    const activePortfolioRewardsSummary = useActivePortfolioRewardsSummary();

    const activePortfolioRewards = activePortfolioRewardsSummary?.data;

    const stats = useMemo((): SummarizedStats | undefined => {
        if (!portfolioStats || !strategies || !loopPositionStats || !lendVaultsPositionStats || !activePortfolioRewards)
            return undefined;

        const lendStats = calculateLendStats(strategies);
        const borrowStats = calculateBorrowStats(portfolioStats);
        const loopStats = calculateLoopsStats(loopPositionStats);
        const overviewStats = summarizeStats({
            lendStats,
            borrowStats,
            loopStats,
            lendVaultStats,
            activePortfolioRewards
        });
        return { lendStats, borrowStats, loopStats, lendVaultStats, overviewStats, activePortfolioRewards };
    }, [
        portfolioStats,
        strategies,
        loopPositionStats,
        lendVaultsPositionStats,
        lendVaultStats,
        activePortfolioRewards
    ]);

    return stats;
}
function calculateLendStats(strategies: StrategyExpanded[]): LendStatsExpanded {
    const strategyBalanceUsd = strategies.reduce(
        (acc, strategy) => acc + strategy.totalBalance * (strategy.principalUsdPrice ?? 0),
        0
    );
    let summary: LendStats = {
        principalDeployedUsd: 0,
        wAvgApy: 0,
        interestAccruedUsd: 0,
        interestEarnedAllTimeUsd: 0
    };
    let totalWeight = 0;
    for (const strategy of strategies) {
        const usdPrice = strategy.principalUsdPrice ?? 0;

        // wAvgApy needs to include external yield + deployed principal * deployed apy
        const deployedUsd = strategy.strategy.currentDeployedAmount * usdPrice;
        let wAvgApy = strategy.strategy.fixedApy * deployedUsd;
        totalWeight += deployedUsd;

        // add weighted external yield
        if (strategy.externalYieldInfo) {
            const yieldDepositsUsd = strategy.externalYieldInfo.balance * usdPrice;
            totalWeight += yieldDepositsUsd;
            wAvgApy += strategy.externalYieldInfo.apy * yieldDepositsUsd;
        } else {
            // if no external yield, add the total balance as idle
            totalWeight += strategy.totalBalance * usdPrice;
        }

        const interestAccrued = calculateTotalInterestAccrued(strategy, {
            includeStrategyInterest: true,
            includeExternalInterest: true,
            includeCumulativeInterest: true
        });

        summary = {
            principalDeployedUsd: summary.principalDeployedUsd + strategy.strategy.currentDeployedAmount * usdPrice,
            interestAccruedUsd: summary.interestAccruedUsd + interestAccrued * usdPrice,
            interestEarnedAllTimeUsd: summary.interestEarnedAllTimeUsd + interestAccrued * usdPrice,
            wAvgApy: summary.wAvgApy + wAvgApy
        };
    }

    summary.wAvgApy = summary.wAvgApy / (totalWeight || 1);

    const totalSupplyUsd = summary.principalDeployedUsd + strategyBalanceUsd;

    return { ...summary, strategyBalanceUsd, totalSupplyUsd };
}

function calculateBorrowStats(portfolioStats: PortfolioStats): BorrowStatsExpanded {
    let summary: BorrowStats = {
        principalBorrowedUsd: 0,
        interestAccruedUsd: 0,
        wAvgApy: 0,
        collateralUsd: 0
    };
    let totalWeight = 0;
    for (const stats of Object.values(portfolioStats.borrowStats)) {
        const weight = stats.principalBorrowedUsd;
        totalWeight += weight;

        summary = {
            principalBorrowedUsd: summary.principalBorrowedUsd + stats.principalBorrowedUsd,
            interestAccruedUsd: summary.interestAccruedUsd + stats.interestAccruedUsd,
            wAvgApy: summary.wAvgApy + bpsToUiDecimals(stats.wAvgApy) * weight,
            collateralUsd: summary.collateralUsd + stats.collateralUsd
        };
    }
    summary.wAvgApy = summary.wAvgApy / (totalWeight || 1);

    const totalBorrowedUsd = summary.principalBorrowedUsd + summary.interestAccruedUsd;
    const netPositionUsd = Math.max(summary.collateralUsd - totalBorrowedUsd, 0);

    return { ...summary, netPositionUsd, totalBorrowedUsd };
}

function summarizeStats({
    lendStats,
    borrowStats,
    loopStats,
    lendVaultStats
}: Omit<SummarizedStats, "overviewStats">): OverviewStats {
    const borrowNetValue =
        borrowStats.collateralUsd - borrowStats.principalBorrowedUsd - borrowStats.interestAccruedUsd;
    const lendNetValue = lendStats.strategyBalanceUsd + lendStats.principalDeployedUsd + lendStats.interestAccruedUsd;
    const loopNetValue = loopStats.netPositionValue;
    const lendVaultNetValue = lendVaultStats.totalValueUsd;

    const netValue = lendNetValue + borrowNetValue + loopNetValue + lendVaultNetValue;

    // Does not include interest earned from lend vaults since we do not show PnL
    const totalInterestEarned = lendStats.interestEarnedAllTimeUsd;
    return {
        netValue,
        totalInterestEarned
    };
}

function calculateLoopsStats(stats: LoopPositionStats): LoopPositionStats {
    return {
        ...stats,
        wAvgApy: percentUiToDecimals(stats.wAvgApy)
    };
}

// Takes in the response from the lend vaults stats route and returns the sumarized lending vault stat for all principals
function useCalculateLendVaultStats(stats: LendVaultStatsResponse | undefined): LendingVaultStats {
    const mints = stats ? Object.keys(stats) : [];
    const { getUsdPrice } = useOraclePrices(mints);
    const { getMetadata } = useTokenListMetadataByMints(mints);

    if (!stats) {
        return {
            wAvgApy: 0,
            totalValueUsd: 0
        };
    }

    let totalApy = 0;
    let totalValueUsd = 0;

    const elements = Object.entries(stats);
    const length = elements.length;
    for (const [mint, rawStat] of elements) {
        const convertedStat = {
            ...rawStat,
            totalValue: lamportsToUiAmount(rawStat.totalValue, getMetadata(mint)?.decimals)
        };
        totalApy += convertedStat.wAvgApy;
        const usdPrice = getUsdPrice(mint);

        totalValueUsd += convertedStat.totalValue * (usdPrice ?? 0);
    }

    const wAvgApy = totalApy / (length || 1);

    return {
        wAvgApy: percentUiToDecimals(wAvgApy),
        totalValueUsd
    };
}

interface HistoricalPortfolioRewardsSummary {
    data: SummarizedHistoricalPortfolioRewards | undefined;
    isLoading: boolean;
}

export function useHistoricalPortfolioRewardsSummary(): HistoricalPortfolioRewardsSummary | undefined {
    const { data: portfolioRewards, isLoading } = useHistoricalPortfolioRewards();

    const lendingPoints = summarizeLendingPoints(portfolioRewards?.strategies ?? {});
    const borrowPoints = summarizeBorrowPoints(portfolioRewards?.loans ?? {});
    const lendVaultPoints = summarizeLendVaultPoints(portfolioRewards?.vaultStakes ?? {});

    return {
        data: {
            lendingPoints,
            borrowPoints,
            lendVaultPoints
        },
        isLoading
    };
}

interface ActivePortfolioRewardsResponse {
    data: SummarizedActivePortfolioRewards | undefined;
    isLoading: boolean;
}
export function useActivePortfolioRewardsSummary(): ActivePortfolioRewardsResponse | undefined {
    const { data: portfolioRewards, isLoading } = useActivePortfolioRewards();

    const activeStrategyDailyLendPoints = summarizeDailyActiveLendPoints(portfolioRewards?.strategies ?? {});
    const activeBorrowDailyPoints = summarizeDailyActiveBorrowPoints(portfolioRewards?.loans ?? {});
    const activeLendVaultDailyPoints = summarizeDailyActiveLendVaultPoints(portfolioRewards?.vaultStakes ?? {});

    return {
        data: {
            activeStrategyDailyLendPoints,
            activeBorrowDailyPoints,
            activeLendVaultDailyPoints
        },
        isLoading
    };
}

function summarizeDailyActiveLendPoints(strategies: Record<string, StrategyRewards>): number | undefined {
    let totalPoints = 0;
    let hasActiveStrategy = false;

    for (const strategy of Object.values(strategies)) {
        const points = calculateActiveStrategyDailyPoints(strategy);
        if (points !== undefined) {
            totalPoints += points;
            hasActiveStrategy = true;
        }
    }

    return hasActiveStrategy ? totalPoints : undefined;
}

function summarizeDailyActiveBorrowPoints(loans: Record<string, LedgerRewards>): number | undefined {
    let totalPoints = 0;
    let hasActiveLoan = false;

    for (const loan of Object.values(loans)) {
        const points = calculateActiveLoanDailyPoints(loan, true);
        if (points !== undefined) {
            totalPoints += points;
            hasActiveLoan = true;
        }
    }

    return hasActiveLoan ? totalPoints : undefined;
}

function summarizeDailyActiveLendVaultPoints(vaultStakes: Record<string, LedgerRewards>): number | undefined {
    let totalPoints = 0;
    let hasActiveStake = false;

    for (const stake of Object.values(vaultStakes)) {
        const points = calculateLendVaultDailyPoints(stake);
        if (points !== undefined) {
            totalPoints += points;
            hasActiveStake = true;
        }
    }

    return hasActiveStake ? totalPoints : undefined;
}

function calculatePointsForLedgerReward(
    ledgerReward: ParsedLedgerReward,
    now: number
): { points: number; lastAccrued: number } {
    let points = 0;
    if (ledgerReward.isActive) {
        const pointsNotReflected = ledgerReward.pointsPerSecond * (now - ledgerReward.accruedTimeStamp);
        points = pointsNotReflected + ledgerReward.lastUpdateValue;
    } else {
        points = ledgerReward.lastUpdateValue;
    }
    return {
        points,
        lastAccrued: ledgerReward.accruedTimeStamp
    };
}

function calculateStrategyPoints(strategyReward: StrategyRewards | undefined): PointSummary {
    if (!strategyReward) return { totalPoints: 0, lastAccruedTimestamp: 0 };

    let strategyPoints = 0;
    let lastAccrued = 0;
    const now = getUnixTs();

    // Process each ledger reward
    for (const ledgerReward of strategyReward.ledgerRewards) {
        // Check if it's a lender reward
        if (ledgerReward.isLenderReward) {
            const { points, lastAccrued: rewardLastAccrued } = calculatePointsForLedgerReward(ledgerReward, now);
            strategyPoints += points;
            lastAccrued = Math.max(lastAccrued, rewardLastAccrued);
        }
    }

    // Add external yield rewards if they exist
    if (strategyReward.externalYieldRewards) {
        const externalReward = strategyReward.externalYieldRewards;
        const pointsNotReflected = externalReward.pointsPerSecond * (now - externalReward.accruedTimeStamp);
        const points = pointsNotReflected + externalReward.lastUpdateValue;

        strategyPoints += points;
        lastAccrued = Math.max(lastAccrued, externalReward.accruedTimeStamp);
    }

    return {
        totalPoints: strategyPoints,
        lastAccruedTimestamp: lastAccrued
    };
}

function summarizeLendingPoints(strategies: Record<string, StrategyRewards>): PointSummary {
    let totalLendingPoints = 0;
    let lastAccrued = 0;

    // Process each strategy
    for (const [, strategyReward] of Object.entries(strategies)) {
        const strategyPointSummary = calculateStrategyPoints(strategyReward);
        totalLendingPoints += strategyPointSummary.totalPoints;
        lastAccrued = Math.max(lastAccrued, strategyPointSummary.lastAccruedTimestamp);
    }

    return {
        totalPoints: totalLendingPoints,
        lastAccruedTimestamp: lastAccrued
    };
}

function summarizeBorrowPoints(loans: Record<string, LedgerRewards>): PointSummary {
    let totalBorrowPoints = 0;
    let lastAccrued = 0;
    const now = getUnixTs();

    // For each loan (key)
    for (const [, loanRewards] of Object.entries(loans)) {
        let loanPoints = 0;

        // For each ledger reward (value)
        for (const ledgerReward of loanRewards.ledgerRewards) {
            // If it has a loan address, it's a borrower reward
            if (!ledgerReward.isLenderReward) {
                const { points, lastAccrued: rewardLastAccrued } = calculatePointsForLedgerReward(ledgerReward, now);
                loanPoints += points;
                lastAccrued = Math.max(lastAccrued, rewardLastAccrued);
            }
            // Skip lender rewards
        }

        totalBorrowPoints += loanPoints;
    }

    return {
        totalPoints: totalBorrowPoints,
        lastAccruedTimestamp: lastAccrued
    };
}

function summarizeLendVaultPoints(vaultStakes: Record<string, LedgerRewards>): PointSummary {
    let totalLendVaultPoints = 0;
    let lastAccrued = 0;
    const now = getUnixTs();

    // For each stake (key)
    for (const [, stakeRewards] of Object.entries(vaultStakes)) {
        let stakePoints = 0;

        // For each ledger reward (value)
        for (const ledgerReward of stakeRewards.ledgerRewards) {
            // If isLenderReward true
            if (ledgerReward.isLenderReward) {
                const { points } = calculatePointsForLedgerReward(ledgerReward, now);
                stakePoints += points;

                // Update last accrued timestamp if this one is more recent
                if (ledgerReward.accruedTimeStamp > lastAccrued) {
                    lastAccrued = ledgerReward.accruedTimeStamp;
                }
            }
        }

        totalLendVaultPoints += stakePoints;
    }

    return {
        totalPoints: totalLendVaultPoints,
        lastAccruedTimestamp: lastAccrued
    };
}

export function calculateActiveStrategyDailyPoints(strategyReward: StrategyRewards | undefined): number | undefined {
    if (!strategyReward) return undefined;

    const ledgerPoints = strategyReward.ledgerRewards.reduce((sum, reward) => {
        return sum + reward.pointsPerSecond * reward.usdValue;
    }, 0);

    const externalPoints = strategyReward.externalYieldRewards
        ? strategyReward.externalYieldRewards.pointsPerSecond * strategyReward.externalYieldRewards.usdValue
        : 0;

    const totalPoints = ledgerPoints + externalPoints;
    const dailyPoints = totalPoints * TIME.DAY;

    return dailyPoints;
}

export function calculateActiveLoanDailyPoints(
    loanReward: LedgerRewards | undefined,
    isBorrower: boolean
): number | undefined {
    if (!loanReward) return undefined;

    const ledgerPoints = loanReward.ledgerRewards.reduce((sum, reward) => {
        // Skip rewards that don't match the user type
        const isLenderReward = reward.isLenderReward && !isBorrower;
        const isBorrowerReward = !reward.isLenderReward && isBorrower;

        if (!isLenderReward && !isBorrowerReward) {
            return sum;
        }

        return sum + reward.pointsPerSecond * reward.usdValue;
    }, 0);

    // Convert to daily points
    return ledgerPoints * TIME.DAY;
}

export function calculateLendVaultDailyPoints(stakeReward: LedgerRewards | undefined): number | undefined {
    if (!stakeReward) return undefined;

    const ledgerPoints = stakeReward.ledgerRewards.reduce((sum, reward) => {
        return sum + reward.pointsPerSecond * reward.usdValue;
    }, 0);

    const totalPoints = ledgerPoints;
    const dailyPoints = totalPoints * TIME.DAY;

    return dailyPoints;
}
