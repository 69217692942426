import {
    CloseStrategyTxnParams,
    CreateCustomStrategyTxnParams,
    DepositStrategyTxnParams,
    EditCustomStrategyTxnParams,
    ExternalYieldSource,
    SellLoanParams,
    CreateLoanParams,
    WithdrawStrategyTxnParams,
    getCloseStrategyTransaction,
    getCreateLoanTransaction,
    getCreateStrategyTransaction,
    getDepositStrategyTransaction,
    getEditStrategyTransactions,
    getSellLoanTransaction,
    getWithdrawStrategyTransction
} from "@bridgesplit/abf-sdk";
import { OrderedTransactions, ParallelTransactionsBatch, combineTransactionPromises } from "@bridgesplit/react";
import { Result, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithSetup } from "./common";
import { getTransactionHeadersFromCookies } from "../utils";
import { TRANSACTION_DEFAULT_BATCH_COMMITMENT } from "../constants";

export type DepositStrategyTxnArgs = DepositStrategyTxnParams & {
    identifier?: string;
};

export function useDepositStrategyTransaction(): AbfTransactionDetails<DepositStrategyTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams(params: DepositStrategyTxnArgs): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getDepositStrategyTransaction,
                identifier: params.identifier ?? "Deposit",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Depositing" };
}

export type WithdrawStrategyTxnArgs = WithdrawStrategyTxnParams & {
    identifier?: string;
};

export function useWithdrawStrategyTransaction(): AbfTransactionDetails<WithdrawStrategyTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams(params: WithdrawStrategyTxnArgs): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getWithdrawStrategyTransction,
                identifier: params.identifier ?? "Withdraw",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing" };
}

export function useUpdateStrategyTermsTransaction(): AbfTransactionDetails<EditCustomStrategyTxnParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams(params: EditCustomStrategyTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getEditStrategyTransactions,
                identifier: "Update terms",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating terms" };
}

export function useCreateStrategyWithCollateralTransaction(): AbfTransactionDetails<CreateCustomStrategyTxnParams> {
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();
    const generate = useAbfGenerateTransactionWithSetup();

    async function getTransactionsWithParams({ ...params }: CreateCustomStrategyTxnParams): AbfGeneratorResult {
        try {
            const auth = getTransactionHeadersFromCookies();
            if (!auth.isOk()) return Result.err(auth);

            const createStrategyWithCollateral = generate({
                generateFunction: getCreateStrategyTransaction,
                identifier: "Setup Strategy",
                params: params
            });

            const generatedTxns = await Promise.all([createStrategyWithCollateral]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [setupTxn] = [generatedTxns[0].unwrap()];

            const txns: OrderedTransactions = [
                {
                    transactions: setupTxn.setupTransactions,
                    commitmentLevel: "confirmed"
                },
                { transactions: setupTxn.transactions, commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT }
            ];
            return Result.ok(txns);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Creating custom strategy" };
}

export type CreateLoanTxnParams = Omit<CreateLoanParams, "mintToCollateralInfo"> & {
    principalDecimals: number;
    loanTransactionIdentifier?: string; // optionally rename the transaction ID
};
export function useFillStrategyOfferTransaction(): AbfTransactionDetails<CreateLoanTxnParams> {
    const {
        resetMarketsApi,
        resetUserAssetsApi,
        resetOraclePricesApi,
        resetLoanApi,
        resetNapoleonApi,
        resetMarketsPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams({
        collateralAmount,
        principalMint,
        collateralMint,
        principalDecimals,
        collateralDecimals,
        ...params
    }: CreateLoanParams): AbfGeneratorResult {
        try {
            const auth = getTransactionHeadersFromCookies();
            if (!auth.isOk()) return Result.err(auth);

            const transactionsRes = await getCreateLoanTransaction(auth.unwrap(), {
                ...params,
                principalMint,
                collateralMint: collateralMint,
                principalAmount: uiAmountToLamports(params.principalAmount, principalDecimals),
                collateralAmount: uiAmountToLamports(collateralAmount, collateralDecimals)
            });

            if (!transactionsRes.isOk()) return Result.err(transactionsRes);

            const txn = transactionsRes.unwrap();

            const orderedTransactions: OrderedTransactions = [];
            // Common transaction mappings
            const createLoanTransactionDetails: ParallelTransactionsBatch = {
                transactions: [
                    {
                        transaction: txn,
                        identifier: "Create loan"
                    }
                ],
                commitmentLevel: "finalized" // always finalized since error case is bad UX
            };

            orderedTransactions.push(createLoanTransactionDetails);

            return Result.ok(orderedTransactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        minGeyserConfirmations: 2,
        refetch: () => {
            resetLoanApi();
            resetMarketsApi();
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetOraclePricesApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Filling borrow order" };
}

export type EditStrategySettingsParams = {
    strategyAddress: string;
    originationsEnabled: boolean;
    externalYieldSource: ExternalYieldSource | undefined;
};

export function useEditStrategySettings(): AbfTransactionDetails<EditStrategySettingsParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetLoanApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams({
        strategyAddress,
        originationsEnabled,
        externalYieldSource
    }: EditStrategySettingsParams): AbfGeneratorResult {
        try {
            const escrowEdit = generate({
                generateFunction: getEditStrategyTransactions,
                identifier: "Update settings",
                params: {
                    strategy: strategyAddress,
                    updateParams: {
                        externalYieldSource: {
                            newExternalYieldSource: externalYieldSource ?? ExternalYieldSource.None
                        },
                        originationsEnabled
                    }
                }
            });

            const transactions = await combineTransactionPromises([escrowEdit], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetLoanApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating loan liquidation preferences" };
}

export type SellLoanTransactionParams = SellLoanParams & { decimals: number };
export function useSellLoanTransaction(): AbfTransactionDetails<SellLoanTransactionParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetLoanApi, resetMarketsPublicApi } =
        useAbfFetches();

    async function getTransactionsWithParams({ decimals, ...params }: SellLoanTransactionParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getSellLoanTransaction,
                identifier: "Early withdraw",
                params: {
                    ...params,
                    sellParams: {
                        ...params.sellParams,
                        expectedSalePrice: uiAmountToLamports(params.sellParams.expectedSalePrice, decimals)
                    }
                }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Selling loan" };
}

export function useDeleteStrategyTransaction(): AbfTransactionDetails<CloseStrategyTxnParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetUserAssetsApi, resetLendingStrategyApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams(params: CloseStrategyTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getCloseStrategyTransaction,
                identifier: "Close account",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetUserAssetsApi();
            resetLendingStrategyApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing accounts" };
}
