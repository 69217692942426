import { LoanMultiplier, LoopscalePointsAction, useRewardsPointsPerSecond } from "@bridgesplit/abf-react";
import { ColorExtension, useAppPalette } from "@bridgesplit/ui";
import { TIME } from "@bridgesplit/utils";

// Points per second refers to the points per second of reward. e.g if its a Lend Reward, use pps of lend.
export function calculateDailyPoints(
    pointsPerSecond: number | undefined,
    usdAmount: number,
    loanMultiplier?: number
): number | undefined {
    if (pointsPerSecond === undefined) {
        return undefined;
    }
    return pointsPerSecond * TIME.DAY * usdAmount * (loanMultiplier ?? 1);
}

export function isBoosted(
    loanMultipliers: LoanMultiplier,
    mint: string,
    loopscalePointsActions: LoopscalePointsAction[]
) {
    return loopscalePointsActions.some((action) => loanMultipliers?.[mint]?.[action]?.multiplier !== undefined);
}

export function useRewardsColor(
    loanMultipliers: LoanMultiplier | undefined,
    mint: string | undefined,
    loopscalePointsActions: LoopscalePointsAction[]
) {
    const { prime, secondary } = useAppPalette();

    if (loanMultipliers && mint && isBoosted(loanMultipliers, mint, loopscalePointsActions)) {
        return prime;
    }

    return secondary;
}

export function useRewardTagColor(
    loanMultipliers: LoanMultiplier | undefined,
    mint: string | undefined,
    loopscalePointsActions: LoopscalePointsAction[]
) {
    if (loanMultipliers && mint && isBoosted(loanMultipliers, mint, loopscalePointsActions)) {
        return ColorExtension.Prime;
    }

    return "secondary";
}

export function useBoostedPointsActions(
    loanMultipliers: LoanMultiplier | undefined,
    mint: string
): LoopscalePointsAction[] {
    if (!loanMultipliers || !mint) return [];

    const allLoopscalePointActions = Object.values(LoopscalePointsAction).filter(
        (value): value is LoopscalePointsAction => typeof value === "number"
    );

    return allLoopscalePointActions.filter((action) => !!loanMultipliers[mint]?.[action]?.multiplier);
}

export function calculateRelativeBoostedMultiplier(
    rewardPointsPerSecond: ReturnType<typeof useRewardsPointsPerSecond>["rewardPointsPerSecond"],
    loanMultipliers: LoanMultiplier | undefined,
    mint: string,
    boostedPointsActions: LoopscalePointsAction[]
): Record<LoopscalePointsAction, number> {
    if (!rewardPointsPerSecond || !loanMultipliers || !mint || boostedPointsActions.length === 0) {
        return {} as Record<LoopscalePointsAction, number>;
    }

    const reletativeBoosted = boostedPointsActions.reduce<Record<LoopscalePointsAction, number>>((acc, action) => {
        const relativePoints = rewardPointsPerSecond[action]?.relativePointsPerSecond || 0;
        const loanMultiplier = loanMultipliers[mint]?.[action]?.multiplier || 0;
        acc[action] = relativePoints * loanMultiplier;
        return acc;
    }, {});

    return reletativeBoosted;
}
