import { Column } from "@bridgesplit/ui";
import { LoopscalePointsAction } from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";

import { RewardsDailyPointsDisplay } from "./RewardsDailyPointsDisplay";
import { RewardsDailyPointsBreakdown } from "./RewardsDailyPointsBreakdown";

export interface RewardsDailyPointsProps {
    loopscalePointsAction: LoopscalePointsAction;
    metadata: TokenListMetadata | undefined;
    loopAmount?: number; //Used for loops to show max leverage based on loops, and for portfolio
}
export function RewardsDailyPoints(props: RewardsDailyPointsProps) {
    return (
        <Column spacing={1}>
            <RewardsDailyPointsDisplay {...props} />
            <Column sx={{ paddingLeft: 2 }}>
                <RewardsDailyPointsBreakdown {...props} />
            </Column>
        </Column>
    );
}
