import { useCallback, useMemo, useState } from "react";

import {
    Column,
    InvisibleSelect,
    Row,
    SkeletonRounded,
    StatColumn,
    Text,
    TextSkeleton,
    Tooltip,
    TooltipText,
    useAppPalette,
    useMemoizedKeyMap,
    VerticalScroll
} from "@bridgesplit/ui";
import {
    bpsToUiDecimals,
    colorToAlpha,
    decimalsToBps,
    filterNullableRecord,
    findMaxElement,
    formatNum,
    formatPercent,
    formatTokenAmount,
    lamportsToUiAmount,
    percentDecimalsToUi,
    percentUiToDecimals,
    roundDownToDecimals
} from "@bridgesplit/utils";
import {
    BsMetaUtil,
    CollateralQuoteFilterForApy,
    useMarketQuotesForApyQuery,
    useMinPrincipalDeposit
} from "@bridgesplit/abf-react";
import { FormInputType } from "@bridgesplit/react";
import { COPY } from "app/constants";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useMarketCollateral, useRatesTicks } from "../util";
import { useMarketContext } from "./MarketContext";
import { useQuotesContext } from "./QuotesContext";
import { EmptyOrderbook } from "./empty";
import { RateTick } from "../types";
import { TokensTooltipDisplay } from "../../common";

type OrderbookProps = {
    selectedApy?: number;
    setSelectedApy?: FormInputType<{ apy: number; maxBorrow: number }>;
    colorOverride?: string;
};

export default function RatesOrderbook(props: OrderbookProps) {
    const [tickSize, setTickSize] = useState(TICK_SIZES_BPS[TICK_SIZES_BPS.length - 2]);
    const { token } = useMarketContext();
    const { uiAmount: minDeposit } = useMinPrincipalDeposit(token);

    return (
        <>
            <Row sx={{ mb: 2 }} spaceBetween>
                <TooltipText
                    helpText={`Max borrow by APY. ${COPY.STRATEGY_TERM_PLURAL} with <${BsMetaUtil.formatAmount(
                        token,
                        minDeposit
                    )} hidden`}
                    variant="h4"
                >
                    Credit book
                </TooltipText>
                <InvisibleSelect
                    variant="body2"
                    options={TICK_SIZES_BPS.map((value) => ({
                        value,
                        label: formatPercent(bpsToUiDecimals(value))
                    }))}
                    value={tickSize}
                    setValue={setTickSize}
                />
            </Row>

            <Orderbook tickSize={tickSize} {...props} />
        </>
    );
}

const SKELETON_COUNT = 5;
const MAX_PERCENT_BAR = 70;
const P_X = 1;
const ROW_HEIGHT = 25;

const TICK_SIZES_BPS = [10, 100, 1000, 10000];
const TICK_SIZE_DECIMALS: Record<number, number> = { 10: 3, 100: 2, 1000: 1, 10000: 0 };

function Orderbook({ selectedApy, setSelectedApy, tickSize, colorOverride }: OrderbookProps & { tickSize: number }) {
    const { symbol, token } = useMarketContext();
    const { orderbookQuotes } = useQuotesContext();

    const ticks = useRatesTicks({ orderbookQuotes, tickSizeBps: tickSize });
    const { secondary, hoverOpacity } = useAppPalette();

    const baseColor = colorOverride ?? secondary;
    const activeBackground = colorToAlpha(baseColor, hoverOpacity * 3);
    const baseBackground = colorToAlpha(baseColor, hoverOpacity * 2);

    const max = findMaxElement(ticks, (v) => v.maxPrincipal)?.maxPrincipal ?? 0;

    const formatToken = useCallback(
        (val: number | undefined, hideSymbol?: boolean) => {
            const decimals = Math.min(token?.decimals ?? 0, 2);
            // always show the rounded down decimals in case user tries to copy/paste value
            const rounded = val ? roundDownToDecimals(val, decimals) : val;
            if (rounded === 0) return `<${1 / 10 ** decimals}`;

            return formatTokenAmount(rounded, { hideSymbol, decimals, symbol: BsMetaUtil.getSymbol(token) });
        },
        [token]
    );

    const percentDecimals = TICK_SIZE_DECIMALS[tickSize];

    if (ticks?.length === 0) {
        return (
            <Column>
                <EmptyOrderbook />
            </Column>
        );
    }

    return (
        <Column sx={{ ml: -P_X }} spacing={0.5}>
            <Row sx={{ pl: P_X }} spaceBetween>
                <Text variant="body2" color="caption">
                    APY
                </Text>
                <Text variant="body2" color="caption">
                    Available ({symbol})
                </Text>
            </Row>
            <VerticalScroll maxHeight={300}>
                {!ticks &&
                    Array(SKELETON_COUNT)
                        .fill(null)
                        .map((_, i) => (
                            <Row sx={{ pl: P_X }} key={i} spaceBetween>
                                <TextSkeleton width="40px" variant="body1" />
                                <SkeletonRounded
                                    height={ROW_HEIGHT}
                                    width={`${Math.min((i + 1) * (MAX_PERCENT_BAR / SKELETON_COUNT), 100)}%`}
                                />
                            </Row>
                        ))}
                {ticks?.map((tick, i) => {
                    const selected = percentUiToDecimals(selectedApy);
                    const textTickApy = ticks[i + 1]?.apy ?? 0;
                    const isActive = (() => {
                        if (!tick.apy) return false;
                        if (i === ticks.length - 1) {
                            return selected >= tick.apy && selected < tick.apy + bpsToUiDecimals(tickSize);
                        }
                        return selected >= tick.apy && selected < textTickApy;
                    })();

                    return (
                        <Tooltip
                            key={i}
                            placement="left"
                            containerSx={{ width: "100%" }}
                            reverseColors
                            title={<TickTooltip tick={tick} tickSize={tickSize} />}
                        >
                            <Row
                                onClick={() =>
                                    setSelectedApy?.({
                                        apy: percentDecimalsToUi(tick.apy),
                                        maxBorrow: tick.maxPrincipal
                                    })
                                }
                                sx={{
                                    pl: P_X,
                                    position: "relative",
                                    height: ROW_HEIGHT + "px",
                                    cursor: setSelectedApy ? "pointer" : undefined,
                                    ":hover": setSelectedApy
                                        ? {
                                              background: activeBackground,
                                              ".available": { background: activeBackground }
                                          }
                                        : undefined
                                }}
                                spaceBetween
                            >
                                <Text variant="body2" color={isActive ? "secondary" : "body"}>
                                    {tick.apy === 0
                                        ? `<${formatPercent(bpsToUiDecimals(tickSize))}` // apy=0 if rounded below tick
                                        : formatPercent(tick.apy, { customDecimals: percentDecimals })}
                                </Text>
                                <Text variant="body2">{formatToken(tick.maxPrincipal, true)}</Text>
                                <Row
                                    className="available"
                                    sx={{
                                        right: 0,
                                        position: "absolute",
                                        height: ROW_HEIGHT + "px",
                                        justifyContent: "flex-end",
                                        background: isActive ? activeBackground : baseBackground,
                                        width: `${MAX_PERCENT_BAR * Math.max(tick.maxPrincipal / max, 0.05)}%`
                                    }}
                                />
                            </Row>
                        </Tooltip>
                    );
                })}
            </VerticalScroll>
        </Column>
    );
}

function useGetMarketQuotesParams() {
    const { principalMint } = useMarketContext();
    const { strategyDuration, collateralMintsForQueries } = useQuotesContext();

    return (tick: RateTick, tickSize: number): CollateralQuoteFilterForApy | undefined => {
        if (!principalMint || !strategyDuration || !collateralMintsForQueries) return undefined;
        return {
            principal: principalMint,
            apyRange: [decimalsToBps(tick.apy), decimalsToBps(tick.apy) + tickSize],
            collateral: collateralMintsForQueries,
            duration: strategyDuration?.duration,
            durationType: strategyDuration?.durationType,
            limit: 1000,
            offset: 0
        };
    };
}

function TickTooltip({ tick, tickSize }: { tick: RateTick; tickSize: number }) {
    const query = useGetMarketQuotesParams()(tick, tickSize);
    const { token } = useMarketContext();

    const { data: rawData } = useMarketQuotesForApyQuery(query ?? skipToken, { skip: !query });
    const allTokens = useMarketCollateral();

    const collateralMap = useMemoizedKeyMap(allTokens, (t) => t.mint);

    const data = useMemo(() => {
        if (!rawData || !collateralMap) return undefined;

        return rawData
            .map((d) => {
                const collateral = collateralMap.get(d.collateral);
                return {
                    ...d,
                    token: collateral,
                    apy: bpsToUiDecimals(d.maxPrincipalAvailable),
                    maxPrincipalAvailable: lamportsToUiAmount(d.maxPrincipalAvailable, token?.decimals)
                };
            })
            .filter(filterNullableRecord);
    }, [collateralMap, rawData, token?.decimals]);

    return (
        <Column spacing={1}>
            <TokensTooltipDisplay
                skeletonsCount={10}
                label={
                    <Row spacing={3} spaceBetween>
                        <Text variant="body2" color="caption">
                            Collateral
                        </Text>
                        <Text variant="body2" color="caption">
                            Available ({BsMetaUtil.getSymbol(token)})
                        </Text>
                    </Row>
                }
                tokens={data?.map(({ token, maxPrincipalAvailable }) => ({
                    metadata: token,
                    rowEnd: (
                        <Text variant="body2" color="caption">
                            {formatNum(maxPrincipalAvailable)}
                        </Text>
                    )
                }))}
            />
            <StatColumn
                variant="body2"
                captionVariant="body2"
                stats={[
                    {
                        caption: "Available",
                        value: formatNum(tick.maxPrincipal)
                    }
                ]}
            />
        </Column>
    );
}
