import { ReactNode } from "react";

import { COPY } from "app/constants";
import { MobileCtaWrapper } from "app/components/wrappers";

import { useWindContext } from "../wind";

export default function LoopMobileCtaWrapper({ children, card }: { children: ReactNode; card: ReactNode }) {
    const { isActionOpen, setIsActionOpen } = useWindContext();

    return (
        <MobileCtaWrapper open={isActionOpen} setOpen={setIsActionOpen} card={card} ctaText={COPY.LOOP_TERM_ACTION}>
            {children}
        </MobileCtaWrapper>
    );
}
