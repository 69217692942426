import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { TokenByWalletResponse } from "../types";
import { GET_TOKENS_ROUTE } from "../constants";
import { abfSerializeQueryArgs, marketsBaseQuery } from "./util";
export const USER_ASSETS_TAG = "EscrowsPublic";
export const userAssetsApi = createApi({
    reducerPath: "userAssetsApi",
    baseQuery: marketsBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [USER_ASSETS_TAG],
    endpoints: (builder) => ({
        tokensByWallet: builder.query<TokenByWalletResponse, string>({
            providesTags: [USER_ASSETS_TAG],
            query() {
                return {
                    url: `${GET_TOKENS_ROUTE}`,
                    method: "GET"
                };
            }
        })
    })
});

export const { useTokensByWalletQuery } = userAssetsApi;

export const useUserAssetsApi = () => {
    const dispatch = useDispatch();
    return {
        resetUserAssetsApi: () => dispatch(userAssetsApi.util.invalidateTags([USER_ASSETS_TAG]))
    };
};
