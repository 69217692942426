import { StatProps } from "@bridgesplit/ui";
import { bsMath, formatPercent, formatUsd } from "@bridgesplit/utils";

import { ProfitLossText } from "../../common";
import { usePortfolioStats } from "./util";
import { PortfolioTableStats } from "./common";
import { COPY } from "../../../constants";

export default function LenderLoanStats() {
    const lendStats = usePortfolioStats()?.lendStats;

    const stats: StatProps[] = [
        {
            value: formatUsd(lendStats?.totalSupplyUsd),
            caption: "Total supplied"
        },
        {
            value: formatPercent(lendStats?.wAvgApy),
            caption: "Lend APY"
        },
        {
            value: <ProfitLossText profitLossUsd={bsMath.add(lendStats?.interestEarnedAllTimeUsd)} />,
            caption: COPY.INTEREST_EARNED_LABEL
        }
    ];
    return <PortfolioTableStats stats={stats} loading={!lendStats} />;
}
