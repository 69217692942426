import React from "react";

import { LoopscaleWeb3Provider, useAppTheme } from "@bridgesplit/react";
import { DialogProvider, LoopscaleTrackingProvider, WalletProvider, createThemeOptions } from "@bridgesplit/ui";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from "@mui/system/ThemeProvider";
import { RPC_URL } from "@bridgesplit/utils";

import AppAlerts from "./AppAlerts";
import { useTrack } from "../../hooks";

// When auth0 runs in a separate domains from browser, session auth doesn't work
// https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari

export default function Providers({ children }: { children: React.ReactNode }) {
    const { mode } = useAppTheme();
    const theme = createTheme(createThemeOptions(mode));
    const track = useTrack();

    return (
        <ThemeProvider theme={theme}>
            <WalletProvider connectionUrl={RPC_URL}>
                <LoopscaleWeb3Provider connectionUrl={RPC_URL}>
                    <DialogProvider>
                        <AppAlerts>
                            <LoopscaleTrackingProvider trackEvent={track.rawTrack}>
                                {children}
                            </LoopscaleTrackingProvider>
                        </AppAlerts>
                    </DialogProvider>
                </LoopscaleWeb3Provider>
            </WalletProvider>
        </ThemeProvider>
    );
}
