import { PublicKey } from "@solana/web3.js";

import { AllowedEnv, DEFAULT_API_VERSION, ENVIRONMENTS, EnvDetails, SERVICE_API_VERSIONS } from "./env";

type AllowedDevEnv =
    | AllowedEnv.Dev
    | AllowedEnv.ProdTesting
    | AllowedEnv.FeatureStaging
    | AllowedEnv.Sandbox
    | AllowedEnv.LocalNet
    | AllowedEnv.Main;

export const IS_LOCAL_NX_DEV = process.env.NX_TASK_TARGET_CONFIGURATION === "development";

export const DEBUG = IS_LOCAL_NX_DEV;

const cloudEnvRaw = process.env.NX_ENVIRONMENT;
const cloudEnv =
    cloudEnvRaw && Object.values(AllowedEnv).includes(cloudEnvRaw as AllowedEnv)
        ? (cloudEnvRaw as AllowedEnv)
        : undefined;

// Modify this
const devEnv: AllowedDevEnv = AllowedEnv.Main;

// Do not modify
const env: AllowedEnv = (() => {
    // prevents bad AWS deploys in prod
    if (window.location.origin.includes("app.loopscale")) return AllowedEnv.Main;
    if (window.location.origin.includes("alpha.loopscale")) return AllowedEnv.Sandbox;

    if (IS_LOCAL_NX_DEV) return devEnv;

    return cloudEnv ?? AllowedEnv.Main;
})();

export const ENV_DETAILS: EnvDetails = ENVIRONMENTS[env];

export const BRIDGESPLIT_TS_API = "https://bridgesplit-backend-vmyhi2huoa-uc.a.run.app/";
export const MESSAGING_API = ENV_DETAILS.messagingApi;
export const MPC_API = ENV_DETAILS.mpcApi;
export const ARRAKIS_API = ENV_DETAILS.arrakisApi;
export const RUBY_API = ENV_DETAILS.rubyApi;
export const ORACLES_API = ENV_DETAILS.oraclesApi;
export const MARKETS_API = ENV_DETAILS.marketsApi;
export const PANDORA_API = ENV_DETAILS.pandoraApi;

export const IS_LOCAL_NET = env === AllowedEnv.LocalNet;
export const IS_PROD = env === AllowedEnv.Main;
export const IS_SANDBOX = env === AllowedEnv.Sandbox;

export const RPC_URL =
    ENV_DETAILS.rpcUrl ?? process.env.RPC_URL ?? "https://delicious-melita-fast-mainnet.helius-rpc.com/";
export const ABF_PID = new PublicKey(ENV_DETAILS.abfPid);
export const LIQUIDATION_PID = new PublicKey(ENV_DETAILS.liquidationPid);

export const SYNDICATED_PID = new PublicKey(ENV_DETAILS.syndicatedPid);

// Sphere's app ID. Fine to expose publicly
export const TELLER_APP_ID = "app_oo7bqp4gqthrcviih8000";

export const doesApiVersionMatch = (endpoint: string, headerVersion: string) => {
    const endpointKey = Object.entries(ENV_DETAILS).find(([, basePath]) => endpoint.includes(basePath))?.[0];

    // if unmatched api, version is always compatible
    if (!endpointKey) {
        return true;
    }
    const apiVersion = SERVICE_API_VERSIONS[endpointKey as keyof typeof SERVICE_API_VERSIONS] ?? DEFAULT_API_VERSION;

    return apiVersion === headerVersion;
};
