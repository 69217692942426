import { Pagination } from "@bridgesplit/react";
import { SortOrder } from "@bridgesplit/utils";

export interface LedgerAccountFilter {
    lenders?: string[];
    borrowers?: string[];
    loans?: string[];
    unfilled_only?: boolean;
    sorting?: LedgerAccountSorting;
    pagination?: Pagination;
}

export interface LedgerAccountSorting {
    direction: SortOrder;
    sorting_type: LedgerSorting;
}

export enum LedgerSorting {
    RepaymentTime = "RepaymentTime",
    TotalDue = "TotalDue"
}

export enum PaymentSorting {
    RepaymentTime = "RepaymentTime"
}

interface PaymentEventSorting {
    direction: SortOrder;
    sorting_type: PaymentSorting;
}

export interface PaymentEventsFilter {
    loans: string[];
    sorting?: PaymentEventSorting;
    pagination?: Pagination;
}
