import { Icon, StatColumn, StatProps, useTextColorMap } from "@bridgesplit/ui";
import { formatLeverage } from "@bridgesplit/abf-react";

import { CaptionWithIcon } from "../common";

export function RewardsMaxLeverage({ maxLeverage }: { maxLeverage: number }) {
    const textColorMap = useTextColorMap();
    const stats: StatProps[] = [
        {
            value: formatLeverage(maxLeverage),
            caption: (
                <CaptionWithIcon
                    caption="Max Leverage"
                    iconColor={textColorMap("caption")}
                    icon={<Icon type="leverage" />}
                />
            )
        }
    ];
    return <StatColumn stats={stats} captionVariant="body1" />;
}
