import { useMemo } from "react";

import {
    bsMath,
    calculatePercentChange,
    findMaxElement,
    getUnixTs,
    isPastNow,
    roundToDecimals,
    TIME
} from "@bridgesplit/utils";

import {
    AbfLoanExpanded,
    AbfOrderFundingType,
    Ledger,
    LedgerDebt,
    LedgerExpanded,
    LiquidationType,
    LoanEventActionType,
    ParsedBorrowPrincipalMetadata,
    ParsedLoanEventExpanded,
    ParsedRepayPrincipalMetadata
} from "../types";
import { summarizeLedgerAccount } from "./order";
import { getZcLedger, getZcLoanCollateral, getZcLoanLiquidationThreshold, useLenderStrategies } from "../api";

export function calculateHealthRatio(
    totalOwedValue: number | undefined,
    loanCollateralValue: number | undefined,
    liquidationThreshold: number | undefined
): number {
    if (totalOwedValue === undefined || loanCollateralValue === undefined || liquidationThreshold === undefined)
        return 0;

    if (totalOwedValue === 0) return 1;
    const loanRatio = totalOwedValue / loanCollateralValue;

    return calculateHealthRatioFromLtv(loanRatio, liquidationThreshold);
}

export function calculateHealthRatioFromLtv(ltv: number | undefined, liquidationThreshold: number | undefined): number {
    if (ltv === undefined || liquidationThreshold === undefined) return 0;

    if (ltv === 0) return 1;
    const health = ltv * (1 / liquidationThreshold);
    const healthRatio = Math.max(1 - health, 0);
    return healthRatio;
}

export function calculateLiquidationPrice(
    totalPrincipalAmount: number | undefined,
    loanCollateralAmount: number | undefined,
    liquidationThreshold: number | undefined
): number {
    const collateralizationRatio = bsMath.div(loanCollateralAmount, totalPrincipalAmount);
    const liquidationPrice = bsMath.mul(liquidationThreshold, collateralizationRatio) ?? 0;
    return 1 / liquidationPrice;
}

export function isLoanZeroCoupon(loanExpanded: AbfLoanExpanded | undefined): boolean {
    return loanExpanded?.ledger.length === 1;
}

export function isLoanRefinanced(loanExpanded: AbfLoanExpanded | undefined): boolean {
    if (!loanExpanded) return false;

    // Check loan events for any refinance actions
    return loanExpanded.events.some(
        (event) =>
            isRefinanceEvent(event.action) ||
            (event.originalLender && event.newLender && event.originalLender !== event.newLender)
    );
}

export function isSimpleLoan(loanExpanded: AbfLoanExpanded | undefined): boolean {
    if (!loanExpanded) return false;
    return isLoanZeroCoupon(loanExpanded);
}

export function canLenderSellLoan(loanExpanded: AbfLoanExpanded | undefined): boolean {
    if (!loanExpanded) return false;
    if (isLoanFullyRepaid(loanExpanded)) return false;
    return true;
}

export function isLoopLoan(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.loan.loanType === AbfOrderFundingType.Loop;
}

export function isLoanOracleBased(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    return true;
}

export function isLoanFullyRepaid(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    return loanExpanded.ledger.every((l) => l.isFilled);
}

export function getNextPaymentDate(loan: AbfLoanExpanded | undefined): number | undefined {
    if (!loan) {
        return undefined;
    }
    const firstNotFilledElement = loan.ledger.find((el) => !el.isFilled);
    if (!firstNotFilledElement) return undefined;
    return firstNotFilledElement.endTime;
}

export function calculateLoanLiquidationPrice(
    loanExpanded: AbfLoanExpanded | undefined,
    ledgerAccount?: LedgerExpanded
) {
    const firstLedger = getZcLedger(loanExpanded);
    const selectedLedgerAccount = ledgerAccount ?? firstLedger;
    if (
        !loanExpanded ||
        loanExpanded.collateral.length !== 1 ||
        !isLoanOracleBased(loanExpanded) ||
        !selectedLedgerAccount
    )
        return undefined;

    const collateral = loanExpanded.collateral[0];
    const totalOutstanding = summarizeLedgerAccount(selectedLedgerAccount).totalOutstanding;
    const collateralInfo = getZcLoanCollateral(loanExpanded);

    const liquidationThreshold = getZcLoanLiquidationThreshold(loanExpanded);
    const liquidationPrice = calculateLiquidationPrice(
        totalOutstanding,
        collateralInfo?.loanCollateral.amount,
        liquidationThreshold
    );

    const currentPrice = bsMath.div(
        collateralInfo?.loanCollateral.usdPrice ?? undefined,
        loanExpanded.principalUsdPrice
    );
    const percentChange = calculatePercentChange(currentPrice, liquidationPrice);

    return { price: liquidationPrice, collateral, percentChange };
}

export type LoanInterestDueInfo = {
    newTotalInterestOutstanding: number;
    principalToPay: number;
    interestToPay: number;
    newTotalInterestDue: number;
    isInterestRecalculated: boolean;
    repaymentAmount: number;
};

export type LoanInterestDueParams = {
    loanExpanded: AbfLoanExpanded;
    repaymentAmount: number;
    ledgerAccount: LedgerExpanded | Ledger | undefined;
    isPrincipalRepay?: boolean;
};

export function getLoanInterestDue(params: LoanInterestDueParams): LoanInterestDueInfo {
    const { repaymentAmount, ledgerAccount } = params;
    const ledgerSummary = summarizeLedgerAccount(ledgerAccount);

    const currentTimestamp = getUnixTs();

    const interestAccrued = getInterestAccrued(ledgerSummary, currentTimestamp);

    // Calculate interest accrued since last repayment
    const interestAccruedSinceLastRepayment = Math.max(interestAccrued - ledgerSummary.interestPaid, 0);

    // First, repay any accrued interest
    const amountLeftAfterInterest = Math.max(repaymentAmount - interestAccruedSinceLastRepayment, 0);

    // Calculate how much principal can be repaid with remaining amount
    const principalOutstanding = ledgerSummary.principalOutstanding;
    const principalToPay = Math.min(amountLeftAfterInterest, principalOutstanding);

    // Calculate how much interest will be repaid
    const interestToPay = Math.min(interestAccruedSinceLastRepayment, repaymentAmount);

    // Calculate new interest due based on principal repayment
    let newTotalInterestDue = ledgerSummary.interestDue;

    if (principalToPay > 0) {
        // If we're repaying principal, calculate the new interest due
        if (currentTimestamp < ledgerSummary.endTime) {
            // Before end time: Calculate interest saved from early principal repayment
            const timeRemaining = ledgerSummary.endTime - currentTimestamp;
            const interestSaved = calculateInterestForPeriod(principalToPay, ledgerSummary.apy, timeRemaining);
            newTotalInterestDue = ledgerSummary.interestDue - interestSaved;
        } else {
            // After end time: Use accrued interest as the total interest due
            newTotalInterestDue = interestAccrued;
        }
    }

    // Calculate new total interest outstanding
    const newTotalInterestOutstanding = newTotalInterestDue - ledgerSummary.interestPaid;

    return {
        repaymentAmount,
        newTotalInterestOutstanding,
        principalToPay,
        interestToPay,
        isInterestRecalculated: principalToPay > 0,
        newTotalInterestDue
    };
}

// Helper function to calculate interest accrued based on Rust's get_interest_accrued method
function getInterestAccrued(
    ledgerSummary: ReturnType<typeof summarizeLedgerAccount>,
    currentTimestamp: number
): number {
    const principalOutstanding = ledgerSummary.principalOutstanding;

    let pastEndTime = false;
    let timeRemaining: number;

    if (ledgerSummary.endTime >= currentTimestamp) {
        timeRemaining = ledgerSummary.endTime - currentTimestamp;
    } else {
        pastEndTime = true;
        timeRemaining = currentTimestamp - ledgerSummary.endTime;
    }
    timeRemaining += 120; // add 2 mins buffer

    // Calculate interest remaining or additional interest accrued after end time

    const interestRemaining = calculateInterestForPeriod(principalOutstanding, ledgerSummary.apy, timeRemaining);

    // If past end time, add interest; otherwise subtract from total interest due
    if (pastEndTime) {
        return ledgerSummary.interestDue + interestRemaining;
    } else {
        return ledgerSummary.interestDue - interestRemaining;
    }
}

// Helper function to calculate interest for a specific period. APY is in decimals.
function calculateInterestForPeriod(principalAmount: number, apy: number, timeInSeconds: number): number {
    return Math.floor(principalAmount * apy * (timeInSeconds / TIME.YEAR));
}

const getDueTime = (ledgerAccount: LedgerExpanded | Ledger) => ledgerAccount.endTime;

const getDueTimeWithGracePeriod = (ledgerAccount: LedgerExpanded) => getDueTime(ledgerAccount);

export function findUnfilledEmptyLedgers(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.ledger.filter((l) => {
        if (l.isFilled) return false;
        const principalOutstanding = Math.max(l.principalDue - l.principalRepaid, 0);
        const interestOutstanding = Math.max(l.interestDue - l.interestRepaid, 0);
        return !principalOutstanding && !interestOutstanding;
    });
}

export function getLoanTotalDebtForLedger(
    loanExpanded: AbfLoanExpanded,
    ledgerAccount: LedgerExpanded | Ledger,
    customRepaymentAmount?: number
): LedgerDebt {
    const originalLedgers = summarizeLedgerAccount(ledgerAccount);

    const repaymentAmount = customRepaymentAmount ?? originalLedgers.totalOutstanding;
    const info = getLoanInterestDue({
        ledgerAccount: ledgerAccount,
        loanExpanded,
        repaymentAmount
    });

    const summary = summarizeChangesFromNewInterest(loanExpanded, ledgerAccount, info);

    return {
        total: summary.totalToPay,
        interestAccrued: info.interestToPay
    };
}

export function getNextLedgerAccount(loanExpanded: AbfLoanExpanded | undefined) {
    const nextLedgerAccount = loanExpanded?.ledger.find((l) => !l.isFilled);
    return { nextLedgerAccount };
}

type BeforeAfter = [number, number];
type SummarizedChanges = {
    outstanding: BeforeAfter;
    totalToPay: number;
    lateFee: number;
    earlyFees: BeforeAfter;
    interestSaved: number;
    dueDate: number;
};

export function summarizeChangesFromNewInterest(
    loanExpanded: AbfLoanExpanded,
    ledgerAccount: LedgerExpanded | Ledger | undefined,
    paymentInfo: LoanInterestDueInfo
): SummarizedChanges {
    const originalLedger = summarizeLedgerAccount(ledgerAccount);

    const firstLedger = getZcLedger(loanExpanded);

    const { newTotalInterestOutstanding, principalToPay, interestToPay, newTotalInterestDue } = paymentInfo;

    const interestSaved = Math.max(originalLedger.interestDue - newTotalInterestDue, 0);

    const totalToPay = principalToPay + interestToPay;

    const oldOutstanding = originalLedger.interestOutstanding + originalLedger.principalOutstanding;
    const newOutstanding =
        Math.max(originalLedger.principalOutstanding - principalToPay) +
        Math.max(newTotalInterestOutstanding - interestToPay, 0);

    return {
        totalToPay: roundToDecimals(totalToPay, loanExpanded.principalMetadata?.decimals),
        outstanding: [oldOutstanding, newOutstanding],
        earlyFees: [0, 0],
        interestSaved,
        lateFee: 0,
        dueDate: firstLedger?.endTime ?? 0
    };
}

export function calculateAmountLateFee(ledgerAccount: LedgerExpanded) {
    if (!isPastNow(getDueTimeWithGracePeriod(ledgerAccount))) {
        return 0;
    }
    const fee =
        ledgerAccount.principalDue -
        ledgerAccount.principalRepaid +
        (ledgerAccount.interestDue - ledgerAccount.interestRepaid);
    return Math.max(fee, 0);
}

export function isUserParticipant(event: ParsedLoanEventExpanded, role: "seller" | "buyer", walletId: string): boolean {
    if (!walletId) return false;

    if (role === "seller") {
        return walletId === event.originalLender;
    } else {
        return walletId === event.newLender;
    }
}

// Updated event finder functions
export function findMostRecentSellerEvent(loanExpanded: AbfLoanExpanded | undefined, walletId: string) {
    return findMaxElement(
        loanExpanded?.events.filter((e) => isSellLedgerEvent(e.action) && isUserParticipant(e, "seller", walletId)),
        ({ eventTime }) => eventTime
    );
}

export function findMostRecentBuyerEvent(loanExpanded: AbfLoanExpanded | undefined, walletId: string) {
    return findMaxElement(
        loanExpanded?.events.filter((e) => isBorrowEvent(e.action) && isUserParticipant(e, "buyer", walletId)),
        ({ eventTime }) => eventTime
    );
}

export function getTotalRepaidForCurrentLender(
    loanExpanded: AbfLoanExpanded | undefined,
    ledgerAccount: LedgerExpanded | undefined
) {
    if (!loanExpanded || !ledgerAccount) return 0;

    // Get total repaid across all ledgers
    const originalLedgers = summarizeLedgerAccount(ledgerAccount);

    // Find the most recent borrow event
    const borrowEvent = loanExpanded.events
        .sort((a, b) => b.eventTime - a.eventTime)
        .find((c) => isBorrowEvent(c.action));

    if (!borrowEvent) return 0;

    const metadata = borrowEvent.actionMetadata;

    const preState = metadata.borrowPrincipal.preBorrowPrincipalLedgerState;
    const totalRepaidInPast = preState ? bsMath.add(preState.principalRepaid ?? 0, preState.interestRepaid ?? 0) : 0;

    const totalRepaidTotal = originalLedgers.totalPaid ?? 0;

    const totalRepaidForCurrentLender = bsMath.sub(totalRepaidTotal, totalRepaidInPast);

    return totalRepaidForCurrentLender;
}

export function getTotalLenderSaleProceeds(loanExpanded: AbfLoanExpanded | undefined, wallet: string | undefined) {
    if (!loanExpanded || !wallet) return { proceeds: 0, mostRecentSell: undefined };
    const mostRecentSell = findMostRecentSellerEvent(loanExpanded, wallet);
    const totalRepaidForCurrentLender = getTotalRepaidForCurrentLender(loanExpanded, getZcLedger(loanExpanded));

    const proceeds = bsMath.add(mostRecentSell?.amount, totalRepaidForCurrentLender) ?? 0;
    return { proceeds, mostRecentSell };
}

export function calculatePrincipalInterestDeltaFromBorrowOrRepayEvent({
    metaData,
    action
}: {
    metaData: ParsedBorrowPrincipalMetadata | ParsedRepayPrincipalMetadata;
    action: LoanEventActionType;
}) {
    if (isBorrowEvent(action)) {
        const { preBorrowPrincipalLedgerState: pre, postBorrowPrincipalLedgerState: post } =
            metaData as ParsedBorrowPrincipalMetadata;
        return {
            principalDelta: Math.abs(post.principalDue - pre.principalDue),
            interestDelta: Math.abs(post.interestDue - pre.interestDue)
        };
    } else {
        const { preRepaymentPrincipalLedgerState: pre, postRepaymentPrincipalLedgerState: post } =
            metaData as ParsedRepayPrincipalMetadata;
        return {
            principalDelta: Math.abs(post.principalRepaid - pre.principalRepaid),
            interestDelta: Math.abs(post.interestDue - pre.interestDue)
        };
    }
}

export function isBorrowEvent(action: LoanEventActionType | undefined) {
    return action === LoanEventActionType.BorrowPrincipal;
}

export function isRepayEvent(action: LoanEventActionType | undefined) {
    return action === LoanEventActionType.RepayPrincipal;
}

export function isLiquidateEvent(action: LoanEventActionType | undefined) {
    return action === LoanEventActionType.Liquidate;
}

export function isFullLiquidationEvent(event: ParsedLoanEventExpanded) {
    const { liquidationType } = event.actionMetadata[LoanEventActionType.Liquidate];
    return liquidationType === LiquidationType.FullLiquidation;
}

export function isRefinanceEvent(action: LoanEventActionType | undefined) {
    return action === LoanEventActionType.RefinanceLedger;
}

export function isSellLedgerEvent(action: LoanEventActionType | undefined) {
    return action === LoanEventActionType.SellLedger;
}

export function useIsLoanLender(loanExpanded: AbfLoanExpanded | undefined, activeWallet: string | undefined) {
    const { data: strategies } = useLenderStrategies();

    const strategyAddress = useMemo(() => strategies?.map((s) => s.strategy.address), [strategies]);

    if (!loanExpanded || !activeWallet || !strategyAddress) return false;
    const isLender = loanExpanded.lenders.some((lender) => strategyAddress.includes(lender));
    return isLender;
}
