import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    QueryDefinition
} from "@reduxjs/toolkit/dist/query";
import axios from "axios";
import {
    ARRAKIS_API,
    PANDORA_API,
    doesApiVersionMatch,
    MARKETS_API,
    MPC_API,
    Result,
    RUBY_API
} from "@bridgesplit/utils";
import { AppCookie } from "@bridgesplit/react";
import { USER_WALLET_HEADER, API_VERSION_HEADER } from "@bridgesplit/abf-sdk";

import { USER_WALLET_COOKIE } from "../constants";
import { updateApiVersionNeeded } from "./authSlice";
import { AbfUserWithPermissionsAndWallets } from "..";

export enum AccessLevel {
    NotConnected = "NOT_CONNECTED",
    Unregistered = "UNREGISTERED",
    Waitlist = "WAITLIST",
    BetaAccess = "BETA_ACCESS"
}
export interface AuthConfig {
    authLevel: AccessLevel;
}

interface HeadersResult {
    skip: boolean;
    headers: Record<string, string | undefined>;
}
export const DEFAULT_AUTH_CONFIG: AuthConfig = {
    authLevel: AccessLevel.BetaAccess
};

function getHeaders(config: AuthConfig): HeadersResult {
    const userWallet = AppCookie.get(USER_WALLET_COOKIE);

    const skip = config.authLevel === AccessLevel.Unregistered && !userWallet;
    const headers: Record<string, string | undefined> = {
        [USER_WALLET_HEADER]: userWallet
    };

    return {
        skip,
        headers
    };
}

export type AbfBaseQueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

interface EndpointDefinition {
    authConfig?: AuthConfig;
}

export const getBaseQuery = (
    baseUrl: string,
    defaultAuthConfig: AuthConfig = DEFAULT_AUTH_CONFIG,
    inviteRequired = true
): AbfBaseQueryFn => {
    const rawBaseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { endpoint }) => {
            const endpointDef = endpoint as EndpointDefinition;
            const endpointAuthConfig = endpointDef.authConfig || defaultAuthConfig;
            const { headers: cookieHeaders } = getHeaders(endpointAuthConfig);

            Object.entries(cookieHeaders).forEach(([key, value]) => {
                if (value !== undefined) {
                    headers.set(key, value);
                }
            });

            return headers;
        }
    });

    return async (args, api, extraOptions) => {
        const endpoint = api.endpoint as EndpointDefinition;
        const endpointAuthConfig = endpoint.authConfig || defaultAuthConfig;
        const { skip } = getHeaders(endpointAuthConfig);

        if (inviteRequired) {
            const queries = api.getState() as {
                abfUserApi: { queries: Record<string, { data: AbfUserWithPermissionsAndWallets }> };
            };

            const userBetaCodeExists = Object.entries(queries.abfUserApi.queries).some(
                ([key, value]) =>
                    (key.includes("userMe") && value.data?.beta_borrow_access) || value.data?.beta_lend_access
            );

            if (!userBetaCodeExists && skip) {
                api.abort();
            }
        }

        // prevent misfires without auth
        if (skip) {
            api.abort();
        }

        const result = await rawBaseQuery(args, api, extraOptions);

        if (result.meta?.response) {
            const apiVersion = result.meta.response.headers.get(API_VERSION_HEADER);

            const url = result.meta.request.url;

            if (apiVersion && !doesApiVersionMatch(url, apiVersion)) {
                api.dispatch(updateApiVersionNeeded(true));
            }
        }

        if (result.error) {
            logError(result.error.data);
        }

        return result;
    };
};

const ALLOWED_ERRORS = [
    "Invalid JWT",
    "User does not have access",
    "User-Wallet: Value not in header",
    "User does not have beta access",
    "User has invalid roles"
];
function logError(error: unknown) {
    if (typeof error !== "string") return;
    for (const allowedError of ALLOWED_ERRORS) {
        if (error.toLowerCase().includes(allowedError.toLowerCase())) {
            return Result.err(error);
        }
    }
}

// serialize with header to force each query to be user specific in case of account change
export function abfSerializeQueryArgs({
    queryArgs,
    endpointName
}: {
    endpointName: string;
    queryArgs: FetchArgs | string;
}) {
    const params = JSON.stringify(queryArgs);

    return `${endpointName}(${params})`;
}

// to override query args for endpoints uncorrelated to user
export function unauthenticatedSerializeQueryArgs<T>({
    queryArgs,
    endpointName
}: {
    endpointName: string;
    queryArgs: T;
}) {
    const params = JSON.stringify(queryArgs);
    return `${endpointName}(${params})`;
}

export const mpcBaseQuery = getBaseQuery(MPC_API);

export const arrakisBaseQuery = getBaseQuery(ARRAKIS_API);
export const pandoraBaseQuery = getBaseQuery(PANDORA_API);

export const marketsBaseQuery = getBaseQuery(MARKETS_API);
export const marketsPublicBaseQuery = getBaseQuery(MARKETS_API, { authLevel: AccessLevel.NotConnected }, false);

export const rubyBaseQuery = getBaseQuery(RUBY_API, { authLevel: AccessLevel.NotConnected }, false);

export function axiosCreateMarkets(baseURL = MARKETS_API) {
    const headers = getHeaders(DEFAULT_AUTH_CONFIG).headers;
    return axios.create({ baseURL, headers });
}

type EndpointWithAuth<T> = T & { authConfig?: AuthConfig };

export function buildEndpointWithAuth<Args, ResultType>(
    definition: QueryDefinition<Args, AbfBaseQueryFn, string, ResultType>,
    authConfig?: AuthConfig
): EndpointWithAuth<QueryDefinition<Args, AbfBaseQueryFn, string, ResultType>> {
    return {
        ...definition,
        authConfig
    };
}
