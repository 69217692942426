import { TrackingInfo } from "@bridgesplit/ui";
import { TransactionResult } from "@bridgesplit/react";
import {
    IncreaseCreditTransactionParams,
    LoopUnwindParams,
    WithdrawCollateralTransactionParams
} from "@bridgesplit/abf-react";
import { SellLoanParams } from "@bridgesplit/abf-sdk";

import {
    SubmitEarnData,
    SubmitLendVaultDepositParams,
    SubmitLendVaultWithdrawParams,
    SubmitRepayData,
    SubmitStrategyDepositParams,
    SubmitStrategyWithdrawParams,
    SubmitTopupData,
    TrackMarketBorrowData,
    TrackMarketLendData,
    TrackRefinanceData
} from "./mixpanel-data";

export enum TrackEvent {
    ViewYieldCurve = "ViewYieldCurve",
    ViewRateHistory = "ViewRateHistory",
    ViewLendPortfolio = "ViewLendPortfolio",
    SubmitMarketGuideBorrow = "SubmitMarketGuideBorrow",
    SubmitMarketGuideLend = "SubmitMarketGuideLend",
    SubmitMarketGuideLoops = "SubmitMarketGuideLoops",
    SubmitMarketGuideLendVault = "SubmitMarketGuideLendVault"
}

export enum TrackEventWithProps {
    SelectBorrowCollateral = "SelectBorrowCollateral",
    SelectBorrowPrincipal = "SelectBorrowPrincipal",
    OpenBorrowModal = "OpenBorrowModal",
    OpenLendModal = "OpenLendModal",
    ClaimCommunityReward = "ClaimCommunityReward",
    SelectBorrowDuration = "SelectBorrowDuration",
    SubmitDeleteStrategy = "SubmitDeleteStrategy"
}

export enum TrackTransactionEvent {
    SubmitMarketBorrowOrder = "SubmitMarketBorrowOrder",
    SubmitMarketLend = "SubmitMarketLend",
    SubmitRepay = "SubmitRepay",
    SubmitTopup = "SubmitTopup",
    SubmitRefinance = "SubmitRefinance",
    SubmitStrategyDeposit = "SubmitStrategyDeposit",
    SubmitStrategyWithdraw = "SubmitStrategyWithdraw",
    EditStrategy = "EditStrategy",
    CollateralWithdraw = "CollateralWithdraw",
    IncreasePrincipal = "IncreasePrincipal",
    SubmitSellLoan = "SubmitEarlyWithdraw",
    SubmitEarnOrder = "SubmitYieldLoopOrder",
    SubmitCloseEarnOrder = "SubmitCloseYieldLoopOrder",
    SubmitLendVaultDeposit = "SubmitLendVaultDeposit",
    SubmitLendVaultWithdraw = "SubmitLendVaultWithdraw"
}

export interface TransactionEventResponse {
    params?: object;
    res?: TransactionResult[];
    err?: string;
}

export type TrackTransactionEventType = {
    [TrackTransactionEvent.SubmitMarketBorrowOrder]: TrackMarketBorrowData;
    [TrackTransactionEvent.SubmitMarketLend]: TrackMarketLendData;
    [TrackTransactionEvent.SubmitRepay]: SubmitRepayData;

    [TrackTransactionEvent.SubmitRefinance]: TrackRefinanceData;
    [TrackTransactionEvent.EditStrategy]: TransactionEventResponse;
    [TrackTransactionEvent.CollateralWithdraw]: WithdrawCollateralTransactionParams;
    [TrackTransactionEvent.IncreasePrincipal]: IncreaseCreditTransactionParams;
    [TrackTransactionEvent.SubmitSellLoan]: SellLoanParams;
    [TrackTransactionEvent.SubmitEarnOrder]: SubmitEarnData;
    [TrackTransactionEvent.SubmitCloseEarnOrder]: LoopUnwindParams;

    [TrackTransactionEvent.SubmitTopup]: SubmitTopupData;
    [TrackTransactionEvent.SubmitStrategyDeposit]: SubmitStrategyDepositParams;
    [TrackTransactionEvent.SubmitStrategyWithdraw]: SubmitStrategyWithdrawParams;
    [TrackTransactionEvent.SubmitLendVaultDeposit]: SubmitLendVaultDepositParams;
    [TrackTransactionEvent.SubmitLendVaultWithdraw]: SubmitLendVaultWithdrawParams;
};
export type TrackTransactionData<T> = T extends keyof TrackTransactionEventType ? TrackTransactionEventType[T] : null;

type TokenSelect = { tokenSymbol: string };
type MarketPrincipal = { marketSymbol: string };
type RewardClaim = { rewardName: string };
type Duration = { duration: string };
type Strategy = { strategyId: string };

type EventPropsMap = {
    [TrackEventWithProps.SelectBorrowCollateral]: TokenSelect;
    [TrackEventWithProps.SelectBorrowPrincipal]: TokenSelect;
    [TrackEventWithProps.OpenBorrowModal]: MarketPrincipal;
    [TrackEventWithProps.OpenLendModal]: MarketPrincipal;
    [TrackEventWithProps.ClaimCommunityReward]: RewardClaim;
    [TrackEventWithProps.SelectBorrowDuration]: Duration;
    [TrackEventWithProps.SubmitDeleteStrategy]: Strategy;
};

export class AppTracking implements TrackingInfo {
    public eventName: string;
    public properties?: object;

    private constructor(eventName: string, properties?: object) {
        this.eventName = eventName;
        this.properties = properties;
    }

    /**
     * Create a new TrackingInfo with strict typing for the event name and properties
     */
    static fromProps(eventName: TrackEventWithProps, properties: EventPropsMap[TrackEventWithProps]) {
        return new AppTracking(eventName, properties);
    }

    /**
     * Create a new TrackingInfo with strict typing for the TransactionEventResponse shape
     */
    static fromTransaction(eventName: TrackTransactionEvent, properties: TransactionEventResponse) {
        return new AppTracking(eventName, properties);
    }

    /**
     * Create a new TrackingInfo with just the event name and undefined properties
     */
    static from(eventName: TrackEvent) {
        return new AppTracking(eventName);
    }
}
