import { createContext, useCallback, useContext, useMemo, useState } from "react";

import { useAbfFetches, useWhirlpoolsOffchainQuery } from "@bridgesplit/abf-react";
import { AddLiquidityQuote, OpenPositionQuote, RemoveLiquidityQuote } from "@orca-so/whirlpool-sdk";
import { Slippage } from "app/components/common";
import { TokenPositionExpanded, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { lamportsToUiAmount } from "@bridgesplit/utils";
import BN from "bn.js";

import {
    DepositForm,
    OpenPositionForm,
    WhirlpoolContextType,
    WhirlpoolContextValue,
    WhirlpoolPoolData,
    WhirlpoolPositionData,
    WithdrawForm
} from "./types";
import { getPoolData, getPositionData } from "./orca";

export const WhirlpoolContext = createContext<WhirlpoolContextType | null>(null);

export function useWhirlpoolContext() {
    const context = useContext(WhirlpoolContext);
    if (!context) {
        throw new Error("useWhirlpoolContext must be used within a WhirlpoolContext");
    }
    return context;
}

export function WhirlpoolProvider({ children, value }: { children: React.ReactNode; value: WhirlpoolContextValue }) {
    const [initialWithdrawQuote, setInitialWithdrawQuote] = useState<RemoveLiquidityQuote | undefined>(undefined);
    const [positionData, setPositionData] = useState<WhirlpoolPositionData | null | undefined>(undefined);
    const [poolData, setPoolData] = useState<WhirlpoolPoolData | null | undefined>(undefined);
    const [openPositionForm, setOpenPositionForm] = useState<OpenPositionForm>({});
    const [openPositionQuote, setOpenPositionQuote] = useState<OpenPositionQuote | undefined>(undefined);
    const [depositForm, setDepositForm] = useState<DepositForm>({});
    const [addLiquidityQuote, setAddLiquidityQuote] = useState<AddLiquidityQuote | undefined>(undefined);
    const [withdrawForm, setWithdrawForm] = useState<WithdrawForm>({});
    const [withdrawQuote, setWithdrawQuote] = useState<RemoveLiquidityQuote | undefined>(undefined);
    const [isFlippedPrice, setIsFlippedPrice] = useState(false);
    const { resetOrcaExternalApi, resetWhirlpoolPositions, resetUserAssetsApi } = useAbfFetches();

    // get the fresh whirlpool position by using up to date withdraw quote
    const whirlpoolPosition = useMemo((): WhirlpoolPositionExpanded => {
        const originalPosition = value.whirlpoolPosition;
        if (!initialWithdrawQuote) return originalPosition;

        const updateTokenBalance = (token: TokenPositionExpanded, amount: BN): TokenPositionExpanded => {
            const uiAmount = lamportsToUiAmount(amount.toNumber(), token.decimals);
            return { ...token, amount: uiAmount, usdAmount: token.usdPrice * uiAmount };
        };

        const tokenA = updateTokenBalance(originalPosition.tokenA, initialWithdrawQuote.estTokenA);
        const tokenB = updateTokenBalance(originalPosition.tokenB, initialWithdrawQuote.estTokenB);

        return {
            ...originalPosition,
            totalPrice: tokenA.usdAmount + tokenB.usdAmount,
            tokenA,
            tokenB
        };
    }, [value.whirlpoolPosition, initialWithdrawQuote]);
    const { data: whirlpoolsOffchain } = useWhirlpoolsOffchainQuery({ token: value.whirlpoolPosition.tokenA.mint });

    const whirlpoolOffchain = useMemo(() => {
        const whirlpoolAddress = value.whirlpoolPosition.whirlpoolAddress;
        return whirlpoolsOffchain?.data.find((whirlpool) => whirlpool.address === whirlpoolAddress);
    }, [value.whirlpoolPosition.whirlpoolAddress, whirlpoolsOffchain?.data]);

    const resetForms = useCallback(() => {
        setOpenPositionForm((prev) => ({
            ...prev,
            side: prev.side ?? "A",
            status: "silent-refetch"
        }));
        setWithdrawForm((prev) => ({
            ...prev,
            status: "silent-refetch"
        }));
        setDepositForm((prev) => ({
            ...prev,
            side: prev.side ?? "A",
            status: "silent-refetch"
        }));
    }, []);

    const slippageController = Slippage.useController({
        presetsUi: [1, 2.5, 5],
        defaultSlippageUi: 2.5,
        onEdit: resetForms
    });

    const forceFetchOrcaData = useCallback(async () => {
        const [poolData, positionData] = await Promise.all([
            getPoolData(whirlpoolPosition),
            getPositionData(whirlpoolPosition)
        ]);
        setPoolData(poolData);
        setPositionData(positionData?.positionData ?? null);
        setInitialWithdrawQuote(positionData?.quote);
    }, [whirlpoolPosition]);

    const reset = useCallback(async () => {
        await forceFetchOrcaData();
        resetForms();
        resetOrcaExternalApi();
        resetUserAssetsApi();
        resetWhirlpoolPositions();
    }, [forceFetchOrcaData, resetForms, resetOrcaExternalApi, resetUserAssetsApi, resetWhirlpoolPositions]);

    const poll = useCallback(async () => {
        resetForms();
        resetUserAssetsApi();
        await forceFetchOrcaData();
    }, [resetForms, forceFetchOrcaData, resetUserAssetsApi]);

    const contextValue: WhirlpoolContextType = {
        ...value,
        initialWithdrawQuote,
        setInitialWithdrawQuote,
        whirlpoolPosition,
        whirlpoolOffchain,
        positionData,
        setPositionData,
        depositForm,
        setDepositForm,
        poolData,
        setPoolData,
        openPositionForm,
        setOpenPositionForm,
        openPositionQuote,
        setOpenPositionQuote,
        isFlippedPrice,
        setIsFlippedPrice,
        reset,
        poll,
        slippageController,
        addLiquidityQuote,
        setAddLiquidityQuote,
        withdrawForm,
        setWithdrawForm,
        withdrawQuote,
        setWithdrawQuote
    };

    return <WhirlpoolContext.Provider value={contextValue}>{children}</WhirlpoolContext.Provider>;
}
