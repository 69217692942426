import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
    Column,
    FormInput,
    Icon,
    InputWrapper,
    Row,
    StatColumn,
    StatProps,
    Text,
    TextButton,
    TextColor,
    TextVariant,
    Tooltip,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import debounce from "lodash.debounce";
import { formatPercent, roundToDecimals } from "@bridgesplit/utils";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { Divider } from "@mui/material";

import { useWhirlpoolContext } from "./WhirlpoolContext";
import { HealthChange, TokenImage, TokenInputWithPrice } from "../../../common";
import { WhirlpoolAmountsForm } from "./types";
import {
    isMinTickIndex,
    isMaxTickIndex,
    useFormatWhirlpoolPercentChangeFromCurrent,
    useWhirlpoolFormatPrice,
    useWhirlpoolLtv
} from "./util";

export const PRICE_RANGE_DECIMALS = 8;

const USD_FOCUSED = "USD";
type HandleChangeType = (amount: number | undefined, side: "A" | "B") => void;
export function TokenInputs({
    handleChange,
    form,
    isFetching,
    getBelowInput,
    ratio
}: {
    handleChange: HandleChangeType;
    form: WhirlpoolAmountsForm;
    isFetching: boolean;
    getBelowInput: (side: "A" | "B") => React.ReactNode;
    ratio?: { ratioA: number; ratioB: number };
}) {
    const { whirlpoolPosition } = useWhirlpoolContext();

    const { tokenA, tokenB } = whirlpoolPosition;

    const [localAmount, setLocalAmount] = useState<number | undefined>(undefined);
    const [focused, setFocused] = useState<string | null>(null);
    const [localUsdValue, setLocalUsdValue] = useState<number | undefined>(undefined);

    const setValueDebounced = useMemo(() => debounce(handleChange, 200), [handleChange]);

    const derivedUsdValue = useMemo(() => {
        if (form.tokenAAmount === undefined || form.tokenBAmount === undefined) return undefined;

        return roundToDecimals(form.tokenAAmount * tokenA.usdPrice + form.tokenBAmount * tokenB.usdPrice, 2);
    }, [form.tokenAAmount, form.tokenBAmount, tokenA.usdPrice, tokenB.usdPrice]);

    useEffect(() => {
        return () => {
            setValueDebounced.cancel();
        };
    }, [setValueDebounced]);

    const inputs = [
        ["A", "tokenAAmount", tokenA],
        ["B", "tokenBAmount", tokenB]
    ] as const;

    return (
        <Column spacing={2}>
            {inputs.map(([side, tokenAmountKey, token]) => {
                return (
                    <TokenInputWithPrice
                        key={side}
                        formNumberInputProps={{
                            onFocus: () => {
                                setFocused(side);
                                setLocalAmount(form[tokenAmountKey]);
                            },
                            onBlur: () => setFocused(null)
                        }}
                        loading={isFetching && form.side === side}
                        value={focused === side ? localAmount : form[tokenAmountKey]}
                        setValue={(value) => {
                            if (focused !== side) return;
                            setLocalAmount(value);
                            setValueDebounced(value, side);
                        }}
                        metadata={token.metadata}
                        selectToken={null}
                        belowInput={getBelowInput(side)}
                    />
                );
            })}
            {ratio && (
                <InputWrapper>
                    <Text color="caption">$</Text>
                    <FormInput
                        fullWidth
                        onFocus={() => {
                            setFocused(USD_FOCUSED);
                            setLocalUsdValue(derivedUsdValue);
                        }}
                        onBlur={() => setFocused(null)}
                        InputProps={{
                            sx: {
                                color: (theme) =>
                                    focused && focused !== USD_FOCUSED ? theme.palette.text.secondary : undefined
                            }
                        }}
                        sx={{ "& fieldset": { border: "none" } }}
                        value={focused === USD_FOCUSED ? localUsdValue : derivedUsdValue}
                        setValue={(value) => {
                            if (focused !== USD_FOCUSED) return;
                            setLocalUsdValue(value);
                            if (value === undefined) return;

                            // always set max A for consistent behavior
                            if (ratio.ratioA) {
                                const tokenAAmountUsd = value * ratio.ratioA;
                                const amountTokenA = tokenAAmountUsd / tokenA.usdPrice;
                                setValueDebounced(amountTokenA, "A");
                            }
                            // if position is unbalanced, use max B
                            else {
                                const tokenBAmountUsd = value * ratio.ratioB;
                                const amountTokenB = tokenBAmountUsd / tokenB.usdPrice;
                                setValueDebounced(amountTokenB, "B");
                            }
                        }}
                        variant="number"
                    />
                    <Text color="caption">Total</Text>
                </InputWrapper>
            )}
        </Column>
    );
}

export function useHealthChangeStats(data: ReturnType<typeof useWhirlpoolLtv>): StatProps[] {
    const { previousHealth, health, ltv } = data;

    return [
        {
            caption: "LTV",
            value:
                previousHealth.ltv !== ltv
                    ? [formatPercent(previousHealth.ltv), formatPercent(ltv)]
                    : formatPercent(ltv)
        },
        {
            caption: "Health",
            value: <HealthChange currentHealth={previousHealth.health} previousHealth={health} />
        }
    ];
}

export function useTokenAmountStats({
    tokenAChange,
    tokenBChange
}: {
    tokenAChange: number;
    tokenBChange: number;
}): StatProps[] {
    const {
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();
    return [
        {
            caption: BsMetaUtil.getSymbol(tokenA.metadata),
            value: [
                BsMetaUtil.formatAmount(tokenA.metadata, tokenA.amount, { hideSymbol: true }),
                BsMetaUtil.formatAmount(tokenA.metadata, tokenA.amount + tokenAChange, { hideSymbol: true })
            ]
        },
        {
            caption: BsMetaUtil.getSymbol(tokenB.metadata),
            value: [
                BsMetaUtil.formatAmount(tokenB.metadata, tokenB.amount, { hideSymbol: true }),
                BsMetaUtil.formatAmount(tokenB.metadata, tokenB.amount + tokenBChange, { hideSymbol: true })
            ]
        }
    ];
}

export function BalanceAndMax({
    side,
    setAmount,
    variant = "body2",
    depositRatio,
    balanceA,
    balanceB,
    tooltipContent,
    inputAmount
}: {
    inputAmount: number | undefined;
    side: "A" | "B";
    setAmount: (amount: number | undefined, side: "A" | "B") => void;
    variant?: TextVariant;
    balanceA: number;
    balanceB: number;
    depositRatio: { ratioA: number; ratioB: number } | undefined;
    tooltipContent?: React.ReactNode;
}) {
    const { error } = useAppPalette();
    const {
        whirlpoolPosition: { tokenA, tokenB },
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();
    const slippageMultiplier = 1 - slippagePercentDecimals;

    const { maxA, maxB } = useMemo(() => {
        let maxA = balanceA;
        let maxB = balanceB;

        if (!balanceA || !balanceB || !depositRatio) return { maxA, maxB };

        if (!depositRatio.ratioA) return { maxA: 0, maxB };
        if (!depositRatio.ratioB) return { maxA, maxB: 0 };

        const maxUsdFromA = (balanceA * tokenA.usdPrice) / depositRatio.ratioA;
        const maxUsdFromB = (balanceB * tokenB.usdPrice) / depositRatio.ratioB;
        const maxUsd = Math.min(maxUsdFromA, maxUsdFromB) * slippageMultiplier;

        if (maxUsdFromA > maxUsdFromB) {
            const maxTokenAUsd = maxUsd * depositRatio.ratioA;
            maxA = maxTokenAUsd / tokenA.usdPrice;
            maxB = slippageMultiplier * balanceB;
        } else {
            const maxTokenBUsd = maxUsd * depositRatio.ratioB;
            maxB = maxTokenBUsd / tokenB.usdPrice;
            maxA = slippageMultiplier * balanceA;
        }

        return { maxA, maxB };
    }, [balanceA, balanceB, depositRatio, slippageMultiplier, tokenA.usdPrice, tokenB.usdPrice]);

    const setMax = useCallback(() => {
        // always set max for A to prevent small discrepancies between max A and max B
        if (maxA) {
            setAmount(maxA, "A");
        } else {
            setAmount(maxB, "B");
        }
    }, [maxA, maxB, setAmount]);

    const token = side === "A" ? tokenA : tokenB;
    const balance = side === "A" ? balanceA : balanceB;
    const insufficientBalance = !!inputAmount && inputAmount > balance;
    const max = side === "A" ? maxA : maxB;

    return (
        <Row spaceBetween spacing={2}>
            <Row spacing={1}>
                <Tooltip
                    reverseColors
                    title={
                        <Column sx={{ minWidth: "200px" }} p={1} spacing={1}>
                            {tooltipContent}

                            <StatColumn
                                captionVariant={variant}
                                variant={variant}
                                stats={[
                                    {
                                        caption: "Total balance",
                                        value: BsMetaUtil.formatAmount(token.metadata, balance)
                                    }
                                ]}
                            />
                            <Divider />
                            <StatColumn
                                captionVariant={variant}
                                variant={variant}
                                stats={[
                                    {
                                        caption: "Max deposit",
                                        value: BsMetaUtil.formatAmount(token.metadata, max)
                                    }
                                ]}
                            />
                        </Column>
                    }
                >
                    <Text color="caption" variant={variant}>
                        Max {BsMetaUtil.formatAmount(token.metadata, max)}
                        <Icon type="tooltip" />
                    </Text>
                </Tooltip>
                {insufficientBalance && (
                    <TooltipText icon={false} helpText="Insufficient balance" variant={variant}>
                        <Icon sx={{ color: error }} type="warning" />
                    </TooltipText>
                )}
            </Row>

            <TextButton
                disabled={max === 0}
                color={max === 0 ? "disabled" : "secondary"}
                variant={variant}
                onClick={setMax}
            >
                Max
            </TextButton>
        </Row>
    );
}

export function TickWithPercentChange({
    tick,
    variant,
    tickLower,
    tickUpper
}: {
    tick: number | undefined;
    variant?: TextVariant;
    tickLower: number | undefined;
    tickUpper: number | undefined;
}) {
    const { poolData } = useWhirlpoolContext();

    const formatPrice = useWhirlpoolFormatPrice();
    const formatPercentChange = useFormatWhirlpoolPercentChangeFromCurrent();

    const isLoading = tick === undefined || tickLower === undefined || tickUpper === undefined || !poolData;

    const currentTick = poolData?.tickCurrentIndex;

    if (tick !== undefined && poolData) {
        if (isMaxTickIndex(tick, poolData.tickSpacing)) {
            return <Text variant={variant}>Infinity </Text>;
        }
        if (isMinTickIndex(tick, poolData?.tickSpacing)) {
            return <Text variant={variant}>0 </Text>;
        }
    }

    return (
        <>
            <Text variant={variant} loadingWidth="40px" loading={isLoading}>
                {formatPrice(tick, 6)}
            </Text>
            <Text variant="caption" color={"caption"} loadingWidth="40px" loading={isLoading}>
                {formatPercentChange(tick, currentTick)}
            </Text>
        </>
    );
}

export function TokensRow({
    tokenAAmount,
    tokenBAmount,
    variant,
    color,
    showLogo
}: {
    tokenAAmount: number | undefined;
    tokenBAmount: number | undefined;
    variant?: TextVariant;
    color?: TextColor;
    showLogo?: boolean;
}) {
    const {
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();

    const tokens = [[tokenA, tokenAAmount] as const, [tokenB, tokenBAmount] as const];
    return (
        <Row spacing={0.5}>
            {tokens.map(([{ metadata }, amount], index) => (
                <Fragment key={index}>
                    <Row spacing={0.5}>
                        {showLogo && <TokenImage size="sm" metadata={metadata} />}
                        <Text
                            color={color}
                            loadingWidth="40px"
                            variant={variant}
                            loading={!metadata || amount === undefined}
                        >
                            {BsMetaUtil.formatAmount(metadata, amount)}
                        </Text>
                    </Row>
                    {index < tokens.length - 1 && (
                        <Text variant={variant} color="disabled">
                            +
                        </Text>
                    )}
                </Fragment>
            ))}
        </Row>
    );
}
