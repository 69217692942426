import {
    BsMetaUtil,
    StrategyExpanded,
    useActiveWallet,
    useDepositStrategyTransaction,
    useWithdrawStrategyTransaction
} from "@bridgesplit/abf-react";
import { DepositStrategyTxnParams, WithdrawStrategyTxnParams } from "@bridgesplit/abf-sdk";
import { MISSING_PARAM_ERROR, Result, uiAmountToLamports } from "@bridgesplit/utils";
import { useTransactionSender } from "app/components/transactions";
import { TrackTransactionEvent } from "app/types";

export function useStrategyDeposit() {
    const send = useTransactionSender();

    const { activeWallet } = useActiveWallet();
    const deposit = useDepositStrategyTransaction();

    return async ({ amount, strategy }: { amount: number | undefined; strategy: StrategyExpanded | undefined }) => {
        if (!strategy || !amount || !activeWallet) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const description = `Depositing ${BsMetaUtil.formatAmount(strategy.principalMetadata, amount)}`;

        const depositParams: DepositStrategyTxnParams = {
            strategy: strategy.strategy.address,
            amount: uiAmountToLamports(amount, strategy.principalMetadata.decimals)
        };
        return await send(deposit, depositParams, {
            description,
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitStrategyDeposit,
                params: {
                    strategyAddress: strategy.strategy.address,
                    amount: depositParams.amount,
                    uiAmount: amount
                }
            }
        });
    };
}
export function useStrategyWithdraw() {
    const send = useTransactionSender();

    const { activeWallet } = useActiveWallet();
    const withdraw = useWithdrawStrategyTransaction();

    return async ({
        amount,
        strategy,
        withdrawAll
    }: {
        amount: number | undefined;
        strategy: StrategyExpanded | undefined;
        withdrawAll: boolean;
    }) => {
        if (!strategy || !amount || !activeWallet) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const description = `Withdrawing ${BsMetaUtil.formatAmount(strategy.principalMetadata, amount)}`;

        const withdrawParams: WithdrawStrategyTxnParams = {
            strategy: strategy.strategy.address,
            amount: uiAmountToLamports(amount, strategy.principalMetadata.decimals),
            withdrawAll
        };
        return await send(withdraw, withdrawParams, {
            description,
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitStrategyWithdraw,
                params: {
                    strategyAddress: strategy.strategy.address,
                    amount: withdrawParams.amount,
                    uiAmount: amount,
                    withdrawAll
                }
            }
        });
    };
}
