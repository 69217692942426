import { ReactNode } from "react";

import {
    DurationType,
    StrategyDuration,
    TokenListTag,
    formatDurationWithTypeShorthand,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";
import {
    BsMetaUtil,
    LendingVaultExpanded,
    LoopExpanded,
    MarketGuideMode,
    RoleView,
    serializeStrategyDuration
} from "@bridgesplit/abf-react";
import {
    AccountBalanceOutlined,
    AppsOutlined,
    AutoAwesome,
    BusinessCenterOutlined,
    CurrencyBitcoinOutlined,
    CurrencyExchangeOutlined,
    HowToVoteOutlined,
    LayersOutlined,
    LocalAtmOutlined,
    LockOutlined,
    RocketLaunchOutlined
} from "@mui/icons-material";
import {
    BORDER_RADIUS_ROUNDED,
    Button,
    ButtonProps,
    Icon,
    MEDIA,
    SolanaSvg,
    TextSkeleton,
    ToggleButtons,
    TooltipText
} from "@bridgesplit/ui";
import { encodeQueryParams } from "@bridgesplit/react";
import { MARKET_SLUG, MARKETS_SLUG } from "app/constants";
import { AppDialog, AppParams, useAppDialog } from "app/utils";
import { USDC_SYMBOL } from "@bridgesplit/utils";
import { useMediaQuery } from "@mui/material";

export function getMarketPath({
    token,
    collateralMint,
    term,
    view,
    navigateTo = "detail"
}: {
    view: RoleView | undefined;
    token: TokenListMetadata | { symbol: string } | undefined;
    collateralMint?: string;
    term?: { duration: number; durationType: DurationType };
    navigateTo?: "detail" | "markets";
}) {
    const slug = MARKET_SLUG;
    if (!token) return slug;

    const queryParams = encodeQueryParams({
        [AppParams.CollateralMint]:
            "assetMint" in token && collateralMint === token.assetMint ? undefined : collateralMint,
        [AppParams.Term]: term ? formatDurationWithTypeShorthand(term.duration, term.durationType) : undefined,
        [AppParams.BorrowLendRole]: view === undefined ? view : view.toLowerCase()
    });
    if (navigateTo === "markets") {
        return `${MARKETS_SLUG}${queryParams}`;
    }

    const symbol = "symbol" in token ? token.symbol : BsMetaUtil.getSymbol(token);
    return `${MARKET_SLUG}/${symbol}${queryParams}`;
}

export const getDefaultMarketPath = (view: RoleView) => getMarketPath({ view, token: { symbol: USDC_SYMBOL } });

type TokenTagMeta = {
    icon: JSX.Element;
    label: string;
    shortLabel: string;
    tag: TokenListTag;
    sortPosition: number;
    hideInCategories?: boolean;
};
const TOKEN_TAG_METADATA: Record<TokenListTag, Omit<TokenTagMeta, "tag" | "sortPosition">> = {
    [TokenListTag.All]: { icon: <AppsOutlined />, label: "All", shortLabel: "All", hideInCategories: true },
    [TokenListTag.Featured]: {
        icon: <AutoAwesome />,
        label: "Featured",
        shortLabel: "Featured",
        hideInCategories: true
    },
    [TokenListTag.Native]: { icon: <SolanaSvg />, label: "Native", shortLabel: "Native" },
    [TokenListTag.Stables]: { icon: <LocalAtmOutlined />, label: "Stable coins", shortLabel: "Stables" },
    [TokenListTag.LST]: { icon: <LayersOutlined />, label: "Liquid staking", shortLabel: "LSTs" },
    [TokenListTag.Bridged]: { icon: <CurrencyBitcoinOutlined />, label: "Bridged", shortLabel: "Bridged" },
    [TokenListTag.Governance]: { icon: <HowToVoteOutlined />, label: "Governance", shortLabel: "Governance" },
    [TokenListTag.Staked]: { icon: <BusinessCenterOutlined />, label: "Staked", shortLabel: "Staked" },
    [TokenListTag.Memes]: { icon: <RocketLaunchOutlined />, label: "Meme coins", shortLabel: "Memes" },
    [TokenListTag.Utility]: { icon: <BusinessCenterOutlined />, label: "Utility", shortLabel: "Utility" },
    [TokenListTag.DeFi]: { icon: <LayersOutlined />, label: "DeFi", shortLabel: "DeFi" },
    [TokenListTag.Treasuries]: { icon: <AccountBalanceOutlined />, label: "Treasuries", shortLabel: "Treasuries" },
    [TokenListTag.LP]: { icon: <CurrencyExchangeOutlined />, label: "LP Tokens", shortLabel: "LPs" },
    [TokenListTag.Misc]: { icon: <LockOutlined />, label: "Miscellaneous", shortLabel: "Misc" }
};

export function getTokenTagMetadata(tag: TokenListTag | undefined): TokenTagMeta | undefined {
    if (!tag || !(tag in TOKEN_TAG_METADATA)) return undefined;
    return {
        ...TOKEN_TAG_METADATA[tag],
        tag,
        sortPosition: Object.keys(TOKEN_TAG_METADATA).findIndex((t) => t === tag)
    };
}

export function OpenHowItWorks({
    mode,
    variant = "outlined",
    loopExpanded,
    vaultExpanded
}: {
    mode: MarketGuideMode;
    variant?: ButtonProps["variant"];
    loopExpanded?: LoopExpanded;
    vaultExpanded?: LendingVaultExpanded;
}) {
    const { open: openDialog } = useAppDialog();

    const handleClick = () =>
        openDialog(AppDialog.MarketGuide, {
            mode,
            isForced: false,
            loopExpanded: loopExpanded,
            vaultExpanded: vaultExpanded
        });

    const isMobile = useMediaQuery(MEDIA.SM.below);
    return (
        <Button
            variant={variant}
            onClick={handleClick}
            sx={{
                borderRadius: BORDER_RADIUS_ROUNDED,
                px: variant === "text" ? 1 : undefined,
                py: 0.5,
                minHeight: "30px"
            }}
            textProps={{
                svgColor: "caption"
            }}
            width="max-content"
        >
            <Icon type="help" />
            {isMobile ? "Guidance" : "How it works"}
        </Button>
    );
}

export function BorrowCapWarning({
    children,
    borrowCapWarning
}: {
    children: ReactNode;
    borrowCapWarning: string | undefined | null;
}) {
    return (
        <TooltipText variant="body1" icon={false} helpText={borrowCapWarning ?? undefined} svgColor="error">
            {!!borrowCapWarning && <Icon type="warning" />}
            {children}
        </TooltipText>
    );
}

export function SelectStrategyDuration({
    strategyDurations,
    strategyDuration,
    setStrategyDuration
}: {
    strategyDurations: StrategyDuration[] | undefined;
    strategyDuration: StrategyDuration | undefined;
    setStrategyDuration: (strategyDuration: StrategyDuration) => void;
}) {
    return (
        <ToggleButtons
            allowNull
            options={
                strategyDurations?.map((preset) => ({
                    value: serializeStrategyDuration(preset),
                    label: formatDurationWithTypeShorthand(preset.duration, preset.durationType)
                })) ?? Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> })
            }
            value={strategyDuration ? serializeStrategyDuration(strategyDuration) : ""}
            setValue={(serializedStrategyDuration) => {
                const strategyDuration = strategyDurations?.find(
                    (d) => serializeStrategyDuration(d) === serializedStrategyDuration
                );
                if (strategyDuration) {
                    setStrategyDuration(strategyDuration);
                }
            }}
        />
    );
}
