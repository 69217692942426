import { useState } from "react";

import { Column, DialogWrapper, TabsSelect } from "@bridgesplit/ui";
import { useAppDialog, AppDialog } from "app/utils";

import StrategyDeposit from "../user/StrategyDeposit";
import StrategyWithdraw from "../user/StrategyWithdraw";

export default function StrategyManageEscrowDialog() {
    const { getData } = useAppDialog();
    const data = getData(AppDialog.StrategyManageEscrow);
    const [sideState, setSide] = useState<"deposit" | "withdraw" | undefined>(undefined);
    const side = sideState ?? data?.side ?? "deposit";

    return (
        <DialogWrapper spacing={0} padding={0}>
            <TabsSelect
                wrapperSx={{ pt: 1 }}
                value={side}
                setValue={(s) => setSide(s as "deposit" | "withdraw")}
                options={[
                    { value: "deposit", label: "Deposit" },
                    { value: "withdraw", label: "Withdraw" }
                ]}
            />
            <Column spacing={3} p={2}>
                {side === "deposit" && <StrategyDeposit strategy={data?.strategy} />}
                {side === "withdraw" && <StrategyWithdraw strategy={data?.strategy} />}
            </Column>
        </DialogWrapper>
    );
}
