import { SortDirection } from "@bridgesplit/utils";
import { ParsedTransactionInstruction, BaseRouteStep } from "@loopscale/pandora";

import { ExpectedLoanValues, StrategyDuration } from "../types";

export interface LoopVaultInfo {
    collateralMint: string;
    principalMint: string;
    collateralDeposited: number;
    collateralApyPct: number;
    maxLeverage: number;
    name: string;
    maxLeveragedApyPct: number;
    metadata?: MeteoraLoopMetadata | null;
    principalAmountAvailable: number;
    routeType?: LoopRouteType | null;
    tags?: LoopTag[];
    quoteFetchParams: QuoteFetchParams;
    maxCollateralAmount?: number;
    aumLimit?: number;
    feVisible: boolean;
}

// This will create a union type that preserves the discriminated union structure
// but removes the specified properties from each variant
export type QuoteFetchParams = {
    [K in BaseRouteStep["type"]]: Omit<Extract<BaseRouteStep, { type: K }>, "inputMint" | "outputMint">;
}[BaseRouteStep["type"]];

export interface JupQuoteFetchParams {
    onlyDirectRoutes?: boolean;
    maxAccounts?: number;
    dexes?: string[];
}

export interface MeteoraLoopMetadata {
    aTokenVault: string;
    bTokenVault: string;
    lpMint: string;
    lutAddress: string;
    lutAddresses: string;
    poolAddress: string;
    tokenAMint: string;
    tokenBMint: string;
    vaultA: string;
    vaultALp: string;
    vaultALpMint: string;
    vaultB: string;
    vaultBLp: string;
    vaultBLpMint: string;
}

export enum LoopTag {
    LST = "LST",
    LRT = "LRT",
    LeveragedStaking = "Leveraged staking",
    Stables = "Stables",
    DexLp = "DEX LP",
    FixedYield = "Fixed Yield",
    PerpsLp = "Perps LP",
    LongCrypto = "Long Crypto",
    ShortSol = "Short SOL",
    ShortBtc = "Short BTC",
    ShortEth = "Short ETH"
}

export enum LoopRouteType {
    Jup,
    Meteora,
    Stabble,
    Exponent,
    Flash,
    Adrena,
    Ratex
}

export interface LoopInfoParams {
    loopVaults?: string[];
    showHidden: boolean;
}

export interface UserLoopFilter {
    principalMint?: string;
    collateralMint?: string;
    active: boolean;
    sortDirection?: SortDirection;
    sortType?: LoopPositionSortType;
    page: number;
    pageSize: number;
}

export enum LoopPositionSortType {
    StartTime,
    LeverageMultiplier,
    NetApy,
    NetPositionValue,
    HealthRatio
}

export interface UserLoopInfoParams extends UserLoopFilter {
    loopVaults?: string[];
    loanVaults?: string[];
}

export interface LoopYieldParams {
    collateral: string[];
}

export interface LoopWindTransactionInput {
    vaultIdentifier: string;
    leverageMultiplier: number;
    strategyAddress: string;
    strategyDuration: StrategyDuration;
    collateralAmount: number;
    principalRequested: number;
    expectedStartingLtv: number;
    expectedLoanValues: ExpectedLoanValues;
    swapIx: ParsedTransactionInstruction[];
    swapLuts: string[];
}

export interface LoopUnwindTransactionInput {
    loanAddress: string;
    principalMint: string;
    collateralMint: string;
    swapIx: ParsedTransactionInstruction[];
    swapLuts: string[];
}

export interface UserLoopInfo {
    loanAddress: string;
    leverageMultiplier: number;
    initialCollateralAmount: number;
    initialCollateralPrice: number;
    totalCollateralDepositedAmount: number; // leveraged amount + additional deposits (in token terms)
    loopVaultIdentifier: string;
    netApy?: number;
    additionalCollateralDepositedAmount: number;
    additionalCollateralDepositsUsd: number;
    totalPaidUsd: number;
    endPrincipalUsdPrice?: number;
    endCollateralUsdPrice?: number;
    positionStartTime: number;
    healthRatio?: number;
    netPositionValue: number;
    index: number;
    totalCount: number;
}
