import { useCallback, useEffect, useMemo } from "react";

import { useBalanceChecker } from "app/components/common";
import { Result, roundDownToDecimals } from "@bridgesplit/utils";
import BN from "bn.js";
import { getZcLedger } from "@bridgesplit/abf-react";

import { useWhirlpoolContext } from "../WhirlpoolContext";
import { getSlippageTolerance, useWhirlpoolLtv, useWhirlpoolRefinanceInfo } from "../util";
import { orca } from "../orca";

export function useWithdrawWhirlpoolBalances() {
    const {
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();
    const { tokenAAmount, tokenBAmount, maxWithdrawPercent } = useWithdrawAmounts();
    const updateAmount = useUpdateWithdrawAmount();

    const A = useBalanceChecker({
        mint: tokenA.metadata.mint,
        amount: tokenAAmount,
        customBalance: roundDownToDecimals(tokenA.amount * maxWithdrawPercent, tokenA.metadata.decimals),
        setMax: (max) => updateAmount(max, "A")
    });

    const B = useBalanceChecker({
        mint: tokenB.metadata.mint,
        amount: tokenBAmount,
        customBalance: roundDownToDecimals(tokenB.amount * maxWithdrawPercent, tokenB.metadata.decimals),
        setMax: (max) => updateAmount(max, "B")
    });

    return { A, B };
}

export function useWithdrawAmounts() {
    const {
        withdrawForm,
        loanExpanded,
        whirlpoolPosition: { totalPrice, tokenA, tokenB }
    } = useWhirlpoolContext();

    const firstLedger = getZcLedger(loanExpanded);
    const debt = firstLedger?.ledgerDebt;

    const { refinanceInfo } = useWhirlpoolRefinanceInfo();
    const ltv = refinanceInfo?.ltv;

    const tokenAAmount = withdrawForm.percentToWithdraw
        ? tokenA.amount * withdrawForm.percentToWithdraw
        : withdrawForm.percentToWithdraw;
    const tokenBAmount = withdrawForm.percentToWithdraw
        ? tokenB.amount * withdrawForm.percentToWithdraw
        : withdrawForm.percentToWithdraw;

    const currentUsdValue = totalPrice;
    const minCollateralUsd = ltv ? (debt?.total ?? 0) / ltv : 0;

    const maxWithdrawPercent = Math.max(currentUsdValue - minCollateralUsd, 0) / currentUsdValue;
    return { tokenAAmount, tokenBAmount, maxWithdrawPercent };
}

export function useUpdateWithdrawAmount() {
    const {
        whirlpoolPosition: { tokenA, tokenB },
        setWithdrawForm
    } = useWhirlpoolContext();

    return useCallback(
        (amount: number | undefined, side: "A" | "B") => {
            setWithdrawForm((prev) => {
                const token = side === "A" ? tokenA : tokenB;
                const previousAmount = token.amount * (prev.percentToWithdraw ?? 0);
                if (previousAmount === amount) return prev;
                const percentToWithdraw = amount ? amount / token.amount : amount;
                return { ...prev, percentToWithdraw, side, status: "refetch-needed" };
            });
        },
        [setWithdrawForm, tokenA, tokenB]
    );
}

export function useWithdrawOrcaHooks() {
    useMaintainWithdrawQuote();
}

function useMaintainWithdrawQuote() {
    const {
        whirlpoolPosition,
        poolData,
        whirlpoolOffchain,
        withdrawForm,
        positionData,
        setWithdrawForm,
        setWithdrawQuote,
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();
    const fetchYieldData = useCallback(async () => {
        try {
            if (!poolData || !whirlpoolOffchain || !positionData) return;

            const precision = 10_000;
            const bpsToWithdraw = (withdrawForm.percentToWithdraw ?? 0) * precision;
            const deltaLiquidity = positionData.liquidity.mul(new BN(bpsToWithdraw)).div(new BN(precision));

            if (!deltaLiquidity) return;
            setWithdrawForm((prev) => ({
                ...prev,
                status: prev.status === "silent-refetch" ? prev.status : "refetching"
            }));

            const quote = await orca.position.getRemoveLiquidityQuote({
                positionAddress: whirlpoolPosition.position.address,
                liquidity: new BN(deltaLiquidity),
                refresh: false,
                slippageTolerance: getSlippageTolerance(slippagePercentDecimals)
            });

            setWithdrawQuote(quote);
        } catch (error) {
            Result.err(error);
            setWithdrawForm((prev) => ({ ...prev, status: "error" }));
        }
    }, [
        poolData,
        whirlpoolOffchain,
        positionData,
        withdrawForm.percentToWithdraw,
        setWithdrawForm,
        whirlpoolPosition.position.address,
        slippagePercentDecimals,
        setWithdrawQuote
    ]);

    useEffect(() => {
        if (withdrawForm.status && ["refetch-needed", "silent-refetch"].includes(withdrawForm.status)) {
            fetchYieldData();
        }
    }, [fetchYieldData, withdrawForm.status]);

    return { fetchYieldData };
}

export function useWithdrawLtv() {
    const wp = useWhirlpoolUsdValue();
    return useWhirlpoolLtv(wp?.positionUsdValue);
}

function useWhirlpoolUsdValue() {
    const { whirlpoolPosition, poolData, whirlpoolOffchain } = useWhirlpoolContext();
    const { tokenAAmount, tokenBAmount } = useWithdrawAmounts();

    return useMemo(() => {
        if (!poolData || !whirlpoolOffchain) return undefined;

        const remainingTokenAAmount = whirlpoolPosition.tokenA.amount - (tokenAAmount ?? 0);
        const remainingTokenBAmount = whirlpoolPosition.tokenB.amount - (tokenBAmount ?? 0);

        const tokenAUsd = remainingTokenAAmount * whirlpoolPosition.tokenA.usdPrice;
        const tokenBUsd = remainingTokenBAmount * whirlpoolPosition.tokenB.usdPrice;

        const positionUsdValue = tokenAUsd + tokenBUsd;
        return { positionUsdValue, tokenAUsd, tokenBUsd };
    }, [
        poolData,
        whirlpoolOffchain,
        whirlpoolPosition.tokenA.amount,
        whirlpoolPosition.tokenA.usdPrice,
        whirlpoolPosition.tokenB.amount,
        whirlpoolPosition.tokenB.usdPrice,
        tokenAAmount,
        tokenBAmount
    ]);
}
