import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { StrategyDuration } from "@bridgesplit/abf-sdk";

import {
    GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE,
    GET_BEST_LOOP_QUOTE_ROUTE,
    GET_BEST_QUOTE_ROUTE,
    GET_MARKET_BORROW_CAPS_ROUTE,
    GET_MARKET_PRINCIPAL_STATS_ROUTE,
    GET_MARKET_STRATEGY_STATS_ROUTE,
    GET_MAX_QUOTE_ROUTE,
    GET_RATE_HISTORY_ROUTE,
    MARKET_DURATIONS,
    MARKET_QUOTE_FOR_APY_ROUTE,
    MARKET_QUOTE_ROUTE,
    ROUTE_GET_REFINANCE_INFO,
    GET_REFRESH_MARKET_STRATEGY_STATS_ROUTE,
    ROUTE_REFRESH_MARKET_TVL_STATS,
    GET_MIN_PRINCIPAL_ROUTE
} from "../constants";
import { abfSerializeQueryArgs, marketsPublicBaseQuery } from "./util";
import {
    BestLoopQuote,
    BestLoopQuoteQuery,
    BestQuote,
    BestQuoteFilter,
    BorrowCap,
    CollateralQuoteFilter,
    CollateralQuoteFilterForApy,
    HistoricalMarketRate,
    MarketPrincipalStats,
    MarketStratResponse,
    MarketsFilter,
    MaxQuote,
    MaxQuoteRequest,
    OrderbookQuote,
    OrderbookQuoteForApy,
    PrincipalMarketStrategyStats,
    RateMarketType,
    RatesHistoryParams,
    RawMarketStratResponse,
    RawMaxQuote,
    RawPrincipalMarketStrategyStats,
    RefinanceInfoParams,
    RefinanceInfoResponse
} from "../types";
import { deserializeStrategyDurationOrUndefined } from "../utils";

const MARKETS_PUBLIC_TAG = "MarketsPublic";

export const marketsPublicApi = createApi({
    reducerPath: "marketsPublicApi",
    baseQuery: marketsPublicBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [MARKETS_PUBLIC_TAG],
    endpoints: (builder) => ({
        strategyDurations: builder.query<StrategyDuration[], void>({
            // market durations are hardcoded for now to reduce latency
            queryFn() {
                return {
                    data: MARKET_DURATIONS
                };
            }
        }),
        marketQuotes: builder.query<OrderbookQuote[], CollateralQuoteFilter>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: MARKET_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        marketQuotesForApy: builder.query<OrderbookQuoteForApy[], CollateralQuoteFilterForApy>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: MARKET_QUOTE_FOR_APY_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        refinanceInfo: builder.query<RefinanceInfoResponse, RefinanceInfoParams>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: ROUTE_GET_REFINANCE_INFO,
                    method: "POST",
                    body
                };
            }
        }),
        allMarketPrincipalStats: builder.query<MarketPrincipalStats[], void>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query() {
                return {
                    url: GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE,
                    params: { force: true },
                    method: "GET"
                };
            }
        }),
        marketPrincipalStatsByMint: builder.query<MarketPrincipalStats, string>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(mint) {
                return {
                    url: `${GET_MARKET_PRINCIPAL_STATS_ROUTE}/${mint}`,
                    method: "GET"
                };
            }
        }),
        maxQuotes: builder.query<MaxQuote[], MaxQuoteRequest>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_MAX_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: convertRawQuote
        }),
        bestQuotes: builder.query<BestQuote[], BestQuoteFilter>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_BEST_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: convertRawBestQuotes
        }),
        loopBestQuote: builder.query<BestLoopQuote | null, BestLoopQuoteQuery>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_BEST_LOOP_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        //Returns a map of principal mint to minimum amount.
        presetPrincipalByMints: builder.query<Record<string, number>, string[]>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_MIN_PRINCIPAL_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        borrowCaps: builder.query<BorrowCap[], string[]>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(args) {
                return {
                    url: GET_MARKET_BORROW_CAPS_ROUTE,
                    method: "POST",
                    body: args
                };
            }
        }),
        marketStats: builder.query<MarketStratResponse, MarketsFilter>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(args) {
                return {
                    url: GET_MARKET_STRATEGY_STATS_ROUTE,
                    method: "POST",
                    body: args
                };
            },
            transformResponse: (raw: RawMarketStratResponse) => convertToMarketStratResponse(raw)
        }),
        refreshMarketStats: builder.query<MarketStratResponse, MarketsFilter>({
            query(args) {
                return {
                    url: GET_REFRESH_MARKET_STRATEGY_STATS_ROUTE,
                    method: "POST",
                    body: args
                };
            },
            transformResponse: (raw: RawMarketStratResponse) => convertToMarketStratResponse(raw)
        }),
        refreshMarketTvl: builder.query<void, void>({
            query() {
                return {
                    url: ROUTE_REFRESH_MARKET_TVL_STATS,
                    method: "POST"
                };
            }
        }),
        ratesHistory: builder.query<Record<RateMarketType, HistoricalMarketRate[]>, RatesHistoryParams>({
            providesTags: [MARKETS_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_RATE_HISTORY_ROUTE,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {
    useStrategyDurationsQuery,
    useMarketStatsQuery,
    useMarketQuotesQuery,
    useMarketQuotesForApyQuery,
    useAllMarketPrincipalStatsQuery,
    useMarketPrincipalStatsByMintQuery,
    useRefinanceInfoQuery,
    useMaxQuotesQuery,
    useBestQuotesQuery,
    useBorrowCapsQuery,
    usePresetPrincipalByMintsQuery,
    useRatesHistoryQuery,
    useLoopBestQuoteQuery,
    useRefreshMarketStatsQuery,
    useRefreshMarketTvlQuery
} = marketsPublicApi;

export const useMarketsPublicApi = () => {
    const dispatch = useDispatch();
    const [fetchMarketStats] = marketsPublicApi.endpoints.marketStats.useLazyQuery();

    return {
        fetchMarketStats,
        resetMarketsPublicApi: () => dispatch(marketsPublicApi.util.invalidateTags([MARKETS_PUBLIC_TAG]))
    };
};

function convertToMarketStratResponse(input: RawMarketStratResponse): MarketStratResponse {
    const result: MarketStratResponse = {};

    for (const [mint, data] of Object.entries(input)) {
        result[mint] = transformPrincipalMarketStats(data);
    }

    return result;
}

export function transformPrincipalMarketStats(raw: RawPrincipalMarketStrategyStats): PrincipalMarketStrategyStats {
    return {
        totalDeposits: raw.totalDeposits,
        durationToMinApy: Object.entries(raw.durationToMinApy).map(([key, apy]) => {
            const strategyDuration = deserializeStrategyDurationOrUndefined(key);
            if (!strategyDuration) {
                throw new Error(`Invalid strategy duration: ${key}`);
            }
            return {
                duration: strategyDuration.duration,
                durationType: strategyDuration.durationType,
                apy: apy
            };
        })
    };
}

function convertRawQuote(raw: Record<string, RawMaxQuote[]>) {
    return Object.entries(raw)
        .map(([collateralMint, quotes]) =>
            quotes.map(
                ([apy, lendingStrategyAddress, ltv, liquidationThreshold, principalAvailable]): MaxQuote => ({
                    apy,
                    lendingStrategyAddress,
                    ltv,
                    liquidationThreshold,
                    principalAvailable,
                    collateralMint
                })
            )
        )
        .flat();
}

function convertRawBestQuotes(raw: Record<string, Omit<BestQuote, "collateralMint">[]>) {
    return Object.entries(raw)
        .map(([collateralMint, quotes]) => quotes.map((bestQuote): BestQuote => ({ ...bestQuote, collateralMint })))
        .flat();
}
