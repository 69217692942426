import { TextLink, useAppPalette, SPACING, FONT_WEIGHTS, Text, Tooltip } from "@bridgesplit/ui";
import { useLocation } from "react-router-dom";

import { NAV_BREAKPOINT } from "./constants";
import { getTabPath } from "../common";
import { NavOption } from "./types";

export default function NavOptions({ options }: { options: NavOption[] }) {
    const location = useLocation();

    const { hoverBackground, textSecondary, secondary, textDisabled } = useAppPalette();

    const selectedColor = secondary;

    return (
        <>
            {options.map((item, j) => {
                const active = (function () {
                    if (location.pathname === "/") return item.path === "/";
                    const pathMatches = item.pathRegex
                        ? item.pathRegex.test(location.pathname)
                        : item.path.includes(location.pathname);
                    if (!pathMatches) return false;
                    if (item.tab) return location.search.includes(item.tab);

                    const pathSearch = item.path.split("?")[1];
                    if (!pathSearch) return true;

                    return location.search.includes(pathSearch);
                })();

                return item.disabled ? (
                    <Tooltip key={`nav-option-disabled-${item.title}-${j}`} title={"Coming soon!"}>
                        <Text
                            sx={{
                                width: "max-content",
                                fontWeight: FONT_WEIGHTS.bold,
                                color: textDisabled,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                userSelect: "none",
                                [NAV_BREAKPOINT.below]: {
                                    width: `calc(100% - ${4 * SPACING}px)`,
                                    px: 2,
                                    py: 1
                                },
                                [NAV_BREAKPOINT.above]: {
                                    width: "100%",
                                    p: 1
                                }
                            }}
                        >
                            {item.title}
                        </Text>
                    </Tooltip>
                ) : (
                    <TextLink
                        key={`nav-option-${item.title}-${j}`}
                        sx={{
                            [NAV_BREAKPOINT.below]: {
                                width: `calc(100% - ${4 * SPACING}px)`,
                                px: 2,
                                py: 1,
                                ":hover": { background: hoverBackground }
                            },
                            [NAV_BREAKPOINT.above]: {
                                width: "100%",
                                p: 1
                            }
                        }}
                        textSx={{
                            width: "max-content",
                            fontWeight: FONT_WEIGHTS.bold,
                            color: active ? selectedColor : textSecondary,
                            [NAV_BREAKPOINT.above]: {
                                svg: { display: "none" }
                            },
                            ":hover": { color: selectedColor }
                        }}
                        to={item.tab ? getTabPath(item.path, item.tab) : item.path}
                    >
                        {item.icon} {item.title}
                    </TextLink>
                );
            })}
        </>
    );
}
