import { createContext, useCallback, useContext, useState } from "react";

import { DispatchType } from "@bridgesplit/react";

import { VaultDepositForm } from "./type";

interface VaultDepositContextState {
    form: VaultDepositForm;
    setForm: DispatchType<VaultDepositForm>;
    reset: () => void;
}

const VaultDepositContext = createContext<VaultDepositContextState | null>(null);

export function useVaultDepositContext(): VaultDepositContextState {
    const context = useContext(VaultDepositContext);
    if (context === null) {
        throw new Error("useVaultDepositContext must be used within a VaultDeposi   tProvider");
    }
    return context;
}

export function VaultDepositProvider({ children }: { children: React.ReactNode }) {
    const [form, setForm] = useState<VaultDepositForm>({});

    const resetForms = useCallback(() => {
        setForm((prev) => ({
            amount: undefined
        }));
    }, []);

    const reset = useCallback(async () => {
        resetForms();
    }, [resetForms]);

    return <VaultDepositContext.Provider value={{ form, setForm, reset }}>{children}</VaultDepositContext.Provider>;
}
