import { useEffect } from "react";

import { AppCookie, useAppNavigate } from "@bridgesplit/react";
import { useSearchParams } from "react-router-dom";
import { useDialog } from "@bridgesplit/ui";
import { AppDialog } from "app/utils/dialog";
import { REFERRAL_KEY } from "@bridgesplit/abf-react";

export default function SignUp() {
    const [params] = useSearchParams();
    const ref = params.get("ref");
    const navigate = useAppNavigate();
    const { open } = useDialog();

    useEffect(() => {
        // Store the ref code before navigation
        const referralCode = ref;
        if (referralCode) {
            AppCookie.set(REFERRAL_KEY, referralCode);
        }

        // Navigate to home page
        navigate("/");

        // If there's a referral code in the URL, open the referral dialog with the code prefilled
        // Using setTimeout to ensure navigation completes before opening dialog
        if (referralCode) {
            setTimeout(() => {
                open(AppDialog.ReferralEnterCode, {
                    initialCode: referralCode
                });
            }, 100);
        }
    }, [navigate, ref, open]);

    return null;
}
