import { convertDateToSeconds, getUnixTs, TIME } from "@bridgesplit/utils";
import { DurationType, getDurationInSeconds, StrategyDuration } from "@bridgesplit/abf-sdk";

import { AbfUiScheduleDetails, PaymentEvent } from "../types";

export function getOffsetDetailsFromStrategyDuration(strategyDuration: StrategyDuration) {
    const endDate = getEndTimeFromDuration(strategyDuration);
    const now = getUnixTs();

    const loanEndDate = convertDateToSeconds(endDate);
    const lastOffset = loanEndDate - now;
    const timeOffsets = [now, lastOffset];

    return { timeOffsets, lastOffset, loanEndDate };
}

type ScheduleParams = {
    timeOffsets: number[];
    apy: number; // ui amount ex 1 -> 1%
    principal: number;
    loanDurationSeconds?: number;
    yearConstant?: number;
};
export function getPaymentScheduleFromDates(params: ScheduleParams): AbfUiScheduleDetails {
    const { schedule, totalAmount } = getSimpleCompoundingSchedule(params);
    return {
        schedule,
        totalRepayment: totalAmount,
        totalInterest: Math.max(0, totalAmount - params.principal)
    };
}

function getSimpleCompoundingSchedule({
    apy,
    loanDurationSeconds,
    timeOffsets,
    yearConstant = TIME.YEAR,
    principal
}: ScheduleParams) {
    const schedule: PaymentEvent[] = [];
    let totalAmount = 0;
    const rate = apy / (yearConstant * 100);

    const loanTerm = (() => {
        if (loanDurationSeconds) return loanDurationSeconds;
        if (timeOffsets.length === 0) return 0;
        return timeOffsets[timeOffsets.length - 1];
    })();

    const totalInterest = rate * loanTerm * principal;

    for (let i = 0; i < timeOffsets.length; i++) {
        const currTimeOffset = timeOffsets[i];
        const prevTimeOffset = i !== 0 ? timeOffsets[i - 1] : 0;

        // ( d`n- d`n-1)
        const timeDifference = currTimeOffset - prevTimeOffset;

        // ( timeDifference ) * r`s * P
        const interestDue = (totalInterest * timeDifference) / loanTerm;
        const principalDue = i === timeOffsets.length - 1 ? principal : 0;
        const amount = interestDue + principalDue;

        totalAmount += amount;
        schedule.push({ amount, timeOffset: currTimeOffset, totalAmount, principalDue, interestDue });
    }
    return { schedule, totalAmount };
}

export function getSimpleInterest({
    principal,
    apy,
    loanDuration,
    yearConstant = TIME.YEAR,
    subtractPrincipal = true
}: {
    principal: number;
    apy: number;
    loanDuration: number;
    yearConstant?: number;
    subtractPrincipal: boolean;
}) {
    // Convert loan duration to years
    const timeInYears = loanDuration / yearConstant;

    // Calculate total amount due: A = P(1 + rt)
    const totalAmountDue = principal * (1 + apy * timeInYears);

    if (subtractPrincipal) {
        return totalAmountDue - principal;
    }
    return totalAmountDue;
}

export function calculateDurationInSecondsForCompoundedInterest({
    principal,
    desiredInterest,
    apy
}: {
    principal: number;
    desiredInterest: number;
    apy: number;
}): number {
    const totalAmountDue = principal + desiredInterest;
    const loanDurationInYears = Math.log(totalAmountDue / principal) / apy;
    return loanDurationInYears * TIME.YEAR;
}

export function getEndTimeFromDuration(strategyDuration: StrategyDuration) {
    const now = new Date();

    if (strategyDuration.durationType !== DurationType.Months) {
        const durationInSeconds = getDurationInSeconds(strategyDuration);
        const end = new Date(now.getTime() + durationInSeconds * 1000);
        return end;
    }
    const end = addMonthsToDate(now, strategyDuration.duration || 1);

    return end;
}

export function addMonthsToDate(date: Date, months: number): Date {
    const result = new Date(date);
    const currentMonth = result.getMonth();
    const targetMonth = currentMonth + months;

    result.setMonth(targetMonth);

    // Handle year overflow
    if (result.getMonth() !== ((targetMonth % 12) + 12) % 12) {
        result.setDate(0);
    }

    return result;
}
