import { createContext, useContext, ReactNode, useCallback, useState } from "react";

import { RoleView, useMarketBorrowCaps, useMarketStats } from "@bridgesplit/abf-react";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { AppDialog, AppParams } from "app/utils";

import { InternalDialogState, MarketContextState, MarketProps } from "../types";
import { MARKET_DETAIL_BREAKPOINT } from "../util";

const MarketContext = createContext<MarketContextState | null>(null);

export function useMarketContext() {
    const context = useContext(MarketContext);
    if (!context) {
        throw new Error("useMarketContext must be used within a MarketContext");
    }
    return context;
}

export function useMarketContextOptional() {
    const context = useContext(MarketContext);

    return context;
}

export function MarketProvider({ marketProps, children }: { marketProps: MarketProps; children: ReactNode }) {
    const borrowCap = useMarketBorrowCaps(
        marketProps.principalMint ? [marketProps.principalMint] : undefined
    ).getBorrowCap(marketProps.principalMint);

    const { data: principalStats } = useMarketStats(marketProps.principalMint);

    const durationToMinApy = principalStats?.strategyStats.durationToMinApy;
    const isDesktop = useMediaQuery(MARKET_DETAIL_BREAKPOINT.above);

    const [actionOpen, setActionOpen] = useState(false);

    const isActionOpen = actionOpen || isDesktop;

    const [internalOpenDialog, setInternalOpenDialog] = useState<InternalDialogState<AppDialog>>(null);

    return (
        <MarketContext.Provider
            value={{
                ...marketProps,
                principalStats,
                borrowCap,
                durationToMinApy,
                isActionOpen,
                setActionOpen,
                internalOpenDialog,
                setInternalOpenDialog
            }}
        >
            {children}
        </MarketContext.Provider>
    );
}

export function useMarketViewController() {
    const [params, setParams] = useSearchParams();
    const viewRaw = params.get(AppParams.BorrowLendRole);
    const view = viewRaw === "lend" ? RoleView.Lend : RoleView.Borrow;
    const setView = useCallback(
        (view: RoleView) =>
            setParams((prev) => {
                const encoded = view === RoleView.Lend ? "lend" : "borrow";
                prev.set(AppParams.BorrowLendRole, encoded);
                return prev;
            }),
        [setParams]
    );

    return { view, setView };
}
