import { useCallback, useEffect, useMemo } from "react";

import {
    AbfOrderFundingType,
    AssetTypeIdentifier,
    BsMetaUtil,
    CollateralWithTerms,
    CreateLoanTxnParams,
    MarketGuideMode,
    OnboardingStep,
    OrderbookQuote,
    RoleView,
    deserializeStrategyDurationOrUndefined,
    getAssetIdentifier,
    getAssetMint,
    getBorrowCapDetails,
    isWhirlpoolMetadata,
    useActiveWallet,
    useBsPrincipalTokens,
    useCreateStrategyWithCollateralTransaction,
    useFillStrategyOfferTransaction,
    useMarketOracleInfos,
    useStrategyDurations,
    useSupportedCollateral,
    useUserOnboardingStep
} from "@bridgesplit/abf-react";
import {
    JITO_SOL_MINT,
    LOADING_ERROR,
    Result,
    USDC_MINT,
    bpsToUiDecimals,
    decimalsToBps,
    filterNullableRecord,
    uiAmountToLamports,
    uiPercentToBps
} from "@bridgesplit/utils";
import { TokenListMetadata, CreateCustomStrategyTxnParams, TokenListTag, getDurationIndex } from "@bridgesplit/abf-sdk";
import { MEDIA } from "@bridgesplit/ui";
import { createSelector } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { AppDialog, AppDialogData, useAppDialog } from "app/utils";
import { COPY } from "app/constants";
import { TrackEventWithProps, TrackTransactionEvent } from "app/types";
import { presetFormToMixpanelFormat, trackSubmitMarketBorrowOrder, trackSubmitMarketLend, useTrack } from "app/hooks";

import { useTransactionSender } from "../transactions";
import { MarketProps, RateTick } from "./types";
import { useMarketContext, useMarketContextOptional } from "./common";
import { MarketLendForm } from "./lend";
import { getMarketPath, getTokenTagMetadata } from "../common";
import { BorrowMode, useMarketBorrowContext } from "./borrow";
import { RootState } from "../../reducers";

export const MARKET_DETAIL_BREAKPOINT = MEDIA.XL;

export function useMarketLend() {
    const createStrategy = useCreateStrategyWithCollateralTransaction();

    const { token } = useMarketContext();
    const { user } = useActiveWallet();
    const { strategyDurations } = useStrategyDurations();

    const { getMarketInformationsByCollateralMint } = useMarketOracleInfos({
        filter: {
            principalMints: token ? [token.mint] : [],
            addresses: [],
            verified: true
        }
    });

    // const trackMixpanelEvent = useTrackFromTransactions();

    const send = useTransactionSender();

    /**
     * 1. generate strat create
     * 3. generate strat modify
     * 4. sign all txns in parrell
     * 5. send txn (only strat escrow create not sign in)
     */
    return async function lend({ form }: { form: MarketLendForm }) {
        if (!token || !user || !strategyDurations || !form.strategyDurationToApy)
            return Result.errFromMessage(LOADING_ERROR);
        const description = `Creating your ${COPY.STRATEGY_TERM.toLowerCase()}`;

        const offerTermsMixpanel = presetFormToMixpanelFormat(form.collateral, form.strategyDurationToApy);

        const depositAmountLamports = uiAmountToLamports(form.amount, token.decimals);

        const liquidityBufferLamports =
            form.liquidityBuffer && token.decimals
                ? uiAmountToLamports(form.liquidityBuffer, token.decimals)
                : undefined;

        const maxOriginationSizeLamports =
            form.originationCap && token.decimals ? uiAmountToLamports(form.originationCap, token.decimals) : undefined;

        const interestFee = form.interestFee ? decimalsToBps(form.interestFee, "cbps") : undefined;
        const originationFee = form.originationFee ? decimalsToBps(form.originationFee, "cbps") : undefined;

        const collateralTerms = new Map<number, [[number, number]]>();
        for (const mint of form.collateral) {
            for (const [serializeDuration, apy] of form.strategyDurationToApy) {
                const deserializedDuration = deserializeStrategyDurationOrUndefined(serializeDuration);
                if (!deserializedDuration) continue;
                const durationIndex = getDurationIndex(deserializedDuration);
                if (durationIndex === undefined) continue;
                const formApy = apy ? uiPercentToBps(apy) : undefined;
                if (!formApy) continue;
                const marketInfos = getMarketInformationsByCollateralMint(mint);
                if (!marketInfos) continue;
                const ltvInfo = marketInfos[0];
                if (!ltvInfo) continue;
                if (collateralTerms.has(formApy)) {
                    const existing = collateralTerms.get(formApy);
                    if (existing) {
                        existing.push([ltvInfo.index, durationIndex]);
                    }
                } else {
                    // Create new entry
                    collateralTerms.set(formApy, [[ltvInfo.index, durationIndex]]);
                }
            }
        }

        const params: CreateCustomStrategyTxnParams = {
            principalMint: token.mint,
            amount: depositAmountLamports,
            lender: user,
            liquidityBuffer: liquidityBufferLamports,
            interestFee: interestFee,
            originationFee: originationFee,
            originationCap: maxOriginationSizeLamports,
            externalYieldSourceArgs: {
                newExternalYieldSource: form.yieldSource,
                createExternalYieldAccount: true
            },
            collateralTerms: Array.from(collateralTerms.entries())
        };

        const transactionResults = await send(createStrategy, params, {
            description,
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitMarketLend,
                params: trackSubmitMarketLend(params, offerTermsMixpanel)
            },
            sendOptions: {
                allowBatchFailure: false
            }
        });

        if (!transactionResults.isOk()) return Result.errFromMessage("Unable to create strategy");

        return transactionResults;
    };
}

const BORROW_ERRORS = {
    "Rate limit": "You're starting loans too quickly - please wait a few seconds and try again",
    "Offer is no longer available": "Quote is no longer available",
    "Borrow cap": "Your request exceeds the available borrow capacity for this market",
    "You can only borrow": "Your quote exceeds the global borrow limit per loan",
    "You have insufficient collateral": "Your quote exceeds your borrow limit"
};

export function useMarketBorrow() {
    const send = useTransactionSender();
    const fillOrder = useFillStrategyOfferTransaction();
    const { token, isDialog } = useMarketContext();
    const { form, activeUserCollateral, bestQuote: quote, setForm } = useMarketBorrowContext();
    const { activeWallet } = useActiveWallet();

    const durationIndex = getDurationIndex(form.strategyDuration);

    return async () => {
        if (
            !token ||
            !form.amount ||
            !form.collateralMint ||
            !form.collateralAmount ||
            !quote ||
            !activeUserCollateral ||
            !activeWallet ||
            !form.strategyDuration ||
            durationIndex === undefined
        )
            return Result.errFromMessage(LOADING_ERROR);

        const isWhirlpool = isWhirlpoolMetadata(activeUserCollateral.metadata);

        const amount = (() => {
            // wp positions always are balance of 1
            if (isWhirlpool) return 1;
            return form.collateralAmount;
        })();

        const metadata = activeUserCollateral.metadata;

        const params: CreateLoanTxnParams = {
            strategy: quote.lendingStrategyAddress,
            borrower: activeWallet,
            expectedLoanValues: {
                expectedApy: decimalsToBps(quote.apy),
                expectedLtv: [decimalsToBps(quote.ltv), 0, 0, 0, 0], //TODO: handle multiple ledgers
                expectedLiquidationThreshold: [decimalsToBps(quote.liquidationThreshold), 0, 0, 0, 0] //TODO: handle multiple ledgers
            },
            collateralAmount: amount,
            collateralDecimals: metadata.decimals,
            collateralMint: getAssetMint(metadata),
            collateralIdentifier: getAssetIdentifier(metadata),
            collateralType: isWhirlpool ? AssetTypeIdentifier.OrcaPosition : AssetTypeIdentifier.SplToken,
            loanType: AbfOrderFundingType.Standard,
            principalDecimals: token.decimals,
            principalAmount: form.amount,
            principalMint: token.mint,
            durationIndex,
            assetIndexGuidance: []
        };

        return await send(fillOrder, params, {
            alerter: { generationEstimateSeconds: 8, showProgress: true },
            messageOverrides: { errorMessageMap: BORROW_ERRORS },
            sendOptions: {
                onSuccess: () =>
                    setForm((prev) => ({
                        ...prev,
                        amount: undefined,
                        collateralAmount: undefined,
                        mode: BorrowMode.InputCollateral,
                        ltvMultiplier: 0
                    }))
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitMarketBorrowOrder,
                params: trackSubmitMarketBorrowOrder({
                    params,
                    duration: quote,
                    source: isDialog ? "dialog" : "detail"
                })
            }
        });
    };
}

const MAX_TOTAL_TICKS = 50;
const MAX_FILL_TICKS = 20;
const MIN_TICK_DENSITY = 10;
export function useRatesTicks({
    orderbookQuotes,
    tickSizeBps = 1
}: {
    orderbookQuotes: OrderbookQuote[] | undefined;
    tickSizeBps?: number;
}) {
    if (!orderbookQuotes) return undefined;

    const allTicksMap = orderbookQuotes.reduce((map, curr) => {
        if (map.size > MAX_TOTAL_TICKS) return map;

        // use bps to prevent JS underflows
        const apyBps = decimalsToBps(curr.apy);
        const tickBps = Math.floor(apyBps / tickSizeBps) * tickSizeBps;

        const prev = map.get(tickBps);

        const totalPrincipal = (prev?.totalPrincipal ?? 0) + curr.sumPrincipalAvailable;
        const maxPrincipal = Math.max(prev?.maxPrincipal ?? 0, curr.maxPrincipalAvailable);

        map.set(tickBps, { totalPrincipal, maxPrincipal });
        return map;
    }, new Map<number, { totalPrincipal: number; maxPrincipal: number }>());

    const minTick = Math.min(...allTicksMap.keys());
    const maxTick = Math.max(...allTicksMap.keys());

    const estimatedAdditionalTicks = (maxTick - minTick) / tickSizeBps;

    if (orderbookQuotes.length > MIN_TICK_DENSITY && estimatedAdditionalTicks < MAX_FILL_TICKS) {
        // fill any 0 amount ticks
        for (let tick = minTick; tick < maxTick; tick += tickSizeBps) {
            if (allTicksMap.size > MAX_TOTAL_TICKS) break;
            const prev = allTicksMap.get(tick);
            if (prev) continue;
            allTicksMap.set(tick, { totalPrincipal: 0, maxPrincipal: 0 });
        }
    }

    let cumulativePrincipal = 0;
    return Array.from(allTicksMap.entries())
        .sort((a, b) => a[0] - b[0])
        .map(([apy, { totalPrincipal, maxPrincipal }]): RateTick => {
            cumulativePrincipal += totalPrincipal;
            return {
                apy: bpsToUiDecimals(apy),
                totalPrincipal,
                maxPrincipal,
                cumulativePrincipal
            };
        })
        .filter((a) => !!a.maxPrincipal)
        .sort((a, b) => a.apy - b.apy);
}

export function getDefaultCollateral({
    principalMint,
    returnAll = false
}: {
    principalMint: string | undefined;
    returnAll?: boolean;
}) {
    if (returnAll) return null;
    return principalMint === USDC_MINT ? JITO_SOL_MINT : USDC_MINT;
}

export function useMarketCapDetails() {
    const { borrowCap, principalStats: data, token } = useMarketContext();

    return useMemo(() => {
        if (!borrowCap || !token || !data) return undefined;
        const { principalUtilized } = data.principalStats;

        return getBorrowCapDetails({ borrowCap, token, principalUtilized });
    }, [borrowCap, data, token]);
}

export function useCollateralTokensByTag(collateral: CollateralWithTerms[] | undefined) {
    const tokensByTags = useMemo(() => {
        if (!collateral) return undefined;
        const tagMap = new Map<TokenListTag, CollateralWithTerms[]>();
        const usedTokenMints = new Set();
        for (const c of collateral) {
            for (const tag of c.metadata.tags ?? []) {
                if (!getTokenTagMetadata(tag)) continue;
                const prev = tagMap.get(tag) ?? [];
                usedTokenMints.add(c.metadata.mint);
                tagMap.set(tag, [...prev, c]);
            }
        }

        if (usedTokenMints.size !== collateral.length) {
            tagMap.set(
                TokenListTag.Misc,
                collateral.filter((c) => !usedTokenMints.has(c.metadata.mint))
            );
        }

        return Array.from(tagMap.entries())
            .map(([tag, tokens]) => ({
                tokens: tokens.sort((a, b) =>
                    BsMetaUtil.getSymbol(a.metadata).localeCompare(BsMetaUtil.getSymbol(b.metadata))
                ),
                tag: getTokenTagMetadata(tag)
            }))
            .filter(filterNullableRecord)
            .filter(({ tag }) => !tag.hideInCategories)
            .sort((a, b) => a.tag.sortPosition - b.tag.sortPosition);
    }, [collateral]);

    return { tokensByTags };
}

export function useMarketCollateral(props?: Partial<MarketProps>) {
    const { principalMint } = useMarketContext();
    return useMarketCollateralFromProps({
        ...props,
        principalMint: props?.principalMint ?? principalMint
    } as MarketProps);
}

export function useMarketCollateralFromProps({ principalMint, requireMarketInformation }: MarketProps) {
    const collateral = useSupportedCollateral(principalMint, { requireMarketInformation: requireMarketInformation });
    return collateral;
}

const selectNapoleonApiQueries = (state: RootState) => state.marketsPublicApi.queries;

const allowedEndpoints = ["maxQuotes", "bestQuotes"];
const selectFetches = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some(
        (e) => e?.status !== QueryStatus.fulfilled && e?.endpointName && allowedEndpoints.includes(e?.endpointName)
    )
);

const selectMaxQuotes = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some((e) => e?.status !== QueryStatus.fulfilled && e?.endpointName === "maxQuotes")
);

export function useNapoleonFetching() {
    return useSelector(selectFetches);
}

export function useMaxQuotesFetching() {
    return useSelector(selectMaxQuotes);
}

const selectErrors = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some((e) => e?.error && allowedEndpoints.includes(e.endpointName))
);
export function useNapoleonErrors() {
    return useSelector(selectErrors);
}

export function useOpenGuideOnLoad({ view }: { view: RoleView }) {
    const { open } = useMarketDialog();
    const { isDialog } = useMarketContext();
    const { steps, isLoading } = useUserOnboardingStep([OnboardingStep.Borrow, OnboardingStep.Lend]);

    useEffect(() => {
        // if the dialog is already open, don't open it again

        if (isLoading) return;

        if (view === RoleView.Borrow && !steps.includes(OnboardingStep.Borrow)) {
            return open(AppDialog.MarketGuide, { mode: MarketGuideMode.Borrow, isForced: true });
        }

        if (view === RoleView.Lend && !steps.includes(OnboardingStep.Lend)) {
            return open(AppDialog.MarketGuide, { mode: MarketGuideMode.Lend, isForced: true });
        }
    }, [isDialog, isLoading, open, steps, view]);
}

// Wrap app dialog to support seamless internal dialogs
export function useMarketDialog() {
    const marketContext = useMarketContextOptional();
    const appDialog = useAppDialog();
    const isDialog = !!marketContext?.isDialog;

    function open<T extends AppDialog>(type: T, data: AppDialogData<T>) {
        if (marketContext?.isDialog) {
            return marketContext.setInternalOpenDialog({ type, data });
        }
        return appDialog.open(type, data);
    }

    function close() {
        if (marketContext?.isDialog) {
            return marketContext.setInternalOpenDialog(null);
        }
        return appDialog.close();
    }

    function getData<T extends AppDialog>(identifier: T): AppDialogData<T> | undefined {
        if (marketContext?.isDialog) {
            if (!marketContext.internalOpenDialog) return undefined;
            return marketContext.internalOpenDialog.data as AppDialogData<T>;
        }
        return appDialog.getData(identifier);
    }

    return { open, close, getData, isDialog };
}

export function useSelectMarketPrincipal() {
    const { open } = useMarketDialog();
    const { selectToken, view } = useMarketContext();
    const { tokens } = useBsPrincipalTokens();
    const { trackWithProps } = useTrack();

    if (!selectToken || !tokens) return null;

    const selectTokenWithTracking = (token: TokenListMetadata): void => {
        trackWithProps(TrackEventWithProps.SelectBorrowPrincipal, {
            tokenSymbol: BsMetaUtil.getSymbol(token)
        });
        selectToken(token);
    };

    return () =>
        open(AppDialog.SelectToken, {
            header: `Select token to ${view.toLowerCase()}`,
            tokens,
            setToken: selectTokenWithTracking
        });
}

export function usePreserveMarketData() {
    const { token, view } = useMarketContext();
    const { open, close } = useAppDialog();

    const detailPath = getMarketPath({ token, view, navigateTo: "detail" });
    const marketsPath = getMarketPath({ token, view, navigateTo: "markets" });

    const onDetailNavigate = useCallback(() => {
        close();
    }, [close]);

    const onMarketsNavigate = useCallback(() => {
        if (!token) return;
        if (view === RoleView.Borrow) {
            open(AppDialog.Borrow, { token });
        } else {
            open(AppDialog.Lend, { token });
        }
    }, [open, token, view]);

    return { detailPath, marketsPath, onDetailNavigate, onMarketsNavigate };
}

// function presetFormToMixpanelFormat(
//     collateralMints: string[],
//     serializedStrategyDurationToApy: Map<string, number | undefined>
// ): FormattedOfferTerms {
//     const terms: { [key: string]: number } = {};

//     for (const [serializedDuration, apy] of serializedStrategyDurationToApy) {
//         const duration = deserializeStrategyDurationOrUndefined(serializedDuration);
//         if (!duration || apy === undefined) continue;

//         const formattedDuration = formatDurationWithType(duration);
//         terms[formattedDuration] = percentDecimalsToUi(apy);
//     }

//     return {
//         collateral: collateralMints,
//         terms
//     };
// }
