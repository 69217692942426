import {
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions,
    deserializeVersionedTransaction,
    VersionedTransactionResponse
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    InitVaultTimelockTxnParams,
    TXN_LEND_VAULT_CANCEL_TIMELOCKS,
    TXN_LEND_VAULT_CREATE_TIMELOCKS,
    TXN_LEND_VAULT_DEPOSIT,
    TXN_LEND_VAULT_WITHDRAW,
    VaultDepositParams,
    VaultWithdrawParams,
    TXN_LEND_VAULT_CLAIM_FEE,
    CancelVaultTimeLockTxnParams,
    ClaimVaultFeesTxnParams,
    UpdateLendingVaultDepositsTxnParams,
    TXN_LEND_VAULT_EDIT_DEPOSITS
} from "../types";
import { createMarketsInstance } from "./auth";

export async function getVaultDepositTransaction(authentication: AbfTransactionHeaders, args: VaultDepositParams) {
    const api = createMarketsInstance(authentication);
    const response = await api.post<{ transaction: VersionedTransactionResponse; stakeAccount: string }>(
        TXN_LEND_VAULT_DEPOSIT,
        args
    );

    const transaction = deserializeVersionedTransaction(response.data.transaction);

    return {
        transactions: [transaction],
        stakeAccount: response.data.stakeAccount
    };
}

export async function getVaultWithdrawTransaction(authentication: AbfTransactionHeaders, args: VaultWithdrawParams) {
    const api = createMarketsInstance(authentication);
    const response = await api.post<{ transaction: VersionedTransactionResponse; stakeAccount: string }>(
        TXN_LEND_VAULT_WITHDRAW,
        args
    );

    const transaction = deserializeVersionedTransaction(response.data.transaction);

    return {
        transactions: [transaction],
        stakeAccount: response.data.stakeAccount
    };
}

export async function getVaultInitTimelockTransactions(
    authentication: AbfTransactionHeaders,
    args: InitVaultTimelockTxnParams
) {
    return await fetchAndDeserializeVersionedTransactions(
        createMarketsInstance(authentication),
        TXN_LEND_VAULT_CREATE_TIMELOCKS,
        args
    );
}

export async function getVaultCancelTimelockTransaction(
    authentication: AbfTransactionHeaders,
    args: CancelVaultTimeLockTxnParams[]
) {
    return await fetchAndDeserializeVersionedTransactions(
        createMarketsInstance(authentication),
        TXN_LEND_VAULT_CANCEL_TIMELOCKS,
        args
    );
}

export async function getVaultClaimFeesTransaction(
    authentication: AbfTransactionHeaders,
    args: ClaimVaultFeesTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_LEND_VAULT_CLAIM_FEE,
            args
        )
    ];
}

export async function getVaultUpdateDepositEnabledTransaction(
    authentication: AbfTransactionHeaders,
    args: UpdateLendingVaultDepositsTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_LEND_VAULT_EDIT_DEPOSITS,
            args
        )
    ];
}

// This is the APY to set a disabled collateral term to.
// String because BN exceeds javascript number. BE converts to BN.
