import VaultDepositAmount from "./VaultDepositAmount";
import { VaultDepositProvider } from "./VaultDepositContext";
import VaultDepositCta from "./VaultDepositCta";
import VaultDepositStats from "./VaultDepositStats";

export function VaultDeposit() {
    return (
        <VaultDepositProvider>
            <VaultDepositAmount />
            <VaultDepositStats />
            <VaultDepositCta />
        </VaultDepositProvider>
    );
}
