import { memo, useState } from "react";

import {
    Column,
    PercentInput,
    Row,
    SkeletonRounded,
    Text,
    TextButton,
    TooltipText,
    repeatElement
} from "@bridgesplit/ui";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { formatPercent, percentDecimalsToUi } from "@bridgesplit/utils";
import { serializeStrategyDuration } from "@bridgesplit/abf-react";

import { useMarketLendContext } from "./MarketLendContext";
import { useLendMarketRates } from "./util";

export default memo(function LendApyDuration() {
    const { form, setForm, strategyDurations } = useMarketLendContext();

    const [focusedIndex, setFocusedIndex] = useState<number>();

    if (!strategyDurations?.length)
        return <Column spacing={0.5}> {repeatElement(<SkeletonRounded height={30} width="100%" />, 5)} </Column>;

    return (
        <Column spacing={1}>
            <Label />
            {strategyDurations?.map((strategyDuration, i) => {
                const key = serializeStrategyDuration(strategyDuration);
                return (
                    <PercentInput
                        key={key}
                        InputProps={{
                            startAdornment: (
                                <Text sx={{ minWidth: "max-content" }} color={focusedIndex === i ? "body" : "caption"}>
                                    {formatDurationWithType(strategyDuration)}
                                </Text>
                            ),
                            endAdornment: (
                                <Text color="disabled" sx={{ minWidth: "max-content", pl: 1 }}>
                                    %
                                </Text>
                            )
                        }}
                        onBlur={() => setFocusedIndex(undefined)}
                        onFocus={() => setFocusedIndex(i)}
                        inputProps={{ sx: { textAlign: "right" } }}
                        adornment={null}
                        value={form.strategyDurationToApy.get(key)}
                        setValue={(apy) =>
                            setForm((prev) => {
                                const strategyDurationToApy = new Map(prev.strategyDurationToApy);
                                strategyDurationToApy.set(key, apy);

                                return { ...prev, strategyDurationToApy };
                            })
                        }
                    />
                );
            })}
        </Column>
    );
});

function Label() {
    const { marketMinApy } = useLendMarketRates();
    const { setForm } = useMarketLendContext();

    return (
        <Row spaceBetween>
            <TooltipText variant="body2" helpText={COPY.STRATEGY_APY_TOOLTIP}>
                {COPY.STRATEGY_APY_TERM}
            </TooltipText>
            {marketMinApy && (
                <Row spacing={0.5}>
                    <Text color="caption" variant="body2">
                        Market:
                    </Text>
                    <TextButton
                        onClick={() =>
                            setForm((prev) => {
                                const strategyDurationToApy = new Map(prev.strategyDurationToApy);
                                for (const serializedDuration of strategyDurationToApy.keys()) {
                                    strategyDurationToApy.set(serializedDuration, percentDecimalsToUi(marketMinApy));
                                }
                                return { ...prev, strategyDurationToApy };
                            })
                        }
                        color="secondary"
                        variant="body2"
                    >
                        {formatPercent(marketMinApy)}
                    </TextButton>
                </Row>
            )}
        </Row>
    );
}
