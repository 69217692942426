import { Fragment, ReactNode, useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    getZcLoanEndTime,
    getLoanName,
    LOAN_HEALTH_THRESHOLD,
    calculateLoanHealth,
    isLoanOracleBased,
    canLenderReclaimCollateral,
    isSimpleLoan,
    RoleView,
    LoanStatus,
    LoopPositionExpanded,
    calculateLoanLiquidationPrice,
    isLoanBorrower,
    getUnclaimedAssets,
    Loan,
    useActiveWallet,
    LOAN_REFINANCE_GRACE_BEFORE_DEFAULT,
    getPastOrPresentZcLedger,
    isLoanComplete,
    StrategyExpanded,
    useIsLoanLender
} from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import {
    Tooltip,
    TagText,
    BreadcrumbsInterface,
    Breadcrumbs,
    StatProps,
    TextVariant,
    TagColor,
    TextColor,
    useAppPalette,
    Icon,
    Row,
    Text,
    FONT_SIZES,
    Image,
    TextButton,
    TagTextProps,
    SMALL_SHADOW,
    StatColumn,
    Column,
    SkeletonRounded
} from "@bridgesplit/ui";
import {
    formatPercent,
    formatSeconds,
    formatTokenAmount,
    getUnixTs,
    removeDuplicatesByProperty
} from "@bridgesplit/utils";
import { ArrowForwardOutlined, SwapHorizOutlined, SyncOutlined } from "@mui/icons-material";
import { BORROW_SLUG, COPY, LOAN_SLUG } from "app/constants";

import { getMarketPath } from "./market";
import { getTokenImageSize, OverlappingMetadataImages, TokenSize } from "./asset";
import { getLoopPath, LoopImage } from "./loop";

export enum LoanStatusFilter {
    All = "All"
}

export type LoanFilter = LoanStatus | LoanStatusFilter;

export function LoanStatusTag({
    status,
    variant = "body2"
}: {
    status: LoanFilter | undefined;
    variant?: TextVariant;
}) {
    if (status === undefined) {
        return (
            <TagText variant="colored" textVariant={variant}>
                ...
            </TagText>
        );
    }

    return (
        <TagText variant="colored" textVariant={variant} color={getTagColorFromLoanStatus(status)}>
            {status}
        </TagText>
    );
}

export const getLoanAssetPath = (loan: Loan | undefined) => (loan ? `${LOAN_SLUG}/${loan.address}` : BORROW_SLUG);

export function LoanBreadCrumbs({
    loanExpanded,
    isLoading,
    loopPosition
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    isLoading?: boolean;
    loopPosition?: LoopPositionExpanded;
}) {
    const { user } = useActiveWallet();

    const paths: BreadcrumbsInterface[] = [
        { text: getLoanName(loanExpanded), path: getLoanAssetPath(loanExpanded?.loan) }
    ];

    if (loopPosition) {
        paths.unshift({
            text: `${loopPosition.loopExpanded.loopVault.name} ${COPY.LOOP_TERM.toLowerCase()}`,
            path: getLoopPath(loopPosition.loopExpanded)
        });
    } else if (isSimpleLoan(loanExpanded)) {
        paths.unshift({
            text: `${BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)} Market`,
            path: getMarketPath({
                token: loanExpanded?.principalMetadata,
                view: loanExpanded?.borrower === user ? RoleView.Borrow : RoleView.Lend
            })
        });
    } else {
        paths.unshift({ text: "Loans", path: BORROW_SLUG });
    }

    return <Breadcrumbs loading={isLoading} paths={paths} />;
}

export function useLoanStats(loanExpanded: AbfLoanExpanded | undefined) {
    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    const ledger = getPastOrPresentZcLedger(loanExpanded);

    const principal: StatProps = { value: ledger?.principalDue, symbol, caption: "Principal" };
    const term: StatProps = {
        value: `${formatSeconds(
            loanExpanded ? (getZcLoanEndTime(loanExpanded) ?? 0) - loanExpanded?.loan.startTime : 0
        )}`,
        caption: "Term"
    };
    const apy: StatProps = {
        value: formatPercent(loanExpanded?.borrowerWAvgApy),
        caption: "APY"
    };

    return { principal, apy, term, stats: [principal, apy, term] };
}

export function getTagColorFromLoanStatus(status: LoanFilter | undefined): TagColor {
    if (!status) return "body";
    if (status === LoanStatusFilter.All) return "body";
    if ([LoanStatus.DefaultedLoan].includes(status)) return "error";
    if ([LoanStatus.OngoingLoan].includes(status)) return "info";
    if ([LoanStatus.RepaidLoan].includes(status)) return "success";

    return "body";
}

export function getTextColorFromLoanStatus(status: LoanStatus | undefined): TextColor {
    if (!status) return "body";
    if ([LoanStatus.DefaultedLoan].includes(status)) return "disabled";
    if ([LoanStatus.RepaidLoan, LoanStatus.OngoingLoan].includes(status)) return "success";
    return "body";
}

export function getIconFromStatus(status: LoanStatus | undefined): ReactNode {
    if (!status) return null;
    if ([LoanStatus.DefaultedLoan].includes(status)) return <Icon type="reject" />;
    if ([LoanStatus.RepaidLoan].includes(status)) return <Icon type="accept" />;
    if ([LoanStatus.OngoingLoan].includes(status)) return <SyncOutlined />;
    if ([LoanStatus.RefinanceGrace].includes(status)) return <Icon type="clock" />;

    return null;
}

export function getFixedTextTooltipFromStatus(
    status: LoanStatus | undefined,
    loanExpanded: AbfLoanExpanded | undefined
): ReactNode {
    if (!status) return null;
    if ([LoanStatus.RefinanceGrace].includes(status)) return <RefinancingTooltip loanExpanded={loanExpanded} />;
    return null;
}

export function useHealthColor() {
    const { success, warning, error } = useAppPalette();

    return (progress: number): string => {
        let color: string;

        if (progress < LOAN_HEALTH_THRESHOLD.danger) {
            color = error;
        } else if (progress <= LOAN_HEALTH_THRESHOLD.warning) {
            color = warning;
        } else {
            color = success;
        }

        return color;
    };
}

export function useLtvColor() {
    const { success, warning, error } = useAppPalette();

    return (ltv: number, liquidationLtv: number): string => {
        const distance = liquidationLtv - ltv;

        if (distance <= LOAN_HEALTH_THRESHOLD.danger) {
            return error;
        } else if (distance <= LOAN_HEALTH_THRESHOLD.warning) {
            return warning;
        }
        return success;
    };
}

export function StrategyRepairTag({ strategyExpanded }: { strategyExpanded: StrategyExpanded | undefined }) {
    const isMissingUpdate = isStrategyMissingUpdateTransaction(strategyExpanded);

    if (!isMissingUpdate) return null;
    return (
        <Tooltip title="One of your strategy settings is missing. Please update your strategy to continue lending">
            <TagText>
                Strategy is inactive
                <Icon sx={{ color: (theme) => `${theme.palette.text.disabled} !important` }} type="tooltip" />
            </TagText>
        </Tooltip>
    );
}

export function isStrategyMissingUpdateTransaction(strategyExpanded: StrategyExpanded | undefined): boolean {
    if (!strategyExpanded) return false;
    return !strategyExpanded?.collateral.length && Object.keys(strategyExpanded?.terms ?? {}).length === 0;
}

export function LoanHealthTag({
    loan,
    variant = "body1"
}: {
    loan: AbfLoanExpanded | undefined;
    variant?: TextVariant;
}) {
    const healthDetails = calculateLoanHealth(loan);
    const healthColor = useHealthColor()(healthDetails.health);
    const status = loan?.status;

    if (!loan) return null;

    const tagText: Partial<TagTextProps> = { px: 1, py: 0.5, textVariant: variant, variant: "basic" };

    if (status !== LoanStatus.OngoingLoan || !isLoanOracleBased(loan)) {
        return (
            <Tooltip reverseColors title={getFixedTextTooltipFromStatus(status, loan)}>
                <TagText {...tagText} color={getTagColorFromLoanStatus(status)}>
                    {getIconFromStatus(status)} {status}
                </TagText>
            </Tooltip>
        );
    }

    return (
        <Tooltip reverseColors title={<HealthTooltip healthDetails={healthDetails} loanExpanded={loan} />}>
            <TagText {...tagText} customColor={healthColor}>
                <Icon type="health" /> {formatPercent(healthDetails.health)}
                <Icon sx={{ color: (theme) => `${theme.palette.text.disabled} !important` }} type="tooltip" />
            </TagText>
        </Tooltip>
    );
}

function RefinancingTooltip({
    variant = "body2",
    loanExpanded
}: {
    variant?: TextVariant;
    loanExpanded: AbfLoanExpanded | undefined;
}) {
    const timeRemaining = Math.max(
        0,
        (getZcLoanEndTime(loanExpanded) ?? 0) + LOAN_REFINANCE_GRACE_BEFORE_DEFAULT - getUnixTs()
    );

    return (
        <Text variant={variant}>
            {`Searching for lenders. If none found in ${formatSeconds(
                timeRemaining
            )}, loan may default. APY subject to change`}
        </Text>
    );
}

function HealthTooltip({
    healthDetails,
    variant = "body2",
    loanExpanded
}: {
    healthDetails: ReturnType<typeof calculateLoanHealth>;
    variant?: TextVariant;
    loanExpanded: AbfLoanExpanded | undefined;
}) {
    const liquidationPrice = calculateLoanLiquidationPrice(loanExpanded);

    return (
        <Column sx={{ minWidth: "230px" }} p={1} spacing={1}>
            <StatColumn
                captionVariant={variant}
                variant={variant}
                stats={[
                    {
                        caption: "Current LTV",
                        value: formatPercent(healthDetails.ltv)
                    },
                    {
                        caption: COPY.LIQUIDATION_THRESHOLD_TERM,
                        value: formatPercent(healthDetails.liquidationThreshold)
                    },
                    {
                        caption: COPY.LIQUIDATION_PRICE_TERM,
                        value: (
                            <LiquidationPrice
                                variant={variant}
                                liquidationPrice={liquidationPrice?.price ?? 0}
                                collateral={liquidationPrice?.collateral.loanCollateral.metadata}
                                principal={loanExpanded?.principalMetadata}
                            />
                        )
                    }
                ]}
            />
        </Column>
    );
}

export function LoanHealthText({
    loan,
    variant = "body1"
}: {
    loan: AbfLoanExpanded | undefined;
    variant?: TextVariant;
}) {
    const { health } = calculateLoanHealth(loan);
    const healthColor = useHealthColor()(health);

    if (!loan) return null;

    return (
        <Text variant={variant} sx={{ svg: { color: healthColor, fontSize: "inherit" } }}>
            <Icon type="health" /> {formatPercent(health)}
        </Text>
    );
}

export function LiquidationPrice({
    liquidationPrice,
    collateral,
    principal,
    percentChange: percentChangeProps,
    variant = "body1",
    isLoading
}: {
    liquidationPrice: number;
    collateral: TokenListMetadata | undefined;
    principal: TokenListMetadata | undefined;
    percentChange?: number;
    variant?: TextVariant;
    isLoading?: boolean;
}) {
    const [flippedPrice, setFlippedPrice] = useState(false);
    let tokenImages = [BsMetaUtil.getImage(principal), BsMetaUtil.getImage(collateral)];
    let tokenSymbols = [BsMetaUtil.getSymbol(principal), BsMetaUtil.getSymbol(collateral)];

    const percentChange = percentChangeProps && flippedPrice ? percentChangeProps * -1 : percentChangeProps;

    const priceFormatted = flippedPrice
        ? `${formatTokenAmount(1 / liquidationPrice, { decimals: 3 })}`
        : `${formatTokenAmount(liquidationPrice, { decimals: 3 })}`;

    if (flippedPrice) {
        tokenImages = tokenImages.reverse();
        tokenSymbols = tokenSymbols.reverse();
    }

    if (isLoading) return <SkeletonRounded width={100} height={FONT_SIZES[variant]} />;

    return (
        <Row spacing={1}>
            <Row spacing={0.5}>
                {!!percentChange && priceFormatted.length < 8 && (
                    <Text color={percentChange < 0 ? "error" : "success"} variant="caption">
                        {formatPercent(percentChange, { includePlus: true, customDecimals: 1 })}
                    </Text>
                )}
                <Text variant={variant}>{priceFormatted}</Text>
            </Row>

            <Tooltip title={tokenSymbols.join("/")}>
                <Row spacing={0.5}>
                    {tokenImages.map((src, i) => (
                        <Fragment key={i}>
                            {i !== 0 && <Text color="disabled">/</Text>}
                            <Image size={`${FONT_SIZES[variant]}px`} variant="circle" src={src} />
                        </Fragment>
                    ))}
                </Row>
            </Tooltip>
            <TextButton variant={variant} onClick={() => setFlippedPrice((prev) => !prev)} color="disabled">
                <SwapHorizOutlined />
            </TextButton>
        </Row>
    );
}

export function LoanImages({
    loanExpanded,
    size,
    loopPosition,
    isLoading
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    size: TokenSize;
    loopPosition?: LoopPositionExpanded;
    isLoading?: boolean;
}) {
    const tokenSize = getTokenImageSize(size);
    const metadata = useMemo(() => {
        if (!loanExpanded) return [];

        if (isLoanComplete(loanExpanded)) {
            const uniqueCollateral = removeDuplicatesByProperty(
                loanExpanded.pastCollateral.map((c) => c.metadata),
                "mint"
            );
            return [loanExpanded.principalMetadata, ...uniqueCollateral];
        } else {
            const uniqueCollateral = removeDuplicatesByProperty(
                loanExpanded.collateral.map((c) => c.loanCollateral.metadata),
                "mint"
            );
            return [loanExpanded.principalMetadata, ...uniqueCollateral];
        }
    }, [loanExpanded]);

    if (isLoading) {
        return <SkeletonRounded width={tokenSize} height={tokenSize} />;
    }

    if (loopPosition) {
        return <LoopImage loopExpanded={loopPosition.loopExpanded} size={size} />;
    }

    return (
        <OverlappingMetadataImages
            sort={false}
            disableTooltip
            sx={{ boxShadow: SMALL_SHADOW }}
            size={`${tokenSize}px`}
            metadata={metadata}
        />
    );
}

export function useUserLoanStatus(loan: AbfLoanExpanded | undefined) {
    const { user } = useActiveWallet();
    const status = loan?.status;

    const isLender = useIsLoanLender(loan, user);

    if (!loan) return undefined;

    const isBorrower = isLoanBorrower(loan, user);

    const unclaimedAssets = getUnclaimedAssets(loan);

    const canLenderClaimPayments = (() => {
        if (isSimpleLoan(loan)) return false;
    })();

    const unclaimedAssetsExist = !!unclaimedAssets?.length;

    const canBorrowerClaimAssets =
        unclaimedAssetsExist && status === LoanStatus.RepaidLoan && isBorrower && user && !loan.lenders.includes(user);
    const canLenderClaimAssets = unclaimedAssetsExist && canLenderReclaimCollateral(loan) && isLender;
    return {
        isBorrower,
        isLender,
        canLenderClaimPayments,
        canBorrowerClaimAssets,
        canLenderClaimAssets,
        unclaimedAssets
    };
}

export function filterOnLoanStatus(filterStatus: LoanFilter, loanStatus: LoanFilter | undefined) {
    if (loanStatus === undefined) return false;
    if (filterStatus === LoanStatusFilter.All) return true;

    if (filterStatus === LoanStatus.OngoingLoan)
        return [LoanStatus.OngoingLoan, LoanStatus.RefinanceGrace].includes(loanStatus as LoanStatus);
    return loanStatus === filterStatus;
}

export function HealthText({ health, variant = "body1" }: { health: number | undefined; variant?: TextVariant }) {
    const healthColor = useHealthColor()(health ?? 0);
    return (
        <Text loading={health === undefined} sx={{ color: healthColor }} variant={variant}>
            {formatPercent(health)}
        </Text>
    );
}

export function HealthChange({
    currentHealth,
    previousHealth,
    variant = "body1"
}: {
    currentHealth: number | undefined;
    previousHealth: number | undefined;
    variant?: TextVariant;
}) {
    if (formatPercent(currentHealth) === formatPercent(previousHealth))
        return <HealthText variant={variant} health={previousHealth} />;
    return (
        <Row spacing={1}>
            <HealthText variant={variant} health={currentHealth} />
            <Text color="disabled" variant="body2">
                <ArrowForwardOutlined />
            </Text>
            <HealthText variant={variant} health={previousHealth} />
        </Row>
    );
}

export function LtvChange({
    currentLtv,
    previousLtv,
    variant = "body1",
    liquidationThreshold
}: {
    currentLtv: number | undefined;
    previousLtv: number | undefined;
    variant?: TextVariant;
    liquidationThreshold: number | undefined;
}) {
    const boundedPreviousLtv = Math.min(previousLtv ?? Infinity, liquidationThreshold ?? Infinity);

    if (formatPercent(currentLtv) === formatPercent(boundedPreviousLtv))
        return <LtvText variant={variant} ltv={boundedPreviousLtv} liquidationThreshold={liquidationThreshold} />;

    return (
        <Row spacing={1}>
            <LtvText variant={variant} ltv={currentLtv} liquidationThreshold={liquidationThreshold} />
            <Text color="disabled" variant="body2">
                <ArrowForwardOutlined />
            </Text>
            <LtvText variant={variant} ltv={boundedPreviousLtv} liquidationThreshold={liquidationThreshold} />
        </Row>
    );
}

export function LtvText({
    ltv,
    variant = "body1",
    liquidationThreshold
}: {
    ltv: number | undefined;
    variant?: TextVariant;
    liquidationThreshold: number | undefined;
}) {
    const ltvColor = useLtvColor()(ltv ?? 0, liquidationThreshold ?? 0);
    return (
        <Text loading={ltv === undefined} sx={{ color: ltvColor }} variant={variant}>
            {formatPercent(ltv)}
        </Text>
    );
}
