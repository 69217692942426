import { useMemo } from "react";

import {
    LoopTransferType,
    LoopExpanded,
    calculateHistoricalLoopProfitLoss,
    LoopPositionExpanded,
    LoopscalePointsAction,
    calculateUnwindStats,
    isLoanActive,
    isLstLoop,
    sumLoopPositionUsdContributions,
    BsMetaUtil,
    calculateDurationInSecondsForCompoundedInterest,
    useLoopCollateralPrice,
    useUnwindQuoteAndIxs,
    ExternalPointReward,
    usePrincipalBorrowedWithLeverage,
    useFlashPerps
} from "@bridgesplit/abf-react";
import { LOOP_DETAIL_SLUG } from "app/constants";
import { Box } from "@mui/material";
import {
    Column,
    METEORA_SVG,
    Row,
    SPACING,
    Text,
    TextButton,
    TextSkeleton,
    Tooltip,
    TooltipText,
    useAppPalette,
    Icon
} from "@bridgesplit/ui";
import {
    filterTruthy,
    formatPercent,
    percentUiToDecimals,
    TIME,
    formatSeconds,
    getUnixTs,
    calculatePercentChange,
    formatUsd,
    DEBUG
} from "@bridgesplit/utils";
import { LoopRouteType, TokenListMetadata } from "@bridgesplit/abf-sdk";
import { useLocalStorage } from "@bridgesplit/react";
import { SwapHoriz } from "@mui/icons-material";

import { getTokenImageSize, TokenImage, TokenSize } from "./asset";
import { DEFAULT_SLIPPAGE } from "./slippage";
import { ProfitLossText } from "./util";
import { RewardsLoopsApy } from "../points";
import { POINT_BASIS } from "../points/app/types";
enum LoopDisplayType {
    UsdCostBasis,
    TokenCostBasis
}

function useLoopDisplayType() {
    const [displayType, setDisplayType] = useLocalStorage("LOOP_DISPLAY_TYPE", LoopDisplayType.TokenCostBasis);
    return { displayType, setDisplayType };
}

export function getLoopPath(loopExpanded: LoopExpanded) {
    return `${LOOP_DETAIL_SLUG}/${loopExpanded.vaultIdentifier}`;
}

export function LoopImage({
    loopExpanded,
    size: propsSize,
    offset = -0.25,
    badgeSize = "sm"
}: {
    loopExpanded: LoopExpanded | undefined;
    size: TokenSize;
    badgeSize?: TokenSize;
    offset?: number;
}) {
    const size = getTokenImageSize(propsSize);

    const [primaryToken, secondaryToken] =
        loopExpanded?.depositType === LoopTransferType.CollateralOnly
            ? [loopExpanded?.principalToken, loopExpanded?.collateralToken]
            : [loopExpanded?.collateralToken, loopExpanded?.principalToken];

    return (
        <Box sx={{ position: "relative", width: size, height: size }}>
            <TokenImage
                size={badgeSize}
                sx={{ position: "absolute", bottom: SPACING * offset, right: SPACING * offset, zIndex: 1 }}
                metadata={primaryToken}
            />
            <TokenImage size={propsSize} metadata={secondaryToken} />
        </Box>
    );
}

const COLLATERAL_MINT_TO_EXTERNAL_POINT_REWARDS: Record<string, ExternalPointReward[]> = {
    xLebAypjbaQ9tmxUKHV6DZU4mY8ATAAP2sfkNNQLXjf: [ExternalPointReward.Meteora],
    HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX: [ExternalPointReward.HubSol],
    sSo14endRuUbvQaJS3dq36Q829a3A6BEfoeeRGJywEh: [ExternalPointReward.Solayer],
    "4tARAT4ssRYhrENCTxxZrmjL741eE2G23Q1zLPDW2ipf": [ExternalPointReward.Adrastea]
};

export function getLoopExternalPointRewards(loopExpanded: LoopExpanded | undefined): ExternalPointReward[] | undefined {
    if (!loopExpanded) {
        return undefined;
    }
    const collateralMint = loopExpanded.collateralToken.mint;
    return COLLATERAL_MINT_TO_EXTERNAL_POINT_REWARDS[collateralMint];
}
export function LoopApy({
    loopExpanded,
    isFeaturedLoop = false
}: {
    loopExpanded: LoopExpanded | undefined;
    isFeaturedLoop?: boolean;
}) {
    const maxLeverage = loopExpanded?.loopVault.maxLeverage;

    const totalAmount = POINT_BASIS / (loopExpanded?.collateralOracle.usdPrice ?? 1);

    const { principalBorrowedWithLeverageUsd } = usePrincipalBorrowedWithLeverage({
        loopExpanded,
        loopLeverage: maxLeverage,
        totalAmount
    });

    const apy = loopExpanded?.loopVault.maxLeveragedApyPct;
    const metadata = [loopExpanded?.principalToken].filter(filterTruthy)[0];

    const externalPointRewards = getLoopExternalPointRewards(loopExpanded);

    if (!loopExpanded || !metadata || !apy || !maxLeverage) {
        return null;
    }

    return (
        <RewardsLoopsApy
            apy={apy}
            externalPointRewards={externalPointRewards}
            loopscalePointsAction={LoopscalePointsAction.Borrow}
            metadata={metadata}
            maxLeverage={maxLeverage}
            loopAmount={principalBorrowedWithLeverageUsd}
            isFeatured={isFeaturedLoop}
        />
    );
}

export function useLoopPointsDetails({ loopExpanded }: { loopExpanded: LoopExpanded | undefined }) {
    const { isDarkMode } = useAppPalette();

    return useMemo(() => {
        switch (loopExpanded?.loopVault.routeType) {
            case LoopRouteType.Jup:
                return null;
            case LoopRouteType.Meteora:
                return {
                    image: METEORA_SVG,
                    backgroundColor: isDarkMode ? undefined : "#141C37",
                    name: "Meteora"
                };
            default:
                return null;
        }
    }, [isDarkMode, loopExpanded?.loopVault.routeType]);
}

export function useLoopProfitLoss(loopPositionExpanded: LoopPositionExpanded | undefined, options: { skip: boolean }) {
    const isActive = useMemo(() => isLoanActive(loopPositionExpanded?.loanExpanded), [loopPositionExpanded]);
    const { data: externalQuote, isLoading: externalQuoteLoading } = useUnwindQuoteAndIxs({
        loopPositionExpanded,
        slippagePercentDecimals: percentUiToDecimals(DEFAULT_SLIPPAGE),
        skip: options?.skip || !isActive || !loopPositionExpanded,
        disablePoll: true
    });

    const { data: jupiterPrices } = useLoopCollateralPrice({
        tokens: loopPositionExpanded?.loopExpanded
            ? [loopPositionExpanded.loopExpanded.collateralToken, loopPositionExpanded.loopExpanded.principalToken]
            : undefined,
        options: { skip: !loopPositionExpanded }
    });

    return useMemo(() => {
        if (!loopPositionExpanded || !jupiterPrices || externalQuoteLoading) return undefined;

        // fallback on BE calculation if external quote is not available
        if (!isActive) {
            return calculateHistoricalLoopProfitLoss(loopPositionExpanded);
        }

        return calculateUnwindStats({ loopPosition: loopPositionExpanded, externalQuote, jupiterPrices });
    }, [loopPositionExpanded, jupiterPrices, externalQuoteLoading, isActive, externalQuote]);
}

export function useLoopWindAlerts(loopExpanded: LoopExpanded | undefined) {
    const routeType = loopExpanded?.loopVault.routeType;
    let windDisabledWithMessage: string | undefined;

    const { data: flashPerps } = useFlashPerps({ skip: !loopExpanded || routeType !== LoopRouteType.Flash });

    if (!loopExpanded) return { windDisabledWithMessage };

    if (flashPerps && routeType === LoopRouteType.Flash) {
        const maxAumUsd = loopExpanded?.loopVault.aumLimit;
        const aumUsd =
            flashPerps.pools.find((pool) =>
                loopExpanded.collateralToken.symbol.toLocaleLowerCase().includes(pool.flpTokenSymbol.toLowerCase())
            )?.aum ?? undefined;
        const isCapExceeded = maxAumUsd && aumUsd && maxAumUsd < parseFloat(aumUsd);
        if (isCapExceeded) {
            windDisabledWithMessage = "Deposits temporarily disabled due to Flash Trade's AUM cap being exceeded";
        }
    }

    return { windDisabledWithMessage };
}

export function useLoopUnwindAlerts({
    loopPositionExpanded,
    summary,
    variant
}: {
    loopPositionExpanded: LoopPositionExpanded | undefined;
    summary: ReturnType<typeof useLoopProfitLoss>;
    variant?: "preview" | "route";
}) {
    const routeType = loopPositionExpanded?.loopExpanded?.loopVault.routeType;
    let unwindWarningMessage: string | undefined;

    const { displayType } = useLoopDisplayType();

    if (!loopPositionExpanded || summary === undefined) return { unwindWarningMessage };

    const {
        userLoopInfo: { netApy, netPositionValue }
    } = loopPositionExpanded;

    const profitLoss =
        displayType === LoopDisplayType.UsdCostBasis ? summary.profitLossUsd : summary.profitLossTokenAmount ?? 0;
    const netPosition =
        displayType === LoopDisplayType.UsdCostBasis
            ? netPositionValue
            : netPositionValue / loopPositionExpanded.loopExpanded.collateralOracle.usdPrice;

    const isTemporarilyNegativePnl = !!netApy && netApy > 0 && profitLoss < 0;

    if (isLstLoop(loopPositionExpanded?.loopExpanded) && displayType === LoopDisplayType.TokenCostBasis) {
        let stakeWarning = "Staking yield is only compounded every epoch and may not be reflected in your P&L yet";

        if (profitLoss < 0) {
            const maxDuration = calculateDurationInSecondsForCompoundedInterest({
                principal: netPosition,
                desiredInterest: Math.abs(profitLoss ?? 0),
                apy: netApy ?? 0
            });

            stakeWarning = `LST price swings may delay yield earnings. Hold for at least ${formatSeconds(maxDuration, {
                maxUnit: "day"
            })} to offset setup costs`;
        }

        if ((variant === "route" && isTemporarilyNegativePnl) || variant === "preview") {
            unwindWarningMessage = stakeWarning;
        }
    }

    if (routeType === LoopRouteType.Meteora) {
        if (isTemporarilyNegativePnl) {
            const loopApy = formatPercent(netApy);
            if (variant === "route") {
                unwindWarningMessage = `You're earning ${loopApy} APY. Closing now locks in losses. Meteora may not have updated trading fees`;
            } else {
                unwindWarningMessage = `You're earning ${loopApy} APY but your P&L is temporarily negative since Meteora has a setup fee and periodically updates yields`;
            }
        }
    }

    return { unwindWarningMessage };
}

const MIN_TIME_FOR_RECENT_START = TIME.MINUTE * 30;

export function LoopProfitLoss({
    loopPosition,
    gainSummary
}: {
    loopPosition: LoopPositionExpanded | undefined;
    gainSummary: ReturnType<typeof useLoopProfitLoss>;
}) {
    const { displayType } = useLoopDisplayType();

    if (!loopPosition || !gainSummary) return null;

    const recentStartTime =
        Math.max(getUnixTs() - loopPosition.loanExpanded.loan.startTime, 0) < MIN_TIME_FOR_RECENT_START;

    if (recentStartTime && !DEBUG) {
        return (
            <TooltipText
                helpText={`P&L calculation in progress (takes ${formatSeconds(MIN_TIME_FOR_RECENT_START)})`}
                color="disabled"
            >
                Pending
            </TooltipText>
        );
    }

    if (
        // principal only always displays in usd
        loopPosition?.loopExpanded.withdrawType === LoopTransferType.PrincipalOnly ||
        displayType === LoopDisplayType.UsdCostBasis
    ) {
        const percentChange = calculatePercentChange(
            gainSummary.contributions.initialCollateralUsd,
            gainSummary.contributions.initialCollateralUsd + gainSummary.profitLossUsd
        );

        return <ProfitLossText percentChange={percentChange} profitLossUsd={gainSummary?.profitLossUsd} />;
    }

    const percentChange = calculatePercentChange(
        gainSummary.contributions.initialCollateralAmount,
        gainSummary.contributions.initialCollateralAmount + (gainSummary?.profitLossTokenAmount ?? 0)
    );

    return (
        <ProfitLossText
            profitLossTokenAmount={gainSummary?.profitLossTokenAmount}
            metadata={loopPosition?.loopExpanded.collateralToken}
            percentChange={percentChange}
        />
    );
}

export function LoopInitialDeposit({ loopPosition }: { loopPosition: LoopPositionExpanded | undefined }) {
    const { displayType, setDisplayType } = useLoopDisplayType();
    if (!loopPosition) return <TextSkeleton variant="body1" width="60px" />;

    const { loopExpanded, userLoopInfo } = loopPosition;
    const contributions = sumLoopPositionUsdContributions(loopPosition);

    return (
        <Row spacing={0.5}>
            {loopPosition?.loopExpanded.withdrawType !== LoopTransferType.PrincipalOnly && (
                <TextButton
                    color="disabled"
                    onClick={() =>
                        setDisplayType(
                            displayType === LoopDisplayType.UsdCostBasis
                                ? LoopDisplayType.TokenCostBasis
                                : LoopDisplayType.UsdCostBasis
                        )
                    }
                >
                    <SwapHoriz />
                </TextButton>
            )}
            {displayType === LoopDisplayType.UsdCostBasis && (
                <Text>{formatUsd(contributions.initialCollateralUsd)} </Text>
            )}
            {displayType === LoopDisplayType.TokenCostBasis && (
                <Text>
                    {BsMetaUtil.formatAmount(loopExpanded.collateralToken, userLoopInfo.initialCollateralAmount)}{" "}
                </Text>
            )}
        </Row>
    );
}

export const ORACLE_DISCREPANCY_THRESHOLD = 0.05;

export function getOracleDiffFromGainSummary(
    gainSummary: ReturnType<typeof useLoopProfitLoss>,
    loopPosition: LoopPositionExpanded | undefined
) {
    if (!gainSummary || !loopPosition) return undefined;
    const oracleDiff = gainSummary.tokenStats?.oraclePercentDifference;
    const oraclePrice = gainSummary.tokenStats?.oraclePrice;
    const quotePrice = gainSummary.tokenStats?.quotePrice;
    const collateralToken = loopPosition.loopExpanded.collateralToken;

    if (!oraclePrice || !quotePrice || !collateralToken || oracleDiff === undefined) {
        return undefined;
    }

    return { oracleDiff, oraclePrice, quotePrice, collateralToken };
}

export function OracleFairValue({
    loopPosition,
    gainSummary
}: {
    loopPosition: LoopPositionExpanded | undefined;
    gainSummary: ReturnType<typeof useLoopProfitLoss>;
}) {
    if (!gainSummary) return <TextSkeleton variant="body1" width="60px" />;

    const oracleDiff = gainSummary.tokenStats?.oraclePercentDifference;
    const oraclePrice = gainSummary.tokenStats?.oraclePrice;
    const quotePrice = gainSummary.tokenStats?.quotePrice;
    const collateralToken = loopPosition?.loopExpanded.collateralToken;

    // Hide tooltip if any required props are undefined
    if (!oraclePrice || !quotePrice || !collateralToken || oracleDiff === undefined) {
        return (
            <Row spacing={0.5}>
                <Text color="disabled">
                    <Icon type="help" />
                </Text>
                <Text color="caption">No quote found</Text>
            </Row>
        );
    }

    const color = oracleDiff > 0 ? "success" : "warning";

    return (
        <Tooltip
            title={
                <OracleTooltipContent
                    oraclePrice={oraclePrice}
                    quotePrice={quotePrice}
                    oracleDiff={oracleDiff}
                    collateralToken={collateralToken}
                    isWind={false}
                />
            }
            reverseColors
        >
            <Row spacing={0.5}>
                <Text color="disabled">
                    <Icon type="help" />
                </Text>
                <Text color={oracleDiff === 0 ? "caption" : color}>
                    {oracleDiff === 0 ? "No difference" : formatPercent(oracleDiff)}
                </Text>
            </Row>
        </Tooltip>
    );
}

export function OracleTooltipContent({
    oraclePrice,
    quotePrice,
    collateralToken,
    oracleDiff,
    isWind
}: {
    oraclePrice: number;
    quotePrice: number;
    collateralToken: TokenListMetadata | undefined;
    oracleDiff: number;
    isWind: boolean;
}) {
    const symbol = BsMetaUtil.getSymbol(collateralToken);

    const getStartingMessage = () => {
        if (oracleDiff > 0) {
            return `The market price is trading higher than the ${symbol}'s underlying value. `;
        } else {
            return `The market price is trading lower than the ${symbol}'s underlying value. `;
        }
    };

    const getPnLMessage = () => {
        if (isWind) {
            return oracleDiff > 0 ? "This may lead to decreased profits." : "This may lead to increased profits.";
        } else {
            return oracleDiff > 0 ? "This may lead to increased profits." : "This may lead to decreased profits.";
        }
    };

    return (
        <Column spacing={2} padding={1} sx={{ width: "fit-content" }}>
            <Text variant="body1" color="caption">
                {getStartingMessage() + getPnLMessage()}
            </Text>
        </Column>
    );
}
