import {
    LedgerValueTuple,
    StrategyDuration,
    TokenListMetadata,
    TokenListMetadataExpanded,
    WhirlpoolPositionExpanded
} from "@bridgesplit/abf-sdk";
import { SortDirection } from "@bridgesplit/utils";

/** Loans */

export interface AbfLoanResponse {
    loanInfos: LoanInfoResponse[];
    totalCount: number;
}
export interface LoanInfoResponse {
    loan: Loan;
    ledgers: Ledger[];
    pastLedgers: Ledger[];
    collateral: LoanCollateral[];
    events: ParsedLoanEvent[];
    matrixUpdates: LoanMatrixEvent[];
}

export interface LoanMatrixEvent {
    id: number;
    loan: string;
    weights: LedgerValueTuple;
    lqtRatios: LedgerValueTuple;
    ltvRatios: LedgerValueTuple;
    timestamp: number;
    txnSignature: string;
    ledgerIndex: number;
}

export interface LoanExpandedBase extends LoanInfoExpanded {
    index: number;
    status: LoanStatus;
    borrower: string;
    lenders: string[]; //List of strategy addresses
    borrowerWAvgApy: number;
}

export interface AbfLoanExpanded extends LoanExpandedBase {
    principalMetadata: TokenListMetadata; //TODO: Deprecate after multi-principal view is implemented
    principalUsdPrice: number | undefined; //TODO: Deprecate after multi-principal view is implemented
}

export enum LoanInfoSorting {
    NextPayment,
    EndTime,
    StartTime,
    Principal,
    LoanHealth
}

export enum LoanFilterType {
    Active,
    Closed,
    RefinanceEligible,
    TimeBasedLiquidationEligible
}

export interface LoanFilterApi {
    //The loan filter that matches the LoanFilter type on the BE
    loanAddresses: string[];
    lenders: string[];
    borrowers: string[];
    filterType?: number;
    principalMints: string[];
    page?: number;
    pageSize?: number;
    sortSide: SortDirection;
    sortType: LoanInfoSorting;
    orderFundingTypes: AbfOrderFundingType[];
}

export interface LoanFilter {
    //Local loan filter to give me options for the hook to do some pre-filtering
    loanAddresses?: string[];
    lenders: string[];
    borrowers: string[];
    assetMint?: string;
    active?: boolean;
    custodians: string[]; //Depricate
    unclaimed?: boolean; //Depricate
    ignoreRefinanced: boolean; //Should be added to filter for active loans only
    principalMints: string[];
    page?: number;
    pageSize?: number;
    saleEvents: boolean;
    sortSide: SortDirection;
    sortType?: LoanInfoSorting;
    orderFundingTypes: number[];
}
// Use for sell loan
export interface LoanSalesFilter {
    buyers?: string[];
    sellers?: string[];
}

export enum LoanStatusType {
    ActiveOnly,
    InactiveOnly,
    All
}

export interface LoanPaginationInfo {
    loanStatusType: LoanStatusType;
    page: number;
    pageSize: number;
    sortSide: SortDirection;
    sortType: LoanInfoSorting;
    principalMint?: string;
}

export interface LoanSaleEvent {
    id: number;
    loanAddress: string;
    buyer: string;
    seller: string;
    loanSaleAmount: number;
    timestamp: number;
    sellerApy: number;
    buyerApy: number;
    principalPaid: number;
    interestPaid: number;
    feePaid: number;
}

export interface LoanSaleEventExpanded extends LoanSaleEvent {
    isUserBuyer: boolean;
    isUserSeller: boolean;
}

export interface LoanInfo {
    loan: LoanExpanded;
    ledger: LedgerExpanded[]; //The live ledger
    pastLedgers: LedgerExpanded[]; //These are ledgers that have been closed
    collateral: LoanCollateralInfoExpanded[];
    events: ParsedLoanEventExpanded[];
}

export interface LoanInfoExpanded extends LoanInfo {
    pastCollateral: PastLoanCollateral[];
}

export interface LoanExpanded extends Loan {
    refinanceEnabled: boolean;
}
export interface Loan {
    id?: number; // Optional since it can be skipped in serialization
    address: string;
    bump: number;
    loanType: number;
    nonce: string;
    borrower: string;
    startTime: number;
    closed: boolean;
    lastInteractedTime: number;
    lastInteractedTxn: string;
}

export interface LedgerExpanded extends Ledger {
    ledgerDebt: LedgerDebt;
    isFilled: boolean;
}

export interface LedgerDebt {
    total: number;
    interestAccrued: number;
}

export interface Ledger {
    id?: number;
    ledgerIndex: number;
    loan: string;
    status: number;
    strategy: string;
    principalMint: string;
    marketInformation: string;
    principalDue: number;
    principalRepaid: number;
    interestDue: number;
    interestRepaid: number;
    duration: number;
    durationType: number;
    startTime: number;
    endTime: number;
    apy: number;
    weights: LedgerValueTuple;
    ltvRatios: LedgerValueTuple;
    lqtRatios: LedgerValueTuple;
    lastInteractedTime: number;
    lastInteractedTxn: string;
}

export interface ParsedLoanEvent {
    id: number;
    loan: string;
    assetIdentifier: string;
    amount: number;
    originalLender?: string;
    newLender?: string;
    action: LoanEventActionType;
    actionMetadata: ParsedLoanEventActionMetadata;
    eventTime: number;
    usdPrice?: number;
    eventTxn: string;
}

export interface ParsedLoanEventExpanded extends ParsedLoanEvent {
    eventAssetMetadata: TokenListMetadata | undefined;
    matrixSnapshots: LoanMatrixEvent[];
}

export interface LoanCollateralInfoExpanded {
    loanCollateral: LoanCollateralExpanded;
}

export interface PastLoanCollateral {
    id?: number;
    usdPrice: number;
    assetType: AssetTypeIdentifier;
    amount: number;
    metadata: TokenListMetadataExpanded;
    whirlpoolPosition?: WhirlpoolPositionExpanded;
}
export interface LoanCollateral {
    id?: number;
    loan: string;
    index: number;
    assetType: AssetTypeIdentifier;
    assetMint: string;
    assetIdentifier: string;
    amount: number;
}

export interface LoanCollateralExpanded extends LoanCollateral {
    metadata: TokenListMetadata;
    usdPrice: number | undefined;
    whirlpoolPosition?: WhirlpoolPositionExpanded;
}

export enum LoanEventActionType {
    AddCollateral = "addCollateral",
    RemoveCollateral = "removeCollateral",
    BorrowPrincipal = "borrowPrincipal",
    RepayPrincipal = "repayPrincipal",
    RefinanceLedger = "refinanceLedger",
    SellLedger = "sellLedger",
    Liquidate = "liquidate"
}

export enum LiquidationType {
    PartialLiquidation = "PartialLiquidation",
    FullLiquidation = "FullLiquidation"
}

export const LoanEventActionTypeLabels: Record<LoanEventActionType, string> = {
    [LoanEventActionType.AddCollateral]: "Add Collateral",
    [LoanEventActionType.RemoveCollateral]: "Remove Collateral",
    [LoanEventActionType.BorrowPrincipal]: "Borrow Principal",
    [LoanEventActionType.RepayPrincipal]: "Repay Principal",
    [LoanEventActionType.RefinanceLedger]: "Refinance Loan",
    [LoanEventActionType.SellLedger]: "Sell Loan",
    [LoanEventActionType.Liquidate]: "Liquidate"
} as const;

export type ParsedLoanEventActionMetadata = {
    [LoanEventActionType.AddCollateral]: null;
    [LoanEventActionType.RemoveCollateral]: null;
    [LoanEventActionType.BorrowPrincipal]: ParsedBorrowPrincipalMetadata;
    [LoanEventActionType.RepayPrincipal]: ParsedRepayPrincipalMetadata;
    [LoanEventActionType.RefinanceLedger]: ParsedRefinanceLedgerMetadata;
    [LoanEventActionType.SellLedger]: ParsedSellLedgerMetadata;
    [LoanEventActionType.Liquidate]: ParsedLiquidateLedgerExpandedMetadata;
};

export interface ParsedBorrowPrincipalMetadata {
    preBorrowPrincipalLedgerState: ParsedEventLedger;
    postBorrowPrincipalLedgerState: ParsedEventLedger;
}

export interface ParsedRepayPrincipalMetadata {
    preRepaymentPrincipalLedgerState: ParsedEventLedger;
    postRepaymentPrincipalLedgerState: ParsedEventLedger;
}

export interface ParsedRefinanceLedgerMetadata {
    preRefinanceLedgerState: ParsedEventLedger;
    postRefinanceLedgerState: ParsedEventLedger;
}

export interface ParsedSellLedgerMetadata {
    buyer: string;
    seller: string;
}

export enum LiquidateEventType {
    LiquidatedCollateral,
    CollateralReturned
}

export interface ParsedLiquidateLedgerExpandedMetadata extends ParsedLiquidateLedgerMetadata {
    repaymentEventMetadata: ParsedRepayPrincipalMetadata;
    liquidationType: LiquidationType;
}

export interface ParsedLiquidateCollateralMetadata {
    amount: number;
    assetMint: string;
    toLiquidator: boolean;
    price: number;
    liquidateEventType: LiquidateEventType;
}

export interface ParsedLiquidateLedgerMetadata {
    ledgerIndex: number;
    isTimeBasedLiquidation: boolean;
    liquidationFee: number;
    collateralTransfers: ParsedLiquidateCollateralMetadata[]; // Added LiquidateEventType to tuple
}

export interface ParsedEventLedger {
    status: number;
    strategy: string;
    principalMint: string;
    marketInformation: string;
    principalDue: number;
    principalRepaid: number;
    interestDue: number;
    interestRepaid: number;
    duration: StrategyDuration;
    interestPerSecond: number;
    apy: number;
    endTime: number;
}

export enum AbfOrderFundingType {
    Standard = 0,
    Syndicated = 1,
    Loop = 2
}

export enum LoanStatus {
    RepaidLoan = "Repaid",
    OngoingLoan = "Ongoing",
    DefaultedLoan = "Defaulted",
    RefinanceGrace = "Refinancing"
}

export enum AssetTypeIdentifier {
    SplToken = 0, //"HFxmxLSwEbB",
    StakedSol = 1, //"9uBaD2bCVi5",
    OrcaPosition = 2 //"AQBQodNFs6F"
}

export interface CollateralStats {
    depositedCollateral: number;
}
