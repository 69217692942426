import { OutlinedCard } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import OverviewDetails from "./OverviewDetails";
import OverviewStats from "./OverviewStats";
import VaultCharts from "./VaultCharts";

export default function VaultOverview() {
    return (
        <OutlinedCard spacing={2}>
            <OverviewDetails />
            <Divider />
            <OverviewStats />
            <Divider />
            <VaultCharts />
        </OutlinedCard>
    );
}
