import { Text } from "@bridgesplit/ui";
import { COPY } from "app/constants";
import { Divider } from "@mui/material";

import { VaultInfoSection } from "../common";
import ParamsStats from "./ParamsStats";
import TermsDisplay from "./TermsDisplay";

export default function MarketParams() {
    return (
        <VaultInfoSection header="Market parameters">
            <Text variant="body2" color="caption">
                The {COPY.VAULT_TERM.toLowerCase()} {COPY.VAULT_MANAGER_TERM.toLocaleLowerCase()} can update APYs,
                durations, or origination fees at any time. All other changes will require a 24 hour cool-down
            </Text>
            <ParamsStats />
            <Divider />
            <TermsDisplay />
        </VaultInfoSection>
    );
}
