export type JupiterQuoteParams = {
    inputMint: string;
    outputMint: string;
    amount: number;
    slippageBps?: number;
    swapMode?: JupiterSwapMode;
    dexes?: string[];
    excludeDexes?: string[];
    restrictIntermediateTokens?: boolean;
    onlyDirectRoutes?: boolean;
    asLegacyTransaction?: boolean;
    platformFeeBps?: number;
    maxAccounts?: number;
    autoSlippage?: boolean;
    maxAutoSlippageBps?: number;
    autoSlippageCollisionUsdValue?: number;
};

export enum JupiterSwapMode {
    ExactIn = "ExactIn",
    ExactOut = "ExactOut"
}

export type JupiterSwapQuote = {
    inputMint: string;
    inAmount: string;
    outputMint: string;
    outAmount: string;
    otherAmountThreshold: string;
    swapMode: JupiterSwapMode;
    slippageBps: number;
    platformFee?: {
        amount?: string;
        feeBps?: number;
    };
    priceImpactPct: string;
    routePlan: { swapInfo: JupiterSwapInfo; percent: number }[];
    contextSlot: number;
    timeTaken: number;
};

export type JupiterSwapInfo = {
    ammKey: string;
    label?: string;
    inputMint: string;
    outputMint: string;
    inAmount: string;
    outAmount: string;
    feeAmount: string;
    feeMint: string;
};

export type JupiterPriceQuoteParams = { mints: string[]; includeExtraInfo?: boolean };

export type JupiterPriceResponse = {
    data: Record<string, JupiterTokenPrice>;
    timeTaken: number;
};

export type JupiterTokenPrice = {
    id: string;
    /** Midpoint between buyPrice and sellPrice */
    type: JupiterPriceType;
    price: string;
    extraInfo?: JupiterPriceExtraInfo;
};

export enum JupiterPriceType {
    DerivedPrice = "derivedPrice",
    BuyPrice = "buyPrice"
}

export type JupiterPriceExtraInfo = {
    /** Last Jupiter swap details - might be null if token hasn't been traded recently */
    lastSwappedPrice?: {
        lastJupiterSellAt?: number;
        lastJupiterSellPrice?: string;
        lastJupiterBuyAt: number;
        lastJupiterBuyPrice: string;
    };
    /** Quoted prices - sellPrice & sellAt might be null if not cached */
    quotedPrice: {
        buyPrice: string;
        buyAt: number;
        sellPrice?: string;
        sellAt?: number;
    };
    confidenceLevel: JupiterConfidenceLevel;
    depth: {
        /** Price impact ratio for different SOL amounts (10, 100, 1000) */
        buyPriceImpactRatio: JupiterPriceDepthInfo;
        sellPriceImpactRatio: JupiterPriceDepthInfo;
    };
};

export enum JupiterConfidenceLevel {
    High = "high",
    Medium = "medium",
    Low = "low"
}

export type JupiterPriceDepthInfo = {
    /** Key is SOL amount (10, 100, or 1000), value is impact percentage */
    depth: Record<"10" | "100" | "1000", number>;
    timestamp: number;
};

export type FlashTradePerpsPoolResponse = {
    pools: {
        poolAddress: string;
        aum: string;
        flpTokenSymbol: string;
    }[];
};
