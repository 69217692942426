export interface OracleQuoteInfo {
    oracleAccount: string;
    baseMint: string;
    quoteMint: string;
    lastUpdateTime: number;
    spotPrice: number;
    twapPrice: number;
    maxAge: number;
    uncertainty: number;
    oracleType: OracleType;
    nowTimestamp: number;
}

export enum UncertaintyType {
    Skip,
    Add,
    Subtract
}

export interface OracleQuote {
    usdPrice: number;
    oracleAccount: string;
    getUsdAmount: <T extends number | undefined>(amount: T, decimals?: number, uncertaintyType?: UncertaintyType) => T;
    baseQuotes: OracleQuoteInfo[];
    uncertainty: number;
}

export enum OracleType {
    PythLegacy,
    StakePoolLST,
    MeteoraLP,
    MeteoraVault,
    SwitchboardPull,
    OrcaWhirlpool,
    Pyth
}

//Markets oracle
export interface TxnCreateMarketInformationParams {
    principalMint: string;
}

export interface TxnUpdateMarketInformationParams {
    marketInformation: string;
    authority: string;
    assetIdentifier: string;
    quoteMint?: string;
    oracleAccount?: string;
    oracleType?: OracleType;
    maxUncertainty?: number;
    maxAge?: number;
    decimals?: number;
    ltv?: number;
    liquidationThreshold?: number;
    remove?: boolean;
}
