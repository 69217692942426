import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { LoopRouteResponse, LoopUnwindRouteRequest, LoopWindRouteRequest } from "@loopscale/pandora";

import { pandoraBaseQuery } from "./util";
import { ROUTE_LOOP_WIND_QUOTE_IXS, ROUTE_LOOP_UNWIND_QUOTE_IXS } from "../constants";

export interface LoopUnwindRouteRequestExtended extends LoopUnwindRouteRequest {
    loanAddress: string;
}

const LOOP_IXS_TAG = "LOOP_IXS_TAG";
export const loopIxsApi = createApi({
    reducerPath: "loopIxsApi",
    tagTypes: [LOOP_IXS_TAG],
    baseQuery: pandoraBaseQuery,
    endpoints: (builder) => ({
        loopWindQuoteIxs: builder.query<LoopRouteResponse, LoopWindRouteRequest>({
            providesTags: [LOOP_IXS_TAG],
            query: (params) => ({
                url: ROUTE_LOOP_WIND_QUOTE_IXS,
                method: "POST",
                body: params
            })
        }),
        loopUnwindQuoteIxs: builder.query<LoopRouteResponse, LoopUnwindRouteRequestExtended>({
            providesTags: [LOOP_IXS_TAG],
            query: (params) => ({
                url: ROUTE_LOOP_UNWIND_QUOTE_IXS,
                method: "POST",
                body: { ...params }
            }),
            serializeQueryArgs: ({ queryArgs }) => {
              // Create a stable cache key based on important fields
              const { loanAddress } = queryArgs;
              const key = `${loanAddress}`;
              return key;
          },
        })
    })
});

export const { useLoopWindQuoteIxsQuery, useLoopUnwindQuoteIxsQuery } = loopIxsApi;

export function useLoopIxsApi() {
    const dispatch = useDispatch();
    return {
        resetLoopIxsApi: () => dispatch(loopIxsApi.util.invalidateTags([LOOP_IXS_TAG]))
    };
}
