import { EmptyPlaceholderPropsWithCta, Icon } from "@bridgesplit/ui";
import { LockOutlined, LoopOutlined } from "@mui/icons-material";
import { BORROW_SLUG, COPY, LEND_SLUG, LOOPS_SLUG } from "app/constants";

import { AppDialog, useAppDialog } from "./dialog";

export enum EmptyPromptType {
    AssetClassAccess,
    Borrow,
    Lend,
    Loop,
    Vaults
}
export function useEmptyPrompts() {
    const { open } = useAppDialog();

    const prompts: Record<EmptyPromptType, Omit<EmptyPlaceholderPropsWithCta, "header">> = {
        [EmptyPromptType.AssetClassAccess]: {
            icon: <LockOutlined />,
            description: "Get Access with Loopscale Prime",
            cta: {
                text: "Join Prime",
                onClick: () => open(AppDialog.AirtableInterestForm, undefined)
            }
        },
        [EmptyPromptType.Borrow]: {
            icon: <Icon type="borrow" />,
            description: "Start your first fixed-rate loan",
            cta: {
                text: "Borrow",
                to: BORROW_SLUG
            }
        },
        [EmptyPromptType.Lend]: {
            icon: <Icon type="lend" />,
            description: "Start earning fixed interest yield by making your first offer",
            cta: {
                text: "Lend",
                to: LEND_SLUG
            }
        },
        [EmptyPromptType.Loop]: {
            icon: <LoopOutlined />,
            description: `Earn yield with ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`,
            cta: {
                text: `Explore ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`,
                to: LOOPS_SLUG
            }
        },
        [EmptyPromptType.Vaults]: {
            icon: <Icon type="lend" />,
            description: "Start earning fixed interest yield by depositing into a lend vault",
            cta: {
                text: `Explore ${COPY.VAULT_TERM_PLURAL.toLowerCase()}`,
                to: LEND_SLUG
            }
        }
    };

    return function (type: EmptyPromptType, emptyDescription: string): EmptyPlaceholderPropsWithCta {
        return { header: emptyDescription, ...prompts[type] };
    };
}
