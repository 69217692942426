import { useCallback, useMemo } from "react";

import {
    IS_LOCAL_NX_DEV,
    NullableRecord,
    bpsToUiDecimals,
    filterNullableRecord,
    greaterThan,
    lamportsToUiAmount,
    roundDownToDecimals,
    uiAmountToLamports
} from "@bridgesplit/utils";
import { TokenListMetadata, StrategyDuration, UncertaintyType } from "@bridgesplit/abf-sdk";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import {
    BsMetaUtil,
    getAssetMint,
    getAssetIdentifier,
    getAssetType,
    useAbfTypesToUiConverter,
    useBsPrincipalTokens
} from "../utils";
import {
    MarketExpanded,
    MaxQuoteFromCollateralAmount,
    MarketDetailStats,
    BestQuote,
    Collateral,
    CollateralWithMaxQuote,
    AbfLoanExpanded,
    MarketPrincipalStats,
    RefinanceInfoParams,
    EstimatedRefinanceInfo,
    BorrowCap,
    CollateralWithTerms,
    StrategyExpanded,
    QuoteCollateralInfo,
    MarketInformationsFilter,
    SellLoanQuote,
    convertParsedMarketInformationToLtvInfo,
    PresetsFilter,
    converParsedMarketInformationToMarketInformationWithLtvInfo,
    convertMarketInformationResponseToMap,
    LtvWithMarketInformation,
    AssetTypeIdentifier,
    PriceFetchType
} from "../types";
import {
    useTokenListQuery,
    useAllMarketPrincipalStatsQuery,
    useBestQuotesQuery,
    useBorrowCapsQuery,
    useMarketPrincipalStatsByMintQuery,
    useMarketQuotesQuery,
    useMarketStatsQuery,
    useMaxQuotesQuery,
    usePresetPrincipalByMintsQuery,
    useRefinanceInfoQuery,
    useStrategyDurationsQuery,
    useTokenListMetadataByMints,
    useOracleMarketInformationsQuery,
    useSellQuoteQuery
} from "../reducers";
import { useOraclePrices } from "./pricing";
import { useExternalYieldVaults } from "./external-yield";

export function useMarkets(options?: { skip?: boolean }) {
    const { tokens } = useBsPrincipalTokens();

    const mints = tokens?.map((t) => t.mint);
    const { data: marketLoanStatsQuery, isLoading: loanLoading } = useAllMarketPrincipalStatsQuery(undefined, {
        skip: options?.skip
    });
    const { data: strategyStatsQuery, isLoading: strategyLoading } = useMarketStatsQuery(
        { principal: mints ?? [] },
        { skip: !mints || options?.skip }
    );

    const { getBorrowCap, isLoading: borrowCapsLoading } = useMarketBorrowCaps(mints);

    const { getOracle } = useOraclePrices(mints, PriceFetchType.Twap);

    const { convertMarketPrincipalStats, convertStrategyStats } = useAbfTypesToUiConverter(mints);
    const principalStatsMap = useMemo(
        () => (marketLoanStatsQuery ? new Map(Object.entries(marketLoanStatsQuery)) : undefined),
        [marketLoanStatsQuery]
    );
    const strategyMap = useMemo(
        () => (strategyStatsQuery ? new Map(Object.entries(strategyStatsQuery)) : undefined),
        [strategyStatsQuery]
    );

    const { getYieldVault, isLoading: yieldVaultLoading } = useExternalYieldVaults();

    const isLoading = strategyLoading || loanLoading || !tokens || borrowCapsLoading || yieldVaultLoading;

    const data = tokens
        ?.map((metadata): NullableRecord<MarketExpanded> => {
            const usdPrice = getOracle(metadata.mint, PriceFetchType.Twap)?.usdPrice ?? null;
            const strategyStats = strategyMap?.get(metadata.mint) ?? { totalDeposits: 0, durationToMinApy: [] };

            // api will omit mints without loans but these shouldn't be filtered out
            const principalStats: MarketPrincipalStats = principalStatsMap?.get(metadata.mint) ?? {
                principalOriginated: 0,
                principalUtilized: 0
            };
            const borrowCap = getBorrowCap(metadata.mint);

            return {
                strategyStats: strategyStats ? convertStrategyStats(metadata.mint, strategyStats) : undefined,
                principalStats: convertMarketPrincipalStats(metadata.mint, principalStats),
                borrowCap,
                metadata,
                usdPrice,
                yieldVault: getYieldVault(metadata.mint)
            };
        })
        .filter(filterNullableRecord)
        .sort(
            (a, b) =>
                (b.usdPrice ?? 0) * (b.strategyStats.totalDeposits + b.principalStats.principalOriginated) -
                (a.usdPrice ?? 0) * (a.strategyStats.totalDeposits + a.principalStats.principalOriginated)
        );

    return { data: isLoading ? undefined : data, isLoading };
}

export function useMarketStats(mint: string | undefined, options?: { skip?: boolean }) {
    const { data: principalStats, isLoading: principalStatsLoading } = useMarketPrincipalStatsByMintQuery(
        mint ?? skipToken,
        { skip: !mint || options?.skip }
    );
    const { data: strategyStatsResponse, isLoading: strategyLoading } = useMarketStatsQuery(
        {
            principal: mint ? [mint] : [],
            force: true
        },
        { skip: !mint || options?.skip }
    );
    const strategyStats = mint ? strategyStatsResponse?.[mint] : undefined;

    // pass empty array since we already have metadata
    const { convertMarketPrincipalStats, convertStrategyStats } = useAbfTypesToUiConverter([mint]);

    const isLoading = principalStatsLoading || strategyLoading;
    const data = useMemo((): MarketDetailStats | undefined => {
        if (!strategyStats || isLoading || !principalStats || !mint) return undefined;
        return {
            strategyStats: convertStrategyStats(mint, strategyStats),
            principalStats: convertMarketPrincipalStats(mint, principalStats)
        };
    }, [convertMarketPrincipalStats, convertStrategyStats, isLoading, mint, principalStats, strategyStats]);

    return { data, isLoading };
}

export function useMinPrincipalDeposit(principalMetadata: TokenListMetadata | undefined) {
    const { data: rawData } = usePresetPrincipalByMintsQuery(principalMetadata ? [principalMetadata.mint] : skipToken, {
        skip: !principalMetadata?.mint
    });
    const lamportAmount = principalMetadata ? rawData?.[principalMetadata?.mint] : undefined;
    const uiAmount = lamportAmount ? lamportsToUiAmount(lamportAmount, principalMetadata?.decimals) : undefined;
    return { uiAmount, lamportAmount };
}

export function useMarketBorrowCaps(principalMints: string[] | undefined) {
    const { data: rawData, isLoading } = useBorrowCapsQuery(principalMints ?? skipToken, {
        skip: !principalMints?.length
    });

    const { convertBorrowCap } = useAbfTypesToUiConverter(principalMints);

    const data = useMemo(() => {
        return rawData?.map((cap) => convertBorrowCap(cap));
    }, [rawData, convertBorrowCap]);

    const mintToCap = useMemoizedKeyMap(data, (m) => m.principalMint);

    function getBorrowCap(mint: string | undefined) {
        if (!mint || !mintToCap) return undefined;
        return mintToCap.get(mint);
    }

    return { data, getBorrowCap, isLoading };
}

export function useSupportedCollateral(
    principalMint: string | undefined,
    options?: {
        skip?: boolean;
        includePrincipalMint?: boolean;
        requireMarketInformation?: boolean;
    }
) {
    const { data: tokens } = useTokenListQuery(undefined, { skip: options?.skip });
    const { getMarketByCollateralAndPrincipalMint } = usePresetMarketInfos({
        filter: {
            principalMints: principalMint ? [principalMint] : []
        },
        skip: !principalMint || options?.skip
    });

    return tokens?.filter((t) => {
        const hasCollateral = t.isCollateral && (options?.includePrincipalMint ? true : t.mint !== principalMint);
        if (!hasCollateral) return false;
        if (options?.requireMarketInformation) {
            return !!getMarketByCollateralAndPrincipalMint(t.mint, principalMint);
        }
        return true;
    });
}

export function usePresetMarketInfos({ filter, skip }: { filter: PresetsFilter; skip?: boolean }) {
    const { data: markets, isLoading } = useOracleMarketInformationsQuery(
        {
            principalMints: filter.principalMints ?? [],
            addresses: [],
            verified: true
        },
        { skip }
    );

    const { convertMarketInformationResponseWithLtvInfo } = useAbfTypesToUiConverter([]);

    const rawValues = useMemo(() => {
        if (!markets) return undefined;
        return Object.values(markets)
            .map(converParsedMarketInformationToMarketInformationWithLtvInfo)
            .flat()
            .map(convertMarketInformationResponseWithLtvInfo)
            .filter((m) =>
                m.ltvInfo?.find((l) => !filter.collateralMints || filter.collateralMints?.includes(l.assetIdentifier))
            );
    }, [markets, convertMarketInformationResponseWithLtvInfo, filter.collateralMints]);

    const principalMintsToRawValues = useMemoizedKeyMap(rawValues, (m) => m.marketInformation.principalMint);

    const principalToCollateralMintToMarketInfoMap = useMemo(() => {
        if (!principalMintsToRawValues) return undefined;
        const map = new Map<string, Map<string, LtvWithMarketInformation>>();
        for (const [principalMint, rawValues] of principalMintsToRawValues.entries()) {
            convertMarketInformationResponseToMap(rawValues).forEach((info) => {
                const principalMap = map.get(principalMint) ?? new Map<string, LtvWithMarketInformation>();
                principalMap.set(info.ltvInfo.assetIdentifier, info);
                map.set(principalMint, principalMap);
            });
        }
        return map;
    }, [principalMintsToRawValues]);

    const getMarketByCollateralAndPrincipalMint = useCallback(
        (collateralMint: string | undefined, principalMint: string | undefined) => {
            if (!collateralMint || !principalMint || !principalToCollateralMintToMarketInfoMap) return undefined;
            return principalToCollateralMintToMarketInfoMap.get(principalMint)?.get(collateralMint);
        },
        [principalToCollateralMintToMarketInfoMap]
    );

    const getMarketByPrincipalMint = useCallback(
        (principalMint: string | undefined) => {
            if (!principalMint || !principalToCollateralMintToMarketInfoMap) return undefined;
            const marketMap = principalToCollateralMintToMarketInfoMap.get(principalMint);
            if (!marketMap) return undefined;
            return Array.from(marketMap.values());
        },
        [principalToCollateralMintToMarketInfoMap]
    );

    return { data: rawValues, getMarketByCollateralAndPrincipalMint, getMarketByPrincipalMint, isLoading };
}

export function useMarketOracleInfos({ filter, skip }: { filter: MarketInformationsFilter; skip?: boolean }) {
    const { data: marketInformation, isLoading } = useOracleMarketInformationsQuery(filter, { skip });

    const { convertMarketInformationResponseWithLtvInfo } = useAbfTypesToUiConverter([]);

    const getMarketInformationByAddressAndMints = useCallback(
        (
            marketAddress: string | undefined,
            collateralMint: string | undefined,
            principalMint: string | undefined
        ): LtvWithMarketInformation | undefined => {
            if (!marketInformation || !marketAddress || !collateralMint || !principalMint) return undefined;
            const matchingMarketInformation = Object.values(marketInformation).find(
                (info) =>
                    info.marketInformation.address === marketAddress &&
                    info.marketInformation.principalMint === principalMint
            );

            if (!matchingMarketInformation) return undefined;

            const infos = convertParsedMarketInformationToLtvInfo(matchingMarketInformation).filter(
                (m) => m.assetIdentifier === collateralMint
            );

            if (infos.length === 0) return undefined;

            const ltvInfo = infos[0];
            const marketInfo = convertMarketInformationResponseWithLtvInfo({
                marketInformation: matchingMarketInformation.marketInformation,
                ltvInfo: [ltvInfo]
            });
            return {
                marketInformation: marketInfo.marketInformation,
                ltvInfo: marketInfo.ltvInfo[0]
            };
        },
        [marketInformation, convertMarketInformationResponseWithLtvInfo]
    );

    const getMarketInformationsByCollateralMint = useCallback(
        (collateralMint: string | undefined) => {
            if (!marketInformation || !collateralMint) return undefined;

            const marketInfos = [];

            for (const marketInfo of Object.values(marketInformation)) {
                const ltvInfos = convertParsedMarketInformationToLtvInfo(marketInfo);
                const matchingLtvInfo = ltvInfos?.find((l) => l.assetIdentifier === collateralMint);
                if (matchingLtvInfo) {
                    marketInfos.push(matchingLtvInfo);
                }
            }

            return marketInfos;
        },
        [marketInformation]
    );

    const rawValues = useMemo(() => {
        if (!marketInformation) return undefined;
        return Object.values(marketInformation)
            .map(converParsedMarketInformationToMarketInformationWithLtvInfo)
            .flat()
            .map(convertMarketInformationResponseWithLtvInfo);
    }, [marketInformation, convertMarketInformationResponseWithLtvInfo]);

    return {
        data: rawValues,
        getMarketInformationByAddressAndMints,
        getMarketInformationsByCollateralMint,
        isLoading
    };
}

export function useCollateralWithTerms({
    principalMint,
    collateral,
    marketInformationAddresses,
    existingStrategy,
    skip
}: {
    principalMint: string | undefined;
    collateral: TokenListMetadata[] | undefined;
    marketInformationAddresses?: string[];
    existingStrategy?: StrategyExpanded;
    skip?: boolean;
}) {
    const { getMarketInformationByAddressAndMints, isLoading } = useMarketOracleInfos({
        filter: {
            principalMints: principalMint ? [principalMint] : [],
            addresses: marketInformationAddresses ?? [],
            verified: false
        },
        skip: !principalMint || skip
    });

    const { getMarketByCollateralAndPrincipalMint: fetchPresetMarketInfo } = usePresetMarketInfos({
        filter: {
            principalMints: principalMint ? [principalMint] : []
        },
        skip: !principalMint || skip
    });

    const data = useMemo(() => {
        return collateral
            ?.map((metadata): NullableRecord<CollateralWithTerms> => {
                if (existingStrategy) {
                    // Find the matching collateral in the strategy
                    const existingCollateral = existingStrategy?.collateral?.find(
                        (c) => c.metadata.mint === metadata.mint
                    );

                    if (existingCollateral) {
                        return {
                            metadata,
                            ltvInfo: existingCollateral.ltvInfo
                        };
                    }
                }
                const allLtvInfos = getMarketInformationByAddressAndMints(
                    existingStrategy?.strategy.marketInformation,
                    metadata.mint,
                    principalMint
                );
                return {
                    metadata,
                    ltvInfo: allLtvInfos
                        ? {
                              ltv: allLtvInfos.ltvInfo.ltv,
                              liquidationThreshold: allLtvInfos.ltvInfo.liquidationThreshold
                          }
                        : fetchPresetMarketInfo(metadata.mint, principalMint)?.ltvInfo
                };
            })
            .filter(filterNullableRecord);
    }, [collateral, existingStrategy, getMarketInformationByAddressAndMints, principalMint, fetchPresetMarketInfo]);

    return { data, isLoading };
}

type MarketQuoteParams = {
    custodianIdentifier: string | undefined;
    collateralMints: string[] | undefined;
    principalMint: string | undefined;
    minPrincipalAmountLamports?: number;
    limit?: number;
    offset?: number;
    strategyDuration: StrategyDuration | undefined;
};

function useQuotes(
    {
        collateralMints,
        principalMint,
        minPrincipalAmountLamports,
        limit = 1000,
        offset = 0,
        strategyDuration
    }: MarketQuoteParams,
    options?: { skip?: boolean }
) {
    const commonParams =
        principalMint && strategyDuration
            ? {
                  principal: principalMint,
                  minPrincipalAmount: minPrincipalAmountLamports,
                  duration: strategyDuration.duration,
                  durationType: strategyDuration.durationType,
                  limit,
                  offset
              }
            : undefined;

    const skip = !principalMint || !strategyDuration || options?.skip;
    const marketQuery = useMarketQuotesQuery(
        commonParams && collateralMints
            ? {
                  ...commonParams,
                  collateral: collateralMints
              }
            : skipToken,
        { skip: skip || !collateralMints?.length }
    );

    return marketQuery;
}

export function useOrderbookQuotes(params: MarketQuoteParams) {
    const { principalMint } = params;

    const { data: quotes, isLoading, isFetching } = useQuotes(params);
    const { convertOrderbookQuote } = useAbfTypesToUiConverter([principalMint]);
    const data = useMemo(() => {
        if (!quotes || !principalMint) return undefined;
        return quotes?.map((q) => convertOrderbookQuote(q, principalMint)).flat();
    }, [convertOrderbookQuote, principalMint, quotes]);

    return { data, isLoading, isFetching };
}

export function useStrategyDurations() {
    const { data, isLoading } = useStrategyDurationsQuery();
    return { strategyDurations: data, isLoading };
}

type MaxQuoteParams = {
    strategyDuration: StrategyDuration | undefined;
    principalMint: string | undefined;
    collateralToInfo: Map<string, QuoteCollateralInfo> | undefined;
    skip?: boolean;
};
function useMaxQuotes({ strategyDuration, collateralToInfo, principalMint, skip }: MaxQuoteParams) {
    return useMaxQuotesQuery(
        strategyDuration && principalMint && collateralToInfo
            ? {
                  duration: strategyDuration.duration,
                  durationType: strategyDuration.durationType,
                  principal: principalMint,
                  collateralToInfo: Object.fromEntries(collateralToInfo)
              }
            : skipToken,
        {
            skip: !strategyDuration || !principalMint || !collateralToInfo?.size || skip
        }
    );
}

export function useMaxQuotesForCollateral({
    strategyDuration,
    principalMint,
    collateral,
    skip,
    priceFetchType = PriceFetchType.Twap
}: {
    strategyDuration: StrategyDuration | undefined;
    principalMint: string | undefined;
    collateral: Collateral[] | undefined;
    skip?: boolean;
    priceFetchType?: PriceFetchType;
}) {
    const collateralToInfo = useMemo(() => {
        return collateral?.reduce((map, curr) => {
            const amountLamports = uiAmountToLamports(curr.amount, curr.metadata?.decimals ?? 0);
            if (!amountLamports) return map;

            // find max balance for given mint (ie. staked sol)
            const prev = map.get(curr.metadata.mint)?.amount ?? 0;
            const maxBalance = Math.max(prev, amountLamports);

            // only query if balance

            if (maxBalance) {
                map.set(curr.metadata.mint, {
                    amount: maxBalance,
                    assetType: getAssetType(curr.metadata),
                    assetMint: getAssetMint(curr.metadata),
                    assetIdentifier: getAssetIdentifier(curr.metadata)
                });
            }
            return map;
        }, new Map<string, QuoteCollateralInfo>());
    }, [collateral]);

    const {
        currentData: maxQuotes,
        isLoading: quotesLoading,
        isFetching
    } = useMaxQuotes({ collateralToInfo, strategyDuration, principalMint, skip });

    const principalPriceUsd = useOraclePrices([principalMint], priceFetchType).getUsdPrice(
        principalMint,
        UncertaintyType.Add
    );
    const isLoading = principalPriceUsd === undefined || quotesLoading;

    const { convertOfferQuote, getDecimals } = useAbfTypesToUiConverter([principalMint]);

    const collateralToMaxQuote = useMemoizedKeyMap(maxQuotes, (q) => q.collateralMint);

    const principalDecimals = getDecimals(principalMint);

    const data = useMemo(() => {
        if (!collateral || !principalMint || (!collateralToMaxQuote && collateralToInfo?.size)) return undefined;
        return collateral
            .map((asset): CollateralWithMaxQuote => {
                const quoteRaw = collateralToMaxQuote?.get(asset.metadata.mint);
                const quote = quoteRaw ? convertOfferQuote(quoteRaw, principalMint) : quoteRaw;

                const collateralPriceUsd = asset.usdPrice;

                if (!quote) {
                    return { ...asset, maxQuote: undefined };
                }

                const collateralUsd = collateralPriceUsd ? asset.amount * collateralPriceUsd : asset.usdValue;

                if (!principalPriceUsd || !collateralUsd) {
                    return { ...asset, maxQuote: { ...quote, maxBorrow: 0 } };
                }

                // max borrow needs to be rounded down manually since LTV might not be evenly divisible
                const maxBorrowFromQuote = roundDownToDecimals(
                    collateralUsd * (1 / principalPriceUsd) * quote.ltv,
                    principalDecimals
                );

                const maxBorrow = Math.min(quote.principalAvailable, maxBorrowFromQuote);

                const maxQuote: MaxQuoteFromCollateralAmount = { ...quote, maxBorrow };

                return { ...asset, maxQuote };
            })
            .sort((a, b) => {
                const maxBorrowSort = (b.maxQuote?.maxBorrow ?? 0) - (a.maxQuote?.maxBorrow ?? 0);
                if (maxBorrowSort !== 0) {
                    return maxBorrowSort;
                }
                const balanceSort = b.amount - a.amount;
                if (balanceSort !== 0) {
                    return balanceSort;
                }
                return BsMetaUtil.getSymbolUnique(a.metadata).localeCompare(BsMetaUtil.getSymbolUnique(b.metadata));
            });
    }, [
        collateral,
        principalMint,
        collateralToMaxQuote,
        collateralToInfo?.size,
        convertOfferQuote,
        principalPriceUsd,
        principalDecimals
    ]);

    return { data, isLoading, isFetching };
}

type BestQuoteParams = {
    collateralMintToInfo: Map<string, QuoteCollateralInfo> | undefined;
    principalMint: string | undefined;
    strategyDurations: StrategyDuration[] | undefined;
    minPrincipalAmountLamports: number | undefined;
    showSelf?: boolean;
    skip?: boolean;
};
export function useBestQuotes({
    collateralMintToInfo,
    principalMint,
    strategyDurations,
    minPrincipalAmountLamports,
    showSelf,
    skip
}: BestQuoteParams) {
    const hasRequiredData = strategyDurations?.length && principalMint && collateralMintToInfo?.size;
    const shouldSkip = !hasRequiredData || skip;

    const queryParams = hasRequiredData
        ? {
              durations: strategyDurations,
              collateralToInfo: Object.fromEntries(collateralMintToInfo.entries()),
              principalMint,
              minPrincipalAmount: minPrincipalAmountLamports || 0,
              showSelf
          }
        : skipToken;

    const { data, isLoading, isFetching } = useBestQuotesQuery(queryParams, {
        skip: shouldSkip,
        pollingInterval: IS_LOCAL_NX_DEV ? undefined : 15_000
    });

    const { convertOfferQuote } = useAbfTypesToUiConverter([principalMint]);

    const quotes = useMemo(() => {
        if (!data || !principalMint) return undefined;
        return data?.map((q) => convertOfferQuote(q, principalMint));
    }, [data, principalMint, convertOfferQuote]);

    return { data: quotes, isLoading, isFetching };
}

export function useGlobalBestOffers({
    principalMint,
    collateralMints,
    strategyDurations,
    collateralUsdAmount = 100_000,
    skip,
    showSelf
}: {
    principalMint: string | undefined;
    collateralMints: string[] | undefined;
    strategyDurations: StrategyDuration[] | undefined;
    collateralUsdAmount?: number;
    skip?: boolean;
    showSelf?: boolean;
}) {
    const { data: minPrincipal } = usePresetPrincipalByMintsQuery(principalMint ? [principalMint] : skipToken, {
        skip: !principalMint || skip
    });

    const { getUsdPrice } = useOraclePrices(collateralMints);
    const { getMetadata } = useTokenListMetadataByMints(collateralMints);

    const collateralMintToInfo: Map<string, QuoteCollateralInfo> = new Map();

    collateralMints?.forEach((collateralMint) => {
        const usdPrice = getUsdPrice(collateralMint);
        const metadata = getMetadata(collateralMint);
        if (!metadata || !usdPrice) return;
        const assetType = getAssetType(metadata);
        const amount =
            assetType === AssetTypeIdentifier.OrcaPosition
                ? 1
                : uiAmountToLamports(collateralUsdAmount / usdPrice, metadata?.decimals);

        const assetIdentifier = getAssetIdentifier(metadata);
        const assetMint = getAssetMint(metadata);
        collateralMintToInfo.set(collateralMint, {
            amount,
            assetType,
            assetMint,
            assetIdentifier
        });
    });

    const minPrincipalAmountLamports = principalMint ? minPrincipal?.[principalMint] : undefined;

    const { data: bestQuotes } = useBestQuotes({
        minPrincipalAmountLamports,
        collateralMintToInfo,
        principalMint,
        strategyDurations,
        showSelf,
        skip: skip
    });

    const bestQuotesUnique = new Map<string, BestQuote>();

    if (!bestQuotes) return undefined;
    bestQuotes?.forEach((quote) => {
        const key = `${quote.duration}${quote.durationType}`;
        const prev = bestQuotesUnique.get(key);
        if (!prev || quote.apy < prev.apy) {
            bestQuotesUnique.set(key, quote);
        }
    });

    return Array.from(bestQuotesUnique.values());
}

export function useSellQuote(loanExpanded: AbfLoanExpanded | undefined) {
    const { data: rawData, ...query } = useSellQuoteQuery(loanExpanded?.loan.address ?? skipToken, {
        skip: !loanExpanded
    });
    const data = useMemo((): SellLoanQuote | undefined => {
        if (!rawData) return undefined;
        const decimals = loanExpanded?.principalMetadata?.decimals ?? 0;
        return {
            ...rawData,
            fairSalePriceAtQuoteRate: lamportsToUiAmount(rawData.fairSalePriceAtQuoteRate, decimals),
            valueLeft: lamportsToUiAmount(rawData.valueLeft, decimals),
            salePrice: lamportsToUiAmount(rawData.salePrice, decimals)
        };
    }, [loanExpanded?.principalMetadata?.decimals, rawData]);

    return { data, ...query };
}

export function useRefinanceInfo(params: RefinanceInfoParams | undefined) {
    const { data: rawData, ...query } = useRefinanceInfoQuery(params ?? skipToken, {
        skip: !params || !Object.keys(params ?? {}).length
    });

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return new Map(
            Object.entries(rawData).map(([principalMint, refinanceInfos]) => [
                principalMint,
                new Map(
                    refinanceInfos.map((info): [string, EstimatedRefinanceInfo] => [
                        info.collateralMint,
                        {
                            collateralMint: info.collateralMint,
                            ltv: bpsToUiDecimals(info.ltv),
                            liquidationThreshold: bpsToUiDecimals(info.liquidationThreshold)
                        }
                    ])
                )
            ])
        );
    }, [rawData]);

    const getRefinanceInfo = useCallback(
        (principalMint: string | undefined, collateralMint: string | undefined) => {
            if (!principalMint || !collateralMint) return undefined;
            return data?.get(principalMint)?.get(collateralMint);
        },
        [data]
    );

    return { data, getRefinanceInfo, isLoading: query.isLoading || !data, isFetching: query.isFetching };
}

const WARNING_THRESHOLD = 0.98; // 98%
export function getBorrowCapDetails({
    borrowCap,
    token,
    principalUtilized
}: {
    borrowCap: BorrowCap | null;
    token: TokenListMetadata;
    principalUtilized: number;
}) {
    const globalBorrowCap = borrowCap?.global || 0;
    const percentOfCap = principalUtilized / (globalBorrowCap || 1);
    const warning = !!borrowCap && percentOfCap >= WARNING_THRESHOLD;

    const remainingGlobalCapacity = Math.max(globalBorrowCap - principalUtilized, 0);

    const warningMessage = (function warningMessage() {
        if (!warning) return null;
        if (percentOfCap >= 1) return "The market cap for borrows has been reached";
        return `This market only has ${BsMetaUtil.formatAmount(
            token,
            remainingGlobalCapacity
        )} remaining borrow capacity`;
    })();

    function exceedsPerLoanCap(amount: number | undefined) {
        if (!borrowCap) return false;
        return greaterThan(amount, borrowCap?.perLoan);
    }

    function exceedsGlobalCap(amount: number | undefined) {
        if (!remainingGlobalCapacity) return false;
        return greaterThan(amount, remainingGlobalCapacity);
    }

    return { borrowCap, percentOfCap, warningMessage, exceedsPerLoanCap, exceedsGlobalCap, remainingGlobalCapacity };
}
