import { MarketInformation, MarketInformationLtvInfo } from "@bridgesplit/abf-sdk";

export interface TurnOracleParams {
    baseMint: string;
    quoteMint: string;
}

export interface MarketInformationWithLtvInfoResponse {
    marketInformation: MarketInformation;
    ltvInfo: MarketInformationLtvInfo[];
}

// flattened version of MarketInformationWithLtvInfoResponse, used for collateral specific fetching
export interface LtvWithMarketInformation {
    marketInformation: MarketInformation;
    ltvInfo: MarketInformationLtvInfo;
}

export function convertMarketInformationResponseWithLtvInfo(
    marketInformation: MarketInformationWithLtvInfoResponse
): LtvWithMarketInformation[] {
    return marketInformation.ltvInfo.map((l) => ({
        marketInformation: marketInformation.marketInformation,
        ltvInfo: l
    }));
}

export function fetchLtvWithMarketInformation(
    marketInformation: MarketInformationWithLtvInfoResponse,
    collateralMint: string
): LtvWithMarketInformation | undefined {
    const ltvInfo = marketInformation.ltvInfo.find((l) => l.assetIdentifier === collateralMint);
    if (!ltvInfo) return undefined;
    return {
        marketInformation: marketInformation.marketInformation,
        ltvInfo
    };
}

export function convertMarketInformationResponseToMap(
    marketInformation: MarketInformationWithLtvInfoResponse
): Map<string, LtvWithMarketInformation> {
    const map = new Map<string, LtvWithMarketInformation>();
    const converted = convertMarketInformationResponseWithLtvInfo(marketInformation);
    converted.forEach((c) => map.set(c.ltvInfo.assetIdentifier, c));
    return map;
}
