import { Fragment, memo } from "react";

import { useAppTheme, useAppNavigate } from "@bridgesplit/react";
import {
    Icon,
    MenuItemProps,
    MenuItems,
    Select,
    useCopyAddress,
    useCopyToClipboard,
    VerticalScroll
} from "@bridgesplit/ui";
import {
    AccountBoxOutlined,
    ContrastOutlined,
    Logout,
    PieChartOutline,
    ShareOutlined,
    StarOutline
} from "@mui/icons-material";
import { useAbfLogout, useAuth, useActiveWallet, useUserReferral } from "@bridgesplit/abf-react";
import { Divider } from "@mui/material";
import { ACCOUNT_SETTINGS_SLUG, PRIME_SLUG, PORTFOLIO_OVERVIEW_SLUG, BS_SHORT_URL_INVITE } from "app/constants";
import { AppDialog, useAppDialog } from "app/utils";

export default memo(function ProfileDropdown({ close }: { close: () => void }) {
    const pageItems = usePageItems();
    const appItems = useAppControls();

    const sections = [pageItems, appItems].filter((item) => !!item.some((i) => !i.hide));

    return (
        <>
            {sections.map((items, i) => (
                <Fragment key={i}>
                    {i !== 0 && <Divider flexItem />}
                    <VerticalScroll sx={{ minHeight: "auto" }} maxHeight={230}>
                        <MenuItems items={items} px={2} py={1} spacing={1} iconWidth="30px" close={close} />
                    </VerticalScroll>
                </Fragment>
            ))}
        </>
    );
});

function ThemeSwitch() {
    const { theme, updateTheme } = useAppTheme();

    return (
        <Select
            sx={{ width: "100px", height: "30px" }}
            value={theme}
            setValue={updateTheme}
            options={[
                { value: "auto", label: "Device" },
                { value: "light", label: "Light" },
                { value: "dark", label: "Dark" }
            ]}
        />
    );
}

function useAppControls(): MenuItemProps[] {
    const { logOut } = useAbfLogout();
    const { isWalletBased } = useAuth();
    const copy = useCopyAddress();
    const copyToClipboard = useCopyToClipboard();

    const { activeWallet } = useActiveWallet();
    const { data: referral } = useUserReferral();
    const { open } = useAppDialog();

    const referralLink = `${BS_SHORT_URL_INVITE}/${referral?.userReferralCode}`;

    return [
        {
            text: "Theme",
            icon: <ContrastOutlined />,
            disableClose: true,
            rowEnd: <ThemeSwitch />
        },
        {
            text: "Transaction settings",
            icon: <Icon type="settings" />,
            onClick: () => open(AppDialog.TransactionSettings, undefined)
        },
        {
            text: "Copy address",
            icon: <Icon type="copy" />,
            onClick: () => copy(activeWallet ?? ""),
            hide: !activeWallet || !isWalletBased
        },
        {
            text: "Share referral link",
            icon: <ShareOutlined />,
            onClick: () => copyToClipboard(referralLink, { successMessage: `${referralLink} copied to clipboard` }),
            hide: !referral?.userReferralCode
        },
        {
            text: isWalletBased ? "Disconnect" : "Log Out",
            icon: <Logout />,
            onClick: logOut
        }
    ];
}

function usePageItems() {
    const navigate = useAppNavigate();

    const items: MenuItemProps[] = [
        {
            text: "Portfolio",
            icon: <PieChartOutline />,
            onClick: () => navigate(PORTFOLIO_OVERVIEW_SLUG)
        },
        {
            text: "Account",
            icon: <AccountBoxOutlined />,
            onClick: () => navigate(ACCOUNT_SETTINGS_SLUG),
            hide: true
        },
        {
            text: "Loopscale Prime",
            onClick: () => navigate(PRIME_SLUG),
            icon: <StarOutline />,
            hide: true
        }
    ];

    return items;
}
