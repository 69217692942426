import { StatRow, StatProps, TextVariant, Icon, Text } from "@bridgesplit/ui";
import { formatNum, formatUsd } from "@bridgesplit/utils";
import { useCurrentTime, UserPointsFunction, useUserPoints } from "@bridgesplit/abf-react";

import { usePortfolioStats } from "./util";
import { ProfitLossText } from "../../common";
import { COPY } from "../../../constants";

export default function PortfolioOverviewStats({ variant }: { variant: TextVariant }) {
    const overviewStats = usePortfolioStats()?.overviewStats;
    const activePortfolioRewards = usePortfolioStats()?.activePortfolioRewards;
    const { getPointsAtTime, isLoading: pointsLoading } = useUserPoints();

    const stats: StatProps[] = [
        {
            value: formatUsd(overviewStats?.netValue),
            caption: "Net value"
        },
        {
            value: <ProfitLossText variant={variant} profitLossUsd={overviewStats?.totalInterestEarned} />,
            caption: COPY.INTEREST_EARNED_LABEL,
            hide: true
        },
        {
            value: (
                <Text svgColor="secondary" variant={variant}>
                    <Icon type="points" />
                    <Points getPoints={getPointsAtTime} />
                </Text>
            ),
            caption: "Rewards",
            tooltip:
                "Points are earned gradually as you interact with the protocol. Please allow up to a few days for updates to reflect"
        }
    ];

    const rewardBreakdown = [
        {
            value: (
                <Text svgColor="secondary" variant={variant}>
                    <Icon type="points" />
                    {formatNum(activePortfolioRewards?.activeLendVaultDailyPoints ?? 0)}
                </Text>
            ),
            caption: "Vault points / day"
        },
        {
            value: (
                <Text svgColor="secondary" variant={variant}>
                    <Icon type="points" />
                    {formatNum(activePortfolioRewards?.activeBorrowDailyPoints ?? 0)}
                </Text>
            ),
            caption: "Borrow points / day"
        },
        {
            value: (
                <Text svgColor="secondary" variant={variant}>
                    <Icon type="points" />
                    {formatNum(activePortfolioRewards?.activeStrategyDailyLendPoints ?? 0)}
                </Text>
            ),
            caption: "Lend points / day"
        }
    ];

    return (
        <>
            <StatRow captionVariant="body2" variant={variant} loading={!overviewStats || pointsLoading} stats={stats} />
            <StatRow
                captionVariant="body2"
                variant={variant}
                loading={!overviewStats || pointsLoading}
                stats={rewardBreakdown}
            />
        </>
    );
}

function Points({ getPoints }: { getPoints: UserPointsFunction }) {
    const { now } = useCurrentTime();
    const data = getPoints(now);

    return <> {formatNum(data?.totalPoints ?? 0)} </>;
}
