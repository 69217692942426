import { BackCta, Column, Link, Row, Text } from "@bridgesplit/ui";
import { COPY, LOOPS_SLUG } from "app/constants";
import { doNothing } from "@bridgesplit/utils";
import { MarketGuideMode } from "@bridgesplit/abf-react";

import { useLoopContext } from "../LoopContext";
import { LoopImage, OpenHowItWorks } from "../../common";

export default function LoopDetailHeader() {
    const { loopExpanded } = useLoopContext();
    return (
        <Column spacing={2}>
            <Link to={LOOPS_SLUG}>
                <BackCta back={doNothing} backCta={`All ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`} />
            </Link>

            <Row spacing={2}>
                <LoopImage offset={-0.25} loopExpanded={loopExpanded} badgeSize="sm" size="lg" />
                <Text variant="h3">{loopExpanded?.loopVault.name}</Text>
                {loopExpanded && (
                    <OpenHowItWorks variant="outlined" mode={MarketGuideMode.Earn} loopExpanded={loopExpanded} />
                )}
            </Row>
        </Column>
    );
}
