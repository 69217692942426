import { AbfLoanExpanded, WhirlpoolOffchain } from "@bridgesplit/abf-react";
import { TokenListMetadata, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import {
    AddLiquidityQuote,
    OpenPositionQuote,
    OrcaWhirlpoolClient,
    RemoveLiquidityQuote
} from "@orca-so/whirlpool-sdk";
import { DispatchType } from "@bridgesplit/react";

import { Slippage } from "../../../common";

export type WhirlpoolPositionData = Awaited<ReturnType<typeof OrcaWhirlpoolClient.prototype.data.getPosition>>;
export type WhirlpoolPoolData = Awaited<ReturnType<typeof OrcaWhirlpoolClient.prototype.data.getPool>>;

export type OriginalWhirlpoolDeposit = {
    mint: string;
    decimals: number;
    metadata: TokenListMetadata;
    usdPrice: number;
    uiAmount: number;
    usdAmount: number;
    lamportAmount: number;
};

type Status = "updated" | "refetching" | "refetch-needed" | "error" | "silent-refetch";

export type WhirlpoolAmountsForm = {
    tokenAAmount?: number;
    tokenBAmount?: number;
    side?: "A" | "B";
    status?: Status;
};

export type OpenPositionForm = WhirlpoolAmountsForm & {
    tickLower?: number;
    tickUpper?: number;

    pricesInitialized?: boolean;
    amountsInitialized?: boolean;
};

export type DepositForm = WhirlpoolAmountsForm;

export type WithdrawForm = {
    percentToWithdraw?: number;
    status?: Status;
};

export type WhirlpoolContextValue = {
    loanExpanded: AbfLoanExpanded;
    whirlpoolPosition: WhirlpoolPositionExpanded;
};

export type WhirlpoolContextType = WhirlpoolContextValue & {
    whirlpoolOffchain: WhirlpoolOffchain | null | undefined;
    positionData: WhirlpoolPositionData | null | undefined;
    setPositionData: (data: WhirlpoolPositionData | null | undefined) => void;
    poolData: WhirlpoolPoolData | null | undefined;
    setPoolData: (data: WhirlpoolPoolData | null | undefined) => void;
    initialWithdrawQuote: RemoveLiquidityQuote | undefined;
    setInitialWithdrawQuote: DispatchType<RemoveLiquidityQuote | undefined>;

    openPositionForm: OpenPositionForm;
    setOpenPositionForm: DispatchType<OpenPositionForm>;
    openPositionQuote: OpenPositionQuote | undefined;
    setOpenPositionQuote: (quote: OpenPositionQuote | undefined) => void;

    addLiquidityQuote: AddLiquidityQuote | undefined;
    setAddLiquidityQuote: DispatchType<AddLiquidityQuote | undefined>;
    depositForm: DepositForm;
    setDepositForm: DispatchType<DepositForm>;

    withdrawForm: WithdrawForm;
    setWithdrawForm: DispatchType<WithdrawForm>;
    withdrawQuote: RemoveLiquidityQuote | undefined;
    setWithdrawQuote: DispatchType<RemoveLiquidityQuote | undefined>;

    isFlippedPrice: boolean;
    setIsFlippedPrice: DispatchType<boolean>;
    reset: () => void;
    poll: () => void;
    slippageController: ReturnType<typeof Slippage.useController>;
};

export type YieldData = {
    positionYieldOverTvl24h: number;
    positionYieldOverTvlAnnual: number;
    positionUsdValue: number;
};

export enum YieldPeriodSelection {
    DAILY = "daily",
    ANNUAL = "annual"
}
