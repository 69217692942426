import { Fragment, ReactNode, useCallback, useMemo } from "react";

import { BsMetaUtil, useOraclePrices } from "@bridgesplit/abf-react";
import {
    Autocomplete,
    BORDER_RADIUS,
    BORDER_RADIUS_ROUNDED,
    Button,
    ButtonProps,
    Checkbox,
    Column,
    FONT_SIZES,
    FONT_WEIGHTS,
    FormInput,
    FormNumberInputProps,
    INPUT_HEIGHT,
    Icon,
    IconButton,
    Image,
    InputWrapper,
    LOGO_URL,
    LabelWrapper,
    MEDIA,
    MediaQuery,
    OutlinedCard,
    PHONE_NUMBER_PATTERN,
    PopoverWrapper,
    Row,
    SPACING,
    Select,
    SkeletonRounded,
    Span,
    StatColumn,
    StatContainer,
    StatRow,
    SxType,
    Text,
    TextButton,
    TextColor,
    TextLink,
    TextProps,
    TextSkeleton,
    TextVariant,
    ToggleButtons,
    ToggleOption,
    ToggleTextButtons,
    TooltipText,
    repeatElement,
    useAppPalette,
    useCopyAddress,
    useElementDimensions,
    useMemoizedKeyMap,
    usePopover,
    useResetWithCoolDown
} from "@bridgesplit/ui";
import {
    IS_LOCAL_NX_DEV,
    SortDirection,
    TIME,
    WRAPPED_SOL_MINT,
    abbreviateString,
    formatBytes,
    formatPercent,
    formatUsd
} from "@bridgesplit/utils";
import {
    AttachmentOutlined,
    ContentCopyOutlined,
    ExpandMore,
    FilterAltOutlined,
    NorthOutlined,
    Refresh,
    SouthOutlined,
    UploadFileOutlined
} from "@mui/icons-material";
import {
    DEFAULT_COUNTRY_CODE,
    DispatchType,
    FileExtensionEnum,
    useCountryCodesQuery,
    useLocalStorage
} from "@bridgesplit/react";
import { ChainId, TokenListMetadata } from "@bridgesplit/abf-sdk";
import QRCode from "react-qr-code";
import Markdown from "react-markdown";
import { Box, Skeleton } from "@mui/material";
import { AppTab } from "app/constants";
import { AppDialog, AppDialogData, useAppDialog } from "app/utils";

import { TokenImage } from "./asset";

export function getTabPath(path: string, tab: AppTab) {
    return `${path}?open=${tab}`;
}

export function StyledMarkdown({ text }: { text: string }) {
    const { textSecondary, textPrimary } = useAppPalette();

    // render markdown as normal text locally due to errors
    if (IS_LOCAL_NX_DEV) {
        return <>{text}</>;
    }

    return (
        <Column
            spacing={1}
            sx={{
                strong: { color: textPrimary, fontWeight: FONT_WEIGHTS.bold },
                a: { color: textPrimary, ":hover": { color: textSecondary } }
            }}
        >
            <Markdown
                components={{
                    a: (props) => (
                        <TextLink
                            underlineLink="always"
                            isLink
                            sx={{ display: "inline-block" }}
                            onClick={() => window.open(props.href)}
                        >
                            {props.children}
                        </TextLink>
                    ),
                    strong: (props) => (
                        <Text variant="h4" sx={{ display: "inline-block" }}>
                            {props.children}
                        </Text>
                    ),
                    p: (props) => (
                        <Text color="caption" sx={{ display: "inline-block" }}>
                            {props.children}
                        </Text>
                    )
                }}
            >
                {text}
            </Markdown>
        </Column>
    );
}

interface TokenAmountInputProps {
    value: number | undefined;
    setValue: (n: number | undefined) => void;
    metadata: TokenListMetadata | undefined;
    maxAmount?: number;
    showFungibleMaxBalance?: boolean;
    disabled?: boolean;
    warningMessage?: string;
    maxNameLength?: number;
    validateAmounts?: boolean;
}
export function TokenAmountInput({
    metadata,
    maxAmount,
    value,
    setValue: setValueRaw,
    showFungibleMaxBalance = true,
    disabled,
    warningMessage,
    maxNameLength = 20,
    validateAmounts,
    ...props
}: TokenAmountInputProps) {
    const { textDisabled } = useAppPalette();
    function setValue(val: number | undefined) {
        if (disabled) return;
        setValueRaw(val);
    }

    const handleClick = () => (value === maxAmount ? setValue(0) : setValue(maxAmount ?? 1));

    const disabledSx: SxType = {
        width: "100%",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer"
    };

    const warningIcon = warningMessage ? (
        <TooltipText icon={false} helpText={warningMessage} color="error">
            <Icon type="warning" />
        </TooltipText>
    ) : null;

    const name = BsMetaUtil.getName(metadata);
    const nameTooltip = maxNameLength && name.length > maxNameLength ? name : undefined;
    const displayName = maxNameLength ? abbreviateString(name, maxNameLength) : name;

    if (BsMetaUtil.isNonFungible(metadata)) {
        return (
            <Row onClick={handleClick} spaceBetween sx={disabledSx} spacing={1}>
                <Row spacing={1}>
                    <Image size="30px" src={BsMetaUtil.getImage(metadata)} />
                    <TooltipText icon={false} helpText={nameTooltip}>
                        {displayName}
                    </TooltipText>
                    {warningIcon}
                </Row>
                <Checkbox disabled={disabled} checked={!!value} />
            </Row>
        );
    }

    return (
        <Row onClick={handleClick} spaceBetween sx={disabledSx} spacing={1}>
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="md" />
                <Column>
                    <Row spacing={1}>
                        <TooltipText icon={false} helpText={nameTooltip}>
                            {displayName}
                        </TooltipText>
                        {warningIcon}
                    </Row>

                    <Row spacing={0.5}>
                        <Text color="caption" variant="caption">
                            {BsMetaUtil.formatAmount(metadata, maxAmount)}
                        </Text>
                        <Text isLink variant="caption">
                            {value === maxAmount ? "Clear" : "Max"}
                        </Text>
                    </Row>
                </Column>
            </Row>
            <FormInput
                validateNumber={validateAmounts ? (num) => (maxAmount && num ? maxAmount >= num : true) : undefined}
                onClick={(e) => e.stopPropagation()}
                inputProps={{
                    sx: {
                        padding: 0,
                        color: value === 0 ? textDisabled : undefined,
                        textAlign: "right",
                        border: "none"
                    }
                }}
                sx={{ maxWidth: "120px", "& fieldset": { border: "none" } }}
                variant="number"
                decimals={metadata?.decimals}
                focused
                disabled={disabled}
                setValue={setValue}
                placeholder="0"
                value={value}
                {...props}
            />
        </Row>
    );
}

export function PhoneCountryCodeInput({ value, setValue }: { value: string; setValue: (val: string) => void }) {
    const { data: codes } = useCountryCodesQuery();
    const options = codes ?? [DEFAULT_COUNTRY_CODE];
    const activeCode =
        codes?.filter((c) => c.dialCode === value).sort((a, b) => b.code.localeCompare(a.code))?.[0] ??
        DEFAULT_COUNTRY_CODE;

    return (
        <Select
            maxMenuHeight={300}
            hideIcon
            sx={{ maxWidth: "max-content", p: 0 }}
            renderValue={() => (
                <>
                    {activeCode.flag} {activeCode.dialCode}
                </>
            )}
            options={options.map((c) => ({
                key: c.name,
                label: (
                    <Row sx={{ width: "250px" }} spaceBetween>
                        <Row spacing={1}>
                            <Text>{c.flag}</Text>
                            <Text>{abbreviateString(c.name)}</Text>
                        </Row>
                        <Text color="caption"> {c.dialCode} </Text>
                    </Row>
                ),
                value: c.dialCode
            }))}
            value={value}
            setValue={setValue}
        />
    );
}

type PhoneForm<T> = T & { countryCode: string; phoneNumber: string | undefined };
export function PhoneNumberInput<FormType>({
    form,
    setForm
}: {
    form: PhoneForm<FormType>;
    setForm: DispatchType<PhoneForm<FormType>>;
}) {
    return (
        <LabelWrapper label="Phone number">
            <Row spacing={1}>
                <PhoneCountryCodeInput
                    value={form.countryCode}
                    setValue={(countryCode) => setForm((prev) => ({ ...prev, countryCode }))}
                />
                <FormInput
                    type="tel"
                    fullWidth
                    placeholder="(123) 456-7890"
                    value={form.phoneNumber}
                    pattern={{ format: PHONE_NUMBER_PATTERN }}
                    setValue={(phoneNumber) => setForm((prev) => ({ ...prev, phoneNumber }))}
                    variant="string"
                />
            </Row>
        </LabelWrapper>
    );
}

export function FileUpload({
    file,
    setFile,
    cta = "Select a file",
    acceptedFiles,
    maxFileSizeBytes
}: {
    file: File | undefined;
    setFile: (file: File | undefined) => void;
    acceptedFiles: FileExtensionEnum[];
    cta?: string;
    maxFileSizeBytes?: number;
}) {
    return (
        <OutlinedCard padding={1.5} spacing={2}>
            <Row spacing={1} spaceBetween>
                <Column>
                    <Text variant="h4">{cta} </Text>
                    <Text variant="body2" color="caption">
                        {acceptedFiles.join(", ").toUpperCase()} files.
                        {maxFileSizeBytes ? ` Max size ${formatBytes(maxFileSizeBytes)}.` : ""}
                    </Text>
                </Column>

                <Button width="max-content" component="label">
                    <input
                        onChange={(e) => {
                            setFile(e.target.files?.item(0) ?? undefined);
                        }}
                        hidden
                        accept="application/pdf"
                        type="file"
                    />
                    <UploadFileOutlined /> Select File
                </Button>
            </Row>

            <Text color="caption">
                <AttachmentOutlined /> {file ? file.name : "No file selected"}
            </Text>
        </OutlinedCard>
    );
}

export function QrCode({
    specifiedMint,
    address,
    chainId,
    size
}: {
    specifiedMint?: string;
    address: string | undefined;
    chainId: ChainId;
    size: number;
}) {
    const sizePx = size + "px";

    const value = useMemo(() => {
        if (!specifiedMint) return address;

        if (chainId === ChainId.Solana) {
            if (specifiedMint === WRAPPED_SOL_MINT) return `solana:${address}`;

            return `solana:${address}?spl-token=${specifiedMint}`;
        }

        return address;
    }, [address, chainId, specifiedMint]);

    if (!value) return <SkeletonRounded width={sizePx} height={sizePx} />;
    return (
        <Column>
            <QRCode size={size} value={value} />
        </Column>
    );
}

export function QrCodeCopyAddress({
    address,
    specifiedMint,
    label = "Your Loopscale Address",
    chainId,
    size
}: {
    address: string | undefined;
    specifiedMint?: string;
    label?: string;
    chainId: ChainId;
    size?: "normal" | "large";
}) {
    const copy = useCopyAddress();

    return (
        <Row sx={{ flexDirection: size === "large" ? "column" : undefined }} spacing={2}>
            <OutlinedCard sx={{ background: "white" }} hideCard={size !== "large"}>
                <QrCode
                    chainId={chainId}
                    size={size === "large" ? 100 : 60}
                    address={address}
                    specifiedMint={specifiedMint}
                />
            </OutlinedCard>

            <Column spacing={0.5}>
                <Text color="caption" variant="body2">
                    {label}
                </Text>
                <Row sx={{ cursor: "pointer" }} onClick={() => copy(address)} spacing={1}>
                    <Text loading={!address} sx={{ wordBreak: "break-all" }}>
                        {address}
                    </Text>
                    <IconButton textColor="disabled" textVariant="body2" jsxIcon={<ContentCopyOutlined />} />
                </Row>
            </Column>
        </Row>
    );
}

interface TokenInputWithPriceProps {
    value: number | undefined;
    setValue: (n: number | undefined) => void;
    metadata: TokenListMetadata | undefined;
    belowInput?: ReactNode;
    disabled?: boolean;
    disableTokenSelect?: boolean;
    label?: string;
    selectToken: AppDialogData<AppDialog.SelectToken> | (() => void) | null;
    loading?: boolean;
    autoFocus?: boolean;
    labelColor?: TextColor;
    formNumberInputProps?: Omit<FormNumberInputProps, "value" | "setValue" | "variant">;
}
export function TokenInputWithPrice({
    value,
    setValue,
    metadata,
    loading,
    disabled,
    label,
    selectToken,
    autoFocus,
    labelColor = "caption",
    disableTokenSelect,
    belowInput,
    formNumberInputProps
}: TokenInputWithPriceProps) {
    const { getOracle } = useOraclePrices([metadata?.mint]);

    const usdPrice = getOracle(metadata?.mint)?.getUsdAmount(value, metadata?.decimals);

    return (
        <Column spacing={0.5}>
            {!!label && (
                <Text variant="body2" color={labelColor}>
                    {label}
                </Text>
            )}
            <InputWrapper height={SPACING * 6.5} sx={{ justifyContent: "space-between" }}>
                <TokenSwitch metadata={metadata} selectToken={selectToken} disableTokenSelect={disableTokenSelect} />
                {loading ? (
                    <Skeleton sx={{ height: FONT_SIZES.h1, width: "70px", justifyContent: "flex-end" }} />
                ) : (
                    <Column sx={{ width: "100%", justifyContent: "flex-end", textAlign: "right", overflow: "hidden" }}>
                        <FormInput
                            autoFocus={autoFocus}
                            onClick={(e) => e.stopPropagation()}
                            inputProps={{
                                sx: {
                                    padding: 0,
                                    textAlign: "right",
                                    border: "none",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }
                            }}
                            fullWidth
                            sx={{
                                height: SPACING * 3,
                                "& fieldset": { border: "none" },
                                "& .MuiInputBase-root": { overflow: "hidden" }
                            }}
                            variant="number"
                            decimals={metadata?.decimals}
                            disabled={disabled}
                            setValue={setValue}
                            placeholder="0"
                            value={value}
                            {...formNumberInputProps}
                        />
                        {!!usdPrice && (
                            <Text sx={{ justifyContent: "flex-end" }} variant="caption" color="caption">
                                {formatUsd(usdPrice)}
                            </Text>
                        )}
                    </Column>
                )}
            </InputWrapper>
            {belowInput}
        </Column>
    );
}

export function NullTokenInput({ onClick, loading }: { onClick?: () => void; loading?: boolean }) {
    return (
        <Column spacing={0.5}>
            <Text variant="body2">Your collateral</Text>
            <InputWrapper height={SPACING * 6.5} sx={{ justifyContent: "space-between" }}>
                <NullTokenSwitch onClick={onClick} loading={loading} />
            </InputWrapper>
        </Column>
    );
}
export function NullTokenSwitch({ onClick, loading }: { onClick?: () => void; loading?: boolean }) {
    return (
        <Button
            disableRipple
            width="max-content"
            height={30}
            textProps={{ variant: "body2" }}
            sx={{ px: 1.5, borderRadius: BORDER_RADIUS_ROUNDED }}
            variant="outlined"
            onClick={(e) => {
                if (onClick) {
                    onClick();
                }
                e.preventDefault(); // Prevent default behavior
            }}
            disabled={loading}
        >
            <Row sx={{ minWidth: "max-content" }} spacing={1}>
                <Image skeletonVariant="circular" variant="circle" size={FONT_SIZES.body1 + "px"} src={LOGO_URL} />
                <Span sx={{ width: "max-content", alignItems: "center", display: "flex" }}>
                    {loading ? <TextSkeleton variant="body1" width="80px" /> : "Select a token"}
                    <ExpandMore />
                </Span>
            </Row>
        </Button>
    );
}

function TokenSwitch({
    metadata,
    selectToken,
    disableTokenSelect
}: Pick<TokenInputWithPriceProps, "metadata" | "selectToken" | "disableTokenSelect">) {
    const { open } = useAppDialog();

    if (selectToken === null) {
        return (
            <Row sx={{ minWidth: "max-content" }} spacing={1}>
                <TokenImage metadata={metadata} size="sm" />
                <Text loading={!metadata} loadingWidth="30px">
                    {BsMetaUtil.getSymbol(metadata)}
                </Text>
            </Row>
        );
    }

    return (
        <Button
            disableRipple
            width="max-content"
            height={30}
            textProps={{ variant: "body2" }}
            sx={{ px: 1.5, borderRadius: BORDER_RADIUS_ROUNDED }}
            variant="outlined"
            onClick={(e) => {
                // prevents double click
                if (e.detail === 0) return;
                if (disableTokenSelect) return;
                if (typeof selectToken === "object") {
                    return open(AppDialog.SelectToken, selectToken);
                }
                selectToken();
            }}
        >
            <TokenImage metadata={metadata} size="sm" />
            <Span sx={{ width: "max-content", alignItems: "center", display: "flex" }}>
                {metadata ? BsMetaUtil.getSymbol(metadata) : <TextSkeleton variant="body1" width="30px" />}{" "}
                <ExpandMore />
            </Span>
        </Button>
    );
}

const RATES_COMPARISON_PERIODS: ToggleOption<number>[] = [
    { value: TIME.DAY, label: "1D" },
    { value: TIME.WEEK, label: "1W" },
    { value: TIME.MONTH, label: "1M" }
];

export function useRatesComparisonPeriodSelect(key: string) {
    const [period, setPeriod] = useLocalStorage(key, TIME.WEEK);
    return { period, setPeriod };
}

export function RatesComparisonPeriodSelect({ period, setPeriod }: ReturnType<typeof useRatesComparisonPeriodSelect>) {
    return <ToggleTextButtons value={period} setValue={setPeriod} options={RATES_COMPARISON_PERIODS} />;
}

export function ProfitLossText({
    variant,
    arrowSize = 12,
    fontWeight,
    metadata,
    profitLossTokenAmount,
    profitLossUsd,
    condensedOnMobile,
    percentChange,
    query = MEDIA.SM
}: {
    arrowSize?: number;
    metadata?: TokenListMetadata;
    profitLossTokenAmount?: number | undefined;
    profitLossUsd?: number;
    condensedOnMobile?: boolean;
    percentChange?: number;
    query?: MediaQuery;
} & Pick<TextProps, "variant" | "fontWeight">) {
    const { error, success, textDisabled } = useAppPalette();

    const pnl = profitLossTokenAmount ?? profitLossUsd ?? 0;
    const isLoading = profitLossTokenAmount === undefined && profitLossUsd === undefined;

    const { icon, color } = useMemo(() => {
        if (pnl > 0) return { icon: <NorthOutlined />, color: success };
        if (pnl < 0) return { icon: <SouthOutlined />, color: error };
        return { icon: null, color: textDisabled };
    }, [error, pnl, success, textDisabled]);

    return (
        <Row spacing={0.5}>
            {!!pnl && (
                <Text
                    variant="caption"
                    sx={{
                        gap: 0.5,
                        color,
                        svg: { fontSize: `${arrowSize}px` }
                    }}
                >
                    {icon}
                    {percentChange !== undefined && formatPercent(percentChange)}
                </Text>
            )}
            {profitLossTokenAmount !== undefined && !!metadata && (
                <Text loading={isLoading} variant={variant} fontWeight={fontWeight}>
                    {BsMetaUtil.formatAmount(metadata, Math.abs(profitLossTokenAmount))}
                </Text>
            )}
            {profitLossUsd !== undefined && (
                <Text
                    variant={variant}
                    sx={
                        condensedOnMobile && profitLossTokenAmount !== undefined
                            ? { [query.below]: { display: "none" } }
                            : undefined
                    }
                    color={profitLossTokenAmount !== undefined ? "caption" : "body"}
                >
                    {formatUsd(Math.abs(profitLossUsd))}
                </Text>
            )}
        </Row>
    );
}

export function FilterButtonPopover({
    children,
    popoverProps,
    reset,
    icon
}: {
    children: ReactNode;
    popoverProps: ReturnType<typeof usePopover>;
    reset: () => void;
    icon?: ReactNode;
}) {
    return (
        <>
            <PopoverWrapper transformOrigin={{ vertical: -1 * SPACING, horizontal: SPACING * 2 }} {...popoverProps}>
                <Column sx={{ minWidth: "300px", p: 2 }} spacing={2}>
                    <Row spaceBetween>
                        <Text>Filters</Text>
                        <TextButton onClick={popoverProps.close}>
                            <Icon type="reject" />
                        </TextButton>
                    </Row>
                    {children}
                    <TextButton color="caption" onClick={reset}>
                        Clear filters
                    </TextButton>
                </Column>
            </PopoverWrapper>

            <IconButton
                sx={{ borderRadius: BORDER_RADIUS, height: INPUT_HEIGHT, width: INPUT_HEIGHT }}
                onClick={popoverProps.open}
                textVariant="h4"
                textColor="caption"
                jsxIcon={icon ?? <FilterAltOutlined />}
            />
        </>
    );
}

export function PastActiveToggle({
    value,
    setValue,
    query = MEDIA.LG
}: {
    value: boolean;
    setValue: (b: boolean) => void;
    query?: MediaQuery;
}) {
    return (
        <ToggleButtons
            sx={{ [query.above]: { width: "200px" } }}
            value={value ? 1 : 0}
            setValue={(val) => setValue(!!val)}
            options={[
                { value: 1, label: "Ongoing" },
                { value: 0, label: "Past" }
            ]}
        />
    );
}

export function SortDirectionButton({
    direction,
    setDirection
}: {
    direction: SortDirection | undefined;
    setDirection: (sortSide: SortDirection) => void;
}) {
    return (
        <IconButton
            sx={{ borderRadius: BORDER_RADIUS }}
            onClick={() => setDirection(direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc)}
            textVariant="h4"
            textColor="caption"
            jsxIcon={
                <SouthOutlined
                    sx={{
                        transform: direction === SortDirection.Asc ? "rotate(-180deg)" : undefined,
                        transition: "300ms ease-in-out"
                    }}
                />
            }
        />
    );
}

export function RefreshButton({ refresh }: { refresh: () => void }) {
    const resetWithCoolDown = useResetWithCoolDown();
    return (
        <IconButton
            textVariant="caption"
            textColor="caption"
            sx={{ p: 0.5 }}
            jsxIcon={<Refresh />}
            onClick={() => resetWithCoolDown(refresh)}
        />
    );
}

export function SettingsButton({ children, sx, ...props }: Omit<ButtonProps, "textProps">) {
    const { textDisabled } = useAppPalette();
    return (
        <Button
            height={22}
            variant="outlined"
            sx={{ p: 1.5, borderRadius: BORDER_RADIUS_ROUNDED, ...sx }}
            textProps={{
                variant: "body2",
                sx: { gap: 1, svg: { color: textDisabled, fontSize: "inherit" } }
            }}
            {...props}
        >
            {children}
        </Button>
    );
}

export function PositionCardStats({
    variant = "body1",
    forceColumn,
    ...props
}: StatContainer & { forceColumn?: boolean }) {
    const [ref, { width }] = useElementDimensions();
    const isRow = width > 800 || forceColumn;

    return (
        <Box ref={ref}>
            {isRow ? <StatRow variant={variant} {...props} /> : <StatColumn variant={variant} {...props} />}
        </Box>
    );
}

export function TagsDisplay({
    tags,
    dotSize = 3,
    variant = "body2"
}: {
    tags: string[] | string | undefined;
    dotSize?: number;
    variant?: TextVariant;
}) {
    const tagsArray = useMemo(() => {
        if (tags === undefined || !tags?.length) return [];
        if (Array.isArray(tags)) return tags;
        const tagsParsed = JSON.parse(tags);
        if (Array.isArray(tagsParsed) && tagsParsed.every((tag) => typeof tag === "string")) return tagsParsed;
        return [];
    }, [tags]);

    return (
        <Row spacing={0.5}>
            {tags === undefined && repeatElement(<TextSkeleton variant={variant} width="20px" />)}
            {tagsArray.map((tag, i) => (
                <Fragment key={tag}>
                    {i !== 0 && (
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.palette.text.disabled,
                                width: dotSize,
                                height: dotSize,
                                borderRadius: "100%"
                            }}
                        />
                    )}
                    <Text variant={variant} color="caption">
                        {tag}
                    </Text>
                </Fragment>
            ))}
        </Row>
    );
}

export function TokenAutoSelect({
    mint,
    setMint,
    tokens,
    placeholder,
    disableClearable
}: {
    mint: string | undefined;
    setMint: (mint: string | undefined) => void;
    tokens: TokenListMetadata[] | undefined;
    placeholder?: string;
    disableClearable?: boolean;
}) {
    const getKey = useCallback((t: TokenListMetadata) => t.mint, []);
    const mintToMetadata = useMemoizedKeyMap(tokens ?? [], getKey);
    const selectedToken = mintToMetadata?.get(mint ?? "");
    return (
        <Autocomplete
            getOptionLabel={(t) => BsMetaUtil.getSymbol(mintToMetadata?.get(t))}
            InputProps={{
                startAdornment: selectedToken ? <TokenImage metadata={selectedToken} size="sm" /> : undefined
            }}
            sx={{ minWidth: "200px" }}
            renderOption={(mint) => {
                const token = mintToMetadata?.get(mint);
                if (!token) return <>None</>;
                return (
                    <Row spacing={0.5}>
                        <TokenImage metadata={token} size="sm" />
                        {BsMetaUtil.getSymbol(token)}
                    </Row>
                );
            }}
            disableClearable={disableClearable}
            placeholder={placeholder ?? "Search by token"}
            options={tokens?.map(getKey)}
            value={mint}
            setValue={setMint}
        />
    );
}
