import { StatProps } from "@bridgesplit/ui";
import { formatPercent, formatUsd } from "@bridgesplit/utils";

import { usePortfolioStats } from "./util";
import { PortfolioTableStats } from "./common";

export default function LoopsStats() {
    const loopsStats = usePortfolioStats()?.loopStats;

    const stats: StatProps[] = [
        {
            value: formatUsd(loopsStats?.netPositionValue),
            caption: "Net position"
        },
        {
            value: formatPercent(loopsStats?.wAvgApy),
            caption: "APY"
        }
    ];

    return <PortfolioTableStats stats={stats} loading={!loopsStats} />;
}
