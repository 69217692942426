import { ReactNode } from "react";

import { Column, OutlinedCard, Row, Text } from "@bridgesplit/ui";

export function VaultInfoSection({
    children,
    header,
    rowEnd
}: {
    children: React.ReactNode;
    header: string;
    rowEnd?: ReactNode;
}) {
    return (
        <Column spacing={2}>
            <Row>
                <Text variant="h3">{header}</Text>
                {rowEnd}
            </Row>
            <OutlinedCard spacing={2}>{children}</OutlinedCard>
        </Column>
    );
}
