import { MayString, STAKED_SOL_MINT, formatTokenAmount } from "@bridgesplit/utils";
import { ChainId, ExtraLink, TokenListMetadata, TokenListMetadataExpanded, TokenListTag } from "@bridgesplit/abf-sdk";

import { useTokenListMetadataByMints } from "../reducers";
import { AssetTypeIdentifier, LoanCollateral, TokenBalance } from "../types";

export const SOL_CUSTODIAN = "so1eR6vHFtUSmj9tkjVhuaf8A8ksLJaFFurf8L3xS1y";

const PLACEHOLDER_IMAGE = "https://bridgesplit-app.s3.amazonaws.com/tokens/placeholder.png";

export function useMetadataDetailsByMint(mint: string | undefined) {
    const { getMetadata, isLoading: metadataLoading, isFetching } = useTokenListMetadataByMints([mint]);

    const metadata = getMetadata(mint);

    const custodian = undefined;

    return {
        metadata,
        custodian,
        isLoading: metadataLoading,
        notFound: !metadata && !metadataLoading && !isFetching
    };
}

type BsMetaUtilInput = TokenListMetadataExpanded | undefined | null;

export class BsMetaUtil {
    static getName(metadata: BsMetaUtilInput): string {
        return this.getNameNoFallback(metadata) || "Unknown Asset";
    }

    static getNameNoFallback(metadata: BsMetaUtilInput): string | undefined {
        let name = metadata?.name?.replace(/\0/g, "");

        if (name) {
            return name;
        }

        name = metadata?.name?.replace(/\0/g, "");
        if (name) {
            return name;
        }

        return undefined;
    }

    static getImage(metadata: BsMetaUtilInput, options?: { hideCdn?: boolean; size?: number }): string {
        if (!metadata) return "";
        let image = metadata.imageUrl;

        if (!image) {
            return PLACEHOLDER_IMAGE;
        }
        image = parseImageUri(image);
        if (options?.hideCdn) return image;
        const defaultSize = this.isFungible(metadata) ? 60 : 400;
        return getCdnUrl(image, options?.size ?? defaultSize);
    }

    static getAllImages(metadata: BsMetaUtilInput, options?: { hideCdn?: boolean; size?: number }): string[] {
        return [this.getImage(metadata, options)];
    }

    static getExternalFiles(metadata: BsMetaUtilInput): ExtraLink[] | undefined {
        if (!metadata) return undefined;
        return [];
    }

    static getDescription(metadata: BsMetaUtilInput) {
        return "No description provided";
    }

    static getSymbol(metadata: BsMetaUtilInput) {
        if (!metadata) return "";
        if (metadata.symbol) return metadata.symbol;
        return "UNKNOWN";
    }

    static getNameOrSymbol(metadata: BsMetaUtilInput) {
        if (this.isNonFungible(metadata)) {
            return this.getName(metadata);
        }
        return this.getSymbol(metadata);
    }

    static isFungible(metadata: BsMetaUtilInput) {
        if (!metadata) return false;
        return metadata?.decimals !== 0;
    }

    static isNonFungible(metadata: BsMetaUtilInput) {
        return metadata?.decimals === 0;
    }

    static formatAmount(
        metadata: BsMetaUtilInput,
        amount: number | undefined,
        options?: { hideSymbol?: boolean; decimals?: number }
    ) {
        const isLp = metadata?.tags?.includes(TokenListTag.LP);
        if (this.isNonFungible(metadata)) {
            if (isLp) {
                return this.getSymbol(metadata);
            }
            return this.getSymbolUnique(metadata);
        }

        return formatTokenAmount(amount, {
            symbol: isLp ? this.getSymbol(metadata) : BsMetaUtil.getSymbolUnique(metadata),
            decimals: options?.decimals ?? metadata?.decimals,
            hideSymbol: options?.hideSymbol
        });
    }

    static getChainId(metadata: BsMetaUtilInput) {
        return ChainId.Solana;
    }

    static isValid(input: object | undefined | null): input is TokenListMetadata {
        if (!input) return false;
        if ("mint" in input && "assetType" in input) return true;
        return false;
    }

    // Returns unique name for NFTs, LPs, and other assets with the same token symbol
    static getSymbolUnique(metadata: BsMetaUtilInput): string {
        if (this.isNonFungible(metadata) || metadata?.tags?.includes(TokenListTag.LP)) {
            return this.getName(metadata);
        }
        return this.getSymbol(metadata);
    }
}

export function getCdnUrl(image: MayString, size: number) {
    return `https://cdn.loopscale.com/?fit=crop&height=${size}&width=${size}&image=${image}`;
}

export function parseImageUri(image: string) {
    return image.replace("ipfs://", "https://ipfs.io/ipfs/");
}

export function getSearchFromMetadata(metadata: BsMetaUtilInput) {
    return [BsMetaUtil.getName(metadata), BsMetaUtil.getSymbol(metadata)];
}

export function isStakedSol(metadataOrAssetType: BsMetaUtilInput | AssetTypeIdentifier | string) {
    if (typeof metadataOrAssetType === "string") {
        return metadataOrAssetType === STAKED_SOL_MINT;
    }
    if (typeof metadataOrAssetType === "number") {
        return metadataOrAssetType === AssetTypeIdentifier.StakedSol;
    }
    return metadataOrAssetType?.mint === STAKED_SOL_MINT;
}

export function formatTokens(
    tokens: Omit<TokenBalance, "mint">[] | undefined,
    options?: { hideSymbol?: boolean; condensed?: boolean; showAll?: boolean }
) {
    if (tokens?.length === 1) {
        const token = tokens[0];
        if (BsMetaUtil.isNonFungible(token.metadata) && options?.condensed) {
            return BsMetaUtil.getSymbol(token.metadata);
        }
        return BsMetaUtil.formatAmount(token.metadata, token.amount, { hideSymbol: options?.hideSymbol });
    }

    if (options?.showAll) {
        return tokens
            ?.map((t) => BsMetaUtil.formatAmount(t.metadata, t.amount, { hideSymbol: options?.hideSymbol }))
            .join(" + ");
    }

    return `${tokens?.length || 0} assets`;
}

export function formatTokenMetadata(tokens: TokenListMetadata[] | undefined, term = "assets") {
    return tokens?.length === 1 ? BsMetaUtil.getSymbol(tokens[0]) : `${tokens?.length || 0} ${term}`;
}

export function getAssetIdentifier(metadata: BsMetaUtilInput) {
    if (!metadata) return "";

    if (metadata.assetType === AssetTypeIdentifier.OrcaPosition && metadata.whirlpoolExpanded) {
        return metadata.whirlpoolExpanded?.position.whirlpool;
    }
    return metadata.mint;
}

export function getAssetMint(metadata: BsMetaUtilInput) {
    if (!metadata) return "";
    if (metadata?.assetType === AssetTypeIdentifier.OrcaPosition && metadata?.whirlpoolExpanded) {
        return metadata.whirlpoolExpanded?.position.positionMint;
    }
    return metadata?.mint;
}

export function getAssetType(metadata: BsMetaUtilInput): number {
    if (!metadata) return 0;
    return metadata.assetType;
}

export function isCollateralSplMint(asset: LoanCollateral) {
    if ("assetType" in asset) {
        return asset.assetType === AssetTypeIdentifier.SplToken;
    }

    return false;
}
