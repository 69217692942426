import {
    SetupLoanArgs,
    getSetupLoanTransactions,
    getBorrowPrincipalTransaction,
    BorrowPrincipalTxnParams,
    getDepositCollateralTransaction,
    ExpectedLoanValues,
    getWithdrawCollateralTransaction,
    getRepayLoanTransaction,
    RepayLoanTxnParams,
    getRefinanceLoanTransaction,
    RefinanceLoanTxnParams,
    convertLedgerValueTupleToBps,
    DepositCollateralParams
} from "@bridgesplit/abf-sdk";
import { OrderedTransactions, ParallelTransactionsBatch, combineTransactionPromises } from "@bridgesplit/react";
import { decimalsToBps, Result, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithSetup } from "./common";
import { TRANSACTION_DEFAULT_BATCH_COMMITMENT } from "../constants";

export function useRepayLoanTransaction(): AbfTransactionDetails<RepayLoanTxnParams> {
    const { resetLoanApi } = useAbfFetches();
    const generate = useAbfGenerateTransaction();

    async function getTransactionsWithParams(params: RepayLoanTxnParams): AbfGeneratorResult {
        try {
            const repay = generate({
                generateFunction: getRepayLoanTransaction,
                identifier: "Repay loan",
                params
            });

            const transactions = await combineTransactionPromises([repay], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        allowBatchFailure: false,
        refetch: () => {
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Repaying Loan" };
}

type SetupLoanParams = {
    setup: SetupLoanArgs;
};
export function useSetupLoanTransaction(): AbfTransactionDetails<SetupLoanParams> {
    const generateWithSetup = useAbfGenerateTransactionWithSetup();

    const { resetLoanApi } = useAbfFetches();

    async function getTransactionsWithParams({ setup }: SetupLoanParams): AbfGeneratorResult {
        try {
            const setupLoan = generateWithSetup({
                generateFunction: getSetupLoanTransactions,
                identifier: "Setup loan",
                params: setup
            });

            const generatedTxns = await Promise.all([setupLoan]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [setupTxn] = [generatedTxns[0].unwrap()];

            const txns: OrderedTransactions = [
                {
                    transactions: setupTxn.setupTransactions,
                    commitmentLevel: "confirmed"
                },
                { transactions: setupTxn.transactions, commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT }
            ];
            return Result.ok(txns);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Setting up loan" };
}
export interface WithdrawCollateralTransactionParams {
    loan: string;
    collateralMint: string;
    amount: number;
    collateralIndex: number;
    assetIndexGuidance?: string; //Do not need to add derived in BE
    weightMatrix?: string; //Do not need to add derived in BE
    expectedLoanValues: ExpectedLoanValues;
}
export function useLoanWithdrawTransaction(): AbfTransactionDetails<WithdrawCollateralTransactionParams> {
    const { resetLoanApi, resetUserAssetsApi } = useAbfFetches();
    const generate = useAbfGenerateTransaction();

    async function getTransactionsWithParams(params: WithdrawCollateralTransactionParams): AbfGeneratorResult {
        try {
            const collateralWithdraw = generate({
                generateFunction: getWithdrawCollateralTransaction,
                identifier: "Withdraw assets",
                params
            });

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([collateralWithdraw]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [depositTxn] = generatedTxns;

            if (depositTxn) {
                transactions.push({
                    transactions: [...(depositTxn?.unwrap() ?? [])]
                });
            }
            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetUserAssetsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing Loan" };
}

export function useLoanRefinanceTransaction(): AbfTransactionDetails<RefinanceLoanTxnParams> {
    const { resetLoanApi, resetUserAssetsApi } = useAbfFetches();
    const generate = useAbfGenerateTransaction();

    async function getTransactionsWithParams(params: RefinanceLoanTxnParams): AbfGeneratorResult {
        try {
            const refinanceLoan = generate({
                generateFunction: getRefinanceLoanTransaction,
                identifier: "Refinance loan",
                params
            });

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([refinanceLoan]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [refinanceTxn] = generatedTxns;

            if (refinanceTxn) {
                transactions.push({
                    transactions: [...(refinanceTxn?.unwrap() ?? [])]
                });
            }
            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetUserAssetsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Refinance loan" };
}

export interface IncreaseCreditTransactionParams extends BorrowPrincipalTxnParams {
    decimals: number;
}
export function useIncreasePrincipalTransaction(): AbfTransactionDetails<IncreaseCreditTransactionParams> {
    const generate = useAbfGenerateTransaction();
    const { resetUserAssetsApi, resetLoanApi, resetNapoleonApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams({
        borrowParams: { amount: increaseAmount, expectedLoanValues },
        decimals,
        ...params
    }: IncreaseCreditTransactionParams): AbfGeneratorResult {
        try {
            const increasePrincipal = generate({
                generateFunction: getBorrowPrincipalTransaction,
                identifier: "Borrow more",
                params: {
                    ...params,
                    borrowParams: {
                        amount: uiAmountToLamports(increaseAmount, decimals),
                        expectedLoanValues: {
                            expectedApy: decimalsToBps(expectedLoanValues.expectedApy),
                            expectedLtv: convertLedgerValueTupleToBps(expectedLoanValues.expectedLtv),
                            expectedLiquidationThreshold: convertLedgerValueTupleToBps(
                                expectedLoanValues.expectedLiquidationThreshold
                            )
                        }
                    }
                }
            });

            const transactions = await combineTransactionPromises([increasePrincipal], {
                order: "parallel"
            });

            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    // Added because escrow patterns above
    const sendOptions = {
        refetch: () => {
            resetUserAssetsApi();
            resetLoanApi();
            resetNapoleonApi();
            resetMarketsPublicApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Increasing borrow" };
}

export function useDepositCollateralTransaction(): AbfTransactionDetails<DepositCollateralParams> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetUserAssetsApi, resetLoopApi } = useAbfFetches();

    async function getTransactionsWithParams(params: DepositCollateralParams): AbfGeneratorResult {
        try {
            const collateralDeposit = generate({
                generateFunction: getDepositCollateralTransaction,
                identifier: "Deposit Asset",
                params: {
                    ...params,
                    amount: uiAmountToLamports(params.amount, params.decimals),
                    expectedLoanValues: {
                        expectedApy: decimalsToBps(params.expectedLoanValues.expectedApy),
                        expectedLtv: convertLedgerValueTupleToBps(params.expectedLoanValues.expectedLtv),
                        expectedLiquidationThreshold: convertLedgerValueTupleToBps(
                            params.expectedLoanValues.expectedLiquidationThreshold
                        )
                    }
                }
            });

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([collateralDeposit]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [depositTxn] = generatedTxns;

            if (depositTxn) {
                transactions.push({
                    transactions: [...(depositTxn?.unwrap() ?? [])]
                });
            }

            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetUserAssetsApi();
            resetLoanApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Deposit Assets" };
}
