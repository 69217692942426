import { ReactNode } from "react";

import { DEFAULT_SOLANA_EXPLORER, MayString, colorToAlpha, formatAddress } from "@bridgesplit/utils";
import { Skeleton, SxProps, TooltipProps, Typography, keyframes, styled } from "@mui/material";

import {
    ColorExtension,
    FONT_SIZES,
    FONT_VARIANT_WEIGHTS,
    FONT_WEIGHTS,
    LINE_HEIGHT_MULTIPLIER,
    SPACING,
    useAppPalette,
    useColorFromTag,
    useTextColorMap
} from "../theme";
import { SxType } from "../types";
import { ConditionalLink } from "./buttons";
import { Column, Row } from "./containers";
import { Icon, IconType } from "./icons";
import { dottedUnderline, Tooltip } from "./misc";
import { TagWrapper } from "./info";
import { TrackingInfo, useUiTrack } from "../tracking";
import { useCopyAddress } from "../util/hooks";

export type TextVariant = "h1" | "h2" | "h3" | "h4" | "body1" | "body2" | "caption";
export type TextColor =
    | "primary"
    | "secondary"
    | "body"
    | "caption"
    | "disabled"
    | "error"
    | "success"
    | "white"
    | "info"
    | "warning";
export type RawTagColor = "body" | "secondary" | "success" | "error" | "info" | "warning" | "disabled";
export type TagColor = RawTagColor | ColorExtension;

export interface TextProps {
    children: ReactNode;
    variant?: TextVariant;
    color?: TextColor;
    sx?: SxType;
    loading?: boolean;
    loadingWidth?: string;
    isLink?: boolean;
    underlineLink?: "hover" | "always" | "never";
    underline?: boolean;
    onClick?: () => void;
    multiLine?: boolean;
    fontWeight?: "normal" | "bold";
    svgColor?: TextColor;
}

export function Text({
    variant = "body1",
    color = "body",
    loadingWidth,
    loading,
    sx,
    children,
    isLink,
    multiLine,
    underlineLink = "never",
    underline,
    onClick,
    fontWeight,
    svgColor
}: TextProps) {
    const getColor = useTextColorMap();
    if (loading) {
        return <TextSkeleton multiLine={multiLine} width={loadingWidth} variant={variant} />;
    }

    const dottedStyle: SxProps = underline ? dottedUnderline() : {};

    return (
        <Typography
            color={getColor(color)}
            variant={variant}
            onClick={onClick}
            sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                textDecoration: isLink && underlineLink === "always" ? "underline" : undefined,
                gap: `${Math.round(FONT_SIZES[variant] / 2)}px`,
                cursor: isLink || onClick ? "pointer" : undefined,
                fontWeight: fontWeight ?? FONT_VARIANT_WEIGHTS[variant],
                ":hover": {
                    textDecoration:
                        isLink && (underlineLink === "always" || underlineLink === "hover") ? "underline" : undefined,

                    color: isLink ? colorToAlpha(getColor(color), 0.6) : undefined
                },
                svg: { fontSize: "inherit", verticalAlign: "middle", color: svgColor ? getColor(svgColor) : undefined },
                ...dottedStyle,
                ...sx
            }}
        >
            {children}
        </Typography>
    );
}

export interface TagTextProps extends Omit<TooltipTextProps, "color" | "variant"> {
    color?: TagColor;
    fullWidth?: boolean;
    px?: number;
    py?: number;
    variant?: "colored" | "basic";
    textVariant?: TextVariant;
    customColor?: string;
    useTooltip?: boolean;
}

export function TagText({
    color = "body",
    textVariant = "caption",
    px = 0.75,
    py = 0.25,
    fullWidth,
    sx,
    children,
    variant = "colored",
    customColor,
    useTooltip = true, //Added to prevent <p> nesting errors when used in tooltip
    ...otherProps
}: TagTextProps) {
    const baseColor = useColorFromTag(color);
    const derivedColor = customColor ?? baseColor;
    const { isDarkMode, textPrimary } = useAppPalette();

    const commonSx = {
        width: fullWidth ? `calc(100% - ${px * SPACING * 2}px)` : "fit-content",
        borderRadius: "3px",
        px,
        py,
        backgroundColor: colorToAlpha(derivedColor, isDarkMode ? 0.2 : 0.1),
        color: variant === "colored" ? derivedColor : textPrimary,
        svg: { color: derivedColor, fontSize: "inherit" },
        ...sx
    };

    if (useTooltip) {
        return (
            <TooltipText
                variant={textVariant}
                fullWidth={fullWidth}
                sx={commonSx}
                icon={false}
                {...(otherProps as Omit<TooltipTextProps, "color" | "variant">)}
            >
                {children}
            </TooltipText>
        );
    }

    return (
        <Text variant={textVariant} sx={commonSx} {...(otherProps as Omit<TextProps, "color" | "variant">)}>
            {children}
        </Text>
    );
}
export function TagTextAlert({
    children,
    color = "info",
    variant = "body2",
    sx,
    icon,
    flexDirection,
    textSx,
    textColor
}: {
    children: ReactNode;
    variant?: TextVariant;
    color?: TagColor;
    textColor?: TextColor;
    sx?: SxType;
    icon?: IconType | JSX.Element;
    flexDirection?: "row" | "column";
    textSx?: SxType;
}) {
    const baseColor = useColorFromTag(color);

    return (
        <TagWrapper sx={sx} color={color}>
            <Row spacing={1}>
                {icon && (
                    <Text sx={{ svg: { color: baseColor, fontSize: "inherit" } }} variant={variant}>
                        {typeof icon === "string" ? <Icon sx={{ color: baseColor }} type={icon} /> : icon}
                    </Text>
                )}

                <Text color={textColor} sx={{ flexWrap: "nowrap", flexDirection, ...textSx }} variant={variant}>
                    {children}
                </Text>
            </Row>
        </TagWrapper>
    );
}

export interface TooltipTextProps extends Omit<TextProps, "children"> {
    helpText?: string | undefined;
    icon?: boolean;
    containerSx?: SxType;
    fullWidth?: boolean;
    children?: ReactNode;
    tooltipProps?: Omit<TooltipProps, "title" | "children" | "fullWidth" | "containerSx">;
}
export function TooltipText({
    helpText,
    children,
    containerSx,
    fullWidth,
    icon = true,
    tooltipProps,
    ...otherProps
}: TooltipTextProps) {
    const { textDisabled } = useAppPalette();
    return (
        <Tooltip
            {...tooltipProps}
            placement={tooltipProps?.placement ?? "right"}
            fullWidth={fullWidth}
            containerSx={containerSx}
            title={helpText}
        >
            <Text {...otherProps}>
                {children}
                {icon && !!helpText && <Icon sx={{ color: textDisabled }} type="tooltip" />}
            </Text>
        </Tooltip>
    );
}

export interface TextLinkProps extends TextProps {
    to?: string;
    textSx?: SxType;
    tracking?: TrackingInfo;
}
export function TextLink({ to, color = "body", sx, textSx, tracking, ...otherProps }: TextLinkProps) {
    const getColor = useTextColorMap();
    const track = useUiTrack();

    const mappedColor = getColor(color);

    return (
        <ConditionalLink sx={{ color: mappedColor, width: "fit-content", ...sx }} to={to}>
            <Text
                onClick={tracking ? () => track(tracking) : undefined}
                color={color}
                isLink
                sx={textSx}
                {...otherProps}
            />
        </ConditionalLink>
    );
}

export function TextSkeleton({
    variant,
    width = "100px",
    multiLine
}: {
    variant: TextVariant;
    width?: string;
    multiLine?: boolean;
}) {
    const height = FONT_SIZES[variant] * LINE_HEIGHT_MULTIPLIER;
    if (multiLine) {
        return (
            <Column sx={{ width: "100%" }}>
                {Array(3)
                    .fill(null)
                    .map((_, i) => (
                        <Skeleton key={i} height={height} width="100%" />
                    ))}
            </Column>
        );
    }
    return <Skeleton height={height} width={width} />;
}

export interface AddressTextProps extends Omit<TextProps, "children"> {
    address: MayString;
    explorer?: string;
    prefix?: string;
    showIcon?: boolean;
    showText?: boolean;
}
export function AddressText({
    address,
    variant,
    showIcon = true,
    showText = true,
    explorer = DEFAULT_SOLANA_EXPLORER,
    prefix = "account",
    sx,
    ...otherProps
}: AddressTextProps) {
    return (
        <Text
            variant={variant}
            onClick={() => window.open(`${explorer}${prefix}/${address}`)}
            sx={{ ":hover": { textDecoration: "underline" }, minWidth: "max-content", ...sx }}
            {...otherProps}
        >
            {showText ? formatAddress(address) : ""} {showIcon && <Icon type="url" />}
        </Text>
    );
}

export function AddressCopy({ address, variant }: { address: string; variant: TextVariant }) {
    const copy = useCopyAddress();
    return (
        <Text
            onClick={() => copy(address)}
            sx={{ ":hover": { textDecoration: "underline" }, minWidth: "max-content" }}
            variant={variant}
            svgColor="disabled"
        >
            {formatAddress(address)} <Icon type="copy" />
        </Text>
    );
}

export type EllipsesTextProps = TextProps;
export function EllipsesText({ loading, sx, ...otherProps }: EllipsesTextProps) {
    const ellipsisAnimation = keyframes`
  to {
    width: 1.25em;
  }
`;

    return (
        <Text
            sx={{
                gap: 0,
                "&:after": {
                    display: loading ? "inline-block" : "none",
                    overflow: "hidden",
                    verticalAlign: "bottom",
                    animation: `${ellipsisAnimation} steps(4, end) 900ms infinite`,
                    content: "'\\2026'",
                    width: "0px"
                },
                ...sx
            }}
            {...otherProps}
        />
    );
}

export const TextNestedLink = styled("span")(({ theme }) => ({
    color: theme.palette.text.primary,
    cursor: "pointer",
    ":hover": { opacity: 0.6 }
}));

export const SpanBold = styled("span")(({ theme }) => ({
    fontWeight: FONT_WEIGHTS.bold,
    color: theme.palette.text.primary
}));

export const Span = styled("span")(({ theme }) => ({}));

export const SpanFlex = styled("span")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 1
}));

export function CircleWrapper({
    color = "primary",
    children,
    padding = 1
}: {
    color?: TextColor;
    children: ReactNode;
    padding?: number;
}) {
    const baseColor = useTextColorMap()(color);
    const { isDarkMode } = useAppPalette();
    return (
        <Span
            sx={{
                padding,
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: colorToAlpha(baseColor, isDarkMode ? 0.2 : 0.1)
            }}
        >
            {children}
        </Span>
    );
}
