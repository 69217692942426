import { useState } from "react";

import { BsMetaUtil, summarizeChangesFromNewInterest, summarizeLedgerAccount } from "@bridgesplit/abf-react";
import { MayNumber } from "@bridgesplit/utils";
import { Column, TokenInput } from "@bridgesplit/ui";

import { MakeRepaymentParams, MakeRepaymentProps } from "./types";
import { AppButton, useBalanceChecker } from "../../../common";
import { useRepay, calculateNewLoanInterestDue } from "./util";
import RepayStats from "./RepayStats";

const FULL_REPAY_AMOUNT = -1;
export default function LoanRepayment({ loanExpanded, ledgerIndex }: MakeRepaymentProps) {
    const [_inputAmountState, setAmount] = useState<MayNumber>(FULL_REPAY_AMOUNT);

    const ledgerAccount = loanExpanded?.ledger[ledgerIndex ?? 0];

    const { totalOutstanding } = summarizeLedgerAccount(ledgerAccount);

    // by default, recalculate loan interest based on the full principal due
    const fullRepay = calculateNewLoanInterestDue({
        loanExpanded,
        repaymentAmount: totalOutstanding,
        ledgerAccount
    });

    const amountDue = loanExpanded
        ? summarizeChangesFromNewInterest(loanExpanded, ledgerAccount, fullRepay).totalToPay
        : 0;

    // Determine the input amount based on the state or the sum of due amounts
    const inputAmount = _inputAmountState === FULL_REPAY_AMOUNT ? amountDue : _inputAmountState;

    // recalculate loan interest based on the amount inputted by user (could be partial principal)
    const repayBasedOnInput = calculateNewLoanInterestDue({
        loanExpanded,
        repaymentAmount: inputAmount,
        ledgerAccount
    });

    const isFullRepay = _inputAmountState === FULL_REPAY_AMOUNT || _inputAmountState === amountDue;

    const params: MakeRepaymentParams = {
        loanExpanded,
        payment: repayBasedOnInput,
        isFullRepay
    };

    const zc = useRepay(params);

    const { repay } = zc;

    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        mint: loanExpanded?.principalMetadata.mint,
        amount: inputAmount,
        escrowNeeded: false,
        setMax: (val) => {
            if (val && val >= amountDue) {
                setAmount(FULL_REPAY_AMOUNT);
            } else {
                setAmount(val);
            }
        }
    });

    return (
        <>
            <TokenInput
                symbol={BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)}
                label="Amount"
                value={inputAmount}
                decimals={loanExpanded?.principalMetadata?.decimals}
                setValue={(val) => {
                    // manually prevent double on change due to manually modifying value
                    if (val === amountDue) {
                        setAmount(FULL_REPAY_AMOUNT);
                    } else {
                        setAmount(val);
                    }
                }}
                setMax={(amt) => {
                    if (amt) {
                        setAmount(FULL_REPAY_AMOUNT);
                    } else {
                        setAmount(amt);
                    }
                }}
                maxText="Total due: "
                maxAmount={amountDue}
                maxButtonCta="Full payment"
            />

            <RepayStats loanExpanded={loanExpanded} ledgerAccount={ledgerAccount} paymentInfo={repayBasedOnInput} />
            <Column spacing={0.5}>
                <AppButton
                    isTransaction
                    disabled={!inputAmount || inputAmount > amountDue || insufficientBalance}
                    asyncCta={{ onClickWithResult: repay, closeDialog: "onSuccess" }}
                    fullWidth
                >
                    Repay
                </AppButton>
                <BalanceDisplay />
            </Column>
        </>
    );
}
