import { useState } from "react";

import { Column, MediaStack, TagColor, TagWrapper, Text, TextButton } from "@bridgesplit/ui";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import VaultTerms from "./VaultTerms";
import {
    useSummarizedVaultPendingChanges,
    useSummarizedVaultTerms,
    useSummarizedVaultPendingSettingsChanges
} from "./util";
import PendingChanges from "./PendingChanges";

export default function TermsDisplay(): JSX.Element {
    const [pendingVisible, setPendingVisible] = useState(false);
    const changes = useSummarizedVaultPendingChanges();
    const settingsChanges = useSummarizedVaultPendingSettingsChanges();

    const summarizedTerms = useSummarizedVaultTerms();

    if (!changes?.length && !settingsChanges?.length) return <VaultTerms summarizedTerms={summarizedTerms} />;
    const totalChanges = (changes?.length ?? 0) + (settingsChanges?.length ?? 0);

    const tagColor: TagColor = pendingVisible ? "info" : "warning";

    return (
        <>
            <Column sx={{ cursor: "pointer" }} onClick={() => setPendingVisible((prev) => !prev)}>
                <TagWrapper color={tagColor}>
                    <MediaStack spaceBetweenRow spacing={1}>
                        <Text svgColor={tagColor}>
                            {pendingVisible ? <VisibilityOff /> : <Visibility />}
                            {pendingVisible
                                ? "You’re previewing changes"
                                : `${totalChanges} parameter${totalChanges === 1 ? "" : "s"} are being modified`}
                        </Text>
                        <TextButton>{pendingVisible ? "Hide" : "Preview"}</TextButton>
                    </MediaStack>
                </TagWrapper>
            </Column>
            {pendingVisible ? (
                <PendingChanges settingsChanges={settingsChanges} changes={changes} />
            ) : (
                <VaultTerms summarizedTerms={summarizedTerms} />
            )}
        </>
    );
}
