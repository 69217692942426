import { Row, TextButton, TooltipText } from "@bridgesplit/ui";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { COPY } from "app/constants";

import { MarketLendForm } from "./type";

export default function LendAdditionalInfoHeader({
    expanded,
    setExpanded,
    form
}: {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    form: MarketLendForm;
}) {
    return (
        <Row spaceBetween>
            <TooltipText variant="body2" helpText={COPY.OPTIONAL_LENDING_CONFIGURATION_TITLE_HELP_TEXT}>
                {COPY.OPTIONAL_LENDING_CONFIGURATION_TITLE}
            </TooltipText>

            <Row sx={{ cursor: "pointer" }} onClick={() => setExpanded((prev) => !prev)}>
                <TextButton color="body">{expanded ? <ExpandLess /> : <ExpandMore />}</TextButton>
            </Row>
        </Row>
    );
}
