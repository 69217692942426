import { memo, useCallback } from "react";

import { Column } from "@bridgesplit/ui";
import {
    serializeStrategyDuration,
    StrategyExpanded,
    useUpdateStrategyTermsTransaction,
    MARKET_DURATIONS
} from "@bridgesplit/abf-react";
import { AppButton } from "app/components/common";
import { useTransactionSender } from "app/components/transactions";
import { CollateralParamsUpdateArgs, EditCustomStrategyTxnParams, StrategyDuration } from "@bridgesplit/abf-sdk";
import { uiPercentToBps, DISABLED_APY, Result, MISSING_PARAM_ERROR } from "@bridgesplit/utils";

import { useInitializeMarketLendCollateral, useInitializeMarketLendPresets } from "./util";
import LendApyDuration from "./LendApyDuration";
import LendCollateralSelect from "./LendCollateralSelect";
import { useMarketLendContext } from "./MarketLendContext";

export default memo(function UpdateApyAndCollateralCard({ strategy }: { strategy: StrategyExpanded }) {
    useInitializeMarketLendCollateral();
    useInitializeMarketLendPresets();

    const { form } = useMarketLendContext();
    const editStrategy = useUpdateStrategyTermsTransaction();
    const send = useTransactionSender();

    const collaterals = form.collateral;

    const { updateMints, atLeastOneEnabledApy } = getStrategyUpdates(
        collaterals,
        form.strategyDurationToApy,
        MARKET_DURATIONS
    );

    const submit = useCallback(async () => {
        if (!updateMints || !strategy) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const params: EditCustomStrategyTxnParams = {
            strategy: strategy.strategy.address,
            collateralTerms: {
                addCollateral: {},
                removeCollateral: {},
                updateCollateral: updateMints
            }
        };

        return await send(editStrategy, params);
    }, [updateMints, editStrategy, send, strategy]);

    return (
        <Column spacing={3}>
            <>
                <LendCollateralSelect />
                <LendApyDuration />
                <Column spacing={1}>
                    <AppButton
                        tooltip={!atLeastOneEnabledApy ? "You must enable at least one APY" : undefined}
                        isTransaction={false}
                        disabled={!atLeastOneEnabledApy}
                        asyncCta={{
                            onClickWithResult: submit
                        }}
                        variant="contained"
                    >
                        Save changes
                    </AppButton>
                </Column>
            </>
        </Column>
    );
});

interface StrategyUpdates {
    updateMints: Record<string, CollateralParamsUpdateArgs> | undefined;
    atLeastOneEnabledApy: boolean;
}

export function getStrategyUpdates(
    collaterals: string[],
    serializedStrategyDurationToApy: Map<string, number | undefined> | undefined,
    strategyDurations: StrategyDuration[] | undefined
): StrategyUpdates {
    if (!collaterals.length || !serializedStrategyDurationToApy || !strategyDurations) {
        return { updateMints: undefined, atLeastOneEnabledApy: false };
    }

    const updates: Record<string, CollateralParamsUpdateArgs> = {};

    let atLeastOneEnabledApy = false;
    for (const mint of collaterals) {
        const apyUpdates: Record<string, string> = {};

        for (const strategyDuration of strategyDurations) {
            const serialized = serializeStrategyDuration(strategyDuration);
            const formApy = serializedStrategyDurationToApy.get(serialized);

            if (!formApy && formApy !== 0) {
                apyUpdates[serialized] = DISABLED_APY;
            } else {
                atLeastOneEnabledApy = true;
                apyUpdates[serialized] = uiPercentToBps(formApy).toString();
            }
        }

        updates[mint] = {
            apyUpdate: apyUpdates
        };
    }

    return { updateMints: updates, atLeastOneEnabledApy };
}
