import { useCallback, useState } from "react";

import { LoanInfoSorting, LoanPaginationInfo, LoanStatusType } from "@bridgesplit/abf-react";
import { useLocalStorage } from "@bridgesplit/react";
import { SortDirection } from "@bridgesplit/utils";

export const DEFAULT_LOAN_PAGE_SIZE = 10;
export const DEFAULT_LOAN_PAGE_SIZE_OPTIONS = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 25, label: "25" }
];
const DEFAULT_SORT_SIDE = SortDirection.Desc;
const DEFAULT_SORT_TYPE = LoanInfoSorting.Principal;

export function useLoansPagination({
    initialPageSize = DEFAULT_LOAN_PAGE_SIZE,
    initialState
}: {
    initialPageSize?: number;
    initialState?: { principalMint?: string };
}) {
    const [sortSide, setSortSide] = useLocalStorage<SortDirection>("LoanCardsOrder", DEFAULT_SORT_SIDE);
    const [sortType, setSortType] = useLocalStorage<LoanInfoSorting>("LoanCardsSort", DEFAULT_SORT_TYPE);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [loanStatusType, setLoanStatusType] = useState<LoanStatusType>(LoanStatusType.ActiveOnly);
    const [principalMintState, setPrincipalMint] = useState<string | undefined>();
    const principalMint = principalMintState ?? initialState?.principalMint;

    const params: LoanPaginationInfo = {
        page,
        pageSize,
        sortSide,
        sortType,
        loanStatusType,
        principalMint
    };

    const reset = useCallback(() => {
        setSortSide(DEFAULT_SORT_SIDE);
        setSortType(DEFAULT_SORT_TYPE);
        setPage(0);
        setPageSize(initialPageSize);
        setLoanStatusType(LoanStatusType.ActiveOnly);
        setPrincipalMint(undefined);
    }, [setSortSide, setSortType, setPage, setPageSize, setLoanStatusType, setPrincipalMint, initialPageSize]);

    return {
        page,
        params,
        setSortSide,
        setSortType,
        setPage,
        setPageSize,
        setLoanStatusType,
        setPrincipalMint,
        reset
    };
}
