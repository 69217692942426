import { MIN_TIME_FOR_VAULT_PNL_PENDING } from "@bridgesplit/abf-react";
import { TooltipText, Text } from "@bridgesplit/ui";
import { formatSeconds } from "@bridgesplit/utils";

export function VaultPendingPnl() {
    return (
        <TooltipText
            helpText={`Interest earned will show after some has been accrued (up to ${formatSeconds(
                MIN_TIME_FOR_VAULT_PNL_PENDING
            )})`}
            color="disabled"
        >
            <Text variant="body1" color="disabled">
                Pending
            </Text>
        </TooltipText>
    );
}
