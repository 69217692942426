import { useLendingVaultAllocation } from "@bridgesplit/abf-react";
import { Divider } from "@mui/material";
import { Text } from "@bridgesplit/ui";

import AllocationTable from "./AllocationTable";
import { useVaultContext } from "../../VaultContext";
import { useAllocationSummaries } from "./util";
import { VaultInfoSection } from "../common";
import AllocationStats from "./AllocationStats";

export default function VaultLoanAllocation() {
    const { vaultAddress, vaultExpanded } = useVaultContext();
    const { data: allocations } = useLendingVaultAllocation(vaultAddress, vaultExpanded?.principalMetadata.mint);
    const allocationSummaries = useAllocationSummaries(allocations);
    return (
        <VaultInfoSection header="Loans">
            <AllocationStats />
            <Divider />
            <Text variant="h4">Allocation</Text>
            <AllocationTable allocations={allocationSummaries} vaultExpanded={vaultExpanded} />
        </VaultInfoSection>
    );
}
