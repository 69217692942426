export const HEALTH_ROUTE = "/health";

export const CHECK_USER_MFI_ACCOUNT_ROUTE = "/user_mfi/exists";

// markets token routes
export const GET_TOKENS_ROUTE = "/user/tokens";
export const GET_TOKEN_LIST_ROUTE = "/tokens";
export const GET_WHIRLPOOL_POSITIONS_ROUTE = "/user/whirlpools";
export const GET_WHIRLPOOL_ROUTE = "/whirlpools";
export const ROUTE_UPDATE_USD_PRICE_MAPPINGS = "/usd-price-mappings";

export const GET_LOANS_WITH_INFO_ROUTE = "/loans/info";
// markets lending vaults routes
export const ROUTE_GET_LENDING_VAULTS = "/lending_vaults/info";
export const ROUTE_GET_LENDING_VAULTS_HISTORY = "/lending_vaults/history";
export const ROUTE_GET_LENDING_VAULTS_UPDATE_HISTORY = "/lending_vaults/update_history";
export const ROUTE_GET_LENDING_VAULT_ALLOCATIONS = "/lending_vaults/allocation";
export const ROUTE_GET_LENDING_USER_VAULT_POSITIONS = "/lending_vaults/user_positions";
export const ROUTE_GET_USER_LENDING_VAULTS_ACTIVE_STATS = "/lending_vaults/user_positions/active_stats";
export const ROUTE_GET_USER_POSITION_PNL = "/lending_vaults/deposit_terms";
export const GET_USER_GENESIS_ACCESS_ROUTE = "/user/genesis";

//markets routes
export const GET_MARKET_STRATEGY_STATS_ROUTE = "/stats";
export const GET_REFRESH_MARKET_STRATEGY_STATS_ROUTE = "/stats/refresh";
export const GET_MARKET_PRINCIPAL_STATS_ROUTE = "/principal/stats";
export const GET_PORTFOLIO_STATS_ROUTE = "/portfolio/stats";
export const GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE = "principal/stats/all";
export const GET_MARKET_BORROW_CAPS_ROUTE = "/borrow_caps";
export const ROUTE_REFRESH_MARKET_TVL_STATS = "/tvl/refresh";
export const GET_USER_BETA_ACCESS_ROUTE = "/user/beta_access";

//markets rates routes
export const GET_RATE_HISTORY_ROUTE = "/rates/history";
export const GET_RATE_HISTROY_UPDATE = "/rates/update";
export const GET_LOOP_VAULT_BORROW_RATE_HISTORY_ROUTE = "/loop/history";

//market quote routes
export const MARKET_QUOTE_ROUTE = "/quote";
export const MARKET_QUOTE_FOR_APY_ROUTE = "/quote/apy";
export const GET_MAX_QUOTE_ROUTE = "/quote/max";
export const GET_BEST_QUOTE_ROUTE = "/quote/best";
export const ROUTE_GET_REFINANCE_INFO = "/quote/refinance";
export const GET_BEST_LOOP_QUOTE_ROUTE = "/quote/loop";
export const ROUTE_INCREASE_CREDIT_QUOTE = "/quote/increase_credit";
export const ROUTE_LOAN_SALE_QUOTE = "/quote/sell";

export const GET_STRATEGY_INFO_ROUTE = "/strategy/infos";
export const GET_MIN_PRINCIPAL_ROUTE = "/strategy/min_principal";
export const GET_COLLATERAL_STATS_ROUTE = "/collateral/stats/all";
// market loop routes
export const GET_LOOP_VAULTS_INFO_ROUTE = "/loop/info";
export const ROUTE_GET_LOOP_VAULTS_HISTORY = "/loop/history";
export const ROUTE_UPDATE_LOOP_VAULTS_HISTORY = "/loop/update_history"; //TODO: Update history not connected in backend
export const GET_USER_LOOP_INFO_ROUTE = "/loop/user_positions";
export const ROUTE_GET_LATEST_COLLATERAL_YIELD_PCT = "/loop/collateral_yield/latest";
export const GET_COLLATERAL_YIELDS_IN_RANGE_ROUTE = "/loop/collateral_yield/range";
export const GET_LOOP_POSITION_STATS_ROUTE = "/loop/position_stats";
export const ROUTE_UPDATE_COLLATERAL_YIELD_UPDATE = "/loop/collateral_yield/update";

export const ROUTE_FETCH_MARKET_INFORMATIONS = "/oracles/data/market_informations";

// market external yield routes
export const ROUTE_GET_EXTERNAL_YIELD_INFO = "/external_yield/info";

// pricing routes
export const FETCH_ORACLE_PRICES_ROUTE = "/prices";

// ruby routes
export const ROUTE_GET_USER_REFERRAL_INFO_ROUTE = "/user/referral_info";
export const ROUTE_REFER_USER = "/user/refer";
export const ROUTE_CREATE_INVITES = "/invite/create";
export const ROUTE_FETCH_USER_INVITES = "/invite/fetch";
export const ROUTE_APPLY_INVITE = "/invite/apply";
export const ROUTE_GET_REWARDS = "/points/rewards";
export const ROUTE_GET_USER_WL_INFO = "/points/user/wl";
export const ROUTE_GET_USER_POINTS_CALC = "/points/user/calc";
export const ROUTE_GET_USER_REFERRAL_POINTS_CALC = "/points/user/referrals";
export const ROUTE_GET_POINT_MULTIPLIERS = "/points/multipliers";
export const ROUTE_VERIFY_TWITTER_FOLLOW = "/twitter/verify_follow";
export const ROUTE_FETCH_TWITTER_REQUEST_TOKEN = "/twitter/request_token";
export const ROUTE_FETCH_TWITTER_OAUTH_TOKEN = "/twitter/oauth_token";
export const ROUTE_FETCH_TWITTER_INFO = "/twitter/info";
export const ROUTE_VERIFY_DISCORD = "/discord/verify";
export const ROUTE_FETCH_DISCORD = "/discord";
export const ROUTE_ONBOARDING = "/onboarding";
export const ROUTE_PORTFOLIO_REWARDS = "/portfolio/rewards";

// community reward routes
export const ROUTE_GET_COMMUNITY_REWARDS = "/points/community";
export const ROUTE_GET_USER_TOTAL_BY_TYPE = "/points/user_total_by_type";
export const ROUTE_APPLY_COMMUNITY_REWARDS = "/points/community/apply-rewards";
export const ROUTE_GET_COMMUNITY_MULTIPLIERS = "/community_multipliers/user_wallet";

export const TRANSACTIONS_STATUS_ROUTE = "/txns/status";

// meteora routes
export const METEORA_BASE = "/meteora";
export const ROUTE_METEORA_DEPOSIT_QUOTE = `${METEORA_BASE}/deposit_quote`;
export const ROUTE_METEORA_WITHDRAW_QUOTE = `${METEORA_BASE}/withdraw_quote`;

// loop ixs routes
export const LOOP_BASE = "/loop";
export const ROUTE_LOOP_WIND_QUOTE_IXS = `${LOOP_BASE}/quote/wind`;
export const ROUTE_LOOP_UNWIND_QUOTE_IXS = `${LOOP_BASE}/quote/unwind`;

export const ROUTE_GET_USER_HAS_MFI_ACCOUNT = "/user_mfi/exists";
