import { DEFAULT_STRATEGY_DURATION } from "@bridgesplit/abf-react";

import { WindForm } from "./types";

export const DEFAULT_WIND_FORM: WindForm = {
    collateralAmount: undefined,
    multiplier: undefined,
    multiplierWithoutDebounce: undefined,
    strategyDuration: DEFAULT_STRATEGY_DURATION,
    percentSlippageDecimals: undefined
};
