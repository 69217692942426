import { uiAmountToLamports } from "@bridgesplit/utils";

import { AbfLoanExpanded, Collateral, QuoteCollateralInfo } from "../types";
import { getAssetIdentifier, getAssetMint, getAssetType } from "../utils";

// Used in refinance when metadata needs to be adjusted for the new format
export function getCollateralMintToInfo(
    loanExpanded: AbfLoanExpanded | undefined
): Map<string, QuoteCollateralInfo> | undefined {
    if (!loanExpanded) return undefined;

    // Loan collateral needs to be converted to the new format. This is a temporary fix until the new format is implemented.
    const adjustedCollateral = loanExpanded.collateral.map((c) => ({
        ...c.loanCollateral,
        metadata: {
            ...c.loanCollateral.metadata,
            whirlpoolExpanded: c.loanCollateral.whirlpoolPosition
        }
    }));

    return new Map(
        adjustedCollateral.map((c) => [
            c.metadata.mint,
            {
                amount: uiAmountToLamports(c.amount, c.metadata.decimals),
                assetType: getAssetType(c.metadata),
                assetMint: getAssetMint(c.metadata),
                assetIdentifier: getAssetIdentifier(c.metadata)
            }
        ])
    );
}

// Used for market borrow context, when generating quotes from a form
export function getCollateralMintToInfoFromForm(
    collateralMint: string | undefined | null,
    activeUserCollateral: Collateral | undefined,
    collateralAmount: number | undefined
): Map<string, QuoteCollateralInfo> | undefined {
    if (!collateralMint || !activeUserCollateral || !collateralAmount) return undefined;

    return new Map([
        [
            collateralMint,
            {
                amount: uiAmountToLamports(collateralAmount, activeUserCollateral.metadata.decimals),
                assetType: getAssetType(activeUserCollateral.metadata),
                assetMint: getAssetMint(activeUserCollateral.metadata),
                assetIdentifier: getAssetIdentifier(activeUserCollateral.metadata)
            }
        ]
    ]);
}
