import { Row, Tooltip, Text, Icon, Column } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";
import { Divider } from "@mui/material";
import { useLoanMultipliersUtil } from "@bridgesplit/abf-react";

import { TooltipContent } from "../..";
import { BaseApy } from "../BaseApy";
import { RewardsPortfolioBorrowApyProps as RewardsPortfolioApyProps } from "../types";
import { RewardsDailyPointsBreakdown } from "../daily-rewards/RewardsDailyPointsBreakdown";
import { RewardsDailyCustomPoints } from "../daily-rewards/RewardsCustomDailyPoints";
import { useRewardsColor } from "../util";
import { RewardsExternalPointsRewards } from "../external-point-rewards/RewardsExternalPointsReward";

export function RewardsPortfolioApy(props: RewardsPortfolioApyProps) {
    const { loanMultipliers, isLoading } = useLoanMultipliersUtil();
    const color = useRewardsColor(loanMultipliers, props.metadata?.mint, [props.loopscalePointsAction]);

    return (
        <Tooltip title={<RewardsPortfolioTooltip {...props} />} reverseColors>
            <Row spacing={0.5}>
                <Text variant="body1" color="caption">
                    {formatPercent(props.apy)} APY
                </Text>
                <Text sx={{ color }} loading={isLoading}>
                    <Icon type="points" />
                </Text>
            </Row>
        </Tooltip>
    );
}

function RewardsPortfolioTooltip(props: RewardsPortfolioApyProps) {
    return (
        <TooltipContent>
            <BaseApy {...props} />
            <Divider />
            <Column spacing={1}>
                <RewardsDailyCustomPoints
                    loopscalePointsAction={props.loopscalePointsAction}
                    metadata={props.metadata}
                    customDailyPoints={props.customDailyPoints}
                />
                <Column sx={{ paddingLeft: 2 }}>
                    <RewardsDailyPointsBreakdown {...props} />
                </Column>
            </Column>
            {props.externalPointRewards?.length && (
                <>
                    <Divider />
                    <RewardsExternalPointsRewards {...props} />
                </>
            )}
        </TooltipContent>
    );
}
