import { useState } from "react";

import { DialogWrapper } from "@bridgesplit/ui";
import { StrategyDuration } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { AppButton, DialogHeader } from "../../common";

enum Setting {
    Never,
    Allowed
}
type Form = { allowed: Setting; maxApy: number | undefined; strategyDuration: StrategyDuration | undefined };
// export default function RefinanceSettings() {
//     const [form, setForm] = useState<Form>({ maxApy: undefined, allowed: Setting.Never, strategyDuration: undefined });
//     const { getData } = useAppDialog();
//     const data = getData(AppDialog.RefinanceSettings)?.loan;

//     const { strategyDurations } = useStrategyDurations();

//     const initialForm: Form = {
//         allowed: order?.refinanceEnabled ? Setting.Allowed : Setting.Never,
//         maxApy: percentDecimalsToUi(order?.maxRefinanceApy ?? undefined),
//         strategyDuration:
//             strategyDurations?.find(
//                 (p) => p.duration === order?.refinanceDuration && p.durationType === order.refinanceDurationType
//             ) ?? strategyDurations?.[0]
//     };

//     useOnInitialRender(() => setForm(initialForm), !order || !strategyDurations);
//     const updateTerms = useUpdateStrategyOrderRefinanceTerms();
//     const send = useTransactionSender();

//     async function submit() {
//         if (!data || !form.strategyDuration) return Result.errFromMessage(LOADING_ERROR);

//         const strategyIdentifier = getLoanStrategyIdentifier(data);
//         if (!strategyIdentifier) return Result.errFromMessage("Can't refinance a Prime loan");
//         return send(updateTerms, {
//             strategyIdentifier,
//             order: data.order.address,
//             refinanceEnabled: !!form.allowed,
//             maxRefinanceApy: percentUiToDecimals(form.maxApy ?? 0),
//             refinanceDurationType: form.strategyDuration.durationType,
//             refinanceDuration: form.strategyDuration.duration
//         });
//     }

//     const changesMade =
//         form.allowed !== initialForm.allowed ||
//         form.maxApy !== initialForm.maxApy ||
//         form.strategyDuration?.duration !== order?.refinanceDuration ||
//         form.strategyDuration?.durationType !== order?.refinanceDurationType;
//     return (
//         <DialogWrapper>
//             <DialogHeader
//                 header="Auto-refinance loans"
//                 description={`When enabled, your loan will auto-refinance daily instead of defaulting. Your loan can still default if there are no available offers`}
//             />
//             <ToggleButtons
//                 value={form.allowed}
//                 setValue={(allowed) => setForm((prev) => ({ ...prev, allowed }))}
//                 options={[
//                     { value: Setting.Allowed, label: "Auto-refinance" },
//                     { value: Setting.Never, label: "Never" }
//                 ]}
//             />
//             <LabelWrapper label="Refinance duration" tooltip="Preferred duration for the new refinanced loan">
//                 <Select
//                     disabled={!form.allowed}
//                     loading={!strategyDurations}
//                     options={
//                         strategyDurations?.map((strategyDuration) => ({
//                             value: serializeStrategyDuration(strategyDuration),
//                             label: formatDurationWithType(strategyDuration)
//                         })) ?? Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> })
//                     }
//                     value={form.strategyDuration ? serializeStrategyDuration(form.strategyDuration) : ""}
//                     setValue={(serializedDuration) => {
//                         setForm((prev) => ({
//                             ...prev,
//                             strategyDuration: deserializeStrategyDurationOrUndefined(serializedDuration)
//                         }));
//                     }}
//                 />
//             </LabelWrapper>

//             <FormInput
//                 disabled={!form.allowed}
//                 label="Max APY"
//                 tooltip="The max APY allowed to auto-refinance"
//                 variant="number"
//                 decimals={PERCENT_DECIMALS}
//                 value={form.maxApy}
//                 setValue={(maxApy) => setForm((prev) => ({ ...prev, maxApy }))}
//             />
//             <AppButton
//                 isTransaction
//                 asyncCta={{ onClickWithResult: submit }}
//                 disabled={!changesMade || (!!form.allowed && !form.maxApy)}
//             >
//                 Save changes
//             </AppButton>
//         </DialogWrapper>
//     );
// }

// TODO: Add refinancing
export default function RefinanceSettings() {
    const [form] = useState<Form>({ maxApy: undefined, allowed: Setting.Never, strategyDuration: undefined });
    const { getData } = useAppDialog();
    const data = getData(AppDialog.RefinanceSettings)?.loan;

    return (
        <DialogWrapper>
            <DialogHeader
                header="Auto-refinance loans"
                description={`When enabled, your loan will auto-refinance daily instead of defaulting. Your loan can still default if there are no available offers. Refinance settings are currently disabled.`}
            />
            <AppButton isTransaction disabled={!data || (!!form.allowed && !form.maxApy)}>
                Save changes
            </AppButton>
        </DialogWrapper>
    );
}
