import { DurationType, StrategyDuration } from "@bridgesplit/abf-sdk";
import { IS_PROD } from "@bridgesplit/utils";

export const DEFAULT_STRATEGY_DURATION: StrategyDuration = {
    duration: 1,
    durationType: DurationType.Days
};

export const MARKET_DURATIONS: StrategyDuration[] = [
    {
        duration: 1,
        durationType: DurationType.Days
    },
    {
        duration: 1,
        durationType: DurationType.Weeks
    },
    {
        duration: 1,
        durationType: DurationType.Months
    },
    {
        duration: 3,
        durationType: DurationType.Months
    },
    ...(!IS_PROD
        ? [
              {
                  duration: 5,
                  durationType: DurationType.Minutes
              }
          ]
        : [])
].filter(Boolean);
