import { useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    calculateLoanHealth,
    formatLoanDuration,
    getLoanName,
    getZcLedger,
    isLoanActive,
    LoanStatus,
    summarizeLedgerAccount
} from "@bridgesplit/abf-react";
import { Table, createRows, TableSearchFilter, TableAssetDisplay, TableColumn, Text, Column } from "@bridgesplit/ui";
import { bsMath, camelCaseToTitleCase, formatPercent, formatUsd } from "@bridgesplit/utils";

import { LoanFilter, LoanStatusFilter, LoanHealthTag, filterOnLoanStatus, getLoanAssetPath } from "../common";

const STATUS_FILTERS = [LoanStatusFilter.All, LoanStatus.OngoingLoan, LoanStatus.RepaidLoan, LoanStatus.DefaultedLoan];
export default function LoansTable({ loans, emptyText }: { loans: AbfLoanExpanded[] | undefined; emptyText: string }) {
    const [filter, setFilter] = useState<LoanFilter>(LoanStatusFilter.All);

    const filtered = loans?.filter((l) => filterOnLoanStatus(filter, l.status));

    const extraColumns = useMemo(() => {
        return generateRoleColumns(["totalOutstanding"]);
    }, []);

    return (
        <Table
            searchRowElement={<TableSearchFilter filter={filter} setFilter={setFilter} options={STATUS_FILTERS} />}
            paginationProps={{
                type: "managed",
                defaultSortKey: "startDate",
                getSearchFromRow: ({ data }) => [getLoanName(data)]
            }}
            emptyPlaceholder={loans?.length ? "No matching loans" : emptyText}
            onRowNavigatePath={({ data }) => getLoanAssetPath(data.loan)}
            loading={!loans}
            rows={createRows(filtered, ({ loan }) => loan.address)}
            columns={[...columns, ...extraColumns]}
        />
    );
}

const columns: TableColumn<AbfLoanExpanded>[] = [
    {
        dataIndex: "startDate",
        title: "Loan",
        width: "250px",
        render: ({ data }) => <LoanTableDisplay loan={data} />,
        sortBy: ({ data }) => data.loan.startTime
    },
    {
        dataIndex: "status",
        title: "Status",
        width: "150px",
        render: ({ data }) => <LoanHealthTag loan={data} />,
        sortBy: ({ data }) => (isLoanActive(data) ? calculateLoanHealth(data).health : 0)
    },
    {
        dataIndex: "apy",
        title: "APY",
        width: "60px",
        align: "right",
        render: ({ data }) => <>{formatPercent(data.borrowerWAvgApy)}</>,
        sortBy: ({ data }) => data.borrowerWAvgApy ?? 0
    }
];

type LoanProperty = keyof Exclude<ReturnType<typeof summarizeLedgerAccount>, undefined>;
function generateRoleColumns(loanProperties: LoanProperty[]) {
    return loanProperties.map(
        (loanProperty): TableColumn<AbfLoanExpanded> => ({
            dataIndex: loanProperty,
            title: LOAN_PROPERTY_TO_TITLE[loanProperty] || camelCaseToTitleCase(loanProperty),
            width: "200px",
            align: "right",
            render: ({ data }) => {
                return <OutstandingDetails loanProperty={loanProperty} loan={data} />;
            }
        })
    );
}

const LOAN_PROPERTY_TO_TITLE: Partial<Record<LoanProperty, string>> = {
    interestDue: "Interest",
    principalDue: "Principal",
    totalOutstanding: "Total Debt"
};

function OutstandingDetails({ loan, loanProperty }: { loan: AbfLoanExpanded; loanProperty: LoanProperty }) {
    const ledger = getZcLedger(loan);
    const details = summarizeLedgerAccount(ledger);
    const value = details ? details[loanProperty] : undefined;

    return (
        <Column alignItems="flex-end">
            <Text>{BsMetaUtil.formatAmount(loan.principalMetadata, value)}</Text>

            {!!loan.principalUsdPrice && (
                <Text variant="body2" color="caption">
                    {formatUsd(bsMath.mul(value, loan.principalUsdPrice))}
                </Text>
            )}
        </Column>
    );
}
function LoanTableDisplay({ loan }: { loan: AbfLoanExpanded }) {
    return <TableAssetDisplay description={formatLoanDuration(loan)} name={getLoanName(loan)} />;
}
