import { Column, createRows, Image, Row, Table, TableColumn, Text } from "@bridgesplit/ui";
import { formatPercent, formatTokenAmount, formatUsd } from "@bridgesplit/utils";
import { LendingVaultExpanded, DenominationDisplay } from "@bridgesplit/abf-react";

import { AllocationSummarized } from "./type";
import { useVaultContext } from "../../VaultContext";

export default function AllocationTable({
    allocations,
    vaultExpanded
}: {
    allocations: AllocationSummarized[] | undefined;
    vaultExpanded?: LendingVaultExpanded | undefined;
}) {
    const { selectedDenomination } = useVaultContext();
    const columns = getAllocationTableColumns(selectedDenomination, vaultExpanded);

    return (
        <Table
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: "allocation"
            }}
            emptyPlaceholder="No active loans"
            loading={!allocations}
            rows={createRows(allocations, (s) => s.key)}
            columns={columns}
        />
    );
}

function getAllocationTableColumns(
    displayDenomination: DenominationDisplay,
    vaultExpanded: LendingVaultExpanded | undefined
): TableColumn<AllocationSummarized>[] {
    const metadata = vaultExpanded?.principalMetadata;

    return [
        {
            dataIndex: "source",
            title: "Source",
            render: ({ data }) => (
                <Row spacing={1}>
                    <Image src={data.image} size="20px" variant="circle" />
                    <Text>{data.name}</Text>
                </Row>
            ),
            sortBy: ({ data }) => data.name,
            width: "30%"
        },
        {
            dataIndex: "allocation",
            title: "Allocation",
            align: "right",
            render: ({ data }) => (
                <Column alignItems="flex-end">
                    <Text>
                        {displayDenomination === DenominationDisplay.PrincipalBasis
                            ? formatTokenAmount(data.totalPrincipalDeployed, {
                                  symbol: metadata?.symbol,
                                  decimals: metadata?.decimals
                              })
                            : formatUsd(data.principalDeployedUsd)}
                    </Text>
                    <Text color="caption" variant="body2">
                        {formatPercent(data.allocationPercent)}
                    </Text>
                </Column>
            ),
            sortBy: ({ data }) => data.allocationPercent,
            width: "30%"
        },
        {
            dataIndex: "apy",
            title: "APY",
            align: "right",
            render: ({ data }) => (
                <Column alignItems="flex-end">
                    <Text> {formatPercent(data.wAvgApy)} </Text>
                    <Text color="caption" variant="body2">
                        {data.isIdle ? "Variable rate" : `${data.activeLoanCount} loans`}
                    </Text>
                </Column>
            ),
            sortBy: ({ data }) => data.wAvgApy,
            width: "30%"
        }
    ];
}
