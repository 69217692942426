import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { abfSerializeQueryArgs, marketsBaseQuery } from "./util";
import {
    AbfLoanResponse,
    CollateralStats,
    IncreasePrincipalQuote,
    LendingStrategyFilter,
    LoanFilterApi,
    MarketInformationsFilter,
    ParsedMarketInformation,
    PortfolioRewardFilter,
    PortfolioRewards,
    PortfolioStats,
    SellLoanQuote,
    StrategyInfo
} from "../types";
import {
    GET_COLLATERAL_STATS_ROUTE,
    GET_LOANS_WITH_INFO_ROUTE,
    GET_PORTFOLIO_STATS_ROUTE,
    GET_STRATEGY_INFO_ROUTE,
    ROUTE_FETCH_MARKET_INFORMATIONS,
    ROUTE_INCREASE_CREDIT_QUOTE,
    ROUTE_LOAN_SALE_QUOTE,
    ROUTE_PORTFOLIO_REWARDS
} from "../constants";

const MARKETS_INFO_TAG = "MarketsPosition";
const LENDING_STRATEGY_TAG = "LendingStrategy";
const LOANS_TAG = "Loans";
const MARKETS_TAG = "Markets";

export const marketsApi = createApi({
    reducerPath: "marketsApi",
    tagTypes: [MARKETS_INFO_TAG, LENDING_STRATEGY_TAG, LOANS_TAG, MARKETS_TAG],
    baseQuery: marketsBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        oracleMarketInformations: builder.query<Record<string, ParsedMarketInformation>, MarketInformationsFilter>({
            providesTags: [MARKETS_INFO_TAG],
            query(params) {
                return {
                    url: ROUTE_FETCH_MARKET_INFORMATIONS,
                    body: params,
                    method: "POST"
                };
            },
            transformResponse: (response: ParsedMarketInformation[]) => {
                const record: Record<string, ParsedMarketInformation> = {};
                response.forEach((market) => {
                    record[market.marketInformation.address] = market;
                });
                return record;
            }
        }),
        loanInfos: builder.query<AbfLoanResponse, LoanFilterApi>({
            providesTags: [LOANS_TAG],
            query(body) {
                return {
                    url: GET_LOANS_WITH_INFO_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        lendingStrategyInfos: builder.query<StrategyInfo[], LendingStrategyFilter>({
            providesTags: [LENDING_STRATEGY_TAG],
            query(filter) {
                return {
                    url: GET_STRATEGY_INFO_ROUTE,
                    method: "POST",
                    body: filter
                };
            }
        }),

        collateralStats: builder.query<CollateralStats, void>({
            query() {
                return {
                    url: GET_COLLATERAL_STATS_ROUTE,
                    method: "GET"
                };
            }
        }),
        increasePrincipalQuote: builder.query<IncreasePrincipalQuote, string>({
            providesTags: [MARKETS_TAG],
            query(loanAddress: string) {
                return {
                    url: `${ROUTE_INCREASE_CREDIT_QUOTE}/${loanAddress}`,
                    method: "GET"
                };
            }
        }),
        portfolioStats: builder.query<PortfolioStats, void>({
            providesTags: [MARKETS_TAG],
            query() {
                return {
                    url: GET_PORTFOLIO_STATS_ROUTE,
                    method: "POST"
                };
            }
        }),
        sellQuote: builder.query<SellLoanQuote, string>({
            providesTags: [MARKETS_TAG],
            query(loanAddress: string) {
                return {
                    url: `${ROUTE_LOAN_SALE_QUOTE}/${loanAddress}`,
                    method: "GET"
                };
            }
        }),
        portfolioRewards: builder.query<PortfolioRewards, PortfolioRewardFilter>({
            providesTags: [MARKETS_TAG],
            query(filter) {
                return {
                    url: ROUTE_PORTFOLIO_REWARDS,
                    method: "POST",
                    body: filter
                };
            }
        })
    })
});

export const {
    useOracleMarketInformationsQuery,
    useLendingStrategyInfosQuery,
    useLoanInfosQuery,
    useCollateralStatsQuery,
    useIncreasePrincipalQuoteQuery,
    usePortfolioStatsQuery,
    useSellQuoteQuery,
    usePortfolioRewardsQuery
} = marketsApi;

export const useMarketsApi = () => {
    const api = marketsApi.endpoints;

    const dispatch = useDispatch();
    const [fetchLoanInfos] = api.loanInfos.useLazyQuery();

    return {
        resetMarketsApi: () => dispatch(marketsApi.util.invalidateTags([MARKETS_INFO_TAG])),
        resetLendingStrategyApi: () => dispatch(marketsApi.util.invalidateTags([LENDING_STRATEGY_TAG])),
        fetchLoanInfos,
        resetNapoleonApi: () => dispatch(marketsApi.util.invalidateTags([MARKETS_TAG])),
        resetLoanApi: () => dispatch(marketsApi.util.invalidateTags([LOANS_TAG]))
    };
};
