import { Icon, SPACING } from "@bridgesplit/ui";
import { AllInclusiveOutlined, PieChartOutline } from "@mui/icons-material";
import { Hidable } from "@bridgesplit/utils";
import { PORTFOLIO_OVERVIEW_SLUG, REWARDS_SLUG, LOOPS_SLUG, BORROW_SLUG, LEND_SLUG } from "app/constants";
import { useActiveWallet } from "@bridgesplit/abf-react";

import { NavOption } from "./types";
import { DESKTOP_PADDING } from "./constants";

export function useNavOptions(params?: { isMobile?: boolean }) {
    const { activeWallet } = useActiveWallet();
    const options: Hidable<NavOption>[] = [
        {
            title: "Lend",
            path: LEND_SLUG,
            icon: <Icon type="lend" />
        },
        {
            title: "Loop",
            path: LOOPS_SLUG,
            icon: <AllInclusiveOutlined />,
            pathRegex: new RegExp(LOOPS_SLUG)
        },
        {
            title: "Borrow",
            path: BORROW_SLUG,
            icon: <Icon type="borrow" />
        },
        {
            title: "Portfolio",
            path: PORTFOLIO_OVERVIEW_SLUG,
            icon: <PieChartOutline />,
            hide: !activeWallet
        },
        {
            title: "Rewards",
            path: REWARDS_SLUG,
            icon: <Icon type="points" />
        }
    ].filter((o) => !o.hide);

    return options;
}

export function useNavbarHeight() {
    const height = 70;
    const navFooter = 0;
    const footer = 40;

    return {
        footer,
        height,
        navFooter,
        heightInPixels: `${height}px`,
        fullHeight: `calc(100vh - ${height}px)`,
        stickyTop: height + DESKTOP_PADDING * SPACING
    };
}
