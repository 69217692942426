import { useMemo } from "react";

import { AppButton, useLoopWindAlerts } from "app/components/common";
import { COPY } from "app/constants";
import { Column, TagTextAlert } from "@bridgesplit/ui";
import { BsMetaUtil, getLoopBorrowCapDetails, isValidLeverage, useAuth } from "@bridgesplit/abf-react";
import { uiAmountToLamports } from "@bridgesplit/utils";

import { useLoopBalanceChecker, useLoopWind } from "./util";
import { useWindContext } from "./WindContext";
import { useLoopContext } from "../LoopContext";
import WindRiskMessage from "./WindRiskMessage";

const MIN_COLLATERAL_USD = 1;
export default function WindCta() {
    const wind = useLoopWind();
    const { form, isQuoteFetching, quote, externalQuote } = useWindContext();

    const { insufficientBalance } = useLoopBalanceChecker();
    const { loopExpanded } = useLoopContext();
    const { windDisabledWithMessage } = useLoopWindAlerts(loopExpanded);
    const { isWalletBased, isAuthenticated } = useAuth();

    const borrowCapDetails = useMemo(() => {
        if (!loopExpanded) return undefined;
        return getLoopBorrowCapDetails(loopExpanded);
    }, [loopExpanded]);

    const ctaError = useMemo(() => {
        if (!isValidLeverage(loopExpanded?.loopVault?.maxLeverage)) return "No leverage available";
        if (!form.collateralAmount) return "Enter an amount";
        if (!isValidLeverage(form.multiplier)) return `Enter ${COPY.LEVERAGE_TERM.toLowerCase()}`;
        if (isQuoteFetching) return "Fetching";
        return undefined;
    }, [form.collateralAmount, form.multiplier, isQuoteFetching, loopExpanded?.loopVault?.maxLeverage]);

    const alertError = useMemo(() => {
        if (isQuoteFetching) return undefined;
        if (borrowCapDetails?.remainingGlobalCapacity === 0)
            return `${BsMetaUtil.getSymbol(loopExpanded?.principalToken)} borrow cap exceeded`;
        if (windDisabledWithMessage) return windDisabledWithMessage;
        if (!isQuoteFetching && form.collateralAmount && form.multiplier) {
            if (quote === null)
                return `Market does not have enough ${BsMetaUtil.getSymbol(loopExpanded?.principalToken)} supplied`;
            if (!externalQuote && isAuthenticated) return "Not enough liquidity available on jupiter to swap from principal to collateral";
        }
        const collateralUsd = loopExpanded?.collateralOracle.getUsdAmount(form.collateralAmount);
        if (collateralUsd && collateralUsd < MIN_COLLATERAL_USD)
            return `Positions must be at least $${MIN_COLLATERAL_USD}`;

        const leveragedCollateralAmount =
            form.collateralAmount &&
            form.multiplier &&
            loopExpanded?.maxCollateralAmount &&
            uiAmountToLamports(form.collateralAmount * form.multiplier, loopExpanded?.collateralToken.decimals);
        if (leveragedCollateralAmount && leveragedCollateralAmount > loopExpanded?.maxCollateralAmount)
            return `Exceeds max ${BsMetaUtil.getSymbol(loopExpanded?.collateralToken)} amount`;

        if (borrowCapDetails && quote?.principalAmount) {
            const { exceedsGlobalCap, exceedsPerLoanCap } = borrowCapDetails;

            if (exceedsPerLoanCap(quote.principalAmount)) return "Exceeds borrow limit";
            if (exceedsGlobalCap(quote.principalAmount)) return "Exceeds borrow cap";
        }
        if (insufficientBalance) return "Insufficient balance";
        if (!isWalletBased && isAuthenticated) return `${COPY.LOOP_TERM} is only supported for individual accounts`;

        return undefined;
    }, [
        isQuoteFetching,
        borrowCapDetails,
        loopExpanded?.principalToken,
        loopExpanded?.collateralOracle,
        loopExpanded?.maxCollateralAmount,
        loopExpanded?.collateralToken,
        windDisabledWithMessage,
        form.collateralAmount,
        form.multiplier,
        quote,
        insufficientBalance,
        isWalletBased,
        isAuthenticated,
        externalQuote
    ]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                isTransaction
                asyncCta={{
                    onClickWithResult: wind,
                    options: { alertOnError: true }
                }}
                color="secondary"
                disabled={!!alertError || !!ctaError}
            >
                {ctaError || COPY.LOOP_TERM_ACTION}
            </AppButton>
            <WindRiskMessage />
        </Column>
    );
}
