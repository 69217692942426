import { useBalanceChecker } from "app/components/common";

import { useVaultDepositContext } from "./VaultDepositContext";
import { useVaultContext } from "../VaultContext";

export function useVaultDepositBalance() {
    const { form } = useVaultDepositContext();
    const { vaultExpanded } = useVaultContext();
    const updateAmount = useDepositUpdateAmount();
    return useBalanceChecker({
        amount: form.amount,
        mint: vaultExpanded?.principalMetadata?.mint,
        setMax: updateAmount
    });
}

export function useDepositUpdateAmount() {
    const { setForm } = useVaultDepositContext();
    return (amount: number | undefined) => setForm((prev) => ({ ...prev, amount }));
}
