import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { OracleQuoteInfo } from "@bridgesplit/abf-sdk";
import { getUnixTs } from "@bridgesplit/utils";

import { FETCH_ORACLE_PRICES_ROUTE } from "../constants";
import { abfSerializeQueryArgs, marketsBaseQuery, unauthenticatedSerializeQueryArgs } from "./util";

const ORACLE_PRICES = "OraclePrices";
const MARKET_INFORMATION = "MarketInformation";
export const oraclePricesApi = createApi({
    reducerPath: "oraclePricesApi",
    baseQuery: marketsBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ORACLE_PRICES, MARKET_INFORMATION],
    endpoints: (builder) => ({
        oraclePrices: builder.query<Record<string, OracleQuoteInfo>, void>({
            serializeQueryArgs: unauthenticatedSerializeQueryArgs,
            providesTags: [ORACLE_PRICES],
            queryFn: async (args, _, options, baseQuery) => {
                const res = await baseQuery({ url: FETCH_ORACLE_PRICES_ROUTE, method: "GET" });
                const rawData = res.data as Record<string, Omit<OracleQuoteInfo, "nowTimestamp">>;
                const now = getUnixTs();
                const data = Object.fromEntries(
                    Object.entries(rawData).map(([key, value]): [string, OracleQuoteInfo] => [
                        key,
                        { ...value, nowTimestamp: now }
                    ])
                );

                return { data };
            }
        })
    })
});

export const { useOraclePricesQuery } = oraclePricesApi;

export function useOraclePricesApi() {
    const dispatch = useDispatch();

    return {
        resetOraclePricesApi: () => dispatch(oraclePricesApi.util.invalidateTags([ORACLE_PRICES]))
    };
}
