import { useState } from "react";

import { OutlinedCard, Row, TextSkeleton, ToggleButtons, ToggleSpacedButtons } from "@bridgesplit/ui";
import { formatPercent, ALLOWED_FEATURES } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";
import { useMediaQuery } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { COPY } from "app/constants";

import { VAULT_DETAIL_QUERY } from "./constants";
import { VaultDeposit } from "./deposit";
import { VaultWithdraw } from "./withdraw";
import { SettingsButton } from "../common";
import { useVaultContext } from "./VaultContext";
import { VaultDepositCard } from "./VaultDepositCard";

enum Open {
    Deposit,
    Withdraw
}
export function VaultCard() {
    const isMobile = useMediaQuery(VAULT_DETAIL_QUERY.below);
    const [open, setOpen] = useState<Open>(Open.Deposit);

    if (isMobile) {
        return <VaultCardMobile open={open} setOpen={setOpen} />;
    }

    return (
        <>
            <VaultDepositCard />
            <OutlinedCard hideCard={isMobile} spacing={3}>
                <Row spaceBetween>
                    <ToggleSpacedButtons
                        variant="default"
                        size="small"
                        value={open}
                        setValue={setOpen}
                        options={[
                            { label: COPY.VAULT_DEPOSIT_CTA, value: Open.Deposit },
                            { label: COPY.VAULT_WITHDRAW_CTA, value: Open.Withdraw }
                        ]}
                    />
                    {open === Open.Withdraw && ALLOWED_FEATURES.showVaultWithdrawSlippage && <SlippageSettingButton />}
                </Row>
                {open === Open.Deposit && <VaultDeposit />}
                {open === Open.Withdraw && <VaultWithdraw />}
            </OutlinedCard>
        </>
    );
}

function VaultCardMobile({ open, setOpen }: { open: Open; setOpen: (open: Open) => void }) {
    return (
        <OutlinedCard spacing={3} hideCard>
            <VaultDepositCard />

            <ToggleButtons
                value={open}
                setValue={setOpen}
                options={[
                    { value: Open.Deposit, label: COPY.VAULT_DEPOSIT_CTA },
                    { value: Open.Withdraw, label: COPY.VAULT_WITHDRAW_CTA }
                ]}
            />
            {open === Open.Withdraw && ALLOWED_FEATURES.showVaultWithdrawSlippage && <SlippageSettingButton />}
            {open === Open.Deposit && <VaultDeposit />}
            {open === Open.Withdraw && <VaultWithdraw />}
        </OutlinedCard>
    );
}

function SlippageSettingButton() {
    const { open } = useAppDialog();
    const { slippageSetting, setSlippageSetting } = useVaultContext();

    if (slippageSetting === undefined) return <TextSkeleton variant="caption" width="30px" />;

    return (
        <SettingsButton
            onClick={() => {
                open(AppDialog.VaultWithdrawSlippage, { slippageSetting, setSlippageSetting });
            }}
        >
            <Settings />

            {formatPercent(slippageSetting, { minimumSignificantDigits: 1 })}
        </SettingsButton>
    );
}
