import { BsMetaUtil, isStrategyDurationSame, MarketGuideMode, useMarkets } from "@bridgesplit/abf-react";
import {
    BORDER_RADIUS_ROUNDED,
    Button,
    MAX_DIALOG_HEIGHT,
    PopoverWrapper,
    Row,
    SkeletonRounded,
    Span,
    Text,
    TextSkeleton,
    VerticalScroll,
    repeatElement,
    useAppPalette,
    usePopover,
    useTabbableList
} from "@bridgesplit/ui";
import { ExpandMore } from "@mui/icons-material";
import { PERCENT_DECIMALS, formatPercent } from "@bridgesplit/utils";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { useAppNavigate } from "@bridgesplit/react";

import { useMarketContext } from "../common/MarketContext";
import { HeaderWithClose, OpenHowItWorks, TokenImage, getMarketPath } from "../../common";
import { useQuotesContext } from "../common";
import { MOBILE_PADDING } from "../../navbar";
import { AppTopDrawerWrapper } from "../../wrappers";

export default function TokenHeader({ isMobile }: { isMobile?: boolean }) {
    const { token } = useMarketContext();

    const { open, isOpen, popoverProps, close } = usePopover();

    return (
        <>
            <Row spacing={1}>
                <Button
                    disableRipple
                    onClick={isOpen ? close : open}
                    sx={{ borderRadius: BORDER_RADIUS_ROUNDED }}
                    textProps={{
                        svgColor: "caption",
                        fontWeight: "normal",
                        variant: "h3"
                    }}
                    width="max-content"
                >
                    <Span sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <TokenImage size="md" metadata={token} />
                        <Span> {token ? BsMetaUtil.getSymbol(token) : <TextSkeleton variant="body1" />} </Span>
                    </Span>
                    <ExpandMore />
                </Button>
                {isMobile && <OpenHowItWorks mode={MarketGuideMode.BorrowAndLend} />}
            </Row>
            {(() => {
                if (isMobile) {
                    return (
                        <AppTopDrawerWrapper onClose={close} open={isOpen}>
                            <HeaderWithClose sx={{ px: MOBILE_PADDING }} close={close} header="Select market" />
                            <PrincipalMarketPicker px={MOBILE_PADDING} isOpen={isOpen} close={close} />
                        </AppTopDrawerWrapper>
                    );
                }
                return (
                    <PopoverWrapper
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        sx={{ width: "300px", py: 2, gap: 1 }}
                        {...popoverProps}
                    >
                        <PrincipalMarketPicker isOpen={isOpen} close={close} />
                    </PopoverWrapper>
                );
            })()}
        </>
    );
}

function PrincipalMarketPicker({ close, isOpen, px = 2 }: { close: () => void; isOpen: boolean; px?: number }) {
    const { data } = useMarkets({ skip: !isOpen });

    const { hoverBackground } = useAppPalette();
    const navigate = useAppNavigate();
    const { strategyDuration } = useQuotesContext();
    const { view } = useMarketContext();

    const { activeIndex, itemRefs, containerRef } = useTabbableList(() => {
        const market = data?.[activeIndex];
        if (!market) return;
        navigate(getMarketPath({ view: view, token: market.metadata }));

        close();
    }, data?.length);

    return (
        <>
            <Row sx={{ px, py: 0.5 }} spaceBetween>
                <Text color="caption" variant="body2">
                    Market
                </Text>
                <Text color="caption" variant="body2">
                    {formatDurationWithTypeShorthand(strategyDuration)} APY
                </Text>
            </Row>
            <VerticalScroll ref={containerRef} maxHeight={MAX_DIALOG_HEIGHT}>
                {!data && repeatElement(<SkeletonRounded sx={{ mx: px, flexGrow: 1 }} height={30} />)}
                {data?.map(({ metadata, strategyStats: { durationToMinApy } }, i) => {
                    const durationWithMinApy = durationToMinApy.find((d) =>
                        isStrategyDurationSame(strategyDuration, d)
                    );
                    return (
                        <Row
                            onClick={() => {
                                navigate(getMarketPath({ token: metadata, view }));
                                close();
                            }}
                            ref={(el) => (el ? (itemRefs.current[i] = el) : null)}
                            key={metadata.mint}
                            sx={{
                                px,
                                py: 0.5,
                                background: activeIndex === i ? hoverBackground : undefined,
                                cursor: "pointer",
                                ":hover": { background: hoverBackground }
                            }}
                            spaceBetween
                        >
                            <Row spacing={1.5}>
                                <TokenImage metadata={metadata} size="md" />

                                <Text>{BsMetaUtil.getSymbol(metadata)}</Text>
                            </Row>
                            <Text>{formatPercent(durationWithMinApy?.apy, { customDecimals: PERCENT_DECIMALS })}</Text>
                        </Row>
                    );
                })}
            </VerticalScroll>
        </>
    );
}
