import { useCallback, useMemo, useState } from "react";

import { EmptyPlaceholder } from "@bridgesplit/ui";
import { ALLOWED_FEATURES, LOADING_ERROR, MISSING_PARAM_ERROR, Result, filterTruthy } from "@bridgesplit/utils";
import {
    AbfLoanExpanded,
    getZcLedger,
    TokenBalanceExpanded,
    useActiveWallet,
    useDepositCollateralTransaction,
    useUserAvailableAssets
} from "@bridgesplit/abf-react";
import { SyncProblemOutlined } from "@mui/icons-material";
import { DepositCollateralParams } from "@bridgesplit/abf-sdk";
import { useTransactionSender } from "app/components/transactions";
import { TrackTransactionEvent } from "app/types";
import { trackSubmitTopup } from "app/hooks";

import { ActionProps } from "./types";
import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { CollateralLoanHealthChangeStats } from "./common";
import { useCalculateNewLoanHealth } from "./util";

export default function TopUp({ loanExpanded }: ActionProps) {
    const { data: assets } = useUserAvailableAssets({});
    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());

    const allowedCollateral = useMemo(() => {
        if (!loanExpanded) return new Set<string>();

        const mints = loanExpanded?.collateral
            .map((c) => {
                if (ALLOWED_FEATURES.disableMultiTopUp && c.loanCollateral.whirlpoolPosition) {
                    return [];
                }

                return [
                    c.loanCollateral.assetIdentifier,
                    c.loanCollateral.whirlpoolPosition?.tokenA.mint,
                    c.loanCollateral.whirlpoolPosition?.tokenB.mint
                ].filter(filterTruthy);
            })
            .flat();
        return new Set(mints);
    }, [loanExpanded]);

    const allowedUserAssets = assets?.filter((c) => allowedCollateral.has(c.metadata.mint));

    if (!allowedCollateral.size)
        return (
            <EmptyPlaceholder
                icon={<SyncProblemOutlined />}
                header="Top up not available"
                description="You don't have any collateral that can be topped up"
            />
        );

    return (
        <>
            <SelectAssets allowTokenInput assets={allowedUserAssets} selected={selected} setSelected={setSelected} />
            <TopUpCta assets={allowedUserAssets} loanExpanded={loanExpanded} selected={selected} />
        </>
    );
}

function TopUpCta({
    selected,
    loanExpanded,
    assets
}: {
    selected: Map<string, number | undefined>;
    loanExpanded: AbfLoanExpanded | undefined;
    assets: TokenBalanceExpanded[] | undefined;
}) {
    const selectedAssets = assets
        ?.map((asset) => ({ asset, selectedAmount: selected.get(asset.key) ?? 0 }))
        .filter((a) => !!a.selectedAmount);
    const healthChange = useCalculateNewLoanHealth(Array.from(selected.keys()))(
        loanExpanded,
        selectedAssets?.map((c) => ({ collateralMint: c.asset.metadata.mint, change: c.selectedAmount }))
    );
    const { activeWallet } = useActiveWallet();
    const send = useTransactionSender();
    const depositCollateral = useDepositCollateralTransaction();

    const submit = useCallback(async () => {
        const ledgerAccount = getZcLedger(loanExpanded);
        if (!loanExpanded || !activeWallet || !ledgerAccount) return Result.errFromMessage(LOADING_ERROR);

        if (!selectedAssets?.length) return Result.errFromMessage(MISSING_PARAM_ERROR);
        const params: DepositCollateralParams = {
            loan: loanExpanded.loan.address,
            depositMint: selectedAssets[0].asset.metadata.mint,
            decimals: selectedAssets[0].asset.metadata.decimals,
            amount: selectedAssets.reduce((acc, { selectedAmount }) => acc + selectedAmount, 0),
            assetType: 0, // only SPL tokens are allowed
            assetIdentifier: selectedAssets[0].asset.metadata.mint,
            expectedLoanValues: {
                expectedApy: ledgerAccount.apy,
                expectedLtv: ledgerAccount.ltvRatios,
                expectedLiquidationThreshold: ledgerAccount.lqtRatios
            }
        };

        return await send(depositCollateral, params, {
            description: "Adding collateral",
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitTopup,
                params: trackSubmitTopup(params, loanExpanded.principalMetadata, 0)
            }
        });
    }, [activeWallet, loanExpanded, depositCollateral, selectedAssets, send]);
    const assetSelectionError = getSelectAssetsErrorMessage({ selected, assets });

    return (
        <>
            <CollateralLoanHealthChangeStats healthChange={healthChange} />
            <AppButton
                isTransaction
                disabled={!!assetSelectionError}
                asyncCta={{ onClickWithResult: submit }}
                fullWidth
            >
                {assetSelectionError ?? " Top Up"}
            </AppButton>
        </>
    );
}
