import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { MayNumber, PERCENT_DECIMALS, colorToAlpha, doNothing, formatTokenAmount } from "@bridgesplit/utils";
import { Close, EmailOutlined, ExpandMore, Search } from "@mui/icons-material";
import {
    MenuItem,
    Select as MuiSelect,
    StandardTextFieldProps,
    TextField,
    SelectProps as MuiSelectProps,
    styled,
    Checkbox as MuiCheckbox,
    Switch as MuiSwitch,
    CheckboxProps,
    FormControl,
    Slider as MuiSlider,
    SliderProps as MuiSliderProps,
    SwitchProps,
    OutlinedInput,
    StackProps,
    InputAdornment,
    TextFieldProps,
    Autocomplete as MuiAutocomplete,
    Box
} from "@mui/material";
import debounce from "lodash.debounce";
import { NumericFormat, PatternFormat } from "react-number-format";

import { Column, Row } from "./containers";
import { Text, TextVariant, TooltipText, TextSkeleton, TooltipTextProps, TextColor } from "./typography";
import { SxType } from "../types";
import {
    BORDER_RADIUS,
    BORDER_RADIUS_ROUNDED,
    FONT_SIZES,
    INPUT_HEIGHT,
    LIGHT_SHADOW,
    SPACING_PX,
    useAppPalette,
    useTextColorMap
} from "../theme";
import { useOutsideAlerter } from "../util";
import { Icon } from "./icons";
import { Button, TextButton } from "./buttons";

export type SelectOption<T> = {
    label?: string | ReactElement;
    value: NonNullable<T>;
    disabled?: boolean;
    key?: string | number;
};

const Input = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        p: 1,
        height: INPUT_HEIGHT + "px",
        "& fieldset": {
            borderColor: theme.palette.divider
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.text.disabled,
            borderWidth: "1px"
        },
        borderRadius: BORDER_RADIUS
    }
}));

export interface SelectProps<T extends string | number> {
    value: T | undefined;
    setValue: (val: T) => void;
    options: SelectOption<T>[];
    renderValue?: (val: T) => ReactNode;
    hideIcon?: boolean;
    sx?: SxType;
    maxMenuHeight?: number;
    loading?: boolean;
    disabled?: boolean;
    selectProps?: Pick<MuiSelectProps<T>, "variant" | "disableUnderline" | "startAdornment">;
}
export function Select<T extends string | number>({
    value,
    setValue,
    renderValue,
    hideIcon,
    options,
    maxMenuHeight,
    sx,
    loading,
    disabled,
    selectProps
}: SelectProps<T>) {
    return (
        <StyledSelect
            MenuProps={{ PaperProps: { sx: { maxHeight: maxMenuHeight } } }}
            IconComponent={hideIcon ? () => null : undefined}
            inputProps={{ sx: { pr: hideIcon ? `${SPACING_PX} !important` : undefined } }}
            renderValue={loading ? () => <TextSkeleton variant="h4" /> : renderValue}
            disabled={loading || disabled}
            fullWidth
            value={value !== undefined ? value : ""}
            onChange={(val) => setValue(val.target.value as T)}
            sx={sx}
            {...selectProps}
        >
            {options.map((o, i) => (
                <MenuItem
                    disabled={o.disabled}
                    key={o.key ?? typeof o.value === "string" ? o.value : o.value.toString() + i}
                    value={o.value}
                    sx={{ minHeight: "0px" }}
                >
                    {o.label ?? o.value}
                </MenuItem>
            ))}
        </StyledSelect>
    );
}

export function InvisibleSelect<T extends string | number>({
    sx,
    selectProps,
    variant = "body1",
    color,
    ...props
}: SelectProps<T> & { variant?: TextVariant; color?: TextColor }) {
    const colorHex = useTextColorMap()(color ?? "body");
    return (
        <Select
            selectProps={{ disableUnderline: true, variant: "standard", ...selectProps }}
            {...props}
            sx={{
                borderRadius: BORDER_RADIUS,
                ".MuiInputBase-input": { px: 1, ":focus": { background: "none" } },
                height: FONT_SIZES.body1 + "px",
                color: colorHex,
                svg: { fontSize: FONT_SIZES.body1 },
                width: "max-content",
                fontSize: FONT_SIZES[variant],
                ...sx
            }}
        />
    );
}

export function InputSkeleton() {
    const { divider } = useAppPalette();
    return (
        <OutlinedInput
            disabled
            sx={{
                p: 1,
                height: INPUT_HEIGHT + "px",
                "& fieldset": {
                    borderColor: `${divider} !important`
                },
                borderRadius: BORDER_RADIUS
            }}
            startAdornment={<TextSkeleton variant="h4" />}
        />
    );
}

export interface OptionsProps<T extends string | number | undefined> {
    value: T | undefined;
    setValue: (val: T) => void;
    options: SelectOption<T>[];
}

export function TextOptions<T extends string | number | undefined>({
    options,
    value,
    setValue,
    variant
}: OptionsProps<T> & { variant?: TextVariant }) {
    return (
        <Row spacing={1}>
            {options.map((option, i) => (
                <TextButton
                    key={option.key ?? i}
                    color={(() => {
                        if (option.disabled) return "disabled";
                        if (option.value === value) return "body";
                        return "caption";
                    })()}
                    variant={variant}
                    onClick={() => !option.disabled && setValue(option.value)}
                >
                    {option.label ?? option.value}
                </TextButton>
            ))}
        </Row>
    );
}

export function OutlinedOptions<T extends string | number | undefined>({
    options,
    value,
    setValue,
    color
}: OptionsProps<T> & { color?: "primary" | "secondary" }) {
    return (
        <Column spacing={1}>
            {options.map((option, i) => (
                <OutlinedOption
                    color={color}
                    key={option.key ?? i}
                    disabled={option.disabled}
                    isSelected={option.value === value}
                    onClick={() => !option.disabled && setValue(option.value)}
                >
                    {option.label ?? <Text> {option.value} </Text>}
                </OutlinedOption>
            ))}
        </Column>
    );
}

type OutlinedOptionProps = {
    onClick?: () => void;
    isSelected: boolean;
    disabled?: boolean;
    children: ReactNode;
    padding?: number;
    sx?: SxType;
    color?: "primary" | "secondary";
};
export function OutlinedOption({
    onClick,
    isSelected,
    disabled,
    children,
    padding = 2,
    sx,
    color = "primary"
}: OutlinedOptionProps) {
    const { border, secondary, hoverOpacity, primary } = useAppPalette();

    const base = color === "secondary" ? secondary : primary;

    return (
        <Column
            sx={{
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? "not-allowed" : "pointer",
                ":hover": { background: disabled ? undefined : colorToAlpha(base, hoverOpacity * 1.5) },
                p: padding,
                background: disabled || !isSelected ? undefined : colorToAlpha(base, hoverOpacity),
                borderRadius: BORDER_RADIUS,
                outline: isSelected ? `1.5px solid ${base}` : border,
                ...sx
            }}
            onClick={disabled ? doNothing : onClick}
        >
            {children}
        </Column>
    );
}

export function OutlinedChip({ sx, children, isSelected, ...props }: OutlinedOptionProps) {
    const { secondary, hoverOpacity, primary } = useAppPalette();

    const base = props.color === "secondary" ? secondary : primary;
    const active = !props.disabled && !!isSelected;
    return (
        <Button
            height={30}
            textProps={{ sx: { color: colorToAlpha(base, active ? 1 : 0.6) } }}
            variant="outlined"
            sx={{
                px: 1.5,
                py: 0.5,
                borderRadius: BORDER_RADIUS_ROUNDED,
                minWidth: "max-content",
                borderColor: active ? base : undefined,
                background: active ? colorToAlpha(base, hoverOpacity) : undefined,
                ...sx
            }}
            {...props}
        >
            {children}
        </Button>
    );
}

function StyledSelect<T>({ sx, ...otherProps }: MuiSelectProps<T>) {
    const { inputBorder, textDisabled, isLightMode, hoverBackground } = useAppPalette();

    return (
        <MuiSelect
            displayEmpty
            MenuProps={{
                elevation: 0,
                disableScrollLock: true,
                sx: {
                    padding: 1,
                    ".MuiPaper-root": {
                        border: `1px ${inputBorder} solid`,
                        boxShadow: isLightMode ? LIGHT_SHADOW : undefined,
                        marginTop: 0.5
                    },
                    ".MuiList-root": {
                        padding: "0px",
                        ".Mui-selected": {
                            backgroundColor: hoverBackground
                        }
                    }
                }
            }}
            sx={{
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: inputBorder
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: inputBorder,
                    borderWidth: "1px"
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: textDisabled
                },
                "& .MuiSelect-select": {
                    p: 1,
                    display: "flex"
                },
                svg: { fontSize: "inherit" },
                ".MuiPaper-root": { boxShadow: "none" },
                ...sx,
                borderRadius: BORDER_RADIUS
            }}
            {...otherProps}
            IconComponent={otherProps.IconComponent ?? ExpandMore}
        />
    );
}

export function SearchInput({
    forceFocus,
    hideBorder,
    sx,
    ...props
}: TextFieldProps & { forceFocus?: boolean; hideBorder?: boolean }) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current || !forceFocus) return;
        inputRef.current.focus();
    }, [forceFocus]);

    return (
        <Input
            sx={{ "& fieldset": { border: hideBorder ? "none" : undefined }, ...sx }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
                inputRef
            }}
            {...props}
        />
    );
}

interface FormInputProps
    extends Omit<StandardTextFieldProps, "label" | "variant" | "margin" | "defaultValue" | "type"> {
    adornment?: ReactNode;
    label?: string;
    tooltip?: string;
    errorMessage?: string;
    alwaysShowError?: boolean;
    multiLineHeight?: number;
    unitSelect?: SelectProps<string | number>;
    variant?: "number" | "string";
    isControlled?: boolean;
    type?: "text" | "password" | "tel";
    inputHeight?: number;
    belowInputElement?: ReactNode; // pass in as props to allow elements in between error message
}

interface FormLabelProps {
    label: string;
    tooltip?: string;
    disabled?: boolean;
    color?: TextColor;
}
export function FormLabel({ label, tooltip, disabled, color = "caption" }: FormLabelProps) {
    return (
        <TooltipText helpText={tooltip ?? ""} variant="body2" color={disabled ? "disabled" : color} sx={{ mb: 0.5 }}>
            {label}
        </TooltipText>
    );
}

export function InputErrorMessage({
    errorMessage,
    showError
}: {
    errorMessage: string | undefined;
    showError: boolean;
}) {
    const errorVisible = showError && !!errorMessage;
    return (
        <Text
            variant="body2"
            color="error"
            sx={{
                mt: errorVisible ? 0.5 : 0,
                opacity: errorVisible ? 1 : 0,
                transition: "opacity 0.3s",
                height: errorVisible ? undefined : "0px"
            }}
        >
            {errorMessage}
        </Text>
    );
}

export function ErrorMessage({ errorMessage }: { errorMessage: string | undefined }) {
    if (!errorMessage) return null;
    return (
        <Text variant="body2" color="error">
            {errorMessage}
        </Text>
    );
}

interface LabelWrapperProps extends FormLabelProps {
    children: ReactNode;
    labelColor?: TextColor;
}
export function LabelWrapper({ children, labelColor, ...props }: LabelWrapperProps) {
    return (
        <Column>
            <FormLabel color={labelColor} {...props} />
            {children}
        </Column>
    );
}

function BaseFormInput({
    value,
    adornment,
    label,
    InputProps,
    tooltip,
    unitSelect,
    errorMessage,
    alwaysShowError,
    multiLineHeight,
    isControlled = true,
    belowInputElement,
    variant,
    inputHeight,
    ...props
}: FormInputProps) {
    const inputProps = props as StandardTextFieldProps;
    delete inputProps.variant;

    return (
        <Input
            value={isControlled ? value ?? "" : undefined}
            {...inputProps}
            multiline={!!multiLineHeight}
            inputProps={{ inputMode: variant === "number" ? "decimal" : undefined, ...props.inputProps }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    height: (() => {
                        if (inputHeight) return `${inputHeight}px`;
                        if (multiLineHeight) return "auto";
                        return undefined;
                    })(),
                    minHeight: multiLineHeight,
                    alignItems: multiLineHeight ? "flex-start" : undefined
                },
                ...inputProps.sx
            }}
            InputProps={{
                endAdornment: (() => {
                    if (unitSelect) {
                        const { sx, ...unitSelectProps } = unitSelect;
                        return (
                            <FormControl>
                                <Select
                                    sx={{
                                        svg: {
                                            fontSize: "1.5rem",
                                            color: "text.secondary"
                                        },
                                        "& fieldset": { border: "none" },
                                        width: "max-content",

                                        pr: 0.5,
                                        border: "none",
                                        ...sx
                                    }}
                                    {...unitSelectProps}
                                />
                            </FormControl>
                        );
                    }

                    if (typeof adornment === "string") {
                        return <Text sx={{ minWidth: "fit-content" }}> {adornment} </Text>;
                    }
                    return adornment;
                })(),
                ...InputProps
            }}
        />
    );
}

function CombinedFormInput(props: FormStringInputProps | FormNumberInputProps) {
    if (props.variant === "number") return <FormNumberInput {...(props as FormNumberInputProps)} />;
    return <FormStringInput {...(props as FormStringInputProps)} />;
}

export function FormInput({
    alwaysShowError,
    label,
    tooltip,
    belowInputElement,
    errorMessage,
    ...props
}: FormStringInputProps | FormNumberInputProps) {
    const [showErrorState, setShowError] = useState(false);
    useOutsideAlerter(() => setShowError(true));
    const showError = (errorMessage && alwaysShowError) || showErrorState;
    return (
        <Column onBlur={() => setShowError(true)} sx={{ width: props.fullWidth ? "100%" : undefined }}>
            {!!label && <FormLabel disabled={props.disabled} label={label} tooltip={tooltip} />}

            <CombinedFormInput {...props} />

            {belowInputElement}
            <InputErrorMessage errorMessage={errorMessage} showError={showError} />
        </Column>
    );
}

interface FormStringInputProps extends FormInputProps {
    value: string | undefined;
    setValue: (n: string | undefined) => void;
    variant: "string";
    pattern?: { format: string; mask?: string | string[] };
}

function FormStringInput({ setValue, type, value, pattern, ...props }: FormStringInputProps) {
    if (!pattern) {
        return <BaseFormInput {...props} type={type} value={value} onChange={(e) => setValue(e.target.value)} />;
    }
    return (
        <PatternFormat
            format={pattern.format}
            mask={pattern.mask}
            {...props}
            type={type}
            customInput={BaseFormInput}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}

interface FormDebouncedStringInput extends FormStringInputProps {
    debounceLength?: number;
}
export function FormDebouncedStringInput({
    setValue,
    label,
    tooltip,
    debounceLength = 500,
    ...props
}: FormDebouncedStringInput) {
    const handleValue = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(e.target.value);
        },
        [setValue]
    );

    const debouncedResults = useMemo(() => {
        return debounce(handleValue, debounceLength);
    }, [debounceLength, handleValue]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, [debouncedResults]);

    return (
        <Column>
            {!!label && <FormLabel disabled={props.disabled} label={label} tooltip={tooltip} />}
            <BaseFormInput isControlled={false} onChange={debouncedResults} {...props} />
        </Column>
    );
}

export interface FormNumberInputProps extends FormInputProps {
    value: number | undefined;
    setValue: (n: number | undefined) => void;
    decimals?: number;
    variant: "number";
    thousandSeparator?: boolean;
    allowNegative?: boolean;
    validateNumber?: (n: number | undefined) => boolean;
}

function FormNumberInput({
    value,
    setValue,
    decimals,
    thousandSeparator = true,
    allowNegative = false,
    validateNumber,
    ...props
}: FormNumberInputProps) {
    return (
        <NumericFormat
            isAllowed={validateNumber ? (val) => validateNumber(val.floatValue) : undefined}
            thousandSeparator={thousandSeparator ? "," : undefined}
            {...props}
            customInput={BaseFormInput}
            allowNegative={allowNegative}
            value={value === undefined ? "" : value}
            decimalScale={decimals}
            onValueChange={(e) => {
                // allow negative numbers
                if (e.value.startsWith("-") && e.floatValue === undefined) return;

                // Otherwise if user type .01 it will show as 1
                if (e.value.startsWith(".") && e.floatValue === 0) return;

                // prevent deleting decimals on deleting last digit
                if (e.value.endsWith(".") && e.value === e.floatValue + ".") return;

                // prevent deleting of 0s from wiping input
                if (e.value.includes(".") && e.value.endsWith("0")) return;

                setValue(e.floatValue);
            }}
        />
    );
}

interface TokenInputProps extends Omit<FormNumberInputProps, "variant"> {
    maxAmount: MayNumber;
    maxText?: string;
    symbol?: string;
    decimals?: number;
    showBalance?: boolean;
    maxButtonCta?: string;
    maxLoading?: boolean;
    setMax?: (n: number | undefined) => void; // override setValue (ex for non debounced input)
}
export function TokenInput({
    maxAmount,
    decimals,
    placeholder,
    maxText = "Balance: ",
    symbol,
    showBalance = true,
    maxButtonCta = "Max",
    setMax,
    maxLoading,
    ...props
}: TokenInputProps) {
    const isMaxSelected = !!maxAmount && props.value === maxAmount;
    return (
        <FormInput
            decimals={decimals}
            belowInputElement={
                showBalance ? (
                    <Row sx={{ my: 0.5 }} spaceBetween>
                        <Row spacing={0.25}>
                            <Text variant="caption" color="caption">
                                {maxText}
                            </Text>
                            <Text loadingWidth="30px" loading={maxLoading} variant="caption" color="caption">
                                {formatTokenAmount(maxAmount, { symbol, decimals })}
                            </Text>
                        </Row>

                        <Text
                            onClick={() => {
                                if (maxLoading) return;
                                const newAmount = isMaxSelected ? undefined : maxAmount;
                                if (setMax) {
                                    setMax(newAmount);
                                } else {
                                    props.setValue(newAmount);
                                }
                            }}
                            isLink
                            variant="caption"
                            color="primary"
                        >
                            {isMaxSelected ? "Clear" : maxButtonCta}
                        </Text>
                    </Row>
                ) : undefined
            }
            adornment={props.adornment ?? symbol}
            variant="number"
            placeholder={placeholder ?? "0"}
            {...props}
        />
    );
}

export function PercentInput({ placeholder, ...props }: Omit<FormNumberInputProps, "variant" | "decimals">) {
    return (
        <FormInput decimals={PERCENT_DECIMALS} adornment="%" variant="number" placeholder={placeholder} {...props} />
    );
}

export function emailFormAdornments({
    isValidEmail,
    showIcon = true,
    errorMessage = "Invalid email address"
}: {
    isValidEmail: boolean;
    errorMessage?: string;
    showIcon?: boolean;
}) {
    return {
        endAdornment: !isValidEmail ? (
            <TooltipText helpText={errorMessage} icon={false} color="error">
                <Icon type="warning" />
            </TooltipText>
        ) : undefined,
        startAdornment: showIcon ? (
            <Text color="disabled" sx={{ mr: 1 }}>
                <EmailOutlined />
            </Text>
        ) : undefined
    };
}

export function preventInvalidNumberTextFieldValues(event: React.KeyboardEvent<HTMLDivElement>) {
    const key = event.key;
    if (key === "-" || key === "e" || key === "+") {
        event.preventDefault();
    }
}

export function Checkbox({ sx, ...props }: Omit<CheckboxProps, "disableRipple" | "size">) {
    return <MuiCheckbox size="small" disableRipple sx={{ p: 0, width: "fit-content", ...sx }} {...props} />;
}

export function Switch({ sx, ...props }: Omit<SwitchProps, "disableRipple">) {
    return <MuiSwitch disableRipple sx={{ m: -1, ...sx }} {...props} />;
}

export function CheckboxRow({
    value,
    setValue,
    label,
    textProps,
    spaceBetween
}: {
    value: boolean;
    setValue: (t: boolean) => void;
    label: string;
    textProps?: Omit<TooltipTextProps, "children">;
    spaceBetween?: boolean;
}) {
    return (
        <Row
            spaceBetween={spaceBetween}
            onClick={() => (value ? setValue(false) : setValue(true))}
            sx={{ width: "100%", cursor: "pointer", flexDirection: spaceBetween ? "row-reverse" : undefined }}
            spacing={1}
        >
            <Checkbox checked={!!value} />

            <TooltipText color={textProps?.color ?? "caption"} {...textProps}>
                {label}
            </TooltipText>
        </Row>
    );
}

export function SwitchRow({
    value,
    setValue,
    label,
    textProps,
    switchColor = "secondary",
    disabled,
    spaceBetween = true
}: {
    value: boolean;
    setValue: (t: boolean) => void;
    label: string | ReactElement;
    textProps?: Omit<TooltipTextProps, "children">;
    switchColor?: "primary" | "secondary";
    disabled?: boolean;
    spaceBetween?: boolean;
}) {
    return (
        <Row
            onClick={disabled ? undefined : () => (value ? setValue(false) : setValue(true))}
            sx={{ width: spaceBetween ? "100%" : undefined, cursor: disabled ? "not-allowed" : "pointer" }}
            spaceBetween={spaceBetween}
            spacing={1}
        >
            {typeof label === "string" ? (
                <TooltipText color={disabled ? "disabled" : textProps?.color ?? "caption"} {...textProps}>
                    {label}
                </TooltipText>
            ) : (
                label
            )}
            <Switch disabled={disabled} checked={!!value} color={switchColor} />
        </Row>
    );
}

const SliderBase = styled(MuiSlider)(() => ({
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-thumb": {
        height: 14,
        width: 14,
        backgroundColor: "currentColor",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "none"
        },
        "&:before": {
            display: "none"
        }
    }
}));

interface RangeSliderProps extends MuiSliderProps {
    values: [number, number];
    setValues: (val: [number, number]) => void;
}

export function RangeSlider({ values, setValues, ...props }: RangeSliderProps) {
    return (
        <SliderBase
            value={values}
            onChange={(_, vals) => {
                if (typeof vals === "number") return;
                setValues(vals as [number, number]);
            }}
            {...props}
        />
    );
}
interface SingleValueSliderProps extends MuiSliderProps {
    value: number | undefined;
    setValue: (val: number) => void;
    hideStartMark?: boolean;
    hideEndMark?: boolean;
}

export function Slider({
    value,
    setValue,
    sx,
    valueLabelDisplay,
    hideStartMark,
    hideEndMark,
    ...props
}: SingleValueSliderProps) {
    const { textSecondary } = useAppPalette();
    return (
        <SliderBase
            sx={{
                ".MuiSlider-markLabel[data-index='0']": {
                    transform: "translateX(0)",
                    display: hideStartMark ? "none" : "block"
                },
                [`.MuiSlider-markLabel[data-index='${Array.isArray(props.marks) && props.marks.length - 1}']`]: {
                    transform: "translateX(-100%)",
                    display: hideEndMark ? "none" : "block"
                },
                ".MuiSlider-mark": { display: "none" },
                ".MuiSlider-markLabel": {
                    color: textSecondary
                },
                ...sx
            }}
            value={value ?? 0}
            onChange={(_, val) => {
                if (typeof val !== "number") return;
                setValue(val);
            }}
            valueLabelDisplay={valueLabelDisplay}
            {...props}
        />
    );
}

export function InputButtonWrapper({ children }: { children: ReactNode }) {
    const { border } = useAppPalette();
    return (
        <Column
            sx={{
                borderRadius: BORDER_RADIUS,
                alignItems: "center",
                justifyContent: "center",
                border,
                height: INPUT_HEIGHT + "px",
                width: INPUT_HEIGHT + "px"
            }}
        >
            {children}
        </Column>
    );
}

export function InputWrapper({
    children,
    sx,
    height = INPUT_HEIGHT,
    ...props
}: { children: ReactNode; sx?: SxType; height?: number } & Pick<StackProps, "onClick">) {
    const { border } = useAppPalette();
    return (
        <Row
            sx={{
                border,
                borderRadius: BORDER_RADIUS,
                height: height + "px",
                maxHeight: height + "px",
                minHeight: height + "px",
                px: 1,
                ...sx
            }}
            {...props}
        >
            {children}
        </Row>
    );
}

export function BackCta({
    back,
    backCta = "Back",
    textVariant = "body1"
}: {
    back: () => void;
    backCta?: string;
    textVariant?: TextVariant;
}) {
    return (
        <TextButton variant={textVariant} underlineLink={false} color="caption" onClick={back}>
            <Icon type="back" /> {backCta}
        </TextButton>
    );
}

type AutocompleteProps<T> = {
    sx?: SxType;
    options: T[] | undefined;
    value: T | undefined;
    setValue: (t: T | undefined) => void;
    renderOption?: (t: T) => ReactNode;
    getOptionLabel?: (t: T) => string;
    placeholder?: string;
    InputProps?: TextFieldProps["InputProps"];
    disableClearable?: boolean;
};
const ICON_SX: SxType = { color: (theme) => theme.palette.text.disabled, fontSize: FONT_SIZES.h3 };
export function Autocomplete<T extends string>({
    sx,
    options,
    value,
    setValue,
    renderOption,
    getOptionLabel,
    placeholder,
    InputProps,
    disableClearable
}: AutocompleteProps<T>) {
    return (
        <MuiAutocomplete
            value={value ?? null}
            onChange={(_, val) => {
                setValue(val ?? undefined);
            }}
            getOptionLabel={getOptionLabel}
            loading={options === undefined}
            options={options ?? []}
            sx={{
                ...sx,
                "& .MuiOutlinedInput-root": {
                    padding: 0, // Remove the default padding override
                    px: 2
                }
            }}
            disableClearable={disableClearable}
            clearIcon={<Close sx={ICON_SX} />}
            popupIcon={<ExpandMore sx={ICON_SX} />}
            renderOption={
                renderOption
                    ? (props, option) => {
                          return (
                              <Box component="li" {...props}>
                                  {renderOption(option)}
                              </Box>
                          );
                      }
                    : undefined
            }
            renderInput={({ InputProps: paramsInputProps, ...params }) => (
                <Input
                    InputProps={{ ...paramsInputProps, ...InputProps }}
                    placeholder={placeholder ?? "Search"}
                    {...params}
                />
            )}
        />
    );
}
