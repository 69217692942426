import { Icon, EmptyPlaceholder } from "@bridgesplit/ui";

import UnauthenticatedPage from "./UnauthenticatedPage";

export function MaintenancePage() {
    return (
        <UnauthenticatedPage>
            <EmptyPlaceholder
                icon={<Icon type="settings" />}
                header="Scheduled Downtime on Loopscale"
                description="We're performing scheduled maintenance and upgrades to our platform today from 11:00 AM UTC to 11:00 PM UTC. During this short window, users will not be able to use the Loopscale interface."
            />
        </UnauthenticatedPage>
    );
}
