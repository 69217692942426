import { ErrorType, IS_LOCAL_NX_DEV, Result, TransactionSettingsHeader, formatAddress } from "@bridgesplit/utils";
import { AppCookie, getAvatarFromString, getBearerToken } from "@bridgesplit/react";
import { AbfTransactionHeaders } from "@bridgesplit/abf-sdk";
import { UAParser } from "ua-parser-js";

import { AbfUserWallet } from "../types";
import {
    AUTH0_COOKIES_PREFIX,
    ABF_GROUP_COOKIE_PREFIX,
    USER_WALLET_COOKIE,
    TRANSACTION_SETTINGS_COOKIE_PREFIX
} from "../constants";

export function getTransactionHeadersFromCookies(): Result<AbfTransactionHeaders> {
    const bearerToken = getBearerToken(AUTH0_COOKIES_PREFIX);
    const groupIdentifier = getBearerToken(ABF_GROUP_COOKIE_PREFIX);
    const userWallet = AppCookie.get(USER_WALLET_COOKIE);
    const txnSettings = parseTransactionSettings(AppCookie.get(TRANSACTION_SETTINGS_COOKIE_PREFIX));

    if (!bearerToken && !userWallet) {
        return Result.errFromMessage("No authentication provided", { errorType: ErrorType.AuthError });
    }

    return Result.ok({ groupIdentifier, bearerToken, userWallet, txnSettings });
}

export function parseTransactionSettings(settings: string | undefined): TransactionSettingsHeader | undefined {
    if (!settings) return undefined;
    try {
        return JSON.parse(settings) as TransactionSettingsHeader;
    } catch {
        return undefined;
    }
}

export function getAvatarFromName(name: string | undefined) {
    if (!name) return undefined;
    const names = name?.split(" ");
    const initials = names.map((n) => n.replace(/[0-9]/g, "")?.[0]);
    return getAvatarFromString(initials.join(""));
}

export function isAuth0SameDomain(auth0Domain: string) {
    if (IS_LOCAL_NX_DEV) return false;
    const subDomain = window.location.host.split(".").slice(1).join(".");
    const auth0Subdomain = auth0Domain.split(".").slice(1).join(".");

    return subDomain === auth0Subdomain;
}

export function isCrossOriginAllowed() {
    const parsed = new UAParser(navigator.userAgent).getResult();

    return parsed.browser.name === "Chrome";
}

export function formatWallet(wallet: AbfUserWallet | undefined) {
    return formatAddress(wallet?.wallet);
}
