import { ReactNode, useMemo } from "react";

import {
    BsMetaUtil,
    TokenBalanceExpanded,
    userAssetsApi,
    isValidPublicKey,
    useAbfFetches,
    useActiveWallet,
    useTokenListMetadataByMint,
    useWalletBalanceByMint,
    LendVaultSortType,
    lendingVaultApi
} from "@bridgesplit/abf-react";
import {
    Button,
    Column,
    FONT_SIZES,
    FormInput,
    Icon,
    IconWithBackground,
    RefreshButtonWithLoading,
    Row,
    SolanaSvg,
    Span,
    Text,
    TextVariant,
    Tooltip,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AppDialog, useAppDialog } from "app/utils";
import { COPY } from "app/constants";
import { DispatchType } from "@bridgesplit/react";
import { SortDirection } from "@bridgesplit/utils";

type BalanceCheckOptions = {
    amount: number | undefined;
    mint: string | undefined;
    escrowNeeded?: boolean; // if omitted, will use escrow preference
    customBalance?: number;
    skip?: boolean;
    setMax?: (amount: number) => void;
    hideRowEnd?: boolean;
    vaultAddress?: string;
};

type BalanceProps = {
    variant?: TextVariant;
    maxText?: string;
    hideRefresh?: boolean;
    helpText?: string;
    hideInsufficientBalance?: boolean;
};

export function useBalanceChecker({
    amount,
    mint,
    escrowNeeded: escrowNeededOverride,
    skip: skipProps,
    customBalance,
    hideRowEnd,
    setMax,
    vaultAddress
}: BalanceCheckOptions) {
    const { error } = useAppPalette();

    const { activeWallet } = useActiveWallet();

    const escrowNeeded = escrowNeededOverride;

    const walletBalance = useWalletBalanceByMint(activeWallet, mint, {
        skip: !!escrowNeeded || !activeWallet,
        fullSolBalance: false
    });

    const { isLoading, balance } = useMemo(() => {
        if (customBalance !== undefined) return { balance: customBalance, isLoading: false };

        return { balance: walletBalance.data?.amount ?? 0, isLoading: walletBalance.isLoading };
    }, [customBalance, walletBalance]);

    const metadata = useTokenListMetadataByMint(mint);

    const insufficientBalance = !skipProps && amount && balance !== undefined ? balance < amount : false;

    const tokenBalance: TokenBalanceExpanded | undefined = metadata
        ? { key: metadata.mint, metadata, amount: balance ?? 0 }
        : undefined;

    const RefreshBalance = ({ variant }: { variant: TextVariant }) => {
        if (vaultAddress) {
            return <RefreshVaultWithdrawButton variant={variant} vaultAddress={vaultAddress} />;
        }
        return <RefreshWalletButton variant={variant} />;
    };

    const RowEnd = ({ variant = "body2" }: BalanceProps) => {
        if (hideRowEnd) return null;
        if (setMax && !!balance) {
            return (
                <Text color="secondary" variant={variant} onClick={() => setMax(balance)}>
                    Max
                </Text>
            );
        }
        return null;
    };

    const Balance = ({ variant = "body2", maxText, hideRefresh, helpText, hideInsufficientBalance }: BalanceProps) => {
        const defaultMaxText = escrowNeeded ? COPY.ESCROW_TERM : "Wallet";
        return (
            <Row spacing={0.5}>
                <Text color="caption" variant={variant}>
                    {maxText ?? defaultMaxText}:
                </Text>
                <Text color="caption" variant={variant}>
                    {BsMetaUtil.formatAmount(metadata, skipProps ? 0 : balance ?? 0)}
                </Text>
                {helpText && (
                    <Tooltip title={helpText}>
                        <Text color="disabled" variant={variant}>
                            <Icon type="tooltip" />
                        </Text>
                    </Tooltip>
                )}
                {!hideRefresh && <RefreshBalance variant={variant} />}

                {insufficientBalance && !hideInsufficientBalance && (
                    <TooltipText icon={false} helpText="Insufficient balance" variant={variant}>
                        <Icon sx={{ color: error }} type="warning" />
                    </TooltipText>
                )}
            </Row>
        );
    };

    const BalanceDisplay = (props: BalanceProps) => {
        if (isLoading) return <Row sx={{ height: FONT_SIZES.body1 + "px" }} />;
        if (hideRowEnd) return <Balance {...props} />;
        return (
            <Row spaceBetween>
                <Balance {...props} />

                <RowEnd {...props} />
            </Row>
        );
    };

    const BalanceWrapper = ({ variant = "body2", children }: { variant?: TextVariant; children: ReactNode }) => {
        return (
            <Column spacing={0.5}>
                {children}
                <BalanceDisplay variant={variant} />
            </Column>
        );
    };

    return {
        BalanceDisplay,
        BalanceWrapper,
        balance,
        tokenBalance,
        insufficientBalance,
        escrowNeeded,
        isLoading
    };
}

function RefreshWalletButton({ variant }: { variant: TextVariant }) {
    const { activeWallet } = useActiveWallet();
    const { resetUserAssetsApi } = useAbfFetches();

    const { isFetching } = userAssetsApi.endpoints.tokensByWallet.useQueryState(activeWallet ?? skipToken);

    return <RefreshButtonWithLoading variant={variant} isFetching={isFetching} callback={resetUserAssetsApi} />;
}

function RefreshVaultWithdrawButton({
    vaultAddress,
    variant
}: {
    vaultAddress: string | undefined;
    variant: TextVariant;
}) {
    const { activeWallet } = useActiveWallet();
    const { resetLendingVaultApi, resetLendingVaultPositionsApi } = useAbfFetches();

    const { isFetching } = lendingVaultApi.endpoints.lendingVaultInfos.useQueryState(
        activeWallet && vaultAddress
            ? {
                  vaultAddresses: [vaultAddress],
                  principalMints: [vaultAddress],
                  lpMints: [vaultAddress],
                  depositsEnabled: true,
                  sortType: LendVaultSortType.TotalDeposits,
                  sortDirection: SortDirection.Desc,
                  page: 0,
                  pageSize: 5
              }
            : skipToken
    );

    const callback = () => {
        resetLendingVaultApi();
        resetLendingVaultPositionsApi();
    };

    return <RefreshButtonWithLoading variant={variant} isFetching={isFetching} callback={callback} />;
}

export function TransferActionsList({ close }: { close: () => void }) {
    const { hoverBackground } = useAppPalette();

    const { items } = useTransferItems();

    return (
        <>
            {items.map(({ icon, onClick, header }, i) => (
                <Row
                    sx={{ px: 2, py: 1, cursor: "pointer", ":hover": { background: hoverBackground } }}
                    key={i}
                    spacing={1}
                    onClick={() => {
                        onClick();
                        close();
                    }}
                >
                    <IconWithBackground size={20} variant="caption">
                        {icon}
                    </IconWithBackground>
                    <Text>{header}</Text>
                </Row>
            ))}
        </>
    );
}

export function TransferButtons({
    type,
    includeStakedSol
}: {
    type: keyof ReturnType<typeof useTransferItems>;
    includeStakedSol?: boolean;
}) {
    const { open: openDialog } = useAppDialog();
    const back = () => openDialog(AppDialog.Wallet, undefined);

    const items = useTransferItems({ includeStakedSol, back })[type];
    const { secondary } = useAppPalette();

    const isColumn = items.length > 2;

    return (
        <Row sx={{ gap: 1, flexGrow: 1 }}>
            {items.map(({ icon, onClick, cta }, i) => (
                <Button
                    fullWidth
                    textProps={{
                        variant: isColumn ? "body2" : undefined,
                        sx: {
                            flexDirection: isColumn ? "column" : undefined,
                            svg: { fontSize: isColumn ? FONT_SIZES.h3 + "px" : "inherit", color: secondary }
                        }
                    }}
                    variant="outlined"
                    key={i}
                    height={isColumn ? 60 : undefined}
                    onClick={onClick}
                >
                    {icon}
                    <Span>{cta}</Span>
                </Button>
            ))}
        </Row>
    );
}

type TransferItem = { header: string; cta: string; icon: JSX.Element; onClick: () => void };
function useTransferItems(params?: { includeStakedSol?: boolean; back?: () => void }) {
    const escrowItems: TransferItem[] = [];

    const walletItems: TransferItem[] = [];

    const items = walletItems;
    return { items, walletItems, escrowItems };
}

export function WalletAddressInput({
    value,
    setValue,
    label = "Address"
}: {
    value: string | undefined;
    setValue: DispatchType<string | undefined>;
    label?: string;
}) {
    const isValid = isValidAddress(value);
    return (
        <FormInput
            inputProps={{ "data-1p-ignore": true }}
            InputProps={{
                endAdornment: value ? (
                    <TooltipText
                        sx={{ pl: 0.75 }}
                        helpText={`${isValid ? "Valid" : "Invalid"} Solana address`}
                        icon={false}
                        color={isValid ? "success" : "error"}
                    >
                        <Icon type={isValid ? "accept" : "warning"} />
                    </TooltipText>
                ) : undefined,
                startAdornment: (
                    <Text variant="body2" sx={{ pr: 0.75 }} color="disabled">
                        <SolanaSvg />
                    </Text>
                )
            }}
            label={label}
            placeholder={`Enter address (e.g. A1b2c...)`}
            variant="string"
            value={value}
            setValue={setValue}
        />
    );
}

export function isValidAddress(address: string | undefined) {
    if (!address) return false;
    return isValidPublicKey(address);
}
