import { useEffect, useCallback, useState } from "react";

import { useActiveWallet } from "@bridgesplit/abf-react";
import { ENV_DETAILS } from "@bridgesplit/utils";
import mixpanel from "mixpanel-browser";
import { TrackingInfo } from "@bridgesplit/ui";

import { AppTracking } from "../types";

export function initMixpanel() {
    mixpanel.init(ENV_DETAILS.mixpanelToken, {
        persistence: "localStorage",
        track_pageview: "full-url",
        api_host: "https://analytics.bridgesplit.com/"
    });
}

export function useSetMixpanel() {
    const { activeWallet } = useActiveWallet();
    const [initializedUserId, setInitializedUserId] = useState<string | null>(null);

    useEffect(() => {
        if (activeWallet && activeWallet !== initializedUserId) {
            mixpanel.identify(activeWallet);
            setInitializedUserId(activeWallet);

            if (activeWallet) {
                mixpanel.people.set_once("wallet", activeWallet);
            }
        }
    }, [initializedUserId, activeWallet]);
}

export function useTrack() {
    // passed into UI components to track events
    const rawTrack = useCallback((info: TrackingInfo) => {
        mixpanel.track(info.eventName, info.properties);
    }, []);

    // These functions track events immediately (not on UI element click)
    const track = useCallback(
        (...info: Parameters<typeof AppTracking.from>) => rawTrack(AppTracking.from(...info)),
        [rawTrack]
    );
    const trackWithProps = useCallback(
        (...info: Parameters<typeof AppTracking.fromProps>) => rawTrack(AppTracking.fromProps(...info)),
        [rawTrack]
    );

    const trackTransaction = useCallback(
        (...info: Parameters<typeof AppTracking.fromTransaction>) => rawTrack(AppTracking.fromTransaction(...info)),
        [rawTrack]
    );

    return { rawTrack, track, trackWithProps, trackTransaction };
}
