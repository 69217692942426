import { BsMetaUtil, VaultUpdateActionType } from "@bridgesplit/abf-react";
import { createRows, Row, Table, TextColor, TableColumn, Text } from "@bridgesplit/ui";
import { formatPercent, formatDate } from "@bridgesplit/utils";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { COPY } from "app/constants";

import { CollateralInfo } from "./common";
import { TermsColumn, VaultSummarizedUpdate } from "./type";

interface PendingParamsTableProps {
    changes: VaultSummarizedUpdate[];
    visibleColumns: TermsColumn[];
}

export default function PendingParamsTable({ changes, visibleColumns }: PendingParamsTableProps) {
    return (
        <Table
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: TermsColumn.Finalized
            }}
            emptyPlaceholder="No pending changes"
            loading={!changes}
            rows={createRows(changes, (s) => s.collateralMint)}
            columns={columns.filter((c) => visibleColumns.includes(c.dataIndex))}
        />
    );
}

const columns: (TableColumn<VaultSummarizedUpdate> & { dataIndex: TermsColumn })[] = [
    {
        dataIndex: TermsColumn.Collateral,
        title: COPY.COLLATERAL_TERM,
        render: ({ data }) => <CollateralInfo metadata={data.collateralMetadata} />,
        sortBy: ({ data }) => BsMetaUtil.getSymbolUnique(data.collateralMetadata)
    },
    {
        dataIndex: TermsColumn.Finalized,
        title: COPY.FINALIZED_TERM,
        render: ({ data }) => <>{formatDate(data.executionTimestamp, "relative")}</>,
        sortBy: ({ data }) => data.executionTimestamp
    },
    {
        dataIndex: TermsColumn.Type,
        title: COPY.TYPE_TERM,
        render: ({ data }) => {
            const type = data.type;
            const metadata = TYPE_METADATA[type];
            if (!metadata) return null;
            return <Text color={metadata.color}>{metadata.name}</Text>;
        }
    },
    {
        dataIndex: TermsColumn.Ltv,
        title: COPY.LTV_TERM,
        render: ({ data }) => <LtvDisplay data={data} />
    },
    {
        dataIndex: TermsColumn.LiquidationLtv,
        title: COPY.LIQUIDATION_THRESHOLD_TERM,
        render: ({ data }) => {
            if (!data.ltvInfo) return <Text color="disabled">N/A</Text>;

            if (!data.originalTerms) {
                return <Text>{formatPercent(data.ltvInfo.liquidationThreshold)}</Text>;
            }

            return (
                <Row sx={{ minWidth: "max-content" }} spacing={0.5}>
                    <Text color="caption">
                        {formatPercent(
                            Object.values(data.originalTerms.durationToLtvInfo)[0]?.marketInfo?.liquidationThreshold ??
                                0
                        )}
                    </Text>
                    <Text color="disabled" variant="body2">
                        <ArrowForwardOutlined />
                    </Text>
                    <Text>{formatPercent(data.ltvInfo?.liquidationThreshold)}</Text>
                </Row>
            );
        }
    }
];

const TYPE_METADATA: Record<VaultUpdateActionType, { name: string; color: TextColor }> = {
    [VaultUpdateActionType.UpdateStrategyMarketInformation]: { name: "Update market info", color: "primary" },
    [VaultUpdateActionType.AddCollateral]: { name: "Add collateral", color: "success" },
    [VaultUpdateActionType.UpdateLtv]: { name: "Update LTV", color: "primary" },
    [VaultUpdateActionType.RemoveCollateral]: { name: "Remove collateral", color: "error" },
    [VaultUpdateActionType.UpdateApy]: { name: "Update APY", color: "primary" },
    [VaultUpdateActionType.UpdateStrategySettings]: { name: "Update strategy", color: "primary" },
    [VaultUpdateActionType.UpdateVaultSettings]: { name: "Update vault", color: "primary" }
};

function LtvDisplay({ data }: { data: VaultSummarizedUpdate }) {
    if (!data.ltvInfo) {
        return <Text color="disabled">N/A</Text>;
    }

    if (!data.originalTerms) {
        return <Text>{formatPercent(data.ltvInfo.ltv)}</Text>;
    }

    return (
        <Row sx={{ minWidth: "max-content" }} spacing={0.5}>
            <Text color="caption">
                {formatPercent(Object.values(data.originalTerms.durationToLtvInfo)[0]?.marketInfo?.ltv ?? 0)}
            </Text>
            <Text color="disabled" variant="body2">
                <ArrowForwardOutlined fontSize="small" />
            </Text>
            <Text>{formatPercent(data.ltvInfo?.ltv)}</Text>
        </Row>
    );
}
