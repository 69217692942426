import { Row, Tooltip } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import { BaseApy } from "./BaseApy";
import { ExternalRewardsDisplay, RateDisplay, TooltipContent } from "./common";
import { RewardsDailyPoints } from "./daily-rewards/RewardsDailyPoints";
import { RewardsExternalPointsRewards } from "./external-point-rewards/RewardsExternalPointsReward";
import { RewardsLendVaultApyProps } from "./types";

export function RewardsLendVaultApy(props: RewardsLendVaultApyProps) {
    return (
        <Tooltip title={<RewardsLendVaultTooltip {...props} />} reverseColors>
            <Row spacing={0.5}>
                <ExternalRewardsDisplay {...props} />
                <RateDisplay apy={props.apy} variant="body1" color="body" isFeatured={props.isFeatured} />
            </Row>
        </Tooltip>
    );
}

function RewardsLendVaultTooltip(props: RewardsLendVaultApyProps) {
    return (
        <TooltipContent>
            <BaseApy {...props} />
            <Divider />
            <RewardsDailyPoints {...props} />
            <Divider />
            <RewardsExternalPointsRewards {...props} />
        </TooltipContent>
    );
}
