import { StrategyExpanded } from "@bridgesplit/abf-react";

export function calculateStrategyUtilization(strategyExpanded: StrategyExpanded | undefined) {
    if (!strategyExpanded) return { apyWeighted: 0, utilization: 0, totalDeployed: 0 };
    const { strategy, externalYieldInfo } = strategyExpanded;

    const isStrategyActive = strategy.currentDeployedAmount === 0 ? false : true;

    const utilization =
        strategy.currentDeployedAmount / (strategyExpanded.totalBalance + strategy.currentDeployedAmount);

    let apyWeighted = strategy.fixedApy * strategy.currentDeployedAmount;
    if (externalYieldInfo) {
        apyWeighted += externalYieldInfo.apy * externalYieldInfo.balance;
    }
    apyWeighted = apyWeighted / (strategy.currentDeployedAmount + strategyExpanded.totalBalance);

    if (!isStrategyActive) return { apyWeighted: apyWeighted, utilization: 0, totalDeployed: 0 };

    return { apyWeighted, utilization, totalDeployed: strategy.currentDeployedAmount };
}
