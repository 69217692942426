import { Row, Tooltip } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import { BaseApy } from "./BaseApy";
import { ExternalRewardsDisplay, RateDisplay, TooltipContent } from "./common";
import { RewardsDailyPoints } from "./daily-rewards/RewardsDailyPoints";
import { RewardsLoopsApyProps } from "./types";
import { RewardsExternalPointsRewards } from "./external-point-rewards/RewardsExternalPointsReward";
import { RewardsMaxLeverage } from "./loops/RewardsMaxLeverage";
import { RewardsDailyPointsBreakdown } from "./daily-rewards/RewardsDailyPointsBreakdown";

export function RewardsLoopsApy(props: RewardsLoopsApyProps) {
    return (
        <Tooltip title={<RewardsLoopsTooltip {...props} />} reverseColors>
            <Row spacing={0.5}>
                <ExternalRewardsDisplay {...props} />
                <RateDisplay apy={props.apy} variant="body1" color="body" isFeatured={props.isFeatured} />
            </Row>
        </Tooltip>
    );
}

function RewardsLoopsTooltip(props: RewardsLoopsApyProps) {
    return (
        <TooltipContent>
            <BaseApy {...props} isMaxApy={true} />
            <RewardsMaxLeverage {...props} />
            <Divider />
            {props.loopAmount ? <RewardsDailyPoints {...props} /> : <RewardsNoRouteFound {...props} />}
            {props.externalPointRewards?.length && (
                <>
                    <Divider />
                    <RewardsExternalPointsRewards {...props} />
                </>
            )}
        </TooltipContent>
    );
}

function RewardsNoRouteFound(props: RewardsLoopsApyProps) {
    return <RewardsDailyPointsBreakdown {...props} />;
}
