import {
    CollateralWithTerms,
    serializeStrategyDuration,
    useStrategyDurations,
    isStrategyDurationSame,
    useUpdateStrategyTermsTransaction,
    deserializeStrategyDurationOrUndefined,
    useGlobalBestOffers,
    StrategyExpanded
} from "@bridgesplit/abf-react";
import { useMemoizedGroupedMap } from "@bridgesplit/ui";
import { decimalsToBps, filterTruthy, findMinElement } from "@bridgesplit/utils";
import { useTransactionSender } from "app/components/transactions";
import { AddCollateralArgs, EditCustomStrategyTxnParams, RemoveCollateralArgs } from "@bridgesplit/abf-sdk";

import { TermsForStrategy } from "./type";

export function useUpdateStrategyCollateral() {
    const editStrategy = useUpdateStrategyTermsTransaction();
    const send = useTransactionSender();

    return async (strategyExpanded: StrategyExpanded, collateral: CollateralWithTerms[]) => {
        const addCollateral: Record<string, AddCollateralArgs> = {};
        const removeCollateral: Record<string, RemoveCollateralArgs> = {};

        const newCollateralMints = new Set<string>(collateral.map((c) => c.metadata.mint));

        // Grab first, since we just need one duration and APY
        const baseDurationsAndApys = strategyExpanded.collateral[0]?.durationAndApys ?? {};
        const durationSerializedToMinApy = Object.entries(baseDurationsAndApys);

        // add new collateral with apys from old terms
        for (const {
            metadata: { mint }
        } of collateral) {
            // Create a map of existing collateral mints for faster lookup
            const existingCollateralMints = new Set(strategyExpanded.collateral.map((c) => c.metadata.mint));

            // skip if collateral was already in strategy
            if (existingCollateralMints.has(mint)) continue;

            const durationsAndApys: Record<string, string> = {};

            for (const [strategyDurationSerialized, minApy] of durationSerializedToMinApy) {
                const strategyDuration = deserializeStrategyDurationOrUndefined(strategyDurationSerialized);
                if (!strategyDuration) continue;
                durationsAndApys[strategyDurationSerialized] = decimalsToBps(minApy).toString();
            }

            if (Object.keys(durationsAndApys).length > 0) {
                addCollateral[mint] = { durationsAndApys };
            }
        }

        // remove collateral + duration pairs based on previous
        for (const collateralItem of strategyExpanded.collateral) {
            const collateralMint = collateralItem.metadata.mint;
            if (!newCollateralMints.has(collateralMint)) {
                removeCollateral[collateralMint] = {
                    durations: Object.keys(collateralItem.durationAndApys)
                        .map(deserializeStrategyDurationOrUndefined)
                        .filter(filterTruthy),
                    removeFromMarketInformation: false
                };
            }
        }

        const params: EditCustomStrategyTxnParams = {
            strategy: strategyExpanded.strategy.address,
            collateralTerms: {
                addCollateral,
                removeCollateral,
                updateCollateral: {}
            }
        };

        return await send(editStrategy, params);
    };
}
export function useStrategyTerms(strategy: StrategyExpanded | undefined, collateralMints: string[] | undefined) {
    const { strategyDurations } = useStrategyDurations();

    const bestQuotes = useGlobalBestOffers({
        collateralMints,
        principalMint: strategy?.principalMetadata.mint,
        strategyDurations,
        showSelf: true
    });

    const strategyDurationToApy = useMemoizedGroupedMap(
        strategy?.collateral.flatMap((col) =>
            Object.entries(col.durationAndApys).map(([duration, apy]) => [duration, apy] as [string, number])
        ) ?? [],
        ([duration]) => duration
    );

    if (!strategyDurationToApy || !bestQuotes) return undefined;

    const terms = strategyDurations?.map((strategyDuration): TermsForStrategy => {
        const key = serializeStrategyDuration(strategyDuration);
        const apy = strategyDurationToApy.get(key)?.map(([_, apy]) => apy);

        const minApy = apy?.length ? Math.min(...apy) : undefined;

        const allMatchingQuotes = bestQuotes.filter((q) => isStrategyDurationSame(q, strategyDuration));
        const bestQuote = findMinElement(allMatchingQuotes, (q) => q.apy);

        return {
            key,
            strategyDuration,
            bestQuote,
            minApy
        };
    });

    return terms;
}
