import { useEffect } from "react";

import { mutationIntoRawResult, useLocalStorage } from "@bridgesplit/react";
import { Result, removeDuplicates } from "@bridgesplit/utils";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";

import { useOnboardingQuery, useCompleteOnboardingMutation, SdkRootState } from "../reducers";
import { OnboardingStep } from "../types";

export interface AddWalletParams {
    isMpc: boolean;
    wallet?: string;
    signedTxn?: string;
    message?: string;
    dfnsId?: string;
}

const USER_ONBOARDING_CACHE_KEY = "UserOnboardingCache";
function useCachedOnboardingSteps() {
    const [cachedSteps, setCachedSteps] = useLocalStorage<OnboardingStep[]>(USER_ONBOARDING_CACHE_KEY, []);
    return { cachedSteps, setCachedSteps };
}

// only call local storage if key is'nt cached
export function useUserOnboardingStep(stepsToCheck: OnboardingStep[]) {
    const { cachedSteps, setCachedSteps } = useCachedOnboardingSteps();

    const isMutating = useSelector((state: SdkRootState) =>
        Object.values(state.onboardingApi.mutations).some((m) => m?.status === QueryStatus.pending)
    );

    const isCachedComplete = stepsToCheck.every((step) => cachedSteps.includes(step));
    const {
        data: apiSteps,
        isFetching,
        isLoading: queryLoading
    } = useOnboardingQuery(undefined, {
        skip: isCachedComplete
    });

    const isLoading = isFetching || isMutating || queryLoading || !apiSteps || queryLoading;
    const steps = apiSteps ?? cachedSteps;
    const isComplete = isLoading || stepsToCheck.every((step) => steps.includes(step));

    useEffect(() => {
        if (!apiSteps || apiSteps.length >= cachedSteps.length) return;
        setCachedSteps(removeDuplicates([...cachedSteps, ...apiSteps]));
    }, [apiSteps, cachedSteps, setCachedSteps]);

    return { steps, isComplete, isLoading };
}

export function useMarkOnboardingStepComplete() {
    const { cachedSteps, setCachedSteps } = useCachedOnboardingSteps();

    const [completeMutation] = useCompleteOnboardingMutation();

    async function markAsComplete(stepsToCheck: OnboardingStep[]) {
        setCachedSteps(removeDuplicates([...cachedSteps, ...stepsToCheck]));

        const results = await Promise.all(stepsToCheck.map((step) => mutationIntoRawResult(completeMutation, step)));

        return Result.combine(results);
    }
    return { markAsComplete };
}
