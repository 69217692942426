import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { UserBetaAccessResponse } from "../types";
import { GET_USER_BETA_ACCESS_ROUTE } from "../constants";
import { marketsBaseQuery } from "./util";

const USER_ME_TAG = "UserMe";
const USER_VIEW_TAG = "UserGroupViews";
const USER_MARGINFI_TAG = "UserMarginFi";

export const abfUserApi = createApi({
    reducerPath: "abfUserApi",
    tagTypes: [USER_ME_TAG, USER_VIEW_TAG, USER_MARGINFI_TAG],
    baseQuery: marketsBaseQuery,
    keepUnusedDataFor: 0, // force there to only ever be a userMe call in the cache
    endpoints: (builder) => ({
        userBetaAccess: builder.query<UserBetaAccessResponse, void>({
            query() {
                return {
                    url: GET_USER_BETA_ACCESS_ROUTE,
                    method: "GET"
                };
            }
        })
    })
});

export const { useUserBetaAccessQuery } = abfUserApi;

export const useAbfUserApi = () => {
    const dispatch = useDispatch();
    // refetch data

    return {
        resetMeApi: () => dispatch(abfUserApi.util.invalidateTags([USER_ME_TAG, USER_VIEW_TAG, USER_MARGINFI_TAG])),
        resetMarginFiApi: () => dispatch(abfUserApi.util.invalidateTags([USER_MARGINFI_TAG]))
    };
};
