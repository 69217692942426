import { BsMetaUtil, LendingStrategy } from "@bridgesplit/abf-react";
import { IconButton, Row, Text, Image } from "@bridgesplit/ui";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { STRATEGY_LOANS_SLUG } from "app/constants";

export function getStrategyLoansPath(strategy: LendingStrategy | undefined) {
    if (strategy) {
        return `${STRATEGY_LOANS_SLUG}/${strategy.address}`;
    }
    return "#";
}

export function SelectedCollateralToken({ metadata, remove }: { metadata: TokenListMetadata; remove: () => void }) {
    return (
        <Row spacing={0.5} sx={{ width: "max-content" }}>
            <Row spacing={1}>
                <Image variant="circle" size="16px" src={BsMetaUtil.getImage(metadata)} />
                <Text> {BsMetaUtil.getSymbol(metadata)} </Text>
            </Row>

            <IconButton
                onClick={remove}
                textColor="disabled"
                color="error"
                background="hover"
                border={false}
                textVariant="body2"
                icon="reject"
            />
        </Row>
    );
}
