import { MISSING_PARAM_ERROR, Result, SortDirection, doNothing } from "@bridgesplit/utils";
import {
    LoanFilterType,
    LoanInfoSorting,
    StrategyExpanded,
    marketsApi,
    useActiveWallet,
    useEditStrategySettings
} from "@bridgesplit/abf-react";

import { useTransactionSender } from "../../transactions";
import { StrategySettingsForm } from "./types";

const description = "Updating settings";

export function useRefreshStrategyAsync() {
    const [refetchStrategies] = marketsApi.endpoints.lendingStrategyInfos.useLazyQuery();
    const [refetchLoans] = marketsApi.endpoints.loanInfos.useLazyQuery();
    const { activeWallet } = useActiveWallet();

    return async (strategy: StrategyExpanded) => {
        await Promise.all([
            refetchStrategies({ userAddress: activeWallet ?? undefined }),
            refetchLoans({
                lenders: [activeWallet ?? ""],
                loanAddresses: [],
                borrowers: [],
                principalMints: [strategy.strategy.principalMint],
                sortSide: SortDirection.Asc,
                sortType: LoanInfoSorting.NextPayment,
                orderFundingTypes: [],
                filterType: LoanFilterType.Active
            })
        ]);
    };
}

export function usePutStrategySettings() {
    const put = usePutStrategySettingsWithoutRefresh();
    const refresh = useRefreshStrategyAsync();

    return async (strategy: StrategyExpanded, form: StrategySettingsForm) => {
        const res = await put(strategy, form);
        if (res.isOk()) {
            await refresh(strategy);
        }
        return res;
    };
}

function usePutStrategySettingsWithoutRefresh() {
    const send = useTransactionSender();
    const editSettings = useEditStrategySettings();

    return async (strategy: StrategyExpanded, form: StrategySettingsForm) => {
        const { allowNewOffers } = form;
        if (!strategy) {
            return Result.errFromMessage(MISSING_PARAM_ERROR);
        }

        const editOriginationCap = allowNewOffers !== strategy.strategy.originationsEnabled;
        const editYieldSource = form.yieldSource !== strategy.strategy.externalYieldSource;
        const editEscrow = editOriginationCap || editYieldSource;

        if (editEscrow) {
            return await send(
                editSettings,
                {
                    strategyAddress: strategy.strategy.address,
                    originationsEnabled: allowNewOffers,
                    externalYieldSource: form.yieldSource
                },
                { description, sendOptions: { refetch: doNothing } }
            );
        }

        return Result.ok();
    };
}
