import { Icon, StatColumn, StatProps, useTextColorMap } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";

import { CaptionWithIcon } from "./common";

export function BaseApy({ apy, isMaxApy }: { apy: number | undefined; isMaxApy?: boolean }) {
    const textColorMap = useTextColorMap();
    const stats: StatProps[] = [
        {
            value: formatPercent(apy),
            caption: (
                <CaptionWithIcon
                    caption={isMaxApy ? "Max APY" : "Base APY"}
                    iconColor={textColorMap("caption")}
                    icon={<Icon type="base-apy" />}
                />
            )
        }
    ];
    return <StatColumn stats={stats} captionVariant="body1" />;
}
