import { useMemo } from "react";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppCookie, clearBearerToken, DEBUG_WALLET } from "@bridgesplit/react";
import { generateNonce } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSelector } from "react-redux";

import { ABF_GROUP_COOKIE_PREFIX, AUTH0_COOKIES_PREFIX, USER_WALLET_COOKIE } from "../constants";

export type AuthSliceState = {
    apiVersionUpdateNeeded: boolean;
};

const initialState: AuthSliceState = {
    apiVersionUpdateNeeded: false
};
const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        resetAuthState: () => {
            return {
                idToken: undefined,
                skipAuth0: false,
                authError: null,
                apiVersionUpdateNeeded: false,
                idempotentKey: generateNonce()
            };
        },
        updateApiVersionNeeded: (state, action: PayloadAction<boolean>) => {
            state.apiVersionUpdateNeeded = action.payload;
        }
    }
});

export default authSlice.reducer;
export const { updateApiVersionNeeded } = authSlice.actions;

const getSlice = (state: { authSlice: AuthSliceState }): AuthSliceState => state.authSlice;

/**
 * Set values in cookies and reducer
 * Cookie storage allows credentials to be used in authenticated RTK queries
 * Reducer storage allows credentials to be used to trigger hook re-renders
 */
export function useAuthStorage() {
    const state: AuthSliceState = useSelector(getSlice);
    function resetCookies() {
        clearBearerToken(AUTH0_COOKIES_PREFIX);
        clearBearerToken(ABF_GROUP_COOKIE_PREFIX);
        AppCookie.remove(USER_WALLET_COOKIE);
    }

    return {
        state,
        resetCookies
    };
}

export function useAuth() {
    const wallet = useWallet();

    const isWalletBased = wallet.connected || DEBUG_WALLET;
    const isAuthenticated = isWalletBased;

    const isLoading = useMemo(() => {
        return wallet.connecting;
    }, [wallet.connecting]);

    return { isAuthenticated, isWalletBased, isLoading };
}
