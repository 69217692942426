import { Button, Row } from "@bridgesplit/ui";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";
import { TrackEventWithProps } from "app/types";
import { useTrack } from "app/hooks";
import { RoleView } from "@bridgesplit/abf-react";
import { AppButton } from "app/components/common";

const WIDTH = "120px";
export default function MarketDetailCta({
    token,
    view,
    isMobile
}: {
    token: TokenListMetadata | undefined;
    view: RoleView;
    isMobile?: boolean;
}) {
    const { open } = useAppDialog();
    const { trackWithProps } = useTrack();
    const width = isMobile ? undefined : WIDTH;
    return (
        <Row spacing={1}>
            {view === RoleView.Borrow && (
                <AppButton
                    disabled={!token}
                    width={width}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!token) return;
                        trackWithProps(TrackEventWithProps.OpenBorrowModal, {
                            marketSymbol: token.symbol
                        });
                        open(AppDialog.Borrow, { token });
                    }}
                    fullWidth
                    isTransaction={false}
                    variant="default"
                    overrideAccess
                >
                    Borrow
                </AppButton>
            )}

            {/* Temporarily hide lend button */}

            {/* {view === RoleView.Lend && (
                <AppButton
                    disabled={!token}
                    width={width}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!token) return;
                        trackWithProps(TrackEventWithProps.OpenLendModal, {
                            marketSymbol: token.symbol
                        });
                        open(AppDialog.Lend, { token });
                    }}
                    fullWidth
                    isTransaction={false}
                    variant="default"
                    overrideAccess
                >
                    Lend
                </AppButton>
            )} */}
            <Button disabled={!token} fullWidth width={width} variant="outlined">
                View market
            </Button>
        </Row>
    );
}
