import { useRewardsPointsPerSecond, useLoanMultipliersUtil, BsMetaUtil } from "@bridgesplit/abf-react";
import { StatProps, StatColumn, Icon, useAppPalette } from "@bridgesplit/ui";
import { formatMultiplier } from "@bridgesplit/utils";
import { BoltOutlined } from "@mui/icons-material";

import { CaptionWithIcon } from "../common";
import { RewardsDailyPointsProps } from "./RewardsDailyPoints";

export function RewardsDailyPointsBreakdown({ loopscalePointsAction, metadata }: RewardsDailyPointsProps) {
    const { rewardPointsPerSecond, isLoading: isRelativePointsLoading } = useRewardsPointsPerSecond();
    const { loanMultipliers, isLoading: isLoadingLoanMultipliers } = useLoanMultipliersUtil();
    const { prime, secondary } = useAppPalette();

    const isLoading = isRelativePointsLoading || isLoadingLoanMultipliers;
    const mint = metadata?.mint;

    const relativePointsPerSecond = rewardPointsPerSecond?.[loopscalePointsAction]?.relativePointsPerSecond;
    const rewardLabel = rewardPointsPerSecond?.[loopscalePointsAction]?.rewardLabel;
    const loanMultiplier = mint ? loanMultipliers?.[mint]?.[loopscalePointsAction]?.multiplier : undefined;

    const stats: StatProps[] = [
        {
            caption: <CaptionWithIcon icon={<Icon type="points" />} iconColor={secondary} caption={rewardLabel} />,
            value: formatMultiplier(relativePointsPerSecond)
        },
        {
            caption: (
                <CaptionWithIcon
                    icon={<BoltOutlined />}
                    iconColor={prime}
                    caption={`${BsMetaUtil.getSymbol(metadata)} boost`}
                />
            ),
            value: formatMultiplier(loanMultiplier),
            hide: !loanMultiplier
        }
    ].filter((stat) => !stat.hide);

    return <StatColumn stats={stats} loading={isLoading} spacing={1} />;
}
