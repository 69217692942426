import { useMemo, useState } from "react";

import { Column, Icon, MEDIA, PriceLineChartWithAxis, Row, Text, ToggleSpacedButtons } from "@bridgesplit/ui";
import { RatesComparisonPeriodSelect, useRatesComparisonPeriodSelect } from "app/components/common";
import {
    bpsToUiDecimals,
    formatDate,
    formatPercent,
    formatStatNum,
    getUnixTs,
    lamportsToUiAmount,
    TIME
} from "@bridgesplit/utils";
import { BsMetaUtil, useLendingVaultHistoryQuery } from "@bridgesplit/abf-react";
import { useMediaQuery } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useVaultContext } from "../../VaultContext";

enum Open {
    Apy = "APY",
    Deployed = "Deployed",
    Volume = "Volume"
}

type Data = {
    date: number;
    apy: number;
    deployed: number;
    volume: number;
};
const DATA: Data[] = [
    {
        date: getUnixTs(),
        apy: 0.15,
        deployed: 3399,
        volume: 500
    },
    {
        date: getUnixTs() + TIME.DAY,
        apy: 0.13,
        deployed: 10000,
        volume: 10000
    }
];

const OPEN_TO_FORMAT: Record<Open, (value: number) => string> = {
    [Open.Apy]: formatPercent,
    [Open.Deployed]: formatStatNum,
    [Open.Volume]: formatStatNum
};

const OPEN_TO_KEY: Record<Open, keyof Data> = {
    [Open.Apy]: "apy",
    [Open.Deployed]: "deployed",
    [Open.Volume]: "volume"
};

export default function VaultCharts() {
    const { vaultAddress, vaultExpanded } = useVaultContext();
    const periodController = useRatesComparisonPeriodSelect("VaultChartsPeriod");
    const { data: history } = useLendingVaultHistoryQuery(
        vaultAddress ? { vaultAddress, timeLookback: periodController.period } : skipToken,
        { skip: !vaultAddress }
    );
    const [open, setOpen] = useState<Open>(Open.Apy);

    const data = useMemo(() => {
        const decimals = vaultExpanded?.principalMetadata.decimals;
        if (!history?.length) {
            return DATA;
        }
        return history?.map(
            (h): Data => ({
                date: h.timestamp,
                apy: bpsToUiDecimals(h.wAvgApy),
                deployed: lamportsToUiAmount(h.principalDeployed, decimals),
                volume: lamportsToUiAmount(h.cumulativeLoanVolume, decimals)
            })
        );
    }, [history, vaultExpanded]);

    const isMobile = useMediaQuery(MEDIA.MD.below);

    return (
        <>
            <Row spaceBetween>
                <ToggleSpacedButtons
                    size="small"
                    value={open}
                    setValue={setOpen}
                    options={Object.values(Open).map((o) => ({ value: o }))}
                />
                <RatesComparisonPeriodSelect {...periodController} />
            </Row>

            <PriceLineChartWithAxis
                keys={[{ key: OPEN_TO_KEY[open] }]}
                height={200}
                formatYAxis={OPEN_TO_FORMAT[open]}
                formatXAxis={(seconds) => formatDate(seconds, "date", { showTime: "never" })}
                aspectRatio={isMobile ? 2 : 3}
                data={data}
                tooltip={(point) => <Tooltip data={point} open={open} />}
            />
        </>
    );
}

function Tooltip({ data, open }: { data: Data | undefined; open: Open }) {
    const { vaultExpanded } = useVaultContext();

    const formattedValue = useMemo(() => {
        if (!data) return null;
        if (open === Open.Apy) return `${formatPercent(data.apy)} APY`;
        if (open === Open.Deployed) return BsMetaUtil.formatAmount(vaultExpanded?.principalMetadata, data.deployed);
        if (open === Open.Volume) return BsMetaUtil.formatAmount(vaultExpanded?.principalMetadata, data.volume);
    }, [data, open, vaultExpanded]);

    return (
        <Column spacing={0.5}>
            <Text>{formattedValue}</Text>
            <Text variant="body2" color="caption">
                <Icon type="date" /> {formatDate(data?.date)}
            </Text>
        </Column>
    );
}
