import { useState } from "react";

import { Row, Column, Text } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import { SettingsChange, TermsColumn, VaultSummarizedUpdate } from "./type";
import { TableFilter } from "./common";
import { SettingsChangeStats } from "./SettingsChangeStats";
import PendingParamsTable from "./PendingParamsTable";

const DEFAULT_COLUMNS = [TermsColumn.Collateral, TermsColumn.Type, TermsColumn.Ltv, TermsColumn.LiquidationLtv];

const ALL_COLUMNS = [
    TermsColumn.Collateral,
    TermsColumn.Finalized,
    TermsColumn.Type,
    TermsColumn.Ltv,
    TermsColumn.LiquidationLtv
];

const REQUIRED_COLUMNS = [TermsColumn.Collateral];
const MAX_COLUMNS = 4;

export default function PendingChanges({
    changes,
    settingsChanges
}: {
    changes?: VaultSummarizedUpdate[];
    settingsChanges?: { executionTimestamp: number; changes: SettingsChange }[];
}) {
    const [visibleColumns, setVisibleColumns] = useState<TermsColumn[]>(DEFAULT_COLUMNS);

    return (
        <Column spacing={2}>
            {settingsChanges && Object.keys(settingsChanges).length > 0 && (
                <SettingsChangeStats settingsChanges={settingsChanges} />
            )}
            {changes && changes.length > 0 && (
                <Column spacing={1}>
                    <Row spaceBetween>
                        <Text variant="h4">Collateral changes</Text>
                        <TableFilter
                            allColumns={ALL_COLUMNS}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={setVisibleColumns}
                            resetColumns={DEFAULT_COLUMNS}
                            requiredColumns={REQUIRED_COLUMNS}
                            maxColumns={MAX_COLUMNS}
                        />
                    </Row>
                    <Divider />
                    <PendingParamsTable changes={changes} visibleColumns={visibleColumns} />
                </Column>
            )}
        </Column>
    );
}
