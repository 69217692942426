import { useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    formatTokens,
    getLoanCompletedDate,
    getLoanDefaultedDate,
    getLoanOrcaCollateral,
    getNextPaymentDate,
    isLoanActive,
    isLoanOracleBased,
    LoanStatus,
    LoopscalePointsAction,
    summarizeLedgerAccount,
    useActiveWallet,
    isLoanBorrower,
    getZcLedger,
    getZcLoanCollateral,
    getZcCollateral,
    getLoanName,
    isLoanInRefinanceGrace,
    getClaimableWhirlpoolFees,
    isLoanComplete,
    getZcPastLedger,
    useIsLoanLender,
    usePortfolioRewardsUtils,
    usePortfolioRewardsByLoans
} from "@bridgesplit/abf-react";
import {
    Row,
    ShadowCard,
    Column,
    MediaQuery,
    Tooltip,
    Text,
    StatProps,
    OverlappingImages,
    ToggleChevron
} from "@bridgesplit/ui";
import { bsMath, formatDate, formatPercent, formatUsd } from "@bridgesplit/utils";
import { Collapse, Divider, useMediaQuery } from "@mui/material";
import { calculateActiveLoanDailyPoints } from "app/components/portfolio/stats";

import {
    ClaimWhirlpoolFeesButton,
    LoanHealthTag,
    LoanHealthText,
    LoanImages,
    parseOrcaTicks,
    PositionCardStats,
    PriceRangeStatus,
    TokenImage
} from "../common";
import { LoanActions } from "../loan";
import { RewardsPortfolioApy } from "../points";

type Props = {
    loan: AbfLoanExpanded | undefined;
    query: MediaQuery;
};
export default function LoanCard(props: Props) {
    const isMobile = useMediaQuery(props.query.below);

    if (isMobile) {
        return <Mobile {...props} />;
    }
    return <Desktop {...props} />;
}

function Desktop(props: Props) {
    const [expanded, setExpanded] = useState(false);
    const { loan, query } = props;

    const toggleOnClick = loan ? () => setExpanded((prev) => !prev) : undefined;

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding={false}>
            <Row sx={{ p: 2, cursor: "pointer" }} onClick={toggleOnClick} spaceBetween>
                <LoanHeader {...props} />
                <Row spacing={2}>
                    <LoanStatusDisplay loan={loan} />
                    <ToggleChevron disabled={!loan} textVariant="h2" expanded={expanded} />
                </Row>
            </Row>

            <Collapse in={expanded}>
                <Column sx={{ p: 2, pt: 0 }} spacing={2}>
                    <Expanded isMobile={false} query={query} loan={loan} />
                </Column>
            </Collapse>
        </ShadowCard>
    );
}

function Mobile(props: Props) {
    const { loan, query } = props;

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding spacing={2}>
            <Row spacing={1}>
                <LoanTitle loan={loan} />
            </Row>

            <Expanded isMobile query={query} loan={loan} />
        </ShadowCard>
    );
}

function LoanHeader(props: Props) {
    const { loan } = props;

    const isActive = isLoanActive(loan);
    const firstLedger = getZcLedger(loan);

    return (
        <Row spacing={1}>
            {isActive ? (
                <>
                    <LoanPrincipal principal={firstLedger?.ledgerDebt.total} loan={loan} />
                    <Divider flexItem orientation="vertical" />
                    <LoanCollateral loan={loan} />

                    <Divider flexItem orientation="vertical" />
                    {loan && !isLoanInRefinanceGrace(loan) && <LoanHeaderApy loan={loan} />}
                </>
            ) : (
                <LoanTitle loan={loan} />
            )}
        </Row>
    );
}

function LoanStatusDisplay({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const isActive = isLoanActive(loan);
    const { formatted } = useDueDetails(loan);

    if (!loan) return null;

    return (
        <Row>
            {isActive && <LoanHealthTag variant="body2" loan={loan} />}
            {formatted && <Text color="caption">{formatted}</Text>}
        </Row>
    );
}

function LoanTitle({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const isActive = isLoanActive(loan);

    return (
        <Row spacing={1}>
            <LoanImages size="md" loanExpanded={loan} />
            <Text loadingWidth="200px" loading={!loan}>
                {getLoanName(loan)}
            </Text>
            {isActive && loan && <LoanHeaderApy loan={loan} />}
        </Row>
    );
}

function LoanPrincipal({ loan, principal }: { loan: AbfLoanExpanded | undefined; principal: number | undefined }) {
    return (
        <Row spacing={1}>
            <TokenImage size="md" metadata={loan?.principalMetadata} />
            <Row spacing={0.5}>
                <Text loadingWidth="200px" loading={!loan}>
                    {BsMetaUtil.formatAmount(loan?.principalMetadata, principal, { hideSymbol: true })}
                </Text>
                <Text loading={!loan} color="caption">
                    {formatUsd(bsMath.mul(principal, loan?.principalUsdPrice))}
                </Text>
            </Row>
        </Row>
    );
}

function LoanCollateral({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const collateral = getZcLoanCollateral(loan);
    const images = collateral ? [BsMetaUtil.getImage(collateral.loanCollateral.metadata)] : undefined;
    const usdValue = collateral?.loanCollateral.usdPrice
        ? collateral.loanCollateral.usdPrice * collateral.loanCollateral.amount
        : 0;

    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.loanCollateral.whirlpoolPosition;
    const firstCollateral = getZcCollateral(loan);

    return (
        <Row spacing={1}>
            {whirlpoolPosition ? (
                <Tooltip sx={{ gap: 0.5 }} title={`whirpool`}>
                    <OverlappingImages size="16px" images={images} />
                </Tooltip>
            ) : (
                <OverlappingImages size="16px" images={images} />
            )}
            <Row spacing={0.5}>
                <Text loadingWidth="200px" loading={!loan}>
                    {formatTokens(
                        firstCollateral
                            ? [
                                  {
                                      amount: firstCollateral.loanCollateral.amount,
                                      metadata: firstCollateral.loanCollateral.metadata
                                  }
                              ]
                            : undefined,
                        { hideSymbol: true, condensed: true }
                    )}
                </Text>
                {!!usdValue && (
                    <Text loading={!loan} color="caption">
                        {formatUsd(usdValue)}
                    </Text>
                )}
            </Row>
            <OrcaStatus loan={loan} />
        </Row>
    );
}

function useDueDetails(loan: AbfLoanExpanded | undefined): { stat: StatProps; formatted?: string } {
    const pastDetails = useMemo(() => {
        switch (loan?.status) {
            case LoanStatus.RepaidLoan:
                return { caption: "Repaid", date: getLoanCompletedDate(loan) };
            case LoanStatus.DefaultedLoan:
                return { caption: "Defaulted", date: getLoanDefaultedDate(loan) };
            default:
                return null;
        }
    }, [loan]);

    if (pastDetails) {
        return {
            stat: { caption: pastDetails.caption, value: formatDate(pastDetails.date, "relative") },
            formatted: `${pastDetails.caption} ${formatDate(pastDetails.date, "relative").toLocaleLowerCase()}`
        };
    }

    return {
        stat: {
            caption: "Rate fixed",
            value: formatDate(getNextPaymentDate(loan), "relative").replace("In", "For"),
            tooltip: `Your APY will update to market rate on ${formatDate(getNextPaymentDate(loan))}`
        }
    };
}
function Expanded({
    loan,
    query,
    isMobile
}: {
    loan: AbfLoanExpanded | undefined;
    query: MediaQuery;
    isMobile: boolean;
}) {
    return (
        <>
            <Stats loan={loan} query={query} />
            <LoanActions linkToDetailCta="Loan details" loanExpanded={loan} isCondensed={isMobile} />
        </>
    );
}

function Stats({ loan, query }: { loan: AbfLoanExpanded | undefined; query: MediaQuery }) {
    const isActive = isLoanActive(loan);
    const { activeWallet } = useActiveWallet();

    const { stat: dueStat } = useDueDetails(loan);
    const isMobile = useMediaQuery(query.below);

    // const { proceeds: totalLenderSaleProceeds } = getTotalLenderSaleProceeds(loan);
    // const { data: bid } = useLoanBidAccount(loan);

    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.loanCollateral.whirlpoolPosition;

    const claimableFeesUsd = getClaimableWhirlpoolFees(whirlpoolPosition);
    const isLender = useIsLoanLender(loan, activeWallet);

    const firstLedger = getZcLedger(loan);
    const loanCompleted = isLoanComplete(loan);
    const relevantLedger = loanCompleted ? getZcPastLedger(loan) : firstLedger;
    const ledgerStats = summarizeLedgerAccount(relevantLedger);

    const isBorrower = isLoanBorrower(loan, activeWallet);

    const hideClaimableYield = claimableFeesUsd === 0 || !isBorrower;

    return (
        <PositionCardStats
            loading={!loan}
            stats={[
                {
                    caption: "Total debt",
                    value: <LoanPrincipal principal={firstLedger?.ledgerDebt.total} loan={loan} />,
                    hide: !isActive || !isMobile
                },
                {
                    caption: "Borrowed",
                    value: isMobile ? (
                        <LoanPrincipal principal={firstLedger?.principalDue} loan={loan} />
                    ) : (
                        BsMetaUtil.formatAmount(loan?.principalMetadata, relevantLedger?.principalDue)
                    )
                },
                {
                    caption: "Interest accrued",
                    value: BsMetaUtil.formatAmount(loan?.principalMetadata, firstLedger?.ledgerDebt.interestAccrued),
                    hide: isMobile || !isActive
                },
                { caption: "Collateral", value: <LoanCollateral loan={loan} />, hide: !isMobile },
                { caption: "APY", value: formatPercent(loan?.borrowerWAvgApy), hide: !isMobile },
                {
                    caption: "Health",
                    value: <LoanHealthText loan={loan} />,
                    hide: !isMobile || !isActive || !isLoanOracleBased(loan)
                },
                {
                    caption: "Amount Repaid",
                    value: BsMetaUtil.formatAmount(loan?.principalMetadata, ledgerStats?.totalPaid),
                    hide: isMobile
                },
                // {
                //     caption: "Withdrawn",
                //     value: BsMetaUtil.formatAmount(loan?.principalMetadata, totalLenderSaleProceeds),
                //     hide: !totalLenderSaleProceeds || loan?.status !== LoanStatus.SoldLoanSeller
                // },
                dueStat,
                {
                    caption: "Position balances",
                    value: formatTokens(whirlpoolPosition ? [whirlpoolPosition.tokenA, whirlpoolPosition.tokenB] : [], {
                        showAll: true
                    }),
                    hide: !whirlpoolPosition || isLender
                },
                {
                    caption: "Pending yield",
                    value: whirlpoolPosition ? (
                        <ClaimWhirlpoolFeesButton
                            claimableFeesUsd={claimableFeesUsd}
                            whirlpoolPosition={whirlpoolPosition}
                            loanExpanded={loan}
                        />
                    ) : undefined,
                    hide: hideClaimableYield
                }
            ]}
        />
    );
}

function OrcaStatus({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const { activeWallet } = useActiveWallet();
    const isLender = useIsLoanLender(loan, activeWallet);
    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.loanCollateral.whirlpoolPosition;
    const ticks = whirlpoolPosition ? parseOrcaTicks(whirlpoolPosition) : undefined;

    return !isLender ? (
        <PriceRangeStatus
            iconOnly
            tickCurrentIndex={ticks?.currentTick}
            tickLower={ticks?.tickLower}
            tickUpper={ticks?.tickUpper}
        />
    ) : null;
}

function LoanHeaderApy({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const { user } = useActiveWallet();
    const isBorrower = isLoanBorrower(loan, user);
    const loanAddress = loan?.loan.address;

    const { getLoanRewards } = usePortfolioRewardsUtils();
    // If viewing as a lender, this is a very expensive call given the current BE implementation
    const { data: portfolioRewards } = usePortfolioRewardsByLoans(isBorrower ? undefined : [loanAddress ?? ""]);

    const ledgerReward = isBorrower ? getLoanRewards(loanAddress) : portfolioRewards?.loans?.[loanAddress ?? ""];

    const customDailyPoints = calculateActiveLoanDailyPoints(ledgerReward, isBorrower);

    if (isBorrower) {
        const collateral = getZcCollateral(loan);

        return (
            <RewardsPortfolioApy
                loopscalePointsAction={LoopscalePointsAction.Borrow}
                metadata={collateral?.loanCollateral.metadata}
                apy={loan?.borrowerWAvgApy}
                customDailyPoints={customDailyPoints}
                externalPointRewards={undefined}
            />
        );
    }

    return (
        <RewardsPortfolioApy
            loopscalePointsAction={LoopscalePointsAction.Lend}
            metadata={loan?.principalMetadata}
            apy={loan?.borrowerWAvgApy}
            customDailyPoints={customDailyPoints}
            externalPointRewards={undefined}
        />
    );
}
