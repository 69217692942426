import { useCallback, useEffect, useMemo } from "react";

import {
    InputWrapper,
    LabelWrapper,
    Row,
    SPACING,
    Select,
    Text,
    useAppPalette,
    useMemoizedKeyMap
} from "@bridgesplit/ui";
import {
    BsMetaUtil,
    DEFAULT_STRATEGY_DURATION,
    isWhirlpoolMetadata,
    useActiveWallet,
    useTokenListMetadataByMint,
    useUserWhirlpoolPositions
} from "@bridgesplit/abf-react";
import { TokenListMetadata, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { ExpandMore } from "@mui/icons-material";
import { AppDialog } from "app/utils";

import { useBorrowBalanceChecker, useMarketBorrowSetCollateral } from "./util";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { NullTokenInput, TokenImage, TokenInputWithPrice, WhirlpoolPositionSummarized } from "../../common";
import { useMarketContext } from "../common";
import { useMarketDialog } from "../util";
import { BorrowMode } from "./type";

const LABEL = "Your collateral";
export default function BorrowCollateral() {
    return (
        <>
            <AmountInput />
            <LpPositionSelect />
        </>
    );
}

function AmountInput() {
    const { form, setForm, supportedCollateral } = useMarketBorrowContext();
    const { open: openDialog } = useMarketDialog();
    const marketProps = useMarketContext();

    const setToken = useMarketBorrowSetCollateral();

    const metadata = useTokenListMetadataByMint(form.collateralMint);
    const { BalanceDisplay } = useBorrowBalanceChecker();

    const selectToken = () => {
        openDialog(AppDialog.BorrowSelectCollateral, {
            setToken,
            marketProps,
            strategyDuration: form.strategyDuration ?? DEFAULT_STRATEGY_DURATION
        });
    };
    if (supportedCollateral?.length === 0) {
        return (
            <LabelWrapper label={LABEL} color="body">
                <Text color="disabled" variant="body2">
                    You don't own any supported collateral
                </Text>
            </LabelWrapper>
        );
    }

    if (!!metadata && BsMetaUtil.isNonFungible(metadata)) {
        return (
            <LabelWrapper label={LABEL} color="body">
                <NftSelect metadata={metadata} onClick={selectToken} />
            </LabelWrapper>
        );
    }

    if (form.collateralMint) {
        return (
            <TokenInputWithPrice
                label={LABEL}
                labelColor="body"
                metadata={metadata}
                selectToken={selectToken}
                value={form.collateralAmount}
                setValue={(collateralAmount) =>
                    setForm((prev) => ({
                        ...prev,
                        collateralAmount,
                        refetchOutAmount: true,
                        mode: BorrowMode.InputCollateral
                    }))
                }
                belowInput={<BalanceDisplay />}
            />
        );
    }

    return <NullTokenInput onClick={selectToken} loading={form.collateralMint === undefined} />;
}

function NftSelect({ metadata, onClick }: { onClick: () => void; metadata: TokenListMetadata }) {
    const { hoverBackground } = useAppPalette();
    return (
        <InputWrapper
            onClick={onClick}
            sx={{
                height: SPACING * 6.5,
                py: 0,
                cursor: "pointer",
                ":hover": { background: hoverBackground },
                justifyContent: "space-between"
            }}
        >
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="sm" />
                <Text>{BsMetaUtil.getName(metadata)}</Text>
            </Row>
            <Text variant="h1" color="caption">
                <ExpandMore />
            </Text>
        </InputWrapper>
    );
}

function LpPositionSelect() {
    const { form, setForm } = useMarketBorrowContext();

    const metadata = useTokenListMetadataByMint(form.collateralMint);
    const { user } = useActiveWallet();

    const isOrcaPosition = isWhirlpoolMetadata(metadata);
    const { data: wp } = useUserWhirlpoolPositions({ skip: !isOrcaPosition });

    const positions = useMemo(
        () =>
            user
                ? wp
                      ?.filter((w) => w.whirlpoolAddress === form.collateralMint)
                      .sort((a, b) => b.totalPrice - a.totalPrice)
                : [],
        [form.collateralMint, user, wp]
    );

    const mintToPosition = useMemoizedKeyMap(positions, (p) => p.position.positionMint);

    const setPosition = useCallback(
        (orcaPosition: WhirlpoolPositionExpanded) =>
            setForm((prev) => ({
                ...prev,
                orcaPosition,
                collateralAmount: orcaPosition.position.totalLiquidity,
                refetchOutAmount: true
            })),

        [setForm]
    );

    useEffect(() => {
        if (!isOrcaPosition || !positions?.length || form.orcaPosition?.whirlpoolAddress === form.collateralMint)
            return;
        setPosition(positions[0]);
    }, [form.collateralMint, form.orcaPosition, isOrcaPosition, positions, setPosition]);

    if (!isOrcaPosition) return null;

    if (positions?.length === 0) {
        return (
            <LabelWrapper color="body" label="Position">
                <Text color="disabled" variant="body2">
                    You don't have any {BsMetaUtil.getName(metadata)} positions
                </Text>
            </LabelWrapper>
        );
    }

    return (
        <LabelWrapper color="body" label="Position">
            <Select
                renderValue={() => <WhirlpoolPositionSummarized position={form.orcaPosition} />}
                value={positions ? form.orcaPosition?.position.positionMint : ""}
                loading={!positions}
                setValue={(positionMint) => {
                    const orcaPosition = mintToPosition?.get(positionMint);
                    if (!orcaPosition) return;
                    setPosition(orcaPosition);
                }}
                options={(positions ?? []).map((position) => {
                    return {
                        label: <WhirlpoolPositionSummarized spaceBetween position={position} />,
                        value: position.position.positionMint
                    };
                })}
            />
        </LabelWrapper>
    );
}
