import { LoopscalePointsAction } from "@bridgesplit/abf-react";
import { useLoopscalePoints } from "app/components/points";
import { StatColumn, StatProps, TextVariant } from "@bridgesplit/ui";
import { bsMath, formatPercent, formatUsd } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useVaultDepositContext } from "./VaultDepositContext";
import { useVaultContext } from "../VaultContext";

const variant: TextVariant = "body1";
export default function VaultDepositStats() {
    const { form } = useVaultDepositContext();
    const { vaultExpanded, userPosition } = useVaultContext();

    const usdValue = vaultExpanded?.principalOracle.getUsdAmount(form.amount);

    const pointsStat = useLoopscalePoints({
        action: LoopscalePointsAction.LiquidStake,
        totalPrincipalAmountUsd: usdValue,
        variant,
        metadata: vaultExpanded?.principalMetadata ? [vaultExpanded.principalMetadata] : undefined
    });

    if (!form.amount) return null;

    const stats: StatProps[] = [
        { caption: "APY", value: formatPercent(vaultExpanded?.vaultStrategy.strategy.wAvgApy) },
        {
            caption: COPY.USER_POSITION_TERM,
            value: [
                formatUsd(userPosition?.totalAmount.amountUsd ?? 0),
                formatUsd(bsMath.add(userPosition?.totalAmount.amountUsd, usdValue))
            ]
        },
        pointsStat
    ];

    return <StatColumn variant={variant} stats={stats} />;
}
