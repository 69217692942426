import { useBalanceChecker } from "app/components/common";
import { getVaultBalanceAvailableToWithdraw, useCurrentTime } from "@bridgesplit/abf-react";
import { roundDownToDecimals } from "@bridgesplit/utils";

import { useVaultContext } from "../VaultContext";
import { useVaultWithdrawContext } from "./VaultWithdrawContext";

export function useVaultWithdrawBalance() {
    const { userPosition, vaultExpanded } = useVaultContext();
    const { form, setForm } = useVaultWithdrawContext();
    const balances = useMaxVaultWithdraw();

    return useBalanceChecker({
        amount: form.amountPrincipal,
        customBalance: balances?.maxWithdraw,
        skip: !userPosition || !balances?.maxWithdraw,
        mint: vaultExpanded?.principalMetadata.mint,
        setMax: (amount: number) => {
            const roundedMaxAmount = roundDownToDecimals(amount, vaultExpanded?.principalMetadata.decimals);

            setForm(() => ({
                amountPrincipal: roundedMaxAmount,
                isMaxWithdraw: true
            }));
        },
        vaultAddress: vaultExpanded?.vault.address,
        hideRowEnd: !userPosition || !balances?.maxWithdraw
    });
}

export function useMaxVaultWithdraw() {
    const { vaultExpanded, userPosition } = useVaultContext();

    const { now } = useCurrentTime(1_000);

    if (!vaultExpanded || !userPosition) return undefined;

    const globalMaxWithdraw = getVaultBalanceAvailableToWithdraw(vaultExpanded, now);
    const userBalance = userPosition.amountAvailable.amountPrincipal;
    const userLpBalance = userPosition.amountAvailable.amountLp;
    const maxWithdraw = Math.min(globalMaxWithdraw, userBalance);
    return { maxWithdraw, userBalance, globalMaxWithdraw, userLpBalance };
}
