import { useMemo } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    LedgerExpanded,
    LoanInterestDueInfo,
    summarizeChangesFromNewInterest
} from "@bridgesplit/abf-react";
import { StatProps, StatColumn } from "@bridgesplit/ui";
import { formatDate } from "@bridgesplit/utils";

export default function RepayStats({
    paymentInfo,
    loanExpanded,
    ledgerAccount
}: {
    paymentInfo: LoanInterestDueInfo;
    loanExpanded: AbfLoanExpanded | undefined;
    ledgerAccount: LedgerExpanded | undefined;
}) {
    const summary = useMemo(
        () => (loanExpanded ? summarizeChangesFromNewInterest(loanExpanded, ledgerAccount, paymentInfo) : undefined),
        [loanExpanded, paymentInfo, ledgerAccount]
    );

    const totalDebt = useMemo(() => ledgerAccount?.ledgerDebt, [ledgerAccount?.ledgerDebt]);
    const newTotalDebt = useMemo(() => {
        if (!totalDebt) return 0;
        // simple loans pay fees in single txn so prepayment/late fees are included
        return Math.max(totalDebt?.total - paymentInfo.repaymentAmount, 0);
    }, [paymentInfo.repaymentAmount, totalDebt]);

    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    const stats: StatProps[] = [
        { value: formatDate(summary?.dueDate, "date", { showTime: "always" }), caption: "Due by" },
        {
            value: [totalDebt?.total, newTotalDebt],
            caption: "Total debt",
            symbol
        }
    ];
    return <StatColumn loading={!summary} stats={stats} />;
}
