export type ApiTypeToString = {
    messagingApi: string;
    mpcApi: string;
    arrakisApi: string;
    rubyApi: string;
    oraclesApi: string;
    marketsApi: string;
    pandoraApi: string;
};

export type EnvDetails = {
    abfPid: string;
    syndicatedPid: string;
    liquidationPid: string;
    authDomain: string;
    authClient: string;
    rpcUrl?: string;
    mixpanelToken: string;
} & ApiTypeToString;

export enum AllowedEnv {
    Main = "MAIN", // ONLY app.loopscale.com
    Sandbox = "SANDBOX", //  ONLY sandbox.loopscale.com
    ProdTesting = "PROD_TESTING", // for simulating production with local FE
    Dev = "DEV", // run sandbox with local rust development server (apis);
    FeatureStaging = "FEATURE_STAGING", // for testing staging features in a deployed FE/BE
    LocalNet = "LOCAL_NET" // testing with Local Network instead of mainnet
}

type EnvBreakdown = { main: string; sandbox: string };

const abfPid: EnvBreakdown = {
    main: "abfcSQac2vK2Pa6UAJb37DzarVxF15bDTdphJzAqYYp",
    sandbox: "sb1dXHpc8itGtDJju3EYprHVERDRJ7EVrTSUThywyRe"
};

const liquidationPid: EnvBreakdown = {
    main: "1iQ4TPe4KpuPhxQ3YbLY8DULco99avi7DVVbhHpKmmf",
    sandbox: "1iq2p3KMKMVe9GgKaZRrtPVxtsHb1yqwXJUcFTUjGUB"
};
const AUTH_0_CLIENT: EnvBreakdown = {
    main: "TUAl3Yf4EDOFgwBHRpEEgsusP5Z1Q0HS",
    sandbox: "q8KJf1rrjYJDO0FqUOV4NHuegqr1jWPW"
};

const AUTH_0_DOMAIN: EnvBreakdown = {
    main: "auth.loopscale.com",
    sandbox: "bs-testing.us.auth0.com"
};
const syndicatedPid: EnvBreakdown = {
    main: "syNdL21zsXmUEwnwkz2vg352EZfDuQTiG5BgTFQy5WD",
    sandbox: "BBuNf5GqeDJVZDBZ9YRkFWweNwnR82yG7nm2HUQuXaRX"
};
const BASE_URL: EnvBreakdown = {
    main: "https://tars.loopscale.com/v1",
    sandbox: "https://case.loopscale.com/v1"
};
const SERVICES: ApiTypeToString = {
    messagingApi: "messaging",
    mpcApi: "mpc",
    arrakisApi: "arrakis",
    rubyApi: "ruby",
    oraclesApi: "oracles",
    marketsApi: "markets",
    pandoraApi: "pandora"
};

const MAIN: EnvDetails = {
    ...generateApiUrls(BASE_URL.main),
    abfPid: abfPid.main,
    liquidationPid: liquidationPid.main,
    syndicatedPid: syndicatedPid.main,
    authDomain: AUTH_0_DOMAIN.main,
    authClient: AUTH_0_CLIENT.main,
    mixpanelToken: "0133491c1811993bed6a4c73a82ff1a7"
};

const SANDBOX: EnvDetails = {
    ...generateApiUrls(BASE_URL.sandbox),
    abfPid: abfPid.sandbox,
    liquidationPid: liquidationPid.sandbox,
    syndicatedPid: syndicatedPid.sandbox,
    authDomain: AUTH_0_DOMAIN.sandbox,
    authClient: AUTH_0_CLIENT.sandbox,
    mixpanelToken: "f29e9e947d4ec4e363d389987d742a9a"
};

const LOCAL_URLS = {
    mpcApi: localHost(8081),
    bsMetadataApi: localHost(8082),
    syndicatedApi: localHost(8084),
    napoleonApi: localHost(8085),
    arrakisApi: localHost(8086),
    rubyApi: localHost(8087),
    pandoraApi: localHost(80),
    marketsApi: localHost(8080)
};

const LOCAL_NET: EnvDetails = {
    ...SANDBOX,
    rpcUrl: "http://34.123.94.113:8899",
    abfPid: "97i9bTHPam32aGqekNk4kFuaqU48bv46nr8yyJdGRayq",
    syndicatedPid: "EVbtHAN9hB6AoKwBgPPBsZk3vEFgD9DJqee1DLbdJ1bS",
    ...LOCAL_URLS
};
const PROD_TESTING: EnvDetails = {
    ...MAIN
};

const FEATURE_STAGING: EnvDetails = {
    ...MAIN
};

const DEV: EnvDetails = {
    ...SANDBOX
};
export const ENVIRONMENTS = {
    [AllowedEnv.Main]: MAIN,
    [AllowedEnv.ProdTesting]: PROD_TESTING,
    [AllowedEnv.Sandbox]: SANDBOX,
    [AllowedEnv.Dev]: DEV,
    [AllowedEnv.FeatureStaging]: FEATURE_STAGING,
    [AllowedEnv.LocalNet]: LOCAL_NET
};

function generateApiUrls(baseUrl: string) {
    const entries = Object.entries(SERVICES).map(([key, serviceName]): [keyof ApiTypeToString, string] => [
        key as keyof ApiTypeToString,
        `${baseUrl}/${serviceName}`
    ]);
    return Object.fromEntries(entries) as ApiTypeToString;
}

export function localHost(port: number) {
    const PORT_FORWARDING = false;

    if (PORT_FORWARDING) return `https://9sfrffzp-${port}.usw2.devtunnels.ms/`;
    return `http://localhost:${port}`;
}

export const DEFAULT_API_VERSION = "1.0.3";
export const SERVICE_API_VERSIONS: Partial<ApiTypeToString> = {
    marketsApi: "1.0.7"
};
