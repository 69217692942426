import { useCallback, useMemo } from "react";

import {
    calculateHealthRatioFromLtv,
    calculateLiquidationPrice,
    calculateLtvFromLeverage,
    getSimpleInterest,
    LoopWindParams,
    useActiveWallet,
    useLoopWindTransaction
} from "@bridgesplit/abf-react";
import {
    bsMath,
    calculatePercentChange,
    decimalsToBps,
    LOADING_ERROR,
    MISSING_PARAM_ERROR,
    MISSING_WALLET_ERROR,
    Result
} from "@bridgesplit/utils";
import { getDurationInSeconds } from "@bridgesplit/abf-sdk";
import { TrackTransactionEvent } from "app/types";
import { trackSubmitEarnVault } from "app/hooks";

import { DEFAULT_SLIPPAGE, useBalanceChecker } from "../../common";
import { useLoopContext } from "../LoopContext";
import { allTransactionsSucceeded, useTransactionSender } from "../../transactions";
import { useWindContext } from "./WindContext";
import { DEFAULT_WIND_FORM } from "./constants";

export function useLoopBalanceChecker() {
    const {
        form: { collateralAmount }
    } = useWindContext();
    const { loopExpanded } = useLoopContext();

    const handleChange = useLoopSetCollateralAmount();
    return useBalanceChecker({
        amount: collateralAmount,
        mint: loopExpanded?.collateralToken.mint,
        setMax: handleChange
    });
}

export function useLoopSetCollateralAmount() {
    const { setForm } = useWindContext();
    return useCallback(
        (collateralAmount: number | undefined) => setForm((prev) => ({ ...prev, collateralAmount })),
        [setForm]
    );
}

const WIND_ERRORS = {
    "Calculated flash loan amount": "Exceeds desired slippage. You can adjust your slippage in settings",
    "Borrow cap": "Your request exceeds the available borrow capacity for this market"
};
export function useLoopWind() {
    const { loopExpanded } = useLoopContext();

    const { form, setForm, externalQuote, quote } = useWindContext();
    const wind = useLoopWindTransaction();
    const send = useTransactionSender();
    const { activeWallet } = useActiveWallet();

    return useCallback(async () => {
        if (!loopExpanded) return Result.errFromMessage(LOADING_ERROR);

        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);

        if (!form.collateralAmount || !quote || !externalQuote || !form.multiplier)
            return Result.errFromMessage(MISSING_PARAM_ERROR);

        const { bestQuote, principalAmount } = quote;

        const expectedStartingLtv = decimalsToBps(calculateLtvFromLeverage(form.multiplier));

        const params: LoopWindParams = {
            loopExpanded,
            vaultIdentifier: loopExpanded.vaultIdentifier,
            collateralAmount: form.collateralAmount,
            leverageMultiplier: form.multiplier - (form.percentSlippageDecimals ?? DEFAULT_SLIPPAGE),
            strategyAddress: bestQuote.lendingStrategyAddress,
            principalRequested: principalAmount,
            strategyDuration: form.strategyDuration,
            expectedStartingLtv,
            swapIx: externalQuote.swapIxs,
            swapLuts: externalQuote.swapLuts,
            expectedLoanValues: {
                expectedApy: decimalsToBps(bestQuote.apy),
                expectedLtv: [decimalsToBps(bestQuote.ltv), 0, 0, 0, 0],
                expectedLiquidationThreshold: [decimalsToBps(bestQuote.liquidationThreshold), 0, 0, 0, 0]
            },
            userPublicKey: activeWallet
        };

        const res = await send(wind, params, {
            alerter: { generationEstimateSeconds: 8, showProgress: true },
            messageOverrides: {
                errorMessageMap: WIND_ERRORS
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitEarnOrder,
                params: trackSubmitEarnVault({ params })
            }
        });
        if (allTransactionsSucceeded(res)) {
            setForm(DEFAULT_WIND_FORM);
        }
        return res;
    }, [
        loopExpanded,
        activeWallet,
        form.collateralAmount,
        form.multiplier,
        form.strategyDuration,
        form.percentSlippageDecimals,
        quote,
        externalQuote,

        send,
        wind,
        setForm
    ]);
}

export function useLoopHealth() {
    const { quote } = useWindContext();
    const { loopExpanded } = useLoopContext();

    return useMemo(() => {
        if (!quote || !loopExpanded) return undefined;
        const { bestQuote, leverageMultiplier } = quote;

        const ltv = calculateLtvFromLeverage(leverageMultiplier);
        const healthRatio = calculateHealthRatioFromLtv(ltv, bestQuote.liquidationThreshold);

        return { ltv, healthRatio };
    }, [loopExpanded, quote]);
}

export function useLoopLiquidationPrice() {
    const { quote } = useWindContext();
    const { loopExpanded } = useLoopContext();

    return useMemo(() => {
        if (!quote || !loopExpanded)
            return {
                liquidationPrice: 0,
                ltv: undefined,
                currentPrice: 0,
                percentChange: 0
            };

        const { bestQuote, leveragedCollateralAmount, principalAmount } = quote;

        const totalInterest = getSimpleInterest({
            principal: principalAmount,
            apy: bestQuote.apy,
            loanDuration: getDurationInSeconds(bestQuote),
            subtractPrincipal: true
        });

        const liquidationPrice = calculateLiquidationPrice(
            bsMath.tokenAdd(principalAmount, totalInterest, loopExpanded.collateralToken.decimals),
            leveragedCollateralAmount,
            bestQuote.liquidationThreshold
        );

        const currentPrice = bsMath.div(loopExpanded.collateralOracle.usdPrice, loopExpanded.principalOracle.usdPrice);
        const percentChange = calculatePercentChange(currentPrice, liquidationPrice);

        return { liquidationPrice, ltv: bestQuote.ltv, currentPrice, percentChange };
    }, [loopExpanded, quote]);
}
