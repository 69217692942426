/** Only transaction endpoints should exist in constants.ts */
export const GENERATE_SIGN_IN_TXN_ENDPOINT = "/user/sign_in";

export const TXN_CREATE_USER_MFI_ACCOUNT_ROUTE = "/txn/user_mfi/create";

export const TXN_CREDITBOOK_CREATE = "/creditbook/create";
export const TXN_CREDITBOOK_REFINANCE = "/creditbook/refinance";
export const TXN_CREDITBOOK_BORROW_PRINCIPAL = "/creditbook/principal/borrow";
export const TXN_CREDITBOOK_REPAY_PRINCIPAL = "/creditbook/principal/repay";
export const TXN_CREDITBOOK_DEPOSIT_COLLATERAL = "/creditbook/collateral/deposit";
export const TXN_CREDITBOOK_WITHDRAW_COLLATERAL = "/creditbook/collateral/withdraw";
export const TXN_CREDITBOOK_SELL = "/creditbook/sell";
export const TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_CLAIM_FEES = "/creditbook/collateral/orca/claim_fees";
export const TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_LIQUIDITY = "/creditbook/collateral/orca/update_liquidity";
export const TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_POSITION = "/creditbook/collateral/orca/transfer_position";

export const TXN_LEND_VAULT_CANCEL_TIMELOCKS = "/lending_vaults/timelock/cancel";
export const TXN_LEND_VAULT_CREATE_TIMELOCKS = "/lending_vaults/timelock/create";
export const TXN_LEND_VAULT_DEPOSIT = "/lending_vaults/deposit";
export const TXN_LEND_VAULT_WITHDRAW = "/lending_vaults/withdraw";
export const TXN_LEND_VAULT_EDIT_DEPOSITS = "/lending_vaults/deposits/edit";
export const TXN_LEND_VAULT_CLAIM_FEE = "/lending_vaults/fee/claim";
export const TXN_LEND_VAULT_CREATE = "/lending_vaults/create";
export const TXN_WIND_LOOP = "/loop/wind";
export const TXN_UNWIND_LOOP = "/loop/unwind";

export const TXN_STRATEGY_CREATE = "/strategy/create";
export const TXN_STRATEGY_DEPOSIT = "/strategy/deposit";
export const TXN_STRATEGY_WITHDRAW = "/strategy/withdraw";
export const TXN_STRATEGY_UPDATE = "/strategy/update";
export const TXN_STRATEGY_CLOSE = "/strategy/close";

export const METEORA_DEPOSIT_TXN = "/meteora/txn/deposit";
export const METEORA_WITHDRAW_TXN = "/meteora/txn/withdraw";

export const USER_WP_POSITIONS_ROUTE = "/user/whirlpools";

export const TXN_CREATE_ORACLE = "/oracles/create";
export const TXN_UPDATE_ORACLE = "/oracles/update";
