import { useUserLoops } from "@bridgesplit/abf-react";
import { Column, Text } from "@bridgesplit/ui";
import { useLoopsPagination } from "app/api";

import { useLoopContext } from "../LoopContext";
import LoopCards from "./LoopCards";

export default function UserLoops() {
    const { loopExpanded } = useLoopContext();
    const pagination = useLoopsPagination();
    const { data: userLoops } = useUserLoops({
        loopsExpanded: loopExpanded ? [loopExpanded] : undefined,
        filter: pagination.params,
        skip: !loopExpanded
    });
    return (
        <Column spacing={2}>
            <Text variant="h3"> My positions </Text>
            <LoopCards pagination={pagination} loopPositions={userLoops} hideTokenFilter />
        </Column>
    );
}
