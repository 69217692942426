import {
    decimalsToBps,
    DISABLED_APY,
    TransactionGenerationType,
    TransactionInstructionResponse,
    TransactionSettingsHeader
} from "@bridgesplit/utils";

import { DurationType, StrategyDuration } from "./time";
import { ExternalYieldSource } from "./external-yield";

export interface AbfTransactionHeaders {
    groupIdentifier: string | undefined;
    bearerToken: string | undefined;
    userWallet: string | undefined;
    txnSettings: TransactionSettingsHeader | undefined;
}

export interface DepositAssetArgs {
    deposit_mint: string;
    amount: number;
    escrow_nonce: string;
    organization_identifier: string;
}

export interface WithdrawAssetArgs {
    deposit_mint: string;
    amount: number;
    escrow_account: string;
    recipient: string;
    extra_instructions: ExtraInstructionArgs;
    withdraw_all?: boolean;
}

export interface ExtraInstructionArgs {
    /// ixs to prepend
    preInstructions?: TransactionInstructionResponse[];
    /// ixs to postpend
    postInstructions?: TransactionInstructionResponse[];
    /// total compute units for txn
    computeUnits?: number;
}

export interface TransferAssetArgs {
    sender: string;
    receiver: string;
    mint: string;
    amount: number;
    payer: string;
}

export interface BorrowPrincipalParams {
    amount: number;
    expectedLoanValues: ExpectedLoanValues;
}

export interface BorrowPrincipalTxnParams {
    loan: string;
    borrowParams: BorrowPrincipalParams;
}

export interface RepayLoanParams {
    amount: number;
    ledgerIndex: number;
    repayAll: boolean;
}

export interface RepayLoanTxnParams {
    loan: string;
    repayParams: RepayLoanParams;
}

export type DepositCollateralParams = {
    loan: string;
    depositMint: string;
    decimals: number;
    amount: number;
    assetType: number;
    assetIdentifier: string;
    expectedLoanValues: ExpectedLoanValues;
};

export interface WithdrawCollateralTxnParams {
    loan: string;
    collateralMint: string;
    amount: number;
    collateralIndex: number;
    assetIndexGuidance?: string; //Do not need to add derived in BE
    weightMatrix?: string; //Do not need to add derived in BE
    expectedLoanValues: ExpectedLoanValues;
}

export interface RefinanceParams {
    ledgerIndex: number;
    refinanceDuration: number;
    refinanceDurationType: number;
}

export interface RefinanceLedgerParams {
    ledgerIndex: number;
    durationIndex: number;
}

export interface RefinanceLoanTxnParams {
    loan: string;
    oldStrategy: string;
    newStrategy: string;
    principalMint: string;
    refinanceParams: RefinanceLedgerParams;
}

export interface SetupLoanArgs {
    order: string;
}

export enum ManageSide {
    Deposit,
    Withdraw
}

// Define a type that includes both the simple 'SplToken' and a structured 'OrcaPosition'
export type LockboxAssetType = "SplToken" | OrcaPositionLbAssetType;

// Define the OrcaPosition type as an object with a single string property
export type OrcaPositionLbAssetType = { OrcaPosition: string };

export type MultiCollateralTermsUpdateParams = Array<
    [
        number, // u64 APY
        Array<[number, number]> //[Index of collateral in market information, duration index]
    ]
>;

export interface CreateCustomStrategyTxnParams {
    principalMint: string;
    lender: string;
    amount: number;
    externalYieldSourceArgs?: ExternalYieldSourceArgs;
    liquidityBuffer?: number;
    interestFee?: number;
    originationFee?: number;
    originationCap?: number;
    collateralTerms?: MultiCollateralTermsUpdateParams;
}

export interface ExternalYieldSourceArgs {
    newExternalYieldSource: ExternalYieldSource;
    createExternalYieldAccount: boolean;
}

export interface WithdrawStrategyTxnParams {
    strategy: string;
    amount: number;
    withdrawAll: boolean;
}

export interface DepositStrategyTxnParams {
    strategy: string;
    amount: number;
}

export interface CloseStrategyTxnParams {
    strategyAddress: string;
}

export interface UpdateLiquidationManagerParams {
    strategyAddress: string;
}

export interface UpdateOrderRefinanceTermsParams {
    strategyAddress: string;
    order: string;
    refinanceEnabled: boolean;
    maxRefinanceApy: number;
    refinanceDuration: number;
    refinanceDurationType: DurationType;
}

export interface CreateLoanParams {
    principalAmount: number;
    principalDecimals: number;
    collateralAmount: number;
    collateralDecimals: number;
    collateralType: number;
    loanType: number;
    collateralIdentifier: string;
    strategy: string;
    borrower: string;
    principalMint: string;
    collateralMint: string;
    assetIndexGuidance: number[];
    durationIndex: number;
    expectedLoanValues: ExpectedLoanValues;
}

export const MAXIMUM_LEDGERS = 5;

export type LedgerValueTuple = [number, number, number, number, number]; // TS cannot handle const for array length
export type LedgerValueTupleForTransaction = [string, string, string, string, string];

// Convert a map of strategy duration to apy values to a fixed length ledger tuple value
export function convertApyValuesToFixedLengthLedgerTupleValue(
    strategyDurationApyValues: Map<string, number | undefined>
): LedgerValueTuple {
    const values = Array.from(strategyDurationApyValues.values());
    return [
        values[0] ?? Number.MAX_SAFE_INTEGER,
        values[1] ?? Number.MAX_SAFE_INTEGER,
        values[2] ?? Number.MAX_SAFE_INTEGER,
        values[3] ?? Number.MAX_SAFE_INTEGER,
        values[4] ?? Number.MAX_SAFE_INTEGER
    ];
}

// By the time a ledgerValueTuple needs to be converted to bps the following key should be used:
// NUMBER.MAX_SAFE_INTEGER indicates that a value is DISABLED_APY
// Any other number indicates that the value is enabled and should be converted to bps, including 0.
export function convertLedgerValueTupleToBps(ledgerValueTuple: LedgerValueTuple): LedgerValueTuple {
    const newTuple: LedgerValueTuple = [0, 0, 0, 0, 0]; // TS cannot handle const for array length so need to use this fn
    for (let i = 0; i < ledgerValueTuple.length; i++) {
        newTuple[i] =
            ledgerValueTuple[i] === Number.MAX_SAFE_INTEGER
                ? Number.MAX_SAFE_INTEGER
                : decimalsToBps(ledgerValueTuple[i]);
    }
    return newTuple;
}

//  By the time parseLedgerValueTupleForTransaction is called the following should be true:
// The tuple has been converted to BPS.
// If there are disabled apy terms, they should be set to NUMBER.MAX_SAFE_INTEGER.
export function prepareLedgerValueTupleForTransaction(
    ledgerValueTuple: LedgerValueTuple
): LedgerValueTupleForTransaction {
    const newTuple: LedgerValueTupleForTransaction = ["", "", "", "", ""];
    for (let i = 0; i < ledgerValueTuple.length; i++) {
        if (ledgerValueTuple[i] === Number.MAX_SAFE_INTEGER) {
            newTuple[i] = DISABLED_APY;
        } else {
            newTuple[i] = ledgerValueTuple[i].toString();
        }
    }
    return newTuple;
}

export interface ExpectedLoanValues {
    expectedApy: number;
    expectedLtv: LedgerValueTuple;
    expectedLiquidationThreshold: LedgerValueTuple;
}

export interface TransactionGenerationQuery {
    transaction_generation_type: TransactionGenerationType;
}

export interface SellLoanParams {
    loan: string;
    oldStrategy: string;
    newStrategy: string;
    sellParams: {
        ledgerIndex: number;
        expectedSalePrice: number;
        buyerDurationIndex: number;
    };
}

export interface VaultDepositParams {
    principalAmount: number;
    minLpAmount: number;
    vault: string;
}

export interface VaultWithdrawParams {
    maxAmountLp: number;
    vault: string;
    amountPrincipal: number;
    withdrawAll: boolean;
}

export interface EditCustomStrategyTxnParams {
    strategy: string;
    collateralTerms?: StrategyCollateralUpdates;
    updateParams?: EditStrategySettingsArgs;
}

export interface EditStrategySettingsArgs {
    originationsEnabled?: boolean;
    liquidityBuffer?: number;
    interestFee?: number;
    originationFee?: number;
    principalFee?: number;
    originationCap?: number;
    externalYieldSource?: ExternalYieldSourceParams;
}

export interface ExternalYieldSourceParams {
    newExternalYieldSource: number;
}

export interface InitVaultTimelockTxnParams {
    vaultAddress: string;
    collateralUpdates: StrategyCollateralUpdates;
    editStrategyArgs?: EditStrategyParams;
    editVaultArgs?: EditVaultParams;
}

export interface EditStrategyParams {
    originationsEnabled: boolean;
    nonce?: string;
    externalYieldSource: ExternalYieldSourceParams;
    principalMint?: string;
    originationFee?: number;
    interestFee?: number;
    originationCap?: number;
    liquidityBuffer?: number;
}

export interface EditVaultParams {
    maxEarlyUnstakeFeeCbps: number;
}

export interface StrategyCollateralUpdates {
    addCollateral: Record<string, AddCollateralArgs>;
    removeCollateral: Record<string, RemoveCollateralArgs>;
    updateCollateral: Record<string, CollateralParamsUpdateArgs>;
}

export interface AddCollateralArgs {
    durationsAndApys: Record<string, string>;
    marketInformation?: UpdateMarketInformationArgs;
}

export interface RemoveCollateralArgs {
    durations: StrategyDuration[];
    removeFromMarketInformation: boolean;
}

export interface CollateralParamsUpdateArgs {
    ltvUpdate?: UpdateMarketInformationArgs;
    apyUpdate?: Record<string, string>;
}

export interface CreateMarketInformationArg {
    principalMint: string;
    authority: string;
}
export interface UpdateMarketInformationArgs {
    assetIdentifier: string;
    quoteMint: string;
    oracleAccount: string;
    oracleType: number;
    maxUncertainty: number;
    maxAge: number;
    decimals: number;
    ltv?: number;
    liquidationThreshold?: number;
    removeCollateral?: boolean;
}

export interface LocalLtvData {
    principalMint: string;
    ltv: number;
    liquidationThreshold: number;
    liquidationFee: number;
}

export interface CancelVaultTimeLockTxnParams {
    timelock: string;
    manager: string;
    vault: string;
}

export interface ClaimVaultFeesTxnParams {
    lendVaultAddress: string;
}

export interface UpdateLendingVaultDepositsTxnParams {
    lendVaultAddress: string;
    depositsEnabled: boolean;
}
