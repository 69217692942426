import { BsMetaUtil } from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { DispatchType } from "@bridgesplit/react";
import {
    Row,
    ExternalLink,
    Text,
    Column,
    IconButton,
    PopoverWrapper,
    TextButton,
    Tooltip,
    usePopover
} from "@bridgesplit/ui";
import { DEFAULT_SOLANA_EXPLORER } from "@bridgesplit/utils";
import { OpenInNewOutlined, TuneOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { TokenImage } from "app/components/common";

import { TermsColumn } from "./type";

export function CollateralInfo({ metadata }: { metadata: TokenListMetadata }) {
    return (
        <Row spacing={1}>
            <TokenImage metadata={metadata} size="md" />
            <Text>{BsMetaUtil.getSymbolUnique(metadata)}</Text>
            <ExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={`${DEFAULT_SOLANA_EXPLORER}/token/${metadata.mint}`}
            >
                <Text color="disabled">
                    <OpenInNewOutlined />
                </Text>
            </ExternalLink>
        </Row>
    );
}

export function TableFilter({
    visibleColumns,
    allColumns,
    resetColumns,
    requiredColumns,
    maxColumns,
    setVisibleColumns
}: {
    visibleColumns: TermsColumn[];
    allColumns: TermsColumn[];
    resetColumns: TermsColumn[];
    requiredColumns: TermsColumn[];
    maxColumns: number;
    setVisibleColumns: DispatchType<TermsColumn[]>;
}) {
    const { open, popoverProps } = usePopover();
    return (
        <>
            <Tooltip title="Adjust columns">
                <IconButton
                    textVariant="body2"
                    border={true}
                    jsxIcon={<TuneOutlined />}
                    onClick={open}
                    sx={{ height: "30px", borderRadius: "4px", opacity: "0.5" }}
                />
            </Tooltip>
            <PopoverWrapper
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
                {...popoverProps}
            >
                <Column p={1} spacing={1}>
                    {Object.values(allColumns)
                        .filter((t) => !requiredColumns.includes(t))
                        .map((term, i) => {
                            const isSelected = visibleColumns.includes(term);
                            return (
                                <Row spacing={2} spaceBetween key={`${term}-${i}`}>
                                    <Text variant="body2">{term}</Text>
                                    <IconButton
                                        textVariant="body2"
                                        onClick={() => {
                                            setVisibleColumns((prev) => {
                                                const copy = [...prev];
                                                if (copy.includes(term)) {
                                                    return copy.filter((c) => c !== term);
                                                }
                                                return [...copy.slice(0, maxColumns - 1), term];
                                            });
                                        }}
                                        jsxIcon={isSelected ? <VisibilityOff /> : <Visibility />}
                                        border={false}
                                    />
                                </Row>
                            );
                        })}
                    <TextButton color="caption" variant="body2" onClick={() => setVisibleColumns(resetColumns)}>
                        Reset
                    </TextButton>
                </Column>
            </PopoverWrapper>
        </>
    );
}
