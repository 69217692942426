import { lazy } from "@bridgesplit/ui";

const Rewards = lazy(() => import("./Rewards"));
const WaitlistUnconnected = lazy(() => import("./WaitlistUnconnected"));

export { Rewards, WaitlistUnconnected };
export * from "./dialog";
export * from "./oauth";
export * from "./app";
export * from "./stats";
export * from "./util";
