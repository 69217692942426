import { useEffect, useState } from "react";

import { useApplyReferralMutation, useUserReferral, REFERRAL_KEY } from "@bridgesplit/abf-react";
import { mutationIntoRawResult, AppCookie, useUserPublicKey } from "@bridgesplit/react";
import { DialogWrapper, FormInput, WalletButtons } from "@bridgesplit/ui";
import { parseErrorFromOptions, Result } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";
import { BS_SHORT_URL_INVITE } from "app/constants";
import { useGetSignedTransactionAuth } from "app/components/transactions";

import { REFERRAL_CODE_PLACEHOLDER } from "../constants";
import { AppButton, DialogHeader } from "../../common";

const ERRORS = {
    PrefineryError: "This code is valid but can't be applied to your account",
    "You cannot refer yourself": "You can't refer yourself",
    "Referral cycle detected": "You can't refer someone who has already referred you"
};

export default function ReferralEnterCodeDialog() {
    const { getData } = useAppDialog();
    const data = getData(AppDialog.ReferralEnterCode);
    const initialCode = data?.initialCode;
    const [code, setCode] = useState<string | undefined>(initialCode);
    const getSignedTransaction = useGetSignedTransactionAuth();
    const userPublicKey = useUserPublicKey();

    const [applyReferral] = useApplyReferralMutation(undefined);
    const { data: referral } = useUserReferral();

    // Check for stored referral code in cookies on component mount
    useEffect(() => {
        if (!code) {
            const storedReferralCode = AppCookie.get(REFERRAL_KEY);
            if (storedReferralCode) {
                setCode(storedReferralCode);
            }
        }
    }, [code]);

    async function submit() {
        if (!userPublicKey) {
            return Result.errFromMessage("Please connect your wallet first", { skipSentry: true });
        }

        if (referral?.userReferralCode === code) {
            return Result.errFromMessage(ERRORS["You cannot refer yourself"], { skipSentry: true });
        }

        const signedTransactionRes = await getSignedTransaction({ description: "Applying referral" });
        if (signedTransactionRes.isErr()) {
            return Result.errFromMessage("Unable to apply referral codes at this time", { skipSentry: true });
        }
        if (!signedTransactionRes.isOk() || !code) return Result.errFromMessage("Invalid signature");

        const signedTransaction = signedTransactionRes.unwrap();

        if (!signedTransaction) return Result.errFromMessage("Invalid signature");

        const res = await mutationIntoRawResult(applyReferral, { code, transaction: signedTransaction });

        if (res.isOk()) {
            // Clear the referral code from cookies after successful application
            AppCookie.remove(REFERRAL_KEY);
            return res;
        }
        const parsedError = parseErrorFromOptions(res.unwrapErrMessage(), ERRORS);

        if (parsedError) return Result.errFromMessage(parsedError, { skipSentry: true });
        return Result.errFromMessage("Invalid referral code");
    }

    // Custom wallet connection UI that stays within this dialog
    if (!userPublicKey) {
        return (
            <DialogWrapper>
                <DialogHeader
                    spacing={0.5}
                    header={AppDialog.ReferralEnterCode}
                    description="Connect your wallet to apply a referral code"
                />
                <WalletButtons />
            </DialogWrapper>
        );
    }

    return (
        <DialogWrapper>
            <DialogHeader
                spacing={0.5}
                header={AppDialog.ReferralEnterCode}
                description="Enter a friend's referral code below"
            />

            <FormInput
                label="Referral code"
                placeholder={REFERRAL_CODE_PLACEHOLDER}
                value={code}
                setValue={(input) => {
                    if (!input) return setCode(input);

                    // in case user accidentally pastes full ref link
                    if (input?.includes(BS_SHORT_URL_INVITE)) {
                        input = input.split(BS_SHORT_URL_INVITE + "/")[1];
                    }

                    const regex = new RegExp(`^[a-zA-Z0-9]*$`);

                    if (!regex.test(input)) return;
                    setCode(input);
                }}
                variant="string"
            />

            <AppButton
                overrideAccess
                isTransaction={false}
                asyncCta={{
                    closeDialog: "onSuccess",
                    onClickWithResult: submit,
                    options: { onSuccessMessage: "Referral was successfully applied" }
                }}
                disabled={!code}
                variant="contained"
            >
                {AppDialog.ReferralEnterCode}
            </AppButton>
        </DialogWrapper>
    );
}
