import { Fragment } from "react";

import { ExternalPointRewardStat } from "./ExternalPointReward";
import { GenericRewardsApyProps } from "../types";

export function RewardsExternalPointsRewards(props: GenericRewardsApyProps) {
    const { externalPointRewards } = props;

    if (!externalPointRewards) return null;

    return (
        <Fragment>
            {externalPointRewards.map((externalPointReward) => (
                <ExternalPointRewardStat
                    key={`external-point-reward-${externalPointReward}`}
                    externalPointReward={externalPointReward}
                />
            ))}
        </Fragment>
    );
}
