import { useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BestQuote,
    BsMetaUtil,
    LoanInfoSorting,
    calculateHealthRatio,
    calculateLoanHealth,
    getLoanCollateralUsdValue,
    getOffsetDetailsFromStrategyDuration,
    getSimpleInterest,
    getZcCollateral,
    getZcLedger,
    isStrategyDurationSame,
    marketsApi,
    serializeStrategyDuration,
    useBestQuotes,
    useStrategyDurations,
    useLoanRefinanceTransaction,
    getCollateralMintToInfo,
    LoanFilterType
} from "@bridgesplit/abf-react";
import {
    EmptyPlaceholder,
    LabelWrapper,
    Row,
    Select,
    StatColumn,
    StatProps,
    Text,
    TextSkeleton
} from "@bridgesplit/ui";
import {
    LOADING_ERROR,
    Result,
    SortDirection,
    bsMath,
    findMaxElement,
    formatDate,
    formatPercent,
    uiAmountToLamports
} from "@bridgesplit/utils";
import {
    formatDurationWithType,
    getDurationIndex,
    getDurationInSeconds,
    RefinanceLoanTxnParams,
    StrategyDuration
} from "@bridgesplit/abf-sdk";
import { useAppNavigate } from "@bridgesplit/react";
import { useLocation } from "react-router-dom";
import { COPY, LOAN_SLUG } from "app/constants";
import { TrackTransactionEvent, TransactionTypes } from "app/types";
import { trackSubmitRefinance } from "app/hooks";

import { useTransactionFeeStat, useTransactionSender } from "../../transactions";
import { AppButton, getLoanAssetPath, HealthChange } from "../../common";

type RefinanceOption = {
    loanEndDate: number;
    bestQuote: BestQuote | undefined;
    totalDebt: number;
    strategyDuration: StrategyDuration;
    healthRatio: number;
    ltv: number;
};

export default function RefinanceLoan({ loanExpanded }: { loanExpanded: AbfLoanExpanded | undefined }) {
    const firstLedger = getZcLedger(loanExpanded);
    const newPrincipalAmount = firstLedger?.ledgerDebt.total ?? 0; //New principal amount is the total debt of the loan

    const { strategyDurations } = useStrategyDurations();

    const { data: quotes } = useBestQuotes({
        collateralMintToInfo: getCollateralMintToInfo(loanExpanded),
        principalMint: firstLedger?.principalMint,
        strategyDurations,
        minPrincipalAmountLamports: uiAmountToLamports(newPrincipalAmount, loanExpanded?.principalMetadata.decimals)
    });

    const [optionState, setOption] = useState<RefinanceOption>();

    const optionsWithOffsets = useMemo(() => {
        if (!quotes || !strategyDurations) return undefined;
        return strategyDurations?.map((d): RefinanceOption => {
            const { loanEndDate } = getOffsetDetailsFromStrategyDuration(d);
            const bestQuote = quotes?.find((q) => isStrategyDurationSame(q, d));

            const totalDebt = bestQuote
                ? getSimpleInterest({
                      principal: newPrincipalAmount,
                      subtractPrincipal: false,
                      apy: bestQuote.apy,
                      loanDuration: getDurationInSeconds(bestQuote)
                  })
                : 0;
            const principalUsd = bsMath.mul(totalDebt, loanExpanded?.principalUsdPrice) ?? 0;
            const collateralUsd = getLoanCollateralUsdValue(loanExpanded);

            const healthRatio = calculateHealthRatio(principalUsd, collateralUsd, bestQuote?.liquidationThreshold);
            const ltv = principalUsd / collateralUsd;
            return { loanEndDate, bestQuote, totalDebt, strategyDuration: d, healthRatio, ltv };
        });
    }, [loanExpanded, newPrincipalAmount, quotes, strategyDurations]);

    const optionsWithQuotes = optionsWithOffsets?.filter((o) => !!o.bestQuote);
    const validOptions = optionsWithQuotes?.filter((o) => !!o.bestQuote);
    const option = optionState ?? validOptions?.[0];
    const refinance = useRefinance(loanExpanded);

    if (validOptions?.length === 0) {
        return (
            <EmptyPlaceholder
                header="No refinance options"
                description={
                    optionsWithQuotes?.length
                        ? "Your loan health is too low to be refinanced"
                        : "There aren't any offers matching your loan terms"
                }
            />
        );
    }

    return (
        <>
            <LabelWrapper label="Extend until">
                <Select
                    renderValue={option ? () => <RenderDuration option={option} /> : undefined}
                    loading={!validOptions}
                    options={
                        optionsWithOffsets?.map((option) => ({
                            disabled: !option.bestQuote,
                            value: serializeStrategyDuration(option.strategyDuration),
                            label: (
                                <Row spaceBetween sx={{ width: "100%", height: "30px" }}>
                                    <RenderDuration option={option} />
                                    <Text> {formatPercent(option.bestQuote?.apy)} APY </Text>
                                </Row>
                            )
                        })) ?? Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> })
                    }
                    value={option ? serializeStrategyDuration(option?.strategyDuration) : ""}
                    setValue={(strategyDuration) => {
                        const option = optionsWithQuotes?.find(
                            (o) => serializeStrategyDuration(o.strategyDuration) === strategyDuration
                        );
                        if (!option) return;
                        setOption(option);
                    }}
                />
            </LabelWrapper>
            <Stats loanExpanded={loanExpanded} option={option} principalAmount={newPrincipalAmount} />
            <AppButton
                isTransaction
                disabled={!newPrincipalAmount || !option}
                asyncCta={{ onClickWithResult: () => refinance(newPrincipalAmount, option), closeDialog: "onSuccess" }}
            >
                Refinance
            </AppButton>
        </>
    );
}

function RenderDuration({ option: { strategyDuration, loanEndDate } }: { option: RefinanceOption }) {
    return (
        <Row spacing={1}>
            <Text>{formatDurationWithType(strategyDuration)}</Text>
            <Text color="caption">{formatDate(loanEndDate, "date", { showTime: "never" })}</Text>
        </Row>
    );
}

function Stats({
    loanExpanded,
    principalAmount,
    option
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    principalAmount: number;
    option: RefinanceOption | undefined;
}) {
    const { health: currentHealth } = calculateLoanHealth(loanExpanded);
    const transactionFeeStat = useTransactionFeeStat({ transactionType: TransactionTypes.Refinance });

    const stats = useMemo(
        (): StatProps[] => [
            {
                value: [formatPercent(loanExpanded?.borrowerWAvgApy), formatPercent(option?.bestQuote?.apy)],
                caption: "APY"
            },
            {
                value: formatDate(option?.loanEndDate),
                caption: "New due date"
            },
            {
                value: [
                    BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, principalAmount, { hideSymbol: true }),
                    BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, option?.totalDebt)
                ],
                caption: "Total debt"
            },
            {
                value: <HealthChange currentHealth={currentHealth} previousHealth={option?.healthRatio} />,
                tooltip: COPY.HEALTH_FACTOR_TOOLTIP,
                caption: COPY.HEALTH_FACTOR_TERM
            },
            transactionFeeStat
        ],
        [
            currentHealth,
            loanExpanded?.borrowerWAvgApy,
            loanExpanded?.principalMetadata,
            option?.bestQuote?.apy,
            option?.healthRatio,
            option?.loanEndDate,
            option?.totalDebt,
            principalAmount,
            transactionFeeStat
        ]
    );

    return <StatColumn loading={!option} stats={stats} />;
}

function useRefinance(loanExpanded: AbfLoanExpanded | undefined) {
    const send = useTransactionSender();
    const refinanceLoan = useLoanRefinanceTransaction();

    const [fetchLoans] = marketsApi.useLazyLoanInfosQuery();
    const location = useLocation();
    const navigate = useAppNavigate();

    return async (principalAmount: number, option: RefinanceOption | undefined) => {
        if (!loanExpanded || !option?.bestQuote || !principalAmount) return Result.errFromMessage(LOADING_ERROR);

        const quote = option.bestQuote;

        const durationIndex = getDurationIndex({ duration: quote.duration, durationType: quote.durationType });

        if (durationIndex === undefined) return Result.errFromMessage(LOADING_ERROR);

        const firstLedger = getZcLedger(loanExpanded);
        const firstCollateral = getZcCollateral(loanExpanded);

        if (!firstLedger?.principalMint || !firstCollateral) return Result.errFromMessage(LOADING_ERROR);

        const params: RefinanceLoanTxnParams = {
            newStrategy: quote.lendingStrategyAddress,
            principalMint: firstLedger.principalMint,
            oldStrategy: firstLedger.strategy,
            loan: loanExpanded.loan.address,
            refinanceParams: {
                durationIndex,
                ledgerIndex: 0 //TODO: Handle multiple ledger index input after multiple collateral is supported
            }
        };

        // navigate to new refinanced loan only if on loan detail page
        async function onSuccess() {
            if (!loanExpanded || !location.pathname.includes(LOAN_SLUG) || !firstLedger?.principalMint) return;
            const loans = await fetchLoans({
                borrowers: [loanExpanded?.borrower],
                principalMints: [loanExpanded?.principalMetadata.mint],
                filterType: LoanFilterType.Active,
                loanAddresses: [loanExpanded.loan.address],
                lenders: [],
                sortSide: SortDirection.Asc,
                sortType: LoanInfoSorting.NextPayment,
                orderFundingTypes: [loanExpanded.loan.loanType]
            });
            if (!loans.data) return;
            const lastLoan = findMaxElement(Object.values(loans.data.loanInfos), (v) => v.loan.id);

            if (!lastLoan) return;
            navigate(getLoanAssetPath(lastLoan.loan));
        }

        return await send(refinanceLoan, params, {
            description: "Refinancing loan",
            sendOptions: { onSuccess },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitRefinance,
                params: trackSubmitRefinance(params, loanExpanded, 0)
            }
        });
    };
}
