import { isLstLoop } from "@bridgesplit/abf-react";
import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { Text } from "@bridgesplit/ui";

import { useLoopContext } from "../LoopContext";

const messages = {
    [LoopRouteType.Meteora]:
        "Setting up your position may include a small fee depending on the ratio of assets in the pool.",
    [LoopRouteType.Jup]: "Setting up your position includes a small Jupiter swap fee.",
    [LoopRouteType.Stabble]:
        "Setting up your position may include a small fee depending on the ratio of assets in the pool.",
    [LoopRouteType.Exponent]: "Setting up your position includes a Jupiter swap fee and an Exponent swap fee.",
    [LoopRouteType.Flash]: "Setting up your position includes a mint fee on flash.",
    [LoopRouteType.Adrena]: "Setting up your position includes a mint fee on Adrena.",
    [LoopRouteType.Ratex]: "Setting up your position includes a Jupiter swap fee and a RateX swap fee."
} as const;

export default function WindRiskMessage() {
    const { loopExpanded } = useLoopContext();

    if (!loopExpanded) {
        return null;
    }

    if (isLstLoop(loopExpanded)) {
        // message is handled in WindNestedStats
        return null;
    }

    const message = loopExpanded?.loopVault.routeType && messages[loopExpanded.loopVault.routeType];
    if (message) {
        return (
            <Text variant="body2" color="caption">
                {message} Closing positions early may result in a loss
            </Text>
        );
    }
    return null;
}
