import { createContext, useCallback, useContext, useMemo, useState } from "react";

import {
    LendingVaultExpanded,
    UserVaultPositionExpanded,
    DenominationDisplay,
    useUserVaultPositionByVault
} from "@bridgesplit/abf-react";
import { DispatchType } from "@bridgesplit/react";

const DEFAULT_SLIPPAGE = 0.001; // 0.1% default slippage. Allow high slippage on the LP token amount

interface VaultContextProps {
    vaultAddress: string | undefined;
    vaultExpanded: LendingVaultExpanded | undefined;
}

interface VaultContextState {
    vaultAddress: string | undefined;
    vaultExpanded: LendingVaultExpanded | undefined;
    userPosition: UserVaultPositionExpanded | undefined;
    userPositionLoading: boolean;
    isActionOpen: boolean;
    setIsActionOpen: DispatchType<boolean>;

    setSelectedDenomination: DispatchType<DenominationDisplay>;
    selectedDenomination: DenominationDisplay;

    slippageSetting: number;
    setSlippageSetting: DispatchType<number>;
    resetSlippageSetting: () => void;
}

const VaultContext = createContext<VaultContextState | null>(null);

export function useVaultContext(): VaultContextState {
    const context = useContext(VaultContext);
    if (context === null) {
        throw new Error("useVaultContext must be used within a VaultProvider");
    }
    return context;
}

export function VaultProvider({
    children,
    ...props
}: {
    children: React.ReactNode;
} & VaultContextProps) {
    const [isActionOpen, setIsActionOpen] = useState(false);
    const [selectedDenomination, setSelectedDenomination] = useState<DenominationDisplay>(
        DenominationDisplay.PrincipalBasis
    );

    const [slippageSetting, setSlippageSetting] = useState<number>(DEFAULT_SLIPPAGE);

    const resetSlippageSetting = useCallback(() => {
        setSlippageSetting(DEFAULT_SLIPPAGE);
    }, [setSlippageSetting]);
    const { data: userPositions, isLoading: userPositionLoading } = useUserVaultPositionByVault(props.vaultExpanded);

    const userPosition: UserVaultPositionExpanded | undefined = useMemo(() => {
        if (!props.vaultExpanded || userPositionLoading) return undefined;
        return userPositions?.find((p) => p.vaultExpanded.vault.address === props.vaultExpanded?.vault.address);
    }, [userPositions, props.vaultExpanded, userPositionLoading]);

    return (
        <VaultContext.Provider
            value={{
                ...props,
                isActionOpen,
                setIsActionOpen,
                userPosition,
                selectedDenomination,
                setSelectedDenomination,
                slippageSetting,
                setSlippageSetting,
                resetSlippageSetting,
                userPositionLoading
            }}
        >
            {children}
        </VaultContext.Provider>
    );
}
