export interface AbfUserIdentifier {
    wallet?: string;
}
export interface AbfUserWithPermissionsAndWallets {
    wallet: string | undefined;
    beta_lend_access: boolean;
    beta_borrow_access: boolean;
}

export enum PrimeRole {
    Default = 0,
    Prime = 1,
    Accredited = 2,
    USBased = 3,
    Admin = 4
}

export enum AccreditedStatus {
    Yes = "Yes",
    No = "No"
}

export interface PrimeRoleStatusParams {
    jurisdiction: string;
    roleIds: PrimeRole[];
}

export interface AbfOnchainRole {
    id: number;
    address: string;
    user: string;
}

export interface AbfUserWalletVerification {
    is_verified: boolean;
}

export interface AbfUserWallet {
    wallet: string;
}

export enum RoleView {
    Borrow = "Borrow",
    Lend = "Lend"
}

export interface PrimeApplicationData {
    id: number;
    userIdentifier: string;
    jurisdiction: string;
    appliedDate: number;
    approvedDate: number;
}

export interface UserBetaAccessResponse {
    hasBetaAccess: boolean;
}
