import { ReactNode } from "react";

import MarketStats from "./MarketStats";
import TokenHeader from "./TokenHeader";
import MarketPositions from "./MarketPositions";
import { RatesComparison, RatesOrderbook } from "../common";
import MarketLayoutMobile from "./MarketLayoutMobile";
import { Page } from "../../wrappers";

export default function MarketDetailMobile({ card }: { card: ReactNode }) {
    return (
        <Page>
            <MarketLayoutMobile
                header={<TokenHeader isMobile />}
                quotes={
                    <>
                        <MarketStats />
                        <RatesComparison isMobile />
                        <RatesOrderbook />
                        <MarketPositions />
                    </>
                }
                card={card}
            />
        </Page>
    );
}
