import { createPhantom } from "@phantom/wallet-sdk";

import Routes from "./components/Routes";
import { AppWrapper, Providers } from "./components/wrappers";
import { initMixpanel } from "./hooks";

initMixpanel();
// Initialize Phantom embedded wallet
if (typeof window !== "undefined") {
    createPhantom({
        zIndex: 10000,
        colorScheme: "light",
        hideLauncherBeforeOnboarded: false,
        paddingRight: 20,
        paddingBottom: 20
    });
}

export function App() {
    return (
        <Providers>
            <AppWrapper>
                <Routes />
            </AppWrapper>
        </Providers>
    );
}

export default App;
