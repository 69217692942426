import { useCallback, useMemo } from "react";

import {
    OriginationCapStatus,
    serializeStrategyDuration,
    useMarketOracleInfos,
    useTokenListMetadataByMints,
    VaultUpdateActionType
} from "@bridgesplit/abf-react";
import { filterNullableRecord, filterTruthy, filterUndefined, NullableRecord } from "@bridgesplit/utils";
import { OracleType } from "@bridgesplit/abf-sdk";

import {
    SettingsChange,
    VaultLtvUpdate,
    VaultSummarizedMissingTerms,
    VaultSummarizedTerms,
    VaultSummarizedUpdate
} from "./type";
import { useVaultContext } from "../../VaultContext";

export function useSummarizedVaultPendingChanges() {
    const { vaultExpanded } = useVaultContext();

    const newMints = vaultExpanded?.pendingTerms.map(
        (update) => update.update[VaultUpdateActionType.AddCollateral]?.collateralTerms.mint
    );

    // Fetch new metadata for assets not in previous terms
    const { getMetadata } = useTokenListMetadataByMints(newMints);

    const oracleAccountToMint: Map<string, string> | undefined = useMemo(() => {
        if (!vaultExpanded?.ltvInfos) return undefined;

        const entries = Object.entries(vaultExpanded.ltvInfos).flatMap(([collateralMint, info]) => {
            return Object.values(info.durationToLtvInfo)
                .map((ltvInfo) => {
                    if (!ltvInfo.marketInfo?.oracleAccount) return null;
                    return [ltvInfo.marketInfo.oracleAccount, collateralMint] as const;
                })
                .filter(filterTruthy);
        });

        return new Map(entries);
    }, [vaultExpanded]);

    const getCollateralMint = useCallback(
        (oracleAccount: string | undefined) => (oracleAccount ? oracleAccountToMint?.get(oracleAccount) : undefined),
        [oracleAccountToMint]
    );

    return useMemo(() => {
        if (!vaultExpanded) return undefined;

        const allTermUpdates: VaultLtvUpdate[] = [];

        vaultExpanded.pendingTerms.forEach(({ update, executionTimestamp }) => {
            const addCollateral = update[VaultUpdateActionType.AddCollateral];
            if (addCollateral) {
                const { updateMarketInformationParams } = addCollateral;
                allTermUpdates.push({
                    collateralMint: updateMarketInformationParams.assetIdentifier,
                    ltvInfo: {
                        principalMint: updateMarketInformationParams.assetIdentifier,
                        ...updateMarketInformationParams
                    },
                    type: VaultUpdateActionType.AddCollateral,
                    executionTimestamp
                });
            }
            const updateLtv = update[VaultUpdateActionType.UpdateLtv];
            if (updateLtv) {
                const oracleAccount = updateLtv.oracleAccount;
                const collateralMint = getCollateralMint(oracleAccount);
                if (updateLtv.ltv && updateLtv.liquidationThreshold && collateralMint) {
                    allTermUpdates.push({
                        collateralMint,
                        ltvInfo: {
                            principalMint: collateralMint,
                            ltv: updateLtv.ltv,
                            liquidationThreshold: updateLtv.liquidationThreshold
                        },
                        type: VaultUpdateActionType.UpdateLtv,
                        executionTimestamp
                    });
                }
            }
        });

        return allTermUpdates
            .map((update): NullableRecord<VaultSummarizedUpdate> => {
                const originalTerms = vaultExpanded?.ltvInfos?.[update.collateralMint];
                return {
                    ...update,
                    key: update.collateralMint + update.executionTimestamp + update.type,
                    originalTerms: originalTerms ?? null,
                    collateralMetadata: originalTerms?.collateralMetadata ?? getMetadata(update.collateralMint)
                };
            })
            .filter(filterNullableRecord);
    }, [getCollateralMint, getMetadata, vaultExpanded]);
}

export function useSummarizedVaultPendingSettingsChanges() {
    const { vaultExpanded } = useVaultContext();

    return useMemo(() => {
        if (!vaultExpanded) return undefined;

        return vaultExpanded.pendingTerms
            .map(({ update, executionTimestamp }) => {
                const settings = update[VaultUpdateActionType.UpdateStrategySettings];
                if (!settings) return undefined;

                const strategy = vaultExpanded.vaultStrategy;
                const changes: SettingsChange = {};

                if (
                    strategy.externalYieldInfo?.externalYieldSource !== undefined &&
                    settings.externalYieldSourceArgs?.newExternalYieldSource !== undefined &&
                    settings.externalYieldSourceArgs.newExternalYieldSource !==
                        strategy.externalYieldInfo?.externalYieldSource
                ) {
                    changes.externalYieldSource = [
                        strategy.externalYieldInfo.externalYieldSource,
                        settings.externalYieldSourceArgs.newExternalYieldSource
                    ];
                }

                if (settings.interestFee !== undefined && settings.interestFee !== strategy.strategy.interestFee) {
                    changes.interestFee = [strategy.strategy.interestFee, settings.interestFee];
                }

                if (
                    settings.liquidityBuffer !== undefined &&
                    settings.liquidityBuffer !== strategy.strategy.liquidityBuffer
                ) {
                    changes.liquidityBuffer = [strategy.strategy.liquidityBuffer, settings.liquidityBuffer];
                }

                if (
                    settings.originationCap !== undefined &&
                    settings.originationCap !== strategy.strategy.originationCap
                ) {
                    changes.originationCap = [strategy.strategy.originationCap, settings.originationCap];
                }

                if (
                    settings.originationsEnabled !== undefined &&
                    settings.originationsEnabled !== strategy.strategy.originationsEnabled
                ) {
                    changes.originationsEnabled = [
                        strategy.strategy.originationsEnabled
                            ? OriginationCapStatus.Accepting
                            : OriginationCapStatus.Closed,
                        settings.originationsEnabled ? OriginationCapStatus.Accepting : OriginationCapStatus.Closed
                    ];
                }

                if (
                    settings.originationFee !== undefined &&
                    settings.originationFee !== strategy.strategy.originationFee
                ) {
                    changes.originationFee = [strategy.strategy.originationFee, settings.originationFee];
                }

                return Object.keys(changes).length > 0 ? { executionTimestamp, changes } : undefined;
            })
            .filter(filterUndefined);
    }, [vaultExpanded]);
}

export function useSummarizedVaultTerms(): VaultSummarizedTerms[] | undefined {
    const { vaultExpanded } = useVaultContext();
    const { getMetadata } = useTokenListMetadataByMints(Object.keys(vaultExpanded?.vaultStrategy.terms ?? {}));

    if (!vaultExpanded) return undefined;

    const summarizedTerms: VaultSummarizedTerms[] = [];

    //get the terms from the vaultExpanded

    const terms = vaultExpanded.vaultStrategy.terms;

    for (const [mint, durationAndApys] of Object.entries(terms)) {
        const metadata = getMetadata(mint);
        if (!metadata) continue;

        for (const [duration, apy] of durationAndApys) {
            // Get the market info for the duration, instead of querying again, will use ltvInfos
            const key = serializeStrategyDuration(duration);
            const marketInfo = vaultExpanded.ltvInfos?.[mint]?.durationToLtvInfo[key]?.marketInfo;

            if (!marketInfo) continue;

            summarizedTerms.push({
                collateralMetadata: metadata,
                strategyDuration: duration,
                apy: apy,

                // From market information
                ltv: marketInfo?.ltv ?? 0,
                liquidationThreshold: marketInfo?.liquidationThreshold ?? 0,
                oracleAccount: marketInfo?.oracleAccount ?? "",
                oracleType: marketInfo?.oracleType ?? OracleType.PythLegacy
            });
        }
    }

    return summarizedTerms;
}

export function useSummarizeVaultMissingTerms(): { data: VaultSummarizedMissingTerms[]; isLoading: boolean } {
    const { vaultExpanded } = useVaultContext();

    const marketInformationAddress = vaultExpanded?.vaultStrategy.strategy.marketInformation;
    const { data: marketInformation, isLoading } = useMarketOracleInfos({
        filter: {
            addresses: marketInformationAddress ? [marketInformationAddress] : [],
            verified: false,
            principalMints: []
        },
        skip: !marketInformationAddress
    });
    const activeMints = Object.keys(vaultExpanded?.vaultStrategy.terms ?? {});

    const missingTerms: VaultSummarizedMissingTerms[] = [];

    // Filter out the principal mint from the market information
    const marketInformationWithoutPrincipal = marketInformation?.filter(({ ltvInfo }) =>
        ltvInfo.filter(({ assetIdentifier }) => assetIdentifier !== vaultExpanded?.vaultStrategy.strategy.principalMint)
    );
    for (const { ltvInfo } of marketInformationWithoutPrincipal ?? []) {
        for (const info of ltvInfo ?? []) {
            if (info?.assetIdentifier && !activeMints.includes(info.assetIdentifier)) {
                missingTerms.push({
                    marketInfo: info
                });
            }
        }
    }

    return {
        data: missingTerms,
        isLoading
    };
}
