import {
    BsMetaUtil,
    LoopscalePointsAction,
    useLoanMultipliersUtil,
    useRewardsPointsPerSecond
} from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { Column, Row, StatColumn, Text, Tooltip, Icon, StatProps } from "@bridgesplit/ui";
import { TokenImage } from "app/components/common";
import { Divider } from "@mui/material";
import { formatMultiplier } from "@bridgesplit/utils";

import { CaptionWithIcon, TooltipContent } from "./common";
import { calculateRelativeBoostedMultiplier, useBoostedPointsActions, useRewardsColor } from "./util";

interface RewardsCollateralLabelProps {
    metadata: TokenListMetadata;
}
interface RewardsCollateralLabelTooltipProps {
    metadata: TokenListMetadata;
    relativeBoostedMultipliers: Record<LoopscalePointsAction, number>;
}

export function RewardsCollateralLabel(props: RewardsCollateralLabelProps): JSX.Element | null {
    const { metadata } = props;
    const { loanMultipliers, isLoading } = useLoanMultipliersUtil();
    const { rewardPointsPerSecond, isLoading: isRelativePointsLoading } = useRewardsPointsPerSecond();

    const boostedLoopscalePointsActions = useBoostedPointsActions(loanMultipliers, metadata.mint);

    const relativeBoostedMultipliers = calculateRelativeBoostedMultiplier(
        rewardPointsPerSecond,
        loanMultipliers,
        metadata?.mint,
        boostedLoopscalePointsActions
    );

    const color = useRewardsColor(loanMultipliers, metadata?.mint, boostedLoopscalePointsActions);

    if (isLoading || isRelativePointsLoading || boostedLoopscalePointsActions.length === 0) return null;

    return (
        <Tooltip
            reverseColors
            title={<RewardsCollateralLabelTooltip relativeBoostedMultipliers={relativeBoostedMultipliers} {...props} />}
        >
            <Text sx={{ color }}>
                <Icon type="points" />
            </Text>
        </Tooltip>
    );
}

function RewardsCollateralHeader({ metadata }: RewardsCollateralLabelProps): JSX.Element {
    const symbol = BsMetaUtil.getSymbolUnique(metadata);
    return (
        <Column spacing={1}>
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="sm" />
                <Text>{symbol}</Text>
            </Row>
            <Text variant="body2" color="caption">
                {`${symbol} is receiving boosted Loopscale rewards`}
            </Text>
        </Column>
    );
}

function RewardsCollateralLabelTooltip(props: RewardsCollateralLabelTooltipProps): JSX.Element {
    return (
        <TooltipContent>
            <RewardsCollateralHeader {...props} />
            <Divider />
            <RewardsCollateralSummary
                metadata={props.metadata}
                relativeBoostedMultipliers={props.relativeBoostedMultipliers}
            />
        </TooltipContent>
    );
}

function RewardsCollateralSummary({
    relativeBoostedMultipliers,
    metadata
}: RewardsCollateralLabelTooltipProps): JSX.Element {
    const { loanMultipliers } = useLoanMultipliersUtil();
    const actions = Object.keys(relativeBoostedMultipliers).map((action) => Number(action) as LoopscalePointsAction);

    const color = useRewardsColor(loanMultipliers, metadata?.mint, actions);
    const stats = useCreateBoostedMultiplierStats(relativeBoostedMultipliers, color);

    return (
        <Column spacing={1}>
            <StatColumn stats={stats} spacing={1} />
        </Column>
    );
}

function useCreateBoostedMultiplierStats(
    relativeBoostedMultipliers: Record<LoopscalePointsAction, number>,
    color: string
): StatProps[] {
    const { rewardPointsPerSecond } = useRewardsPointsPerSecond();

    const sortedRelativeBoostedMultipliers = Object.entries(relativeBoostedMultipliers).sort((a, b) => {
        return b[1] - a[1];
    });

    const stats = sortedRelativeBoostedMultipliers.map(([action, value]) => {
        const actionKey = Number(action) as LoopscalePointsAction;
        const label = rewardPointsPerSecond[actionKey]?.rewardLabel;

        const stat = {
            caption: <CaptionWithIcon icon={<Icon type="points" />} iconColor={color} caption={`${label}`} />,
            value: formatMultiplier(value)
        };

        return stat;
    });

    return stats;
}
