import { useMemo, useEffect, useState, useCallback } from "react";

import { getUnixTs, roundAutoToDecimals } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import {
    useCommunityPointMultipliersQuery,
    useCommunityRewardsQuery,
    usePointMultipliersQuery,
    useRewardsQuery,
    useUserPointsQuery,
    useUserReferralInfoQuery,
    usePointsTotalForRewardQuery,
    useUserReferralPointsQuery,
    usePortfolioRewardsQuery
} from "../reducers";
import {
    MultiplierInfo,
    LoopscalePointsAction,
    PointsRewardType,
    PortfolioRewardFilter,
    UserPointsCalculationInfo,
    LedgerRewards,
    StrategyRewards
} from "../types";
import { useUser } from "./auth";
import {
    buildPointsMultiplierInfoMap,
    convertPointsRewardsResponseToPointMap,
    convertPointsRewardsToBaseMultiplierMap
} from "../utils/points";
import { BASE_PPS, BASE_REFFERAL_PPS } from "../constants";
import { useActiveWallet } from "./wallet";

export function useSkipPoints() {
    const { activeWallet } = useActiveWallet();

    return useMemo(() => !activeWallet, [activeWallet]);
}

export function useUserId() {
    const { data: user } = useUser();
    return user?.wallet ?? skipToken;
}

export function useRewards() {
    const { data, isLoading } = useRewardsQuery({ feVisible: true, activeOnly: true });

    const rewardToInfo = useMemoizedKeyMap(data, (t) => t.reward);

    function getRewardInfo(type: PointsRewardType) {
        return rewardToInfo?.get(type);
    }

    return { data, getRewardInfo, isLoading };
}

export function useUserReferral(options?: { skip?: boolean }) {
    const userId = useUserId();
    const skip = useSkipPoints();

    return useUserReferralInfoQuery(userId, {
        skip: skip || !!options?.skip
    });
}

export function usePointMultipliers() {
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();
    const { data, isLoading } = useCommunityPointMultipliersQuery(userId, { skip: skipIfUnauthenticated });

    const totalMultiplier = useMemo(() => {
        if (!data) return 1;
        return data.reduce((acc, curr) => acc * curr.multiplier, 1);
    }, [data]);

    return { data, totalMultiplier, isLoading };
}

export type UserPointsFunction = ReturnType<typeof useUserPoints>["getPointsAtTime"];
export function useUserPoints(options?: { skip?: number }) {
    // await for referral info or invite code endpoints before calling points to catch point creation endpoints
    const { activeWallet } = useActiveWallet();
    const userId = useUserId();

    const {
        data: basePoints,
        isLoading,
        isUninitialized
    } = useUserPointsQuery(userId, { skip: !activeWallet || !!options?.skip });

    const {
        data: referralPoints,
        isLoading: referralsLoading,
        isUninitialized: referralsUninitialized
    } = useUserReferralPointsQuery(userId, { skip: !activeWallet || !!options?.skip });

    const pointsData: UserPointsCalculationInfo | undefined = basePoints &&
        referralPoints && {
            basePoints,
            referralPoints
        };

    return {
        getPointsAtTime: (timestamp: number) => getPointsAtTime(pointsData, timestamp),
        isLoading: isUninitialized || isLoading || referralsLoading || referralsUninitialized
    };
}

export function usePortfolioRewards({
    portfolioRewardsFilter,
    skip = false
}: {
    portfolioRewardsFilter?: Partial<PortfolioRewardFilter>;
    skip?: boolean;
}) {
    const skipIfUnauthenticated = useSkipPoints();

    const filter: PortfolioRewardFilter = {
        loanAddresses: portfolioRewardsFilter?.loanAddresses ?? [],
        strategyAddresses: portfolioRewardsFilter?.strategyAddresses ?? [],
        stakeAddresses: portfolioRewardsFilter?.stakeAddresses ?? [],
        isActive: portfolioRewardsFilter?.isActive
    };

    const { data: portfolioRewards, isLoading } = usePortfolioRewardsQuery(filter, {
        skip: skipIfUnauthenticated || skip
    });

    const portfolioData = useMemo(() => {
        if (!portfolioRewards) return undefined;
        return portfolioRewards;
    }, [portfolioRewards]);
    return { data: portfolioData, isLoading };
}

function getPointsAtTime(userInfo: UserPointsCalculationInfo | undefined, now: number) {
    if (!userInfo?.basePoints || !userInfo?.referralPoints) {
        return undefined;
    }

    const { basePoints: rawBase, referralPoints: rawReferral } = userInfo;

    // Add null checks with default 0
    const basePPS = rawBase.pps ?? BASE_PPS;
    const referralPPS = rawReferral.pps ?? BASE_REFFERAL_PPS;

    const baseSecondsElapsed = Math.max(now - rawBase.timestamp, 0);
    const basePoints = rawBase.points + basePPS * baseSecondsElapsed;

    const referralSecondsElapsed = Math.max(now - rawReferral.timestamp, 0);
    const referralPoints = rawReferral.points + referralPPS * referralSecondsElapsed;

    const totalPoints = basePoints + referralPoints;

    return { basePoints, referralPoints, totalPoints };
}

export function useCurrentTime(pollingInterval = 50) {
    const [now, setNow] = useState<number>(getUnixTs());

    useEffect(() => {
        if (!pollingInterval) return;
        const interval = setInterval(() => {
            setNow(getUnixTs());
        }, pollingInterval);

        return () => clearInterval(interval);
    }, [pollingInterval]);

    return { now };
}

export function useCommunityRewards() {
    const userId = useUserId();
    return useCommunityRewardsQuery(userId ?? skipToken);
}

export function usePointsSumForReward(pointsReward: PointsRewardType) {
    const { getRewardInfo, isLoading: rewardLoading } = useRewards();
    const userId = useUserId();

    const { data: totalMultiplier, isLoading: multiplierLoading } = usePointsTotalForRewardQuery(
        typeof userId === "string"
            ? {
                  pointsReward,
                  userId
              }
            : skipToken
    );

    const totalPoints = useMemo(() => {
        const rewardInfo = getRewardInfo(pointsReward);
        if (!rewardInfo || !totalMultiplier) return 0;
        return rewardInfo.pointsPerSecond * totalMultiplier;
    }, [getRewardInfo, pointsReward, totalMultiplier]);

    return { totalPoints, isLoading: rewardLoading || multiplierLoading };
}

function usePointSources() {
    const {
        data: multiplierData,
        isLoading: isMultiplierLoading,
        error: isMultiplierError
    } = usePointMultipliersQuery();

    const { data: rewards, isLoading: isRewardsLoading } = useRewards();

    const isLoading = isMultiplierLoading || isRewardsLoading;
    const error = isMultiplierError;

    const processedData = useMemo(() => {
        // Important: Remove isLoading from this check as it might cause unnecessary blocks
        if (!multiplierData || !rewards) {
            return {
                multiplierInfoMap: undefined,
                basePointsPerSecondMap: undefined,
                baseActionMultiplierMap: undefined
            };
        }

        const result = {
            multiplierInfoMap: buildPointsMultiplierInfoMap(multiplierData, rewards),
            basePointsPerSecondMap: convertPointsRewardsResponseToPointMap(rewards),
            baseActionMultiplierMap: convertPointsRewardsToBaseMultiplierMap(rewards)
        };

        return result;
    }, [multiplierData, rewards]);

    return useMemo(
        () => ({
            ...processedData,
            isLoading,
            error
        }),
        [processedData, isLoading, error]
    );
}

// TODO: Remove this hook once stats is deprecated
export function usePointsSourcesUtil() {
    const { multiplierInfoMap, basePointsPerSecondMap, baseActionMultiplierMap, isLoading, error } = usePointSources();

    const getPointsPerSecondCallback = useCallback(
        (action: LoopscalePointsAction) => getPointsPerSecondByPointSource(action, basePointsPerSecondMap),
        [basePointsPerSecondMap]
    );

    const getCheckMultiplierGreaterThanBase = useCallback(
        (action: LoopscalePointsAction, metadata: TokenListMetadata[] | undefined) =>
            hasAnyPointSourceMultiplier(multiplierInfoMap, metadata, action),
        [multiplierInfoMap]
    );

    const getAssetActionsWithMultipliers = useCallback(
        (metadata: TokenListMetadata): LoopscalePointsAction[] => {
            const pointSourceMap = multiplierInfoMap?.[metadata.mint]?.pointSourceToAdditionalMultipliers;

            if (!pointSourceMap) {
                return [];
            }

            return Object.entries(pointSourceMap)
                .filter(([_, value]) => value !== 1)
                .map(([key]) => Number(key) as LoopscalePointsAction)
                .filter((value): value is LoopscalePointsAction =>
                    Object.values(LoopscalePointsAction).includes(value)
                );
        },
        [multiplierInfoMap]
    );

    return {
        multiplierInfoMap,
        basePointsPerSecondMap,
        baseActionMultiplierMap,
        getPointsPerSecondByPointSource: getPointsPerSecondCallback,
        getCheckMultiplierGreaterThanBase,
        getAssetActionsWithMultipliers,
        isLoading,
        error
    };
}

export function getPointsPerSecondByPointSource(
    pointSource: LoopscalePointsAction,
    pointsPerSecondMap: Record<LoopscalePointsAction, number> | undefined
): number | undefined {
    if (!pointSource || !pointsPerSecondMap) return undefined;
    return pointsPerSecondMap[pointSource];
}

// Checks if a collateral mint has a specific point source type
export function getNormalizedPointsByMint(
    sources: Record<string, MultiplierInfo> | undefined,
    metadata: TokenListMetadata[] | undefined,
    pointSource: LoopscalePointsAction
): number[] {
    if (!sources || !metadata?.length) {
        return [];
    }

    return metadata.map((meta) => {
        if (!meta?.mint || !sources[meta.mint]) {
            return 0;
        }
        return sources[meta.mint].pointSourceToAdditionalMultipliers[pointSource] ?? 0;
    });
}

export function hasAnyPointSourceMultiplier(
    sources: Record<string, MultiplierInfo> | undefined,
    metadata: TokenListMetadata[] | undefined,
    action: LoopscalePointsAction
): boolean {
    if (!sources || !metadata) {
        return false;
    }

    return metadata.some((m) => sources[m.mint] && sources[m.mint].pointSourceToAdditionalMultipliers[action] !== 1);
}

type Mint = string;
interface LoanMultiplierInfo {
    name: string;
    multiplier: number;
}

export interface LoanMultiplier {
    [key: Mint]: Record<LoopscalePointsAction, LoanMultiplierInfo>;
}

function useLoanMultipliers() {
    const { data: loanMultipliers, isLoading } = usePointMultipliersQuery();

    const loanMultiplier = useMemo(() => {
        if (!loanMultipliers) {
            return undefined;
        }

        // Group multipliers by mint
        const loanMultiplier = loanMultipliers.reduce<LoanMultiplier>((acc, multiplier) => {
            const { mint, name, reward, multiplier: value } = multiplier;

            if (!acc[mint]) {
                acc[mint] = {} as Record<LoopscalePointsAction, LoanMultiplierInfo>;
            }

            acc[mint][reward] = {
                name,
                multiplier: value
            };

            return acc;
        }, {});

        return loanMultiplier;
    }, [loanMultipliers]);

    return { loanMultipliers: loanMultiplier, isLoading };
}
export function useLoanMultipliersUtil() {
    const { loanMultipliers, isLoading } = useLoanMultipliers();

    return { loanMultipliers, isLoading };
}

type RewardPointsPerSecond = Record<
    LoopscalePointsAction,
    { pointsPerSecond: number; relativePointsPerSecond: number; rewardLabel: string }
>;

export function useRewardsPointsPerSecond() {
    const { data: rewards, isLoading } = useRewards();

    const rewardPointsPerSecond = useMemo(() => {
        if (!rewards) return {} as RewardPointsPerSecond;

        const pointsPerSecondMap = rewards.reduce<Record<LoopscalePointsAction, number>>((acc, reward) => {
            const rewardValue = reward.reward as LoopscalePointsAction;
            if (Object.values(LoopscalePointsAction).includes(rewardValue)) {
                acc[rewardValue] = reward.pointsPerSecond;
            }
            return acc;
        }, {} as Record<LoopscalePointsAction, number>);

        const idleLendPps = pointsPerSecondMap[LoopscalePointsAction.IdleCap] || 1;

        return rewards.reduce<RewardPointsPerSecond>((acc, reward) => {
            const rewardValue = reward.reward as LoopscalePointsAction;
            if (Object.values(LoopscalePointsAction).includes(rewardValue)) {
                acc[rewardValue] = {
                    pointsPerSecond: reward.pointsPerSecond,
                    relativePointsPerSecond: roundAutoToDecimals(reward.pointsPerSecond / idleLendPps, 0),
                    rewardLabel: reward.feRewardLabel
                };
            }
            return acc;
        }, {} as RewardPointsPerSecond);
    }, [rewards]);

    return { rewardPointsPerSecond, isLoading };
}

// Returns all portfolio rewards for active user positions. Used for daily points accrual calculations
export function useActivePortfolioRewards() {
    const portfolioRewards = usePortfolioRewards({ portfolioRewardsFilter: { isActive: true } });
    return portfolioRewards;
}

// Returns all portfolio rewards including non active positions
export function useHistoricalPortfolioRewards() {
    const portfolioRewards = usePortfolioRewards({ portfolioRewardsFilter: {}, skip: true });
    return portfolioRewards;
}

// Custom hook to get portfolio rewards by loan addresses. Used for loan cards
export function usePortfolioRewardsByLoans(loanAddresses?: string[]) {
    const filter = loanAddresses?.length
        ? {
              loanAddresses,
              strategyAddresses: [],
              stakeAddresses: [],
              isActive: true
          }
        : undefined;

    return usePortfolioRewards({ portfolioRewardsFilter: filter });
}

export function usePortfolioRewardsUtils() {
    const { data: portfolioRewards } = useActivePortfolioRewards();

    /**
     * Returns reward data for a specific loan
     * @param loanAddress The address of the loan to check
     * @returns The reward data for the loan, or undefined if not found
     */
    const getLoanRewards = (loanAddress: string | undefined): LedgerRewards | undefined => {
        const loanRewards = loanAddress ? portfolioRewards?.loans?.[loanAddress] : undefined;
        return loanRewards;
    };

    /**
     * Returns reward data for a specific strategy
     * @param strategyAddress The address of the strategy to check
     * @returns The reward data for the strategy, or undefined if not found
     */
    const getStrategyRewards = (strategyAddress: string | undefined): StrategyRewards | undefined => {
        const strategyReward = strategyAddress ? portfolioRewards?.strategies?.[strategyAddress] : undefined;
        return strategyReward;
    };

    /**
     * Returns reward data for a specific stake
     * @param stakeAddress The address of the stake to check
     * @returns The reward data for the stake, or undefined if not found
     */
    const getStakeRewards = (stakeAddress: string | undefined): LedgerRewards | undefined => {
        const stakeRewards = stakeAddress ? portfolioRewards?.vaultStakes?.[stakeAddress] : undefined;
        return stakeRewards;
    };

    return {
        getLoanRewards,
        getStrategyRewards,
        getStakeRewards,
        isLoading: !portfolioRewards
    };
}
