import { ReactNode } from "react";

import { ExternalPointReward } from "@bridgesplit/abf-react";
import {
    Column,
    Row,
    RewardImage,
    JLP_LOGO,
    METEORA_SVG,
    SOLANA_HUB_LOGO,
    SOLAYER_LOGO,
    ADRASTEA_LOGO,
    Text,
    Icon,
    TagText
} from "@bridgesplit/ui";

export function ExternalPointRewardStat({ externalPointReward }: { externalPointReward: ExternalPointReward }) {
    const rewardTitle = getExternalPointsRewardCaption(externalPointReward);

    return (
        <Column spacing={1} sx={{ width: "100%" }}>
            <Row spacing={1}>
                <Text color="caption">{getExternalPointsRewardIcon(externalPointReward)}</Text>
                <Text color="caption">Points</Text>
                <TagText color="disabled">{rewardTitle}</TagText>
            </Row>
        </Column>
    );
}

export function getExternalPointsRewardIcon(source: ExternalPointReward): ReactNode {
    switch (source) {
        case ExternalPointReward.MarginFi:
            return <Icon type="margin-fi" />;
        case ExternalPointReward.Jupiter:
            return <RewardImage src={JLP_LOGO} />;
        case ExternalPointReward.Meteora:
            return <RewardImage src={METEORA_SVG} />;
        case ExternalPointReward.HubSol:
            return <RewardImage src={SOLANA_HUB_LOGO} />;
        case ExternalPointReward.Solayer:
            return <RewardImage src={SOLAYER_LOGO} />;
        case ExternalPointReward.Adrastea:
            return <RewardImage src={ADRASTEA_LOGO} />;
    }
}

function getExternalPointsRewardCaption(externalPointReward: ExternalPointReward): string {
    switch (externalPointReward) {
        case ExternalPointReward.MarginFi:
            return "mrgn rewards";
        case ExternalPointReward.Jupiter:
            return "jup rewards";
        case ExternalPointReward.Meteora:
            return "meteora rewards";
        case ExternalPointReward.HubSol:
            return "Loyalty sol rewards";
        case ExternalPointReward.Solayer:
            return "Solayer rewards";
        case ExternalPointReward.Adrastea:
            return "Adrastea rewards";
    }
}
