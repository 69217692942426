export enum ExternalYieldSource {
    None = 0,
    MarginFi = 1
}

export interface ExternalYieldInfo {
    externalYieldSource: ExternalYieldSource;
    balance: number;
    apy: number;
    yieldAccount: string;
}

export interface ExternalYieldVault {
    id: number;
    yieldSourceVault: string;
    principalMint: string;
    yieldSource: ExternalYieldSource;
    totalAssetShares: number;
    valuePerShare: number;
    apy: number;
    lastUpdateTime: number;
}
