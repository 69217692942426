import { useState } from "react";

import {
    LoopscalePointsAction,
    UserVaultPositionExpanded,
    LendingVaultExpanded,
    StakeStatus,
    usePortfolioRewardsUtils,
    getVaultExternalPointRewards
} from "@bridgesplit/abf-react";
import { MediaQuery, Row, ShadowCard, Text, ToggleChevron, Column, Button, SPACING, Image } from "@bridgesplit/ui";
import { Box, Collapse, useMediaQuery } from "@mui/material";
import { TokenSize, getTokenImageSize, TokenImage, getLendingVaultPath } from "app/components/common";
import { AppDialog, useAppDialog } from "app/utils";
import { RewardsPortfolioApy } from "app/components/points";
import { calculateLendVaultDailyPoints } from "app/components/portfolio/stats";
import { formatUsd } from "@bridgesplit/utils";
import { useNavigate } from "react-router-dom";

import { VaultPositionStats } from "./VaultPositionStats";

export function VaultPositionCard({
    vaultPosition,
    query,
    status
}: {
    vaultPosition: UserVaultPositionExpanded | undefined;
    query: MediaQuery;
    status?: StakeStatus;
}) {
    const { open } = useAppDialog();
    const navigate = useNavigate();

    const [expandedState, setExpanded] = useState(false);

    const vaultExpanded = vaultPosition?.vaultExpanded;

    const isMobile = useMediaQuery(query.below);

    const expanded = expandedState || isMobile;

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding={false}>
            <Row
                sx={{ p: 2, cursor: vaultPosition ? "pointer" : undefined }}
                onClick={vaultPosition ? () => setExpanded((prev) => !prev) : undefined}
                spaceBetween
            >
                <Row spacing={1}>
                    <LendVaultImage lendVaultExpanded={vaultExpanded} size="md" />
                    <Text loadingWidth="100px" loading={!vaultPosition}>
                        {vaultExpanded?.vaultMetadata?.name}
                    </Text>
                    <Text loadingWidth="55px" loading={!vaultPosition} color="caption">
                        {formatUsd(vaultPosition?.totalAmount.amountUsd)}
                    </Text>
                </Row>

                <Row sx={{ [query.below]: { display: "none" } }} spacing={1}>
                    {vaultPosition ? (
                        vaultPosition.totalAmount.amountPrincipal !== null ? (
                            <VaultPositionApy vaultPosition={vaultPosition} />
                        ) : (
                            <Text color="disabled"> Closed </Text>
                        )
                    ) : null}
                    <ToggleChevron disabled={!vaultPosition} textVariant="h2" expanded={expanded} />
                </Row>
            </Row>

            <Collapse in={expanded}>
                <Column sx={{ p: 2, pt: 0 }} spacing={2}>
                    <VaultPositionStats vaultPosition={vaultPosition} query={query} status={status} />
                    <Row spacing={1}>
                        {vaultPosition?.active && (
                            <Button
                                sx={{ [query.below]: { width: "100%" } }}
                                onClick={() =>
                                    !!vaultExpanded &&
                                    open(AppDialog.VaultManageEscrow, { vaultExpanded: vaultExpanded, side: "deposit" })
                                }
                                variant="contained"
                            >
                                Deposit/Withdraw
                            </Button>
                        )}
                        <Button
                            sx={{ [query.below]: { width: "100%" } }}
                            onClick={() => navigate(getLendingVaultPath(vaultExpanded))}
                            variant="outlined"
                        >
                            View vault
                        </Button>
                    </Row>
                </Column>
            </Collapse>
        </ShadowCard>
    );
}

export function VaultPositionApy({ vaultPosition }: { vaultPosition: UserVaultPositionExpanded | undefined }) {
    const metadata = vaultPosition?.vaultExpanded.principalMetadata;
    const { getStakeRewards } = usePortfolioRewardsUtils();

    const apy = vaultPosition?.vaultExpanded.vaultStrategy.strategy.wAvgApy;

    const stakeRewards = getStakeRewards(vaultPosition?.address);
    const customDailyPoints = calculateLendVaultDailyPoints(stakeRewards);
    const externalPointSources = getVaultExternalPointRewards(vaultPosition?.vaultExpanded);

    return (
        <RewardsPortfolioApy
            externalPointRewards={externalPointSources}
            loopscalePointsAction={LoopscalePointsAction.LiquidStake}
            metadata={metadata}
            apy={apy}
            customDailyPoints={customDailyPoints}
        />
    );
}

function LendVaultImage({
    lendVaultExpanded,
    size: propsSize,
    offset = -0.25,
    badgeSize = "sm"
}: {
    lendVaultExpanded: LendingVaultExpanded | undefined;
    size: TokenSize;
    badgeSize?: TokenSize;
    offset?: number;
}) {
    const size = getTokenImageSize(propsSize);

    const [primaryToken, secondaryToken] = [
        lendVaultExpanded?.principalMetadata,
        lendVaultExpanded?.vaultMetadata?.managerImage
    ];

    return (
        <Box sx={{ position: "relative", width: size, height: size }}>
            <TokenImage
                size={badgeSize}
                sx={{ position: "absolute", bottom: SPACING * offset, right: SPACING * offset, zIndex: 1 }}
                metadata={primaryToken}
            />
            <Image skeletonVariant="circular" variant="circle" size={size + "px"} src={secondaryToken} />
        </Box>
    );
}
