/**
 * These estimates are based on the average of tx simulations from personal testing.
 * We hardcode these fee values because we can't simulate balance changes unless we have the actual txns.
 * Meaning we would have to generate all the txns on page load, since we currently generate the txn on confirm button.
 * **/

import { BASE_FEE_LAMPORTS } from "@bridgesplit/utils";
import { TransactionFees } from "app/components/transactions";
import { TransactionTypes } from "app/types";

export const MAX_TX_COMPUTE = 1400000;
export const MAX_TX_UNIQUE_KEYS = 24;
export const FALLBACK_PRIORITY_FEE = 100000;
export const FALLBACK_IX_COMPUTE = 300000;
export const FALLBACK_IX_KEYS = 6;
export const HIGH_PRIORITY_FEE = 1000000;
export const MED_PRIORITY_FEE = 250000;
export const NETWORK_FEE_BUFFER = 459500;
export const DEFAULT_SLIPPAGE = 0.05;

// Borrow
export const AVG_BORROW_REFUNDED_RENT = 22540000;
export const AVG_BORROW_RENT = 10002000;
export const TOTAL_BORROW_FEE = AVG_BORROW_REFUNDED_RENT + AVG_BORROW_RENT + BASE_FEE_LAMPORTS;

// Lend
export const AVG_LEND_REFUNDED_RENT = 21934720;
export const AVG_LEND_RENT = 5952040;
export const TOTAL_LEND_FEE = AVG_LEND_REFUNDED_RENT + AVG_LEND_RENT + BASE_FEE_LAMPORTS;

//Refinance
export const AVG_REFINANCE_REFUNDED_RENT = 0;
export const AVG_REFINANCE_RENT = 15230000;
export const TOTAL_REFINANCE_FEE = AVG_REFINANCE_REFUNDED_RENT + AVG_REFINANCE_RENT + BASE_FEE_LAMPORTS;

//Borrow More
export const AVG_BORROW_MORE_REFUNDED_RENT = 2040000;
export const AVG_BORROW_MORE_RENT = 27650000;
export const TOTAL_BORROW_MORE_FEE = AVG_BORROW_MORE_REFUNDED_RENT + AVG_BORROW_MORE_RENT + BASE_FEE_LAMPORTS;

// Repay
export const AVG_REPAY_REFUNDED_RENT = 0;
export const AVG_REPAY_RENT = 6240000;
export const TOTAL_REPAY_FEE = AVG_REPAY_REFUNDED_RENT + AVG_REPAY_RENT + BASE_FEE_LAMPORTS;

// Loop Borrow
export const AVG_EARN_FIRST_SETUP_ACCOUNT = 10000000 + BASE_FEE_LAMPORTS;
export const AVG_EARN_REFUNDED_RENT = 20200000; //You get more then the rent, this its likley closing an ATA account
export const AVG_EARN_RENT = 7800000;
export const TOTAL_EARN_FEE = AVG_EARN_RENT + AVG_EARN_REFUNDED_RENT + BASE_FEE_LAMPORTS;

/**
 * The following events have no rent or refunded rent. Just the network fee, a priority fee and a buffer from the wallet.
 * - Topup
 * - Loan Withdraw
 * - Strategy Deposit
 * - Strategy Withdraw
 * **/

export const DEFAULT_TX_FEE = BASE_FEE_LAMPORTS + NETWORK_FEE_BUFFER;
export const ZERO_FEE = 0;

export type TransactionData = {
    [key in TransactionTypes]: TransactionFees;
};

// Currently no support for setupFees
export const TRANSACTION_FEE_ESTIMATES: TransactionData = {
    [TransactionTypes.MarketBorrow]: {
        rentAmount: AVG_BORROW_RENT,
        totalFeeAmount: TOTAL_BORROW_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_BORROW_REFUNDED_RENT,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.MarketLend]: {
        rentAmount: AVG_LEND_RENT,
        totalFeeAmount: TOTAL_LEND_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_LEND_REFUNDED_RENT,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.Refinance]: {
        rentAmount: AVG_REFINANCE_RENT,
        totalFeeAmount: TOTAL_REFINANCE_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_REFINANCE_REFUNDED_RENT,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.BorrowMore]: {
        rentAmount: AVG_BORROW_MORE_RENT,
        totalFeeAmount: TOTAL_BORROW_MORE_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_BORROW_MORE_REFUNDED_RENT,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.Repay]: {
        rentAmount: AVG_REPAY_RENT,
        totalFeeAmount: TOTAL_REPAY_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_REPAY_REFUNDED_RENT,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.Topup]: {
        rentAmount: ZERO_FEE,
        totalFeeAmount: DEFAULT_TX_FEE,
        networkAmount: DEFAULT_TX_FEE,
        refundAmount: ZERO_FEE,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.LoanWithdraw]: {
        rentAmount: ZERO_FEE,
        totalFeeAmount: DEFAULT_TX_FEE,
        networkAmount: DEFAULT_TX_FEE,
        refundAmount: ZERO_FEE,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.StrategyDeposit]: {
        rentAmount: ZERO_FEE,
        totalFeeAmount: DEFAULT_TX_FEE,
        networkAmount: DEFAULT_TX_FEE,
        refundAmount: ZERO_FEE,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.StrategyWithdraw]: {
        rentAmount: ZERO_FEE,
        totalFeeAmount: DEFAULT_TX_FEE,
        networkAmount: DEFAULT_TX_FEE,
        refundAmount: ZERO_FEE,
        setupAmount: ZERO_FEE
    },
    [TransactionTypes.EarnBorrow]: {
        rentAmount: AVG_EARN_RENT,
        totalFeeAmount: TOTAL_EARN_FEE,
        networkAmount: BASE_FEE_LAMPORTS,
        refundAmount: AVG_EARN_REFUNDED_RENT,
        setupAmount: AVG_EARN_FIRST_SETUP_ACCOUNT
    }
};
