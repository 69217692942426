import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";

import { filterUndefined } from "./primitives";
import { PERCENT_DECIMALS } from "../constants";
export function lamportsToUiAmount(rawAmount: number | undefined = 0, decimals: number | undefined) {
    if (!decimals) return rawAmount;
    return rawAmount / Math.pow(10, decimals);
}

export function stringLamportsToUiAmount(rawAmount: string | undefined, decimals: number | undefined) {
    return lamportsToUiAmount(rawAmount !== undefined ? parseInt(rawAmount) : undefined, decimals);
}

export function bigNumberStringToUiAmount(rawAmount: string | undefined, decimals: number | undefined) {
    return lamportsToUiAmount(rawAmount !== undefined ? new BN(rawAmount, "hex").toNumber() : undefined, decimals);
}

export enum RoundingType {
    UP = "UP",
    DOWN = "DOWN",
    ROUND = "ROUND"
}

export function uiAmountToLamports(
    uiAmount: number | undefined = 0,
    decimals: number | undefined,
    roundType: RoundingType = RoundingType.ROUND
) {
    if (!decimals) return uiAmount;
    const rawAmount = uiAmount * Math.pow(10, decimals);

    switch (roundType) {
        case RoundingType.UP:
            return Math.abs(Math.ceil(rawAmount));
        case RoundingType.DOWN:
            return Math.abs(Math.floor(rawAmount));
        default:
            return Math.abs(Math.round(rawAmount));
    }
}

export function uiPercentToBps(percent: number | undefined = 0, type: "cbps" | "bps" = "cbps") {
    return decimalsToBps(percentUiToDecimals(percent), type);
}

export function decimalsToBps(percent: number | undefined = 0, type: "cbps" | "bps" = "cbps") {
    const bpsDecimals = type === "cbps" ? 4 : 2;
    return Math.round(percent * 100 * 10 ** bpsDecimals);
}

export function bpsToPercent(bps: number | undefined = 0) {
    return percentDecimalsToUi(bpsToUiDecimals(bps));
}

export function bpsToUiDecimals(bps: number | undefined = 0, type: "cbps" | "bps" = "cbps") {
    const bpsDecimals = type === "cbps" ? 4 : 2;

    return bps / (100 * 10 ** bpsDecimals);
}

// Converts a 10% input of 0.1 -> 10
export function percentDecimalsToUi(percentDecimals: number | undefined = 0) {
    if (percentDecimals <= 0) return percentDecimals;
    return roundAutoToDecimals(percentDecimals * 100, PERCENT_DECIMALS);
}

// Converts a 10% input of 0.1 -> 10
export function percentUiToDecimals(percentDecimals: number | undefined = 0) {
    return percentDecimals / 100;
}

export function stringUiPercentToDecimals(percentString: string | undefined) {
    return percentUiToDecimals(percentString !== undefined ? parseFloat(percentString) : undefined);
}

export function sumArray<T>(array: T[] | undefined, propertyKey: keyof T) {
    if (!array) {
        return 0;
    }
    return array.reduce((prev, curr) => {
        const currVal = typeof curr[propertyKey] === "number" ? (curr[propertyKey] as number) : 0;
        return prev + currVal;
    }, 0);
}

export function findMinElement<T>(array: T[] | undefined, mapToNum: (e: T) => number | undefined): T | undefined {
    if (!array) {
        return undefined;
    }

    let minElement: T | undefined = undefined;
    let minVal: number | undefined = undefined;
    array.forEach((e) => {
        const val = mapToNum(e);
        if (val !== undefined && (!minVal || val < minVal)) {
            minVal = val;
            minElement = e;
        }
    });

    return minElement;
}

export function findMaxElement<T>(array: T[] | undefined, mapToNum: (e: T) => number | undefined): T | undefined {
    if (!array) {
        return undefined;
    }

    let minElement: T | undefined = undefined;
    let minVal: number | undefined = undefined;
    array.forEach((e) => {
        const val = mapToNum(e);
        if (val && (!minVal || val > minVal)) {
            minVal = val;
            minElement = e;
        }
    });

    return minElement;
}

export function findHighestOccurringElement<T extends number | string>(array: T[]) {
    const occurrencesMap = array.reduce((map, curr) => {
        const prev = map.get(curr) ?? 0;
        map.set(curr, prev + 1);
        return map;
    }, new Map<T, number>());

    let maxElement: T | undefined = undefined;
    let maxOccurrences = 0;

    for (const [element, occurrences] of occurrencesMap.entries()) {
        if (occurrences > maxOccurrences) {
            maxElement = element;
            maxOccurrences = occurrences;
        }
    }
    return { element: maxElement ?? array[0], occurrences: maxOccurrences };
}

export function findMinMax<T>(
    array: T[] | undefined,
    mapToNum: (e: T) => number | undefined
): { min: number | undefined; max: number | undefined } {
    const mappedToNum = array?.map((e) => mapToNum(e)).filter(filterUndefined);
    if (!mappedToNum?.length) {
        return { min: undefined, max: undefined };
    }

    const min = Math.min(...mappedToNum);
    const max = Math.max(...mappedToNum);

    return { min, max };
}

function add(...items: (number | undefined)[]) {
    return items.reduce((prev, curr) => (prev || 0) + (curr || 0), 0);
}

function sub(a: number | undefined, b: number | undefined) {
    if (a === undefined || b === undefined) return undefined;
    return a - b;
}

function tokenAdd(a: number | undefined, b: number | undefined, decimals: number | undefined) {
    const [aVal, bVal] = [uiAmountToLamports(a, decimals), uiAmountToLamports(b, decimals)];

    return lamportsToUiAmount(aVal + bVal, decimals);
}

function tokenSub(a: number | undefined, b: number | undefined, decimals: number | undefined) {
    const [aVal, bVal] = [uiAmountToLamports(a, decimals), uiAmountToLamports(b, decimals)];

    return lamportsToUiAmount(aVal - bVal, decimals);
}

function tokenMul(a: number | undefined, b: number | undefined, decimals: number | undefined) {
    const [aVal, bVal] = [uiAmountToLamports(a, decimals), uiAmountToLamports(b, decimals)];

    return lamportsToUiAmount(aVal * bVal, decimals);
}

function tokenAMul(tokenAmount: number | undefined, num: number | undefined, decimals: number | undefined) {
    if (!num) return 0;
    return lamportsToUiAmount(uiAmountToLamports(tokenAmount, decimals) * num, decimals);
}

function tokenDiv(a: number | undefined, b: number | undefined, decimals: number | undefined) {
    const [aVal, bVal] = [uiAmountToLamports(a, decimals), uiAmountToLamports(b, decimals)];

    if (!bVal) return 0;
    return lamportsToUiAmount(aVal / (bVal || 1), decimals);
}

function tokenADiv(tokenAmount: number | undefined, num: number | undefined, decimals: number | undefined) {
    if (!num) return 0;
    return lamportsToUiAmount(uiAmountToLamports(tokenAmount, decimals) / num, decimals);
}

function mul(...items: (number | undefined | null)[]): number | undefined {
    if (items.some((item) => item === undefined)) return undefined;
    return items.reduce((prev, curr) => (prev ?? 0) * (curr || 0), 1) ?? undefined;
}

function div(a: number | undefined, b: number | undefined) {
    return (a || 0) / (b || 1);
}

export const bsMath = { add, mul, sub, div, tokenSub, tokenAdd, tokenAMul, tokenMul, tokenDiv, tokenADiv };

export function createPubkey(pubkeyStr: string | undefined) {
    if (!pubkeyStr) return PublicKey.default;
    return new PublicKey(pubkeyStr);
}

export function roundToDecimals(val: number, decimals = 6) {
    if (val === 0) return 0;
    const divider = 10 ** decimals;
    return Math.ceil((val + Number.EPSILON) * divider) / divider;
}

export function roundDownToDecimals(val: number, decimals = 6) {
    const divider = 10 ** decimals;
    return Math.floor((val + Number.EPSILON) * divider) / divider;
}

export function roundAutoToDecimals(val: number, decimals = 6) {
    const divider = 10 ** decimals;
    return Math.round((val + Number.EPSILON) * divider) / divider;
}

/**
 * @returns a is greater than b
 */
export function greaterThan(a: number | undefined, b: number | undefined) {
    if (a === undefined || b === undefined) return false;
    return a > b;
}

/**
 * @returns a is greater than b
 */
export function greaterThanEqual(a: number | undefined, b: number | undefined) {
    if (a === undefined || b === undefined) return false;
    return a >= b;
}

/**
 * @returns a is less than b
 */
export function lessThan(a: number | undefined, b: number | undefined) {
    if (a === undefined || b === undefined) return false;
    return a < b;
}

export function calculatePercentChange(originalValue: number, newValue: number): number {
    if (!originalValue) return 0;

    const change = newValue - originalValue;
    const percentChange = change / originalValue;
    return percentChange;
}
