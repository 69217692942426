import {
    OrderedTransactions,
    TransactionResult,
    ParallelTransactionsBatch,
    SendTransactionsInterface,
    SendableTransactionWithIdentifier,
    TransactionType,
    isVersionedTransaction,
    serializeAndEncodeTransaction,
    SendableParallelTransactionsBatch,
    useTransactionsState,
    BundleOptions
} from "@bridgesplit/react";
import {
    Result,
    ErrorType,
    SendableTransaction,
    TransactionStatus,
    getReadableErrorMessage,
    LoopscaleWallet,
    parseErrorFromOptions,
    sleep,
    base64
} from "@bridgesplit/utils";
import { getSignInTransaction } from "@bridgesplit/abf-sdk";
import { Transaction } from "@solana/web3.js";

import { useSendTransactionsMutation } from "../reducers";
import { TransactionSenderOptions, TransactionWalletAuth } from "../types";
import {
    MAX_TRANSACTION_BUNDLE_SIZE,
    TRANSACTIONS_STATUS_ROUTE,
    TRANSACTION_DEFAULT_BATCH_COMMITMENT,
    WALLET_ERROR_MESSAGES,
    WALLET_ERROR_NAME_MAPPING
} from "../constants";
import { axiosCreateMarkets } from "../reducers/util";
import { getTransactionHeadersFromCookies } from "./auth";

/**
 * Send transactions using the ABF sender API. Ideally not exposed
 * @param transactionWallet either MPC wallet identifier or a browser wallet (Loopscale Wallet)
 * If browser wallet, transaction is signed in `useSignAndSendTransactions`
 * Else, transactions are signed server-side using MPC
 */
export function useSendTransactions() {
    const sendSignedTransactions = useSendSignedTransactions();
    const signTransactions = useSignTransactions();
    const { setSignaturePending } = useTransactionsState();

    async function sendTransactions(
        transactionWallet: TransactionWalletAuth,
        orderedTransactions: OrderedTransactions,
        options: TransactionSenderOptions
    ): Promise<Result<TransactionResult[]>> {
        try {
            const {
                refetch,
                onFail,
                onSuccess,
                onSign,
                skipSignature,
                delayMethod = "geyser",
                minGeyserConfirmations = 2
            } = options;

            const transactionBatches = orderedTransactions;

            let signedBatches = transactionBatches;
            if (!skipSignature) {
                const signedRes = await signTransactions(transactionBatches, transactionWallet, options);
                if (!signedRes.isOk()) {
                    return Result.err(signedRes);
                }
                signedBatches = signedRes.unwrap();
            }

            onSign?.();

            const sendableTransactions = prepareTransactionBatchesToSend(signedBatches, transactionWallet, options);
            if (!sendableTransactions.isOk()) return Result.err(sendableTransactions);

            const statusesRes = await sendSignedTransactions(sendableTransactions.unwrap());

            if (!statusesRes.isOk()) {
                // explicitly catch sign error in case MPC cookies are out of date

                return Result.err(statusesRes);
            }

            const statuses = statusesRes.unwrapOr([]);

            if (statuses.length === 0) {
                return Result.errFromMessage("No transactions were sent", {
                    errorType: ErrorType.TransactionSendError
                });
            }

            const errors = statuses.filter((s) => s.status !== TransactionStatus.Confirmed);
            if (errors.length) {
                await onFail?.();
            } else {
                if (delayMethod === "geyser") {
                    await pollUntilGeyserConfirmed(
                        statuses.map((s) => s.signature),
                        minGeyserConfirmations
                    );
                }
                if (delayMethod === "sleep") {
                    await sleep(2_500);
                }

                await onSuccess?.();
            }
            await refetch?.();

            return Result.ok(statuses);
        } catch (error) {
            setSignaturePending(false);
            return Result.err(error);
        }
    }

    return async (
        transactionWallet: TransactionWalletAuth,
        orderedTransactions: OrderedTransactions,
        options: TransactionSenderOptions
    ) => {
        const res = await sendTransactions(transactionWallet, orderedTransactions, options);

        if (!res.isOk()) {
            options.onFail?.();
        }
        return res;
    };
}

export function useSignTransactions() {
    const { setSignaturePending } = useTransactionsState();

    return async function signTransactions(
        batches: ParallelTransactionsBatch[],
        transactionWallet: TransactionWalletAuth,
        options: TransactionSenderOptions
    ): Promise<Result<ParallelTransactionsBatch[]>> {
        setSignaturePending(true);

        const result = await (async (): Promise<Result<ParallelTransactionsBatch[]>> => {
            const flattenedTransactions: SendableTransaction[] = [];
            const flattenedIdentifiers: string[] = [];

            batches.forEach((txns) => {
                txns.transactions.forEach(({ transaction, identifier }) => {
                    flattenedTransactions.push(transaction);
                    flattenedIdentifiers.push(identifier);
                });
            });

            const signedBatches = [...batches];
            let signedTransactions: SendableTransaction[] = [];
            if ("wallet" in transactionWallet) {
                const signedTransactionsRes = await walletSignTransactions(
                    transactionWallet.wallet,
                    flattenedTransactions
                );
                if (!signedTransactionsRes.isOk()) return Result.err(signedTransactionsRes);
                signedTransactions = signedTransactionsRes.unwrap();
                let flatIndex = 0;
                signedBatches.forEach((txns) => {
                    txns.transactions.forEach((_, index) => {
                        if (signedTransactions[flatIndex]) {
                            txns.transactions[index].transaction = signedTransactions[flatIndex];
                            flatIndex++;
                        }
                    });
                });
            }

            return Result.ok(signedBatches);
        })();
        setSignaturePending(false);

        return result;
    };
}

async function walletSignTransactions(
    wallet: LoopscaleWallet,
    flattenedTransactions: SendableTransaction[]
): Promise<Result<SendableTransaction[]>> {
    try {
        const signed = await wallet.signAllTransactions(flattenedTransactions);
        return Result.ok(signed);
    } catch (error) {
        if (error instanceof Error) {
            if (error.message) {
                const parsedError = parseErrorFromOptions(error.message, WALLET_ERROR_NAME_MAPPING);
                if (parsedError) return Result.errFromMessage(parsedError, { skipSentry: true });
            }

            if (error.name in WALLET_ERROR_MESSAGES) {
                return Result.errFromMessage(WALLET_ERROR_MESSAGES[error.name], { skipSentry: true });
            }
        }
        return Result.errWithDebug(getReadableErrorMessage("sign transactions"), error);
    }
}

function useSendSignedTransactions() {
    const [sendTransactions] = useSendTransactionsMutation();
    return async (transactions: SendTransactionsInterface): Promise<Result<TransactionResult[]>> => {
        try {
            const txnResult = await sendTransactions(transactions);
            if ("error" in txnResult) {
                return Result.errWithDebug(getReadableErrorMessage("send transactions"), txnResult.error);
            }
            return Result.ok(txnResult.data);
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("send authenticated transactions"), error);
        }
    };
}

function prepareTransactionBatchesToSend(
    batches: ParallelTransactionsBatch[],
    transactionWallet: TransactionWalletAuth,
    options: TransactionSenderOptions
): Result<SendTransactionsInterface> {
    const encodedBatches: SendableParallelTransactionsBatch[] = batches.map((batch, i) => {
        const defaultConfirmation =
            batches.length > 1 && i !== batches.length - 1 ? TRANSACTION_DEFAULT_BATCH_COMMITMENT : "confirmed";

        return {
            transactions: batch.transactions.map(
                ({ identifier, transaction, transactionActions }): SendableTransactionWithIdentifier => ({
                    identifier,
                    transaction: serializeAndEncodeTransaction(transaction),
                    transactionType: isVersionedTransaction(transaction) ? TransactionType.V0 : TransactionType.Legacy,
                    transactionActions: transactionActions ?? []
                })
            ),
            allowFailure: options.allowBatchFailure ?? batch.allowFailure,
            commitmentLevel: options.commitmentLevel ?? batch.commitmentLevel ?? defaultConfirmation,
            bundle: BundleOptions.Priority, //TODO: use enum directly here from higher up from FE component for user sending options @chris @ross
            skipBundleSim: batch.skipBundleSim ?? true
        };
    });

    // it's better to err rather than sending non atomically (could lead to edge cases)
    if (options.bundle === "always" && batches.find((b) => b.transactions.length > MAX_TRANSACTION_BUNDLE_SIZE)) {
        return Result.errFromMessage("Unable to send. Try reducing the amount of data");
    }

    return Result.ok({
        batches: encodedBatches,
        mpcIdentifier: "mpcIdentifier" in transactionWallet ? transactionWallet.mpcIdentifier : undefined
    });
}

async function getIsGeyserConfirmed(signatures: string[], minCount: number) {
    try {
        const res = await axiosCreateMarkets().post<number>(TRANSACTIONS_STATUS_ROUTE, signatures);
        return typeof res.data === "number" && res.data >= minCount;
    } catch {
        return false;
    }
}

async function pollUntilGeyserConfirmed(signatures: string[], minCount: number, interval = 500, maxTime = 10000) {
    const startTime = Date.now();

    while (Date.now() - startTime < maxTime) {
        const isConfirmed = await getIsGeyserConfirmed(signatures, minCount);
        if (isConfirmed) {
            return true;
        }
        await sleep(interval);
    }
    return false;
}

export function useSignInTransactionAuth() {
    const sign = useSignTransactions();

    return async (transactionWalletAuth: TransactionWalletAuth): Promise<Result<string>> => {
        const headers = getTransactionHeadersFromCookies();

        if (!headers.isOk()) return Result.err(headers);

        if (!("wallet" in transactionWalletAuth)) return Result.errFromMessage("Not able to find a wallet to sign");

        const signInTransaction = await getSignInTransaction(
            headers.unwrap(),
            transactionWalletAuth.wallet.publicKey.toString()
        );
        if (!signInTransaction.isOk()) return Result.err(signInTransaction);

        const signedTransactionRes = await sign(
            [{ transactions: [{ transaction: signInTransaction.unwrap(), identifier: "Sign in" }] }],
            transactionWalletAuth,
            {}
        );
        if (!signedTransactionRes.isOk()) return Result.err(signedTransactionRes);
        const signedTransaction = signedTransactionRes.unwrap()[0].transactions[0].transaction;
        if (!signInTransaction) return Result.errFromMessage("Error signing transaction");
        const serialized = serializeTransactionForAuth(signedTransaction);
        return Result.ok(serialized);
    };
}

export async function getSignInTransactionFromAuth(
    transactionWalletAuth: TransactionWalletAuth
): Promise<Result<Transaction | undefined>> {
    const headers = getTransactionHeadersFromCookies();

    if (!headers.isOk()) return Result.err(headers);

    if ("mpcIdentifier" in transactionWalletAuth) return Result.ok(undefined);

    return await getSignInTransaction(headers.unwrap(), transactionWalletAuth.wallet.publicKey.toString());
}

export function serializeTransactionForAuth(signedTransaction: SendableTransaction) {
    return base64.encode(signedTransaction.serialize({ requireAllSignatures: false }) as Buffer);
}
