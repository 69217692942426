import { IconButton, MEDIA, ToggleSpacedButtons } from "@bridgesplit/ui";
import { BsMetaUtil, DenominationDisplay } from "@bridgesplit/abf-react";
import { CurrencyExchange } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

import { useVaultContext } from "../../VaultContext";

export default function SelectValueDenomination() {
    const { selectedDenomination, setSelectedDenomination, vaultExpanded } = useVaultContext();
    const isMobile = useMediaQuery(MEDIA.SM.below);
    if (!selectedDenomination || !vaultExpanded) return null;

    const denominationOptions = [
        {
            value: DenominationDisplay.PrincipalBasis,
            label: BsMetaUtil.getSymbol(vaultExpanded?.principalMetadata)
        },
        {
            value: DenominationDisplay.UsdBasis,
            label: "USD"
        }
    ];

    if (isMobile) {
        return (
            <IconButton
                sx={{
                    height: "30px"
                }}
                jsxIcon={<CurrencyExchange />}
                border={true}
                onClick={() => {
                    const currentIndex = denominationOptions.findIndex((opt) => opt.value === selectedDenomination);
                    const nextIndex = (currentIndex + 1) % denominationOptions.length;
                    setSelectedDenomination(denominationOptions[nextIndex].value);
                }}
            />
        );
    }

    return (
        <ToggleSpacedButtons
            value={selectedDenomination}
            variant="default"
            size="small"
            setValue={setSelectedDenomination}
            options={denominationOptions}
        />
    );
}
