import { useCallback, useEffect } from "react";

import { AppCookie, clearBearerToken, useAlert, useUserPublicKey, useWeb3User } from "@bridgesplit/react";
import { AuthorizationParams, useAuth0 } from "@auth0/auth0-react";
import { Result, getReadableErrorMessage } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";
import { UAParser } from "ua-parser-js";
import { getCurrentScope } from "@sentry/react";

import { useAbfFetches, useAuth, useAuthStorage } from "../reducers";
import { TransactionWalletAuth, AbfUserWithPermissionsAndWallets } from "../types";
import { useActiveWallet } from "./wallet";
import { AUTH0_COOKIES_PREFIX, REDIRECT_LOCATION_KEY, SIGN_UP_PREFERENCE_KEY, USER_WALLET_COOKIE } from "../constants";
import { createDefaultUser } from "./utils";

interface UseUserResponse {
    data: AbfUserWithPermissionsAndWallets | undefined;
    skip?: boolean;
    isError?: boolean;
    isLoading: boolean;
    isFetching?: boolean;
    error?: string;
}
export function useUser(options?: { skip?: boolean }): UseUserResponse {
    const { params, skip, isLoading } = useUserMeParams();

    useEffect(() => {
        if (params && (!params.walletPubkey || skip || isLoading)) return;

        // Set user context and cookies
        const userId = params?.walletPubkey;

        // Set Sentry user context
        getCurrentScope().setUser({
            id: userId,
            wallet: params?.walletPubkey
        });

        // Set essential cookies and reset auth state
        if (params?.walletPubkey) {
            AppCookie.set(USER_WALLET_COOKIE, params.walletPubkey);
        }
        clearBearerToken(AUTH0_COOKIES_PREFIX);
    }, [params?.walletPubkey, skip, isLoading, params]);

    if (isLoading) {
        return {
            data: undefined,
            skip: skip,
            isError: true,
            isLoading: true,
            isFetching: true
        };
    }

    if (!params?.walletPubkey) {
        return {
            data: undefined,
            skip: skip,
            isLoading: false,
            isError: true,
            isFetching: false
        };
    }

    const user = createDefaultUser(params?.walletPubkey);

    return {
        data: user,
        skip: options?.skip || skip,
        isLoading: false,
        isError: false,
        isFetching: false
    };
}

export function useSkipUnauthenticated() {
    const { activeWallet } = useActiveWallet();
    return !activeWallet;
}

export function useUserMeParams() {
    const { isAuthenticated, isWalletBased, isLoading } = useAuth();
    const userPubkey = useUserPublicKey();
    const params = isWalletBased ? { walletPubkey: userPubkey } : undefined;
    const skip = !isAuthenticated || isLoading;

    return { params, skip, isLoading };
}

export function useTransactionWalletAuth() {
    const { activeWallet } = useActiveWallet();
    const { getWeb3UserResult } = useWeb3User();

    return function (): Result<TransactionWalletAuth> {
        try {
            if (!activeWallet) return Result.errFromMessage("You haven't registered your wallet");
            const web3User = getWeb3UserResult();
            if (!web3User.isOk()) return Result.err(web3User);

            return Result.ok({ wallet: web3User.unwrap().wallet });
        } catch (error) {
            // catches browser wallet errors
            return Result.errWithDebug(getReadableErrorMessage("access your connected wallet"), error);
        }
    };
}

export function useAuth0Logout() {
    const { logout: auth0Logout } = useAuth0();

    return () => {
        onBeforeAuthentication();
        auth0Logout({ logoutParams: { returnTo: window.location.origin } });
    };
}

export function useAuth0Login() {
    const { loginWithPopup, loginWithRedirect } = useAuth0();

    return async (authorizationParams?: AuthorizationParams) => {
        const browser = new UAParser(navigator.userAgent).getBrowser();
        const isMobile = new UAParser(navigator.userAgent).getDevice().type === "mobile";

        // virtual browsers like Phantom error with popup
        if (browser.name === "WebKit" || isMobile) {
            return await loginWithRedirect({ authorizationParams });
        }

        return await loginWithPopup({ authorizationParams });
    };
}

export function useAbfLogout() {
    const auth0Logout = useAuth0Logout();
    const { isAuthenticated } = useAuth0();
    const { disconnect, select } = useWallet();
    const { resetCookies } = useAuthStorage();

    function logOut() {
        resetCookies();
        disconnect();
        select(null);

        if (isAuthenticated) {
            auth0Logout();
        }
    }

    return { logOut };
}

export function onBeforeAuthentication() {
    const route = window.location.pathname;

    if (route !== "/") {
        AppCookie.set(REDIRECT_LOCATION_KEY, window.location.pathname + window.location.search);
    }
}

export function useLogoutOnError() {
    const { isError } = useUser();
    const { isAuthenticated, isLoading } = useAuth();
    const { logOut } = useAbfLogout();

    useEffect(() => {
        if (!isError || isLoading || !isAuthenticated) return;
        logOut();
    }, [isAuthenticated, isError, isLoading, logOut]);
}

// Store auth0 credentials in a cookie so that it can be used in RTK queries
export function useSetAuth0Bearer() {
    const { isAuthenticated, isLoading, error, user } = useAuth0();
    const logout = useAuth0Logout();
    const { alert } = useAlert();

    // Store in both cookie and state
    const { resetAll } = useAbfFetches();

    const handleError = useCallback(() => {
        alert(getReadableErrorMessage("log in with email"), "error");
        return logout();
    }, [alert, logout]);

    useEffect(() => {
        if (!isAuthenticated || isLoading || error || !user?.email_verified) return;
        (async function fetchAndSetToken() {
            AppCookie.remove(SIGN_UP_PREFERENCE_KEY);
            resetAll();
        })();
    }, [error, handleError, isAuthenticated, isLoading, resetAll, user?.email_verified]);
}
