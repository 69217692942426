import { useMemo, useState } from "react";

import {
    Icon,
    InvisibleSelect,
    RewardIcon,
    Row,
    SelectOption,
    StatProps,
    Text,
    TextVariant,
    Tooltip
} from "@bridgesplit/ui";
import { formatNum, formatPercent, TIME } from "@bridgesplit/utils";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import {
    LoopscalePointsAction,
    PriceFetchType,
    usePointsSourcesUtil,
    useLoopCollateralPrice,
    useOraclePrices
} from "@bridgesplit/abf-react";
import { COPY } from "app/constants";

import { calculatePointsData } from "./util";
import { OracleTooltipContent, ORACLE_DISCREPANCY_THRESHOLD } from "../common";

export interface UsePointsStat {
    variant?: TextVariant;
    action: LoopscalePointsAction;
    showSelect?: boolean;
    totalPrincipalAmountUsd: number | undefined;
    metadata: TokenListMetadata[] | undefined;
}

const TIME_OPTIONS: SelectOption<number>[] = [
    { label: "per day", value: TIME.DAY },
    { label: "per week", value: TIME.WEEK },
    { label: "per month", value: TIME.MONTH },
    { label: "per year", value: TIME.YEAR }
];

interface UseCalculatePointsProps {
    loopscalePointsAction: LoopscalePointsAction;
    estimateSeconds: number;
    totalPrincipalAmountUsd: number | undefined;
    metadata?: TokenListMetadata[];
}

export function useCalculatePoints({
    loopscalePointsAction,
    estimateSeconds,
    totalPrincipalAmountUsd,
    metadata = []
}: UseCalculatePointsProps): number {
    const { multiplierInfoMap, basePointsPerSecondMap, baseActionMultiplierMap } = usePointsSourcesUtil();

    return useMemo(() => {
        const { totalPoints } = calculatePointsData({
            loopscalePointsAction,
            metadata,
            multiplierInfoMap,
            basePointsPerSecondMap,
            baseActionMultiplierMap,
            totalPrincipalAmountUsd,
            estimateSeconds
        });

        return totalPoints;
    }, [
        metadata,
        loopscalePointsAction,
        multiplierInfoMap,
        basePointsPerSecondMap,
        baseActionMultiplierMap,
        totalPrincipalAmountUsd,
        estimateSeconds
    ]);
}

export function useLoopscalePoints({
    variant = "body2",
    action,
    showSelect = true,
    totalPrincipalAmountUsd,
    metadata
}: UsePointsStat) {
    const { getCheckMultiplierGreaterThanBase } = usePointsSourcesUtil();
    const [estimateSeconds, setEstimateSeconds] = useState(TIME_OPTIONS[0].value);

    const calculatedPoints = useCalculatePoints({
        loopscalePointsAction: action,
        estimateSeconds,
        totalPrincipalAmountUsd,
        metadata
    });

    const caption = (
        <Row>
            <Text sx={{ mr: showSelect ? -0.5 : 0 }} variant={variant} color="caption">
                {showSelect ? "Rewards" : "Rewards per day"}
            </Text>
            {showSelect && (
                <InvisibleSelect
                    variant={variant}
                    color="caption"
                    value={estimateSeconds}
                    setValue={setEstimateSeconds}
                    options={TIME_OPTIONS}
                />
            )}
        </Row>
    );

    const value = (
        <Row spacing={1}>
            <RewardIcon hasMultiplier={getCheckMultiplierGreaterThanBase(action, metadata)} />
            <Text variant={variant} loading={!calculatedPoints}>
                {formatNum(calculatedPoints)}
            </Text>
        </Row>
    );

    return {
        value,
        caption
    };
}
export function useLoopPoints({
    borrowed,
    variant,
    metadata
}: {
    borrowed: number | undefined;
    variant: TextVariant;
    metadata: TokenListMetadata[] | undefined;
}): StatProps[] {
    const pointsStat = useLoopscalePoints({
        action: LoopscalePointsAction.Borrow,
        totalPrincipalAmountUsd: borrowed,
        variant,
        metadata
    });

    return [pointsStat];
}

// Takes in metadata and returns a list of discrepency stats
export function useOracleDiscrepencyStats({ metadata }: { metadata: TokenListMetadata[] | undefined }): StatProps[] {
    const { data: jupiterPrices } = useLoopCollateralPrice({
        tokens: metadata,
        options: { skip: !metadata }
    });

    const { getOracle } = useOraclePrices(
        metadata?.map((t) => t.mint),
        PriceFetchType.Twap
    );

    const stats: StatProps[] = [];

    if (jupiterPrices && metadata) {
        for (const token of metadata) {
            const jupiterPrice = Number(jupiterPrices.data[token.mint]?.price);
            const oraclePrice = getOracle(token.mint, PriceFetchType.Twap)?.usdPrice;
            if (jupiterPrice && oraclePrice) {
                const oracleDiff = (jupiterPrice - oraclePrice) / oraclePrice;
                if (Math.abs(oracleDiff) > ORACLE_DISCREPANCY_THRESHOLD) {
                    stats.push({
                        caption: COPY.LOOP_ORACLE_DISCREPANCY_TERM,
                        value: getOracleDiscrepancyValue(oracleDiff, jupiterPrice, oraclePrice, token, true)
                    });
                }
            }
        }
    }

    return stats;
}

function getOracleDiscrepancyValue(
    oracleDiff: number,
    jupiterPrice: number,
    oraclePrice: number,
    token: TokenListMetadata,
    isWind: boolean
) {
    const color = isWind ? (oracleDiff < 0 ? "success" : "warning") : oracleDiff > 0 ? "warning" : "success";

    return (
        <Tooltip
            title={
                <OracleTooltipContent
                    oraclePrice={oraclePrice}
                    quotePrice={jupiterPrice}
                    oracleDiff={oracleDiff}
                    collateralToken={token}
                    isWind={isWind}
                />
            }
            reverseColors
        >
            <Row spacing={0.5}>
                <Text color="disabled">
                    <Icon type="help" />
                </Text>
                <Text color={oracleDiff === 0 ? "caption" : color}>
                    {oracleDiff === 0 ? "No difference" : formatPercent(oracleDiff)}
                </Text>
            </Row>
        </Tooltip>
    );
}
