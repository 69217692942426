import { useMemo } from "react";

import { WalletAdapterNetwork, WalletReadyState } from "@solana/wallet-adapter-base";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    WalletConnectWalletAdapter,
    WalletConnectWalletAdapterConfig
} from "@solana/wallet-adapter-wallets";

const supportedWallets = [
    "Phantom",
    "Coinbase Wallet",
    "Backpack",
    "Solflare",
    "Glow",
    "Magic Eden",
    "SquadsX",
    "NuFi"
];
const walletConfigAdapter: WalletConnectWalletAdapterConfig = {
    network: WalletAdapterNetwork.Mainnet,
    options: {}
};
const standardAdapters = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new WalletConnectWalletAdapter(walletConfigAdapter)
];

// utils copied from https://github.com/solana-labs/wallet-adapter/blob/master/packages/ui/react-ui/src/WalletModal.tsx
export function useWalletUtils() {
    const { wallets } = useWallet();

    // show at least basic wallets for devices with no wallets installed
    if (!wallets.length) {
        wallets.push(...standardAdapters.map((adapter) => ({ adapter, readyState: WalletReadyState.NotDetected })));
    }

    const [installedWallets, otherWallets] = useMemo(() => {
        const installed: Wallet[] = [];
        const notDetected: Wallet[] = [];
        const loadable: Wallet[] = [];

        for (const wallet of wallets) {
            // hardcode wallets here to prevent user installed wallets
            const isSupported = supportedWallets.includes(wallet.adapter.name);
            if (!isSupported) continue;

            if (wallet.readyState === WalletReadyState.NotDetected) {
                notDetected.push(wallet);
            } else if (wallet.readyState === WalletReadyState.Loadable) {
                loadable.push(wallet);
            } else if (wallet.readyState === WalletReadyState.Installed) {
                installed.push(wallet);
            }
        }
        const installedSorted = installed.sort((a) => (a.adapter.name === "Phantom" ? -1 : 0));

        return [installedSorted, [...loadable, ...notDetected]];
    }, [wallets]);

    return { installedWallets, otherWallets, allWallets: [...installedWallets, ...otherWallets] };
}
