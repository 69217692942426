import { TIME, findMaxElement, findMinElement, formatNum } from "@bridgesplit/utils";
import { Frequency } from "rrule";

export enum DurationType {
    Days,
    Weeks,
    Months,
    Minutes
}

export interface StrategyDuration {
    duration: number;
    durationType: DurationType;
}

const ONCHAIN_LENDING_STRATEGY_TERM_TO_INDEX = new Map<string, number>([
    ["1_0", 0], // 1 day
    ["1_1", 1], // 1 week
    ["1_2", 2], // 1 month
    ["3_2", 3], // 3 months
    ["5_3", 4] // 5 minutes
]);

const DURATION_INDEX_TO_DURATION = new Map<number, StrategyDuration>(
    Array.from(ONCHAIN_LENDING_STRATEGY_TERM_TO_INDEX.entries()).map(([key, value]) => {
        const [duration, durationType] = key.split("_").map(Number);
        return [value, { duration, durationType }];
    })
);

export function getDurationFromIndex(index: number | undefined): StrategyDuration | undefined {
    if (!index) return undefined;
    const duration = DURATION_INDEX_TO_DURATION.get(index);
    if (!duration) return undefined;
    return duration;
}

export function getDurationIndex(duration: StrategyDuration | undefined): number | undefined {
    if (!duration) return undefined;
    const key = `${duration.duration}_${duration.durationType}`;
    return ONCHAIN_LENDING_STRATEGY_TERM_TO_INDEX.get(key);
}

export enum RRuleWeekday {
    Mon = "MO",
    Tue = "TU",
    Wed = "WE",
    Thur = "TH",
    Fri = "FR",
    Sat = "SA",
    Sun = "SU"
}

const weekdayLetters: { [key in RRuleWeekday]: string } = {
    [RRuleWeekday.Mon]: "M",
    [RRuleWeekday.Tue]: "T",
    [RRuleWeekday.Wed]: "W",
    [RRuleWeekday.Thur]: "R",
    [RRuleWeekday.Fri]: "F",
    [RRuleWeekday.Sat]: "S",
    [RRuleWeekday.Sun]: "U"
};

export function getWeekdaySingleLetter(day: RRuleWeekday): string {
    return weekdayLetters[day] || "";
}

export enum RRuleMonthlyOption {
    FirstDayOfMonth = "First day of the month",
    FirstWeekDayOfMonth = "First weekday of the month",
    LastDayOfMonth = "Last day of the month",
    LastWeekDayOfMonth = "Last weekday of the month"
}

type StrategyAndDurationSplit = [number | undefined, DurationType | undefined];
type StrategyDurationOrSplit = [StrategyDuration | undefined] | StrategyAndDurationSplit;
// take in a strategy duration or a duration and duration type
function getDurationDetailsFromStrategyDuration(...params: StrategyDurationOrSplit): StrategyAndDurationSplit {
    if (params.length === 2) {
        return params;
    }
    return [params[0]?.duration, params[0]?.durationType];
}

export function getDurationDetails(unit: DurationType) {
    const details = DURATION_UNIT_MAP[unit];
    if (!details) return DURATION_UNIT_MAP[DurationType.Weeks];
    return details;
}

export function getDurationInSeconds(...params: StrategyDurationOrSplit) {
    const [duration, durationType] = getDurationDetailsFromStrategyDuration(...params);
    if (duration === undefined || durationType === undefined) return 0;
    return duration * getDurationDetails(durationType).time;
}

export function formatDurationWithType(...params: StrategyDurationOrSplit): string {
    const [duration, durationType] = getDurationDetailsFromStrategyDuration(...params);

    if (!duration || durationType === undefined) return "--";

    const freqDetails = getDurationDetails(durationType);
    return `${formatNum(duration)} ${freqDetails.unit.toLocaleLowerCase()}${duration === 1 ? "" : "s"}`;
}

export function formatMinMaxDurations(durations: { duration: number; durationType: DurationType }[] | undefined) {
    const minDuration = findMinElement(durations, (d) => getDurationInSeconds(d.duration, d.durationType));
    const maxDuration = findMaxElement(durations, (d) => getDurationInSeconds(d.duration, d.durationType));

    if (!minDuration || !maxDuration) return "--";
    if (minDuration.durationType === maxDuration.durationType) {
        if (minDuration.duration === maxDuration.duration) {
            return formatDurationWithType(minDuration.duration, minDuration.durationType);
        }
        const unit = getDurationDetails(minDuration.durationType).unit.toLowerCase();
        return `${formatNum(minDuration.duration)} - ${formatNum(maxDuration.duration)} ${unit}`;
    }

    return `${formatDurationWithType(minDuration.duration, minDuration.durationType)} - ${formatDurationWithType(
        maxDuration.duration,
        maxDuration.durationType
    )}`;
}

export function formatDurationWithTypeShorthand(...params: StrategyDurationOrSplit): string {
    const [duration, durationType] = getDurationDetailsFromStrategyDuration(...params);
    if (!duration || durationType === undefined) return "--";

    const freqDetails = getDurationDetails(durationType);
    return `${formatNum(duration)}${freqDetails.unit.toUpperCase()[0]}`;
}

export const DEFAULT_DURATION_TYPE = DurationType.Weeks;

export function getRRuleFrequencyDurationDetails(freq: Frequency) {
    return (
        Object.values(DURATION_UNIT_MAP).find(({ frequency }) => frequency === freq) ??
        DURATION_UNIT_MAP[DEFAULT_DURATION_TYPE]
    );
}

export type DurationDetails = {
    time: number;
    frequency: Frequency;
    unit: string;
    unitSingle: string;
    type: DurationType;
    paramUnit: string;
};

const DURATION_UNIT_MAP: Record<DurationType, DurationDetails> = {
    [DurationType.Days]: {
        time: TIME.DAY,
        frequency: Frequency.DAILY,
        unit: "Day",
        unitSingle: "Daily",
        type: DurationType.Days,
        paramUnit: "Days"
    },
    [DurationType.Weeks]: {
        time: TIME.WEEK,
        frequency: Frequency.WEEKLY,
        unit: "Week",
        unitSingle: "Weekly",
        type: DurationType.Weeks,
        paramUnit: "Weeks"
    },
    [DurationType.Months]: {
        time: TIME.MONTH,
        frequency: Frequency.MONTHLY,
        unit: "Month",
        unitSingle: "Monthly",
        type: DurationType.Months,
        paramUnit: "Months"
    },
    [DurationType.Minutes]: {
        time: TIME.MINUTE,
        frequency: Frequency.MINUTELY,
        unit: "Minute",
        unitSingle: "Minute",
        type: DurationType.Minutes,
        paramUnit: "Minutes"
    }
};

export const DURATION_OPTIONS = [DurationType.Days, DurationType.Weeks, DurationType.Months].map((o) => {
    const details = getDurationDetails(o as DurationType);
    return { value: o, label: details.unit + "s" };
});
