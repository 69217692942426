import { useState } from "react";

import { DialogWrapper, EmptyPlaceholder, useMemoizedMap } from "@bridgesplit/ui";
import { percentDecimalsToUi } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";
import { AccountBalanceOutlined } from "@mui/icons-material";

import { useStrategyTerms } from "../terms/util";
import StrategyTermsTable from "../terms/StrategyTermsTable";
import { StrategyTermsProps } from "../terms/type";
import StrategyTermsCta from "../terms/StrategyTermsCta";
import { DialogHeader } from "../../common";

export default function StrategyTermsDialog() {
    const { getData } = useAppDialog();
    const strategy = getData(AppDialog.StrategyTerms)?.strategy;
    const [serializedStrategyDurationToApyState, setSerializedStrategyDurationToApy] =
        useState<Map<string, number | undefined>>();

    const terms = useStrategyTerms(
        strategy,
        strategy?.collateral.map((t) => t.metadata.mint)
    );

    const initialTerms = useMemoizedMap(
        terms,
        (t) => t.key,
        (t) => (t.minApy !== undefined ? percentDecimalsToUi(t.minApy) : undefined)
    );

    const serializedStrategyDurationToApy = serializedStrategyDurationToApyState ?? initialTerms;

    const props: StrategyTermsProps = {
        strategy,
        terms,
        serializedStrategyDurationToApy,
        setSerializedStrategyDurationToApy
    };

    if (!strategy?.collateral.length)
        return (
            <DialogWrapper>
                <DialogHeader header={AppDialog.StrategyTerms} description={null} />
                <EmptyPlaceholder
                    icon={<AccountBalanceOutlined />}
                    header="Strategy has no collateral selected"
                    description="You have not selected collateral you would like to lend against. Please select collateral before setting terms."
                />
            </DialogWrapper>
        );

    return (
        <DialogWrapper>
            <DialogHeader header={AppDialog.StrategyTerms} description={null} />
            <StrategyTermsTable {...props} />
            <StrategyTermsCta {...props} />
        </DialogWrapper>
    );
}
