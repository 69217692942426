import { useMemo } from "react";

import { useMemoizedKeyMap } from "@bridgesplit/ui";
import { bpsToUiDecimals } from "@bridgesplit/utils";

import { useExternalYieldInfoQuery } from "../reducers";

export function useExternalYieldVaults() {
    const { data: rawData, ...query } = useExternalYieldInfoQuery();

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return rawData.map((vault) => ({
            ...vault,
            apy: bpsToUiDecimals(vault.apy)
        }));
    }, [rawData]);

    const principalMintToYieldVault = useMemoizedKeyMap(data, (v) => v.principalMint);

    function getYieldVault(principalMint: string | undefined) {
        if (!principalMint || !principalMintToYieldVault) return undefined;
        return principalMintToYieldVault.get(principalMint) ?? null;
    }

    return { data, ...query, getYieldVault };
}
