import { fetchAndDeserializeLegacyTransaction } from "@bridgesplit/utils";
import { Meteora } from "@bridgesplit/bs-protos";

import { createPandoraInstance } from "./auth";
import { AbfTransactionHeaders, METEORA_DEPOSIT_TXN, METEORA_WITHDRAW_TXN } from "../types";

export async function getMeteoraDepositTransaction(
    authentication: AbfTransactionHeaders,
    params: Meteora.DynamicPoolDepositParams
) {
    return [
        await fetchAndDeserializeLegacyTransaction(createPandoraInstance(authentication), METEORA_DEPOSIT_TXN, params)
    ];
}

export async function getMeteoraWithdrawTransaction(
    authentication: AbfTransactionHeaders,
    params: Meteora.DynamicPoolWithdrawParams
) {
    return [
        await fetchAndDeserializeLegacyTransaction(createPandoraInstance(authentication), METEORA_WITHDRAW_TXN, params)
    ];
}
