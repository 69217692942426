import { ReactNode, useMemo, useState } from "react";

import {
    BsMetaUtil,
    getZcLoanEndTime,
    getTotalRepaidForCurrentLender,
    getZcLedger,
    SellLoanTransactionParams,
    summarizeLedgerAccount,
    useSellLoanTransaction,
    useSellQuote
} from "@bridgesplit/abf-react";
import {
    BORDER_RADIUS,
    CheckboxRow,
    Column,
    FONT_SIZES,
    Row,
    SPACING,
    StatColumn,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import {
    bsMath,
    calculatePercentChange,
    formatDate,
    formatPercent,
    lessThan,
    LOADING_ERROR,
    Result
} from "@bridgesplit/utils";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { TrackTransactionEvent } from "app/types";
import { getDurationIndex } from "@bridgesplit/abf-sdk";

import { AppButton, TokenImage } from "../../common";
import { ActionProps } from "./types";
import { useTransactionSender } from "../../transactions";

export default function SellLoan(props: ActionProps) {
    const { loanExpanded } = props;
    const { data: quote } = useSellQuote(loanExpanded);
    const send = useTransactionSender();
    const sellLoan = useSellLoanTransaction();
    const [accepted, setAccepted] = useState(false);

    const firstLedger = getZcLedger(loanExpanded);

    const netInterest = useMemo(() => {
        if (!loanExpanded) return 0;
        const totalRepaidForCurrentLender = getTotalRepaidForCurrentLender(loanExpanded, getZcLedger(loanExpanded));
        return quote?.salePrice ?? 0 + (totalRepaidForCurrentLender ?? 0) - (firstLedger?.principalDue ?? 0);
    }, [loanExpanded, quote, firstLedger]);

    const isTakingPrincipalLoss = lessThan(netInterest, 0);

    // this will occur if there isn't sufficient liquidity to give fair fair
    const priceImpact = bsMath.sub(quote?.salePrice, quote?.fairSalePriceAtQuoteRate);
    const priceImpactWarningNeeded = lessThan(priceImpact, 0);

    const ctaError = useMemo(() => {
        if (!quote || !loanExpanded || !quote.quote) return "No offers";
        if (priceImpactWarningNeeded && !accepted) return "Accept warning";
        return undefined;
    }, [quote, loanExpanded, priceImpactWarningNeeded, accepted]);

    const originalLedger = summarizeLedgerAccount(firstLedger);

    const totalLedgersOutstanding = originalLedger.accruedTotalOutstanding;
    const sellPriceDiff = quote ? calculatePercentChange(totalLedgersOutstanding, quote?.salePrice) : undefined;

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        const ledgerAccount = getZcLedger(loanExpanded);
        if (!ledgerAccount) return Result.errFromMessage(LOADING_ERROR);
        if (!quote) return Result.errFromMessage("No quote found");
        const params: SellLoanTransactionParams = {
            decimals: loanExpanded.principalMetadata?.decimals ?? 0,
            loan: loanExpanded.loan.address,
            newStrategy: quote.quote.lendingStrategyAddress,
            oldStrategy: ledgerAccount.strategy,
            sellParams: {
                ledgerIndex: 0, //TODO: handle multiple ledgers
                expectedSalePrice: quote.salePrice,
                buyerDurationIndex:
                    getDurationIndex({
                        duration: quote.quote.duration,
                        durationType: quote.quote.durationType
                    }) ?? 0
            }
        };
        return await send(sellLoan, params, {
            mixpanelEvent: { key: TrackTransactionEvent.SubmitSellLoan, params }
        });
    }

    return (
        <>
            <Text color="caption">Get instant liquidity for your loan by selling it back to the market</Text>

            <Column>
                <NestedCard isTop>
                    <Row spacing={0.5}>
                        <Text> Your loan </Text>
                        <Text color="caption">
                            repaid {formatDate(getZcLoanEndTime(loanExpanded), "relative").toLowerCase()}
                        </Text>
                    </Row>
                    <Row spacing={1}>
                        <TokenImage metadata={loanExpanded?.principalMetadata} size="sm" />
                        <Text>
                            {BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, totalLedgersOutstanding, {
                                hideSymbol: true
                            })}
                        </Text>
                    </Row>
                </NestedCard>
                <DownDivider />
                <NestedCard>
                    <Text> Receive now </Text>
                    <Row spacing={1}>
                        <TokenImage metadata={loanExpanded?.principalMetadata} size="sm" />
                        <Text loading={!quote}>
                            {BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, quote?.salePrice, {
                                hideSymbol: true
                            })}
                        </Text>
                        {!!sellPriceDiff && (
                            <Text color={sellPriceDiff < -0.03 ? "error" : "caption"} variant="body2" loading={!quote}>
                                {formatPercent(sellPriceDiff, { includePlus: true })}
                            </Text>
                        )}
                    </Row>
                </NestedCard>
            </Column>

            <StatColumn
                loading={!quote}
                stats={[
                    {
                        caption: "Price impact",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, priceImpact),
                        hide: !priceImpactWarningNeeded,
                        tooltip: `The difference between your loan's ${BsMetaUtil.formatAmount(
                            loanExpanded?.principalMetadata,
                            quote?.fairSalePriceAtQuoteRate,
                            { hideSymbol: true }
                        )} fair market value and the price you're receiving`,
                        textColor: "error"
                    },
                    {
                        caption: "You funded",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, firstLedger?.principalDue),
                        hide: !isTakingPrincipalLoss
                    },
                    {
                        caption: "Net interest",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, netInterest),
                        tooltip: "Due to limited liquidity, early withdrawing would result in a loss",
                        textColor: "error",
                        hide: !isTakingPrincipalLoss
                    }
                ]}
            />
            {priceImpactWarningNeeded && (
                <CheckboxRow
                    value={accepted}
                    setValue={() => setAccepted(!accepted)}
                    label="I understand that I'm receiving less than this loan's fair value due to insufficient liquidity"
                />
            )}

            <AppButton disabled={!!ctaError} isTransaction asyncCta={{ onClickWithResult: submit }}>
                {ctaError ?? "Early withdraw"}
            </AppButton>
        </>
    );
}

function NestedCard({ children, isTop }: { children: ReactNode; isTop?: boolean }) {
    const { hoverBackground } = useAppPalette();
    let borderRadiusParts = [BORDER_RADIUS, BORDER_RADIUS, "0px", "0px"];
    if (!isTop) {
        borderRadiusParts = borderRadiusParts.reverse();
    }
    const borderRadius = borderRadiusParts.join(" ");
    return (
        <Row
            spaceBetween
            sx={{
                borderRadius,
                backgroundColor: hoverBackground,
                p: 2
            }}
        >
            {children}
        </Row>
    );
}

function DownDivider() {
    const { hoverBackground, paperBackground, textDisabled } = useAppPalette();
    const size = "20px";
    return (
        <Row sx={{ height: SPACING / 2 + "px", flexGrow: 1, justifyContent: "center" }}>
            <Box sx={{ background: paperBackground, p: SPACING / 4 + "px", zIndex: 1, borderRadius: "100%" }}>
                <Box
                    sx={{
                        background: hoverBackground,
                        svg: { fontSize: FONT_SIZES.body1, color: textDisabled },
                        height: size,
                        width: size,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%"
                    }}
                >
                    <ArrowDownwardOutlined />
                </Box>
            </Box>
        </Row>
    );
}
