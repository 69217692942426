import { useMemo, useState } from "react";

import { AsyncHandlerOptions, useAsyncResultHandler, useTransactionsState, useUserPublicKey } from "@bridgesplit/react";
import {
    Button,
    ButtonProps,
    Column,
    ErrorWrapper,
    FormInput,
    Icon,
    SupportedKey,
    Text,
    Tooltip,
    WalletDisplay,
    WalletSelectToggle,
    emailFormAdornments,
    useKeyDetecter
} from "@bridgesplit/ui";
import { EMAIL_REGEX, Result } from "@bridgesplit/utils";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { AppDialog, useAppDialog } from "app/utils";

interface AsyncCtaOptions {
    options?: AsyncHandlerOptions;
    closeDialog?: "always" | "onSuccess" | "never";
}
interface AsyncCtaOptionsResult<T> extends AsyncCtaOptions {
    onClickWithResult: () => Promise<Result<T>>;
}
interface AsyncCtaOptionsNonResult<T> extends AsyncCtaOptions {
    onClick: () => Promise<T>;
}

export interface AppButtonProps<ResultType> extends ButtonProps {
    gatedCheck?: boolean;
    handleUnauthorized?: "hide" | "message";
    tooltip?: string;
    asyncCta?: AsyncCtaOptionsResult<ResultType> | AsyncCtaOptionsNonResult<ResultType>;
    syncCta?: () => Result<ResultType>;
    errorMessage?: string;
    keyListener?: SupportedKey;
    kyc?: { required: boolean; actionDescription: string };
    isTransaction: boolean;
    overrideAccess?: boolean;
    requiresBeta?: boolean;
}
export function AppButton<ResultType>({
    gatedCheck,
    handleUnauthorized = "message",
    tooltip,
    disabled: propsDisabled,
    onClick,
    asyncCta,
    syncCta,
    children,
    keyListener,
    errorMessage,
    fullWidth = true,
    variant = "contained",
    kyc,
    isTransaction,
    overrideAccess = false,
    ...props
}: AppButtonProps<ResultType>) {
    const { close, open } = useAppDialog();
    const { handler, resultHandler, syncHandler, isLoading } = useAsyncResultHandler<ResultType>();
    const { isTransactionSignaturePending } = useTransactionsState();

    const transactionsInProgress = isTransaction && isTransactionSignaturePending;

    const authorized = useMemo(() => {
        if (!gatedCheck) return true;
        return false;
    }, [gatedCheck]);

    const buttonTooltip = useMemo(() => {
        if (transactionsInProgress) return "Transaction in progress";
        return tooltip;
    }, [tooltip, transactionsInProgress]);

    const disabled = propsDisabled || props.loading || !!errorMessage || transactionsInProgress;

    useKeyDetecter(keyListener ?? "Enter", handleClick, !keyListener || disabled);

    async function handleClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e && onClick?.(e);

        if (asyncCta) {
            if (asyncCta.closeDialog === "always") {
                close();
            }

            const options: AsyncHandlerOptions = {
                ...asyncCta.options,
                alertOnError: asyncCta.options?.alertOnError ?? true
            };
            const isResult = "onClick" in asyncCta;
            const getResult = isResult
                ? handler(asyncCta.onClick, options)
                : resultHandler(asyncCta.onClickWithResult, options);
            const result = await getResult;
            if (result.isOk() && asyncCta.closeDialog === "onSuccess") {
                close();
            }
        }
        if (syncCta) {
            syncHandler(syncCta);
        }
    }

    if (kyc?.required) {
        return (
            <Button
                variant="default"
                color="info"
                onClick={() => open(AppDialog.KYC, { ctaText: kyc.actionDescription })}
                fullWidth={fullWidth}
            >
                <VerifiedUserOutlined /> Identity Verification Required
            </Button>
        );
    }

    if (handleUnauthorized === "hide") {
        return null;
    }

    const button = (
        <Button
            fullWidth={fullWidth}
            width={fullWidth ? undefined : "fit-content"}
            variant={variant}
            loading={props.loading || isLoading}
            onClick={handleClick}
            disabled={disabled}
            {...props}
        >
            {handleUnauthorized === "message" && !authorized ? "Insufficient Permissions" : children}
        </Button>
    );

    if (!buttonTooltip) {
        if (!errorMessage) {
            return button;
        }
        return (
            <ErrorWrapper fullWidth={fullWidth} errorMessage={errorMessage}>
                {button}
            </ErrorWrapper>
        );
    }

    return (
        <ErrorWrapper fullWidth={fullWidth} errorMessage={errorMessage}>
            <Tooltip
                fullWidth={fullWidth}
                containerSx={{ cursor: disabled ? "not-allowed" : undefined }}
                title={buttonTooltip}
            >
                {button}
            </Tooltip>
        </ErrorWrapper>
    );
}

export function WalletRequiredConnect({ fullWidth }: { fullWidth?: boolean }) {
    const { open } = useAppDialog();

    return (
        <Button variant="contained" onClick={() => open(AppDialog.Connect, undefined)} fullWidth={fullWidth}>
            <Icon type="connect" /> Connect Wallet
        </Button>
    );
}

export function WalletConnect({ connectedLabel }: { connectedLabel: string }) {
    const userPublicKey = useUserPublicKey();

    return (
        <Column spacing={1}>
            <Text color="caption" variant="body2">
                {userPublicKey ? connectedLabel : "Connect your self-custodial wallet"}
            </Text>
            {!userPublicKey ? <WalletSelectToggle /> : <WalletDisplay address={userPublicKey} />}
        </Column>
    );
}

export function EmailInvite({
    emails,
    setEmail,
    placeHolder
}: {
    emails: Set<string>;
    setEmail: (email: string) => void;
    placeHolder?: string;
}) {
    const [inputEmail, setInputEmail] = useState<string>();

    const isValidEmail = inputEmail ? EMAIL_REGEX.test(inputEmail) : false;
    const emailAlreadyAdded = inputEmail ? emails?.has(inputEmail) : false;

    function addEmail() {
        if (!inputEmail || !isValidEmail) return;
        if (emailAlreadyAdded) {
            return;
        }
        setEmail(inputEmail);
        setInputEmail(undefined);
    }

    useKeyDetecter("Enter", addEmail);

    return (
        <FormInput
            errorMessage={emailAlreadyAdded ? "Email already added" : undefined}
            InputProps={emailFormAdornments({ isValidEmail: isValidEmail || !inputEmail })}
            fullWidth
            placeholder={placeHolder ?? "Email"}
            variant="string"
            key={emails?.toString()}
            value={inputEmail}
            setValue={setInputEmail}
        />
    );
}
