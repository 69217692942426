import { LendingVaultAllocationExpanded, BsMetaUtil, getVaultIdleBalance } from "@bridgesplit/abf-react";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { MARGIN_FI_LOGO, LOGO_URL } from "@bridgesplit/ui";
import { getUnixTs } from "@bridgesplit/utils";

import { useVaultContext } from "../../VaultContext";
import { AllocationSummarized } from "./type";

export function useAllocationSummaries(allocations: LendingVaultAllocationExpanded[] | undefined) {
    const { vaultExpanded } = useVaultContext();
    if (!allocations || !vaultExpanded) return undefined;

    const idleBalance = getVaultIdleBalance(vaultExpanded, getUnixTs());
    const totalBalance = vaultExpanded.vaultStrategy.strategy.currentDeployedAmount + idleBalance;

    // Calculate collateral allocations
    const collateralAllocation = allocations.map(
        (a): AllocationSummarized => ({
            key: a.collateralMint,
            image: BsMetaUtil.getImage(a.collateralMetadata),
            name: BsMetaUtil.getSymbolUnique(a.collateralMetadata),
            principalDeployedUsd: vaultExpanded.principalOracle.getUsdAmount(a.totalPrincipalDeployed),
            allocationPercent: totalBalance ? a.totalPrincipalDeployed / totalBalance : 0,
            activeLoanCount: a.activeLoanCount,
            wAvgApy: a.wAvgApy,
            isIdle: false,
            totalPrincipalDeployed: a.totalPrincipalDeployed
        })
    );

    // Calculate external yield allocation
    let externalYield: AllocationSummarized;
    if (vaultExpanded.vaultStrategy.externalYieldInfo) {
        externalYield = {
            key: "idle",
            ...getMetadataFromExternalYieldInfo(vaultExpanded.vaultStrategy.externalYieldInfo.externalYieldSource),
            wAvgApy: vaultExpanded.vaultStrategy.externalYieldInfo.apy,
            principalDeployedUsd: vaultExpanded.principalOracle.getUsdAmount(
                vaultExpanded.vaultStrategy.externalYieldInfo.balance
            ),
            allocationPercent: totalBalance ? vaultExpanded.vaultStrategy.externalYieldInfo.balance / totalBalance : 0,
            activeLoanCount: 0,
            isIdle: true,
            totalPrincipalDeployed: vaultExpanded.vaultStrategy.externalYieldInfo.balance
        };
    } else {
        const idleBalance = getVaultIdleBalance(vaultExpanded, getUnixTs());
        externalYield = {
            key: "idle",
            ...getMetadataFromExternalYieldInfo(ExternalYieldSource.None),
            wAvgApy: 0,
            principalDeployedUsd: vaultExpanded.principalOracle.getUsdAmount(idleBalance),
            allocationPercent: totalBalance ? idleBalance / totalBalance : 0,
            activeLoanCount: 0,
            isIdle: true,
            totalPrincipalDeployed: idleBalance
        };
    }

    const allAllocations = [...collateralAllocation, externalYield];

    // Calculate total USD value
    const totalUsdValue = allAllocations.reduce((sum, allocation) => sum + allocation.principalDeployedUsd, 0);

    // Normalize allocations to ensure they sum to 100%
    return allAllocations.map((allocation) => ({
        ...allocation,
        allocationPercent: totalUsdValue > 0 ? allocation.principalDeployedUsd / totalUsdValue : 0
    }));
}

function getMetadataFromExternalYieldInfo(externalYieldSource: ExternalYieldSource): { image: string; name: string } {
    if (externalYieldSource === ExternalYieldSource.MarginFi) {
        return {
            image: MARGIN_FI_LOGO,
            name: "MarginFi"
        };
    }
    return {
        image: LOGO_URL,
        name: "Idle liquidity"
    };
}
