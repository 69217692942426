import { ReactNode } from "react";

import {
    CollateralWithTerms,
    Collateral,
    AbfLoanExpanded,
    StrategyExpanded,
    MarketGuideMode,
    LoopExpanded,
    LendingVaultExpanded
} from "@bridgesplit/abf-react";
import { StrategyDuration, TokenListMetadata } from "@bridgesplit/abf-sdk";
import { useDialog } from "@bridgesplit/ui";
import { Result } from "@bridgesplit/utils";
import { FormInputType, DispatchType } from "@bridgesplit/react";
import { TransactionsDialogData } from "app/components/transactions";

import { MarketProps } from "../components/market-detail/types";
import { WindForm } from "../components/loop";

export enum AppDialog {
    Connect = "Connect",
    TransactionSender = "Send transactions",
    WalletConnect = "Connect wallet",
    Wallet = "Wallet",
    MarketGuide = "MarketGuide",

    LoanActions = "LoanActions",
    RefinanceSettings = "RefinanceSettings",

    UpdateStrategyApyAndCollateral = "UpdateStrategyApyAndCollateral",

    // auth
    AddMember = "Add member",
    AddGroup = "Add organization",
    ModifyLoanRoles = "Modify roles",
    ModifyAdminRoles = "Modify permissions",
    DeleteMember = "Delete member",
    VerifyMember = "Verify member",
    CreateCustodianInvite = "Create invite",
    KYC = "KYC",

    AirtableInterestForm = "Airtable",

    StrategyDelete = "Delete position",
    StrategyManageEscrow = "Deposit/Withdraw",
    StrategyTerms = "Update terms",
    StrategySettings = "Settings",

    MultiSelectCollateral = "MultiSelectCollateral",

    SelectToken = "Select token",
    BorrowSelectCollateral = "Select collateral",
    ReferralEnterCode = "Accept a referral",
    Lend = "Lend",
    Borrow = "Borrow",
    LendApy = "LendApy",
    WindSettings = "WindSettings",
    LoopExternalTransfers = "LoopExternalTransfers",

    AccessLevelNotConnected = "AccessLevelNotConnected",
    AccessLevelWaitlist = "AccessLevelWaitlist",

    Notifications = "Notifications",

    VaultManageEscrow = "VaultManageEscrow",
    VaultWithdrawSlippage = "VaultWithdrawSlippage",

    TransactionSettings = "TransactionSettings"
}

type Map = {
    [AppDialog.TransactionSender]: TransactionsDialogData;
    [AppDialog.MultiSelectCollateral]: {
        header?: string;
        collateral: string[];
        tokens: TokenListMetadata[] | undefined;
        customMarketInformation?: string;
        setCollateral?: FormInputType<CollateralWithTerms[]>;
        setCollateralAsync?: (collateral: CollateralWithTerms[]) => Promise<Result<unknown>>;
        existingStrategy?: StrategyExpanded;
        principalMint?: string;
        isAdvanced: boolean;
    };
    [AppDialog.SelectToken]: {
        tokens: TokenListMetadata[] | undefined;
        setToken: (token: TokenListMetadata) => void;
        header?: string;
    };

    [AppDialog.BorrowSelectCollateral]: {
        marketProps: MarketProps;
        strategyDuration: StrategyDuration;
        setToken: (token: Collateral) => void;
    };

    [AppDialog.KYC]: { ctaText?: string };

    [AppDialog.AddMember]: void;
    [AppDialog.AddGroup]: void;

    [AppDialog.StrategyDelete]: { strategy: StrategyExpanded };
    [AppDialog.StrategyManageEscrow]: { strategy: StrategyExpanded; side: "deposit" | "withdraw" };
    [AppDialog.StrategyTerms]: { strategy: StrategyExpanded };
    [AppDialog.StrategySettings]: { strategy: StrategyExpanded };

    [AppDialog.LoanActions]: {
        loan: AbfLoanExpanded;
        action: { component: ReactNode; label: string };
        includeLoanHeader?: boolean;
        isDrawer?: boolean;
        hideHeader?: boolean;
    };
    [AppDialog.RefinanceSettings]: { loan: AbfLoanExpanded };
    [AppDialog.Lend]: { token: TokenListMetadata };
    [AppDialog.UpdateStrategyApyAndCollateral]: {
        token: TokenListMetadata;
        strategy: StrategyExpanded;
    };
    [AppDialog.Borrow]: { token: TokenListMetadata };

    [AppDialog.MarketGuide]: {
        mode: MarketGuideMode;
        isForced?: boolean;
        isNestedDialog?: boolean;
        loopExpanded?: LoopExpanded; //Added for loops guide
        vaultExpanded?: LendingVaultExpanded;
    };

    [AppDialog.LendApy]: {
        setApy: (apy: number | undefined) => void;
        marketMinApy: number;
        defaultRate: number;
        currentApy: number;
    };
    [AppDialog.WindSettings]: { loopExpanded: LoopExpanded; form: WindForm; setForm: DispatchType<WindForm> };
    [AppDialog.LoopExternalTransfers]: { loopExpanded: LoopExpanded };

    [AppDialog.AccessLevelNotConnected]: void;
    [AppDialog.AccessLevelWaitlist]: void;

    [AppDialog.Notifications]: void;
    [AppDialog.ReferralEnterCode]: { initialCode?: string };

    [AppDialog.VaultManageEscrow]: { vaultExpanded: LendingVaultExpanded; side: "deposit" | "withdraw" };
    [AppDialog.TransactionSettings]: void;
    [AppDialog.VaultWithdrawSlippage]: { slippageSetting: number; setSlippageSetting: DispatchType<number> };
};

export type AppDialogData<T extends AppDialog> = T extends keyof Map ? Map[T] : void;

export function useAppDialog() {
    const dialog = useDialog();

    function open<T extends AppDialog>(type: T, data: AppDialogData<T>, addToStack = false) {
        if (addToStack) {
            dialog.addToStack<AppDialogData<T>>(type, data);
        } else {
            dialog.open<AppDialogData<T>>(type, data);
        }
    }

    function getData<T extends AppDialog>(identifier: T) {
        return dialog.getData<AppDialogData<T>>(identifier);
    }

    const openIdentifiers = dialog.openIdentifiers as AppDialog[];

    return {
        close: dialog.close,
        open,
        getData,
        isOpen: dialog.isOpen,
        openIdentifiers,
        setPreventClose: dialog.setPreventClose,
        preventClose: dialog.preventClose
    };
}
