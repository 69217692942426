import { memo, useMemo } from "react";

import { Column, TagTextAlert } from "@bridgesplit/ui";
import { COPY } from "app/constants";
import { useOraclePrices } from "@bridgesplit/abf-react";
import { filterTruthy } from "@bridgesplit/utils";

import { AppButton, useBalanceChecker } from "../../common";
import { useMarketLend } from "../util";
import { useMarketLendContext } from "./MarketLendContext";
import { useMarketContext } from "../common";

export default memo(function LendCta() {
    const { form, resetForm } = useMarketLendContext();
    const { principalMint } = useMarketContext();
    const lend = useMarketLend();
    const { insufficientBalance } = useBalanceChecker({
        mint: principalMint,
        amount: form.amount
    });

    const alertError = useMemo(() => {
        if (insufficientBalance) return "Insufficient balance";

        return undefined;
    }, [insufficientBalance]);

    const ctaError = useMemo(() => {
        if (!form.amount) return "Enter an amount";
        if (!form.collateral.length) return "Select collateral";
        if (![...form.strategyDurationToApy.values()].filter(filterTruthy).length) return "Enter offer terms";
        return undefined;
    }, [form.amount, form.collateral.length, form.strategyDurationToApy]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                isTransaction
                asyncCta={{
                    onClickWithResult: () => lend({ form }),
                    options: { alertOnError: true, onSuccess: resetForm }
                }}
                color="secondary"
                disabled={!!ctaError || !!alertError}
            >
                {ctaError ?? `Create ${COPY.STRATEGY_TERM.toLowerCase()}`}
            </AppButton>
        </Column>
    );
});

const MIN_USD_AMOUNT = 250;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LowAmountWarning() {
    const { principalMint } = useMarketContext();
    const {
        form: { amount }
    } = useMarketLendContext();
    const { getOracle } = useOraclePrices([principalMint]);

    const usdPrice = getOracle(principalMint)?.getUsdAmount(amount);

    if (!usdPrice || usdPrice > MIN_USD_AMOUNT) {
        return null;
    }

    return (
        <TagTextAlert color="warning" icon="tooltip">
            Small deposits are unlikely to be borrowed and may remain idle for extended periods
        </TagTextAlert>
    );
}
