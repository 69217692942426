import {
    UserVaultPositionExpanded,
    useTokenListMetadataByMints,
    BsMetaUtil,
    useOraclePrices,
    getVaultIdleBalance
} from "@bridgesplit/abf-react";
import { TextSkeleton, Row, TextButton, Text } from "@bridgesplit/ui";
import { formatUsd, getUnixTs } from "@bridgesplit/utils";
import { SwapHoriz } from "@mui/icons-material";
import { useLocalStorage } from "@solana/wallet-adapter-react";

enum VaultDisplayType {
    UsdCostBasis,
    TokenCostBasis
}

interface VaultAmountDisplayProps {
    vaultPosition: UserVaultPositionExpanded | undefined;
    variant: "total" | "available";
}
// Move hook to top level to share state across components
const useVaultDisplayType = () => {
    const [displayType, setDisplayType] = useLocalStorage("VAULT_DISPLAY_TYPE", VaultDisplayType.TokenCostBasis);
    return { displayType, setDisplayType };
};

function useVaultAmounts(vaultPosition: UserVaultPositionExpanded | undefined, variant: "total" | "available") {
    const { getUsdPrice } = useOraclePrices([vaultPosition?.vaultExpanded.vault.principalMint]);

    if (!vaultPosition || !getUsdPrice) return { amountUsd: 0, amountPrincipal: 0, amountLp: 0 };

    const { vaultExpanded } = vaultPosition;
    const { totalAmount, amountAvailable } = vaultPosition;
    const { vaultStrategy } = vaultExpanded;

    const idleBalance = getVaultIdleBalance(vaultExpanded, getUnixTs());
    const principalMint = vaultStrategy.strategy.principalMint;
    const idleUsdBalance = idleBalance * (getUsdPrice(principalMint) ?? 0);

    const tokenAccountBalance = vaultStrategy.strategy.tokenBalance ?? 0;
    const tokenBalanceUsd = tokenAccountBalance * (getUsdPrice(principalMint) ?? 0);

    const amountUsd =
        variant === "total"
            ? totalAmount.amountUsd
            : Math.min(amountAvailable.amountUsd, tokenBalanceUsd + idleUsdBalance);

    const amountPrincipal =
        variant === "total"
            ? totalAmount.amountPrincipal
            : Math.min(amountAvailable.amountPrincipal, idleBalance + tokenAccountBalance);

    const amountLp = variant === "total" ? totalAmount.amountLp : amountAvailable.amountLp;

    return {
        amountUsd,
        amountPrincipal: amountPrincipal,
        amountLp: amountLp
    };
}

export function VaultAmountDisplay({ vaultPosition, variant }: VaultAmountDisplayProps) {
    const { displayType, setDisplayType } = useVaultDisplayType();
    const mints = [vaultPosition?.vaultExpanded.vault.principalMint];
    const amount = useVaultAmounts(vaultPosition, variant);
    const { getMetadata } = useTokenListMetadataByMints(mints);

    if (!vaultPosition) return <TextSkeleton variant="body1" width="60px" />;

    const { vaultExpanded } = vaultPosition;

    return (
        <Row spacing={0.5}>
            {vaultPosition?.vaultExpanded.vault.depositsEnabled && (
                <TextButton
                    color="disabled"
                    onClick={() => {
                        const nextType = {
                            [VaultDisplayType.UsdCostBasis]: VaultDisplayType.TokenCostBasis,
                            [VaultDisplayType.TokenCostBasis]: VaultDisplayType.UsdCostBasis
                        }[displayType];
                        setDisplayType(nextType);
                    }}
                >
                    <SwapHoriz />
                </TextButton>
            )}
            {displayType === VaultDisplayType.UsdCostBasis && <Text>{formatUsd(amount.amountUsd)} </Text>}
            {displayType === VaultDisplayType.TokenCostBasis && (
                <Text>
                    {BsMetaUtil.formatAmount(getMetadata(vaultExpanded.vault.principalMint), amount.amountPrincipal)}
                </Text>
            )}
        </Row>
    );
}
