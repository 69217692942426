import { useCallback, useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
    OracleQuote,
    OrcaPositionAmounts,
    TokenListMetadata,
    TokenPositionExpanded,
    WhirlpoolPositionExpanded
} from "@bridgesplit/abf-sdk";
import { filterTruthy, formatAddress, lamportsToUiAmount, removeDuplicates } from "@bridgesplit/utils";

import { useTokenListMetadataByMints, useWhirlpoolPositionsQuery } from "../reducers";
import { useUserUnrecognizedAssets } from "./assets";
import { getUsdAmountFromUsdPrice, useOraclePrices } from "./pricing";
import { AssetTypeIdentifier, TokenBalanceExpanded } from "../types";

export const MAX_TICK_INDEX = 443636;
export const MIN_TICK_INDEX = -443636;
export const MAX_SQRT_PRICE = "79226673515401279992447579055";
export const MIN_SQRT_PRICE = "4295048016";

export function useUserWhirlpoolPositions(options?: { skip?: boolean }) {
    const { data: walletAssets } = useUserUnrecognizedAssets(!!options?.skip);

    const nftMints = useMemo(() => {
        return walletAssets?.filter((d) => d.tokenAmount.decimals === 0).map((d) => d.mint);
    }, [walletAssets]);
    return useWhirlpoolPositions(nftMints, options);
}

export function useWhirlpoolPositions(nftMints: string[] | undefined, options?: { skip?: boolean }) {
    const {
        data: rawData,
        isLoading: whirlpoolLoading,
        isFetching
    } = useWhirlpoolPositionsQuery(nftMints ? removeDuplicates(nftMints) : skipToken, {
        skip: !nftMints?.length || options?.skip
    });

    const mints = Object.values(rawData?.whirlpools ?? {})
        .map((w) => [w.tokenMintA, w.tokenMintB])
        .flat();

    const { getOracle, isLoading: pricesLoading } = useOraclePrices(mints);
    const { getMetadata, isLoading: metadataLoading } = useTokenListMetadataByMints(mints);

    const isLoading = whirlpoolLoading || pricesLoading || metadataLoading;

    const getTokenDetail = useCallback(
        (mint: string, lamportAmount: number): (TokenPositionExpanded & { oracle: OracleQuote }) | undefined => {
            const metadata = getMetadata(mint);
            const oracle = getOracle(mint);
            if (!metadata || !oracle) return undefined;
            const amount = lamportsToUiAmount(lamportAmount, metadata.decimals);
            return {
                mint,
                decimals: metadata.decimals,
                metadata,
                usdPrice: oracle.usdPrice,
                oracle,
                usdAmount: oracle.getUsdAmount(amount),
                amount
            };
        },
        [getMetadata, getOracle]
    );

    const data = useMemo(() => {
        if (nftMints?.length === 0) return [];
        if (!rawData || isLoading) return undefined;
        const allPositions = Object.values(rawData.whirlpoolsToPositions).flat();

        return allPositions
            .map((position): WhirlpoolPositionExpanded | null => {
                const whirlpool = rawData.whirlpools[position.whirlpool];
                if (!whirlpool) return null;
                const tokenA = getTokenDetail(whirlpool.tokenMintA, position.liquidityAmounts[whirlpool.tokenMintA]);
                const tokenB = getTokenDetail(whirlpool.tokenMintB, position.liquidityAmounts[whirlpool.tokenMintB]);
                const whirlpoolMetadata = getMetadata(position.whirlpool);

                if (!tokenA || !tokenB || !whirlpoolMetadata) return null;
                const totalPrice = tokenA.usdAmount + tokenB?.usdAmount;

                const oracle: OracleQuote = {
                    usdPrice: totalPrice,
                    getUsdAmount: (...params) =>
                        getUsdAmountFromUsdPrice(
                            totalPrice,
                            tokenA.oracle.uncertainty + tokenB.oracle.uncertainty,
                            ...params
                        ),
                    oracleAccount: position.whirlpool,
                    baseQuotes: [...tokenA.oracle.baseQuotes, ...tokenB.oracle.baseQuotes],
                    uncertainty: tokenA.oracle.uncertainty + tokenB.oracle.uncertainty
                };

                return {
                    totalPrice,
                    whirlpoolAddress: position.whirlpool,
                    whirlpoolMetadata,
                    tokenA,
                    tokenB,
                    whirlpool,
                    position,
                    oracle
                };
            })
            .filter(filterTruthy);
    }, [getMetadata, getTokenDetail, isLoading, nftMints?.length, rawData]);

    return { data, isLoading, isFetching };
}

export function isWhirlpoolMetadata(metadata: TokenListMetadata | undefined) {
    if (!metadata) return false;
    return metadata.assetType === AssetTypeIdentifier.OrcaPosition;
}

export const whirlpoolPositionToToken = (whirlpoolPosition: WhirlpoolPositionExpanded): TokenBalanceExpanded => {
    const {
        whirlpoolMetadata,
        position: { positionMint: mint }
    } = whirlpoolPosition;
    return {
        amount: 1,
        metadata: {
            ...whirlpoolMetadata,
            mint,
            name: `Orca LP (${formatAddress(mint)})`
        },
        key: mint,
        whirlpoolPosition
    };
};

export function parseOrcaAmounts(whirlpoolPosition: WhirlpoolPositionExpanded, amounts: OrcaPositionAmounts) {
    return Object.entries(amounts).map(([mint, amount]) => {
        const token = mint === whirlpoolPosition.tokenA.mint ? whirlpoolPosition.tokenA : whirlpoolPosition.tokenB;
        const uiAmount = lamportsToUiAmount(amount, token.metadata.decimals);
        const usdAmount = uiAmount * token.usdPrice;

        return { mint, uiAmount, usdAmount };
    });
}

export function getClaimableWhirlpoolFees(whirlpoolPosition?: WhirlpoolPositionExpanded) {
    if (!whirlpoolPosition) return 0;
    const { position } = whirlpoolPosition;
    const orcaAmounts = parseOrcaAmounts(whirlpoolPosition, position.feeAmounts);
    return orcaAmounts.reduce((prev, curr) => prev + curr.usdAmount, 0);
}
