import { MediaQuery, StatProps, StatRow } from "@bridgesplit/ui";
import { BsMetaUtil, calculateTotalInterestAccrued, StrategyExpanded } from "@bridgesplit/abf-react";
import { bsMath, formatDate, formatPercent, formatPercentArray } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { useMediaQuery } from "@mui/material";
import { RewardsStrategyApy } from "app/components/points";

import { PositionCardStats, TokenAmountWithUsd } from "../../common";
import { calculateStrategyUtilization } from "../util";

const variant = "body1" as const;
export default function StrategyCardStats({
    strategyExpanded,
    query,
    mode
}: {
    strategyExpanded: StrategyExpanded | undefined;
    query: MediaQuery;
    mode: "loans" | "terms";
}) {
    const isLoading = !strategyExpanded;
    const isMobile = useMediaQuery(query.below);

    const { utilization } = calculateStrategyUtilization(strategyExpanded);
    const interestEarned = calculateTotalInterestAccrued(strategyExpanded, {
        includeCumulativeInterest: true,
        includeExternalInterest: true,
        includeStrategyInterest: true
    });

    const targetApy: number[] =
        strategyExpanded?.collateral.flatMap((collateral) => Object.values(collateral.durationAndApys)) ?? [];

    const totalPosition = bsMath.add(strategyExpanded?.totalBalance, strategyExpanded?.strategy.currentDeployedAmount);

    const stats: StatProps[] = [
        {
            value: strategyExpanded ? <RewardsStrategyApy strategyExpanded={strategyExpanded} /> : undefined,
            caption: "APY",
            hide: !isMobile && mode !== "loans"
        },

        {
            value: formatPercentArray(targetApy),
            caption: COPY.STRATEGY_APY_TERM,
            hide: mode !== "terms"
        },
        {
            value: formatPercent(utilization),
            caption: "Utilization",
            tooltip: `${BsMetaUtil.formatAmount(
                strategyExpanded?.principalMetadata,
                strategyExpanded?.strategy.currentDeployedAmount
            )} deployed in loans`,
            hide: mode !== "terms"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(totalPosition, strategyExpanded?.principalUsdPrice)}
                    amount={totalPosition}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Total supplied",
            hide: mode !== "loans"
        },

        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(
                        strategyExpanded?.strategy.currentDeployedAmount,
                        strategyExpanded?.principalUsdPrice
                    )}
                    amount={strategyExpanded?.strategy.currentDeployedAmount}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Deployed",
            hide: mode !== "loans"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(strategyExpanded?.totalBalance, strategyExpanded?.principalUsdPrice)}
                    amount={strategyExpanded?.totalBalance}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Available",
            tooltip: "Funds that are unused in loans and available with withdraw",
            hide: mode !== "terms"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amount={interestEarned}
                    amountUsd={bsMath.mul(interestEarned, strategyExpanded?.principalUsdPrice)}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: COPY.INTEREST_EARNED_LABEL
        },
        {
            value: formatDate(strategyExpanded?.strategy.createdAt),
            caption: "Creation date",
            hide: mode !== "terms"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(
                        strategyExpanded?.strategy.cumulativePrincipalOriginated,
                        strategyExpanded?.principalUsdPrice
                    )}
                    amount={strategyExpanded?.strategy.cumulativePrincipalOriginated}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: COPY.ORIGINATED_ALL_TIME_TERM,
            hide: mode !== "loans"
        }
    ];

    if (mode === "terms") {
        return <PositionCardStats loading={isLoading} stats={stats} />;
    }

    return <StatRow variant={variant} loading={isLoading} stats={stats} />;
}
