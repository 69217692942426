import { memo, useState, useEffect } from "react";

import { Collapse } from "@mui/material";
import { LabelWrapper, FormInput, PercentInput, Column } from "@bridgesplit/ui";
import { COPY } from "app/constants";

import { MarketLendForm } from "./type";
import { useMarketLendContext } from "./MarketLendContext";
import LendAdditionalInfoHeader from "./LendAdditionalInfoHeader";

type ConfigurationValue = {
    label: string;
    tooltip: string;
    value?: number;
    key: keyof MarketLendForm;
    type: "percent" | "number";
};

function getConfigurations(form: MarketLendForm): ConfigurationValue[] {
    return [
        {
            label: COPY.LIQUIDITY_BUFFER,
            tooltip: COPY.LIQUIDITY_BUFFER_TOOLTIP,
            value: form.liquidityBuffer,
            key: "liquidityBuffer",
            type: "number"
        },
        {
            label: COPY.MAX_ORIGINATION_SIZE,
            tooltip: COPY.MAX_ORIGINATION_SIZE_TOOLTIP,
            value: form.originationCap,
            key: "originationCap",
            type: "number"
        },
        {
            label: COPY.INTEREST_FEE,
            tooltip: COPY.INTEREST_FEE_TOOLTIP,
            value: form.interestFee,
            key: "interestFee",
            type: "percent"
        },
        {
            label: COPY.ORIGINATION_FEE,
            tooltip: COPY.ORIGINATION_FEE_TOOLTIP,
            value: form.originationFee,
            key: "originationFee",
            type: "percent"
        }
    ];
}

export default memo(function LendAdditionalInfo({ hide = false }: { hide?: boolean }) {
    const { form, setForm } = useMarketLendContext();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!form.amount) {
            if (form.liquidityBuffer || form.interestFee || form.originationCap || form.originationFee) {
                setForm((prev) => ({
                    ...prev,
                    liquidityBuffer: undefined,
                    interestFee: undefined,
                    originationCap: undefined,
                    originationFee: undefined
                }));
            }
        }
    }, [form.amount, form.liquidityBuffer, form.interestFee, form.originationCap, form.originationFee, setForm]);

    const handleSetValue = (key: keyof typeof form, value: number | undefined) => {
        setForm((prev) => ({ ...prev, [key]: value }));
    };

    function validateNumber(num: number | undefined) {
        return form.amount && num !== undefined ? num <= form.amount : true;
    }

    function validatePercent(num: number | undefined) {
        return num !== undefined ? (num <= 100 && num > 0 ? true : false) : true;
    }

    if (hide) return null;

    return (
        <Column>
            <LendAdditionalInfoHeader form={form} expanded={expanded} setExpanded={setExpanded} />
            <Collapse in={expanded}>
                <Column mt={1}>
                    {getConfigurations(form).map(({ label, tooltip, value, type, key }) => (
                        <LabelWrapper key={key} label={label} tooltip={tooltip}>
                            {type === "percent" ? (
                                <PercentInput
                                    value={value ?? undefined}
                                    setValue={(n) => handleSetValue(key, n)}
                                    validateNumber={validatePercent}
                                    disabled={!form.amount}
                                />
                            ) : (
                                <FormInput
                                    value={value ?? undefined}
                                    validateNumber={validateNumber}
                                    variant="number"
                                    setValue={(n) => handleSetValue(key, n)}
                                    disabled={!form.amount}
                                />
                            )}
                        </LabelWrapper>
                    ))}
                </Column>
            </Collapse>
        </Column>
    );
});
