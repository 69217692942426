import { memo, useCallback } from "react";

import {
    Column,
    Icon,
    InputWrapper,
    Row,
    Text,
    TextButton,
    TextSkeleton,
    TooltipText,
    useAppPalette,
    useMemoizedKeyMap
} from "@bridgesplit/ui";
import { formatTokenMetadata } from "@bridgesplit/abf-react";
import { filterUndefined } from "@bridgesplit/utils";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { AppDialog } from "app/utils";
import { COPY } from "app/constants";

import { useMarketContext, useQuotesContext } from "../common";
import { useMarketLendContext } from "./MarketLendContext";
import { OverlappingMetadataImages } from "../../common";
import { useMarketCollateral, useMarketDialog } from "../util";

export default memo(function LendCollateralSelect({ isStat }: { isStat?: boolean }) {
    const { form } = useMarketLendContext();

    const tokens = useMarketCollateral();
    const tokensByMint = useMemoizedKeyMap(tokens, (t) => t.mint);

    // iterate through form.collateral instead of tokens to preserve selection order
    const selectedTokens: TokenListMetadata[] | undefined = form.collateral
        .map((c) => tokensByMint?.get(c))
        .filter(filterUndefined);

    if (isStat) {
        return (
            <Row spaceBetween>
                <TooltipText helpText={COPY.ALLOWED_COLLATERAL_TOOLTIP} color="caption">
                    {COPY.ALLOWED_COLLATERAL_TERM}
                </TooltipText>
                <StatSelect selectedTokens={selectedTokens} />
            </Row>
        );
    }

    return (
        <Column spacing={0.5}>
            <TooltipText helpText={COPY.ALLOWED_COLLATERAL_TOOLTIP} variant="body2">
                {COPY.ALLOWED_COLLATERAL_TERM}
            </TooltipText>
            <CustomSelection selectedTokens={selectedTokens} />
        </Column>
    );
});

function StatSelect({ selectedTokens }: { selectedTokens: TokenListMetadata[] | undefined }) {
    const onClick = useSelectCollateral();

    return (
        <Row spacing={1}>
            {!!selectedTokens?.length && (
                <OverlappingMetadataImages maxLength={3} size="20px" metadata={selectedTokens} />
            )}
            {!selectedTokens && <TextSkeleton variant="body1" />}
            {selectedTokens?.length === 0 && <Text color="disabled">None</Text>}
            <TextButton onClick={onClick} color="caption" variant="body2">
                <Icon type="edit" />
            </TextButton>
        </Row>
    );
}

function CustomSelection({ selectedTokens }: { selectedTokens: TokenListMetadata[] | undefined }) {
    const { hoverBackground } = useAppPalette();

    const onClick = useSelectCollateral();

    return (
        <InputWrapper
            onClick={onClick}
            sx={{ justifyContent: "space-between", cursor: "pointer", ":hover": { background: hoverBackground } }}
        >
            {!!selectedTokens?.length && (
                <Row spacing={1}>
                    <OverlappingMetadataImages
                        disableTooltip
                        maxLength={3}
                        showPlus={false}
                        size="20px"
                        metadata={selectedTokens}
                    />
                    <Text>{formatTokenMetadata(selectedTokens, "tokens")}</Text>
                </Row>
            )}
            {!selectedTokens && <TextSkeleton variant="body1" />}
            {selectedTokens?.length === 0 && <Text color="disabled">None</Text>}
            <TextButton color="caption" variant="body2">
                <Icon type="edit" />
            </TextButton>
        </InputWrapper>
    );
}

function useSelectCollateral() {
    const { principalMint } = useMarketContext();

    const { form, setForm } = useMarketLendContext();

    const { setCollateralMints: setQuoteCollateral } = useQuotesContext();
    const tokens = useMarketCollateral();

    const { open } = useMarketDialog();

    return useCallback(
        () =>
            open(AppDialog.MultiSelectCollateral, {
                tokens,
                principalMint,
                isAdvanced: true,
                collateral: form.collateral,
                setCollateral: (collateral) => {
                    const collateralMints = collateral.map((c) => c.metadata.mint);
                    setQuoteCollateral(collateralMints);
                    setForm((prev) => ({
                        ...prev,
                        collateral: collateralMints
                    }));
                }
            }),
        [form.collateral, open, principalMint, setForm, setQuoteCollateral, tokens]
    );
}
