import { IS_LOCAL_NX_DEV } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";

// luke is a good test wallet LoTy38EiLYg85rWq5okYjNwzQECGbYa6uPcJPj8MHu2
// jason -> 67VABVhoSdcWy29XPHs4a9z99ggmWTgenLGxeWYo3o62
// jason -> PJU6yfZGRALLmstEAozLRAStz2t6RaXd48ttu5GVUpz
const debugWallet: string | null = null;
export const DEBUG_WALLET = IS_LOCAL_NX_DEV ? debugWallet : null;
export const useUserPublicKey = (): string | undefined => {
    const wallet = useWallet();
    if (DEBUG_WALLET) return DEBUG_WALLET;
    return wallet ? wallet.publicKey?.toString() : undefined;
};
