import { LoopscalePointsAction, PointsInfoDetails, ExternalPointSource } from "../types";

export const LOOPSCALE_POINTS_SOURCE_INFO: Record<LoopscalePointsAction, PointsInfoDetails> = {
    [LoopscalePointsAction.Borrow]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Borrow boost"
    },
    [LoopscalePointsAction.Lend]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Lend boost"
    },
    [LoopscalePointsAction.IdleCap]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Idle cap boost"
    },
    [LoopscalePointsAction.LiquidStake]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Lend vault boost"
    },
    [LoopscalePointsAction.ThirtyDayVest]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "30d vault boost"
    },
    [LoopscalePointsAction.SixtyDayVest]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "60d vault boost"
    },
    [LoopscalePointsAction.NintyDayVest]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "90d vault boost"
    },
    [LoopscalePointsAction.OneHundredEightyDayVest]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "180d vault boost"
    }
};

export const POINTS_SOURCE_INFO: Record<ExternalPointSource, PointsInfoDetails> = {
    [ExternalPointSource.MarginFi]: {
        externalPointSource: ExternalPointSource.MarginFi,
        caption: "mrgn rewards"
    },
    [ExternalPointSource.LoopscaleHidden]: {
        externalPointSource: ExternalPointSource.LoopscaleHidden,
        caption: "Total multiplier"
    },
    [ExternalPointSource.Loopscale]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Total multiplier"
    },
    [ExternalPointSource.Jupiter]: {
        externalPointSource: ExternalPointSource.Jupiter,
        caption: "Jupiter rewards"
    },
    [ExternalPointSource.Meteora]: {
        externalPointSource: ExternalPointSource.Meteora,
        caption: "Meteora rewards"
    },
    [ExternalPointSource.HubSol]: {
        externalPointSource: ExternalPointSource.HubSol,
        caption: "Loyalty league rewards"
    },
    [ExternalPointSource.Solayer]: {
        externalPointSource: ExternalPointSource.Solayer,
        caption: "Solayer rewards"
    },
    [ExternalPointSource.Adrastea]: {
        externalPointSource: ExternalPointSource.Adrastea,
        caption: "Adrastea Points"
    }
};
// The USD per unit amount. Currently points per day is the rate per usd per $1000
export const BASE_PPS = 0.0005; //FALLBACK VALUE
export const BASE_REFFERAL_PPS = 0;

interface LoopscalePointCaptionInfo {
    full: string;
    short: string;
}

export const LOOPSCALE_POINT_CAPTIONS: Record<LoopscalePointsAction, LoopscalePointCaptionInfo> = {
    [LoopscalePointsAction.Borrow]: {
        full: "Borrow multiplier",
        short: "Borrow"
    },
    [LoopscalePointsAction.Lend]: {
        full: "Lend multiplier",
        short: "Lend"
    },
    [LoopscalePointsAction.IdleCap]: {
        full: "Idle capital multiplier",
        short: "Idle capital"
    },
    [LoopscalePointsAction.LiquidStake]: {
        full: "Lend vault multiplier",
        short: "Lend vault"
    }
};
