import { useMemo } from "react";

import { ExpandContent, WalletButtons, WalletDisplaySmall } from "@bridgesplit/ui";
import { useActiveWallet } from "@bridgesplit/abf-react";

export default function WalletNotConnected() {
    const { activeWallet } = useActiveWallet();

    const header = useMemo(() => {
        if (!activeWallet) return "";

        if (activeWallet.length > 1) return "Please connect one of your self-custodial wallets";

        return "Please connect your self-custodial wallet";
    }, [activeWallet]);

    return (
        <>
            <ExpandContent
                hideExpand={!activeWallet}
                defaultExpanded
                textVariant="body2"
                textColor="caption"
                spacing={0.5}
                header={header}
            >
                <WalletDisplaySmall key={activeWallet} address={activeWallet} />
            </ExpandContent>
            <WalletButtons />
        </>
    );
}
