import { LoopscalePointsAction, ExternalPointReward } from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";

export type GenericRewardsApyProps = {
    apy: number | undefined;
    loopscalePointsAction: LoopscalePointsAction;
    metadata: TokenListMetadata | undefined;
    externalPointRewards: ExternalPointReward[] | undefined;
};

export type RewardsLendVaultApyProps = GenericRewardsApyProps & {
    isFeatured: boolean; //Used to control the rate display width only for featured & mobile
};

export type RewardsLendApyProps = GenericRewardsApyProps;

export type RewardsBorrowApyProps = Omit<GenericRewardsApyProps, "externalPointRewards">;

export type RewardsLoopsApyProps = GenericRewardsApyProps & {
    maxLeverage: number; //Used to show max leverage based on loops
    isFeatured: boolean; //Used to control the rate display width only for featured loops & mobile
    loopAmount: number | undefined; //Used to show max leverage based on loop. Must be provided to see how much $1000 leveraged at maximum leverage can borrow you in principal.
};

export type RewardsPortfolioBorrowApyProps = GenericRewardsApyProps & {
    customDailyPoints: number | undefined;
};

export const POINT_BASIS = 1000;
