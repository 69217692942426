import { formatTokenAmount, formatUsd } from "@bridgesplit/utils";
import {
    DenominationDisplay,
    isRecentVaultDepositOrWithdrawal,
    LendingVaultExpanded,
    useActiveWallet,
    useOraclePrices,
    UserVaultPnl,
    useUserVaultPnlFromPosition
} from "@bridgesplit/abf-react";
import { OutlinedCard, StatRow } from "@bridgesplit/ui";

import { useVaultContext } from "./VaultContext";
import { ProfitLossText } from "../common/util";
import { COPY } from "../../constants";
import { VaultPendingPnl } from "./position";

interface VaultDepositStats {
    totalDepositsPrincipal: number | undefined;
    totalDepositsUsd: number | undefined;
}

export function VaultDepositCard() {
    const { activeWallet } = useActiveWallet();
    const { selectedDenomination, vaultExpanded, userPosition, userPositionLoading } = useVaultContext();

    const isUsd = selectedDenomination === DenominationDisplay.UsdBasis;

    const userVaultPnl = useUserVaultPnlFromPosition(userPosition);

    const { getUsdPrice } = useOraclePrices([vaultExpanded?.vault.principalMint]);

    const borrowStats = useUserVaultDepositStats();
    const principalPrice = getUsdPrice(vaultExpanded?.vault.principalMint);
    const pnlStats = getVaultPnLStats(userVaultPnl, isUsd, vaultExpanded, principalPrice);

    const formattedDeposits = isUsd
        ? formatUsd(borrowStats?.totalDepositsUsd ?? 0)
        : formatTokenAmount(borrowStats?.totalDepositsPrincipal ?? 0, {
              decimals: vaultExpanded?.principalMetadata?.decimals,
              symbol: vaultExpanded?.principalMetadata?.symbol
          });

    const stats = [
        {
            value: formattedDeposits,
            caption: "Your deposits"
        },
        ...pnlStats
    ];

    const loading = userPositionLoading || !vaultExpanded;
    if (!activeWallet) return null;

    return (
        <OutlinedCard>
            <StatRow variant="body1" captionVariant="body2" stats={stats} loading={loading} spacing={2} />
        </OutlinedCard>
    );
}

function useUserVaultDepositStats(): VaultDepositStats | undefined {
    const { vaultExpanded, userPosition: vaultPosition, userPositionLoading } = useVaultContext();

    if (userPositionLoading || !vaultExpanded || !vaultPosition) return undefined;

    const totalDepositsUsd = vaultPosition.totalAmount.amountUsd;

    const totalDepositsPrincipal = vaultPosition.totalAmount.amountPrincipal;

    return {
        totalDepositsPrincipal,
        totalDepositsUsd
    };
}

export function getVaultPnLStats(
    userVaultPnl: UserVaultPnl | undefined,
    isUsd: boolean,
    vaultExpanded: LendingVaultExpanded | undefined,
    principalPrice: number | undefined
) {
    const isPnlPending = isRecentVaultDepositOrWithdrawal(userVaultPnl?.mostRecentDepositOrWithdrawalTimestamp);

    const hideLifetimePnl = !userVaultPnl?.lifetimePnl || isPnlPending;
    const hideCurrentPnl = !userVaultPnl?.currentPnl || isPnlPending;

    const stats = [
        {
            value: userVaultPnl?.currentPnl ? (
                <PnLDisplay
                    vaultExpanded={vaultExpanded}
                    value={userVaultPnl.currentPnl}
                    isUsd={isUsd}
                    principalPrice={principalPrice}
                />
            ) : undefined,
            caption: "Current " + COPY.INTEREST_EARNED_LABEL.toLowerCase(),
            hide: hideCurrentPnl
        },
        {
            value: userVaultPnl?.lifetimePnl ? (
                <PnLDisplay
                    vaultExpanded={vaultExpanded}
                    value={userVaultPnl.lifetimePnl}
                    isUsd={isUsd}
                    principalPrice={principalPrice}
                />
            ) : undefined,
            caption: "Lifetime " + COPY.INTEREST_EARNED_LABEL.toLowerCase(),
            hide: hideLifetimePnl
        },
        {
            value: <VaultPendingPnl />,
            caption: COPY.INTEREST_EARNED_LABEL,
            hide: !isPnlPending
        }
    ];

    return stats;
}

function PnLDisplay({
    vaultExpanded,
    value,
    isUsd,
    principalPrice
}: {
    vaultExpanded: LendingVaultExpanded | undefined;
    value: number;
    isUsd: boolean;
    principalPrice: number | undefined;
}) {
    if (isUsd && principalPrice) {
        return <ProfitLossText fontWeight="normal" variant="body1" profitLossUsd={value * principalPrice} />;
    }

    return (
        <ProfitLossText
            fontWeight="normal"
            variant="body1"
            profitLossTokenAmount={value}
            metadata={vaultExpanded?.principalMetadata}
        />
    );
}
