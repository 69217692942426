import {
    DurationType,
    OracleQuote,
    ExternalYieldVault,
    StrategyDuration,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";

import { TokenBalance } from "./escrow";

export type MarketExpanded = {
    usdPrice: number | null;
    principalStats: MarketPrincipalStats;
    strategyStats: PrincipalMarketStrategyStats;
    metadata: TokenListMetadata;
    borrowCap: BorrowCap | null;
    yieldVault: ExternalYieldVault | null;
};

export type MarketDetailStats = {
    principalStats: MarketPrincipalStats;
    strategyStats: PrincipalMarketStrategyStats;
};

export interface MarketsFilter {
    principal: string[];
    force?: boolean;
}

export interface DurationToMinApyItem {
    duration: number;
    durationType: number;
    apy: number;
}

export interface RawPrincipalMarketStrategyStats {
    totalDeposits: number;
    durationToMinApy: Record<string, number>;
}

export interface PrincipalMarketStrategyStats {
    totalDeposits: number;
    durationToMinApy: DurationToMinApyItem[];
}

export interface MarketStratResponse {
    [marketId: string]: PrincipalMarketStrategyStats;
}
interface DurationToMinApy {
    [key: string]: number;
}

interface TotalDepositsData {
    totalDeposits: number;
    durationToMinApy: DurationToMinApy;
}

export interface RawMarketStratResponse {
    [mint: string]: TotalDepositsData;
}

export interface Collateral extends TokenBalance {
    oracle: OracleQuote | null;
    usdPrice: number;
    usdValue: number;
}

export type MaxQuoteFromCollateralAmount = MaxQuote & {
    maxBorrow: number;
};

export interface CollateralWithMaxQuote extends Collateral {
    maxQuote: MaxQuoteFromCollateralAmount | undefined;
}

interface QuoteFilterBase {
    principal: string;
    minPrincipalAmount?: number;
    duration: number;
    durationType: DurationType;
    limit: number;
    offset: number;
    lendingStrategyAddress?: string;
}

export interface CollateralQuoteFilter extends QuoteFilterBase {
    collateral: string[];
}

export interface CollateralQuoteFilterForApy extends CollateralQuoteFilter {
    collateral: string[];
    apyRange: [number, number];
}

export interface CustodianQuoteFilter extends QuoteFilterBase {
    custodian: string;
}

export interface MaxQuoteRequest {
    collateralToInfo: Record<string, QuoteCollateralInfo>;
    principal: string;
    duration: number;
    durationType: DurationType;
}

export interface BestQuoteFilter {
    collateralToInfo: Record<string, QuoteCollateralInfo>;
    principalMint: string;
    durations: StrategyDuration[];
    minPrincipalAmount: number;
    showSelf?: boolean;
}

export interface QuoteCollateralInfo {
    amount: number;
    assetType: number;
    assetMint: string;
    assetIdentifier: string;
}

export interface PresetsFilter {
    collateralMints?: string[];
    principalMints?: string[];
}
export interface SellLoanQuote {
    quote: BestSellQuote;
    salePrice: number;
    fairSalePriceAtQuoteRate: number;
    valueLeft: number;
    saleTime: number;
}

export type RawMaxQuote = [
    number, // apy,
    string, // lendingStrategyAddress,
    number, // lendingStrategyLtv,
    number, // lendingStrategyLiquidationThreshold,
    number // strategyCollateralAmount
];

export type OrderbookQuote = {
    apy: number;
    maxPrincipalAvailable: number;
    sumPrincipalAvailable: number;
};

export type OrderbookQuoteForApy = {
    collateral: string;
    maxPrincipalAvailable: number;
    sumPrincipalAvailable: number;
};

export type MaxQuote = {
    apy: number;
    lendingStrategyAddress: string;
    ltv: number;
    liquidationThreshold: number;
    principalAvailable: number;
    collateralMint: string;
};

export type BestQuote = {
    duration: number;
    durationType: DurationType;
    lendingStrategyAddress: string;
    apy: number;
    principalAvailable: number;
    ltv: number;
    liquidationThreshold: number;
    collateralMint: string; // added in FE
};

export type BestSellQuote = BestQuote;
export interface BorrowCap {
    principalMint: string;
    perLoan: number;
    global: number;
}

export type MarketPrincipalStats = {
    principalUtilized: number;
    principalOriginated: number;
};

export type RatesHistoryParams = {
    principalMints: string[];
    timeLookback: number;
    onlyLoopscale?: boolean;
};
export type LoopVaultBorrowRateQuery = {
    loopVaultIdentifier: string;
    timeLookback: number;
};

export enum RateMarketType {
    Loopscale,
    KaminoMain,
    MarginFi,
    Solend
}

export type HistoricalMarketRate = {
    id: number;
    marketType: RateMarketType;
    principalMint: "string";
    timestamp: number;
    borrowApy: number;
    lendApy: number;
    duration: number;
    durationType: DurationType;
};

export type HistoricalLoopVaultBorrowRate = {
    id: number;
    timestamp: number;
    borrowRate: number;
    loopVaultIdentifier: string;
};

export interface CollateralYieldHistory {
    id: number;
    collateralMint: string;
    apy: number;
    timestamp: number;
}

export type EstimatedRefinanceInfo = {
    collateralMint: string;
    ltv: number;
    liquidationThreshold: number;
};

export type RefinanceInfoResponse = Record<string, EstimatedRefinanceInfo[]>;

// principal to collateral mints
export type RefinanceInfoParams = Record<string, string[]>;

export interface IncreasePrincipalQuote {
    apy: number;
    amountLeft: number;
    strategyAddress: string;
    ltv: number;
    liquidationThreshold: number;
}
