import { QuestionToggleProps } from "@bridgesplit/ui";
import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { JLP_MINT } from "@bridgesplit/utils";
import { isLstLoop } from "@bridgesplit/abf-react";

import { usePerpsFaq } from "./perps";
import { useLoopContext } from "../../LoopContext";
import { useMeteoraFaq } from "./meteora";
import { useLstFaq } from "./lst";

export function useLoopFaq(): QuestionToggleProps[] | undefined {
    const { loopExpanded } = useLoopContext();
    const perps = usePerpsFaq();
    const meteora = useMeteoraFaq();
    const lst = useLstFaq();

    if (!loopExpanded) return undefined;

    if (isLstLoop(loopExpanded)) return lst;
    if (
        (loopExpanded.loopVault.routeType === LoopRouteType.Jup &&
            loopExpanded.loopVault.collateralMint === JLP_MINT) ||
        loopExpanded.loopVault.routeType === LoopRouteType.Flash
    )
        return perps;
    if (loopExpanded.loopVault.routeType === LoopRouteType.Meteora) return meteora;
    return undefined;
}
