import { useState } from "react";

import { Button, Column, RefreshButtonWithLoading, Row, Text, TextButton } from "@bridgesplit/ui";
import {
    useAbfFetches,
    useActiveWallet,
    useOraclePrices,
    useTokensByWalletQuery,
    useWalletBalanceByMint
} from "@bridgesplit/abf-react";
import { RECOMMENDED_SOL_BALANCE, SOL_SYMBOL, WRAPPED_SOL_MINT, formatUsd } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ChainId } from "@bridgesplit/abf-sdk";

import { DialogHeader, QrCodeCopyAddress } from "../common";

export default function useMinTransactionBalance() {
    const [completeState, setComplete] = useState<boolean>();

    const { insufficientBalance, isLoading } = useBalance();

    const balanceCheckNeeded = completeState ?? (!isLoading && insufficientBalance);

    const Component = () => <BalancePrompt next={() => setComplete(false)} />;

    return { balanceCheckNeeded, BalancePrompt: Component };
}

function BalancePrompt({ next }: { next: () => void }) {
    const { activeWallet, solBalance, insufficientBalance } = useBalance();
    const solUsdPrice = useOraclePrices([WRAPPED_SOL_MINT]).getUsdPrice(WRAPPED_SOL_MINT);

    return (
        <>
            <DialogHeader
                header="Low wallet balance"
                description="Your transaction is likely to fail due to insufficient funds for network fees"
            />
            <Text color="caption" variant="body2">
                {`Send SOL from an exchange or another wallet. We recommend depositing ${RECOMMENDED_SOL_BALANCE} ${SOL_SYMBOL} ${
                    solUsdPrice ? `(${formatUsd(solUsdPrice * RECOMMENDED_SOL_BALANCE)})` : ""
                }`}
            </Text>
            <QrCodeCopyAddress
                label="Your Solana address"
                chainId={ChainId.Solana}
                size="normal"
                specifiedMint={WRAPPED_SOL_MINT}
                address={activeWallet}
            />
            <Column spacing={1}>
                <Row spacing={0.5}>
                    <Text color="caption" variant="body2">
                        Balance: {solBalance} {SOL_SYMBOL}
                    </Text>

                    <RefreshBalance />
                </Row>
                <Button variant="contained" disabled={insufficientBalance} onClick={next}>
                    Continue
                </Button>

                {!!solBalance && (
                    <TextButton variant="body2" color="caption" onClick={next}>
                        Continue with low balance
                    </TextButton>
                )}
            </Column>
        </>
    );
}

function RefreshBalance() {
    const { resetUserAssetsApi } = useAbfFetches();

    return <RefreshButtonWithLoading variant="body2" isFetching={false} callback={resetUserAssetsApi} />;
}

function useBalance() {
    const { user } = useActiveWallet();

    const { activeWallet } = useActiveWallet();
    const { data, isLoading } = useWalletBalanceByMint(activeWallet, WRAPPED_SOL_MINT, {
        fullSolBalance: true,
        skip: !user
    });

    // poll sol balance
    useTokensByWalletQuery(activeWallet ?? skipToken, {
        skip: !activeWallet,
        pollingInterval: 3_000
    });

    const solBalance = data?.amount || 0;
    const insufficientBalance = solBalance < RECOMMENDED_SOL_BALANCE;

    return { solBalance, insufficientBalance, activeWallet, isLoading };
}
