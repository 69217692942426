import { colorToAlpha } from "@bridgesplit/utils";
import { useTheme } from "@mui/material";

import { TextColor, TextVariant, TagColor, RawTagColor } from "../components";
import { ThemeMode } from "../types/theme";
import { COLOR_EXTENSIONS } from "./extensions";

export function useAppPalette() {
    const { palette } = useTheme();

    const themeMode = palette.mode;
    const isDarkMode = themeMode === "dark";
    const isLightMode = themeMode === "light";
    const hoverOpacity = isDarkMode ? 0.1 : 0.05;
    const backgroundOpacity = isDarkMode ? 0.2 : 0.1;

    return {
        themeMode,
        backgroundOpacity,
        background: palette.background.default,
        cardBackground: isDarkMode ? colorToAlpha(BRAND_COLORS.SKY, 0.05) : BRAND_COLORS.WHITE,
        paperBackground: palette.background.paper,
        hoverBackground: colorToAlpha(palette.secondary.main, hoverOpacity),
        secondaryBackground: colorToAlpha(palette.secondary.main, backgroundOpacity),
        hoverOpacity,
        divider: palette.divider,
        inputBorder: palette.divider,
        inputBorderFocused: palette.text.disabled,

        info: palette.info.main,
        isDarkMode,
        isLightMode,
        primary: palette.primary.main,
        secondary: palette.secondary.main,
        prime: "#F5BE53",
        primeBackground: colorToAlpha("#F5BE53", hoverOpacity),
        border: `1px solid ${palette.divider}`,
        success: palette.success.main,
        error: palette.error.main,
        warning: palette.warning.main,
        textDisabled: palette.text.disabled,
        textPrimary: palette.text.primary,
        textSecondary: palette.text.secondary
    };
}

const SECONDARY_TEXT_COLOR = "#276ED6";
export function useTextColorMap() {
    const { palette } = useTheme();
    const { isDarkMode } = useAppPalette();

    const multiTextColorMap = {
        primary: palette.primary.main,
        secondary: palette.secondary.main,
        body: palette.text.primary,
        caption: palette.text.secondary,
        disabled: palette.text.disabled,
        success: palette.success.main,
        info: palette.info.main,
        error: palette.error.main,
        warning: palette.warning.main,
        white: "#FFFFFF",
        black: "#000000"
    };

    return (color: TextColor, isGraphic?: boolean) => {
        if (!isGraphic && isDarkMode && color === "secondary") {
            return SECONDARY_TEXT_COLOR;
        }
        return multiTextColorMap[color];
    };
}

export function useColorFromTag(color: TagColor) {
    const { secondary, info, error, success, textSecondary, warning, isDarkMode, textDisabled } = useAppPalette();
    const colorMap: Record<RawTagColor, string> = {
        body: textSecondary,
        info,
        warning,
        success,
        error,
        secondary: isDarkMode ? SECONDARY_TEXT_COLOR : secondary,
        disabled: textDisabled
    };

    if (typeof color === "number") {
        const { dark, light } = COLOR_EXTENSIONS[color];
        return isDarkMode ? dark : light;
    }

    return colorMap[color];
}

export const FONT_SIZES: Record<TextVariant, number> = {
    h1: 28,
    h2: 24,
    h3: 20,
    h4: 16,
    body1: 16,
    body2: 14,
    caption: 12
};

export const SPACING = 8;
export const SPACING_PX = `${SPACING}px`;

export const BORDER_RADIUS = `${SPACING + 4}px`;
export const BORDER_RADIUS_SM = `${SPACING / 2}px`;
export const BORDER_RADIUS_ROUNDED = "100px";

export const LINE_HEIGHT_MULTIPLIER = 1.3;
export const INPUT_HEIGHT = SPACING * 5;
export const DIALOG_PADDING = 2;
export const DIALOG_WINDOW_CENTER_MIN_HEIGHT = 800;

export const BRAND_COLORS = {
    BLACK: "#151619",
    WHITE: "#FFFFFF",
    LIGHT: "#F8F9FB",
    BLUE: "#276ED6",
    GRAY: "#6E8591",
    SKY: "#DBDEF7",
    ORANGE: "#FD7448",
    CREAM: "#E2DEDD"
};

export const LIGHT_SHADOW = "0px 0px 10px 0px rgba(0, 0, 0, 0.02)";
export const LIGHT_SMALL_SHADOW = `0px 0px 10px rgba(0, 0, 0, 0.02)`;
export const SMALL_SHADOW = `0px 0px 10px rgba(0, 0, 0, 0.08)`;
export const HEAVY_SHADOW = `0px 0px 10px rgba(0, 0, 0, 0.2)`;

export const THEME_LOCAL_STORAGE_KEY = "THEME";
export const DEFAULT_THEME_MODE: ThemeMode = "light";

export const FONT_WEIGHTS = { normal: 400, bold: 500, logo: 500 };

export const FONT_VARIANT_WEIGHTS: Record<TextVariant, number> = {
    h1: FONT_WEIGHTS.bold,
    h2: FONT_WEIGHTS.bold,
    h3: FONT_WEIGHTS.bold,
    h4: FONT_WEIGHTS.bold,
    body1: FONT_WEIGHTS.normal,
    body2: FONT_WEIGHTS.normal,
    caption: FONT_WEIGHTS.normal
};
