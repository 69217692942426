import { StatColumn, StatProps } from "@bridgesplit/ui";
import { formatNum, formatUsd } from "@bridgesplit/utils";
import { BsMetaUtil, DenominationDisplay, LendingVaultExpanded } from "@bridgesplit/abf-react";

import { useVaultContext } from "../../VaultContext";

export default function AllocationStats() {
    const { vaultExpanded, selectedDenomination } = useVaultContext();

    const activeLoanCount = vaultExpanded?.vaultStrategy.strategy.activeLoanCount ?? 0;

    const stats: StatProps[] = [
        {
            value: getDeployedAmount(selectedDenomination, vaultExpanded),
            caption: "Deployed"
        },
        {
            value: `${formatNum(activeLoanCount)} loan${activeLoanCount === 1 ? "" : "s"}`,
            caption: "Ongoing"
        }
    ];

    return <StatColumn loading={!vaultExpanded} stats={stats} />;
}

function getDeployedAmount(
    selectedDenomination: DenominationDisplay | undefined,
    vaultExpanded: LendingVaultExpanded | undefined
) {
    const deployedAmount = vaultExpanded?.vaultStrategy.strategy.currentDeployedAmount;

    if (selectedDenomination === DenominationDisplay.UsdBasis) {
        return formatUsd(vaultExpanded?.principalOracle.getUsdAmount(deployedAmount));
    }

    return BsMetaUtil.formatAmount(vaultExpanded?.principalMetadata, deployedAmount);
}
