import { getMeteoraDepositTransaction, getMeteoraWithdrawTransaction } from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { Result } from "@bridgesplit/utils";
import { Meteora } from "@bridgesplit/bs-protos";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction } from "./common";

export function useMeteoraDepositTransaction(): AbfTransactionDetails<Meteora.DynamicPoolDepositParams> {
    const generate = useAbfGenerateTransaction();
    const { resetMeteoraApi, resetUserAssetsApi } = useAbfFetches();

    async function getTransactionsWithParams(params: Meteora.DynamicPoolDepositParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getMeteoraDepositTransaction,
                identifier: "LP deposit",
                params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetMeteoraApi();
            resetUserAssetsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Depositing to Meteora" };
}

export function useMeteoraWithdrawTransaction(): AbfTransactionDetails<Meteora.DynamicPoolWithdrawParams> {
    const generate = useAbfGenerateTransaction();
    const { resetMeteoraApi, resetUserAssetsApi } = useAbfFetches();

    async function getTransactionsWithParams(params: Meteora.DynamicPoolWithdrawParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getMeteoraWithdrawTransaction,
                identifier: "LP withdraw",
                params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetMeteoraApi();
            resetUserAssetsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing from Meteora" };
}
