import { useMemo } from "react";

import {
    LoopInfoParams,
    JupiterQuoteParams,
    JupiterSwapMode,
    UserLoopFilter,
    StrategyDuration,
    TokenListTag,
    TokenListMetadata,
    UncertaintyType,
    JupiterPriceResponse,
    LoopRouteType,
    UserLoopInfo
} from "@bridgesplit/abf-sdk";
import {
    decimalsToBps,
    filterNullableRecord,
    formatNum,
    getUnixTs,
    greaterThan,
    IS_LOCAL_NX_DEV,
    lamportsToUiAmount,
    NullableRecord,
    percentUiToDecimals,
    roundDownToDecimals,
    SortDirection,
    TIME,
    uiAmountToLamports,
    WRAPPED_SOL_MINT
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";
import {
    LoopWindRouteRequest,
    WindRouteStep,
    LoopUnwindRouteRequest,
    UnwindRouteStep,
    LoopRouteResponse
} from "@loopscale/pandora";

import {
    useTokenListMetadataByMints,
    useLoopBestQuoteQuery,
    useLoopInfosQuery,
    useUserLoopsQuery,
    useAllMarketPrincipalStatsQuery,
    useFlashPerpsQuery,
    useJupiterPriceQuoteQuery,
    useLoopWindQuoteIxsQuery,
    useLoopUnwindQuoteIxsQuery,
    LoopUnwindRouteRequestExtended
} from "../reducers";
import {
    AbfLoanExpanded,
    BestLoopQuoteQuery,
    ExternalUnwindQuoteParams,
    ExternalWindQuoteParams,
    LoopTransferType,
    LoopExpanded,
    LoopPositionExpanded,
    LoopQuoteExpanded,
    LoopExpandedBase,
    AbfOrderFundingType,
    PriceFetchType,
    LoopExpandedMeteora
} from "../types";
import { useOraclePrices } from "./pricing";
import { isLoopLoan, useAbfTypesToUiConverter } from "../utils";
import { getZcLedger, isLoanActive, useLoanInfos } from "./loans";
import { getBorrowCapDetails, useMarketBorrowCaps } from "./markets";
import { DEFAULT_STRATEGY_DURATION } from "../constants";
import { useActiveWallet } from "./wallet";

export function useLoopsExpanded(params: LoopInfoParams, options?: { skip?: boolean }) {
    const query = useLoopInfosQuery(params, { skip: options?.skip });

    const collateralMints = useMemo(
        () => Object.values(query.data ?? {}).map((loop) => loop.collateralMint),
        [query.data]
    );
    const principalMints = useMemo(
        () => Object.values(query.data ?? {}).map((loop) => loop.principalMint),
        [query.data]
    );

    const allMints = useMemo(() => [...collateralMints, ...principalMints], [collateralMints, principalMints]);
    const { getMetadata, isLoading: metadataLoading } = useTokenListMetadataByMints(allMints);
    const { getOracle, isLoading: marketLoading } = useOraclePrices(allMints, PriceFetchType.Twap);
    const { convertLoopVault, convertMarketPrincipalStats } = useAbfTypesToUiConverter(allMints);
    const { getBorrowCap, isLoading: borrowCapsLoading } = useMarketBorrowCaps(principalMints);

    const marketStatsQuery = useAllMarketPrincipalStatsQuery(undefined, {
        skip: !principalMints.length || options?.skip
    });

    const principalStatsMap = useMemo(
        () => (marketStatsQuery.data ? new Map(Object.entries(marketStatsQuery.data)) : undefined),
        [marketStatsQuery]
    );

    const isLoading =
        query.isLoading || metadataLoading || marketLoading || marketStatsQuery.isLoading || borrowCapsLoading;

    const data = useMemo(() => {
        if (!query.data || isLoading) return undefined;

        return Object.entries(query.data)
            .map(([vaultIdentifier, loopVaultRaw]): NullableRecord<LoopExpanded> => {
                const loopVault = convertLoopVault(loopVaultRaw);
                const borrowCap = getBorrowCap(loopVault.principalMint);
                let principalStats = principalStatsMap?.get(loopVault.principalMint);
                if (principalStats) {
                    principalStats = convertMarketPrincipalStats(loopVault.principalMint, principalStats);
                }

                const common: NullableRecord<LoopExpandedBase> = {
                    vaultIdentifier,
                    loopVault,
                    collateralToken: getMetadata(loopVault.collateralMint),
                    principalToken: getMetadata(loopVault.principalMint),
                    collateralOracle: getOracle(loopVault.collateralMint, PriceFetchType.Spot),
                    principalOracle: getOracle(loopVault.principalMint, PriceFetchType.Spot),
                    principalStats,
                    borrowCap,
                    maxCollateralAmount: loopVault.maxCollateralAmount
                };

                if (!loopVault.feVisible && !params.showHidden && !IS_LOCAL_NX_DEV) {
                    return {
                        ...common,
                        // this will disable the loop in the UI
                        tokenA: undefined,
                        depositType: LoopTransferType.CollateralOnly,
                        withdrawType: LoopTransferType.PrincipalOnly
                    };
                }

                if (loopVault.metadata && "lpMint" in loopVault.metadata) {
                    const tokenA = getMetadata(loopVault.metadata.tokenAMint);
                    const tokenB = getMetadata(loopVault.metadata.tokenBMint);
                    return {
                        ...common,
                        depositType: LoopTransferType.CollateralOnly,
                        withdrawType: LoopTransferType.PrincipalOnly,
                        meteoraPool: loopVault.metadata,
                        tokenA,
                        tokenB
                    };
                }

                if (loopVault.routeType === LoopRouteType.Stabble || loopVault.routeType === LoopRouteType.Exponent || loopVault.routeType === LoopRouteType.Ratex) {
                    return {
                        ...common,
                        depositType: LoopTransferType.CollateralOnly,
                        withdrawType: LoopTransferType.PrincipalOnly
                    };
                }


                return {
                    ...common,
                    depositType: LoopTransferType.CollateralOnly,
                    withdrawType: LoopTransferType.CollateralOnly
                };
            })
            .filter(filterNullableRecord);
    }, [
        query.data,
        isLoading,
        convertLoopVault,
        getBorrowCap,
        principalStatsMap,
        getMetadata,
        getOracle,
        convertMarketPrincipalStats
    ]);

    return { data, isLoading, isFetching: query.isFetching };
}

export function useUserLoops({
    loopsExpanded,
    filter,
    skip
}: {
    loopsExpanded: LoopExpanded[] | undefined;
    filter: UserLoopFilter;
    skip?: boolean;
}) {
    const { activeWallet } = useActiveWallet();
    const query = useUserLoopsQuery(
        activeWallet
            ? {
                  userLoopInfo: {
                      loopVaults: loopsExpanded?.map((loop) => loop.vaultIdentifier) ?? [],
                      ...filter
                  }
              }
            : skipToken,
        {
            skip: !loopsExpanded?.length || skip,
            refetchOnMountOrArgChange: true //Temporary fix
        }
    );
    const loanAddresses = useMemo(
        () => (query.data ? query.data.map((userLoop) => userLoop.loanAddress) : []),
        [query.data]
    );

    const {
        data: loanInfos,
        isLoading: loanInfosLoading,
        isFetching: loanInfosFetching
    } = useLoanInfos({
        skip: !loanAddresses.length,
        pagination: null,
        loanFilter: {
            loanAddresses,
            orderFundingTypes: [AbfOrderFundingType.Loop],
            borrowers: [],
            lenders: [],
            custodians: [],
            principalMints: [],
            saleEvents: false,
            ignoreRefinanced: false,
            sortSide: SortDirection.Asc
        }
    });
    const loanExpandedMap = useMemoizedKeyMap(loanInfos, (l) => l.loan.address);
    const loopExpandedMap = useMemoizedKeyMap(loopsExpanded, (l) => l.vaultIdentifier);

    const isLoading = query.isLoading || loanInfosLoading || query.isFetching || loanInfosFetching;
    const { convertUserLoopInfo } = useAbfTypesToUiConverter([]);

    const data = useMemo(() => {
        if (!query.data || isLoading) return undefined;

        return query.data
            .map((userLoop): NullableRecord<LoopPositionExpanded> => {
                const loanExpanded = loanExpandedMap?.get(userLoop.loanAddress);
                const loopExpanded = loopExpandedMap?.get(userLoop.loopVaultIdentifier);
                const userLoopInfo = loopExpanded ? convertUserLoopInfo(userLoop, loopExpanded) : undefined;
                return {
                    loanAddress: userLoop.loanAddress,
                    userLoopInfo,
                    loanExpanded,
                    loopExpanded
                };
            })
            .filter(filterNullableRecord);
    }, [query.data, isLoading, loopExpandedMap, convertUserLoopInfo, loanExpandedMap]);

    return { data, isLoading };
}

export function useFlashPerps(options?: { skip?: boolean }) {
    return useFlashPerpsQuery(undefined, { skip: options?.skip });
}

export function useUserLoopByLoan(loanExpanded: AbfLoanExpanded | undefined, options?: { skip?: boolean }) {
    const positionQuery = useUserLoopsQuery(
        {
            userLoopInfo: {
                page: 0,
                pageSize: 1,
                active: isLoanActive(loanExpanded),
                loanVaults: loanExpanded ? [loanExpanded.loan.address] : []
            }
        },
        {
            skip: options?.skip || !loanExpanded || !isLoopLoan(loanExpanded)
        }
    );

    const loopPosition = positionQuery.data?.find((userLoop) => userLoop.loanAddress === loanExpanded?.loan.address);
    const loopQuery = useLoopsExpanded(
        { loopVaults: loopPosition ? [loopPosition.loopVaultIdentifier] : undefined, showHidden: true },
        { skip: !loopPosition }
    );
    const loopExpanded = loopQuery.data ? Object.values(loopQuery.data)[0] : undefined;

    const isLoading = positionQuery.isLoading || loopQuery.isLoading;
    const isFetching = positionQuery.isFetching || loopQuery.isFetching;

    const { convertUserLoopInfo } = useAbfTypesToUiConverter([]);

    const data = useMemo((): LoopPositionExpanded | undefined => {
        if (!loanExpanded || !loopPosition || isLoading || !loopExpanded) return undefined;

        const userLoopInfo = convertUserLoopInfo(loopPosition, loopExpanded);
        return {
            loanAddress: loanExpanded.loan.address,
            userLoopInfo,
            loanExpanded,
            loopExpanded
        };
    }, [loanExpanded, loopPosition, isLoading, loopExpanded, convertUserLoopInfo]);

    return { data, isLoading, isFetching };
}

export function formatLeverage(leverage: number, decimals = 1) {
    return `${formatNum(leverage, { customDecimals: decimals })}x`;
}

export function useWindQuoteAndIxs({
    loopExpanded,
    principalAmount,
    skip,
    slippagePercentDecimals
}: ExternalWindQuoteParams) {
    const { activeWallet } = useActiveWallet();
    const request = useMemo(() => {
        if (!loopExpanded || !principalAmount || !slippagePercentDecimals || !activeWallet || skip) return undefined;
        const inputMint = loopExpanded.principalToken.mint;
        const outputMint = loopExpanded.collateralToken.mint;
        const routeStep: WindRouteStep = {
            inputMint,
            outputMint,
            inputAmount: uiAmountToLamports(principalAmount, loopExpanded.principalToken.decimals),
            ...loopExpanded.loopVault.quoteFetchParams
        };
        const request: LoopWindRouteRequest = {
            slippageBps: decimalsToBps(slippagePercentDecimals, "bps"),
            userWallet: activeWallet,
            routeSteps: [routeStep]
        };
        return request;
    }, [loopExpanded, principalAmount, slippagePercentDecimals, activeWallet, skip]);

    return useLoopWindQuoteIxsQuery(request ?? skipToken, {
        skip: !request || skip
    });
}

// temporary type until backend is updated, then will use type exported from pandora
type ExtendedUnwindRouteStep = UnwindRouteStep & {
    collateralDecimals: number;
    principalDecimals: number;
};

const UNWIND_REFETCH_INTERVAL = TIME.MINUTE * 2;

export function useUnwindQuoteAndIxs({
    loopPositionExpanded,
    skip,
    slippagePercentDecimals
}: ExternalUnwindQuoteParams) {
    const { activeWallet } = useActiveWallet();

    const request = useMemo(() => {
        if (!loopPositionExpanded || !slippagePercentDecimals || !activeWallet || skip) return undefined;

        const inputMint = loopPositionExpanded.loopExpanded.collateralToken.mint;
        const outputMint = loopPositionExpanded.loopExpanded.principalToken.mint;

        const collateralAmount = loopPositionExpanded.userLoopInfo.totalCollateralDepositedAmount;

        const collateralAmountLamports = uiAmountToLamports(
            collateralAmount,
            loopPositionExpanded.loopExpanded.collateralToken.decimals
        );

        const ledger = getZcLedger(loopPositionExpanded.loanExpanded);
        if (!ledger) return undefined;
        const ledgerDebtAmount = uiAmountToLamports(
            ledger.ledgerDebt.total,
            loopPositionExpanded.loopExpanded.principalToken.decimals
        );

        const routeStep: ExtendedUnwindRouteStep = {
            inputMint,
            outputMint,
            principalDecimals: loopPositionExpanded.loopExpanded.principalToken.decimals,
            collateralDecimals: loopPositionExpanded.loopExpanded.collateralToken.decimals,
            ledgerDebtAmount,
            totalCollateralAmount: collateralAmountLamports,
            ...loopPositionExpanded.loopExpanded.loopVault.quoteFetchParams
        };
        const request: LoopUnwindRouteRequestExtended = {
            slippageBps: decimalsToBps(slippagePercentDecimals, "bps"),
            userWallet: activeWallet,
            routeSteps: [routeStep],
            loanAddress: loopPositionExpanded.loanAddress,
        };
        return request;
    }, [loopPositionExpanded, slippagePercentDecimals, activeWallet, skip]);

    return useLoopUnwindQuoteIxsQuery(request ?? skipToken, {
        skip: !request || skip,
        refetchOnMountOrArgChange: UNWIND_REFETCH_INTERVAL,
    });
}

export const MAX_JUP_ACCOUNTS = 36;

export const DEFAULT_JUP_QUOTE_PARAMS: Omit<
    JupiterQuoteParams,
    "amount" | "slippageBps" | "inputMint" | "outputMint" | "outputMint"
> = {
    maxAccounts: MAX_JUP_ACCOUNTS,
    restrictIntermediateTokens: true,
    swapMode: JupiterSwapMode.ExactIn,
    onlyDirectRoutes: false
};

export function useLoopCollateralPrice({
    tokens,
    options
}: {
    tokens: TokenListMetadata[] | undefined;
    options?: { skip?: boolean };
}) {
    const isActive = !!tokens?.length;

    const { data: jupiterPrices, isError: priceError } = useJupiterPriceQuoteQuery(
        {
            mints: tokens ? tokens.map((token) => token.mint) : []
        },
        {
            skip: options?.skip || !isActive || !tokens
        }
    );

    return { data: jupiterPrices, isError: priceError };
}

export function calculateLtvFromLeverage(leverageMultiplier: number) {
    return 1 - 1 / leverageMultiplier;
}

function getLoopLeveragedAmounts({
    loopExpanded,
    leverageMultiplier,
    collateralAmount
}: {
    loopExpanded: LoopExpanded;
    leverageMultiplier: number;
    collateralAmount: number;
}) {
    const leveragedCollateralAmount = collateralAmount * leverageMultiplier;
    const collateralUsd = loopExpanded.collateralOracle.getUsdAmount(
        leveragedCollateralAmount,
        undefined,
        UncertaintyType.Subtract
    );

    const principalUsd = collateralUsd * calculateLtvFromLeverage(leverageMultiplier);
    const principalAmount = roundDownToDecimals(
        principalUsd / (loopExpanded.principalOracle.usdPrice + loopExpanded.principalOracle.uncertainty),
        loopExpanded.principalToken.decimals
    );

    return {
        principalAmount,
        leveragedCollateralAmount
    };
}

type LoopBestQuoteParams = {
    loopExpanded: LoopExpanded | undefined;
    collateralAmountUi: number | undefined;
    leverageMultiplier: number | undefined;
    slippagePctDecimals?: number;
    strategyDuration: StrategyDuration;
};
export function useLoopBestQuote(params: LoopBestQuoteParams) {
    const { convertOfferQuote } = useAbfTypesToUiConverter([]);
    const quoteParams = useMemo((): BestLoopQuoteQuery | undefined => {
        if (
            !params.loopExpanded ||
            !params.collateralAmountUi ||
            !isValidLeverage(params.leverageMultiplier) ||
            !params.strategyDuration
        )
            return undefined;
        return {
            loopVault: params.loopExpanded.vaultIdentifier,
            collateralAmount: uiAmountToLamports(
                params.collateralAmountUi,
                params.loopExpanded.collateralToken.decimals
            ),
            duration: params.strategyDuration,
            leverageMultiplier: params.leverageMultiplier
        };
    }, [params]);

    const { data: rawData, ...query } = useLoopBestQuoteQuery(quoteParams ?? skipToken, {
        skip: !quoteParams
    });

    const data = useMemo((): LoopQuoteExpanded | null | undefined => {
        if (!params.loopExpanded || !quoteParams || !params.collateralAmountUi) return undefined;
        if (!rawData) return rawData;
        const collateralAmount = params.collateralAmountUi;
        const bestQuote = convertOfferQuote(rawData.bestQuote, params.loopExpanded.principalToken.mint);
        const { principalAmount, leveragedCollateralAmount } = getLoopLeveragedAmounts({
            loopExpanded: params.loopExpanded,
            leverageMultiplier: quoteParams.leverageMultiplier - (params.slippagePctDecimals ?? 0.05),
            collateralAmount
        });
        return {
            ...rawData,
            netApyPct: percentUiToDecimals(rawData.netApyPct),
            bestQuote,
            leverageMultiplier: quoteParams.leverageMultiplier - (params.slippagePctDecimals ?? 0.05),
            collateralAmount,
            principalAmount,
            leveragedCollateralAmount
        };
    }, [
        params.loopExpanded,
        params.collateralAmountUi,
        params.slippagePctDecimals,
        quoteParams,
        rawData,
        convertOfferQuote
    ]);

    return { data, ...query };
}

export function usePrincipalBorrowedWithLeverage({
    loopExpanded,
    loopLeverage,
    totalAmount
}: {
    loopExpanded: LoopExpanded | undefined;
    loopLeverage: number | undefined;
    totalAmount: number;
}) {
    const quoteParams = useMemo((): LoopBestQuoteParams => {
        return {
            loopExpanded,
            leverageMultiplier: loopLeverage,
            collateralAmountUi: totalAmount,
            strategyDuration: DEFAULT_STRATEGY_DURATION
        };
    }, [loopExpanded, loopLeverage, totalAmount]);

    const { data: quote, isLoading: isQuoteLoading } = useLoopBestQuote(quoteParams);

    const principalBorrowedWithLeverageUsd = useMemo(() => {
        if (!quote?.principalAmount || !loopExpanded?.principalOracle.usdPrice) {
            return undefined;
        }
        return quote.principalAmount * loopExpanded.principalOracle.usdPrice;
    }, [quote, loopExpanded]);

    return {
        principalBorrowedWithLeverageUsd,
        isLoading: isQuoteLoading
    };
}

export function calculateLoopNetApy({
    collateralYield,
    leverageMultiplier,
    principalBorrowRate
}: {
    collateralYield: number;
    leverageMultiplier: number;
    principalBorrowRate: number;
}) {
    const collateralWeighted = leverageMultiplier;
    const principalWeighted = collateralWeighted * calculateLtvFromLeverage(leverageMultiplier);
    const netApy = collateralWeighted * collateralYield - principalWeighted * principalBorrowRate;
    return netApy;
}

export function getLoopBorrowCapDetails(loopExpanded: LoopExpanded | undefined) {
    if (!loopExpanded) return undefined;
    return getBorrowCapDetails({
        borrowCap: loopExpanded.borrowCap,
        token: loopExpanded.principalToken,
        principalUtilized: loopExpanded.principalStats.principalUtilized
    });
}

export function useAllUserLoops(filter: UserLoopFilter) {
    const { data: loops } = useLoopsExpanded({
      showHidden: true,
    });
    return useUserLoops({ loopsExpanded: loops, filter, skip: !loops });
}

// leverage must be at least 1 to start a loan
export const isValidLeverage = (leverageMultiplier: number | undefined): leverageMultiplier is number =>
    greaterThan(leverageMultiplier, 1);

interface UnwindTokenStats {
    receiveTokenAmount: number;
    receiveUsdAmount: number;
    tokenToReceive: TokenListMetadata | undefined;
    oraclePercentDifference: number;
    quotePrice: number;
    oraclePrice: number;
}

interface ProfitLossStats {
    profitLossUsd: number;
    profitLossTokenAmount: number | undefined;
    contributions: ReturnType<typeof sumLoopPositionUsdContributions>;
    tokenStats: UnwindTokenStats | null;
}

export function calculateUnwindStats({
    loopPosition,
    externalQuote,
    jupiterPrices
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: LoopRouteResponse | undefined;
    jupiterPrices: JupiterPriceResponse;
}): ProfitLossStats {
    const { loopExpanded } = loopPosition;
    const contributions = sumLoopPositionUsdContributions(loopPosition);
    const tokenStats = (() => {
        if (loopExpanded.withdrawType === LoopTransferType.CollateralOnly) {
            return calcUnwindStatsCollateral({ loopPosition, externalQuote, jupiterPrices });
        }
        return calcUnwindStatsPrincipal({ loopPosition, externalQuote, jupiterPrices });
    })();

    const profitLossUsd = tokenStats.receiveUsdAmount - contributions.totalContributionUsd;
    let profitLossTokenAmount = undefined;
    if (loopExpanded.withdrawType !== LoopTransferType.PrincipalOnly) {
        profitLossTokenAmount = tokenStats.receiveTokenAmount - contributions.totalCollateralContributionAmount;
    }
    return { tokenStats, profitLossUsd, profitLossTokenAmount, contributions };
}

function calcUnwindStatsPrincipal({
    loopPosition,
    externalQuote
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: LoopRouteResponse | undefined;
    jupiterPrices: JupiterPriceResponse;
}): UnwindTokenStats {
    const { loopExpanded, loanExpanded } = loopPosition;

    const principalSwapped = (() => {
        if (loopExpanded.loopVault.routeType === LoopRouteType.Jup) {
            return 0;
        }
        if (externalQuote) {
            return lamportsToUiAmount(externalQuote.minOutputAmount, loopPosition.loopExpanded.principalToken.decimals);
        }
        return (
            calculateNetLoopPositionUsd({ loopExpanded, loopInfo: loopPosition.userLoopInfo, loanExpanded }) /
            loopExpanded.principalOracle.usdPrice
        );
    })();

    const firstLedger = getZcLedger(loanExpanded);

    const receiveTokenAmount = Math.max(principalSwapped - (firstLedger?.ledgerDebt.total ?? 0), 0);
    const receiveUsdAmount = loopExpanded.principalOracle.getUsdAmount(receiveTokenAmount);
    const tokenToReceive = loopExpanded.principalToken;
    const oraclePrice = loopExpanded.principalOracle.usdPrice;

    const quotePrice = (() => {
        switch (loopExpanded.loopVault.routeType) {
            case LoopRouteType.Meteora || LoopRouteType.Stabble || LoopRouteType.Exponent || LoopRouteType.Ratex:
                return receiveUsdAmount / receiveTokenAmount; //Recieved usd amount uses regular oracle, should be no discrepancy
            default:
                return 0; //only meteora is supported for principal swaps
        }
    })();

    const oraclePercentDifference = (quotePrice - oraclePrice) / oraclePrice;

    return {
        receiveTokenAmount,
        receiveUsdAmount,
        tokenToReceive,
        oraclePercentDifference,
        quotePrice,
        oraclePrice
    };
}

function calculateNetLoopPositionUsd({
    loopExpanded,
    loanExpanded
}: {
    loopExpanded: LoopExpanded;
    loopInfo: UserLoopInfo;
    loanExpanded: AbfLoanExpanded;
}) {
    const zcLedger = getZcLedger(loanExpanded);

    const ledgerDebtUsd = (zcLedger?.ledgerDebt.total ?? 0) * loopExpanded.principalOracle.usdPrice;
    const loanCollateralUsd =
        loanExpanded.collateral[0]?.loanCollateral.amount * loopExpanded.collateralOracle.usdPrice;
    return Math.max(loanCollateralUsd - ledgerDebtUsd, 0);
}

function calcUnwindStatsCollateral({
    loopPosition,
    externalQuote,
    jupiterPrices
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: LoopRouteResponse | undefined;
    jupiterPrices: JupiterPriceResponse;
}): UnwindTokenStats {
    const { userLoopInfo, loopExpanded, loanExpanded } = loopPosition;

    const inputCollateral = externalQuote
        ? lamportsToUiAmount(externalQuote.inputAmount, loopPosition.loopExpanded.collateralToken.decimals)
        : undefined;

    const receiveTokenAmount = inputCollateral
        ? Math.max(userLoopInfo.totalCollateralDepositedAmount - inputCollateral, 0)
        : calculateNetLoopPositionUsd({ loopExpanded, loopInfo: userLoopInfo, loanExpanded }) /
          loopExpanded.collateralOracle.usdPrice;

    const receiveUsdAmount = loopExpanded.collateralOracle.getUsdAmount(receiveTokenAmount);

    const tokenToReceive = loopExpanded.collateralToken;

    const quotePrice = (() => {
        switch (loopExpanded.loopVault.routeType) {
            case LoopRouteType.Jup:
                return Number(jupiterPrices.data[loopExpanded.collateralToken.mint]?.price ?? 0);
            default:
                return 0; //only jupiter is supported for collateral swaps
        }
    })();

    const oraclePrice = loopExpanded.collateralOracle.usdPrice;

    const oraclePercentDifference = (quotePrice - oraclePrice) / oraclePrice;

    return {
        receiveTokenAmount,
        receiveUsdAmount,
        tokenToReceive,
        oraclePercentDifference,
        quotePrice,
        oraclePrice
    };
}

const HISTORICAL_PRICE_CRON_INTERVAL = TIME.MINUTE * 15;
export function calculateHistoricalLoopProfitLoss(position: LoopPositionExpanded): ProfitLossStats {
    const { userLoopInfo, loopExpanded } = position;

    const collateralPrice =
        isLoanActive(position.loanExpanded) || !userLoopInfo.endCollateralUsdPrice
            ? loopExpanded.collateralOracle.usdPrice
            : userLoopInfo.endCollateralUsdPrice;

    const contributions = sumLoopPositionUsdContributions(position);
    const profitLossTokenAmount =
        position.userLoopInfo.totalCollateralDepositedAmount - position.userLoopInfo.netPositionValue / collateralPrice;

    const profitLossUsd =
        position.userLoopInfo.totalCollateralDepositedAmount * position.userLoopInfo.initialCollateralPrice -
        contributions.totalContributionUsd;

    return { profitLossUsd, contributions, profitLossTokenAmount, tokenStats: null };
}

export function sumLoopPositionUsdContributions({ userLoopInfo, loopExpanded, loanExpanded }: LoopPositionExpanded) {
    // cron updates rates every 15 minutes so oracle price might be more recent than userLoopInfo.initialCollateralPrice
    const recentStartTime = Math.max(getUnixTs() - loanExpanded.loan.startTime, 0) < HISTORICAL_PRICE_CRON_INTERVAL;
    const startingCollateralPrice = recentStartTime
        ? loopExpanded.collateralOracle.usdPrice
        : userLoopInfo.initialCollateralPrice;

    const initialDepositAmount = userLoopInfo.initialCollateralAmount;

    const initialCollateralUsd = initialDepositAmount * startingCollateralPrice;

    return {
        initialCollateralUsd,
        initialCollateralAmount: initialDepositAmount,
        totalCollateralContributionUsd: initialCollateralUsd + userLoopInfo.additionalCollateralDepositsUsd,
        totalCollateralContributionAmount: initialDepositAmount + userLoopInfo.additionalCollateralDepositedAmount,
        repaymentsUsd: userLoopInfo.totalPaidUsd,
        totalContributionUsd:
            initialCollateralUsd + userLoopInfo.additionalCollateralDepositsUsd + userLoopInfo.totalPaidUsd,
        totalPaidUsd: userLoopInfo.totalPaidUsd,
        additionalDepositsUsd: userLoopInfo.additionalCollateralDepositsUsd + userLoopInfo.totalPaidUsd
    };
}

export function isLstLoop(loop: LoopExpanded) {
    return loop.collateralToken.tags?.includes(TokenListTag.LST) && loop.principalToken.mint === WRAPPED_SOL_MINT;
}

export function isExponentLoop(loop: LoopExpanded) {
    return loop.loopVault.routeType === LoopRouteType.Exponent;
}

export function isRatexLoop(loop: LoopExpanded) {
    return loop.loopVault.routeType === LoopRouteType.Ratex;
}

export function isMeteora(loopExpanded: LoopExpanded): loopExpanded is LoopExpandedMeteora {
    return "meteoraPool" in loopExpanded && "tokenA" in loopExpanded && "tokenB" in loopExpanded;
}
