const DOCS_ROOT = "https://docs.loopscale.com";

export const DOCS = {
    root: DOCS_ROOT,
    borrowTutorial: `${DOCS_ROOT}/concepts/loopscale/borrowing`,
    lendTutorial: `${DOCS_ROOT}/concepts/loopscale/lending`,
    prepayment: `${DOCS_ROOT}/concepts/loopscale/borrowing/#prepayment-penalty`,
    loansInfo: `${DOCS_ROOT}/concepts/protocol-concepts/bilateral-loans`,
    lendVaultTutorial: `${DOCS_ROOT}/concepts/loopscale/lending`
};
