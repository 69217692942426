import { AbfLoanExpanded } from "@bridgesplit/abf-react";

import { ActionProps } from "./types";
import SinglePayment from "./payments/SinglePayment";

export default function MakeRepayment({ loanExpanded }: ActionProps) {
    return <RepaymentActions loanExpanded={loanExpanded} />;
}

// with multiple ledgers, should let lender pick ledger and then update props to take in ledgerIndex
function RepaymentActions({ loanExpanded }: { loanExpanded: AbfLoanExpanded | undefined }) {
    return <SinglePayment loanExpanded={loanExpanded} ledgerIndex={0} />;
}
