import { useLoanMultipliersUtil, useRewardsPointsPerSecond } from "@bridgesplit/abf-react";
import { StatProps, StatColumn, IconWithBackground, Icon } from "@bridgesplit/ui";
import { formatNum } from "@bridgesplit/utils";

import { CaptionWithIcon } from "../common";
import { calculateDailyPoints, useRewardTagColor } from "../util";
import { RewardsDailyPointsProps } from "./RewardsDailyPoints";
import { POINT_BASIS } from "../types";

// Rewards daily points display supports loops
export function RewardsDailyPointsDisplay({ loopscalePointsAction, metadata, loopAmount }: RewardsDailyPointsProps) {
    const { loanMultipliers, isLoading: loanMultipliersLoading } = useLoanMultipliersUtil();
    const { rewardPointsPerSecond, isLoading: rewardPointsPerSecondLoading } = useRewardsPointsPerSecond();

    const loading = loanMultipliersLoading || rewardPointsPerSecondLoading;

    const loanMultiplier = metadata?.mint
        ? loanMultipliers?.[metadata.mint]?.[loopscalePointsAction]?.multiplier
        : undefined;

    const amount = loopAmount ?? POINT_BASIS;

    const dailyPoints = calculateDailyPoints(
        rewardPointsPerSecond?.[loopscalePointsAction]?.pointsPerSecond,
        amount,
        loanMultiplier
    );
    const stats: StatProps[] = [
        {
            caption: <RewardsDailyPointsCaption loopscalePointsAction={loopscalePointsAction} metadata={metadata} />,
            value: formatNum(dailyPoints)
        }
    ];

    return <StatColumn stats={stats} loading={loading} />;
}

function RewardsDailyPointsCaption({ loopscalePointsAction, metadata }: RewardsDailyPointsProps) {
    const { loanMultipliers } = useLoanMultipliersUtil();
    const tagColor = useRewardTagColor(loanMultipliers, metadata?.mint, [loopscalePointsAction]);

    return (
        <CaptionWithIcon
            icon={
                <IconWithBackground tagColor={tagColor} size={20}>
                    <Icon type="points" />
                </IconWithBackground>
            }
            caption={`Daily points per $1K`}
        />
    );
}
