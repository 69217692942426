import { useMemo } from "react";

import { AppButton } from "app/components/common";
import { COPY } from "app/constants";
import { Column, TagTextAlert } from "@bridgesplit/ui";
import { useTransactionSender } from "app/components/transactions";
import { useVaultDepositTransaction, useActiveWallet } from "@bridgesplit/abf-react";
import { LOADING_ERROR, MISSING_PARAM_ERROR, MISSING_WALLET_ERROR, Result } from "@bridgesplit/utils";
import { TrackTransactionEvent } from "app/types";

import { useVaultDepositContext } from "./VaultDepositContext";
import { useVaultDepositBalance } from "./util";
import { useVaultContext } from "../VaultContext";

export default function VaultDepositCta() {
    const { form } = useVaultDepositContext();
    const { vaultExpanded } = useVaultContext();
    const deposit = useDeposit();

    const { insufficientBalance } = useVaultDepositBalance();

    const ctaError = useMemo(() => {
        if (!vaultExpanded) return "Loading";
        if (!vaultExpanded.vault.depositsEnabled) return "Deposits are not enabled for this vault";
        if (!form.amount) return "Enter an amount";

        return undefined;
    }, [form.amount, vaultExpanded]);

    const alertError = useMemo(() => {
        if (!form.amount) return undefined;
        if (insufficientBalance) return "Insufficient balance";

        return undefined;
    }, [form.amount, insufficientBalance]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                asyncCta={{ onClickWithResult: deposit }}
                isTransaction
                color="secondary"
                disabled={!!alertError || !!ctaError}
            >
                {ctaError || COPY.VAULT_DEPOSIT_CTA}
            </AppButton>
        </Column>
    );
}

function useDeposit() {
    const vaultDeposit = useVaultDepositTransaction();
    const send = useTransactionSender();
    const { vaultExpanded, userPosition } = useVaultContext();
    const { form, reset } = useVaultDepositContext();
    const { activeWallet } = useActiveWallet();

    return async () => {
        if (!vaultExpanded) return Result.errFromMessage(LOADING_ERROR);
        if (!form.amount) return Result.errFromMessage(MISSING_PARAM_ERROR);
        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);

        const principalAmount = form.amount;
        const minLpAmount = 0; // min LP amount is 0 becasue we do not care about slippage on the deposit for now
        const isNewDeposit = !userPosition?.active;

        return await send(
            vaultDeposit,
            {
                principalAmount,
                minLpAmount,
                principalMetadata: vaultExpanded.principalMetadata,
                vault: vaultExpanded.vault.address,
                newDeposit: isNewDeposit,
                userWallet: activeWallet
            },
            {
                sendOptions: { onSuccess: reset },
                mixpanelEvent: {
                    key: TrackTransactionEvent.SubmitLendVaultDeposit,
                    params: {
                        vaultAddress: vaultExpanded.vault.address,
                        amount: principalAmount,
                        minLpAmount,
                        principalMint: vaultExpanded.principalMetadata.mint
                    }
                }
            }
        );
    };
}
