import {
    Result,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions,
    fetchAndDeserializeVersionedTransactionsWithSetup
} from "@bridgesplit/utils";
import { VersionedTransaction } from "@solana/web3.js";

import {
    AbfTransactionHeaders,
    CloseStrategyTxnParams,
    TXN_STRATEGY_CREATE,
    WithdrawStrategyTxnParams,
    CreateCustomStrategyTxnParams,
    TXN_STRATEGY_UPDATE,
    EditCustomStrategyTxnParams,
    TXN_CREDITBOOK_SELL,
    TXN_CREDITBOOK_CREATE,
    BorrowPrincipalTxnParams,
    TXN_CREDITBOOK_BORROW_PRINCIPAL,
    SellLoanParams,
    DepositStrategyTxnParams,
    TXN_STRATEGY_DEPOSIT,
    TXN_STRATEGY_CLOSE,
    TXN_STRATEGY_WITHDRAW,
    CreateLoanParams
} from "../types";
import { createMarketsInstance } from "./auth";

export async function getCreateStrategyTransaction(
    authentication: AbfTransactionHeaders,
    args: CreateCustomStrategyTxnParams
) {
    return await fetchAndDeserializeVersionedTransactionsWithSetup(
        createMarketsInstance(authentication),
        TXN_STRATEGY_CREATE,
        args
    );
}

export async function getDepositStrategyTransaction(
    authentication: AbfTransactionHeaders,
    args: DepositStrategyTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(createMarketsInstance(authentication), TXN_STRATEGY_DEPOSIT, args)
    ];
}

export async function getWithdrawStrategyTransction(
    authentication: AbfTransactionHeaders,
    args: WithdrawStrategyTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_STRATEGY_WITHDRAW,
            args
        )
    ];
}

export async function getEditStrategyTransactions(
    authentication: AbfTransactionHeaders,
    args: EditCustomStrategyTxnParams
) {
    return await fetchAndDeserializeVersionedTransactions(
        createMarketsInstance(authentication),
        TXN_STRATEGY_UPDATE,
        args
    );
}

// custom fetch needed due to custom return type
export async function getCreateLoanTransaction(
    authentication: AbfTransactionHeaders,
    args: Omit<CreateLoanParams, "principalDecimals" | "collateralDecimals">
): Promise<Result<VersionedTransaction>> {
    try {
        const api = createMarketsInstance(authentication);

        const res = await fetchAndDeserializeVersionedTransaction(api, TXN_CREDITBOOK_CREATE, args);
        return Result.ok(res);
    } catch (error) {
        return Result.err(error);
    }
}

export async function getSellLoanTransaction(authentication: AbfTransactionHeaders, args: SellLoanParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(createMarketsInstance(authentication), TXN_CREDITBOOK_SELL, args)
    ];
}

export async function getBorrowPrincipalTransaction(
    authentication: AbfTransactionHeaders,
    args: BorrowPrincipalTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_BORROW_PRINCIPAL,
            args
        )
    ];
}

export async function getCloseStrategyTransaction(authentication: AbfTransactionHeaders, args: CloseStrategyTxnParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            `${TXN_STRATEGY_CLOSE}/${args.strategyAddress}`
        )
    ];
}
