import { colorToAlpha } from "@bridgesplit/utils";
import { PaletteOptions } from "@mui/material";

import { BRAND_COLORS } from "./theme";

export function getLightPalette(): PaletteOptions {
    return {
        primary: {
            main: BRAND_COLORS.BLACK
        },
        secondary: {
            main: BRAND_COLORS.BLUE
        },
        background: {
            default: BRAND_COLORS.LIGHT,
            paper: BRAND_COLORS.LIGHT
        },
        success: {
            main: "#009D05"
        },
        error: {
            main: "#E12D2D"
        },
        warning: {
            main: "#FF7631"
        },
        info: {
            main: "#113EDD"
        },
        divider: colorToAlpha(BRAND_COLORS.BLACK, 0.08),
        text: {
            primary: BRAND_COLORS.BLACK,
            secondary: BRAND_COLORS.GRAY,
            disabled: colorToAlpha(BRAND_COLORS.BLACK, 0.4)
        }
    };
}
