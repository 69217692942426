import {
    isLoanBorrower,
    LoopPositionExpanded,
    LoopscalePointsAction,
    useActiveWallet,
    usePortfolioRewardsUtils
} from "@bridgesplit/abf-react";
import { percentUiToDecimals } from "@bridgesplit/utils";
import { getLoopExternalPointRewards } from "app/components/common";
import { RewardsPortfolioApy } from "app/components/points";
import { calculateActiveLoanDailyPoints } from "app/components/portfolio/stats";

export function LoopPositionApy({ loopPosition }: { loopPosition: LoopPositionExpanded | undefined }) {
    const { user } = useActiveWallet();

    const metadata = loopPosition?.loopExpanded.collateralToken;
    const pointSources = getLoopExternalPointRewards(loopPosition?.loopExpanded);

    const { getLoanRewards } = usePortfolioRewardsUtils();
    const loanAddress = loopPosition?.loanExpanded?.loan?.address;
    const isBorrower = isLoanBorrower(loopPosition?.loanExpanded, user);
    const ledgerReward = getLoanRewards(loanAddress);

    const customDailyPoints = calculateActiveLoanDailyPoints(ledgerReward, isBorrower);

    if (!loopPosition?.userLoopInfo.netApy) return null;
    return (
        <RewardsPortfolioApy
            metadata={metadata}
            loopscalePointsAction={LoopscalePointsAction.Borrow}
            apy={percentUiToDecimals(loopPosition.userLoopInfo.netApy)}
            customDailyPoints={customDailyPoints}
            externalPointRewards={pointSources}
        />
    );
}
