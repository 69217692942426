import { colorToAlpha } from "@bridgesplit/utils";
import { PaletteOptions } from "@mui/material";

import { BRAND_COLORS } from "./theme";

export function getDarkPalette(): PaletteOptions {
    return {
        primary: {
            main: BRAND_COLORS.LIGHT
        },
        secondary: {
            main: BRAND_COLORS.BLUE
        },
        background: {
            default: BRAND_COLORS.BLACK,
            paper: BRAND_COLORS.BLACK
        },
        success: {
            main: "#06DB0C"
        },
        error: {
            main: "#E12D2D"
        },
        warning: {
            main: "#FFD15C"
        },
        info: {
            main: "#4A88FF"
        },
        divider: colorToAlpha(BRAND_COLORS.LIGHT, 0.08),
        text: {
            primary: BRAND_COLORS.LIGHT,
            secondary: BRAND_COLORS.GRAY,
            disabled: colorToAlpha(BRAND_COLORS.LIGHT, 0.4)
        }
    };
}
