import { Column, Image, Row, Text } from "@bridgesplit/ui";
import { COPY } from "app/constants";

import { useVaultContext } from "../../VaultContext";

export default function OverviewDetails() {
    const { vaultExpanded } = useVaultContext();
    return (
        <Column spacing={2}>
            <Row spacing={1}>
                <Text multiLine loading={!vaultExpanded} color="caption">
                    {vaultExpanded?.vaultMetadata?.description}
                </Text>
            </Row>
            <Row spacing={1}>
                <Text color="caption">{COPY.VAULT_MANAGER_PREFIX}</Text>
                <Image variant="circle" src={vaultExpanded?.vaultMetadata?.managerImage} size="20px" />
                <Text>{vaultExpanded?.vaultMetadata?.managerName}</Text>
            </Row>
        </Column>
    );
}
