import { IconButton } from "@bridgesplit/ui";
import { useActiveWallet, useAuth } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";

import { NAV_ICON_BUTTON_SX } from "./constants";

export default function NavbarWallet() {
    const { open } = useAppDialog();

    const { isAuthenticated, isWalletBased } = useAuth();
    const { activeWallet } = useActiveWallet();

    if (!isAuthenticated || isWalletBased || !activeWallet) return null;

    return <IconButton sx={NAV_ICON_BUTTON_SX} onClick={() => open(AppDialog.Wallet, undefined)} icon="wallet" />;
}
