import { useCallback, useMemo } from "react";

import { createApi } from "@reduxjs/toolkit/query/react";
import { OrcaPositionInfo, ParsedOrcaWhirlpool, TokenListMetadata, TokenListTag } from "@bridgesplit/abf-sdk";
import { filterTruthy, removeDuplicates, MARKETS_API } from "@bridgesplit/utils";
import { useDispatch } from "react-redux";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import { abfSerializeQueryArgs, AccessLevel, getBaseQuery } from "./util";
import { GET_TOKEN_LIST_ROUTE, GET_WHIRLPOOL_POSITIONS_ROUTE, GET_WHIRLPOOL_ROUTE } from "../constants";

const WHIRLPOOL_TAG = "WHIRLPOOL";
const TOKEN_LIST_TAG = "TOKEN_LIST";
// split token list to use unauthenticated base query
export const tokenListApi = createApi({
    reducerPath: "tokenListApi",
    baseQuery: getBaseQuery(MARKETS_API, { authLevel: AccessLevel.NotConnected }, false),
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [WHIRLPOOL_TAG, TOKEN_LIST_TAG],
    endpoints: (builder) => ({
        tokenList: builder.query<TokenListMetadata[], void>({
            query() {
                return {
                    url: GET_TOKEN_LIST_ROUTE,
                    method: "GET"
                };
            },
            transformResponse: (raw: Map<string, TokenListMetadata>) => {
                return Object.values(raw).map((token) => ({
                    ...token,
                    tags: token.tags ? (JSON.parse(token.tags.replace(/'/g, '"')) as TokenListTag[]) : []
                }));
            },
            providesTags: [TOKEN_LIST_TAG]
        }),
        whirlpoolPositions: builder.query<OrcaPositionInfo, string[]>({
            query(mints) {
                return {
                    url: GET_WHIRLPOOL_POSITIONS_ROUTE,
                    method: "POST",
                    body: mints,
                    responseHandler: async (response) => {
                        const text = await response.text();

                        // Replace large numbers with strings to preserve precision
                        const keysToPreserve = ["upperSqrtPrice", "lowerSqrtPrice", "sqrtPrice"];

                        const preservedText = keysToPreserve.reduce((acc, key) => {
                            const pattern = new RegExp(`"${key}"\\s*:\\s*(\\d+)`, "g");
                            return acc.replace(pattern, `"${key}":"$1"`);
                        }, text);

                        return JSON.parse(preservedText);
                    }
                };
            },
            providesTags: [WHIRLPOOL_TAG]
        }),
        whirlpools: builder.query<Record<string, ParsedOrcaWhirlpool>, string[]>({
            query(mints) {
                return {
                    url: GET_WHIRLPOOL_ROUTE,
                    method: "POST",
                    body: mints
                };
            }
        })
    })
});

export const { useTokenListQuery, useWhirlpoolPositionsQuery, useWhirlpoolsQuery } = tokenListApi;

export function useTokenListMetadataByMint(identifier: string | undefined | null) {
    const { getMetadata } = useTokenListMetadataByMints([identifier]);
    return getMetadata(identifier);
}

export function useWhirlpoolByAddresses(addresses: string[]) {
    const uniqueAddresses = removeDuplicates(addresses?.filter(filterTruthy) ?? []);
    const { data: addressToWhirlpool, isLoading, isFetching } = useWhirlpoolsQuery(uniqueAddresses);

    const getWhirlpool = useCallback(
        (address: string | undefined) => {
            return address ? addressToWhirlpool?.[address] : undefined;
        },
        [addressToWhirlpool]
    );

    return {
        getWhirlpool,
        isLoading,
        isFetching
    };
}
export function useTokenListMetadataByMints(mints: (string | undefined | null)[] | undefined) {
    const uniqueMints = removeDuplicates(mints?.filter(filterTruthy) ?? []);
    const tokenList = useTokenListQuery();

    const mintToToken = useMemo(() => {
        if (!tokenList.data) return undefined;
        return new Map(tokenList.data.map((t) => [t.mint, t]));
    }, [tokenList.data]);

    const metadata = useTokenListQuery(undefined, { skip: !uniqueMints?.length || !tokenList.data?.length });

    const mintToMetadata = useMemoizedKeyMap(metadata.data, (t) => t.mint);

    const getMetadata = useCallback(
        (mint: string | undefined | null) => {
            if (!mint) return undefined;
            return mintToToken?.get(mint) ?? mintToMetadata?.get(mint);
        },
        [mintToMetadata, mintToToken]
    );

    return {
        getMetadata,
        isLoading: tokenList.isLoading || metadata.isFetching || metadata.isLoading,
        isFetching: tokenList.isFetching || metadata.isFetching
    };
}

export const useTokenListApi = () => {
    const api = tokenListApi.endpoints;
    const dispatch = useDispatch();
    const [fetchTokenList] = api.tokenList.useLazyQuery();
    const [fetchWhirlpoolPositions] = api.whirlpoolPositions.useLazyQuery();

    return {
        fetchTokenList,
        fetchWhirlpoolPositions,
        resetTokenList: () => dispatch(tokenListApi.util.invalidateTags([TOKEN_LIST_TAG])),
        resetWhirlpoolPositions: () => dispatch(tokenListApi.util.invalidateTags([WHIRLPOOL_TAG]))
    };
};
