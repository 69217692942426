import { useMemo } from "react";

import {
    calculateUnwindStats,
    getLoopSlippage,
    LoopPositionExpanded,
    LoopTransferType,
    LoopUnwindParams,
    useActiveWallet,
    useLoopCollateralPrice,
    useLoopUnwindTransaction,
    useUnwindQuoteAndIxs
} from "@bridgesplit/abf-react";
import {
    Result,
    LOADING_ERROR,
    formatUsd,
    formatPercent,
    MISSING_WALLET_ERROR,
    ALLOWED_FEATURES
} from "@bridgesplit/utils";
import { Column, Row, StatColumn, StatProps, TagTextAlert, Text } from "@bridgesplit/ui";
import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { LoopRouteResponse } from "@loopscale/pandora";
import { COPY } from "app/constants";
import { TrackTransactionEvent } from "app/types";

import { ActionProps } from "./types";
import {
    AppButton,
    OracleFairValue,
    LoopInitialDeposit,
    LoopProfitLoss,
    Slippage,
    TokenAmountWithUsd,
    useLoopUnwindAlerts,
    getOracleDiffFromGainSummary,
    ORACLE_DISCREPANCY_THRESHOLD
} from "../../common";
import { useTransactionSender } from "../../transactions";

export default function LoopUnwind({ loopPosition }: ActionProps) {
    const { slippagePresetsUi, defaultSlippageUi } = getLoopSlippage(loopPosition?.loopExpanded);
    const { slippagePercentDecimals, slippageWrapperProps, slippageButtonProps } = Slippage.useController({
        defaultSlippageUi,
        presetsUi: slippagePresetsUi
    });

    const unwind = useLoopUnwind();

    const {
        data: externalQuote,
        isError,
        isLoading: externalQuoteLoading
    } = useUnwindQuoteAndIxs({
        loopPositionExpanded: loopPosition,
        slippagePercentDecimals
    });

    const { data: jupiterPrices } = useLoopCollateralPrice({
        tokens: loopPosition?.loopExpanded
            ? [loopPosition.loopExpanded.collateralToken, loopPosition.loopExpanded.principalToken]
            : undefined,
        options: { skip: !loopPosition }
    });

    const summary = useMemo(() => {
        if (!loopPosition || !jupiterPrices || externalQuoteLoading) return undefined;
        return calculateUnwindStats({ loopPosition, externalQuote, jupiterPrices });
    }, [loopPosition, jupiterPrices, externalQuoteLoading, externalQuote]);

    const { unwindWarningMessage } = useLoopUnwindAlerts({
        loopPositionExpanded: loopPosition,
        summary,
        variant: "route"
    });

    return (
        <Slippage.Wrapper {...slippageWrapperProps}>
            <Row spaceBetween>
                <Text color="caption">{COPY.UNWIND_TERM}</Text>
                <Slippage.Button {...slippageButtonProps} />
            </Row>

            <ReceiveStats summary={summary} />
            <SecondaryStats summary={summary} externalQuote={externalQuote} loopPosition={loopPosition} />
            {unwindWarningMessage ? (
                <TagTextAlert color="warning" icon="warning">
                    {unwindWarningMessage}
                </TagTextAlert>
            ) : null}
            {isError && (
                <TagTextAlert color="error" icon="warning">
                    Unable to find a swap route to swap from collateral to repay principal in Jupiter. Please check back later
                </TagTextAlert>
            )}
            <AppButton
                disabled={!summary}
                isTransaction
                asyncCta={{ onClickWithResult: () => unwind({ loopPosition, externalQuote }) }}
            >
                {COPY.UNWIND_TERM}
            </AppButton>
        </Slippage.Wrapper>
    );
}

type Summary = ReturnType<typeof calculateUnwindStats> | undefined;
function ReceiveStats({ summary }: { summary: Summary }) {
    const tokenStats = summary?.tokenStats;
    return (
        <Column spacing={0.5}>
            <Text variant="body2" color="caption">
                Receive
            </Text>
            <TokenAmountWithUsd
                variant="h3"
                tokenSize="md"
                amount={tokenStats?.receiveTokenAmount}
                amountUsd={tokenStats?.receiveUsdAmount}
                token={tokenStats?.tokenToReceive}
            />
        </Column>
    );
}

function SecondaryStats({
    summary,
    externalQuote,
    loopPosition
}: {
    summary: Summary;
    loopPosition: LoopPositionExpanded | undefined;
    externalQuote: LoopRouteResponse | undefined;
}) {
    const stats: StatProps[] = [
        {
            value: <LoopInitialDeposit loopPosition={loopPosition} />,
            caption: "Original deposit"
        },
        {
            value: formatUsd(summary?.contributions.additionalDepositsUsd),
            caption: COPY.LOOP_ADDITIONAL_DEPOSITS_TERM,
            tooltip: COPY.LOOP_ADDITIONAL_DEPOSITS_TOOLTIP,
            hide: !summary?.contributions.additionalDepositsUsd
        },
        {
            value: <LoopProfitLoss gainSummary={summary} loopPosition={loopPosition} />,
            caption: "Estimated P&L",
            hide: ALLOWED_FEATURES.hideLoopPnl
        }
    ];

    const oracleDiff = getOracleDiffFromGainSummary(summary, loopPosition);

    if (oracleDiff && Math.abs(oracleDiff.oracleDiff) > ORACLE_DISCREPANCY_THRESHOLD) {
        stats.push({
            value: <OracleFairValue loopPosition={loopPosition} gainSummary={summary} />,
            caption: COPY.LOOP_ORACLE_DISCREPANCY_TERM,
            hide: !summary?.tokenStats?.oraclePrice || !summary?.tokenStats?.quotePrice
        });
    }

    if (loopPosition?.loopExpanded.loopVault.routeType === LoopRouteType.Jup && externalQuote?.priceImpactPct) {
        stats.push({
            caption: "Price impact",
            value: formatPercent(externalQuote.priceImpactPct, {
                //TODO: verify this is correct
                maxPrecisionUi: 0.01
            })
        });
    }

    return <StatColumn stats={stats} loading={!summary} />;
}

function useLoopUnwind() {
    const unwind = useLoopUnwindTransaction();
    const send = useTransactionSender();
    const { activeWallet } = useActiveWallet();
    return async ({
        loopPosition,
        externalQuote
    }: {
        loopPosition: LoopPositionExpanded | undefined;
        externalQuote: LoopRouteResponse | undefined;
    }) => {
        if (!loopPosition || !loopPosition.loanExpanded || !externalQuote) return Result.errFromMessage(LOADING_ERROR);
        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);
        const { loopExpanded, loanExpanded } = loopPosition;

        const receiveToken =
            loopExpanded.withdrawType === LoopTransferType.CollateralOnly
                ? loopExpanded.collateralToken
                : loopExpanded.principalToken;

        const params: LoopUnwindParams = {
            loanAddress: loanExpanded.loan.address,
            principalMint: loopExpanded.principalToken.mint,
            collateralMint: loopExpanded.collateralToken.mint,
            mintToReceive: receiveToken.mint,
            userPublicKey: activeWallet,
            swapIx: externalQuote.swapIxs,
            swapLuts: externalQuote.swapLuts
        };

        return await send(unwind, params, {
            alerter: {
                generationEstimateSeconds: 8,
                showProgress: true
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitCloseEarnOrder,
                params
            }
        });
    };
}
