import {
    TokenListMetadata,
    getVaultInitTimelockTransactions,
    getVaultDepositTransaction,
    getVaultWithdrawTransaction,
    VaultDepositParams,
    VaultWithdrawParams,
    InitVaultTimelockTxnParams,
    CancelVaultTimeLockTxnParams,
    getVaultCancelTimelockTransaction,
    getVaultClaimFeesTransaction,
    ClaimVaultFeesTxnParams,
    getVaultUpdateDepositEnabledTransaction,
    UpdateLendingVaultDepositsTxnParams
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises, TransactionAction, TransactionActionType } from "@bridgesplit/react";
import { Result, RoundingType, TransactionStatus, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, ParsedAllowedStakeDuration } from "../types";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithData } from "./common";

function useVaultEscrowRefetch() {
    const {
        resetNapoleonApi,
        resetUserAssetsApi,
        resetLendingVaultApi,
        resetMarketsPublicApi,
        resetLendingVaultPositionsApi
    } = useAbfFetches();
    return () => {
        resetNapoleonApi();
        resetUserAssetsApi();
        resetLendingVaultApi();
        resetMarketsPublicApi();
        resetLendingVaultPositionsApi();
    };
}

type DepositParams = VaultDepositParams & {
    principalMetadata: TokenListMetadata;
    userWallet: string;
    newDeposit: boolean;
};

export function useVaultDepositTransaction(): AbfTransactionDetails<DepositParams> {
    const generate = useAbfGenerateTransactionWithData();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams({
        principalMetadata,
        userWallet,
        vault,
        newDeposit,
        ...params
    }: DepositParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultDepositTransaction,
                identifier: "Deposit",
                getActions: (response): TransactionAction[] => {
                    return [
                        {
                            action: {
                                [TransactionActionType.WriteVaultDepositAndStake]: {
                                    userWallet,
                                    principalMint: principalMetadata.mint,
                                    vaultAddress: vault,
                                    stakeAccount: response.stakeAccount,
                                    stakeDurationType: ParsedAllowedStakeDuration.ZeroDays,
                                    isNewDeposit: newDeposit
                                }
                            },
                            transactionStatus: TransactionStatus.Confirmed
                        }
                    ];
                },
                params: {
                    ...params,
                    principalAmount: uiAmountToLamports(
                        params.principalAmount,
                        principalMetadata.decimals,
                        RoundingType.UP
                    ),
                    minLpAmount: uiAmountToLamports(params.minLpAmount, principalMetadata.decimals, RoundingType.DOWN),
                    vault
                }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Depositing" };
}

type WithdrawParams = VaultWithdrawParams & {
    principalMetadata: TokenListMetadata;
    userWallet: string;
    withdrawAll: boolean;
};

export function useVaultWithdrawTransaction(): AbfTransactionDetails<WithdrawParams> {
    const generate = useAbfGenerateTransactionWithData();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams({
        principalMetadata,
        userWallet,
        withdrawAll,
        vault,
        ...params
    }: WithdrawParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultWithdrawTransaction,
                identifier: "Withdraw",
                getActions: (response): TransactionAction[] => {
                    return [
                        {
                            action: {
                                [TransactionActionType.WriteVaultUnstakeAndWithdraw]: {
                                    userWallet: userWallet,
                                    principalMint: principalMetadata.mint,
                                    vaultAddress: vault,
                                    stakeAccount: response.stakeAccount,
                                    stakeDurationType: ParsedAllowedStakeDuration.ZeroDays,
                                    isFullWithdrawal: withdrawAll
                                }
                            },
                            transactionStatus: TransactionStatus.Confirmed
                        }
                    ];
                },
                params: {
                    ...params,
                    amountPrincipal: uiAmountToLamports(
                        params.amountPrincipal,
                        principalMetadata.decimals,
                        RoundingType.DOWN
                    ),
                    maxAmountLp: uiAmountToLamports(params.maxAmountLp, principalMetadata.decimals, RoundingType.UP),
                    vault,
                    withdrawAll
                }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing" };
}

export function useVaultTimelockInitTransactions(): AbfTransactionDetails<InitVaultTimelockTxnParams> {
    const generate = useAbfGenerateTransaction();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams(params: InitVaultTimelockTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultInitTimelockTransactions,
                identifier: "Create timelock",
                params: params
            });

            return await combineTransactionPromises([create], { order: "parallel" });
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Creating timelock" };
}

export function useVaultTimelockCancelTransaction(): AbfTransactionDetails<CancelVaultTimeLockTxnParams[]> {
    const generate = useAbfGenerateTransaction();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams(params: CancelVaultTimeLockTxnParams[]): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultCancelTimelockTransaction,
                identifier: "Cancel timelock",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Cancel timelock" };
}

export function useVaultClaimFeeTransaction(): AbfTransactionDetails<ClaimVaultFeesTxnParams> {
    const generate = useAbfGenerateTransaction();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams(params: ClaimVaultFeesTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultClaimFeesTransaction,
                identifier: "Claim vault fee",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Claiming vault fee" };
}

export function useVaultUpdateDepositsEnabledTransaction(): AbfTransactionDetails<UpdateLendingVaultDepositsTxnParams> {
    const generate = useAbfGenerateTransaction();
    const refetch = useVaultEscrowRefetch();

    async function getTransactionsWithParams(params: UpdateLendingVaultDepositsTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getVaultUpdateDepositEnabledTransaction,
                identifier: "Update vault deposits",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = { refetch };

    return { getTransactionsWithParams, sendOptions, description: "Updating vault deposits" };
}
