import { OutlinedCard, Row, StatColumn, Text, Link } from "@bridgesplit/ui";
import { BsMetaUtil, ExternalPointReward, LoopscalePointsAction, RoleView } from "@bridgesplit/abf-react";
import { bsMath, formatUsd } from "@bridgesplit/utils";
import { formatDurationWithTypeShorthand, StrategyDuration } from "@bridgesplit/abf-sdk";

import { GenericRewardsApyProps, RewardsBorrowApy, RewardsLendApy } from "../../points";
import { MarketStats } from "./types";
import { BorrowCapWarning, getMarketPath, TokenImage } from "../../common";
import MarketDetailCta from "./MarketDetailCta";
export default function PrincipalMarketCard({
    marketStats,
    selectedDuration,
    view
}: {
    marketStats: MarketStats | undefined;
    selectedDuration: StrategyDuration | undefined;
    view: RoleView;
}) {
    const metadata = marketStats?.metadata;
    const apy = marketStats?.apy;

    return (
        <Link to={getMarketPath({ token: metadata, view: undefined })}>
            <OutlinedCard padding={2} spacing={3}>
                <Row spacing={1}>
                    <TokenImage metadata={metadata} size="lg" />

                    <Text loading={!marketStats} variant="h3" fontWeight="normal">
                        {BsMetaUtil.getSymbolUnique(metadata)}
                    </Text>
                </Row>
                <StatColumn
                    loading={!marketStats || !selectedDuration}
                    stats={[
                        {
                            caption: `${formatDurationWithTypeShorthand(
                                selectedDuration?.duration,
                                selectedDuration?.durationType
                            )} APY`,
                            value: <RewardsMobileApy apy={apy} metadata={metadata} view={view} />
                        },
                        {
                            caption: "Supplied",
                            value: bsMath.add(marketStats?.availableUsd, marketStats?.borrowedUsd),
                            isUsd: true
                        },
                        {
                            caption: "Borrowed",
                            value: marketStats?.borrowCapWarning ? (
                                <BorrowCapWarning borrowCapWarning={marketStats.borrowCapWarning}>
                                    {formatUsd(marketStats?.borrowedUsd)}
                                </BorrowCapWarning>
                            ) : (
                                marketStats?.borrowedUsd
                            ),
                            isUsd: true
                        }
                    ]}
                />
                <MarketDetailCta isMobile view={view} token={metadata} />
            </OutlinedCard>
        </Link>
    );
}

// TODO: remove mobile padding
function RewardsMobileApy({
    apy,
    metadata,
    view
}: Omit<GenericRewardsApyProps, "externalPointRewards" | "loopscalePointsAction"> & { view: RoleView }) {
    if (view === RoleView.Borrow) {
        return <RewardsBorrowApy apy={apy} loopscalePointsAction={LoopscalePointsAction.Borrow} metadata={metadata} />;
    }
    return (
        <RewardsLendApy
            apy={apy}
            loopscalePointsAction={LoopscalePointsAction.Lend}
            metadata={metadata}
            externalPointRewards={[ExternalPointReward.MarginFi]}
        />
    );
}
