import { DEFAULT_STRATEGY_DURATION, LendingVaultExpanded, VaultLtvInfos } from "@bridgesplit/abf-react";
import { ExternalYieldSource, StrategyDuration, TokenListMetadata } from "@bridgesplit/abf-sdk";

export type CollateralForm = {
    search: string;
    viewPendingUpdate: boolean;
    mintToInfo: Record<string, CollateralAmountsForm>;
};

export type CollateralAmountsForm = {
    ltvUiAmount?: number;
    liquidationLtvAmount?: number;
};

export type RateForm = {
    search: string;
    strategyDuration: StrategyDuration; //Used for the viewing state
    mintToRateInfo: Record<string, Record<string, RateAmountForm>>;
    disabledMints: Record<string, string[]>; //Key is the list of serelized strategy durations
};
export type RateAmountForm = {
    apyAmountUi?: string;
};

export type SettingForm = {
    search: string;
    viewPendingUpdate: boolean;
    idleCapital: ExternalYieldSource;
    originationsEnabled: boolean | undefined;
    // Lend vault escrow
    liquidityBuffer: number | undefined;
    originationFee: number | undefined;
    interestFee: number | undefined;
    originationCap: number | undefined;

    //Paresed lend vault terms
    liquidationThreshold: number | undefined;
    maxEarlyUnstakeFee: number | undefined;
};

export const initialCollateralForm: CollateralForm = {
    search: "",
    viewPendingUpdate: false,
    mintToInfo: {}
};

export const initialRateForm: RateForm = {
    search: "",
    strategyDuration: DEFAULT_STRATEGY_DURATION,
    mintToRateInfo: {},
    disabledMints: {}
};

export const initialSettingsForm: SettingForm = {
    search: "",
    viewPendingUpdate: false,
    idleCapital: ExternalYieldSource.None,
    originationsEnabled: undefined,
    liquidityBuffer: undefined,
    originationFee: undefined,
    interestFee: undefined,
    originationCap: undefined,
    liquidationThreshold: undefined,

    maxEarlyUnstakeFee: undefined
};

export type CollateralFormTableInfo = CollateralAmountsForm & {
    id: number;
    metadata: TokenListMetadata | undefined;
};

export type RateFormTableInfo = RateAmountForm & {
    id: number;
    metadata: TokenListMetadata | undefined;
};

export type LendVaultProps = {
    vaultAddress: string;
    vaultExpanded: LendingVaultExpanded | undefined;
    isLoading: boolean;
    notFound: boolean;
};
export interface LendVaultCollateral {
    mint: string;
    usdPrice: number | null;
    metadata: TokenListMetadata;
    marketInfo: VaultLtvInfos | undefined;
}
