import { ReactNode } from "react";

import {
    AccountBalanceOutlined,
    AccountBalanceWalletOutlined,
    Add,
    AssuredWorkloadOutlined,
    ArticleOutlined,
    CalendarMonthOutlined,
    Check,
    Close,
    EditOffOutlined,
    EditOutlined,
    GavelOutlined,
    HowToRegOutlined,
    InfoOutlined,
    InsertLinkOutlined,
    Language,
    LaunchOutlined,
    LinkedIn,
    MoreHoriz,
    PaymentsOutlined,
    PersonOutline,
    QueryBuilderOutlined,
    RecentActorsOutlined,
    Twitter,
    Verified,
    FingerprintOutlined,
    WarningAmberOutlined,
    FavoriteOutlined,
    AutoGraphOutlined,
    ContentCopyOutlined,
    RequestQuoteOutlined,
    NorthOutlined,
    SouthOutlined,
    NearMeOutlined,
    WalletOutlined,
    ArrowBackOutlined,
    AccessTime,
    AddOutlined,
    LinkOutlined,
    HelpOutlineOutlined,
    NotificationsNone,
    DeleteOutline,
    Settings,
    CurrencyExchangeOutlined,
    HistoryOutlined
} from "@mui/icons-material";
import { IconButton as MuiIconButton, Stack, styled } from "@mui/material";
import { colorToAlpha, getDeterministicIndexFromSeed } from "@bridgesplit/utils";

import { useAppPalette, useColorFromTag } from "../theme";
import { SxType } from "../types";
import { SpanBold, TagColor, Text, TextProps, TextVariant } from "./typography";

export type IconType =
    | "dollar"
    | "logo"
    | "logo-outer"
    | "tooltip"
    | "connect-wallet"
    | "wallet"
    | "twitter"
    | "linked-in"
    | "url"
    | "website"
    | "loan"
    | "loan-borrower"
    | "lender"
    | "lend"
    | "borrower"
    | "borrow"
    | "profile"
    | "add"
    | "deposit"
    | "asset"
    | "date"
    | "withdraw"
    | "receive"
    | "send"
    | "copy"
    | "accept"
    | "reject"
    | "offer"
    | "pending"
    | "more"
    | "view-only"
    | "kyc"
    | "edit"
    | "delete"
    | "bank"
    | "passkey"
    | "warning"
    | "health"
    | "escrow"
    | "back"
    | "points"
    | "referral"
    | "clock"
    | "connect"
    | "help"
    | "notification"
    | "logo-icon"
    | "logo-icon-filled"
    | "settings"
    | "base-apy"
    | "margin-fi"
    | "leverage"
    | "currency-exchange"
    | "history";

export function Icon({ type, sx }: { type: IconType; sx?: SxType }) {
    switch (type) {
        case "logo":
            return <LoopscaleSvg sx={sx} />;
        case "logo-outer":
            return <LoopscaleSvgOuterLogo sx={sx} />;
        case "tooltip":
            return <InfoOutlined sx={sx} />;
        case "wallet":
            return <AccountBalanceWalletOutlined sx={sx} />;
        case "linked-in":
            return <LinkedIn sx={sx} />;
        case "twitter":
            return <Twitter sx={sx} />;
        case "website":
            return <Language sx={sx} />;
        case "url":
            return <LaunchOutlined sx={sx} />;
        case "dollar":
            return <span style={{ marginRight: "-4px" }}>$</span>;
        case "loan-borrower":
            return <PaymentsOutlined sx={sx} />;
        case "loan":
            return <ArticleOutlined sx={sx} />;
        case "borrow":
            return <RequestQuoteOutlined sx={sx} />;
        case "lend":
            return <AutoGraphOutlined sx={sx} />;
        case "lender":
            return <AccountBalanceOutlined sx={sx} />;
        case "borrower":
            return <PersonOutline sx={sx} />;
        case "offer":
            return <GavelOutlined sx={sx} />;
        case "asset":
            return <RecentActorsOutlined sx={sx} />;
        case "deposit":
            return <SouthOutlined sx={sx} />;
        case "withdraw":
            return <NorthOutlined sx={sx} />;
        case "profile":
            return <PersonOutline sx={sx} />;
        case "date":
            return <CalendarMonthOutlined sx={sx} />;
        case "copy":
            return <ContentCopyOutlined sx={sx} />;
        case "kyc":
            return <HowToRegOutlined sx={sx} />;
        case "accept":
            return <Check sx={sx} />;
        case "pending":
            return <QueryBuilderOutlined sx={sx} />;
        case "reject":
            return <Close sx={sx} />;
        case "add":
            return <Add sx={sx} />;
        case "delete":
            return <DeleteOutline sx={sx} />;
        case "more":
            return <MoreHoriz sx={sx} />;
        case "receive":
            return <AddOutlined sx={sx} />;
        case "send":
            return <NearMeOutlined sx={sx} />;
        case "view-only":
            return <EditOffOutlined sx={sx} />;
        case "edit":
            return <EditOutlined sx={sx} />;
        case "bank":
            return <AssuredWorkloadOutlined sx={sx} />;
        case "connect-wallet":
            return <InsertLinkOutlined sx={{ transform: "rotate(-45deg)", ...sx }} />;
        case "passkey":
            return <FingerprintOutlined sx={sx} />;
        case "warning":
            return <WarningAmberOutlined sx={sx} />;
        case "health":
            return <FavoriteOutlined sx={sx} />;
        case "escrow":
            return <WalletOutlined sx={sx} />;
        case "back":
            return <ArrowBackOutlined sx={sx} />;
        case "points":
            return <Sparkle sx={sx} />;
        case "referral":
            return <PersonOutline sx={sx} />;
        case "clock":
            return <AccessTime sx={sx} />;
        case "connect":
            return <LinkOutlined sx={{ transform: "rotate(45deg)" }} />;
        case "help":
            return <HelpOutlineOutlined sx={sx} />;
        case "notification":
            return <NotificationsNone sx={sx} />;
        case "logo-icon":
            return <LoopscaleIconSvg sx={sx} />;
        case "logo-icon-filled":
            return <LoopscaleIconFilledSvg sx={sx} />;
        case "settings":
            return <Settings sx={sx} />;
        case "currency-exchange":
            return <CurrencyExchangeOutlined sx={sx} />;
        case "history":
            return <HistoryOutlined sx={sx} />;
        case "base-apy":
            return <BaseApySvg sx={sx} />;
        case "margin-fi":
            return <MarginFi sx={sx} />;
        case "leverage":
            return <AutoGraphOutlined sx={sx} />;
        default:
            return null;
    }
}

export function UnreadDot({ size = 8, color = "error" }: { size?: number; color?: "success" | "error" | "info" }) {
    const { error, success, info } = useAppPalette();
    const background = { error, success, info }[color];
    return <Stack sx={{ background, width: `${size}px`, height: `${size}px`, borderRadius: "100%" }} />;
}

export const StyledSvg = styled("svg")({ fontSize: "inherit" });

export function LoopscaleIconSvg({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg width="1em" height="1em" viewBox="0 0 60 60" fill="none" sx={sx} xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="currentColor" fillOpacity="0.05" />
            <g clipPath="url(#clip0_11584_8990)">
                <path
                    d="M16.7162 35.7222L20.1461 31.0886C20.3682 30.7886 20.8392 30.9134 20.8885 31.2846C21.9351 39.1494 32.6038 41.9647 37.3438 35.3699L48.201 20.7015C48.3669 20.4778 48.7213 20.5946 48.7213 20.8738V29.4602C48.7213 29.8166 48.6067 30.164 48.3945 30.4501L42.3148 38.6495C35.9527 47.8139 21.7633 46.8537 16.6076 37.1022C16.3746 36.6607 16.4191 36.1242 16.7162 35.7222Z"
                    fill="currentColor"
                />
                <path
                    d="M43.1619 24.2858L39.7123 28.9452C39.4901 29.2452 39.0202 29.1204 38.9698 28.7502C37.9143 20.9141 27.303 18.0949 22.5462 24.6391L16.4428 32.8712L11.8064 39.1957C11.6416 39.4215 11.2852 39.3035 11.2852 39.0243V30.3855C11.2852 30.0292 11.3997 29.6827 11.611 29.3966L17.1951 21.8536C23.2235 12.1525 38.1118 12.9702 43.2744 22.9128C43.5035 23.3533 43.457 23.8868 43.1619 24.2858Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_11584_8990">
                    <rect width="37.5" height="30" fill="white" transform="translate(11.25 15)" />
                </clipPath>
            </defs>
        </StyledSvg>
    );
}

export function LoopscaleIconFilledSvg({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg
            width="1em"
            height="1em"
            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={sx}
        >
            <path
                d="M286.965 592.156L341.944 517.878C345.505 513.07 353.054 515.069 353.846 521.02C370.621 647.091 541.639 692.219 617.62 586.507L791.66 351.375C794.318 347.789 800 349.661 800 354.136V491.775C800 497.488 798.164 503.057 794.762 507.643L697.304 639.078C595.321 785.983 367.868 770.591 285.224 614.276C281.489 607.199 282.185 598.582 286.949 592.14L286.965 592.156Z"
                fill="currentColor"
            />
            <path
                d="M710.978 408.849L655.682 483.54C652.121 488.348 644.587 486.348 643.78 480.414C626.862 354.803 456.763 309.611 380.513 414.514L282.676 546.472L208.356 647.853C205.713 651.47 200 649.582 200 645.107V506.628C200 500.915 201.836 495.361 205.223 490.775L294.735 369.861C391.369 214.355 630.027 227.462 712.782 386.84C716.454 393.901 715.726 402.47 710.994 408.865L710.978 408.849Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}
export function LoopscaleSvg({ sx }: { sx?: SxType }) {
    const height = 1;
    return (
        <StyledSvg
            width={`${(1198 / 210) * height}em`}
            height={`${height}em`}
            viewBox="0 0 1198 210"
            fill="none"
            sx={sx}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1011.61 0H1041.41V167.84H1011.61V0Z" fill="currentColor" />
            <path
                d="M590.823 158.204C601.251 167.513 616.039 172.438 633.596 172.438L633.605 172.447C667.684 172.447 685.511 150.593 685.511 130.613C685.511 110.634 671.132 96.1855 646.055 90.99L624.79 86.8672C615.629 85.123 609.936 79.4332 609.936 72.0178C609.936 62.8955 619.199 55.7413 631.024 55.7413C651.833 55.7413 656.604 70.4974 657.573 75.0213L657.676 75.5063L683.927 68.3428L683.834 67.9137C682.828 63.1846 679.827 54.0717 671.151 46.0873C661.459 37.1702 647.966 32.6556 631.024 32.6556C603.18 32.6556 580.535 51.5439 580.535 74.7508C580.535 93.6857 594.327 107.882 617.428 112.742L638.088 117.116C649.149 119.28 655.244 124.718 655.244 132.805C655.244 140.892 648.46 149.054 633.298 149.054C614.362 149.054 605.789 137.712 604.093 127.097L604.009 126.584L576.882 133.757L576.947 134.167C578.261 142.637 583.452 151.619 590.823 158.204Z"
                fill="currentColor"
            />
            <path
                d="M27.5278 102.519V32.693H0V102.519C0 119.738 6.22497 135.501 16.5502 147.673C29.3543 162.765 48.4392 172.345 69.77 172.345C93.5051 172.345 114.472 160.48 127.071 142.357L175.138 77.6424C198.137 46.05 250.723 61.6923 251.524 101.651L272.715 73.0812C250.537 23.9438 178.949 18.8696 150.378 64.7704L104.082 127.209C96.4125 137.861 83.888 144.801 69.77 144.801C46.4357 144.801 27.5278 125.866 27.5278 102.519Z"
                fill="currentColor"
            />
            <path d="M167.049 103.315L167.059 103.302L167.049 103.312L167.049 103.315Z" fill="currentColor" />
            <path
                d="M167.049 103.315L145.933 131.789C168.214 180.012 236.419 185.748 266.593 142.357L314.659 77.6424C337.658 46.05 390.244 61.6923 391.046 101.651L412.479 73.0812C390.636 23.6453 318.275 19.1681 289.89 64.7704L243.576 127.209C220.661 159.043 167.788 143.421 167.049 103.315Z"
                fill="currentColor"
            />
            <path
                d="M446.111 158.092C490.44 191.895 558.114 159.594 558.114 102.51C558.114 34.232 465.289 7.29414 429.523 64.761L383.134 127.2C360.238 159.062 307.316 143.392 306.589 103.293L285.454 131.789C307.717 180.021 376.043 185.748 406.217 142.366L454.237 77.5118C476.993 46.3858 530.586 62.4945 530.586 102.519C530.586 156.553 447.164 157.337 446.12 103.358L418.592 140.529V210.009H446.111V158.092Z"
                fill="currentColor"
            />
            <path
                d="M770.993 171.972C731.416 171.972 701.568 142.058 701.568 102.388C701.568 62.7184 731.052 33.1128 770.145 33.1128C806.33 33.1128 825.461 55.2377 831.109 74.6949L804.541 84.1437C801.969 75.7489 793.964 59.6683 770.415 59.6683C751.61 59.6683 731.36 73.0346 731.36 102.388C731.36 131.742 751.908 145.677 770.993 145.677C790.078 145.677 801.978 133.402 806.171 121.435L832.19 130.632C825.657 149.987 805.957 171.972 770.993 171.972Z"
                fill="currentColor"
            />
            <path
                d="M1135.91 171.972C1117.5 171.972 1100.49 165.433 1088.01 153.568C1074.5 140.724 1067.36 122.844 1067.36 101.857C1067.36 58.8287 1100.51 33.1221 1132.55 33.1221C1152.66 33.1221 1169.34 39.4834 1180.76 51.5066C1192.05 63.3899 1198.01 80.5152 1198.01 101.036C1198.01 104.925 1197.69 108.246 1197.46 109.794H1097.07L1097.12 111.231C1097.98 131.817 1114.65 147.347 1135.92 147.347C1153.31 147.347 1165.44 139.419 1171.11 124.392L1195.97 132.069C1189.31 151.6 1170.21 171.972 1135.93 171.972H1135.91ZM1132.84 56.9259C1111.78 56.9259 1099.16 72.82 1097.96 87.7627L1097.84 89.2551H1168.03L1167.97 87.8187C1167.44 73.5662 1157.93 56.9166 1132.83 56.9166L1132.84 56.9259Z"
                fill="currentColor"
            />
            <path
                d="M846.056 101.847C846.056 142.805 872.633 171.403 910.673 171.403C928.183 171.403 943.084 162.999 949.569 149.464L952.272 150.052V167.84H980.638V37.2076H951.722V53.3628L948.982 53.8665C945.162 44.4084 932.684 33.3646 910.114 33.3646C872.997 33.3646 846.056 60.8902 846.056 101.847ZM875.82 101.847C875.82 75.7862 891.299 58.2785 914.326 58.2785C936.859 58.2785 951.992 75.4038 951.992 101.847C951.992 128.291 937.362 146.769 914.326 146.769C891.29 146.769 875.82 127.908 875.82 101.847Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}

export function LoopscaleSvgOuterLogo({ sx }: { sx?: SxType }) {
    const height = 1.3; // 1em height

    return (
        <StyledSvg
            width={`${(955 / 154) * height}em`} // Maintains aspect ratio
            height={`${height}em`}
            viewBox="0 0 955 154" // Using the actual SVG viewBox
            fill="none"
            sx={sx}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.3482 92.9156L39.7412 72.1368C40.7382 70.7918 42.8517 71.3511 43.0733 73.0157C47.7701 108.283 95.6508 120.908 116.924 91.3353L165.651 25.559C166.395 24.5557 167.986 25.0796 167.986 26.3313V64.8348C167.986 66.4327 167.472 67.9908 166.519 69.2736L139.233 106.042C110.68 147.138 46.9991 142.832 23.8607 99.1035C22.815 97.1237 23.0145 94.7178 24.3482 92.9156Z"
                fill="currentColor"
            />
            <path
                d="M143.062 41.6394L127.58 62.5336C126.583 63.8786 124.474 63.3193 124.248 61.6591C119.511 26.5204 71.8876 13.8782 50.5393 43.2241L23.1472 80.1384L2.33954 108.499C1.59957 109.511 0 108.983 0 107.731V68.9922C0 67.3941 0.513989 65.8405 1.46221 64.5577L26.5236 30.7329C53.5789 -12.7688 120.397 -9.10223 143.567 35.4826C144.595 37.4579 144.387 39.8505 143.062 41.6394Z"
                fill="currentColor"
            />
            <path d="M220.72 121.069V8.07127H241.846V103.151H298.612V121.069H220.72Z" fill="currentColor" />
            <path
                d="M344.815 123.975C321.108 123.975 304.498 108.155 304.498 82.0042C304.498 56.1761 321.27 39.7107 344.815 39.7107C368.521 39.7107 385.292 56.1761 385.292 82.0042C385.292 108.155 368.521 123.975 344.815 123.975ZM344.815 108.478C357.071 108.478 365.295 98.7924 365.295 82.0042C365.295 64.8931 356.909 55.369 344.815 55.369C332.881 55.369 324.495 64.8931 324.495 82.0042C324.495 98.7924 332.558 108.478 344.815 108.478Z"
                fill="currentColor"
            />
            <path
                d="M436.629 123.975C412.923 123.975 396.313 108.155 396.313 82.0042C396.313 56.1761 413.084 39.7107 436.629 39.7107C460.335 39.7107 477.107 56.1761 477.107 82.0042C477.107 108.155 460.335 123.975 436.629 123.975ZM436.629 108.478C448.886 108.478 457.11 98.7924 457.11 82.0042C457.11 64.8931 448.724 55.369 436.629 55.369C424.696 55.369 416.31 64.8931 416.31 82.0042C416.31 98.7924 424.373 108.478 436.629 108.478Z"
                fill="currentColor"
            />
            <path
                d="M492.804 154V42.2935H512.479V45.8449C512.479 48.2662 512.317 50.3648 511.834 53.5933C516.833 45.1992 525.219 39.7107 537.636 39.7107C558.44 39.7107 572.147 57.1446 572.147 82.1656C572.147 107.671 556.827 123.975 536.83 123.975C523.606 123.975 516.833 118.325 511.834 110.254C512.317 113.482 512.479 115.581 512.479 117.841V154H492.804ZM531.992 108.478C544.087 108.478 552.15 97.6625 552.15 81.8428C552.15 66.3459 544.571 55.369 531.992 55.369C520.219 55.369 511.672 65.5388 511.672 81.8428C511.672 97.6625 520.058 108.478 531.992 108.478Z"
                fill="currentColor"
            />
            <path
                d="M619.054 123.975C596.8 123.975 583.092 112.352 581.802 93.9497H601.799C602.283 103.312 608.088 109.285 618.571 109.285C627.44 109.285 633.084 105.088 633.084 99.1153C633.084 93.7883 628.569 91.3669 620.183 89.7526L610.668 87.8155C594.219 84.587 584.866 76.8386 584.866 63.7631C584.866 50.0419 597.767 39.7107 617.119 39.7107C638.245 39.7107 651.469 50.6876 651.953 67.153H632.278C631.633 59.4046 625.989 54.4004 617.119 54.4004C609.862 54.4004 604.218 57.3061 604.218 62.956C604.218 67.6373 607.282 70.0587 617.28 71.9958L626.795 73.9329C642.277 77 652.436 84.1027 652.436 97.9853C652.436 112.998 639.374 123.975 619.054 123.975Z"
                fill="currentColor"
            />
            <path
                d="M703.4 123.975C678.404 123.975 663.568 107.832 663.568 81.6813C663.568 55.369 681.468 39.7107 704.368 39.7107C727.268 39.7107 739.685 53.5933 741.621 71.9958H721.624C720.979 63.1174 715.495 55.369 704.368 55.369C692.918 55.369 683.565 64.7317 683.565 81.8428C683.565 97.501 691.789 108.478 703.884 108.478C715.818 108.478 721.462 100.407 722.43 91.044H742.427C739.685 111.061 726.784 123.975 703.4 123.975Z"
                fill="currentColor"
            />
            <path
                d="M779.809 123.975C766.102 123.975 752.716 114.935 752.716 99.4382C752.716 81.6813 766.424 75.5472 783.357 73.2872L794.646 71.8344C801.096 71.0273 803.354 68.6059 803.354 64.7317C803.354 59.2432 798.839 54.4004 790.453 54.4004C781.261 54.4004 775.455 59.566 774.649 67.7987H754.329C755.619 51.3333 769.004 39.7107 789.324 39.7107C813.353 39.7107 823.029 52.6247 823.029 74.74V121.069H804.806V117.841C804.806 115.258 805.128 112.836 805.612 110.254C801.419 118.002 793.356 123.975 779.809 123.975ZM785.131 109.447C795.613 109.447 803.999 101.698 803.999 90.5597V82.0042C802.225 83.6184 799.484 84.4256 794.646 85.3941L788.195 86.6855C779.003 88.4612 772.714 91.5283 772.714 98.631C772.714 105.572 778.197 109.447 785.131 109.447Z"
                fill="currentColor"
            />
            <path d="M842.267 121.069V8.07127H861.942V121.069H842.267Z" fill="currentColor" />
            <path
                d="M917.748 123.975C894.203 123.975 877.592 108.801 877.592 81.5199C877.592 56.6604 894.203 39.7107 917.102 39.7107C941.131 39.7107 955 56.4989 955 82.1656V86.5241H897.75C897.912 98.9539 905.814 108.478 917.748 108.478C926.295 108.478 933.229 103.635 934.519 95.4025H954.516C952.904 111.545 939.035 123.975 917.748 123.975ZM934.842 72.3187C934.519 61.6646 927.585 54.4004 916.941 54.4004C906.298 54.4004 898.718 61.9874 897.589 72.3187H934.842Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}

interface IconWithBackgroundProps extends Omit<TextProps, "color"> {
    size?: number;
    tagColor?: TagColor;
}
export function IconWithBackground({ sx, tagColor = "body", size = 30, ...props }: IconWithBackgroundProps) {
    const { isDarkMode } = useAppPalette();
    const color = useColorFromTag(tagColor);
    return (
        <Text
            sx={{
                backgroundColor: colorToAlpha(color, isDarkMode ? 0.1 : 0.05),
                color,
                borderRadius: "100%",
                display: "flex",
                width: size,
                maxWidth: size,
                minWidth: size,
                height: size,
                maxHeight: size,
                minHeight: size,
                alignItems: "center",
                justifyContent: "center",
                ...sx
            }}
            {...props}
        />
    );
}

export function IconHeader({ children, tagColor }: { children: ReactNode; tagColor?: TagColor }) {
    return (
        <IconWithBackground tagColor={tagColor} variant="h2" size={50} sx={{ alignSelf: "center" }}>
            {children}
        </IconWithBackground>
    );
}

export function IconLink({
    type,
    sx,
    variant,
    href,
    padding = true
}: {
    type: IconType;
    sx?: SxType;
    variant?: TextVariant;
    href?: string;
    padding?: boolean;
}) {
    const { primary } = useAppPalette();
    return (
        <MuiIconButton
            sx={{ fontSize: "inherit", padding: padding ? 0.5 : 0.25 }}
            onClick={() => href && window.open(href)}
        >
            <Text color="caption" sx={{ ":hover": { color: primary } }} variant={variant}>
                <Icon sx={sx} type={type} />
            </Text>
        </MuiIconButton>
    );
}

export function VerifiedIcon({ variant }: { variant?: TextVariant }) {
    return (
        <Text variant={variant} sx={{ color: "#628EFF" }}>
            <Verified />
        </Text>
    );
}

function Sparkle({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg
            sx={sx}
            width="0.8em"
            height="0.8em"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10.5 5.5L8 0L5.5 5.5L0 8L5.5 10.5L8 16L10.5 10.5L16 8L10.5 5.5Z" fill="currentColor" />
        </StyledSvg>
    );
}

export const SolanaSvg = () => {
    return (
        <StyledSvg width="0.8em" height="0.8em" viewBox="0 0 96 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M95.5053 67.8049L79.658 84.8288C79.3134 85.1986 78.8966 85.4934 78.4334 85.6949C77.9703 85.8964 77.4706 86.0003 76.9656 86H1.8398C1.48134 86 1.13068 85.8951 0.830924 85.6982C0.531164 85.5013 0.295357 85.221 0.152475 84.8917C0.00959266 84.5624 -0.03414 84.1985 0.0266501 83.8446C0.0874403 83.4908 0.250105 83.1624 0.494658 82.8999L16.3543 65.876C16.6979 65.5072 17.1134 65.2129 17.5751 65.0115C18.0368 64.81 18.5349 64.7056 19.0385 64.7048H94.1602C94.5187 64.7048 94.8693 64.8097 95.1691 65.0066C95.4688 65.2035 95.7046 65.4838 95.8475 65.8131C95.9904 66.1424 96.0341 66.5063 95.9734 66.8601C95.9126 67.214 95.7499 67.5423 95.5053 67.8049ZM79.658 33.5236C79.3134 33.1538 78.8966 32.859 78.4334 32.6575C77.9703 32.456 77.4706 32.3521 76.9656 32.3524H1.8398C1.48134 32.3524 1.13068 32.4573 0.830924 32.6542C0.531164 32.8511 0.295357 33.1314 0.152475 33.4607C0.00959266 33.79 -0.03414 34.1539 0.0266501 34.5078C0.0874403 34.8616 0.250105 35.19 0.494658 35.4525L16.3543 52.4764C16.6979 52.8452 17.1134 53.1394 17.5751 53.3409C18.0368 53.5424 18.5349 53.6468 19.0385 53.6476H94.1602C94.5187 53.6476 94.8693 53.5427 95.1691 53.3458C95.4688 53.1489 95.7046 52.8686 95.8475 52.5393C95.9904 52.21 96.0341 51.8461 95.9734 51.4922C95.9126 51.1384 95.7499 50.81 95.5053 50.5475L79.658 33.5236ZM1.8398 21.2952H76.9656C77.4706 21.2955 77.9703 21.1917 78.4334 20.9902C78.8966 20.7887 79.3134 20.4938 79.658 20.124L95.5053 3.1001C95.7499 2.83758 95.9126 2.50922 95.9734 2.15538C96.0341 1.80153 95.9904 1.4376 95.8475 1.10831C95.7046 0.779013 95.4688 0.498699 95.1691 0.301804C94.8693 0.10491 94.5187 1.21255e-05 94.1602 0L19.0385 0C18.5349 0.000858433 18.0368 0.105251 17.5751 0.306715C17.1134 0.508179 16.6979 0.802426 16.3543 1.17124L0.498747 18.1951C0.25443 18.4574 0.0918367 18.7854 0.0309086 19.1389C-0.0300194 19.4923 0.0133662 19.8559 0.155745 20.1851C0.298123 20.5142 0.533305 20.7945 0.832447 20.9918C1.13159 21.189 1.48169 21.2944 1.8398 21.2952Z"
                fill={"currentColor"}
            />
        </StyledSvg>
    );
};

export const EthSvg = () => {
    return (
        <StyledSvg width="0.8em" height="0.8em" viewBox="0 0 653 1063" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <mask id="mask0_3118_29093" maskUnits="userSpaceOnUse" x="0" y="392" width="653" height="342">
                    <path d="M0 392.732H652.033V733.682H0V392.732Z" fill="white" />
                </mask>
                <g mask="url(#mask0_3118_29093)">
                    <path
                        d="M326.081 392.732L0 541.033L326.081 733.682L652.033 541.033L326.081 392.732Z"
                        fill="currentColor"
                    />
                </g>
            </g>
            <g opacity="0.45">
                <mask id="mask1_3118_29093" maskUnits="userSpaceOnUse" x="0" y="0" width="327" height="734">
                    <path d="M0 0H326.081V733.682H0V0Z" fill="white" />
                </mask>
                <g mask="url(#mask1_3118_29093)">
                    <path d="M0 541.033L326.081 733.682V0L0 541.033Z" fill="currentColor" />
                </g>
            </g>
            <g opacity="0.8">
                <mask id="mask2_3118_29093" maskUnits="userSpaceOnUse" x="326" y="0" width="327" height="734">
                    <path d="M326.081 0H652.162V733.682H326.081V0Z" fill="white" />
                </mask>
                <g mask="url(#mask2_3118_29093)">
                    <path d="M326.081 0V733.682L652.034 541.033L326.081 0Z" fill="currentColor" />
                </g>
            </g>
            <g opacity="0.45">
                <mask id="mask3_3118_29093" maskUnits="userSpaceOnUse" x="0" y="602" width="327" height="461">
                    <path d="M0 602.813H326.081V1062.2H0V602.813Z" fill="white" />
                </mask>
                <g mask="url(#mask3_3118_29093)">
                    <path d="M0 602.813L326.081 1062.2V795.463L0 602.813Z" fill="currentColor" />
                </g>
            </g>
            <g opacity="0.8">
                <mask id="mask4_3118_29093" maskUnits="userSpaceOnUse" x="326" y="602" width="327" height="461">
                    <path d="M326.081 602.813H652.29V1062.2H326.081V602.813Z" fill="white" />
                </mask>
                <g mask="url(#mask4_3118_29093)">
                    <path d="M326.081 795.463V1062.2L652.29 602.813L326.081 795.463Z" fill="currentColor" />
                </g>
            </g>
        </StyledSvg>
    );
};

export const MaticSvg = () => {
    return (
        <StyledSvg width="1em" height="1em" viewBox="0 0 113 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M83.8291 70.4275L110.61 54.9635C112.028 54.1441 112.906 52.6212 112.906 50.9824V20.0582C112.906 18.4233 112.025 16.8966 110.61 16.0772L83.8291 0.613095C82.4106 -0.206296 80.652 -0.202431 79.2335 0.613095L52.4526 16.0772C51.0341 16.8966 50.1568 18.4233 50.1568 20.0582V75.3245L31.3765 86.166L12.5962 75.3245V53.6377L31.3765 42.7963L43.764 49.9466V35.3986L33.6723 29.5739C32.9766 29.172 32.1804 28.9594 31.3726 28.9594C30.5648 28.9594 29.7686 29.172 29.0768 29.5739L2.29584 45.038C0.877364 45.8574 0 47.3802 0 49.019V79.9433C0 81.5782 0.881229 83.1049 2.29584 83.9243L29.0768 99.3883C30.4914 100.204 32.2538 100.204 33.6723 99.3883L60.4532 83.9281C61.8717 83.1088 62.7491 81.5821 62.7491 79.9471V24.6808L63.0892 24.4875L81.5294 13.8393L100.31 24.6808V46.3676L81.5294 57.209L69.1612 50.0664V64.6145L79.2335 70.4314C80.652 71.2469 82.4106 71.2469 83.8291 70.4314V70.4275Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
};

export function Dot({ color, size = 6 }: { color: string; size?: number }) {
    return <SpanBold sx={{ width: `${size}px`, height: `${size}px`, borderRadius: "100%", background: color }} />;
}

export function PrimeSvg({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg
            sx={sx}
            width="0.7rem"
            height="0.7rem"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 13.2716 1.57111 16.1763 4 18.0007V8H8V12H12V16H8V19.8C8.64622 19.9311 9.31507 20 10 20ZM12 12V8H8V4H12C14.2092 4 16 5.79102 16 8C16 10.209 14.2092 12 12 12Z"
                fill="#F5BE53"
            />
        </StyledSvg>
    );
}

export function DiscordSvg() {
    return (
        <StyledSvg width="1em" height="1em" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.209 1.01575C13.1844 0.545603 12.0856 0.19922 10.9368 0.000832211C10.9159 -0.00299661 10.895 0.00657182 10.8842 0.0257091C10.7429 0.277042 10.5864 0.604926 10.4768 0.862641C9.24113 0.677653 8.01183 0.677653 6.80153 0.862641C6.6919 0.599197 6.52969 0.277042 6.38774 0.0257091C6.37697 0.00721034 6.35607 -0.0023581 6.33515 0.000832211C5.18695 0.198586 4.08819 0.544969 3.06292 1.01575C3.05404 1.01957 3.04644 1.02596 3.04139 1.03425C0.957261 4.14789 0.386331 7.185 0.66641 10.1845C0.667677 10.1991 0.675915 10.2132 0.687321 10.2221C2.06237 11.2319 3.39434 11.8449 4.70157 12.2513C4.72249 12.2577 4.74466 12.25 4.75797 12.2328C5.0672 11.8105 5.34285 11.3652 5.57919 10.897C5.59313 10.8696 5.57982 10.837 5.55131 10.8262C5.11409 10.6603 4.69776 10.4581 4.29729 10.2285C4.26561 10.21 4.26308 10.1647 4.29222 10.143C4.37649 10.0798 4.46079 10.0141 4.54126 9.94777C4.55582 9.93566 4.57611 9.9331 4.59322 9.94075C7.22418 11.142 10.0725 11.142 12.6724 9.94075C12.6895 9.93247 12.7098 9.93502 12.725 9.94714C12.8055 10.0135 12.8898 10.0798 12.9747 10.143C13.0038 10.1647 13.0019 10.21 12.9702 10.2285C12.5698 10.4626 12.1534 10.6603 11.7156 10.8255C11.6871 10.8364 11.6744 10.8696 11.6883 10.897C11.9298 11.3646 12.2054 11.8098 12.5089 12.2321C12.5216 12.25 12.5444 12.2577 12.5653 12.2513C13.8789 11.8449 15.2109 11.2319 16.5859 10.2221C16.5979 10.2132 16.6056 10.1998 16.6068 10.1851C16.942 6.71739 16.0454 3.70518 14.2299 1.03488C14.2255 1.02596 14.2179 1.01957 14.209 1.01575ZM5.97208 8.3581C5.17998 8.3581 4.52731 7.63089 4.52731 6.73781C4.52731 5.84473 5.16732 5.11752 5.97208 5.11752C6.78315 5.11752 7.4295 5.85111 7.41682 6.73781C7.41682 7.63089 6.77681 8.3581 5.97208 8.3581ZM11.3138 8.3581C10.5218 8.3581 9.8691 7.63089 9.8691 6.73781C9.8691 5.84473 10.5091 5.11752 11.3138 5.11752C12.1249 5.11752 12.7713 5.85111 12.7586 6.73781C12.7586 7.63089 12.1249 8.3581 11.3138 8.3581Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}

export function TwitterSvg() {
    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="0.85em" height="0.85em">
            <path
                fill="currentColor"
                d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"
            />
        </StyledSvg>
    );
}

const AVATAR_PATHS = [
    "M240 0A240 240 0 0 0 0 240v240h240a240 240 0 0 0 240-240V0H240Zm0 360a120 120 0 1 1 0-240 120 120 0 0 1 0 240Z",
    "M450 210a169.3 169.3 0 0 0-130.6-49.4c2.9-46.9-13.6-94.8-49.4-130.6L240 0l-30 30a169.3 169.3 0 0 0-49.4 130.6C113.7 157.7 65.8 174.2 30 210L0 240l30 30a169.3 169.3 0 0 0 130.6 49.4c-2.9 46.9 13.6 94.8 49.4 130.6l30 30 30-30a169.3 169.3 0 0 0 49.4-130.6c46.9 2.9 94.8-13.6 130.6-49.4l30-30-30-30Z",
    "M240 0v240h240A240 240 0 0 0 240 0ZM240 480h240V240a240 240 0 0 0-240 240ZM0 240a240 240 0 0 0 240 240V240H0ZM0 240V0h240A240 240 0 0 1 0 240Z",
    "M360 289.7c43.4 0 86.9-16.6 120-49.7a169.2 169.2 0 0 0-120-49.7 169.2 169.2 0 0 0 49.7-120c-46.9 0-89.3 19-120 49.7 0-43.4-16.6-86.9-49.7-120a169.2 169.2 0 0 0-49.7 120 169.2 169.2 0 0 0-120-49.7c0 46.8 19 89.3 49.7 120-43.4 0-86.9 16.6-120 49.7a169.2 169.2 0 0 0 120 49.7 169.2 169.2 0 0 0-49.7 120c46.8 0 89.3-19 120-49.7 0 43.4 16.6 86.9 49.7 120a169.2 169.2 0 0 0 49.7-120 169.2 169.2 0 0 0 120 49.7c0-46.9-19-89.3-49.7-120Z",
    "M480 240H240V0a240 240 0 0 1 240 240ZM240 480H0V240a240 240 0 0 1 240 240ZM480 480H240V240a240 240 0 0 1 240 240ZM240 240H0V0a240 240 0 0 1 240 240Z",
    "M240 0H0a240 240 0 0 0 240 240h240A240 240 0 0 0 240 0ZM240 240H0a240 240 0 0 0 240 240h240a240 240 0 0 0-240-240Z",
    "m404.1 185.3-54.7 18.2a57.4 57.4 0 0 1-27.1 2.4c5.1-7 12-13 20.9-17.5l51.5-25.8a57.7 57.7 0 1 0-77.4-77.4l-25.7 51.6a57.4 57.4 0 0 1-17.5 21 57.4 57.4 0 0 1 2.4-27.2l18.2-54.7a57.7 57.7 0 1 0-109.4 0l18.2 54.7a57.4 57.4 0 0 1 2.4 27.1 57.3 57.3 0 0 1-17.5-20.8l-25.8-51.6a57.7 57.7 0 1 0-77.4 77.4l51.6 25.7a57.4 57.4 0 0 1 21 17.5c-8.7 1.4-18 .7-27.2-2.4l-54.7-18.2a57.7 57.7 0 1 0 0 109.4l54.7-18.2a57.4 57.4 0 0 1 27.1-2.4 57.3 57.3 0 0 1-20.8 17.5l-51.6 25.8a57.7 57.7 0 1 0 77.4 77.4l25.7-51.6a57.4 57.4 0 0 1 17.5-21c1.4 8.7.7 18-2.4 27.2l-18.2 54.7a57.7 57.7 0 1 0 109.4 0l-18.2-54.7a57.4 57.4 0 0 1-2.4-27.1c7 5.1 13 12 17.5 20.9l25.8 51.5a57.7 57.7 0 1 0 77.4-77.4l-51.6-25.7a57.4 57.4 0 0 1-21-17.5c8.7-1.4 18-.7 27.2 2.4l54.7 18.2a57.7 57.7 0 1 0 0-109.4Z",
    "M480 240a160 160 0 0 0-240-138.6V0a160 160 0 0 0-138.6 240H0a160 160 0 0 0 240 138.6V480a160 160 0 0 0 138.6-240H480Z",
    "M480 240a240 240 0 0 0-240 240 240 240 0 0 0 240-240ZM240 0A240 240 0 0 0 0 240 240 240 0 0 0 240 0ZM480 240A240 240 0 0 0 240 0a240 240 0 0 0 240 240ZM240 480A240 240 0 0 0 0 240a240 240 0 0 0 240 240Z"
];

export function RandomAvatarSvg({ seed, size = "1em" }: { seed: string; size?: string }) {
    const avatarIndex = getDeterministicIndexFromSeed(seed.charAt(0), AVATAR_PATHS.length);

    return (
        <StyledSvg width={size} height={size} viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={AVATAR_PATHS[avatarIndex]} fill="currentColor" />
        </StyledSvg>
    );
}

export function BaseApySvg({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg sx={sx} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.74805 11.2539C1.31055 11.2539 0.976562 11.1426 0.746094 10.9199C0.519531 10.7012 0.40625 10.3848 0.40625 9.9707V4.9375C0.40625 4.51953 0.519531 4.20117 0.746094 3.98242C0.976562 3.75977 1.31055 3.64844 1.74805 3.64844H3.41211C3.84961 3.64844 4.18164 3.75977 4.4082 3.98242C4.63867 4.20117 4.75391 4.51953 4.75391 4.9375V9.9707C4.75391 10.3848 4.63867 10.7012 4.4082 10.9199C4.18164 11.1426 3.84961 11.2539 3.41211 11.2539H1.74805ZM7.16211 11.2539C6.72461 11.2539 6.39062 11.1426 6.16016 10.9199C5.93359 10.7012 5.82031 10.3848 5.82031 9.9707V3.22656C5.82031 2.80859 5.93359 2.48828 6.16016 2.26562C6.39062 2.04297 6.72461 1.93164 7.16211 1.93164H8.82617C9.26367 1.93164 9.5957 2.04297 9.82227 2.26562C10.0527 2.48828 10.168 2.80859 10.168 3.22656V9.9707C10.168 10.3848 10.0527 10.7012 9.82227 10.9199C9.5957 11.1426 9.26367 11.2539 8.82617 11.2539H7.16211ZM12.5762 11.2539C12.1426 11.2539 11.8105 11.1426 11.5801 10.9199C11.3535 10.7012 11.2402 10.3848 11.2402 9.9707V1.51562C11.2402 1.10156 11.3535 0.783203 11.5801 0.560547C11.8105 0.337891 12.1426 0.226562 12.5762 0.226562H14.2402C14.6816 0.226562 15.0156 0.337891 15.2422 0.560547C15.4727 0.783203 15.5879 1.10156 15.5879 1.51562V9.9707C15.5879 10.3848 15.4727 10.7012 15.2422 10.9199C15.0156 11.1426 14.6816 11.2539 14.2402 11.2539H12.5762Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}

export function MarginFi({ sx }: { sx?: SxType }) {
    return (
        <StyledSvg sx={sx} width="1em" height="1em" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.28166 0.0340411C8.4067 0.0230922 8.53244 0.0120808 8.65879 0L8.6496 7.00901H8.46209C8.22627 7.03561 7.99956 7.1141 7.79871 7.23861C6.72294 8.03801 6.34069 9.2141 5.94603 10.4283L5.93662 10.4573C5.59817 11.5619 4.90661 12.5296 3.96469 13.2167C3.10344 13.7975 2.16359 13.8791 1.17668 13.9648C1.0469 13.9761 0.916324 13.9874 0.784996 14H0.258789V7.00451H0.533342C0.922345 6.99466 1.30273 6.88992 1.64049 6.69979C1.97824 6.50965 2.26282 6.24006 2.46862 5.91509C2.92903 5.17816 3.27738 4.37894 3.5026 3.54276C3.84131 2.43831 4.53483 1.47166 5.47908 0.78778C6.34056 0.204038 7.28863 0.121008 8.28166 0.0340411ZM8.65879 6.91766H15.7411V14H8.65879V6.91766Z"
                fill="currentColor"
            />
        </StyledSvg>
    );
}
