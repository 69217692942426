import { useMemo } from "react";

import { AppButton } from "app/components/common";
import { COPY } from "app/constants";
import { Column, TagTextAlert } from "@bridgesplit/ui";
import { useTransactionSender } from "app/components/transactions";
import {
    Result,
    LOADING_ERROR,
    MISSING_PARAM_ERROR,
    ALLOWED_FEATURES,
    formatTokenAmount,
    MISSING_WALLET_ERROR
} from "@bridgesplit/utils";
import {
    getVaultLpVirtualPrice,
    useCurrentTime,
    useVaultWithdrawTransaction,
    useActiveWallet
} from "@bridgesplit/abf-react";
import { TrackTransactionEvent } from "app/types";

import { useVaultWithdrawContext } from "./VaultWithdrawContext";
import { useMaxVaultWithdraw } from "./util";
import { useVaultContext } from "../VaultContext";

export default function VaultWithdrawCta() {
    const { form } = useVaultWithdrawContext();
    const withdraw = useWithdraw();

    const { vaultExpanded } = useVaultContext();
    const balances = useMaxVaultWithdraw();
    const ctaError = useMemo(() => {
        if (!vaultExpanded) return "Loading";
        if (!form.amountPrincipal) return "Enter an amount";
        if (!balances) return "No balance available";

        return undefined;
    }, [form.amountPrincipal, vaultExpanded, balances]);

    const warningError = useMemo(() => {
        if (balances) {
            if (balances?.userLpBalance > balances.maxWithdraw) {
                return `Full amount of ${formatTokenAmount(balances.userBalance, {
                    symbol: vaultExpanded?.principalMetadata.symbol
                })} available to withdraw after more loans complete`;
            }
        }
        return undefined;
    }, [balances, vaultExpanded?.principalMetadata.symbol]);

    const alertError = useMemo(() => {
        if (!form.amountPrincipal) return undefined;
        if (balances) {
            if (balances.userBalance < form.amountPrincipal) return "Withdrawal amount exceeds your balance";
            if (balances.maxWithdraw < form.amountPrincipal)
                return `Only ${balances.maxWithdraw} available to withdraw`;
        }
        return undefined;
    }, [balances, form.amountPrincipal]);

    return (
        <Column spacing={1}>
            {(!!warningError || !!alertError) && (
                <TagTextAlert color={alertError ? "error" : "warning"} icon="warning">
                    {alertError ?? warningError}
                </TagTextAlert>
            )}

            <AppButton
                asyncCta={{ onClickWithResult: withdraw }}
                isTransaction
                color="secondary"
                disabled={!!alertError || !!ctaError}
            >
                {ctaError || COPY.VAULT_WITHDRAW_CTA}
            </AppButton>
        </Column>
    );
}

function useWithdraw() {
    const vaultWithdraw = useVaultWithdrawTransaction();
    const send = useTransactionSender();
    const { vaultExpanded, slippageSetting, resetSlippageSetting } = useVaultContext();
    const { form, resetWithdrawForm } = useVaultWithdrawContext();
    const { now } = useCurrentTime(1_000);
    const balances = useMaxVaultWithdraw();
    const { activeWallet } = useActiveWallet();

    return async () => {
        if (!vaultExpanded || !balances) return Result.errFromMessage(LOADING_ERROR);
        if (!form.amountPrincipal) return Result.errFromMessage(MISSING_PARAM_ERROR);
        if (slippageSetting === undefined) return Result.errFromMessage(MISSING_PARAM_ERROR);
        const vaultPrice = getVaultLpVirtualPrice(vaultExpanded, now);
        if (!vaultPrice) return Result.errFromMessage(LOADING_ERROR);
        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);

        const amountPrincipal = form.amountPrincipal;
        const lpAmount = amountPrincipal / vaultPrice;
        const maxAmountLp = (1 + slippageSetting) * lpAmount;
        const withdrawAll = lpAmount === balances?.userLpBalance || form.isMaxWithdraw;
        function reset() {
            if (ALLOWED_FEATURES.showVaultWithdrawSlippage) resetSlippageSetting();
            resetWithdrawForm();
        }

        return await send(
            vaultWithdraw,
            {
                amountPrincipal,
                maxAmountLp,
                principalMetadata: vaultExpanded.principalMetadata,
                vault: vaultExpanded.vault.address,
                withdrawAll,
                userWallet: activeWallet
            },
            {
                sendOptions: { onSuccess: reset },
                mixpanelEvent: {
                    key: TrackTransactionEvent.SubmitLendVaultWithdraw,
                    params: {
                        vaultAddress: vaultExpanded.vault.address,
                        amount: amountPrincipal,
                        principalMint: vaultExpanded.principalMetadata.mint,
                        maxAmountLp,
                        withdrawAll
                    }
                }
            }
        );
    };
}
