import { useCallback } from "react";

import { DEFAULT_PUBLIC_KEY, bsMath, filterTruthy } from "@bridgesplit/utils";
import { useObjectToMap } from "@bridgesplit/ui";
import { OracleQuote, OracleQuoteInfo, UncertaintyType } from "@bridgesplit/abf-sdk";

import { useOraclePricesQuery } from "../reducers";
import { PriceFetchType } from "../types";

// mints are unused in query but include mints in params for future refactors

const DEFAULT_POLLING_INTERVAL = 15_000;

export function useOraclePrices(
    mints: (string | undefined | null)[] | undefined,
    priceFetchType: PriceFetchType = PriceFetchType.Spot,
    pollingInterval: number = DEFAULT_POLLING_INTERVAL
) {
    const validMints = mints?.filter(filterTruthy);
    const {
        data: prices,
        isLoading,
        isError
    } = useOraclePricesQuery(undefined, {
        pollingInterval,
        skip: !validMints
    });

    const priceMap = useObjectToMap(prices);

    const getOracle = useCallback(
        function getOracle(
            mint: string | undefined | null,
            priceType: PriceFetchType = priceFetchType
        ): OracleQuote | undefined {
            if (!priceMap || !mint) return undefined;
            const quote = priceMap.get(mint);
            if (!quote) return undefined;
            const baseQuote = quote.quoteMint === DEFAULT_PUBLIC_KEY ? undefined : priceMap.get(quote.quoteMint);
            const spotPrice = quote.spotPrice * (baseQuote?.spotPrice ?? 1);
            const twapPrice = quote.twapPrice * (baseQuote?.twapPrice ?? 1);
            const usdPrice = priceType === PriceFetchType.Spot ? spotPrice : twapPrice;
            const uncertainty = quote.uncertainty;

            const baseQuotes: OracleQuoteInfo[] = baseQuote ? [quote, baseQuote] : [quote];

            return {
                uncertainty,
                oracleAccount: quote.oracleAccount,
                baseQuotes,
                usdPrice,
                getUsdAmount: (...params) => getUsdAmountFromUsdPrice(usdPrice, uncertainty, ...params)
            };
        },
        [priceMap, priceFetchType]
    );

    const getUsdPrice = useCallback(
        function getUsdPrice(
            mint: string | undefined | null,
            uncertaintyType: UncertaintyType = UncertaintyType.Skip
        ): number | undefined {
            const oracle = getOracle(mint);
            if (!oracle) return undefined;
            const basePrice = oracle.usdPrice;
            if (!basePrice) return undefined;
            const uncertainty = oracle.uncertainty;

            if (uncertaintyType === UncertaintyType.Add) return basePrice + uncertainty;
            if (uncertaintyType === UncertaintyType.Subtract) return basePrice - uncertainty;

            return basePrice;
        },
        [getOracle]
    );

    return { getOracle, getUsdPrice, isLoading, isError };
}

export function getUsdAmountFromUsdPrice<T extends number | undefined>(
    usdPrice: number,
    uncertainty: number,
    amount: T,
    decimals?: number,
    uncertaintyType?: UncertaintyType
): T {
    if (uncertaintyType === UncertaintyType.Add) {
        usdPrice += uncertainty;
    } else if (uncertaintyType === UncertaintyType.Subtract) {
        usdPrice -= uncertainty;
    }
    if (decimals) {
        return bsMath.tokenAMul(amount, usdPrice, decimals) as T;
    }
    return bsMath.mul(amount, usdPrice) as T;
}

export function useAllAvailablePrices(mints: string[] | undefined) {
    const { getUsdPrice, isLoading: oracleLoading } = useOraclePrices(mints);

    const getPriceByMint = useCallback(
        (mint: string | undefined) => {
            return getUsdPrice(mint);
        },
        [getUsdPrice]
    );

    return { getPriceByMint, isLoading: oracleLoading };
}

export function isOracleStale(oracleInfo: OracleQuoteInfo) {
    const timeSinceLastUpdate = oracleInfo.nowTimestamp - oracleInfo.lastUpdateTime;
    return timeSinceLastUpdate > oracleInfo.maxAge;
}
