import { useCallback, useMemo } from "react";

import { BORDER_RADIUS_ROUNDED, Button, Row, Slider, Text, useAppPalette, useElementDimensions } from "@bridgesplit/ui";
import { colorToAlpha, formatPercent, roundDownToDecimals } from "@bridgesplit/utils";
import { Box } from "@mui/material";
import debounce from "lodash.debounce";

import { useMarketBorrowContext } from "./MarketBorrowContext";
import { BorrowMode, LTV_PRESETS, LtvPreset } from "./type";
import { useBorrowLtv, useMaxBorrowAmount } from "./util";
import { useMarketContext } from "../common";

export default function BorrowLtv() {
    const { ltv } = useBorrowLtv();
    const { isDialog, token } = useMarketContext();

    const { twapPrices: prices, maxQuote } = useMarketBorrowContext();

    const { maxBorrow } = useMaxBorrowAmount();

    const validPresets = PRESETS.map((preset) => {
        if (!prices.collateralAmountUsd || !prices.principalPriceUsd || !maxQuote) return { preset, valid: false };
        const multiplier = LTV_PRESETS[preset];
        const ltvBasedBorrowUsd = prices.collateralAmountUsd * multiplier * maxQuote.ltv;
        const ltvBasedBorrow = roundDownToDecimals(ltvBasedBorrowUsd / prices.principalPriceUsd, token?.decimals);

        return {
            preset,
            valid: maxBorrow >= ltvBasedBorrow
        };
    });

    return (
        <>
            {!isDialog && (
                <Row spaceBetween>
                    <Text variant="body2" color="caption">
                        {ltv ? `${formatPercent(ltv, { maxPrecisionUi: 0.01 })} LTV` : "LTV"}
                    </Text>

                    <HealthButtons validPresets={validPresets} />
                </Row>
            )}

            <BorrowLtvSlider />
        </>
    );
}

const PRESETS = [LtvPreset.Safe, LtvPreset.Moderate, LtvPreset.Max];

function HealthButtons({ validPresets }: { validPresets: { preset: LtvPreset; valid: boolean }[] }) {
    const { form, setForm } = useMarketBorrowContext();

    return (
        <Row spacing={0.5}>
            {validPresets.map(({ preset, valid }, index) => {
                const multiplier = LTV_PRESETS[preset];
                const isSelected = form.ltvMultiplier === multiplier;

                return (
                    <MaxButton
                        key={`${LTV_PRESETS[preset]}-${index}`}
                        disabled={!valid}
                        onClick={() =>
                            setForm((prev) => ({
                                ...prev,
                                ltvMultiplier: multiplier,
                                mode: BorrowMode.InputLtv,
                                refetchOutAmount: true
                            }))
                        }
                        isSelected={isSelected}
                    >
                        {preset}
                    </MaxButton>
                );
            })}
        </Row>
    );
}

function MaxButton({
    onClick,
    isSelected,
    children,
    disabled
}: {
    onClick: () => void;
    isSelected: boolean;
    children: React.ReactNode;
    disabled: boolean;
}) {
    const { textDisabled, divider, textPrimary, textSecondary } = useAppPalette();
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            sx={{
                border: `0.5px ${isSelected ? textDisabled : divider} solid`,
                px: 1,
                borderRadius: BORDER_RADIUS_ROUNDED
            }}
            width="min-content"
            variant="outlined"
            height={20}
            textProps={{ variant: "caption", sx: { color: isSelected ? textPrimary : textSecondary } }}
        >
            {children}
        </Button>
    );
}

function BorrowLtvSlider() {
    const { form, setForm } = useMarketBorrowContext();
    const { isDialog } = useMarketContext();
    const { primary, textDisabled } = useAppPalette();

    const { gradient, getTrackColor } = getHealthGradient();
    const [ref, dimensions] = useElementDimensions();

    const refetchOutAmount = useCallback(() => {
        setForm((prev) => ({
            ...prev,
            refetchOutAmount: true
        }));
    }, [setForm]);

    const debouncedRefetchOutAmount = useMemo(() => debounce(refetchOutAmount, 200), [refetchOutAmount]);

    return (
        <Box ref={ref}>
            <Slider
                sx={{
                    "& .MuiSlider-rail": { background: colorToAlpha(primary, 0.15) },
                    "& .MuiSlider-track": { background: gradient, backgroundSize: `${dimensions.width}px` },
                    "& .MuiSlider-thumb": {
                        background: form.ltvMultiplier ? getTrackColor(form.ltvMultiplier) : textDisabled,
                        border: "none"
                    }
                }}
                min={0}
                max={100}
                value={form.ltvMultiplier * 100}
                setValue={(val) => {
                    if (!val) {
                        return setForm((prev) => ({
                            ...prev,
                            amount: undefined,
                            mode: BorrowMode.InputLtv,
                            ltvMultiplier: 0
                        }));
                    }
                    setForm((prev) => ({
                        ...prev,
                        mode: BorrowMode.InputLtv,
                        ltvMultiplier: val / 100
                    }));
                    debouncedRefetchOutAmount();
                }}
            />
            {isDialog && <Labels />}
        </Box>
    );
}

function Labels() {
    const variant = "caption" as const;
    const color = "caption" as const;
    return (
        <Row sx={{ mt: -1, mb: 1 }}>
            <Text sx={{ width: "100%" }} variant={variant} color={color}>
                Safe
            </Text>
            <Text sx={{ width: "100%", justifyContent: "center" }} variant={variant} color={color}>
                Moderate
            </Text>
            <Text sx={{ width: "100%", justifyContent: "flex-end" }} variant={variant} color={color}>
                Max
            </Text>
        </Row>
    );
}

function getHealthGradient() {
    const healthColors = ["#FF4F4F", "#FF9A03", "#11D230"];
    const gradient = `linear-gradient(270deg, ${healthColors.join(",")})`;

    function getTrackColor(progress: number) {
        let color: string;

        if (progress < 0.3) {
            color = healthColors[2];
        } else if (progress <= 0.8) {
            color = healthColors[1];
        } else {
            color = healthColors[0];
        }

        return color;
    }

    return { gradient, getTrackColor };
}
