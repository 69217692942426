import { useCallback, useEffect, useMemo } from "react";

import { Column, StatColumn, TextSkeleton } from "@bridgesplit/ui";
import { findMinElement, formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { useExternalYieldVaults } from "@bridgesplit/abf-react";
import { TransactionTypes } from "app/types";

import { useMarketLendContext } from "./MarketLendContext";
import { useMarketContext } from "../common";
import { useTransactionFeeStat } from "../../transactions";

export default function LendLiteStats() {
    const transactionFeeStat = useTransactionFeeStat({ transactionType: TransactionTypes.MarketLend });

    return (
        <Column spacing={0.5}>
            <StatColumn
                stats={[
                    {
                        caption: COPY.EXTERNAL_YIELD_APY_TERM,
                        tooltip: COPY.EXTERNAL_YIELD_APY_TOOLTIP,
                        value: <ExternalYieldApy />
                    },
                    {
                        caption: COPY.STRATEGY_APY_TERM,
                        tooltip: COPY.STRATEGY_APY_TOOLTIP_LITE,
                        value: <Apy />
                    },
                    transactionFeeStat
                ]}
            />
        </Column>
    );
}

function ExternalYieldApy() {
    const { principalMint } = useMarketContext();
    const { getYieldVault, isLoading } = useExternalYieldVaults();

    if (isLoading) return <TextSkeleton variant="body1" width="60px" />;

    return <>{formatPercent(getYieldVault(principalMint)?.apy ?? 0)}</>;
}

function Apy() {
    const { form, setForm } = useMarketLendContext();

    const { principalMint } = useMarketContext();

    const lowestApy = useMemo(
        () => findMinElement(Array.from(form.strategyDurationToApy?.values()), (v) => v),
        [form.strategyDurationToApy]
    );

    const setApy = useCallback(
        (apy: number | undefined) =>
            setForm((prev) => {
                const activeDurations = Array.from(prev.strategyDurationToApy?.keys() ?? []);
                const strategyDurationToApy = new Map(activeDurations.map((m) => [m, apy]));

                return { ...prev, strategyDurationToApy };
            }),
        [setForm]
    );

    // in case any apys are leftover from advanced edit
    useEffect(() => {
        if (lowestApy && Array.from(form.strategyDurationToApy.values()).some((v) => v !== lowestApy)) {
            setApy(lowestApy);
        }
    }, [form.strategyDurationToApy, lowestApy, setApy]);

    if (form.templateApyInitializedForMint !== principalMint) return <TextSkeleton variant="body1" width="60px" />;

    return <>{formatPercent(lowestApy, { skipMultiplication: true })}</>;
}
