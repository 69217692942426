import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import {
    FetchLendVaultsHistoryParams,
    FetchLendVaultsInfoParams,
    LendingVaultAllocation,
    LendingVaultsHistory,
    LendingVaultsInfoResponse,
    LendVaultStatsResponse,
    UserGenesisAccessResponse,
    UserLendingVaultFilter,
    UserVaultStakePositionsResponse
} from "../types";
import { abfSerializeQueryArgs, marketsPublicBaseQuery } from "./util";
import {
    ROUTE_GET_LENDING_USER_VAULT_POSITIONS,
    ROUTE_GET_LENDING_VAULT_ALLOCATIONS,
    ROUTE_GET_LENDING_VAULTS,
    ROUTE_GET_LENDING_VAULTS_HISTORY,
    ROUTE_GET_LENDING_VAULTS_UPDATE_HISTORY,
    ROUTE_GET_USER_LENDING_VAULTS_ACTIVE_STATS,
    ROUTE_GET_USER_POSITION_PNL,
    GET_USER_GENESIS_ACCESS_ROUTE
} from "../constants";

const LENDING_VAULT_POSITION_TAG = "LendingVault";
const LENDING_VAULT_TAG = "LendingInfoVault";

interface DepositTerms {
    deposits: number;
    withdrawals: number;
}

export interface DepositTermSummary {
    lifetimeCapitalFlows?: DepositTerms;
    currentFlows?: DepositTerms;
    mostRecentDepositOrWithdrawalTimestamp?: number;
}

export interface UserPositionDepositTermsParams {
    vaultAddresses?: string[];
    stakeAccounts?: string[];
}
export type UserPositionDepositTermsResponse = Record<string, DepositTermSummary>;

export const lendingVaultApi = createApi({
    reducerPath: "abfLendingVaultApi",
    baseQuery: marketsPublicBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [LENDING_VAULT_POSITION_TAG, LENDING_VAULT_TAG],

    endpoints: (builder) => ({
        lendingVaultHistory: builder.query<LendingVaultsHistory[], FetchLendVaultsHistoryParams>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query(params) {
                return {
                    url: ROUTE_GET_LENDING_VAULTS_HISTORY,
                    method: "POST",
                    body: params
                };
            }
        }),
        lendingVaultUpdateHistory: builder.query<string, void>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query() {
                return {
                    url: ROUTE_GET_LENDING_VAULTS_UPDATE_HISTORY,
                    method: "POST"
                };
            }
        }),
        lendingVaultAllocations: builder.query<LendingVaultAllocation[], { vaultAddress: string }>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query(params) {
                return {
                    url: `${ROUTE_GET_LENDING_VAULT_ALLOCATIONS}/${params.vaultAddress}`,
                    method: "GET"
                };
            }
        }),
        lendingVaultPositions: builder.query<UserVaultStakePositionsResponse, UserLendingVaultFilter>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query(params) {
                return {
                    url: ROUTE_GET_LENDING_USER_VAULT_POSITIONS,
                    method: "POST",
                    body: params
                };
            }
        }),
        lendingVaultInfos: builder.query<LendingVaultsInfoResponse, FetchLendVaultsInfoParams>({
            providesTags: [LENDING_VAULT_TAG],
            query(params) {
                return {
                    url: ROUTE_GET_LENDING_VAULTS,
                    method: "POST",
                    body: params
                };
            }
        }),
        lendVaultPositionStats: builder.query<LendVaultStatsResponse, void>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query() {
                return {
                    url: ROUTE_GET_USER_LENDING_VAULTS_ACTIVE_STATS,
                    method: "GET"
                };
            }
        }),
        userPositionDepositTerms: builder.query<UserPositionDepositTermsResponse, UserPositionDepositTermsParams>({
            providesTags: [LENDING_VAULT_POSITION_TAG],
            query(params) {
                return {
                    url: ROUTE_GET_USER_POSITION_PNL,
                    method: "POST",
                    body: params
                };
            }
        }),
        userGenesisAccess: builder.query<UserGenesisAccessResponse, void>({
            query() {
                return {
                    url: GET_USER_GENESIS_ACCESS_ROUTE,
                    method: "GET"
                };
            }
        })
    })
});

export const {
    useLendingVaultHistoryQuery,
    useLendingVaultUpdateHistoryQuery,
    useLendingVaultAllocationsQuery,
    useLendingVaultPositionsQuery,
    useLendingVaultInfosQuery,
    useLendVaultPositionStatsQuery,
    useUserPositionDepositTermsQuery,
    useUserGenesisAccessQuery
} = lendingVaultApi;

export function useLendingVaultApi() {
    const dispatch = useDispatch();

    return {
        resetLendingVaultPositionsApi: () =>
            dispatch(lendingVaultApi.util.invalidateTags([LENDING_VAULT_POSITION_TAG])),
        resetLendingVaultApi: () => dispatch(lendingVaultApi.util.invalidateTags([LENDING_VAULT_TAG]))
    };
}
