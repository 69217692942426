import { fetchAndDeserializeVersionedTransaction } from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    GetClaimFeeTxnArgs,
    GetUpdateLiquidityTxnArgs,
    GetUpdatePositionTxnArgs,
    TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_CLAIM_FEES,
    TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_LIQUIDITY,
    TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_POSITION
} from "../types";
import { createMarketsInstance } from "./auth";

export async function getWhirlpoolClaimFeeTransaction(authentication: AbfTransactionHeaders, args: GetClaimFeeTxnArgs) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_CLAIM_FEES,
            args
        )
    ];
}

export async function getWhirlpoolUpdatePositionTransaction(
    authentication: AbfTransactionHeaders,
    args: GetUpdatePositionTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_POSITION,
            args
        )
    ];
}

export async function getWhirlpoolUpdateLiquidityTransaction(
    authentication: AbfTransactionHeaders,
    args: GetUpdateLiquidityTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_MANAGE_COLLATERAL_ORCA_UPDATE_LIQUIDITY,
            args
        )
    ];
}
