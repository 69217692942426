import { LoopscalePointsAction, MultiplierInfo } from "@bridgesplit/abf-react";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { TIME } from "@bridgesplit/utils";

export function calculatePointsData({
    loopscalePointsAction,
    metadata = [],
    multiplierInfoMap,
    basePointsPerSecondMap,
    baseActionMultiplierMap,
    totalPrincipalAmountUsd,
    estimateSeconds = TIME.DAY
}: {
    loopscalePointsAction: LoopscalePointsAction;
    metadata?: TokenListMetadata[];
    multiplierInfoMap: Record<string, MultiplierInfo> | undefined;
    basePointsPerSecondMap: Record<LoopscalePointsAction, number> | undefined;
    baseActionMultiplierMap: Record<LoopscalePointsAction, number> | undefined;
    totalPrincipalAmountUsd: number | undefined;
    estimateSeconds?: number;
}) {
    if (
        !Array.isArray(metadata) ||
        !metadata.length ||
        !multiplierInfoMap ||
        !basePointsPerSecondMap ||
        !baseActionMultiplierMap ||
        !totalPrincipalAmountUsd
    ) {
        return {
            totalPoints: 0,
            totalMultiplier: 1,
            baseRate: 0,
            pointsPerDay: 0
        };
    }

    // Calculate multipliers for each asset included
    const additionalMultiplier = metadata
        .map((m) => {
            const additionalMultiplier =
                multiplierInfoMap[m?.mint ?? ""]?.pointSourceToAdditionalMultipliers[loopscalePointsAction] ?? 1;
            return additionalMultiplier;
        })
        .reduce((acc, curr) => acc * (curr as number), 1);

    const baseMultiplier = baseActionMultiplierMap[loopscalePointsAction];

    const totalMultiplier = additionalMultiplier * baseMultiplier;
    // Get base rate using IdleCap as reference
    const baseRate = basePointsPerSecondMap[loopscalePointsAction] ?? 0;

    // Calculate points per day - only multiply by relative multiplier if multiplier is 1

    const pointsPerSecond = additionalMultiplier * baseRate * totalPrincipalAmountUsd;
    const pointsPerDay = pointsPerSecond * TIME.DAY;
    const totalPoints = estimateSeconds ? pointsPerSecond * estimateSeconds : 0;

    return {
        totalPoints,
        totalMultiplier,
        baseRate,
        pointsPerDay
    };
}
