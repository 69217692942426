import {
    getLoopUnwindTransaction,
    getLoopWindTransaction,
    LoopUnwindTransactionInput,
    LoopWindTransactionInput
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises, TransactionAction, TransactionActionType } from "@bridgesplit/react";
import { Result, TransactionStatus, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfTransactionDetails, AbfGeneratorResult, LoopExpanded, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithData } from "./common";

export type LoopWindParams = LoopWindTransactionInput & {
    loopExpanded: LoopExpanded;
    userPublicKey: string;
};

export function useLoopWindTransaction(): AbfTransactionDetails<LoopWindParams> {
    const generate = useAbfGenerateTransactionWithData();
    const { resetNapoleonApi, resetMarketsPublicApi, resetLoopApi, resetUserAssetsApi, resetLoanApi } = useAbfFetches();

    async function getTransactionsWithParams({
        loopExpanded,
        userPublicKey,
        ...params
    }: LoopWindParams): AbfGeneratorResult {
        try {
            const collateralDecimals = loopExpanded.collateralToken.decimals;
            const principalDecimals = loopExpanded.principalToken.decimals;
            const create = generate({
                generateFunction: getLoopWindTransaction,
                identifier: "Create position",
                getActions: (response): TransactionAction[] => [
                    {
                        action: {
                            [TransactionActionType.WriteLoopWind]: {
                                mint: loopExpanded.collateralToken.mint,
                                loanAddress: response.loanAddress,
                                owner: userPublicKey,
                                leverageMultiplier: params.leverageMultiplier
                            }
                        },
                        transactionStatus: TransactionStatus.Confirmed
                    }
                ],
                params: {
                    ...params,
                    principalRequested: uiAmountToLamports(params.principalRequested, principalDecimals),
                    collateralAmount: uiAmountToLamports(params.collateralAmount, collateralDecimals)
                }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetLoopApi();
            resetUserAssetsApi();
            resetLoanApi();
        },
        minGeyserConfirmations: 5
    };

    return { getTransactionsWithParams, sendOptions, description: "Initializing position" };
}

export type LoopUnwindParams = LoopUnwindTransactionInput & {
    mintToReceive: string;
    userPublicKey: string;
    loanAddress: string;
};

export function useLoopUnwindTransaction(): AbfTransactionDetails<LoopUnwindParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetLoopApi, resetUserAssetsApi, resetLoanApi, resetMarketsPublicApi } = useAbfFetches();

    async function getTransactionsWithParams({
        mintToReceive,
        loanAddress,
        userPublicKey,
        ...params
    }: LoopUnwindParams): AbfGeneratorResult {
        try {
            const close = generate({
                generateFunction: getLoopUnwindTransaction,
                identifier: "Close",
                params: {
                    ...params,
                    loanAddress
                },
                getActions: (): TransactionAction[] => [
                    {
                        action: {
                            [TransactionActionType.WriteLoopUnwind]: {
                                mint: mintToReceive,
                                loanAddress: loanAddress,
                                owner: userPublicKey
                            }
                        },
                        transactionStatus: TransactionStatus.Confirmed
                    }
                ]
            });

            const transactions = await combineTransactionPromises([close], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetMarketsPublicApi();
            resetLoopApi();
            resetUserAssetsApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing position" };
}
