import {
    useOraclePrices,
    useAbfTypesToUiConverter,
    AbfLoanExpanded,
    summarizeLedgerAccount,
    calculateHealthRatio,
    calculateLoanHealth,
    getZcLoanCollateral,
    getZcLedger,
    getZcLoanLiquidationThreshold
} from "@bridgesplit/abf-react";
import { bsMath } from "@bridgesplit/utils";

import { CollateralChange, CollateralLoanHealthChange } from "./types";

export function useCalculateNewLoanHealth(mints: string[] | undefined) {
    const { getOracle } = useOraclePrices(mints);
    const { getDecimals } = useAbfTypesToUiConverter(mints);

    return function (loanExpanded: AbfLoanExpanded | undefined, collateralChanges: CollateralChange[] | undefined) {
        const ledgerAccount = getZcLedger(loanExpanded);
        if (!loanExpanded || !ledgerAccount) return undefined;

        // Return early if no changes to avoid unnecessary calculations
        if (!collateralChanges?.length) {
            const { health: originalHealth } = calculateLoanHealth(loanExpanded);
            return {
                newHealth: originalHealth,
                originalHealth,
                originalCollateralValueUsd: 0,
                newLoanCollateralValueUsd: 0
            };
        }

        const { totalOutstanding } = summarizeLedgerAccount(ledgerAccount);
        const collateralInfo = getZcLoanCollateral(loanExpanded);

        const originalCollateralValueUsd = loanExpanded.collateral
            .map((collateral) =>
                bsMath.tokenAMul(
                    collateralInfo?.loanCollateral.amount,
                    collateralInfo?.loanCollateral.usdPrice ?? 0,
                    collateralInfo?.loanCollateral?.metadata?.decimals
                )
            )
            .reduce((acc, value) => bsMath.add(acc, value) ?? 0, 0);

        let newLoanCollateralValueUsd = originalCollateralValueUsd;

        for (const { collateralMint, change } of collateralChanges) {
            const usdValueCollateralChange =
                getOracle(collateralMint)?.getUsdAmount(change, getDecimals(collateralMint)) ?? 0;
            if (change > 0) {
                newLoanCollateralValueUsd += usdValueCollateralChange;
            } else {
                newLoanCollateralValueUsd -= usdValueCollateralChange;
            }
        }

        const totalOwedValue = getOracle(loanExpanded.principalMetadata.mint)?.getUsdAmount(
            totalOutstanding,
            loanExpanded.principalMetadata?.decimals
        );

        const { health: originalHealth } = calculateLoanHealth(loanExpanded);

        const liquidationThreshold = getZcLoanLiquidationThreshold(loanExpanded);

        const newHealth = calculateHealthRatio(totalOwedValue, newLoanCollateralValueUsd, liquidationThreshold);

        const result: CollateralLoanHealthChange = {
            newHealth,
            originalHealth,
            originalCollateralValueUsd,
            newLoanCollateralValueUsd
        };

        return result;
    };
}
