import { useState, useEffect, useMemo, useCallback } from "react";

import {
    AssetTypeIdentifier,
    BsMetaUtil,
    Collateral,
    DEFAULT_STRATEGY_DURATION,
    PriceFetchType,
    RoleView,
    calculateHealthRatio,
    calculateLiquidationPrice,
    getOffsetDetailsFromStrategyDuration,
    getPaymentScheduleFromDates,
    isStrategyDurationSame,
    useOraclePrices,
    useSummarizedUserAssets
} from "@bridgesplit/abf-react";
import {
    NullableRecord,
    bsMath,
    calculatePercentChange,
    decimalsToBps,
    filterNullableRecord,
    filterTruthy,
    percentDecimalsToUi,
    removeDuplicatesByProperty,
    roundDownToDecimals
} from "@bridgesplit/utils";
import { useSearchParams } from "react-router-dom";
import { formatDurationWithTypeShorthand, StrategyDuration, UncertaintyType } from "@bridgesplit/abf-sdk";
import { AppParams } from "app/utils";

import { useMarketContext, useQuotesContext } from "../common";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { BorrowMode } from "./type";
import { useBalanceChecker } from "../../common";
import { getDefaultCollateral, useMarketCapDetails, useMarketCollateralFromProps, useOpenGuideOnLoad } from "../util";
import { MarketProps } from "../types";
import { useTrack } from "../../../hooks";
import { TrackEventWithProps } from "../../../types";

export function useMarketBorrowHooks(options?: { setDefaultDuration?: boolean }) {
    useMaintainBorrowLtv();
    useInitializeMarketBorrowCollateral();
    useInitializeMarketDuration(options);
    useOpenGuideOnLoad({ view: RoleView.Borrow });
}

export function useInitializeMarketDuration(options?: { setDefaultDuration?: boolean }) {
    const { setForm, strategyDurations } = useMarketBorrowContext();
    const [initialized, setInitialized] = useState(false);
    const [search] = useSearchParams();
    const termShortHand = search.get(AppParams.Term);

    const strategyDurationFromParams = useMemo(() => {
        return strategyDurations?.find((d) => {
            if (formatDurationWithTypeShorthand(d) === termShortHand) return true;

            if (options?.setDefaultDuration && isStrategyDurationSame(d, DEFAULT_STRATEGY_DURATION)) return true;

            return false;
        });
    }, [options?.setDefaultDuration, strategyDurations, termShortHand]);

    useEffect(() => {
        if (!strategyDurationFromParams || !!initialized) return;
        setInitialized(true);

        setForm((prev) => ({ ...prev, strategyDuration: strategyDurationFromParams }));
    }, [initialized, setForm, strategyDurationFromParams, termShortHand]);
}

export function useMarketBorrowCollateral() {
    const { form, userCollateral } = useMarketBorrowContext();
    const activeCollateral = userCollateral?.find((c) => c.metadata.mint === form.collateralMint);

    return { userCollateral, activeCollateral };
}

export function useMaxBorrowAmount() {
    const { maxQuote } = useMarketBorrowContext();

    const maxGlobalBorrow = useMaxGlobalAvailableBorrow();

    if (!maxQuote) {
        return { maxBorrow: 0, maxGlobalBorrow };
    }
    let maxBorrow = maxQuote.maxBorrow;

    if (maxGlobalBorrow !== undefined) {
        maxBorrow = Math.min(maxBorrow, maxGlobalBorrow);
    }

    return { maxBorrow, maxGlobalBorrow };
}

export function useMaxGlobalAvailableBorrow() {
    const { borrowCap } = useMarketContext();

    const borrowCapDetails = useMarketCapDetails();
    const remainingGlobalCapacity = borrowCapDetails?.remainingGlobalCapacity;

    let maxGlobalBorrow: number | undefined;
    if (borrowCap) {
        maxGlobalBorrow = borrowCap.perLoan;
    }
    if (remainingGlobalCapacity !== undefined) {
        if (maxGlobalBorrow === undefined) {
            maxGlobalBorrow = remainingGlobalCapacity;
        } else {
            maxGlobalBorrow = Math.min(maxGlobalBorrow, remainingGlobalCapacity);
        }
    }

    return maxGlobalBorrow;
}

export function useBorrowStats() {
    const { bestQuote, twapPrices: prices } = useMarketBorrowContext();

    const ltv = bsMath.div(prices.principalAmountUsd, prices.collateralAmountUsd);
    const liquidationThreshold = bestQuote?.liquidationThreshold;

    return {
        liquidationThreshold,
        ltv,
        insufficientCollateral: decimalsToBps(ltv) - decimalsToBps(bestQuote?.ltv) > 5 //  small bps difference might occur due to rounding
    };
}

export function useBorrowBalanceChecker() {
    const { form, activeUserCollateral, setForm } = useMarketBorrowContext();

    return useBalanceChecker({
        mint: form.collateralMint ?? undefined,
        setMax: (maxAmount) =>
            setForm((prev) => ({
                ...prev,
                collateralAmount: maxAmount,
                refetchOutAmount: true,
                mode: BorrowMode.InputCollateral
            })),
        amount: form.collateralAmount,
        customBalance: activeUserCollateral?.amount
    });
}

export function useBorrowLtv() {
    const { form, bestQuote, maxQuote, twapPrices: prices } = useMarketBorrowContext();

    const { maxBorrow } = useMaxBorrowAmount();

    return useMemo(() => {
        // used to set LTV multiplier if user inputs number first without ltv selection
        const ltvMultiplierFallback = form.amount && maxBorrow ? form.amount / maxBorrow : 0;
        const ltvMultiplier = form.ltvMultiplier || ltvMultiplierFallback;

        const ltv =
            prices.principalAmountUsd && prices.collateralAmountUsd
                ? prices.principalAmountUsd / prices.collateralAmountUsd
                : 0;

        const ltvFromQuote = bestQuote?.ltv ?? maxQuote?.ltv;

        return { ltv, ltvMultiplier, ltvFromQuote };
    }, [
        bestQuote?.ltv,
        form.amount,
        form.ltvMultiplier,
        maxBorrow,
        maxQuote?.ltv,
        prices.collateralAmountUsd,
        prices.principalAmountUsd
    ]);
}

// Initialize collateral selection for market borrowing
// This can be set from URL params, default to first available collateral, or show all options
export function useInitializeMarketBorrowCollateral() {
    const { form, setForm, allCollateral } = useMarketBorrowContext();
    const { principalMint } = useMarketContext();
    const [search] = useSearchParams();

    // Get mint from URL params
    const mintFromUrlParams = search.get(AppParams.CollateralMint);

    // Only show all collateral options if no URL param is specified
    const shouldShowAllOptions = !mintFromUrlParams;

    // Get default collateral or use URL param
    const specifiedMint = mintFromUrlParams ?? getDefaultCollateral({ principalMint, returnAll: shouldShowAllOptions });

    const allowedMints = useMemo(
        () => new Set(allCollateral?.map((c) => c.mint).filter((mint) => mint !== principalMint)),
        [allCollateral, principalMint]
    );

    // Determine which mint to use:
    // 1. Use specified mint if it's valid
    // 2. Otherwise use the first available collateral
    const mintToSet = useMemo(() => {
        if (specifiedMint && allowedMints.has(specifiedMint)) {
            return specifiedMint;
        }
        return allowedMints.size > 0 ? allowedMints.values().next().value : undefined;
    }, [allowedMints, specifiedMint]);

    useEffect(() => {
        if (!mintToSet) return;
        const hasValidCollateralMint =
            form.collateralMint && form.collateralMint !== principalMint && allowedMints.has(form.collateralMint);
        if (hasValidCollateralMint) return;

        // If URL param is specified, use that mint
        // Otherwise, show all options (null) or default to first available
        setForm((prev) => ({
            ...prev,
            collateralMint: mintFromUrlParams ? mintToSet : shouldShowAllOptions ? null : mintToSet
        }));
    }, [allowedMints, form.collateralMint, mintToSet, principalMint, setForm, mintFromUrlParams, shouldShowAllOptions]);
}

export function useMaintainBorrowLtv() {
    const {
        form,
        setForm,
        twapPrices: prices,
        bestQuote,
        quoteFetching,
        activeUserCollateral
    } = useMarketBorrowContext();

    const { token } = useMarketContext();
    const collateralDecimals = useMemo(
        () => activeUserCollateral?.metadata?.decimals,
        [activeUserCollateral?.metadata?.decimals]
    );

    const { ltvFromQuote: ltv, ltvMultiplier } = useBorrowLtv();

    const isNft = BsMetaUtil.isNonFungible(activeUserCollateral?.metadata);

    useEffect(() => {
        if (!form.refetchOutAmount || !!quoteFetching || !ltv) return;
        // allow free edit of principal or collateral
        if (form.mode === BorrowMode.InputCollateral || form.mode === BorrowMode.InputPrincipal) {
            if (!prices.collateralAmountUsd || !prices.principalAmountUsd || !bestQuote) return;

            const maxLtv = bestQuote.ltv;
            const ltv = prices.principalAmountUsd / prices.collateralAmountUsd;
            const ltvMultiplier = ltv / maxLtv;

            return setForm((prev) => ({
                ...prev,
                ltvMultiplier,
                refetchOutAmount: false
            }));
        }

        if (form.mode === BorrowMode.InputLtv) {
            if (!prices.collateralAmountUsd || !prices.principalPriceUsd) return;

            const principalAmountUsd = prices.collateralAmountUsd * ltv * ltvMultiplier;
            const principalAmount = principalAmountUsd / prices.principalPriceUsd;

            return setForm((prev) => ({
                ...prev,
                amount: roundDownToDecimals(principalAmount, token?.decimals),
                refetchOutAmount: false
            }));
        }
    }, [
        activeUserCollateral?.amount,
        bestQuote,
        collateralDecimals,
        form.amount,
        form.collateralMint,
        form.mode,
        form.refetchOutAmount,
        isNft,
        ltv,
        ltvMultiplier,
        prices.collateralAmountUsd,
        prices.collateralPriceUsd,
        prices.principalAmountUsd,
        prices.principalPriceUsd,
        quoteFetching,
        setForm,
        token?.decimals
    ]);
}

export function useBorrowTimeOffsets() {
    const { form } = useMarketBorrowContext();

    return useMemo(
        () => (form.strategyDuration ? getOffsetDetailsFromStrategyDuration(form.strategyDuration) : undefined),
        [form.strategyDuration]
    );
}

export function useUserCollateralForMarket(
    marketProps: MarketProps,
    options?: { includeLpPositions?: boolean; supportedOnly?: boolean; priceFetchType?: PriceFetchType }
) {
    const { balanceMap, balances } = useSummarizedUserAssets({
        includeLpPositions: options?.includeLpPositions
    });

    const tokens = useMarketCollateralFromProps(marketProps);
    const { getOracle, getUsdPrice } = useOraclePrices(
        tokens?.map((t) => t.mint),
        options?.priceFetchType,
        5_000
    );

    const whirlpoolBalances: Collateral[] = useMemo(
        () =>
            balances
                ?.filter(({ metadata }) => metadata.assetType === AssetTypeIdentifier.OrcaPosition)
                .map((c) => {
                    const oracle = getOracle(c.metadata.mint, options?.priceFetchType);

                    return {
                        metadata: {
                            ...c.metadata,
                            whirlpoolExpanded: c.whirlpoolPosition ?? undefined
                        },
                        amount: 1,
                        oracle: oracle ?? null,
                        usdPrice: getUsdPrice(c.metadata.mint, UncertaintyType.Subtract) ?? 0,
                        usdValue: getUsdPrice(c.metadata.mint, UncertaintyType.Subtract) ?? 0
                    };
                })
                .filter(filterTruthy) ?? [],
        [balances, getOracle, options?.priceFetchType, getUsdPrice]
    );

    const data = useMemo(() => {
        if (!tokens || !balanceMap) return undefined;

        const tokenList = tokens
            .map((t): NullableRecord<Collateral> => {
                const oracle = getOracle(t.mint, options?.priceFetchType) ?? null;
                const userBalance = balanceMap.get(t.mint);
                return {
                    metadata: t,
                    oracle,
                    amount: userBalance?.available ?? 0,
                    usdValue: userBalance?.availableUsd ?? 0,
                    usdPrice: getUsdPrice(t.mint, UncertaintyType.Subtract) ?? 0
                };
            })
            .filter(filterNullableRecord);

        const allTokens = removeDuplicatesByProperty([...tokenList, ...whirlpoolBalances], "metadata");
        return allTokens.sort((a, b) => b.amount - a.amount);
    }, [balanceMap, getOracle, options?.priceFetchType, tokens, whirlpoolBalances, getUsdPrice]);

    const owned = useMemo(() => (balances ? data?.filter((d) => !!d.amount) : undefined), [balances, data]);
    const supported = data?.filter((c) => c.oracle);

    return {
        data: options?.supportedOnly ? supported : data,
        owned,
        isLoading: !balances || !tokens,
        tokens
    };
}

export function useMarketBorrowSetCollateral() {
    const { setForm } = useMarketBorrowContext();

    const { setCollateralMints: setQuoteCollateralMint } = useQuotesContext();

    const [, setSearch] = useSearchParams();
    const { trackWithProps } = useTrack();

    return useCallback(
        (collateral: Collateral) => {
            const {
                metadata: { mint: collateralMint }
            } = collateral;

            trackWithProps(TrackEventWithProps.SelectBorrowCollateral, {
                tokenSymbol: BsMetaUtil.getSymbol(collateral.metadata)
            });

            setQuoteCollateralMint([collateralMint]);
            setSearch((prev) => {
                prev.set(AppParams.CollateralMint, collateralMint);
                return prev;
            });

            setForm((prev) => ({
                ...prev,
                collateralMint,
                refetchOutAmount: true
            }));
        },
        [setForm, setQuoteCollateralMint, setSearch, trackWithProps]
    );
}

export function useScheduleDetails() {
    const { form, bestQuote } = useMarketBorrowContext();
    const offsets = useBorrowTimeOffsets();

    return useMemo(() => {
        if (!offsets || !form.amount || !bestQuote) return undefined;

        const { timeOffsets, lastOffset, loanEndDate } = offsets;

        const { totalInterest } = getPaymentScheduleFromDates({
            loanDurationSeconds: lastOffset,
            timeOffsets,
            principal: form.amount,
            apy: percentDecimalsToUi(bestQuote.apy)
        });

        return { totalInterest, loanEndDate };
    }, [bestQuote, form.amount, offsets]);
}

export function useSetBorrowerStrategyDuration() {
    const { setForm } = useMarketBorrowContext();
    const { setStrategyDuration: setQuoteStrategyDuration } = useQuotesContext();
    const [, setSearch] = useSearchParams();
    const { trackWithProps } = useTrack();

    const setStrategyDuration = useCallback(
        (strategyDuration: StrategyDuration) => {
            setForm((prev) => ({
                ...prev,
                strategyDuration,
                refetchOutAmount: true
            }));
            setQuoteStrategyDuration(strategyDuration);
            const shorthand = formatDurationWithTypeShorthand(strategyDuration);
            setSearch((prev) => {
                prev.set(AppParams.Term, shorthand);
                return prev;
            });
            trackWithProps(TrackEventWithProps.SelectBorrowDuration, {
                duration: shorthand
            });
        },
        [setForm, setQuoteStrategyDuration, setSearch, trackWithProps]
    );

    return { setStrategyDuration };
}

export function useHealthRatio() {
    const {
        bestQuote,
        spotPrices: prices,
        form: { amount }
    } = useMarketBorrowContext();
    const totalInterest = useScheduleDetails()?.totalInterest;

    const totalPrincipalAmount = bsMath.add(amount, totalInterest);
    const { ltv, liquidationThreshold } = useBorrowStats();

    const healthRatio = calculateHealthRatio(
        bsMath.mul(totalPrincipalAmount, prices.principalPriceUsd),
        prices.collateralAmountUsd,
        bestQuote?.liquidationThreshold
    );

    return { healthRatio, ltv, liquidationThreshold };
}

export function useLiquidationPrice() {
    const { token } = useMarketContext();

    const { form, bestQuote, spotPrices: prices } = useMarketBorrowContext();
    const totalInterest = useScheduleDetails()?.totalInterest;

    const liquidationPrice = calculateLiquidationPrice(
        bsMath.tokenAdd(form.amount, totalInterest, token?.decimals),
        form.collateralAmount,
        bestQuote?.liquidationThreshold
    );

    const currentPrice = bsMath.div(prices.collateralPriceUsd, prices.principalPriceUsd);
    const percentChange = calculatePercentChange(currentPrice, liquidationPrice);

    return { liquidationPrice, ltv: bestQuote?.ltv, currentPrice, percentChange };
}
