import { useCallback, useMemo, useState } from "react";

import { decimalsToBps, LOADING_ERROR, MISSING_PARAM_ERROR, Result, uiAmountToLamports } from "@bridgesplit/utils";
import {
    AbfLoanExpanded,
    TokenBalanceExpanded,
    LoanCollateralExpanded,
    getZcLedger,
    getZcLoanCollateral,
    useLoanWithdrawTransaction,
    useActiveWallet,
    WithdrawCollateralTransactionParams,
    summarizeLedgerAccount,
    getZcLoanLtv
} from "@bridgesplit/abf-react";
import { EmptyPlaceholder } from "@bridgesplit/ui";
import { LockClockOutlined } from "@mui/icons-material";
import { TrackTransactionEvent } from "app/types";
import { useTransactionSender } from "app/components/transactions";
import { convertLedgerValueTupleToBps } from "@bridgesplit/abf-sdk";

import { ActionProps } from "./types";
import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { CollateralLoanHealthChangeStats } from "./common";
import { useCalculateNewLoanHealth } from "./util";

export default function CollateralWithdraw({ loanExpanded }: ActionProps) {
    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());

    const collateralWithMaxWithdrawableAmounts = useCollateralWithMaxWithdrawableAmounts(loanExpanded);

    if (!collateralWithMaxWithdrawableAmounts?.some((c) => c.amount > 0))
        return (
            <EmptyPlaceholder
                icon={<LockClockOutlined />}
                header="Withdraw not available"
                description="You don't have any collateral that can be withdrawn"
            />
        );

    return (
        <>
            <SelectAssets
                allowTokenInput
                maxText="Max withdrawal:"
                assets={collateralWithMaxWithdrawableAmounts}
                selected={selected}
                setSelected={setSelected}
            />
            <WithdrawCollateralCta
                selected={selected}
                loanExpanded={loanExpanded}
                collateralWithMaxWithdrawableAmounts={collateralWithMaxWithdrawableAmounts}
            />
        </>
    );
}

function WithdrawCollateralCta({
    selected,
    loanExpanded,
    collateralWithMaxWithdrawableAmounts
}: {
    selected: Map<string, number | undefined>;
    loanExpanded: AbfLoanExpanded | undefined;
    collateralWithMaxWithdrawableAmounts: TokenBalanceExpanded[] | undefined;
}) {
    const send = useTransactionSender();
    const { activeWallet } = useActiveWallet();

    const selectedAssets = loanExpanded?.collateral
        ?.map((asset) => ({ asset, selectedAmount: selected.get(asset.loanCollateral.metadata.mint) ?? 0 }))
        .filter((a) => !!a.selectedAmount);

    const healthChange = useCalculateNewLoanHealth(Array.from(selected.keys()))(
        loanExpanded,
        selectedAssets?.map((c) => ({
            collateralMint: c.asset.loanCollateral.metadata.mint,
            change: -c.selectedAmount
        }))
    );

    const creditBookWithdraw = useLoanWithdrawTransaction();

    const submit = useCallback(async () => {
        const ledgerAccount = getZcLedger(loanExpanded);
        if (!loanExpanded || !activeWallet || !ledgerAccount) return Result.errFromMessage(LOADING_ERROR);

        if (!selectedAssets?.length) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const selectedAsset = selectedAssets[0];

        const params: WithdrawCollateralTransactionParams = {
            loan: loanExpanded.loan.address,
            collateralMint: selectedAsset.asset.loanCollateral.metadata.mint,
            amount: uiAmountToLamports(
                selectedAssets.reduce((acc, { selectedAmount }) => acc + selectedAmount, 0),
                selectedAsset.asset.loanCollateral.metadata.decimals
            ),
            expectedLoanValues: {
                expectedApy: decimalsToBps(ledgerAccount.apy),
                expectedLtv: convertLedgerValueTupleToBps(ledgerAccount.ltvRatios),
                expectedLiquidationThreshold: convertLedgerValueTupleToBps(ledgerAccount.lqtRatios)
            },
            collateralIndex: 0 // update when multiple collaterals are supported
        };

        return await send(creditBookWithdraw, params, {
            description: "Withdrawing collateral",
            mixpanelEvent: { key: TrackTransactionEvent.CollateralWithdraw, params }
        });
    }, [activeWallet, loanExpanded, creditBookWithdraw, selectedAssets, send]);

    const assetSelectionError = getSelectAssetsErrorMessage({ selected, assets: collateralWithMaxWithdrawableAmounts });

    return (
        <>
            <CollateralLoanHealthChangeStats healthChange={healthChange} />
            <AppButton
                isTransaction
                disabled={!!assetSelectionError}
                asyncCta={{ onClickWithResult: submit }}
                fullWidth
            >
                {assetSelectionError ?? " Withdraw "}
            </AppButton>
        </>
    );
}

export function useCollateralWithMaxWithdrawableAmounts(loanExpanded: AbfLoanExpanded | undefined) {
    return useMemo(() => {
        const totalOutstanding = loanExpanded ? summarizeLedgerAccount(getZcLedger(loanExpanded)).totalOutstanding : 0;

        return loanExpanded?.collateral.map((collateral): TokenBalanceExpanded => {
            if (collateral.loanCollateral.whirlpoolPosition) {
                return {
                    key: collateral.loanCollateral.metadata.mint,
                    metadata: collateral.loanCollateral.metadata,
                    amount: 0
                };
            }

            const loanLtv = getZcLoanLtv(loanExpanded) ?? 1;
            const totalOutstandingUsd = totalOutstanding * (loanExpanded.principalUsdPrice ?? 0);
            const requiredValueUsd = totalOutstandingUsd / loanLtv;
            const requiredValue = requiredValueUsd / (collateral.loanCollateral.usdPrice ?? 0);
            const withdrawableAmount = Math.max(collateral.loanCollateral.amount - requiredValue, 0);
            const finalAmount = Math.min(collateral.loanCollateral.amount, withdrawableAmount);

            return {
                key: collateral.loanCollateral.metadata.mint,
                metadata: collateral.loanCollateral.metadata,
                amount: finalAmount
            };
        });
    }, [loanExpanded]);
}
interface AllowedUserAssetProps {
    loanExpanded: AbfLoanExpanded | undefined;
    assets: LoanCollateralExpanded[] | undefined;
    allowedCollateral: Map<string, number>;
}

export const useAllowedUserAssets = ({ loanExpanded, assets, allowedCollateral }: AllowedUserAssetProps) => {
    const allowedUserAssets: TokenBalanceExpanded[] = useMemo(() => {
        if (!loanExpanded || !assets) return [];

        return assets
            .filter((asset) => {
                const maxWithdrawable = allowedCollateral.get(asset.assetIdentifier);
                return maxWithdrawable !== undefined && maxWithdrawable > 0;
            })
            .map((asset): TokenBalanceExpanded | undefined => {
                const loanCollateral = getZcLoanCollateral(loanExpanded);
                if (!loanCollateral) return undefined;

                return {
                    key: asset.assetIdentifier,
                    metadata: loanCollateral.loanCollateral.metadata,
                    amount: loanCollateral.loanCollateral.amount
                };
            })
            .filter((item): item is TokenBalanceExpanded => !!item);
    }, [loanExpanded, assets, allowedCollateral]);

    return allowedUserAssets;
};
