import { useState } from "react";

import { DialogWrapper, FormInput, LabelWrapper, Row, ToggleButtons, ToggleOption } from "@bridgesplit/ui";
import { useAppDialog } from "app/utils";
import {
    lamportsToUiAmount,
    RECOMMENDED_TXN_FEE,
    SOL_DECIMALS,
    SOL_SYMBOL,
    TransactionFeeLevel,
    TransactionGenerationType,
    TransactionSettingsHeader
} from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { useAlert } from "@bridgesplit/react";

import { AppButton, HeaderWithClose } from "../common";
import { useTransactionSettings } from "./util";

export default function TransactionSettingsDialog() {
    const { close } = useAppDialog();
    const { txnSettings, setTxnSettings, compareUnsavedChangesInLamports, convertTxSettingsToLamports } =
        useTransactionSettings();

    const { alert } = useAlert();
    // Initialize unsaved changes with local storage values, converting to ui amounts
    const [unsavedChanges, setUnsavedChanges] = useState<TransactionSettingsHeader>({
        ...txnSettings,
        maxFee: txnSettings.maxFee ? lamportsToUiAmount(txnSettings.maxFee, SOL_DECIMALS) : undefined,
        exactFee: txnSettings.exactFee ? lamportsToUiAmount(txnSettings.exactFee, SOL_DECIMALS) : undefined
    });

    const feeLevelOptions = getFeeLevelOptions();
    const broadcastModeOptions = getBroadcastModeOptions();

    const handleUpdate = (updates: Partial<TransactionSettingsHeader>) => {
        setUnsavedChanges((prev) => ({
            ...prev,
            ...updates
        }));
    };

    // Convert maxFee and exactFee to lamports before saving and setting transaction setting state
    const saveSettings = () => {
        const settingsInLamports = convertTxSettingsToLamports(unsavedChanges);
        setTxnSettings(settingsInLamports);
        alert("Settings saved", "success");
    };

    return (
        <DialogWrapper>
            <HeaderWithClose header={COPY.TRANSACTION_SETTINGS_TITLE} close={close} />
            <Row spacing={1}>
                <LabelWrapper label={COPY.TRANSACTION_MAX_FEE} tooltip={COPY.TRANSACTION_MAX_FEE_TOOLTIP}>
                    <FormInput
                        variant="number"
                        adornment={SOL_SYMBOL}
                        decimals={6}
                        validateNumber={(val) => (val ? val > 0 : true)}
                        fullWidth
                        setValue={(val) => handleUpdate({ maxFee: val, exactFee: undefined })}
                        placeholder={RECOMMENDED_TXN_FEE}
                        value={unsavedChanges.maxFee}
                    />
                </LabelWrapper>

                <LabelWrapper label={COPY.TRANSACTION_EXACT_FEE} tooltip={COPY.TRANSACTION_EXACT_FEE_TOOLTIP}>
                    <FormInput
                        variant="number"
                        adornment={SOL_SYMBOL}
                        decimals={6}
                        validateNumber={(val) => (val ? val > 0 : true)}
                        fullWidth
                        setValue={(val) => handleUpdate({ exactFee: val, maxFee: undefined, feeLevel: undefined })}
                        placeholder={RECOMMENDED_TXN_FEE}
                        value={unsavedChanges.exactFee}
                    />
                </LabelWrapper>
            </Row>
            <LabelWrapper label={COPY.TRANSACTION_FEE_LEVEL} tooltip={COPY.TRANSACTION_FEE_LEVEL_TOOLTIP}>
                <ToggleButtons
                    value={unsavedChanges?.feeLevel}
                    setValue={(val: TransactionFeeLevel) => handleUpdate({ feeLevel: val, exactFee: undefined })}
                    options={feeLevelOptions}
                />
            </LabelWrapper>
            <LabelWrapper label={COPY.TRANSACTION_BROADCAST_MODE} tooltip={COPY.TRANSACTION_BROADCAST_MODE_TOOLTIP}>
                <ToggleButtons
                    value={unsavedChanges?.broadcastMode}
                    setValue={(val: TransactionGenerationType) => handleUpdate({ broadcastMode: val })}
                    options={broadcastModeOptions}
                />
            </LabelWrapper>
            <AppButton
                disabled={!compareUnsavedChangesInLamports(txnSettings, convertTxSettingsToLamports(unsavedChanges))}
                variant="contained"
                onClick={() => {
                    saveSettings();
                    close();
                }}
                overrideAccess
                isTransaction={false}
            >
                Save settings
            </AppButton>
        </DialogWrapper>
    );
}

function getFeeLevelOptions(): ToggleOption<TransactionFeeLevel>[] {
    return Object.keys(TransactionFeeLevel).map((key) => ({
        label: key,
        value: key as TransactionFeeLevel
    }));
}

function getBroadcastModeOptions(): ToggleOption<TransactionGenerationType>[] {
    return Object.keys(TransactionGenerationType)
        .filter((key) => isNaN(Number(key)))
        .map((key) => ({
            label: key,
            value: TransactionGenerationType[key as keyof typeof TransactionGenerationType]
        }));
}
