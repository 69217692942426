import {
    UserVaultPositionExpanded,
    UserVaultPnl,
    StakeStatus,
    isRecentVaultDepositOrWithdrawal,
    useUserVaultPnlFromPosition
} from "@bridgesplit/abf-react";
import { MediaQuery, Row, Text, Image, TooltipText } from "@bridgesplit/ui";
import { useMediaQuery } from "@mui/material";
import { PositionCardStats, ProfitLossText, getTokenImageSize } from "app/components/common";
import { COPY } from "app/constants";

import { VaultAmountDisplay } from "./VaultAmountDisplay";
import { VaultPendingPnl } from "./VaultPendingPnl";
import { VaultPositionApy } from "./VaultPositionCard";

export function VaultPositionStats({
    vaultPosition,
    query,
    status
}: {
    vaultPosition: UserVaultPositionExpanded | undefined;
    query: MediaQuery;
    status?: StakeStatus;
}) {
    const isMobile = useMediaQuery(query.below);

    const userVaultPnl = useUserVaultPnlFromPosition(vaultPosition);

    const stats = getVaultPositionStats(vaultPosition, isMobile, userVaultPnl, status);

    return <PositionCardStats loading={!vaultPosition || !userVaultPnl} stats={stats} forceColumn={!isMobile} />;
}
function VaultPositionValue({ vaultPosition }: { vaultPosition: UserVaultPositionExpanded | undefined }) {
    return <VaultAmountDisplay vaultPosition={vaultPosition} variant="total" />;
}

function VaultPositionManager({ vaultPosition }: { vaultPosition: UserVaultPositionExpanded | undefined }) {
    const size = getTokenImageSize("sm");

    return (
        <Row spacing={1}>
            <Image
                skeletonVariant="circular"
                variant="circle"
                size={size + "px"}
                src={vaultPosition?.vaultExpanded.vaultMetadata?.managerImage}
            />
            <Text>{vaultPosition?.vaultExpanded.vaultMetadata?.managerName}</Text>
        </Row>
    );
}

function getVaultPositionStats(
    vaultPosition: UserVaultPositionExpanded | undefined,
    isMobile: boolean,
    userVaultPnl: UserVaultPnl | undefined,
    status?: StakeStatus
) {
    const showPnl = status === StakeStatus.Active && (userVaultPnl?.lifetimePnl || userVaultPnl?.currentPnl);

    const isPnlPending = isRecentVaultDepositOrWithdrawal(userVaultPnl?.mostRecentDepositOrWithdrawalTimestamp);
    const hidePendingPnl = !showPnl || !isPnlPending;

    const stats = [
        {
            value: <VaultPositionApy vaultPosition={vaultPosition} />,
            caption: "APY",
            hide: !isMobile
        },
        {
            value: <VaultPositionValue vaultPosition={vaultPosition} />,
            caption: "Position value",
            hide: isMobile
        },
        {
            value: (
                <ProfitLossText
                    fontWeight="normal"
                    variant="body1"
                    profitLossTokenAmount={userVaultPnl?.lifetimePnl ?? userVaultPnl?.currentPnl ?? undefined}
                    metadata={vaultPosition?.vaultExpanded.principalMetadata}
                />
            ),
            caption: (
                <TooltipText
                    helpText={userVaultPnl?.lifetimePnl ? COPY.LIFETIME_PNL_TOOLTIP : COPY.CURRENT_PNL_TOOLTIP}
                >
                    <Text color="caption">{COPY.INTEREST_EARNED_LABEL}</Text>
                </TooltipText>
            ),
            hide: !showPnl || isPnlPending
        },
        {
            value: <VaultPendingPnl />,
            caption: COPY.INTEREST_EARNED_LABEL,
            hide: hidePendingPnl
        },
        {
            value: <VaultPositionManager vaultPosition={vaultPosition} />,
            caption: "Vault manager"
        }
    ].filter((stat) => !stat.hide);

    return stats;
}
