import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { DispatchType } from "@bridgesplit/react";
import {
    BestQuote,
    Collateral,
    DEFAULT_STRATEGY_DURATION,
    MaxQuoteFromCollateralAmount,
    PriceFetchType,
    getCollateralMintToInfoFromForm,
    isStrategyDurationSame,
    isWhirlpoolMetadata,
    useBestQuotes,
    useMaxQuotesForCollateral,
    useOraclePrices,
    useStrategyDurations
} from "@bridgesplit/abf-react";
import { bsMath, filterTruthy, findMaxElement, uiAmountToLamports } from "@bridgesplit/utils";
import { TokenListMetadata, OracleQuote, StrategyDuration, UncertaintyType } from "@bridgesplit/abf-sdk";

import { initialBorrowForm, MarketBorrowForm } from "./type";
import { useMarketContext } from "../common";
import { useUserCollateralForMarket } from "./util";

type Prices = {
    principalAmountUsd: number | undefined;
    collateralAmountUsd: number | undefined;
    principalPriceUsd: number | undefined;
    collateralPriceUsd: number | undefined;
    principalOracle: OracleQuote | undefined;
    collateralOracle: OracleQuote | undefined;
};

type ContextState = {
    form: MarketBorrowForm;
    setForm: DispatchType<MarketBorrowForm>;
    userCollateral: Collateral[] | undefined;
    supportedCollateral: Collateral[] | undefined;
    activeUserCollateral: Collateral | undefined;
    maxQuote: MaxQuoteFromCollateralAmount | null | undefined;
    allCollateral: TokenListMetadata[] | undefined;
    strategyDurations: StrategyDuration[] | undefined;
    bestQuote: BestQuote | undefined;
    allDurationQuotes: BestQuote[] | undefined;
    quoteFetching: boolean;
    twapPrices: Prices;
    spotPrices: Prices;
};
const MarketBorrowContext = createContext<ContextState | null>(null);

export function useMarketBorrowContext() {
    const context = useContext(MarketBorrowContext);
    if (!context) {
        throw new Error("useMarketBorrowState must be used within a MarketBorrowProvider");
    }
    return context;
}

export function MarketBorrowProvider({ children }: { children: ReactNode }) {
    const marketProps = useMarketContext();
    const { principalMint, token } = marketProps;
    const [form, setForm] = useState<MarketBorrowForm>(initialBorrowForm);

    const { data: userCollateral, tokens } = useUserCollateralForMarket(marketProps, {
        includeLpPositions: true,
        priceFetchType: PriceFetchType.Twap
    });

    const { getUsdPrice: getTwapPrice, getOracle: principalOracle } = useOraclePrices(
        [form.collateralMint, principalMint],
        PriceFetchType.Twap
    );
    const { getUsdPrice: getSpotPrice, getOracle: principalSpotOracle } = useOraclePrices(
        [form.collateralMint, principalMint],
        PriceFetchType.Spot
    );

    const supportedCollateral = userCollateral;

    const activeUserCollateral = useMemo(() => {
        const active = userCollateral?.find((c) => c.metadata.mint === form.collateralMint);
        if (isWhirlpoolMetadata(active?.metadata)) {
            if (!form.orcaPosition) return undefined;
            const { position, whirlpoolMetadata, totalPrice, whirlpoolAddress, oracle } = form.orcaPosition;
            const positionAsCollateral: Collateral = {
                metadata: {
                    ...whirlpoolMetadata,
                    whirlpoolExpanded: form.orcaPosition,
                    mint: whirlpoolAddress
                },
                // quotes endpoints take in total liquidity as collateral amount
                amount: position.totalLiquidity,
                usdValue: totalPrice,
                // price is in USD per 1 unit of collateral
                usdPrice: totalPrice / position.totalLiquidity,
                oracle
            };
            return positionAsCollateral;
        }
        return active;
    }, [form.collateralMint, form.orcaPosition, userCollateral]);

    const inputCollateral = useMemo(() => {
        if (!activeUserCollateral) return [];
        if (isWhirlpoolMetadata(activeUserCollateral.metadata)) {
            return [activeUserCollateral];
        }
        // user collateral max quotes is already cached
        if (!form.collateralAmount) return userCollateral;

        return [{ ...activeUserCollateral, amount: form.collateralAmount ?? activeUserCollateral.amount }];
    }, [activeUserCollateral, form.collateralAmount, userCollateral]);

    // fallback on the default preset if no preset is selected (so that max quotes can be fetched)
    const strategyDuration = form.strategyDuration ?? DEFAULT_STRATEGY_DURATION;

    const maxQuotes = useMaxQuotesForCollateral({
        collateral: inputCollateral,
        strategyDuration,
        principalMint,
        priceFetchType: PriceFetchType.Twap
    });

    const maxQuote = useMemo(() => {
        if (maxQuotes.isLoading || !maxQuotes.data || maxQuotes.isFetching) return undefined;

        const maxQuote = maxQuotes.data?.find((c) => c.metadata.mint === form.collateralMint)?.maxQuote ?? null;
        return maxQuote;
    }, [form.collateralMint, maxQuotes]);

    const { strategyDurations } = useStrategyDurations();

    const collateralMintToInfo = getCollateralMintToInfoFromForm(
        form.collateralMint,
        activeUserCollateral,
        form.collateralAmount
    );

    const { data: quotes, isFetching: quoteFetching } = useBestQuotes({
        collateralMintToInfo,
        principalMint,
        minPrincipalAmountLamports: uiAmountToLamports(form.amount, token?.decimals),
        strategyDurations,
        skip: !form.amount
    });

    const availableQuotes = quotes?.filter((q) => {
        const hasAmount = !!form.amount;
        const hasMatchingCollateral = q.collateralMint === form.collateralMint;
        const hasSufficientPrincipal = q.principalAvailable >= (form.amount || 0);
        const duration: StrategyDuration = { duration: q.duration, durationType: q.durationType };
        const hasMatchingDuration = isStrategyDurationSame(duration, strategyDuration);
        const result = hasAmount && hasMatchingCollateral && hasSufficientPrincipal && hasMatchingDuration;

        return result;
    });

    // best quotes already guarantee top apy
    const bestQuote = findMaxElement(availableQuotes, (v) => v.ltv);

    const collateralOracle = activeUserCollateral?.oracle ?? undefined;
    const principalPriceUsd = getTwapPrice(principalMint, UncertaintyType.Add);
    const collateralPriceUsd = activeUserCollateral?.usdPrice;

    const collateralSpotOracle = activeUserCollateral?.oracle ?? undefined;
    const principalSpotPriceUsd = getSpotPrice(principalMint, UncertaintyType.Add);
    const collateralSpotPriceUsd = activeUserCollateral?.usdPrice;

    return (
        <MarketBorrowContext.Provider
            value={{
                form,
                setForm,
                userCollateral,
                supportedCollateral,
                activeUserCollateral,
                bestQuote,
                allDurationQuotes: quotes,
                strategyDurations,
                allCollateral: tokens?.filter(filterTruthy),
                quoteFetching,
                maxQuote,
                twapPrices: {
                    collateralAmountUsd: bsMath.mul(collateralPriceUsd, form.collateralAmount),
                    principalAmountUsd: bsMath.mul(principalPriceUsd, form.amount),
                    collateralPriceUsd,
                    principalPriceUsd,
                    principalOracle: principalOracle(principalMint),
                    collateralOracle
                },
                spotPrices: {
                    collateralAmountUsd: bsMath.mul(collateralSpotPriceUsd, form.collateralAmount),
                    principalAmountUsd: bsMath.mul(principalSpotPriceUsd, form.amount),
                    collateralPriceUsd: collateralSpotPriceUsd,
                    principalPriceUsd: principalSpotPriceUsd,
                    principalOracle: principalSpotOracle(principalMint),
                    collateralOracle: collateralSpotOracle
                }
            }}
        >
            {children}
        </MarketBorrowContext.Provider>
    );
}
