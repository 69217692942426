import { Row, Tooltip } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import { BaseApy } from "./BaseApy";
import { ExternalRewardsDisplay, RateDisplay, TooltipContent } from "./common";
import { RewardsDailyPoints } from "./daily-rewards/RewardsDailyPoints";
import { RewardsBorrowApyProps } from "./types";
export function RewardsBorrowApy(props: RewardsBorrowApyProps) {
    return (
        <Tooltip title={<RewardsBorrowTooltip {...props} />} reverseColors>
            <Row spacing={0.5}>
                <ExternalRewardsDisplay externalPointRewards={undefined} {...props} />
                <RateDisplay apy={props.apy} variant="body1" color="body" />
            </Row>
        </Tooltip>
    );
}

function RewardsBorrowTooltip(props: RewardsBorrowApyProps) {
    return (
        <TooltipContent>
            <BaseApy {...props} />
            <Divider />
            <RewardsDailyPoints {...props} />
        </TooltipContent>
    );
}
