import { useMemo, useState } from "react";

import { Column, EmptyPlaceholder, Row, Text, Select } from "@bridgesplit/ui";
import { serializeStrategyDuration } from "@bridgesplit/abf-react";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { AssuredWorkloadOutlined } from "@mui/icons-material";
import { removeDuplicatesWithFunction } from "@bridgesplit/utils";

import TermsTable from "./TermsTable";
import { TermsColumn, VaultSummarizedTerms } from "./type";
import { TableFilter } from "./common";

export const DEFAULT_COLUMNS = [
    TermsColumn.Collateral,
    TermsColumn.Apy,
    TermsColumn.LiquidationThreshold,
    TermsColumn.Ltv
];

const ALL_COLUMNS = [
    TermsColumn.Collateral,
    TermsColumn.Apy,
    TermsColumn.LiquidationThreshold,
    TermsColumn.Ltv,
    TermsColumn.Oracle
];

const REQUIRED_COLUMNS = [TermsColumn.Collateral];
const MAX_COLUMNS = 4;

export default function VaultTerms({ summarizedTerms }: { summarizedTerms: VaultSummarizedTerms[] | undefined }) {
    const [durationState, setDuration] = useState<string>();
    const [visibleColumns, setVisibleColumns] = useState<TermsColumn[]>(DEFAULT_COLUMNS);

    const allTerms = useMemo(() => {
        if (!summarizedTerms?.length) return undefined;
        return removeDuplicatesWithFunction(
            summarizedTerms.map((t) => t.strategyDuration).flat(),
            serializeStrategyDuration
        );
    }, [summarizedTerms]);

    const serializedDuration =
        durationState ?? (allTerms && allTerms[0] ? serializeStrategyDuration(allTerms[0]) : undefined);

    const filteredTerms = useMemo(() => {
        if (!summarizedTerms?.length || !serializedDuration) return undefined;
        return summarizedTerms.filter((d) => serializeStrategyDuration(d.strategyDuration) === serializedDuration);
    }, [summarizedTerms, serializedDuration]);

    if (!summarizedTerms?.length || !allTerms?.length || !serializedDuration) {
        return (
            <EmptyPlaceholder
                icon={<AssuredWorkloadOutlined />}
                header="Vault has no lending parameters"
                description="The vault manager has not yet configured their market parameters"
            />
        );
    }
    return (
        <Column spacing={2}>
            <Row spacing={1} spaceBetween>
                <Text variant="h4">Collateral </Text>
                <Row spacing={1}>
                    {allTerms && (
                        <Select
                            sx={{ width: "100px", height: "30px" }}
                            value={serializedDuration}
                            setValue={setDuration}
                            options={allTerms.map((d) => ({
                                label: formatDurationWithType(d),
                                value: serializeStrategyDuration(d)
                            }))}
                        />
                    )}
                    <TableFilter
                        visibleColumns={visibleColumns}
                        setVisibleColumns={setVisibleColumns}
                        allColumns={ALL_COLUMNS}
                        resetColumns={DEFAULT_COLUMNS}
                        requiredColumns={REQUIRED_COLUMNS}
                        maxColumns={MAX_COLUMNS}
                    />
                </Row>
            </Row>
            <TermsTable terms={filteredTerms} visibleColumns={new Set(visibleColumns)} />
        </Column>
    );
}
