import { TransactionWithIdentifier, TransactionWithIdentifiersAndSetup } from "@bridgesplit/react";
import {
    SendableTransaction,
    SendableTransactionsWithSetup,
    Result,
    TransactionsWithDataGenerateReturn
} from "@bridgesplit/utils";

import { GenerateTransactionParams } from "../types";
import { getTransactionHeadersFromCookies } from "../utils";

export function useAbfGenerateTransaction() {
    return async function abfGenerateTransaction<ParamsType extends object, QueryType extends object>(
        props: GenerateTransactionParams<ParamsType, SendableTransaction[], QueryType>
    ): Promise<Result<TransactionWithIdentifier[]>> {
        const { params, identifier, generateFunction, queryParams } = props;
        try {
            const auth = getTransactionHeadersFromCookies();
            if (!auth.isOk()) return Result.err(auth);
            const txns = await generateFunction(auth.unwrap(), params, queryParams);
            const txnWithIdentifiers = txns.map((transaction, i): TransactionWithIdentifier => {
                return {
                    transaction,
                    identifier: identifier ?? "" + i,
                    transactionActions: props.getActions?.(txns) ?? []
                };
            });
            return Result.ok(txnWithIdentifiers);
        } catch (error) {
            return Result.err(error);
        }
    };
}

export function useAbfGenerateTransactionWithData() {
    return async function abfGenerateTransaction<
        ParamsType extends object,
        DataType extends object,
        TransactionType extends TransactionsWithDataGenerateReturn<DataType>,
        QueryType extends object
    >(
        props: GenerateTransactionParams<ParamsType, TransactionType, QueryType>
    ): Promise<Result<TransactionWithIdentifier[]>> {
        const { params, identifier, generateFunction, queryParams } = props;
        try {
            const auth = getTransactionHeadersFromCookies();
            if (!auth.isOk()) return Result.err(auth);

            const txns = await generateFunction(auth.unwrap(), params, queryParams);
            const transactionActions = props.getActions?.(txns) ?? [];

            const txnWithIdentifiers = txns.transactions.map((transaction, i): TransactionWithIdentifier => {
                return {
                    transaction,
                    identifier: identifier ?? "" + i,
                    transactionActions
                };
            });
            return Result.ok(txnWithIdentifiers);
        } catch (error) {
            return Result.err(error);
        }
    };
}

export function useAbfGenerateTransactionWithSetup() {
    return async function abfGenerateTransaction<ParamsType extends object, QueryType extends object>(
        props: GenerateTransactionParams<ParamsType, SendableTransactionsWithSetup, QueryType>
    ): Promise<Result<TransactionWithIdentifiersAndSetup>> {
        const { params, identifier, queryParams, generateFunction } = props;
        try {
            const auth = getTransactionHeadersFromCookies();
            if (!auth.isOk()) return Result.err(auth);
            const result = await generateFunction(auth.unwrap(), params, queryParams);
            const { setupTransactions, transactions } = result;
            const setUpTransactionsWithIdentifiers = setupTransactions.map(
                (transaction, i): TransactionWithIdentifier => {
                    return {
                        transaction,
                        identifier: "Set up " + identifier ?? "" + i
                    };
                }
            );

            const transactionsWithIdentifiers = transactions.map((transaction, i): TransactionWithIdentifier => {
                return {
                    transaction,
                    identifier: identifier ?? "" + i,
                    transactionActions: props.getActions?.(result) ?? []
                };
            });
            return Result.ok({
                setupTransactions: setUpTransactionsWithIdentifiers,
                transactions: transactionsWithIdentifiers
            });
        } catch (error) {
            return Result.err(error);
        }
    };
}
