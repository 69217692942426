import { combineReducers, Middleware } from "@reduxjs/toolkit";

import { abfUserApi, useAbfUserApi } from "./userApi";
import authSlice from "./authSlice";
import { transactionsApi } from "./transactionsApi";
import { oraclePricesApi, useOraclePricesApi } from "./oraclePricesApi";
import { pointsApi, usePointsApi } from "./pointsApi";
import { twitterPointsApi, useTwitterPointsApi } from "./twitterPointsApi";
import { discordPointsApi, useDiscordPointsApi } from "./discordPointsApi";
import { tokenListApi, useTokenListApi } from "./tokenListApi";
import { onboardingApi } from "./onboardingApi";
import { externalYieldApi } from "./externalYieldApi";
import { loopApi, useLoopApi } from "./loopApi";
import { jupiterApi, useJupiterApi } from "./jupiterApi";
import { meteoraApi, useMeteoraApi } from "./meteoraApi";
import { orcaExternalApi, useOrcaExternalApi } from "./orcaExternalApi";
import { loopPublicApi, useLoopPublicApi } from "./loopPublicApi";
import { marketsPublicApi, useMarketsPublicApi } from "./marketsPublicApi";
import { userAssetsApi, useUserAssetsApi } from "./userAssetsApi";
import { lendingVaultApi, useLendingVaultApi } from "./lendingVaultsApi";
import { marketsApi, useMarketsApi } from "./marketsApi";
import { loopIxsApi, useLoopIxsApi } from "./loopIxsApi";
const AbfSdkReducers = {
    authSlice,
    [tokenListApi.reducerPath]: tokenListApi.reducer,
    [userAssetsApi.reducerPath]: userAssetsApi.reducer,
    [abfUserApi.reducerPath]: abfUserApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [marketsPublicApi.reducerPath]: marketsPublicApi.reducer,
    [marketsApi.reducerPath]: marketsApi.reducer,
    [oraclePricesApi.reducerPath]: oraclePricesApi.reducer,
    [pointsApi.reducerPath]: pointsApi.reducer,
    [twitterPointsApi.reducerPath]: twitterPointsApi.reducer,
    [discordPointsApi.reducerPath]: discordPointsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [externalYieldApi.reducerPath]: externalYieldApi.reducer,
    [loopApi.reducerPath]: loopApi.reducer,
    [loopPublicApi.reducerPath]: loopPublicApi.reducer,
    [jupiterApi.reducerPath]: jupiterApi.reducer,
    [meteoraApi.reducerPath]: meteoraApi.reducer,
    [orcaExternalApi.reducerPath]: orcaExternalApi.reducer,
    [lendingVaultApi.reducerPath]: lendingVaultApi.reducer,
    [loopIxsApi.reducerPath]: loopIxsApi.reducer
};

export type SdkRootState = ReturnType<ReturnType<typeof combineReducers<typeof AbfSdkReducers>>>;

const AbfSdkMiddleware: Middleware[] = [
    userAssetsApi.middleware,
    abfUserApi.middleware,
    tokenListApi.middleware,
    transactionsApi.middleware,
    marketsPublicApi.middleware,
    externalYieldApi.middleware,
    oraclePricesApi.middleware,
    marketsApi.middleware,
    pointsApi.middleware,
    twitterPointsApi.middleware,
    discordPointsApi.middleware,
    onboardingApi.middleware,
    loopApi.middleware,
    loopPublicApi.middleware,
    jupiterApi.middleware,
    meteoraApi.middleware,
    orcaExternalApi.middleware,
    lendingVaultApi.middleware,
    loopIxsApi.middleware
];

export function useAbfFetches() {
    const userAssets = useUserAssetsApi();
    const user = useAbfUserApi();
    const marketsPublic = useMarketsPublicApi();
    const oracle = useOraclePricesApi();
    const markets = useMarketsApi();
    const twitterPoints = useTwitterPointsApi();
    const discordPoints = useDiscordPointsApi();
    const points = usePointsApi();
    const loop = useLoopApi();
    const loopPublic = useLoopPublicApi();
    const jupiter = useJupiterApi();
    const meteora = useMeteoraApi();
    const orcaExternal = useOrcaExternalApi();
    const lendingVault = useLendingVaultApi();
    const tokenList = useTokenListApi();
    const loopIxs = useLoopIxsApi();
    const all = {
        ...userAssets,
        ...user,
        ...marketsPublic,
        ...oracle,
        ...twitterPoints,
        ...discordPoints,
        ...points,
        ...loop,
        ...loopPublic,
        ...jupiter,
        ...meteora,
        ...orcaExternal,
        ...lendingVault,
        ...markets,
        ...tokenList,
        ...loopIxs
    };

    function resetAll() {
        all.resetUserAssetsApi();
        all.resetLoanApi();
        all.resetMeApi();
        all.resetNapoleonApi();
        all.resetMarketsPublicApi();
        all.resetOraclePricesApi();
        all.resetTwitterInfo();
        all.resetDiscordInfo();
        all.resetPointsAndReferrals();
        all.resetLoopApi();
        all.resetLoopApiPublic();
        all.resetJupiterSwapApi();
        all.resetMeteoraApi();
        all.resetOrcaExternalApi();
        all.resetLendingVaultApi();
        all.resetMarketsApi();
        all.resetTokenList();
        all.resetWhirlpoolPositions();
        all.resetLoopIxsApi();
    }

    return { resetAll, ...all };
}

export * from "./userAssetsApi";
export * from "./userApi";
export * from "./authSlice";
export * from "./tokenListApi";
export * from "./transactionsApi";
export * from "./marketsPublicApi";
export * from "./oraclePricesApi";
export * from "./pointsApi";
export * from "./twitterPointsApi";
export * from "./discordPointsApi";
export * from "./onboardingApi";
export * from "./externalYieldApi";
export * from "./loopApi";
export * from "./loopPublicApi";
export * from "./jupiterApi";
export * from "./meteoraApi";
export * from "./orcaExternalApi";
export * from "./lendingVaultsApi";
export * from "./marketsApi";
export * from "./loopIxsApi";
export { AbfSdkMiddleware, AbfSdkReducers };
