import { BackCta, Column, Link, Row, Text } from "@bridgesplit/ui";
import { COPY, LEND_SLUG } from "app/constants";
import { doNothing } from "@bridgesplit/utils";
import { OpenHowItWorks, TokenImage } from "app/components/common";
import { MarketGuideMode } from "@bridgesplit/abf-react";

import { useVaultContext } from "../../VaultContext";
import SelectValueDenomination from "./SelectValueDenomination";

export default function VaultDetailHeader() {
    const { vaultExpanded } = useVaultContext();
    return (
        <Column spacing={2}>
            <Link to={LEND_SLUG}>
                <BackCta back={doNothing} backCta={`All ${COPY.VAULT_TERM_PLURAL.toLowerCase()}`} />
            </Link>

            <Row spaceBetween>
                <Row spacing={2}>
                    <TokenImage metadata={vaultExpanded?.principalMetadata} size="lg" />
                    <Text variant="h3">{vaultExpanded?.vaultMetadata?.name}</Text>
                    {vaultExpanded && (
                        <OpenHowItWorks
                            variant="outlined"
                            mode={MarketGuideMode.LendVault}
                            vaultExpanded={vaultExpanded}
                        />
                    )}
                </Row>
                <Row>
                    <SelectValueDenomination />
                </Row>
            </Row>
        </Column>
    );
}
