import { useState } from "react";

import { Button, DialogWrapper, FormInput, LabelWrapper, ToggleButtons } from "@bridgesplit/ui";
import {
    AirtableCustomerAccountType,
    AirtableCustomerInterestType,
    submitAirtableInterestForm
} from "@bridgesplit/abf-react";
import { useAsyncResultHandler } from "@bridgesplit/react";
import { useAppDialog } from "app/utils";
import { COPY } from "app/constants";

import { DialogHeader } from "../../common";

interface Form {
    comment: string;
}

export default function AirtableInterestDialog() {
    const [form, setForm] = useState<Partial<Form>>({});
    const [type, setType] = useState<AirtableCustomerInterestType>();

    const { close } = useAppDialog();

    const { resultHandler, isLoading } = useAsyncResultHandler();

    async function submit() {
        const name = ""; // needs to be updated later
        const submit = () =>
            submitAirtableInterestForm({
                "First Name": name?.[0],
                "Interest Type": type,
                Comment: form.comment,
                "Last Name": name?.length && name.length > 1 ? name[1] : undefined,
                Email: "",
                "Account Type": AirtableCustomerAccountType.Company
            });
        await resultHandler(submit, {
            alertOnError: true,
            onSuccessMessage: "Request submitted! Someone will be in touch soon"
        });
        close();
    }

    return (
        <DialogWrapper>
            <DialogHeader
                header="Request access"
                description={`Get access to Loopscale's network of ${COPY.CUSTODIAN_TERM_FULL_PLURAL.toLowerCase()}`}
            />

            <LabelWrapper label="Interest type">
                <ToggleButtons
                    value={type}
                    setValue={setType}
                    options={[
                        { value: AirtableCustomerInterestType.Borrower },
                        { value: AirtableCustomerInterestType.Lender }
                    ]}
                />
            </LabelWrapper>
            <FormInput
                variant="string"
                multiLineHeight={100}
                label="Additional information"
                placeholder="Up to 200 characters"
                value={form.comment}
                setValue={(val) => setForm({ ...form, comment: val?.slice(0, 200) })}
            />
            <Button onClick={submit} loading={isLoading} variant="contained">
                Apply for access
            </Button>
        </DialogWrapper>
    );
}
