import { RoleView } from "@bridgesplit/abf-react";
import { IconType } from "@bridgesplit/ui";

const GROUP_VIEW_META: Record<RoleView, { role: string; action: string; icon: IconType }> = {
    [RoleView.Borrow]: { role: "Borrower", action: "Borrow", icon: "borrow" },
    [RoleView.Lend]: { role: "Lender", action: "Lend", icon: "lend" }
};

export function getUserGroupViewMeta(groupView: RoleView | undefined) {
    return GROUP_VIEW_META[groupView ?? RoleView.Borrow];
}
