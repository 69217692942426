import { useMemo } from "react";

import { textContains } from "@bridgesplit/utils";
import { TableContainer } from "@mui/material";
import { genericsMemo } from "@bridgesplit/react";

import { SPACING } from "../../theme";
import { Column, Row } from "../containers";
import { SearchInput } from "../input";
import InternalTable from "./InternalTable";
import { BaseTableProps, TableRow } from "./types";
import { sortRows, splitPaginationProps, useTableController } from "./internal";
import { EmptyPlaceholder, Pagination } from "../misc";

/**
 * Pagination/sort/search is either self managed using useTableController
 * useTableController + API paginationProps to pass pagination details into an API
 */
export default genericsMemo(function <T>(props: BaseTableProps<T>) {
    const { apiPaginationProps, managedPaginationProps } = splitPaginationProps(props.paginationProps);

    // Manage table's pagination using useState hooks (only used if type==="managed" below)
    const managedController = useTableController(props.paginationProps);
    const paginationController =
        props.paginationProps?.type === "managed" ? managedController : props.paginationProps.controller;

    const { order, orderBy, page, setPage, search, setSearch } = paginationController;

    const rowsPerPage = props.paginationProps?.pageSize ?? 5;
    const rawDataLength = apiPaginationProps?.totalDataCount ?? (props.rows?.length || 0);
    const displayPage = props.paginationProps.firstPage === 1 ? Math.max(0, page - 1) : page;

    const rows: TableRow<T>[] | undefined = useMemo(() => {
        // Assume non managed data is already filtered/sorted/paginated
        if (!props.rows || props.paginationProps?.type !== "managed") {
            return props.rows;
        }
        const filteredRows = props.rows.filter((row) => {
            if (!search || !managedPaginationProps?.getSearchFromRow) return true;
            const values = managedPaginationProps.getSearchFromRow(row).join();
            return textContains(values, search);
        });

        const sortedRows = sortRows({
            rows: filteredRows,
            columns: props.columns,
            order,
            orderBy
        });

        // Standard pagination
        return sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [
        managedPaginationProps,
        order,
        orderBy,
        page,
        props.columns,
        props.paginationProps?.type,
        props.rows,
        rowsPerPage,
        search
    ]);

    const emptyPlaceholder =
        typeof props.emptyPlaceholder === "string" ? (
            <EmptyPlaceholder header={props.emptyPlaceholder} />
        ) : (
            <EmptyPlaceholder {...props.emptyPlaceholder} />
        );
    return (
        <Column sx={{ width: `calc(100% + ${SPACING * 2}px)`, mx: -1 }}>
            {managedController?.setSearch && (
                <Row spacing={1} sx={{ mb: 1, px: 1, width: `calc(100% - ${SPACING * 2}px)` }}>
                    <SearchInput
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch?.(e.target.value)}
                        placeholder={props.searchPlaceholder || "Search"}
                    />
                    {props.searchRowElement}
                </Row>
            )}

            {!props.loading && props.rows !== undefined && rows?.length === 0 ? (
                emptyPlaceholder
            ) : (
                <TableContainer sx={{ marginTop: "0px !important" }}>
                    <InternalTable
                        tableProps={{
                            ...props,
                            preventEmptyRows:
                                (props.rows?.length && props.rows?.length < rowsPerPage) || props.preventEmptyRows,
                            rows
                        }}
                        paginationController={paginationController}
                    />
                </TableContainer>
            )}

            {rawDataLength > rowsPerPage && (
                <Pagination
                    count={rawDataLength}
                    rowsPerPage={rowsPerPage}
                    page={displayPage}
                    setPage={(newPage) => {
                        if (props.paginationProps.firstPage === 1) {
                            setPage(Math.max(0, newPage + 1));
                        } else {
                            setPage(newPage);
                        }
                    }}
                />
            )}
        </Column>
    );
});
