import { useMemo } from "react";

import debounce from "lodash.debounce";
import { Column, ExternalLink, Row, Text, TextButton } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { BsMetaUtil, LoopExpanded } from "@bridgesplit/abf-react";
import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { ArrowOutwardOutlined } from "@mui/icons-material";

import { TokenInputWithPrice } from "../../common";
import { useLoopSetCollateralAmount, useLoopBalanceChecker } from "./util";
import { useWindContext } from "./WindContext";
import { useLoopContext } from "../LoopContext";

export default function WindAmount() {
    const {
        form: { collateralAmount }
    } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const handleChange = useLoopSetCollateralAmount();

    const { BalanceDisplay } = useLoopBalanceChecker();

    const setValueDebounced = useMemo(() => debounce(handleChange, 500), [handleChange]);

    return (
        <Column spacing={0.5}>
            <Row spaceBetween>
                <Text color="caption" variant="body2">
                    You deposit
                </Text>
                <GetLoopCollateralButton loopExpanded={loopExpanded} />
            </Row>
            <TokenInputWithPrice
                metadata={loopExpanded?.collateralToken}
                selectToken={null}
                value={collateralAmount}
                setValue={setValueDebounced}
            />

            <BalanceDisplay />
        </Column>
    );
}

function GetLoopCollateralButton({ loopExpanded }: { loopExpanded: LoopExpanded | undefined }) {
    const { open } = useAppDialog();

    if (!loopExpanded) return null;

    switch (loopExpanded.loopVault.routeType) {
        case LoopRouteType.Meteora:
            return (
                <TextButton
                    color="primary"
                    variant="body2"
                    onClick={() => open(AppDialog.LoopExternalTransfers, { loopExpanded })}
                >
                    Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                </TextButton>
            );
        case LoopRouteType.Jup:
            return (
                <ExternalLink
                    target="_blank"
                    rel="noopener"
                    href={`https://jup.ag/swap/${BsMetaUtil.getSymbol(
                        loopExpanded?.principalToken
                    )}-${BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}`}
                >
                    <TextButton color="primary" variant="body2">
                        Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                        <ArrowOutwardOutlined />
                    </TextButton>
                </ExternalLink>
            );
        case LoopRouteType.Stabble:
            return (
                <ExternalLink
                    target="_blank"
                    rel="noopener"
                    href={`https://app.stabble.org/pools/deposit/?poolId=${loopExpanded?.collateralOracle.oracleAccount}`}
                >
                    <TextButton color="primary" variant="body2">
                        Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                        <ArrowOutwardOutlined />
                    </TextButton>
                </ExternalLink>
            );
        case LoopRouteType.Exponent:
            return (
                <ExternalLink
                    target="_blank"
                    rel="noopener"
                    href={`https://www.exponent.finance/income/${loopExpanded?.collateralToken.name.replace(
                        "Exponent ",
                        ""
                    )}?preserveScroll=true`}
                >
                    <TextButton color="primary" variant="body2">
                        Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                        <ArrowOutwardOutlined />
                    </TextButton>
                </ExternalLink>
            );
        case LoopRouteType.Ratex:
            return (
                <ExternalLink
                    target="_blank"
                    rel="noopener"
                    href={`https://app.rate-x.io/earn/fixed-yield`}
                >
                    <TextButton color="primary" variant="body2">
                        Get PT-{BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                        <ArrowOutwardOutlined />
                    </TextButton>
                </ExternalLink>
            );
        case LoopRouteType.Flash:
            return (
                <ExternalLink target="_blank" rel="noopener" href={`https://beast.flash.trade/earn`}>
                    <TextButton color="primary" variant="body2">
                        Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                        <ArrowOutwardOutlined />
                    </TextButton>
                </ExternalLink>
            );
        default:
            return null;
    }
}
