import { useCallback, useMemo } from "react";

import { Column, ErrorMessage, TextButton } from "@bridgesplit/ui";
import { decimalsToBps, DISABLED_APY, LOADING_ERROR, Result, uiPercentToBps } from "@bridgesplit/utils";
import {
    serializeStrategyDuration,
    useStrategyDurations,
    useUpdateStrategyTermsTransaction
} from "@bridgesplit/abf-react";
import { CollateralParamsUpdateArgs, EditCustomStrategyTxnParams } from "@bridgesplit/abf-sdk";

import { StrategyTermsProps } from "./type";
import { AppButton } from "../../common";
import { useTransactionSender } from "../../transactions";

export default function StrategyTermsCta({
    strategy,
    serializedStrategyDurationToApy,
    setSerializedStrategyDurationToApy
}: StrategyTermsProps) {
    const editStrategy = useUpdateStrategyTermsTransaction();
    const send = useTransactionSender();
    const { strategyDurations } = useStrategyDurations();

    const reset = useCallback(() => {
        setSerializedStrategyDurationToApy(undefined);
    }, [setSerializedStrategyDurationToApy]);

    const updateMints = useMemo(() => {
        if (!strategy || !serializedStrategyDurationToApy || !strategyDurations) return undefined;
        const updates: Record<string, CollateralParamsUpdateArgs> = {};

        for (const collateral of strategy.collateral) {
            const collateralMint = collateral.metadata.mint;
            const pastDurationAndApys = collateral.durationAndApys;
            const apyUpdates: Record<string, string> = {};

            for (const strategyDuration of strategyDurations) {
                const serialized = serializeStrategyDuration(strategyDuration);
                const previousApyBps = decimalsToBps(pastDurationAndApys[serialized]);

                const formApy = serializedStrategyDurationToApy?.get(serialized);
                const formApyBps = uiPercentToBps(formApy);

                if (!formApy && formApy !== 0) {
                    apyUpdates[serialized] = DISABLED_APY;
                } else if (formApyBps !== previousApyBps) {
                    apyUpdates[serialized] = formApyBps.toString();
                }
            }

            updates[collateralMint] = {
                apyUpdate: apyUpdates
            };
        }

        return updates;
    }, [strategy, serializedStrategyDurationToApy, strategyDurations]);
    const submit = useCallback(async () => {
        if (!updateMints || !strategy) return Result.errFromMessage(LOADING_ERROR);

        const params: EditCustomStrategyTxnParams = {
            strategy: strategy.strategy.address,
            collateralTerms: {
                addCollateral: {},
                removeCollateral: {},
                updateCollateral: updateMints
            }
        };

        return await send(editStrategy, params);
    }, [updateMints, editStrategy, send, strategy]);

    const termsExist = !!serializedStrategyDurationToApy?.size || serializedStrategyDurationToApy === undefined;
    const changesMade = !!updateMints && !!Object.keys(updateMints).length;

    return (
        <Column spacing={1}>
            {!termsExist && <ErrorMessage errorMessage="You must set at least one term" />}
            <AppButton
                isTransaction={false}
                asyncCta={{
                    onClickWithResult: submit,
                    options: {
                        onSuccess: reset
                    }
                }}
                disabled={!changesMade}
                variant="contained"
            >
                Save changes
            </AppButton>
            {!!changesMade && (
                <TextButton onClick={reset} color="caption" variant="body2">
                    Reset
                </TextButton>
            )}
        </Column>
    );
}
