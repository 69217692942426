import { StatColumn, StatProps, TextSkeleton, TextVariant } from "@bridgesplit/ui";
import { BsMetaUtil, isValidLeverage } from "@bridgesplit/abf-react";
import { formatPercent } from "@bridgesplit/utils";
import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { useTransactionFeeStat } from "app/components/transactions";
import { TransactionTypes } from "app/types";
import { COPY } from "app/constants";

import { HealthText, LiquidationPrice } from "../../common";
import { useLoopHealth, useLoopLiquidationPrice } from "./util";
import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";

export default function WindStats() {
    const { quote, form, isQuoteFetching, externalQuote } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const variant = "body2" as const;

    const health = useLoopHealth();

    const transactionFeeStat = useTransactionFeeStat({
        variant: variant,
        transactionType: TransactionTypes.EarnBorrow
    });

    const liquidationDetails = useLoopLiquidationPrice();

    if (!form.collateralAmount || !isValidLeverage(form.multiplier) || !externalQuote || !quote) return null;

    const stats: StatProps[] = [
        {
            caption: "Position size",
            value: BsMetaUtil.formatAmount(loopExpanded?.collateralToken, quote.leveragedCollateralAmount)
        },
        {
            caption: "Health",
            value: <HealthText variant={variant} health={health?.healthRatio} />
        },
        {
            caption: "Loan to value",
            value: formatPercent(health?.ltv)
        },
        {
            caption: "Borrowed",
            value: BsMetaUtil.formatAmount(loopExpanded?.principalToken, quote?.principalAmount)
        },
        {
            caption: "Borrow APY",
            value: formatPercent(quote.bestQuote.apy)
        },
        {
            value: <BorrowLiquidationPrice liquidationDetails={liquidationDetails} variant={variant} />,
            caption: COPY.LIQUIDATION_PRICE_TERM,
            tooltip: COPY.LIQUIDATION_PRICE_TOOLTIP
        },
        transactionFeeStat
    ];

    if (loopExpanded?.loopVault.routeType === LoopRouteType.Jup && externalQuote.priceImpactPct) {
        const priceImpactPercent = externalQuote.priceImpactPct; //TODO: verify this is correct
        stats.push({
            caption: "Price impact",
            value: formatPercent(priceImpactPercent, { maxPrecisionUi: 0.01 })
        });
    }

    return <StatColumn variant={variant} loading={isQuoteFetching} stats={stats} />;
}

function BorrowLiquidationPrice({
    variant,
    liquidationDetails
}: {
    variant: TextVariant;
    liquidationDetails: ReturnType<typeof useLoopLiquidationPrice>;
}) {
    const { loopExpanded } = useLoopContext();

    const { liquidationPrice, percentChange } = liquidationDetails;

    if (!liquidationPrice) return <TextSkeleton variant={variant} />;

    return (
        <LiquidationPrice
            variant={variant}
            principal={loopExpanded?.principalToken}
            collateral={loopExpanded?.collateralToken}
            liquidationPrice={liquidationPrice}
            percentChange={percentChange}
        />
    );
}
