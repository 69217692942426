import { useUser } from "./auth";

export function useActiveWallet() {
    const { data, isLoading, isFetching } = useUser();
    const activeWallet = data?.wallet;

    return {
        user: activeWallet,
        activeWallet,
        isLoading,
        isFetching
    };
}
