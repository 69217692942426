import {
    MARKETS_API,
    PANDORA_API,
    RUBY_API,
    Result,
    deserializeLegacyTransaction,
    fetchAndDeserializeVersionedTransaction
} from "@bridgesplit/utils";
import axios from "axios";
import { Transaction } from "@solana/web3.js";

import { AbfTransactionHeaders } from "../types/arguments";
import { GENERATE_SIGN_IN_TXN_ENDPOINT, TXN_CREATE_USER_MFI_ACCOUNT_ROUTE } from "../types";

export function createMarketsInstance(auth: AbfTransactionHeaders) {
    return createInstanceWithAuth(MARKETS_API, auth);
}

export function createRubyInstance(auth: AbfTransactionHeaders) {
    return createInstanceWithAuth(RUBY_API, auth);
}

export function createPandoraInstance(auth: AbfTransactionHeaders) {
    return createInstanceWithAuth(PANDORA_API, auth);
}

export const USER_WALLET_HEADER = "User-Wallet";
export const TXN_SETTINGS_HEADER = "Transaction-Settings";
export const WALLET_TXN_HEADER = "Wallet-Txn";
export const API_VERSION_HEADER = "Api-Version";

function createInstanceWithAuth(
    baseURL: string,
    { bearerToken, groupIdentifier, userWallet, txnSettings }: AbfTransactionHeaders
) {
    return axios.create({
        baseURL,
        headers: {
            Authorization: bearerToken ? `Bearer ${bearerToken}` : undefined,
            Organization: groupIdentifier,
            [USER_WALLET_HEADER]: userWallet,
            [TXN_SETTINGS_HEADER]: JSON.stringify(txnSettings)
        }
    });
}

export async function getSignInTransaction(
    authentication: AbfTransactionHeaders,
    wallet: string
): Promise<Result<Transaction>> {
    try {
        const res = await createRubyInstance(authentication).post<string>(GENERATE_SIGN_IN_TXN_ENDPOINT, {
            headers: { [USER_WALLET_HEADER]: wallet }
        });
        const txn = deserializeLegacyTransaction(res.data);
        return Result.ok(txn);
    } catch (error) {
        return Result.err(error);
    }
}

export async function getMarginFiAccountSetupTransaction(authentication: AbfTransactionHeaders) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREATE_USER_MFI_ACCOUNT_ROUTE
        )
    ];
}
