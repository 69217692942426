import { LoanStatus, RoleView, useLoanInfos, AbfOrderFundingType, useActiveWallet } from "@bridgesplit/abf-react";
import { useLoansPagination } from "app/api";
import { SortDirection } from "@bridgesplit/utils";

import { LoanCards } from "../../loans";
import { useMarketContext } from "../common";

export default function BorrowExistingLoans() {
    const { activeWallet } = useActiveWallet();
    const { principalMint } = useMarketContext();
    const pagination = useLoansPagination({ initialPageSize: 5, initialState: { principalMint } });

    const { data: loans, totalLoans } = useLoanInfos({
        loanFilter: {
            orderFundingTypes: [AbfOrderFundingType.Standard],
            principalMints: principalMint ? [principalMint] : [],
            lenders: [],
            borrowers: activeWallet ? [activeWallet] : [],
            custodians: [],
            saleEvents: false,
            ignoreRefinanced: false,
            sortSide: SortDirection.Asc
        },
        pagination: pagination.params,
        skip: !activeWallet
    });

    return (
        <LoanCards
            view={RoleView.Borrow}
            defaultFilter={LoanStatus.OngoingLoan}
            loans={loans}
            pagination={{ controller: pagination, totalLoans }}
        />
    );
}
