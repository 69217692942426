import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { UserLoopInfo, UserLoopInfoParams } from "@bridgesplit/abf-sdk";

import { abfSerializeQueryArgs, marketsBaseQuery } from "./util";
import {
    GET_USER_LOOP_INFO_ROUTE,
    GET_LOOP_POSITION_STATS_ROUTE,
    ROUTE_GET_LATEST_COLLATERAL_YIELD_PCT,
    ROUTE_UPDATE_COLLATERAL_YIELD_UPDATE,
    ROUTE_GET_LOOP_VAULTS_HISTORY,
    GET_COLLATERAL_YIELDS_IN_RANGE_ROUTE
} from "../constants";
import {
    CollateralYieldHistory,
    HistoricalLoopVaultBorrowRate,
    LoopPositionStats,
    LoopVaultBorrowRateQuery
} from "../types";

const LOOP_POSITIONS_TAG = "LoopPositions";

// ... (getHeaders and getMarketsBaseQuery functions remain the same)

export const loopApi = createApi({
    reducerPath: "loopApi",
    tagTypes: [LOOP_POSITIONS_TAG],
    baseQuery: marketsBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        userLoops: builder.query<UserLoopInfo[], { userLoopInfo: UserLoopInfoParams }>({
            providesTags: [LOOP_POSITIONS_TAG],
            query: (params) => ({
                url: GET_USER_LOOP_INFO_ROUTE,
                method: "POST",
                body: params.userLoopInfo
            })
        }),
        loopPositionStats: builder.query<LoopPositionStats, void>({
            providesTags: [LOOP_POSITIONS_TAG],
            query: () => ({
                url: GET_LOOP_POSITION_STATS_ROUTE,
                method: "GET"
            })
        }),
        loopCollateralYieldLatest: builder.query<number, string>({
            providesTags: [LOOP_POSITIONS_TAG],
            query: (mint) => ({
                url: `${ROUTE_GET_LATEST_COLLATERAL_YIELD_PCT}/${mint}`,
                method: "GET"
            })
        }),
        loopCollateralYieldUpdate: builder.mutation<void, void>({
            query: () => ({
                url: ROUTE_UPDATE_COLLATERAL_YIELD_UPDATE,
                method: "POST"
            })
        }),
        loopHistory: builder.query<HistoricalLoopVaultBorrowRate[], LoopVaultBorrowRateQuery>({
            query: (params) => ({
                url: ROUTE_GET_LOOP_VAULTS_HISTORY,
                method: "POST",
                body: params
            })
        }),
        collateralYieldHistory: builder.query<
            CollateralYieldHistory[],
            { startTime: number; endTime: number; collateralMint: string }
        >({
            providesTags: [LOOP_POSITIONS_TAG],
            query({ collateralMint, ...params }) {
                return {
                    url: `${GET_COLLATERAL_YIELDS_IN_RANGE_ROUTE}/${collateralMint}`,
                    method: "GET",
                    params
                };
            }
        })
    })
});

export const {
    useUserLoopsQuery,
    useLoopPositionStatsQuery,
    useLoopCollateralYieldLatestQuery,
    useLoopCollateralYieldUpdateMutation,
    useLoopHistoryQuery,
    useCollateralYieldHistoryQuery
} = loopApi;

export const useLoopApi = () => {
    const dispatch = useDispatch();

    return {
        resetLoopApi: () => dispatch(loopApi.util.invalidateTags([LOOP_POSITIONS_TAG]))
    };
};
