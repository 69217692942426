export enum OnboardingStep {
    Borrow,
    Lend,
    Earn,
    LendVault
}

export interface UserOnboarding {
    id: number;
    userIdentifier: string;
    onboardingStep: OnboardingStep;
}

export enum MarketGuideMode {
    Borrow,
    Lend,
    BorrowAndLend,
    Earn,
    LendVault
}
