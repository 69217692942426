import { ParsedLtvData, VaultLtvInfos, VaultUpdateActionType } from "@bridgesplit/abf-react";
import {
    ExternalYieldSource,
    MarketInformationLtvInfo,
    OracleType,
    StrategyDuration,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";

export enum TermsColumn {
    Collateral = "Collateral",
    Apy = "APY",
    Ltv = "LTV",
    LiquidationThreshold = "Liquidation LTV",
    Oracle = "Oracle",
    Finalized = "Finalized",
    Type = "Type",
    LiquidationLtv = "Liquidation LTV"
}

export interface VaultLtvUpdate {
    collateralMint: string;
    executionTimestamp: number;
    type: VaultUpdateActionType;
    // null if deleted
    ltvInfo: ParsedLtvData | null;
}

export interface VaultSummarizedUpdate extends VaultLtvUpdate {
    originalTerms: VaultLtvInfos | null;
    collateralMetadata: TokenListMetadata;
    key: string;
}

export interface VaultSummarizedTerms {
    collateralMetadata: TokenListMetadata;
    apy: number; //If no APY is set, it is disabled
    strategyDuration: StrategyDuration;
    ltv: number;
    liquidationThreshold: number;
    oracleAccount: string;
    oracleType: OracleType;
}

export interface VaultSummarizedMissingTerms {
    marketInfo: MarketInformationLtvInfo;
}

export interface SettingsChange {
    externalYieldSource?: [ExternalYieldSource, ExternalYieldSource];
    interestFee?: [number, number];
    liquidityBuffer?: [number, number];
    originationFee?: [number, number];
    originationCap?: [number, number];
    originationsEnabled?: [string, string];
}
