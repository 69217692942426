import React from "react";

import { ALLOWED_FEATURES } from "@bridgesplit/utils";
import { Error404Page, lazy } from "@bridgesplit/ui";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import {
    LOAN_SLUG,
    PORTFOLIO_OVERVIEW_SLUG,
    STRATEGY_LOANS_SLUG,
    REWARDS_SLUG,
    TWITTER_SLUG,
    DISCORD_SLUG,
    MARKET_SLUG,
    LOOP_DETAIL_SLUG,
    BORROW_SLUG,
    EARN_SLUG,
    SIGNUP_SLUG,
    LEND_SLUG,
    LOOPS_SLUG,
    VAULT_MANAGER_SLUG,
    LEND_VAULT_DETAIL_SLUG
} from "app/constants";

import { NavigateListener, AppFallback } from "./wrappers";
import { PortfolioOverview } from "./portfolio";
import { StrategyLoansDetail } from "./strategy";
import { Rewards, DiscordCallback, TwitterCallback } from "./points";
import { ExploreLoops, ExploreBorrow, ExploreLend } from "./explore";
import { LoanDetail } from "./loan";
import MarketDetail from "./market-detail/MarketDetail";
import { LoopDetail } from "./loop";
import { ManagerOverview } from "./manager-view";
import { VaultDetail } from "./vault";
import { MaintenancePage } from "./wrappers/MaintenancePage";
import SignUp from "./auth/SignUp";

const AppDialogs = lazy(() => import("./dialogs"));
const GlobalDialogs = lazy(() => import("./global-dialogs"));

export default function Routes() {
    if (ALLOWED_FEATURES.currentlyDownForMaintenance) {
        return (
            <RouterRoutes>
                <Route path="*" element={<MaintenancePage />} />
            </RouterRoutes>
        );
    }

    return (
        <React.Suspense fallback={<AppFallback />}>
            <NavigateListener />

            <AppDialogs />
            <GlobalDialogs />

            <RouterRoutes>
                <Route path={"/"} element={<Navigate to={LEND_SLUG} />} />
                <Route path={LEND_SLUG} element={<ExploreLend />} />
                <Route path={EARN_SLUG} element={<Navigate to={LOOPS_SLUG} />} />
                <Route path={BORROW_SLUG} element={<ExploreBorrow />} />
                <Route path={LOOPS_SLUG} element={<ExploreLoops />} />
                <Route path={SIGNUP_SLUG} element={<SignUp />} />
                <Route path={`${MARKET_SLUG}/:symbol`} element={<MarketDetail />} />
                <Route path={`${LEND_VAULT_DETAIL_SLUG}/:address`} element={<VaultDetail />} />
                <Route path={`${LOOP_DETAIL_SLUG}/:address`} element={<LoopDetail />} />
                <Route path={PORTFOLIO_OVERVIEW_SLUG} element={<PortfolioOverview />} />
                <Route path={`${LOAN_SLUG}/:address`} element={<LoanDetail />} />
                <Route path={`${STRATEGY_LOANS_SLUG}/:address`} element={<StrategyLoansDetail />} />
                <Route path={`${VAULT_MANAGER_SLUG}/:address`} element={<ManagerOverview />} />

                {/* Rewards */}
                <Route path="/waitlist" element={<Navigate to={REWARDS_SLUG} />} />

                <Route path={REWARDS_SLUG} element={<Rewards />} />
                <Route path={TWITTER_SLUG} element={<TwitterCallback />} />
                <Route path={DISCORD_SLUG} element={<DiscordCallback />} />

                <Route path={"*"} element={<Error404Page />} />
            </RouterRoutes>
        </React.Suspense>
    );
}
