import { useMemo } from "react";

import { DialogHeader } from "../../common";
import WalletNotConnected from "./WalletNotConnected";
import { WalletConnectError } from "./type";

type TransactionsWalletConnectProps = {
    error: WalletConnectError | undefined;
    isLoading: boolean;
    header?: { description: string };
};
export default function TransactionsWalletConnect(props: TransactionsWalletConnectProps) {
    const content = useMemo(() => {
        switch (props.error) {
            case WalletConnectError.None:
                return null;
            case WalletConnectError.NotConnected:
                return <WalletNotConnected />;
            default:
                return null;
        }
    }, [props.error]);

    return (
        <>
            {props.header && <DialogHeader header="Connect wallet" description={props.header.description} />}
            {content}
        </>
    );
}
