import { Fragment, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { colorToAlpha, formatNum, formatTokenAmount, formatUsd } from "@bridgesplit/utils";
import {
    Divider,
    PopoverOrigin,
    Stack,
    Popover as MuiPopover,
    LinearProgress as MuiLinearProgress,
    LinearProgressProps,
    CircularProgress,
    keyframes,
    Stepper,
    Step,
    StepLabel,
    stepLabelClasses,
    StepIconProps,
    useMediaQuery,
    Box,
    popoverClasses,
    Collapse
} from "@mui/material";
import {
    ArrowForwardIos,
    ArrowForwardOutlined,
    Check,
    ChevronRight,
    RefreshOutlined,
    ExpandMore
} from "@mui/icons-material";

import { MediaQuery, SxType } from "../types";
import { Column, MediaStack, OutlinedCard, Row } from "./containers";
import { Icon } from "./icons";
import { TagColor, Text, TextColor, TextSkeleton, TextVariant, TooltipText } from "./typography";
import { ConditionalLink, TextButton } from "./buttons";
import { BORDER_RADIUS, FONT_SIZES, FONT_WEIGHTS, SPACING, useAppPalette, useColorFromTag } from "../theme";
import { Tooltip } from "./misc";
import { MEDIA } from "../util";

export type StatRawValue = ReactElement | string | number | undefined;

export type StatDirection = "row" | "row-reverse" | "column-reverse" | "column";
export type LoadingStyle = "both" | "value-only";
export interface StatProps {
    value: StatRawValue | [StatRawValue, StatRawValue];
    caption: string | JSX.Element;
    tooltip?: string;
    variant?: TextVariant;
    captionVariant?: TextVariant;
    captionColor?: TextColor;
    direction?: StatDirection;
    isUsd?: boolean;
    symbol?: string;
    valuePostfix?: string;
    href?: string;
    loading?: boolean;
    loadingStyle?: LoadingStyle;
    hide?: boolean;
    sx?: SxType;
    loadingWidth?: string;
    textColor?: TextColor;
}

export function Stat({
    value,
    caption,
    tooltip,
    href,
    variant = "h4",
    loading,
    valuePostfix,
    isUsd,
    sx,
    hide,
    symbol,
    loadingWidth = "90px",
    loadingStyle = "both",
    direction = "column",
    textColor,
    captionVariant,
    captionColor
}: StatProps) {
    const isRow = direction.includes("row");
    const isHeading = variant.includes("h");

    const formatStat = useCallback(
        (value: StatRawValue, options?: { hideSymbol?: boolean }) => {
            const postFix = valuePostfix ? ` ${valuePostfix}` : "";

            if (typeof value === "number" || value === undefined) {
                if (isUsd) {
                    return formatUsd(value) + postFix;
                }
                if (symbol) {
                    return formatTokenAmount(value, { symbol, hideSymbol: options?.hideSymbol }) + postFix;
                }
                return formatNum(value) + postFix;
            }
            return value;
        },
        [isUsd, symbol, valuePostfix]
    );

    const valueContent = useMemo(() => {
        if (loading) {
            return <TextSkeleton width={loadingWidth} variant={variant} />;
        }

        if (Array.isArray(value)) {
            return (
                <Row sx={{ minWidth: "max-content" }} spacing={0.5}>
                    <Text variant={variant}>{formatStat(value[0], { hideSymbol: true })}</Text>
                    <Text color="disabled" variant="body2">
                        <ArrowForwardOutlined />
                    </Text>
                    <Text variant={variant}>{formatStat(value[1])}</Text>
                </Row>
            );
        }
        if (["undefined", "string", "number"].includes(typeof value)) {
            const formattedValue = formatStat(value);
            return (
                <Text
                    loadingWidth={loadingWidth}
                    loading={loading}
                    color={textColor}
                    onClick={href ? () => window.open(href) : undefined}
                    sx={{
                        cursor: href ? "pointer" : undefined,
                        ":hover": { textDecoration: href ? "underline" : undefined }
                    }}
                    variant={variant}
                >
                    {formattedValue}
                    {href && <Icon type="url" />}
                </Text>
            );
        }

        return value;
    }, [formatStat, href, loading, loadingWidth, textColor, value, variant]);

    const captionContent = useMemo(() => {
        if (loading && loadingStyle !== "value-only") return <TextSkeleton width="50px" variant={variant} />;
        if (typeof caption !== "string") return caption;
        return (
            <TooltipText
                loadingWidth="50px"
                helpText={tooltip}
                color={captionColor ?? "caption"}
                variant={(() => {
                    if (captionVariant) return captionVariant;
                    if (isRow) return "body1";
                    if (isHeading) return "body2";
                    return variant;
                })()}
            >
                {caption}
            </TooltipText>
        );
    }, [caption, captionVariant, isHeading, isRow, loading, loadingStyle, tooltip, variant, captionColor]);

    if (hide) return null;

    return (
        <Stack
            sx={{
                justifyContent: direction === "row-reverse" ? "flex-end" : undefined,
                flexDirection: direction,
                gap: isRow ? `${SPACING / 2}px` : undefined,
                ...sx
            }}
        >
            {captionContent}
            {valueContent}
        </Stack>
    );
}

export type StatContainer = {
    spacing?: number;
    sx?: SxType;
    stats: Omit<StatProps, "direction" | "variant" | "loading" | "loadingStyle">[] | undefined;
    variant?: TextVariant;
    loading?: boolean;
    loadingStyle?: LoadingStyle;
    captionVariant?: TextVariant;
    captionColor?: TextColor;
};

export function StatRow({
    stats,
    spacing = 3,
    sx,
    direction,
    loading,
    variant,
    loadingStyle,
    captionVariant,
    captionColor
}: StatContainer & { direction?: StatDirection }) {
    if (!stats?.some((s) => !s.hide)) return null;

    return (
        <Row sx={{ gap: spacing, alignItems: "flex-start", flexWrap: "wrap", ...sx }}>
            {stats?.map((s, i) => (
                <Stat
                    loadingStyle={loadingStyle}
                    loading={loading}
                    variant={variant}
                    captionVariant={captionVariant}
                    captionColor={captionColor}
                    key={typeof s.caption === "string" ? s.caption + i : i}
                    {...s}
                    direction={direction}
                />
            ))}
        </Row>
    );
}

export function StatColumn({
    stats,
    spacing = 0.5,
    loading,
    variant = "body1",
    loadingStyle,
    captionVariant
}: StatContainer) {
    if (!stats?.some((s) => !s.hide)) return null;
    return (
        <Column spacing={spacing}>
            {stats?.map((s, i) => (
                <Stat
                    captionVariant={variant ?? captionVariant}
                    loadingStyle={loadingStyle}
                    variant={variant}
                    loading={loading}
                    sx={{ justifyContent: "space-between", gap: 2 }}
                    direction="row"
                    key={i}
                    {...s}
                />
            ))}
        </Column>
    );
}

export function MediaStats({
    stats,
    mobileSpacing = 0.5,
    spacing = 3,
    query = MEDIA.LG,
    captionVariant,
    loading,
    loadingStyle,
    variant
}: StatContainer & { query?: MediaQuery; mobileSpacing?: number }) {
    const isAbove = useMediaQuery(query.above);

    return (
        <MediaStack query={query} mobileSpacing={mobileSpacing} spacing={spacing}>
            {stats?.map((s, i) => (
                <Stat
                    captionVariant={captionVariant ?? (isAbove ? "body2" : "body1")}
                    loadingStyle={loadingStyle}
                    variant={variant ?? (isAbove ? "h4" : "body1")}
                    loading={loading}
                    sx={{
                        [query.below]: { flexDirection: "row", justifyContent: "space-between", gap: 2 },
                        [query.above]: { flexDirection: "column", gap: 0 }
                    }}
                    direction="row"
                    key={i}
                    {...s}
                />
            ))}
        </MediaStack>
    );
}

export interface BreadcrumbsInterface {
    text: string | undefined;
    path?: string;
}
export function Breadcrumbs({ paths, loading }: { paths: BreadcrumbsInterface[]; loading?: boolean }) {
    return (
        <Row wrap>
            {paths.map((s, i) => {
                const isLastIndex = i === paths.length - 1;
                if (s.path && !isLastIndex) {
                    return (
                        <ConditionalLink key={i} to={s.path}>
                            <BreadcrumbsText isLink loading={loading} text={s.text} isLastIndex={isLastIndex} />
                        </ConditionalLink>
                    );
                }
                return (
                    <BreadcrumbsText
                        isLink={!!s.path}
                        key={i}
                        loading={loading}
                        text={s.text}
                        isLastIndex={isLastIndex}
                    />
                );
            })}
        </Row>
    );
}
function BreadcrumbsText({
    text,
    isLastIndex,
    loading,
    isLink
}: {
    text: string | undefined;
    isLastIndex: boolean;
    loading?: boolean;
    isLink: boolean;
}) {
    return (
        <Row>
            <Text
                loading={!text || loading}
                underlineLink="hover"
                isLink={!isLastIndex && isLink}
                color={isLastIndex ? "body" : "caption"}
                variant={isLastIndex ? "h4" : "body1"}
            >
                {text}
            </Text>

            {!isLastIndex && (
                <Text variant="body2" color="disabled">
                    <ArrowForwardIos sx={{ mx: "5px" }} color="inherit" fontSize="inherit" />
                </Text>
            )}
        </Row>
    );
}

export function StepProgress({ totalSteps, step }: { totalSteps: number; step: number }) {
    return (
        <Row sx={{ width: "100%" }} spacing={1}>
            {Array(totalSteps)
                .fill(null)
                .map((_, i) => (
                    <Divider
                        flexItem
                        sx={{
                            borderWidth: "1.5px",
                            width: `calc(${100 / totalSteps}% - ${SPACING}px)`,
                            borderColor: step === i ? "primary.main" : undefined
                        }}
                        key={i}
                    />
                ))}
        </Row>
    );
}

export function StepsWithLabels({ stepIndex, steps }: { stepIndex: number; steps: string[] }) {
    const { primary, background, textDisabled, border } = useAppPalette();
    const iconSizePx = "20px";
    return (
        <Stepper activeStep={stepIndex}>
            {steps.map((label, i) => (
                <Step key={label}>
                    <StepLabel
                        StepIconComponent={({ active, completed }: StepIconProps) => (
                            <Stack
                                sx={{
                                    border: active ? undefined : border,
                                    background: active ? primary : undefined,
                                    height: iconSizePx,
                                    width: iconSizePx,
                                    boxSizing: "border-box",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "100%"
                                }}
                            >
                                <Text
                                    sx={{
                                        color: (() => {
                                            if (active) return background;
                                            if (completed) return primary;
                                            return textDisabled;
                                        })()
                                    }}
                                    variant="caption"
                                >
                                    {completed ? <Check /> : i + 1}
                                </Text>
                            </Stack>
                        )}
                        sx={{
                            [`& .${stepLabelClasses.alternativeLabel}`]: {
                                marginTop: "2px !important",
                                fontWeight: FONT_WEIGHTS.normal + "!important",
                                fontSize: FONT_SIZES.body2 + "!important"
                            }
                        }}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

type StepBreadcrumb = {
    label: string;
    isComplete: boolean;
    onClick?: () => void;
};
export function StepBreadcrumbs({
    steps,
    variant,
    activeStep
}: {
    steps: StepBreadcrumb[];
    variant?: TextVariant;
    activeStep: number;
}) {
    const lastCompleteIndex = useMemo(() => {
        let lastCompleteIndex = 0;
        for (const [i, step] of steps.entries()) {
            if (step.isComplete) {
                lastCompleteIndex = i;
            } else {
                return lastCompleteIndex;
            }
        }
        return lastCompleteIndex;
    }, [steps]);

    return (
        <Row spacing={1}>
            {steps.map((step, i) => {
                const isActive = i === activeStep;
                const isLastIndex = i === steps.length - 1;

                const isAllowedToClick = !isActive && ((step.isComplete && activeStep > i) || lastCompleteIndex === i);
                return (
                    <Fragment key={i}>
                        <Text
                            isLink={isAllowedToClick && !!step.onClick}
                            onClick={isAllowedToClick ? step.onClick : undefined}
                            color={isActive ? "primary" : "caption"}
                            variant={variant}
                        >
                            {step.label}
                        </Text>
                        {!isLastIndex && (
                            <Text variant={variant} color="disabled">
                                <ChevronRight />
                            </Text>
                        )}
                    </Fragment>
                );
            })}
        </Row>
    );
}

interface ProgressBarProps extends LinearProgressProps {
    loading?: boolean;
    bgColor?: string;
}
export function ProgressBar({ variant = "determinate", sx, loading, value, bgColor, ...props }: ProgressBarProps) {
    const { divider } = useAppPalette();
    return (
        <MuiLinearProgress
            variant="determinate"
            value={loading ? 0 : value}
            sx={{
                width: "100%",
                height: "8px",
                backgroundColor: divider,
                "& .MuiLinearProgress-bar": {
                    backgroundColor: bgColor
                },
                ...sx
            }}
            {...props}
        />
    );
}

export function usePopover() {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    function open(event: React.MouseEvent<HTMLElement>) {
        // prevents double click
        if (event.detail === 0) return;
        setAnchorEl(event.currentTarget);
    }

    function close() {
        setAnchorEl(null);
    }

    const popoverProps: PopoverProps = { anchorEl, close };

    return { open, close, isOpen: !!anchorEl, anchorEl, popoverProps };
}

export type PopoverProps = {
    anchorEl: HTMLElement | null;
    close: () => void;
};

type PopoverWrapperProps = {
    children: ReactNode;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    sx?: SxType;
};

export const PopoverWrapper = ({
    children,
    anchorOrigin,
    transformOrigin,
    sx,
    anchorEl,
    close
}: PopoverWrapperProps & PopoverProps) => {
    const { border, cardBackground, background } = useAppPalette();

    const columnSx = {
        overflow: "scroll",
        border,
        background: cardBackground,
        borderRadius: BORDER_RADIUS,
        ...sx
    };

    const popoverSx = {
        [`.${popoverClasses.paper}`]: {
            background,
            borderRadius: BORDER_RADIUS
        }
    };

    return (
        <MuiPopover
            sx={popoverSx}
            anchorOrigin={
                anchorOrigin ?? {
                    vertical: "bottom",
                    horizontal: "center"
                }
            }
            transformOrigin={
                transformOrigin ?? {
                    vertical: "top",
                    horizontal: "center"
                }
            }
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={close}
        >
            <Column sx={columnSx}>{children}</Column>
        </MuiPopover>
    );
};

export interface MenuItemProps {
    icon?: ReactNode;
    onClick?: () => void;
    text: string;
    isDangerous?: boolean;
    hide?: boolean;
    rowEnd?: ReactNode;
    disableClose?: boolean;
    disabled?: boolean;
}

export interface MenuItemsProps {
    px?: number;
    py?: number;
    items: MenuItemProps[];
    close: () => void;
    sx?: SxType;
    spacing?: number;
    iconWidth?: string;
}
export function MenuItems({ px = 2, py = 1, iconWidth, spacing = 1, close, sx, items }: MenuItemsProps) {
    const { hoverBackground, error } = useAppPalette();

    const activeItems = items.filter((i) => !i.hide);

    if (!activeItems.length) return null;

    return (
        <Column sx={{ py: 1, ...sx }}>
            {activeItems.map(({ isDangerous, rowEnd, disableClose, onClick, disabled, ...item }, i) => (
                <Row
                    key={i}
                    tabIndex={0}
                    spaceBetween
                    sx={{
                        cursor: onClick ? "pointer" : undefined,
                        "&:hover": onClick
                            ? {
                                  backgroundColor: isDangerous ? colorToAlpha(error, 0.2) : hoverBackground
                              }
                            : undefined,
                        px,
                        py,

                        background: isDangerous ? colorToAlpha(error, 0.1) : undefined
                    }}
                    onClick={() => {
                        if (disabled) return;
                        if (onClick) {
                            onClick?.();
                            if (!disableClose) {
                                close();
                            }
                        }
                    }}
                >
                    <Row spacing={spacing}>
                        {item.icon && (
                            <Text
                                sx={{ width: iconWidth, justifyContent: "center" }}
                                color={(() => {
                                    if (disabled) return "disabled";
                                    if (isDangerous) return "error";
                                    return "caption";
                                })()}
                            >
                                {item.icon}
                            </Text>
                        )}
                        <Text
                            color={(() => {
                                if (disabled) return "disabled";
                                if (isDangerous) return "error";
                                return "body";
                            })()}
                        >
                            {item.text}
                        </Text>
                    </Row>
                    {rowEnd}
                </Row>
            ))}
        </Column>
    );
}

export function RadioOption({
    isSelected,
    onClick,
    children
}: {
    isSelected: boolean;
    onClick: () => void;
    children: ReactNode;
}) {
    const { border, primary, hoverBackground } = useAppPalette();

    return (
        <Row
            onClick={onClick}
            sx={{
                px: 1,
                py: 1,
                cursor: "pointer",
                ":hover": { background: hoverBackground },
                borderRadius: BORDER_RADIUS,
                outline: isSelected ? `1px solid ${primary}` : border
            }}
        >
            {children}
        </Row>
    );
}

export function TooltipRow({
    icon,
    title,
    description,
    sx
}: {
    icon?: ReactElement;
    title: string;
    description: string;
    sx?: SxType;
}) {
    return (
        <Row alignItems="flex-start" sx={sx} spacing={1}>
            {icon && (
                <Text variant="body2" sx={{ py: 0.25, color: "inherit" }}>
                    {icon}
                </Text>
            )}
            <Column>
                <Text variant="body2" sx={{ color: "inherit" }}>
                    {title}
                </Text>
                <Text variant="body2" sx={{ color: "inherit", opacity: 0.6 }}>
                    {description}
                </Text>
            </Column>
        </Row>
    );
}

export function TagWrapper({ color = "info", sx, children }: { color?: TagColor; sx?: SxType; children: ReactNode }) {
    const baseColor = useColorFromTag(color);
    const { isDarkMode } = useAppPalette();

    return (
        <Box px={1.5} py={1} sx={{ backgroundColor: colorToAlpha(baseColor, isDarkMode ? 0.2 : 0.1), ...sx }}>
            {children}
        </Box>
    );
}

export function PollingWithProgress({
    refreshInterval = 10_000,
    interval = 1_000,
    callback,
    size = 20,
    helpText = "Refresh",
    isFetching,
    skip
}: {
    refreshInterval?: number;
    callback: () => void;
    interval?: number;
    size?: number;
    helpText?: string;
    isFetching?: boolean;
    skip?: boolean;
}) {
    const { divider, textDisabled } = useAppPalette();
    const sizePx = size + "px";
    const [count, setCount] = useState<number>(0);

    const trigger = useCallback(() => {
        callback();
        setCount(0);
    }, [callback, setCount]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isFetching && !skip) {
                setCount((prevCount) => prevCount + interval);
            }

            if (count >= refreshInterval) {
                trigger();
            }
        }, interval);

        return () => clearInterval(intervalId);
    }, [count, refreshInterval, interval, callback, trigger, isFetching, skip]);

    const strokeWidth = 2;
    return (
        <Tooltip title={helpText}>
            <Stack
                onClick={isFetching ? undefined : trigger}
                sx={{
                    color: textDisabled,
                    cursor: isFetching ? "wait" : "pointer",
                    position: "relative",
                    width: sizePx,
                    height: sizePx
                }}
            >
                <CircularProgress
                    color="inherit"
                    sx={{ position: "absolute", left: 0, top: 0, zIndex: 2 }}
                    value={(count / refreshInterval) * 100}
                    variant="determinate"
                    size={size}
                />
                <Stack
                    sx={{
                        border: `${strokeWidth}px solid ${divider}`,
                        zIndex: 1,
                        borderRadius: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: size - strokeWidth * 2 + "px",
                        height: size - strokeWidth * 2 + "px"
                    }}
                />
                <Text
                    key={isFetching ? "fetching" : "not-fetching"}
                    // animation reset on rerender
                    color="disabled"
                    sx={{
                        fontSize: size / 1.75 + "px",
                        position: "absolute",
                        width: sizePx,
                        height: sizePx,
                        justifyContent: "center"
                    }}
                >
                    <RefreshOutlined
                        sx={{
                            animation: `${rotate} 1s linear infinite`,
                            animationPlayState: isFetching ? "running" : "paused"
                        }}
                    />
                </Text>
            </Stack>
        </Tooltip>
    );
}

export function RefreshButtonWithLoading({
    callback,
    isFetching,
    variant,
    color = "caption"
}: {
    callback: () => void;
    isFetching: boolean;
    variant?: TextVariant;
    color?: TextColor;
}) {
    return (
        <TextButton
            onClick={callback}
            variant={variant}
            disabled={isFetching}
            key={isFetching ? "fetching" : "not-fetching"}
            color={color}
        >
            <RefreshOutlined
                sx={{
                    animation: `${rotate} 1s linear infinite`,
                    animationPlayState: isFetching ? "running" : "paused"
                }}
            />
        </TextButton>
    );
}

const rotate = keyframes`
0% {
    transform: rotate(0deg);
}
100%{
    transform: rotate(360deg);
}
`;

export type StatCardProps = {
    value: string | JSX.Element;
    caption: string;
    description?: string;
    descriptionTooltip?: string;
    tooltip?: ReactNode;
    loading?: boolean;
    query?: MediaQuery;
    hide?: boolean;
};

export function StatCard({
    value,
    caption,
    description,
    tooltip,
    query = MEDIA.MD,
    loading,
    descriptionTooltip
}: StatCardProps) {
    const { cardBackground, border, textDisabled } = useAppPalette();
    return (
        <Column
            sx={{
                gap: 0.25,
                [query.above]: { width: `calc(100% - ${SPACING * 2}px)` },
                p: 1.5,
                background: cardBackground,
                border,
                borderRadius: BORDER_RADIUS
            }}
        >
            <Text loading={loading} loadingWidth="40px" variant="body2" color="caption">
                {caption}
            </Text>
            <Tooltip placement="right" reverseColors={typeof tooltip !== "string"} title={tooltip}>
                {typeof value === "string" ? (
                    <Text
                        variant="h3"
                        sx={{ svg: { fontSize: FONT_SIZES.body1 + "px", color: textDisabled } }}
                        fontWeight="normal"
                        loading={loading}
                        loadingWidth="60px"
                    >
                        {value} {tooltip && <Icon type="tooltip" />}
                    </Text>
                ) : (
                    value
                )}
            </Tooltip>
            <TooltipText
                loadingWidth="30px"
                loading={loading}
                helpText={descriptionTooltip}
                variant="body2"
                color="caption"
            >
                {description}
            </TooltipText>
        </Column>
    );
}

export function StatCards({
    stats: statsRaw,
    query = MEDIA.MD,
    loading: propsLoading,
    spacing = 2
}: {
    stats: StatCardProps[];
    query?: MediaQuery;
    loading?: boolean;
    spacing?: number;
}) {
    const stats = statsRaw.filter((c) => !c.hide);
    if (!stats.length) return null;
    return (
        <Row
            spacing={spacing}
            sx={{
                alignItems: "stretch",
                [query.below]: {
                    display: "grid",
                    gridGap: `${spacing * SPACING}px`,
                    gridTemplateColumns: "1fr 1fr"
                }
            }}
        >
            {stats.map(({ loading, ...card }, i) => (
                <StatCard query={query} loading={loading || propsLoading} key={i} {...card} />
            ))}
        </Row>
    );
}

export type QuestionToggleProps = { label: string; answer: JSX.Element | string[] };
export function QuestionToggleCards({
    questions,
    labelVariant
}: {
    questions: QuestionToggleProps[];
    labelVariant?: TextVariant;
}) {
    const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);
    return (
        <Column spacing={1}>
            {questions.map(({ label, answer }, i) => (
                <Column
                    onClick={() => setExpandedIndex(expandedIndex === i ? undefined : i)}
                    key={i}
                    role="button"
                    tabIndex={0}
                    aria-expanded={expandedIndex === i}
                    aria-controls={`faq-answer-${i}`}
                    sx={{ cursor: "pointer" }}
                >
                    <OutlinedCard padding={2}>
                        <Row spaceBetween>
                            <Text variant={labelVariant}>{label}</Text>
                            <TextButton variant="h3" color="disabled">
                                <ExpandMore
                                    sx={{
                                        transform: expandedIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                                        transition: "transform 0.3s ease"
                                    }}
                                />
                            </TextButton>
                        </Row>
                        <Collapse in={expandedIndex === i}>
                            <Column spacing={1} sx={{ marginTop: 1 }}>
                                {Array.isArray(answer)
                                    ? answer.map((a, j) => (
                                          <Text key={j} color="caption">
                                              {a}
                                          </Text>
                                      ))
                                    : answer}
                            </Column>
                        </Collapse>
                    </OutlinedCard>
                </Column>
            ))}
        </Column>
    );
}
