const CUSTODIAN_TERM = "Issuer";
const CUSTODIAN_TERM_PLURAL = "Issuers";
const CUSTODIAN_TERM_FULL = "Asset issuer";
const CUSTODIAN_TERM_FULL_PLURAL = "Asset issuers";

const custodian = { CUSTODIAN_TERM, CUSTODIAN_TERM_PLURAL, CUSTODIAN_TERM_FULL, CUSTODIAN_TERM_FULL_PLURAL };

const COLLATERAL_TERM = "Collateral";
const BORROW_TERM = "Borrow";
const BORROW_TERM_GERUND = "Borrowing";
const STRATEGY_TERM = "Custom position";
const STRATEGY_TERM_PLURAL = "Custom positions";
const MAX_DURATION_TOOLTIP = "The longest duration that borrowers can take out a loan for";
const ALLOWED_COLLATERAL_TOOLTIP = "The tokens you will accept as collateral. Loan-to-values are set by Loopscale";
const INTEREST_EARNED_LABEL = "Interest earned";
const MAX_DURATION_TERM = "Max duration";
const ALLOWED_COLLATERAL_TERM = "Eligible collateral";
const YIELD_SOURCE_TERM = "Idle capital";
const YIELD_SOURCE_LABEL = "Lend idle capital";
const YIELD_SOURCE_TOOLTIP =
    "Deposit idle capital into variable-rate lending while you wait for a loan to match to your position";
const EXTERNAL_YIELD_APY_TERM = "Current APY";
const EXTERNAL_YIELD_APY_TOOLTIP = "The APY you'll earn while waiting for loans";

const STRATEGY_APY_TOOLTIP = `Your ${STRATEGY_TERM.toLowerCase()} will only be used for loans when it's the lowest APY in the market`;
const STRATEGY_APY_TOOLTIP_LITE = "The APY you'll earn on new loans";

const STRATEGY_APY_TERM = "Target APY";

export const ORIGINATION_CAP = "Origination Cap";
export const ORIGINATION_CAP_STATUS = "Accepting Originations Status";
export const ORIGINATION_CAP_TOOLTIP = "Restrict originations to this amount";

export const FINALIZED_TERM = "Finalized";
export const TYPE_TERM = "Type";
export const LTV_TERM = "LTV";

export const LIQUIDITY_BUFFER = "Liquidity Buffer";
export const LIQUIDITY_BUFFER_TOOLTIP = "The reserve funds set aside to ensure liquidity for the loan.";

export const INTEREST_FEE = "Interest Fee";
export const INTEREST_FEE_TOOLTIP = "The fee applied to the interest earned on this loan.";

export const ORIGINATION_FEE = "Origination Fee";
export const ORIGINATION_FEE_TOOLTIP = "The fee charged for originating this loan.";

export const MAX_ORIGINATION_SIZE = "Max Origination Size";
export const MAX_ORIGINATION_SIZE_TOOLTIP = "The maximum size of the loan origination allowed.";

export const OPTIONAL_LENDING_CONFIGURATION_TITLE = "Optional Lending Configurations";
export const OPTIONAL_LENDING_CONFIGURATION_TITLE_HELP_TEXT = "Optional values to customize your strategy.";

export const PRINCIPAL_FEE = "Principal Fee";

export const EXTERNAL_YIELD_SOURCE = "External Yield Source";

export const SLIPPAGE_TOOLTIP =
    "Higher slippage values may help with transaction success rate, but may result in unfavorable trades";

export const LIFETIME_PNL_TOOLTIP = "Lifetime Vault Interest Earned";
export const CURRENT_PNL_TOOLTIP = "Current Vault Interest Earned";

const ORIGINATED_ALL_TIME_TERM = "Volume (all time)";
const strategy = {
    BORROW_TERM,
    BORROW_TERM_GERUND,
    STRATEGY_TERM,
    STRATEGY_TERM_PLURAL,
    MAX_DURATION_TOOLTIP,
    ALLOWED_COLLATERAL_TOOLTIP,
    MAX_DURATION_TERM,
    ALLOWED_COLLATERAL_TERM,
    INTEREST_EARNED_LABEL,
    YIELD_SOURCE_TERM,
    YIELD_SOURCE_LABEL,
    YIELD_SOURCE_TOOLTIP,
    STRATEGY_APY_TOOLTIP,
    STRATEGY_APY_TERM,
    STRATEGY_APY_TOOLTIP_LITE,
    EXTERNAL_YIELD_APY_TERM,
    EXTERNAL_YIELD_APY_TOOLTIP,
    ORIGINATED_ALL_TIME_TERM,
    ORIGINATION_CAP,
    ORIGINATION_CAP_STATUS,
    COLLATERAL_TERM,
    FINALIZED_TERM,
    TYPE_TERM,
    LTV_TERM,
    ORIGINATION_CAP_TOOLTIP,
    LIQUIDITY_BUFFER,
    LIQUIDITY_BUFFER_TOOLTIP,
    INTEREST_FEE,
    INTEREST_FEE_TOOLTIP,
    ORIGINATION_FEE,
    ORIGINATION_FEE_TOOLTIP,
    MAX_ORIGINATION_SIZE,
    MAX_ORIGINATION_SIZE_TOOLTIP,
    OPTIONAL_LENDING_CONFIGURATION_TITLE,
    OPTIONAL_LENDING_CONFIGURATION_TITLE_HELP_TEXT,
    PRINCIPAL_FEE,
    EXTERNAL_YIELD_SOURCE,
    SLIPPAGE_TOOLTIP
};

const POINTS_REASON_NO_STRATEGY = `No ${STRATEGY_TERM} data available`;
const POINTS_REASON_NO_ACTIVE_LOANS = "No active loans in this strategy";
const POINTS_REASON_PRINCIPAL_LENDING = "Principal asset has lending points multiplier";
const POINTS_REASON_PRINCIPAL_IDLE = "Principal asset has idle points multiplier";
const POINTS_REASON_COLLATERAL = "One or more active loan's collateral asset has a lending points multiplier";
const POINTS_REASON_NO_POINTS = `${STRATEGY_TERM} does not earn any points`;
const POINTS_REASON_EARNS_POINTS = `View active loans to see breakdown`;

const reasons = {
    POINTS_REASON_NO_STRATEGY,
    POINTS_REASON_NO_ACTIVE_LOANS,
    POINTS_REASON_PRINCIPAL_LENDING,
    POINTS_REASON_PRINCIPAL_IDLE,
    POINTS_REASON_COLLATERAL,
    POINTS_REASON_NO_POINTS,
    POINTS_REASON_EARNS_POINTS
};
const ESCROW_TERM = "Escrow";
const ESCROW_TERM_ASSETS = "Escrowed assets";
const ESCROW_TERM_FULL = "Escrow account";

const escrow = { ESCROW_TERM, ESCROW_TERM_ASSETS, ESCROW_TERM_FULL };

const HEALTH_FACTOR_TERM = "Health";
const HEALTH_FACTOR_TOOLTIP =
    "This ratio represents how well-collateralized your loan is. Your collateral can be liquidated if your health is 0 or below";
const LIQUIDATION_THRESHOLD_TOOLTIP = "The loan-to-value where your collateral is exposed to being liquidated";
const LIQUIDATION_THRESHOLD_TERM = "Liquidation LTV";

const LIQUIDATION_PRICE_TERM = "Liquidation price";
const LIQUIDATION_PRICE_TOOLTIP = "The price at which your collateral is exposed to being liquidated";

const health = {
    HEALTH_FACTOR_TERM,
    HEALTH_FACTOR_TOOLTIP,
    LIQUIDATION_THRESHOLD_TERM,
    LIQUIDATION_PRICE_TERM,
    LIQUIDATION_THRESHOLD_TOOLTIP,
    LIQUIDATION_PRICE_TOOLTIP
};

// info
const ASSET_INFO_TITLE = "Additional information";
const ASSET_ORIGINATOR_TITLE = "About loan custodian";
const ASSET_REDEMPTION_TITLE = "Recourse process";
const ASSET_ORDERS_TITLE = "Offers";
const ASSET_NOT_DEPOSITED_ALERT = "This asset must be deposited before offers can be sent";
const ASSET_PRICING_TOOLTIP = `Estimated value for an asset based on pricing data provided by the ${CUSTODIAN_TERM_FULL_PLURAL.toLocaleLowerCase()} (if available)`;
const ASSET_BRIDGE_TRANSFER_LENGTH = "Transfers typically take a few minutes";

const assetInfo = {
    ASSET_INFO_TITLE,
    ASSET_ORIGINATOR_TITLE,
    ASSET_REDEMPTION_TITLE,
    ASSET_ORDERS_TITLE,
    ASSET_NOT_DEPOSITED_ALERT,
    ASSET_PRICING_TOOLTIP,
    ASSET_BRIDGE_TRANSFER_LENGTH
};

// actions
const ASSET_ACTION_ACTIVE_LOAN = "Loan details";
const ASSET_ACTION_MAKE_REPAYMENT = "Make repayment";
const ASSET_ACTION_RECEIVING_OFFERS = "Receiving offers";
const ASSET_ACTION_DEFAULTED_LOAN = "Defaulted loan";
const ASSET_ACTION_PAID_OFF_LOAN = "Completed loan";
const ASSET_ACTION_LEDGER_OFFER = "Pending loan";

const assetActions = {
    ASSET_ACTION_ACTIVE_LOAN,
    ASSET_ACTION_MAKE_REPAYMENT,
    ASSET_ACTION_RECEIVING_OFFERS,
    ASSET_ACTION_DEFAULTED_LOAN,
    ASSET_ACTION_LEDGER_OFFER,
    ASSET_ACTION_PAID_OFF_LOAN
};

// tables
const WITHDRAW_ESCROW_CTA = "Withdraw";
const ASSET_PAGE_CTA = "View Asset";
const DEPOSIT_ESCROW_CTA = "Deposit assets";
const SEARCH_METADATA_PLACEHOLDER = "Search by asset";
const DEALS_EMPTY = "You don't have permission to view any deals";
const DEALS_EMPTY_FILTER = "No matching deals found";

const tableCtas = {
    DEALS_EMPTY,
    DEALS_EMPTY_FILTER,
    WITHDRAW_ESCROW_CTA,
    ASSET_PAGE_CTA,
    DEPOSIT_ESCROW_CTA,
    SEARCH_METADATA_PLACEHOLDER
};

const AUTH_INDIVIDUAL_GROUP_DESCRIPTION = "Personal account";
const WALLET_VERIFY = "You will need to pay a one time account fee of 0.00443 SOL to create your Loopscale Account";
const ACCOUNT_CREATE_PREVIEW = "By default, these account details will be shown on any loans you participate in";
const PASSKEY_SIGN_PROMPT = "Please authenticate with your device";
const WALLET_SIGN_PROMPT = "Please sign with your wallet";
const PASSKEY_SIGN_FAIL = "Unable to authenticate with your device";
const CONNECT_PROMPT = "Connect";
const CONNECT_DESCRIPTION = "Connect a Solana wallet";

const auth = {
    AUTH_INDIVIDUAL_GROUP_DESCRIPTION,
    WALLET_VERIFY,
    ACCOUNT_CREATE_PREVIEW,
    PASSKEY_SIGN_PROMPT,
    WALLET_SIGN_PROMPT,
    PASSKEY_SIGN_FAIL,
    CONNECT_PROMPT,
    CONNECT_DESCRIPTION
};

const REQUEST_DEFAULT = "Any";
const REQUEST_PREFERENCE_TOOLTIP = "The borrower has established this range as a preference for incoming offers";
const GRACE_PERIOD =
    "The amount of time after the payment that the borrower can repay without paying fees or defaulting";
const MATURITY_GRACE_PERIOD = "A window of time after the due date that the borrower can repay without defaulting";
const PREPAYMENT_GRACE_PERIOD =
    "Specify the min time remaining in the loan at which a borrower can repay a loan with prorated interest";

const LATE_FEE =
    "Following a missed payment beyond the grace period, an additional percentage will be added to the original amount due";

const PREPAYMENT =
    "Borrowers can benefit from reduced interest by repaying their loan in the early repayment window, paying the prorated interest plus a discounted rate on the unused portion of the loan term";
const PREPAYMENT_FEE_DESCRIPTION =
    "The percentage fee incurred for repaying principal before the maturity date. This fee is calculated based on the difference between the original interest due and the new interest due after early repayment. For instance, with a 50% fee, the borrower is required to pay 50% of the interest amount initially saved by repaying the loan ahead of schedule";

const request = {
    REQUEST_DEFAULT,
    REQUEST_PREFERENCE_TOOLTIP,
    PREPAYMENT_GRACE_PERIOD,
    GRACE_PERIOD,
    MATURITY_GRACE_PERIOD,
    LATE_FEE,
    PREPAYMENT,
    PREPAYMENT_FEE_DESCRIPTION
};

const LOOP_TERM = "Yield loop";
const LOOP_TERM_ACTION = "Deposit";
const LOOP_TERM_PLURAL = "Yield loops";
const LEVERAGE_TERM = "Leverage";
const MAX_LEVERAGE_TERM = "Max leverage";
const LOOP_COLLATERAL_DEPOSIT_TERM = "Deposits";
const LOOP_PRINCIPAL_AVAILABLE_TERM = "Available";
const LOOP_ADDITIONAL_DEPOSITS_TERM = "Contributions";
const LOOP_ADDITIONAL_DEPOSITS_TOOLTIP = "Additional repayments and top ups after initial deposit";
const LOOP_ORACLE_DISCREPANCY_TERM = "Market premium";
const UNWIND_TERM = `Close ${LOOP_TERM.toLowerCase()}`;
const loop = {
    LOOP_TERM,
    LOOP_TERM_PLURAL,
    LEVERAGE_TERM,
    MAX_LEVERAGE_TERM,
    LOOP_COLLATERAL_DEPOSIT_TERM,
    LOOP_PRINCIPAL_AVAILABLE_TERM,
    LOOP_TERM_ACTION,
    LOOP_ADDITIONAL_DEPOSITS_TERM,
    LOOP_ADDITIONAL_DEPOSITS_TOOLTIP,
    LOOP_ORACLE_DISCREPANCY_TERM,
    UNWIND_TERM
};

const VAULT_WITHDRAW_SETTINGS = "Vault Withdraw Setting";
const VAULT_DEPOSIT_CTA = "Deposit";
const VAULT_WITHDRAW_CTA = "Withdraw";
const VAULT_MANAGER_TIMELOCK_INIT_CTA = "Save changes";
const VAULT_MANAGER_TERM = "Manager";
const VAULT_MANAGER_PREFIX = "Managed by";
const VAULT_TERM = "Vault";
const VAULT_TERM_PLURAL = "Vaults";
const TVL_TERM = "TVL";
const USER_POSITION_TERM = "Your deposits";
const LIQUIDITY_BUFFER_TERM = "Liquidity buffer";
const LIQUIDITY_BUFFER_TERM_TOOLTIP =
    "The minimum liquidity required for instant withdrawals. No lending offers will be placed if idle liquidity is below the buffer";
const vault = {
    VAULT_WITHDRAW_SETTINGS,
    VAULT_DEPOSIT_CTA,
    VAULT_WITHDRAW_CTA,
    VAULT_MANAGER_TIMELOCK_INIT_CTA,
    VAULT_MANAGER_TERM,
    VAULT_MANAGER_PREFIX,
    VAULT_TERM,
    VAULT_TERM_PLURAL,
    TVL_TERM,
    USER_POSITION_TERM,
    LIQUIDITY_BUFFER_TERM,
    LIQUIDITY_BUFFER_TERM_TOOLTIP,
    LIFETIME_PNL_TOOLTIP,
    CURRENT_PNL_TOOLTIP
};

const TRANSACTION_FEE = "Fees";
const TRANSACTION_FEE_TOTAL = "Total Fees";
const TRANSACTION_FEE_NET = "Net Fees";
const SETUP_FEE = "Setup (one time)";
const RENT_REFUNDABLE = "Rent (refundable)";
const RENT_NON_REFUNDABLE = "Rent (non-refundable)";
const RENT_NETWORK = "Network";
const RENT_ESTIMATE_TOOLTIP = "The USD estimate is the total non-refundable fee to fill the transaction";

const TRANSACTION_SETTINGS_TITLE = "Transaction Settings";
const TRANSACTION_MAX_FEE = "Max Fee";
const TRANSACTION_MAX_FEE_TOOLTIP = "Optional, set your max fee to prevent paying more than a certain amount";
const TRANSACTION_EXACT_FEE = "Exact Fee";
const TRANSACTION_EXACT_FEE_TOOLTIP = "Optional, overrides max fee and fee level";
const TRANSACTION_FEE_LEVEL = "Fee Level";
const TRANSACTION_FEE_LEVEL_TOOLTIP =
    "Set your fee level, the higher you set it, the more likely it is to land quickly. However, when the chain is congested you may end up paying a higher fee.";
const TRANSACTION_BROADCAST_MODE = "Broadcast Type";
const TRANSACTION_BROADCAST_MODE_TOOLTIP = "Send with priority fees, Jito, or both (defaults to Priority Fee)";

const transactions = {
    TRANSACTION_FEE,
    TRANSACTION_FEE_TOTAL,
    TRANSACTION_FEE_NET,
    SETUP_FEE,
    RENT_REFUNDABLE,
    RENT_NON_REFUNDABLE,
    RENT_NETWORK,
    RENT_ESTIMATE_TOOLTIP,
    TRANSACTION_SETTINGS_TITLE,
    TRANSACTION_MAX_FEE,
    TRANSACTION_MAX_FEE_TOOLTIP,
    TRANSACTION_EXACT_FEE,
    TRANSACTION_EXACT_FEE_TOOLTIP,
    TRANSACTION_FEE_LEVEL,
    TRANSACTION_FEE_LEVEL_TOOLTIP,
    TRANSACTION_BROADCAST_MODE,
    TRANSACTION_BROADCAST_MODE_TOOLTIP
};

const REWARDS_SUFFIX = " rewards";
const EARN_REWARDS = LOOP_TERM + REWARDS_SUFFIX;
const RATES_AND_REWARDS = "Rates and" + REWARDS_SUFFIX;
const BORROW_REWARDS = "Borrow" + REWARDS_SUFFIX;
const LEND_REWARDS = "Lend" + REWARDS_SUFFIX;

const rewards = {
    EARN_REWARDS,
    RATES_AND_REWARDS,
    BORROW_REWARDS,
    LEND_REWARDS
};

const LEND_VAULTS_TERM = "Lend vaults";
const LEND_VAULTS_TIME_LOCK_TOOLTIP = "Preview using latest time locks (if any)";

const lendVaults = {
    LEND_VAULTS_TERM,
    LEND_VAULTS_TIME_LOCK_TOOLTIP
};
export const COPY = {
    ...custodian,
    ...assetInfo,
    ...assetActions,
    ...tableCtas,
    ...auth,
    ...request,
    ...escrow,
    ...health,
    ...strategy,
    ...loop,
    ...transactions,
    ...rewards,
    ...reasons,
    ...vault,
    ...lendVaults
};

export enum AppTab {
    Assets = "assets",
    Offers = "offers",
    Loans = "loans",
    Payments = "payments",
    Activity = "activity",
    GeneralSettings = "general"
}
