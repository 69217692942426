import { useMemo } from "react";

import {
    NullableRecord,
    bpsToUiDecimals,
    filterNullableRecord,
    filterTruthy,
    removeDuplicates
} from "@bridgesplit/utils";

import { useLendingStrategyInfosQuery } from "../reducers";
import {
    BsMetaUtil,
    calculateFixedStrategyApy,
    calculateWAvgApy,
    serializeStrategyDuration,
    useAbfTypesToUiConverter
} from "../utils";
import { LendingStrategyFilter, StrategyCollateralExpanded, StrategyExpanded } from "../types";
import { useOraclePrices } from "./pricing";
import { useMarketOracleInfos } from "./markets";
import { useActiveWallet } from "./wallet";

export function useStrategies(filter: LendingStrategyFilter, options?: { skip?: boolean }) {
    const { data: rawData, isLoading: queryLoading, isFetching } = useLendingStrategyInfosQuery(filter, options);

    const strategies = Object.values(rawData ?? {});
    const principalMints = strategies.map((s) => s.strategy.principalMint);
    const collateralMints = strategies.flatMap((s) => Object.keys(s.terms));
    const mints = removeDuplicates(collateralMints.concat(principalMints));

    const { tokensLoading, getMetadata, convertStrategyInfo } = useAbfTypesToUiConverter(mints);
    const { getUsdPrice, isLoading: pricesLoading } = useOraclePrices(principalMints);

    const { data: marketData } = useMarketOracleInfos({
        filter: {
            principalMints: [],
            addresses: strategies.map((s) => s.strategy.marketInformation),
            verified: true
        }
    });

    const isLoading = queryLoading || tokensLoading || pricesLoading;

    const data = useMemo(() => {
        if (!rawData || isLoading) return undefined;

        return strategies
            .map((d): NullableRecord<StrategyExpanded> => {
                const strategy = convertStrategyInfo(d);

                strategy.strategy.fixedApy = calculateFixedStrategyApy(strategy.strategy);
                strategy.strategy.wAvgApy = calculateWAvgApy(strategy);

                // Find matching market information for this strategy
                const strategyMarketInfo = marketData?.find(
                    (info) => info.marketInformation.address === strategy.strategy.marketInformation
                );

                const collateral = Object.entries(d.terms)
                    .map(([mint, terms]) => {
                        const metadata = getMetadata(mint);
                        if (!metadata) return undefined;

                        // Find matching asset info for this collateral
                        const assetInfo = strategyMarketInfo?.ltvInfo.find((asset) => asset.assetIdentifier === mint);

                        if (!assetInfo) return undefined;

                        const durationAndApys: Record<string, number> = {};
                        terms.forEach(([duration, apy]) => {
                            durationAndApys[serializeStrategyDuration(duration)] = bpsToUiDecimals(apy);
                        });

                        const result: StrategyCollateralExpanded = {
                            metadata,
                            durationAndApys,
                            ltvInfo: {
                                ltv: assetInfo.ltv,
                                liquidationThreshold: assetInfo.liquidationThreshold
                            }
                        };

                        return result;
                    })
                    .filter((item): item is StrategyCollateralExpanded => item !== undefined);

                const principalMetadata = getMetadata(d.strategy.principalMint);

                const principalUsdPrice = getUsdPrice(d.strategy.principalMint);

                return {
                    ...strategy,
                    totalBalance: (strategy.externalYieldInfo?.balance ?? 0) + strategy.strategy.tokenBalance,
                    collateral,
                    principalUsdPrice,
                    principalMetadata
                };
            })
            .filter(filterNullableRecord);
    }, [rawData, isLoading, strategies, marketData, getMetadata, getUsdPrice, convertStrategyInfo]);

    return {
        data: isLoading ? undefined : data,
        isLoading,
        isFetching
    };
}
export function useLenderStrategies(params?: { skip?: boolean }) {
    const { activeWallet } = useActiveWallet();

    const query = useStrategies({ userAddress: activeWallet }, { skip: !activeWallet || params?.skip });

    return query;
}

export function getStrategyName(strategyExpanded: StrategyExpanded | undefined) {
    if (!strategyExpanded) return "";
    const { principalMetadata } = strategyExpanded;

    const principalSymbol = BsMetaUtil.getName(principalMetadata);

    if (strategyExpanded.collateral.length === 1) {
        return `${principalSymbol}/${BsMetaUtil.getSymbol(strategyExpanded.collateral[0].metadata)}`;
    }

    const collateralTags = removeDuplicates(
        strategyExpanded.collateral
            .map((c) => c.metadata.tags)
            .flat()
            .filter(filterTruthy)
    );

    if (collateralTags.length === 1) {
        return `${principalSymbol}/${collateralTags[0]}`;
    }

    return principalSymbol;
}
