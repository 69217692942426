import {
    fetchAndDeserializeLegacyTransactionsWithSetup,
    fetchAndDeserializeVersionedTransaction
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    TXN_CREDITBOOK_REPAY_PRINCIPAL,
    TXN_CREDITBOOK_CREATE,
    SetupLoanArgs,
    RepayLoanTxnParams,
    TXN_CREDITBOOK_DEPOSIT_COLLATERAL,
    DepositCollateralParams,
    TXN_CREDITBOOK_WITHDRAW_COLLATERAL,
    WithdrawCollateralTxnParams,
    TXN_CREDITBOOK_REFINANCE,
    RefinanceLoanTxnParams
} from "../types";
import { createMarketsInstance } from "./auth";

export async function getSetupLoanTransactions(authentication: AbfTransactionHeaders, args: SetupLoanArgs) {
    return await fetchAndDeserializeLegacyTransactionsWithSetup(
        createMarketsInstance(authentication),
        TXN_CREDITBOOK_CREATE,
        args
    );
}

export async function getRepayLoanTransaction(authentication: AbfTransactionHeaders, args: RepayLoanTxnParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_REPAY_PRINCIPAL,
            args
        )
    ];
}

export async function getDepositCollateralTransaction(
    authentication: AbfTransactionHeaders,
    args: DepositCollateralParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_DEPOSIT_COLLATERAL,
            args
        )
    ];
}

export async function getWithdrawCollateralTransaction(
    authentication: AbfTransactionHeaders,
    args: WithdrawCollateralTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_WITHDRAW_COLLATERAL,
            args
        )
    ];
}

export async function getRefinanceLoanTransaction(authentication: AbfTransactionHeaders, args: RefinanceLoanTxnParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createMarketsInstance(authentication),
            TXN_CREDITBOOK_REFINANCE,
            args
        )
    ];
}
