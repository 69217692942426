import { FONT_WEIGHTS, Icon, Row, TextLink } from "@bridgesplit/ui";

import { DESKTOP_PADDING, NAV_BREAKPOINT } from "./constants";
import NavOptions from "./NavOptions";
import ProfileButton from "./ProfileButton";
import NavbarWallet from "./NavbarWallet";
import { useNavOptions } from "./util";
import DialectNotificationButton from "./DialectNotificationButton";
import { NavPoints } from "./points";

export default function DesktopNavbar() {
    const navOptions = useNavOptions();

    return (
        <Row
            spaceBetween
            sx={{
                p: DESKTOP_PADDING,
                [NAV_BREAKPOINT.below]: { display: "none" }
            }}
        >
            <Row spacing={1}>
                <TextLink variant="h4" textSx={{ width: "max-content", fontWeight: FONT_WEIGHTS.logo }} to="/">
                    <Icon type="logo-outer" />
                </TextLink>
                <Row>
                    <NavOptions options={navOptions} />
                </Row>
            </Row>

            <Row spacing={1}>
                <NavbarWallet />
                <DialectNotificationButton />
                <NavPoints />
                <ProfileButton />
            </Row>
        </Row>
    );
}
