import { memo } from "react";

import { SwitchRow } from "@bridgesplit/ui";
import { useExternalYieldVaults } from "@bridgesplit/abf-react";
import { formatPercent } from "@bridgesplit/utils";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { useMarketLendContext } from "./MarketLendContext";
import { useMarketContext } from "../common";
import { COPY } from "../../../constants";

export default memo(function LendSettings() {
    const { form, setForm } = useMarketLendContext();
    const { principalMint } = useMarketContext();
    const { getYieldVault, isLoading } = useExternalYieldVaults();
    const yieldVault = getYieldVault(principalMint);
    const yieldSourceLabel = yieldVault ? ` at ${formatPercent(yieldVault.apy)} APY` : "";

    if (!isLoading && !yieldVault) return null;

    return (
        <SwitchRow
            label={`${COPY.YIELD_SOURCE_LABEL}${yieldSourceLabel}`}
            textProps={{
                helpText: COPY.YIELD_SOURCE_TOOLTIP
            }}
            value={form.yieldSource === ExternalYieldSource.MarginFi}
            setValue={(lendIdleCapital) =>
                setForm((prev) => ({
                    ...prev,
                    yieldSource: lendIdleCapital ? ExternalYieldSource.MarginFi : ExternalYieldSource.None
                }))
            }
        />
    );
});
