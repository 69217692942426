import { useCallback, useMemo, useState } from "react";

import { AppButton, HealthChange, LtvChange } from "app/components/common";
import {
    AbfLoanExpanded,
    BsMetaUtil,
    calculateApy,
    calculateHealthRatio,
    calculateLoanHealth,
    getLoanCollateralUsdValue,
    getLoanStrategyIdentifier,
    getSimpleInterest,
    getZcLedger,
    getZcLedgerStrategyIdentifier,
    getZcLoanCollateral,
    IncreaseCreditTransactionParams,
    IncreasePrincipalQuote,
    LedgerExpanded,
    serializeStrategyDuration,
    StrategyExpanded,
    summarizeLedgerAccount,
    useActiveWallet,
    useIncreasePrincipalQuoteQuery,
    useIncreasePrincipalTransaction,
    useStrategies
} from "@bridgesplit/abf-react";
import { StatColumn, TokenInput, StatProps, TagTextAlert } from "@bridgesplit/ui";
import { useTransactionSender } from "app/components/transactions";
import {
    Result,
    LOADING_ERROR,
    MISSING_PARAM_ERROR,
    lamportsToUiAmount,
    bpsToUiDecimals,
    formatPercent,
    getUnixTs,
    bsMath,
    roundDownToDecimals
} from "@bridgesplit/utils";
import { TrackTransactionEvent } from "app/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StrategyDuration } from "@bridgesplit/abf-sdk";

import { ActionProps } from "./types";

export default function IncreasePrincipal({ loanExpanded }: ActionProps) {
    const [additionalPrincipal, setAdditionalPrincipal] = useState<number | undefined>(undefined);

    const transferCreditQuote = useIncreasePrincipalQuote(loanExpanded);

    const additionalPrincipalLimits = useMemo(
        () => getMaxAdditionalPrincipal({ loanExpanded, transferCreditQuote, additionalPrincipal }),
        [loanExpanded, transferCreditQuote, additionalPrincipal]
    );

    const strategyIdentifier = getZcLedgerStrategyIdentifier(loanExpanded);

    const { data: strategy } = useStrategies({
        addresses: strategyIdentifier ? [strategyIdentifier] : []
    });

    const firstLedger = getZcLedger(loanExpanded); //TODO: handle multiple ledgers in the action props

    const newApy = calculateIncreaseBorrowApy(loanExpanded, firstLedger, additionalPrincipal, strategy);
    const send = useTransactionSender();
    const { user } = useActiveWallet();
    const { activeWallet } = useActiveWallet();

    const increasePrincipal = useIncreasePrincipalTransaction();

    const submit = useCallback(async () => {
        const firstLedger = getZcLedger(loanExpanded);
        if (!user || !loanExpanded || !activeWallet || !firstLedger) return Result.errFromMessage(LOADING_ERROR);

        if (!additionalPrincipal || !additionalPrincipalLimits) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Can't increase principal on a Prime loan");

        const params: IncreaseCreditTransactionParams = {
            decimals: loanExpanded.principalMetadata?.decimals ?? 0,
            loan: loanExpanded.loan.address,
            borrowParams: {
                amount: additionalPrincipal,
                expectedLoanValues: {
                    expectedApy: firstLedger.apy,
                    expectedLtv: firstLedger.ltvRatios,
                    expectedLiquidationThreshold: firstLedger.lqtRatios
                }
            }
        };

        return await send(increasePrincipal, params, {
            description: "Increasing borrow",
            mixpanelEvent: { key: TrackTransactionEvent.IncreasePrincipal, params }
        });
    }, [activeWallet, user, increasePrincipal, loanExpanded, additionalPrincipal, additionalPrincipalLimits, send]);

    const isLtvConstrained =
        additionalPrincipalLimits !== undefined
            ? additionalPrincipalLimits.maxAdditionalPrincipal ===
              additionalPrincipalLimits.maxAdditionalPrincipalBasedOnLtv
            : false;

    const exceedsMaxBorrow =
        !!additionalPrincipalLimits && additionalPrincipal !== undefined
            ? additionalPrincipalLimits.maxAdditionalPrincipal < additionalPrincipal
            : false;

    return (
        <>
            <TokenInput
                maxLoading={!transferCreditQuote}
                label="Amount"
                maxText="Max: "
                symbol={BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)}
                decimals={loanExpanded?.principalMetadata.decimals}
                maxAmount={additionalPrincipalLimits?.maxAdditionalPrincipal}
                value={additionalPrincipal}
                setValue={setAdditionalPrincipal}
            />
            {additionalPrincipalLimits?.maxAdditionalPrincipal === 0 ? (
                <TagTextAlert color="warning" icon="warning">
                    The lender you borrowed from doesn't have enough funds to lend you more
                </TagTextAlert>
            ) : (
                (isLtvConstrained || exceedsMaxBorrow) &&
                additionalPrincipal !== undefined &&
                additionalPrincipal > (additionalPrincipalLimits?.maxAdditionalPrincipal ?? 0) && (
                    <TagTextAlert color="warning" icon="warning">
                        {isLtvConstrained
                            ? "You do not have enough collateral to borrow this much more. Deposit more collateral first."
                            : "The lender you borrowed from doesn't have enough funds to lend this much more."}
                    </TagTextAlert>
                )
            )}
            {!!additionalPrincipal && (
                <Stats
                    loanExpanded={loanExpanded}
                    transferCreditQuote={transferCreditQuote}
                    additionalPrincipal={additionalPrincipal}
                    newApy={newApy}
                />
            )}
            <AppButton
                isTransaction
                disabled={
                    !transferCreditQuote ||
                    !additionalPrincipal ||
                    !additionalPrincipalLimits?.maxAdditionalPrincipal ||
                    additionalPrincipal > additionalPrincipalLimits.maxAdditionalPrincipal
                }
                asyncCta={{ onClickWithResult: submit }}
                fullWidth
            >
                Borrow more
            </AppButton>
        </>
    );
}

type IncreasePrincipalStatsProps = {
    loanExpanded: AbfLoanExpanded | undefined;
    transferCreditQuote: IncreasePrincipalQuote | undefined;
    additionalPrincipal: number | undefined;
    newApy: number | undefined;
};

function Stats(props: IncreasePrincipalStatsProps) {
    const { loanExpanded, newApy } = props;
    const newInterest = useMemo(() => getNewInterest(props), [props]);
    const { health: originalHealth } = calculateLoanHealth(loanExpanded);
    const { ltv, liquidationThreshold } = calculateLoanHealth(loanExpanded);

    const firstLedger = getZcLedger(loanExpanded);

    const stats: StatProps[] = [
        {
            caption: "APY",
            value: [formatPercent(loanExpanded?.borrowerWAvgApy), formatPercent(newApy ?? newInterest?.apy)],
            hide: loanExpanded?.borrowerWAvgApy === (newApy ?? newInterest?.apy)
        },
        {
            caption: "Health",
            value: <HealthChange currentHealth={originalHealth} previousHealth={newInterest?.newHealth} />,
            hide: !originalHealth
        },

        {
            caption: "LTV",
            value: (
                <LtvChange
                    currentLtv={ltv}
                    previousLtv={newInterest?.newLtv}
                    liquidationThreshold={liquidationThreshold}
                />
            ),
            hide: !ltv || !newInterest?.newLtv || !liquidationThreshold
        },
        {
            caption: "Borrowed",
            value: [firstLedger?.principalDue, newInterest?.totalPrincipal],
            hide: !firstLedger?.ledgerDebt,
            symbol: BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)
        }
    ];

    return <StatColumn loading={!newInterest} stats={stats} />;
}

function getMaxAdditionalPrincipal({ loanExpanded, transferCreditQuote }: Omit<IncreasePrincipalStatsProps, "newApy">) {
    if (!loanExpanded?.principalMetadata || !transferCreditQuote) {
        return undefined;
    }

    const collateralUsd = getLoanCollateralUsdValue(loanExpanded);

    const maxBorrowUsd = collateralUsd * transferCreditQuote.ltv;
    const maxTotalPrincipal = roundDownToDecimals(
        maxBorrowUsd / (loanExpanded.principalUsdPrice ?? 0),
        loanExpanded.principalMetadata.decimals
    );
    const firstLedger = getZcLedger(loanExpanded);
    const maxAdditionalPrincipalBasedOnLtv = Math.max(maxTotalPrincipal - (firstLedger?.ledgerDebt.total ?? 0), 0);
    const maxAdditionalPrincipal = Math.min(maxAdditionalPrincipalBasedOnLtv, transferCreditQuote.amountLeft);

    return { maxAdditionalPrincipal, maxAdditionalPrincipalBasedOnLtv };
}

function getNewInterest({ loanExpanded, additionalPrincipal, transferCreditQuote }: IncreasePrincipalStatsProps) {
    if (!loanExpanded || !transferCreditQuote || additionalPrincipal === undefined) return undefined;

    const firstLedger = getZcLedger(loanExpanded);
    const loanDuration = firstLedger?.endTime ?? 0;

    const now = getUnixTs();

    const accruedInterest = getSimpleInterest({
        subtractPrincipal: true,
        principal: firstLedger?.principalDue ?? 0,
        apy: loanExpanded.borrowerWAvgApy,
        loanDuration: now - loanExpanded.loan.startTime
    });

    const timeRemaining = (firstLedger?.endTime ?? 0) + loanExpanded.loan.startTime - now;
    const remainingInterest = getSimpleInterest({
        principal: firstLedger?.principalDue ?? 0,
        apy: loanExpanded.borrowerWAvgApy,
        subtractPrincipal: true,
        loanDuration: timeRemaining
    });

    const loanEndTime = loanExpanded.loan.startTime + loanDuration;
    const newInterest = getSimpleInterest({
        principal: additionalPrincipal,
        subtractPrincipal: true,
        apy: transferCreditQuote.apy,
        loanDuration: loanEndTime - now
    });

    const totalPrincipal = (firstLedger?.ledgerDebt?.total ?? 0) + additionalPrincipal;
    const totalLoanValue = totalPrincipal + accruedInterest;
    const totalRemainingInterest = newInterest + remainingInterest;

    const apy =
        transferCreditQuote.apy === loanExpanded.borrowerWAvgApy
            ? loanExpanded.borrowerWAvgApy
            : calculateApy({
                  principalAmount: totalLoanValue,
                  repaymentAmount: totalRemainingInterest + totalLoanValue,
                  durationInSeconds: timeRemaining
              });

    const principalUsd = bsMath.mul(totalPrincipal, loanExpanded?.principalUsdPrice) ?? 0;
    const loanCollateralValue = getLoanCollateralUsdValue(loanExpanded);
    const newHealth = calculateHealthRatio(principalUsd, loanCollateralValue, transferCreditQuote.liquidationThreshold);

    const newLtv = principalUsd / loanCollateralValue;

    return { apy, newHealth, totalPrincipal, newLtv };
}

function useIncreasePrincipalQuote(loanExpanded: AbfLoanExpanded | undefined) {
    const { data } = useIncreasePrincipalQuoteQuery(loanExpanded?.loan.address ?? skipToken, {
        skip: !loanExpanded
    });

    return useMemo((): IncreasePrincipalQuote | undefined => {
        if (!data || !loanExpanded) return undefined;
        const decimals = loanExpanded.principalMetadata?.decimals ?? 0;
        return {
            ...data,
            apy: bpsToUiDecimals(data.apy),
            amountLeft: lamportsToUiAmount(data.amountLeft, decimals),
            ltv: bpsToUiDecimals(data.ltv),
            liquidationThreshold: bpsToUiDecimals(data.liquidationThreshold)
        };
    }, [data, loanExpanded]);
}

function calculateIncreaseBorrowApy(
    loanExpanded: AbfLoanExpanded | undefined,
    ledger: LedgerExpanded | undefined,
    additionalPrincipal: number | undefined,
    strategies: StrategyExpanded[] | undefined
) {
    if (!loanExpanded || !additionalPrincipal || !strategies || !ledger) return undefined;

    const collateral = getZcLoanCollateral(loanExpanded);
    if (!collateral) return undefined;

    const { principalOutstanding, interestOutstanding } = summarizeLedgerAccount(ledger);
    const strategy = strategies.find((s) => s.strategy.address === ledger.strategy) ?? undefined;
    if (!strategy) return undefined;

    const totalDuration = ledger.endTime - ledger.startTime;

    // Requires current loan duration to be before end time
    const remainingDuration = getUnixTs() < ledger.endTime ? totalDuration - (getUnixTs() - ledger.startTime) : 0;

    const currentLendVaultDurationApys = strategy.terms[collateral.loanCollateral.metadata.mint];

    const ledgerStrategyDuration: StrategyDuration = {
        duration: ledger.duration,
        durationType: ledger.durationType
    };

    const currentStrategyApy = findStrategyDurationApy(currentLendVaultDurationApys, ledgerStrategyDuration);

    const additionalInterest = getSimpleInterest({
        principal: additionalPrincipal,
        apy: currentStrategyApy,
        loanDuration: remainingDuration,
        subtractPrincipal: true
    });

    const newTotalOutstanding = interestOutstanding + principalOutstanding + additionalPrincipal + additionalInterest;

    const newApy = calculateApy({
        principalAmount: additionalPrincipal + principalOutstanding,
        repaymentAmount: newTotalOutstanding,
        durationInSeconds: totalDuration
    });

    return newApy;
}

function findStrategyDurationApy(durationApys: [StrategyDuration, number][], targetDuration: StrategyDuration): number {
    return (
        durationApys.find(
            ([duration]) => serializeStrategyDuration(duration) === serializeStrategyDuration(targetDuration)
        )?.[1] ?? 0
    );
}
