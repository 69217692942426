import { useCallback, useMemo } from "react";

import {
    ExternalYieldInfo,
    LoopVaultInfo,
    UserLoopInfo,
    MarketInformationLtvInfo,
    MarketInformationAssetData,
    TokenListMetadata,
    StrategyDuration,
    WhirlpoolPositionExpanded,
    LedgerValueTuple
} from "@bridgesplit/abf-sdk";
import {
    DISABLED_APY,
    TIME,
    USDC_MINT,
    USDC_SYMBOL,
    bpsToUiDecimals,
    getUnixTs,
    lamportsToUiAmount,
    percentUiToDecimals
} from "@bridgesplit/utils";

import {
    BestQuote,
    PrincipalMarketStrategyStats,
    StrategyInfo,
    MarketPrincipalStats,
    BorrowCap,
    OrderbookQuote,
    LoopExpanded,
    MaxQuote,
    LendingVaultInfo,
    ParsedLendingVault,
    ParsedTimeLock,
    VaultUpdateActionType,
    ParsedLtvData,
    VaultLtvInfos,
    LendingVaultAllocation,
    ParsedCollateralTerms,
    MarketInformationWithLtvInfoResponse,
    ParsedMarketInformation,
    LendingStrategy,
    StrategyTerms,
    StrategyInfoExpanded,
    LendingStrategyExpanded,
    LoanCollateral,
    ParsedUserVaultStake,
    AssetTypeIdentifier,
    LedgerExpanded,
    LoanCollateralExpanded,
    ParsedMarketInformationInitArgs,
    VaultLtvInfo,
    LoanEventActionType,
    LiquidateEventType,
    LoanMatrixEvent,
    ParsedLoanEventExpanded,
    LoanInfoResponse,
    ParsedLoanEvent,
    LiquidationType,
    LoanCollateralInfoExpanded,
    LendingVaultMetadata,
    LendingVaultMetadataExpanded
} from "../types";
import { useTokenListMetadataByMints, useTokenListQuery } from "../reducers";
import { BsMetaUtil } from "./metadata";
import { serializeStrategyDuration } from "./strategies";
import { isLiquidateEvent, isRepayEvent } from "./loan";

type GetDecimals = (mint: string) => number;
type GetMetadata = (mint: string) => TokenListMetadata | undefined;
type MintsInput = (string | undefined)[] | undefined;

// use a hook to allow a later replacement with an api call to a token list
export function useAbfTypesToUiConverter(mints: MintsInput) {
    const { getMetadata, isLoading } = useTokenListMetadataByMints(mints);

    const getDecimals = (mint: string | undefined) => getMetadata(mint)?.decimals ?? 0;
    return {
        // Utils
        getMetadata,
        getDecimals,
        tokensLoading: isLoading,

        // Loans
        convertLoanEvents: (loan: LoanInfoResponse, matrixUpdates: LoanMatrixEvent[]) =>
            convertLoanEvents(loan, getMetadata, matrixUpdates),

        convertLoanCollateralInfo: (
            collateral: LoanCollateral,
            usdPrice: number | undefined,
            positionToWhirlpool: Map<string, WhirlpoolPositionExpanded> | undefined,
            metadata: TokenListMetadata
        ) => convertLoanCollateralInfo(collateral, usdPrice, positionToWhirlpool, metadata),

        convertLoanLedgerExpanded: (ledger: LedgerExpanded, principalMetadata: TokenListMetadata) =>
            convertLoanLedgerExpanded(ledger, principalMetadata),

        // Strategies
        convertStrategyInfo: (strategy: StrategyInfo) => convertStrategyInfo(strategy, getDecimals),
        convertStrategyStats: (mint: string, stats: PrincipalMarketStrategyStats) =>
            convertStrategyStats(mint, stats, getDecimals),
        convertStrategyTerms: (terms: StrategyTerms) => convertStrategyTerms(terms),

        // Loops
        convertLoopVault: (loopVault: LoopVaultInfo) => convertLoopVault(loopVault, getDecimals),

        // Lending vaults
        convertLendingVaultInfo: (vaultInfo: LendingVaultInfo) => convertLendingVaultInfo(vaultInfo, getDecimals),
        convertLendingVaultLtvInfo: (
            vaultInfo: LendingVaultInfo,
            marketInfo: MarketInformationWithLtvInfoResponse | undefined,
            strategyDurations: StrategyDuration[] | undefined,
            getMetadata: GetMetadata
        ) => convertLendingVaultLtvInfo(vaultInfo, marketInfo, strategyDurations, getMetadata),
        convertLendingVaultAllocation,

        // User portfolio
        convertUserLoopInfo,
        convertUserVaultStake: (stake: ParsedUserVaultStake, principalMint: string) =>
            convertUserVaultStake(stake, principalMint, getDecimals),

        // Quotes & Markets
        convertOfferQuote: <T extends MaxQuote | BestQuote>(quote: T, principalMint: string) =>
            convertOfferQuote(quote, principalMint, getDecimals),
        convertOrderbookQuote: (quote: OrderbookQuote, principalMint: string) =>
            convertOrderbookQuote(quote, principalMint, getDecimals),
        convertBorrowCap: (cap: BorrowCap) => convertBorrowCap(cap, getDecimals),
        convertMarketPrincipalStats: (mint: string, stats: MarketPrincipalStats) =>
            convertMarketPrincipalStats(mint, stats, getDecimals),
        // Market information
        convertMarketInformationResponseWithLtvInfo,
        convertParsedMarketInformation
    };
}

export function useBsPrincipalTokens() {
    const { data, ...query } = useTokenListQuery();
    const tokens = data?.filter((d) => !!d.isPrincipal);

    // mark errors as loading to prevent infinite loads
    const isLoading = query.isError || query.isLoading;

    const mintToToken = useMemo(() => {
        if (!data || isLoading) return undefined;
        return new Map(data.map((t) => [t.mint, t]));
    }, [isLoading, data]);

    const getMetadata = useCallback(
        (mint: string | undefined) => {
            if (!mint || !mintToToken || isLoading) return undefined;
            return mintToToken.get(mint);
        },
        [isLoading, mintToToken]
    );

    const isPrincipalMint = useCallback(
        (mint: string | undefined) => {
            if (!mint || isLoading) return false;
            return !!mintToToken?.get(mint)?.isPrincipal;
        },
        [isLoading, mintToToken]
    );

    const options = useMemo(() => {
        if (!tokens) return [{ value: USDC_MINT, label: USDC_SYMBOL }];
        return tokens.map((t) => ({ value: t.mint, label: BsMetaUtil.getSymbol(t) }));
    }, [tokens]);

    return { getMetadata, isPrincipalMint, tokens, options, isLoading };
}

function groupEventsByEventTxn(events: ParsedLoanEvent[]): Map<string, ParsedLoanEvent[]> {
    const eventsByTxn = new Map<string, ParsedLoanEvent[]>();

    events.forEach((event) => {
        const txnEvents = eventsByTxn.get(event.eventTxn) || [];
        txnEvents.push(event);
        eventsByTxn.set(event.eventTxn, txnEvents);
    });

    return eventsByTxn;
}

function mergeLiquidateAndRepayEvents(
    liquidateEvent: ParsedLoanEvent,
    repayEvent: ParsedLoanEvent,
    matrixUpdates: LoanMatrixEvent[],
    getMetadata: GetMetadata
): ParsedLoanEventExpanded {
    const { postRepaymentPrincipalLedgerState } = repayEvent.actionMetadata[LoanEventActionType.RepayPrincipal];

    const liquidateMetadata = liquidateEvent.actionMetadata[LoanEventActionType.Liquidate];
    const matrixUpdateForEvent = matrixUpdates.filter((update) => update.txnSignature === liquidateEvent.eventTxn);

    const collateralTransfersExpanded = liquidateMetadata.collateralTransfers.map((transfer) => {
        const { assetMint, amount, toLiquidator, price } = transfer;
        const liquidateEventType = toLiquidator
            ? LiquidateEventType.LiquidatedCollateral
            : LiquidateEventType.CollateralReturned;
        return { assetMint, amount, toLiquidator, liquidateEventType, price };
    });

    const fullLiquidation =
        postRepaymentPrincipalLedgerState.principalDue === postRepaymentPrincipalLedgerState.principalRepaid &&
        postRepaymentPrincipalLedgerState.interestDue === postRepaymentPrincipalLedgerState.interestRepaid;

    const mergedLiquidateEvent = {
        ...liquidateEvent,
        eventAssetMetadata: getMetadata(liquidateEvent.assetIdentifier),
        matrixSnapshots: matrixUpdateForEvent,
        actionMetadata: {
            ...liquidateEvent.actionMetadata,
            [LoanEventActionType.Liquidate]: {
                ledgerIndex: liquidateMetadata.ledgerIndex,
                isTimeBasedLiquidation: liquidateMetadata.isTimeBasedLiquidation,
                liquidationFee: liquidateMetadata.liquidationFee,
                collateralTransfers: collateralTransfersExpanded,
                liquidationType: fullLiquidation ? LiquidationType.FullLiquidation : LiquidationType.PartialLiquidation,
                repaymentEventMetadata: {
                    preRepaymentPrincipalLedgerState:
                        repayEvent.actionMetadata[LoanEventActionType.RepayPrincipal].preRepaymentPrincipalLedgerState,
                    postRepaymentPrincipalLedgerState:
                        repayEvent.actionMetadata[LoanEventActionType.RepayPrincipal].postRepaymentPrincipalLedgerState
                }
            }
        }
    };
    return mergedLiquidateEvent;
}

function convertLoanEvents(
    loan: LoanInfoResponse,
    getMetadata: GetMetadata,
    matrixUpdates: LoanMatrixEvent[]
): ParsedLoanEventExpanded[] {
    const events = loan.events;
    const eventsByTxn = groupEventsByEventTxn(events);

    const convertedEvents: ParsedLoanEventExpanded[] = [];

    for (const txnEvents of eventsByTxn.values()) {
        const repayEvent = txnEvents.find((e) => isRepayEvent(e.action));
        const liquidateEvent = txnEvents.find((e) => isLiquidateEvent(e.action));

        // if there is a repay and a liquidate event with the same transaction then we want to group
        if (liquidateEvent && repayEvent) {
            const mergedLiquidateAndRepayEvent = mergeLiquidateAndRepayEvents(
                liquidateEvent,
                repayEvent,
                matrixUpdates,
                getMetadata
            );
            convertedEvents.push(mergedLiquidateAndRepayEvent);
        } else {
            convertedEvents.push(
                ...txnEvents.map((event) => ({
                    ...event,
                    eventAssetMetadata: getMetadata(event.assetIdentifier),
                    matrixSnapshots: matrixUpdates.filter((update) => update.txnSignature === event.eventTxn)
                }))
            );
        }
    }

    return convertedEvents;
}

function convertLoanCollateralInfo(
    collateral: LoanCollateral,
    usdPrice: number | undefined,
    positionToWhirlpool: Map<string, WhirlpoolPositionExpanded> | undefined,
    metadata: TokenListMetadata
): LoanCollateralInfoExpanded {
    const whirlpool = positionToWhirlpool?.get(collateral.assetMint);

    let updatedUsdPrice = usdPrice;
    const loanCollateral: LoanCollateral = {
        ...collateral,
        assetType: collateral.assetType as AssetTypeIdentifier,
        amount: lamportsToUiAmount(collateral.amount, metadata.decimals)
    };

    if (collateral.assetType === AssetTypeIdentifier.OrcaPosition) {
        updatedUsdPrice = whirlpool?.totalPrice;
    }

    const loanCollateralExpanded: LoanCollateralExpanded = {
        ...loanCollateral,
        metadata,
        usdPrice: updatedUsdPrice,
        whirlpoolPosition: whirlpool || undefined
    };

    return { loanCollateral: loanCollateralExpanded };
}

function convertLoanLedgerExpanded(rawLedger: LedgerExpanded, principalMetadata: TokenListMetadata): LedgerExpanded {
    const ledgerExpanded: LedgerExpanded = {
        ...rawLedger,
        principalDue: lamportsToUiAmount(rawLedger.principalDue, principalMetadata.decimals),
        principalRepaid: lamportsToUiAmount(rawLedger.principalRepaid, principalMetadata.decimals),
        interestDue: lamportsToUiAmount(rawLedger.interestDue, principalMetadata.decimals),
        interestRepaid: lamportsToUiAmount(rawLedger.interestRepaid, principalMetadata.decimals),
        apy: bpsToUiDecimals(rawLedger.apy),
        weights: rawLedger.weights.map((w) => bpsToUiDecimals(w)) as LedgerValueTuple,
        ltvRatios: rawLedger.ltvRatios.map((l) => bpsToUiDecimals(l)) as LedgerValueTuple,
        lqtRatios: rawLedger.lqtRatios.map((l) => bpsToUiDecimals(l)) as LedgerValueTuple,
        ledgerDebt: {
            interestAccrued: lamportsToUiAmount(rawLedger.ledgerDebt.interestAccrued, principalMetadata.decimals),
            total: lamportsToUiAmount(rawLedger.ledgerDebt.total, principalMetadata.decimals)
        }
    };

    return ledgerExpanded;
}

function convertStrategyInfo(
    { terms, strategy, externalYieldInfo }: StrategyInfo,
    getDecimals: GetDecimals
): StrategyInfoExpanded {
    const decimals = getDecimals(strategy.principalMint);

    const convertedStrategy = convertStrategy(strategy, decimals);
    const convertedTerms = convertStrategyTerms(terms);

    return {
        strategy: convertedStrategy,
        externalYieldInfo: externalYieldInfo
            ? convertExternalYieldInfo(externalYieldInfo, decimals)
            : externalYieldInfo,
        terms: convertedTerms
    };
}

function convertStrategy(strategy: LendingStrategy, decimals: number): LendingStrategyExpanded {
    return {
        ...strategy,
        // Convert token amounts using lamportsToUiAmount
        tokenBalance: lamportsToUiAmount(strategy.tokenBalance, decimals),
        originationCap: lamportsToUiAmount(strategy.originationCap, decimals),
        externalYieldAmount: lamportsToUiAmount(strategy.externalYieldAmount, decimals),
        currentDeployedAmount: lamportsToUiAmount(strategy.currentDeployedAmount, decimals),
        outstandingInterestAmount: lamportsToUiAmount(strategy.outstandingInterestAmount, decimals),
        feeClaimable: lamportsToUiAmount(strategy.feeClaimable, decimals),
        cumulativePrincipalOriginated: lamportsToUiAmount(strategy.cumulativePrincipalOriginated, decimals),
        cumulativeInterestAccrued: lamportsToUiAmount(strategy.cumulativeInterestAccrued, decimals),

        // Convert basis points using bpsToUiDecimals
        liquidityBuffer: bpsToUiDecimals(strategy.liquidityBuffer),
        interestFee: bpsToUiDecimals(strategy.interestFee),
        principalFee: bpsToUiDecimals(strategy.principalFee),
        originationFee: bpsToUiDecimals(strategy.originationFee),

        // Keep non-numeric values and counts as is
        id: strategy.id,
        address: strategy.address,
        version: strategy.version,
        nonce: strategy.nonce,
        bump: strategy.bump,
        principalMint: strategy.principalMint,
        lender: strategy.lender,
        externalYieldSource: strategy.externalYieldSource,
        interestPerSecond: lamportsToUiAmount(strategy.interestPerSecond, decimals),
        lastAccruedTimestamp: strategy.lastAccruedTimestamp,
        cumulativeLoanCount: strategy.cumulativeLoanCount,
        activeLoanCount: strategy.activeLoanCount,
        marketInformation: strategy.marketInformation,
        closed: strategy.closed,
        lastInteractedTime: strategy.lastInteractedTime,
        lastInteractedTxn: strategy.lastInteractedTxn,
        createdAt: strategy.createdAt,

        // Strategy info expanded
        fixedApy: 0, // place holder set right after all information is available
        wAvgApy: 0 // place holder set right after all information is available
    };
}

export function calculateWAvgApy(strategy: StrategyInfoExpanded): number {
    const externalYieldInfo = strategy.externalYieldInfo;
    const strategyCurrentDeployed = strategy.strategy.currentDeployedAmount;

    const totalDeployed = strategyCurrentDeployed;
    const externalBalance = externalYieldInfo?.balance ?? 0;

    const totalBalance = totalDeployed + externalBalance + strategy.strategy.tokenBalance;

    if (totalBalance === 0) return 0;

    const fixedWeight = totalDeployed / totalBalance;
    const externalWeight = externalBalance / totalBalance;

    const fixedApyWeighted = strategy.strategy.fixedApy * fixedWeight; // fixed apy is already minus fee

    const externalApyWeighted = externalYieldInfo ? externalYieldInfo.apy * externalWeight : 0;

    const externalApyMinusFee = externalApyWeighted * (1 - strategy.strategy.interestFee);
    const totalApyMinusFee = fixedApyWeighted + externalApyMinusFee;
    return totalApyMinusFee;
}

function convertBorrowCap({ principalMint, perLoan, global }: BorrowCap, getDecimals: GetDecimals): BorrowCap {
    const decimals = getDecimals(principalMint);
    return {
        principalMint,
        perLoan: lamportsToUiAmount(perLoan, decimals),
        global: lamportsToUiAmount(global, decimals)
    };
}

function convertMarketPrincipalStats(
    mint: string,
    stats: MarketPrincipalStats,
    getDecimals: GetDecimals
): MarketPrincipalStats {
    const decimals = getDecimals(mint);
    return {
        principalOriginated: lamportsToUiAmount(stats.principalOriginated, decimals),
        principalUtilized: lamportsToUiAmount(stats.principalUtilized, decimals)
    };
}

function convertStrategyStats(
    mint: string,
    stats: PrincipalMarketStrategyStats,
    getDecimals: GetDecimals
): PrincipalMarketStrategyStats {
    const decimals = getDecimals(mint);
    return {
        totalDeposits: lamportsToUiAmount(stats.totalDeposits, decimals),
        durationToMinApy: stats.durationToMinApy.map((s) => ({ ...s, apy: bpsToUiDecimals(s.apy) }))
    };
}

function convertOfferQuote<T extends MaxQuote | BestQuote>(
    quote: T,
    principalMint: string,
    getDecimals: GetDecimals
): T {
    const decimals = getDecimals(principalMint);
    return {
        ...quote,
        apy: bpsToUiDecimals(quote.apy),
        ltv: bpsToUiDecimals(quote.ltv),
        liquidationThreshold: bpsToUiDecimals(quote.liquidationThreshold),
        principalAvailable: lamportsToUiAmount(quote.principalAvailable, decimals)
    };
}

function convertOrderbookQuote(quote: OrderbookQuote, principalMint: string, getDecimals: GetDecimals): OrderbookQuote {
    const decimals = getDecimals(principalMint);
    return {
        ...quote,
        apy: bpsToUiDecimals(quote.apy),
        maxPrincipalAvailable: lamportsToUiAmount(quote.maxPrincipalAvailable, decimals),
        sumPrincipalAvailable: lamportsToUiAmount(quote.sumPrincipalAvailable, decimals)
    };
}

function convertMarketInformationLtvInfo(marketInformationLtvInfo: MarketInformationLtvInfo): MarketInformationLtvInfo {
    return {
        ...marketInformationLtvInfo,
        ltv: bpsToUiDecimals(marketInformationLtvInfo.ltv),
        liquidationThreshold: bpsToUiDecimals(marketInformationLtvInfo.liquidationThreshold)
    };
}

function convertMarketInformationAssetData(assetData: MarketInformationAssetData): MarketInformationAssetData {
    return {
        ...assetData,
        // Convert basis points values
        ltv: bpsToUiDecimals(assetData.ltv),
        liquidationThreshold: bpsToUiDecimals(assetData.liquidationThreshold)
    };
}

function convertParsedMarketInformation(parsedMarketInfo: ParsedMarketInformation): ParsedMarketInformation {
    return {
        marketInformation: parsedMarketInfo.marketInformation,
        marketInformationAssetInfo: parsedMarketInfo.marketInformationAssetInfo.map(convertMarketInformationAssetData)
    };
}

function convertMarketInformationResponseWithLtvInfo(
    marketInformationResponseWithLtvInfo: MarketInformationWithLtvInfoResponse
): MarketInformationWithLtvInfoResponse {
    return {
        ltvInfo: marketInformationResponseWithLtvInfo.ltvInfo.map(convertMarketInformationLtvInfo),
        marketInformation: marketInformationResponseWithLtvInfo.marketInformation
    };
}

function convertExternalYieldInfo(
    externalYieldInfo: ExternalYieldInfo,
    decimals: number | undefined
): ExternalYieldInfo {
    return {
        ...externalYieldInfo,
        apy: bpsToUiDecimals(externalYieldInfo.apy),
        balance: lamportsToUiAmount(externalYieldInfo.balance, decimals)
    };
}

function convertLoopVault(loopVault: LoopVaultInfo, getDecimals: GetDecimals): LoopVaultInfo {
    return {
        ...loopVault,
        collateralApyPct: percentUiToDecimals(loopVault.collateralApyPct),
        maxLeveragedApyPct: percentUiToDecimals(loopVault.maxLeveragedApyPct),
        collateralDeposited: lamportsToUiAmount(loopVault.collateralDeposited, getDecimals(loopVault.collateralMint)),
        principalAmountAvailable: lamportsToUiAmount(
            loopVault.principalAmountAvailable,
            getDecimals(loopVault.principalMint)
        )
    };
}

function convertUserLoopInfo(userLoopInfo: UserLoopInfo, loopExpanded: LoopExpanded): UserLoopInfo {
    const collateralDecimals = loopExpanded.collateralToken.decimals;
    return {
        ...userLoopInfo,
        initialCollateralAmount: lamportsToUiAmount(userLoopInfo.initialCollateralAmount, collateralDecimals),
        totalCollateralDepositedAmount: lamportsToUiAmount(
            userLoopInfo.totalCollateralDepositedAmount,
            collateralDecimals
        ),
        additionalCollateralDepositedAmount: lamportsToUiAmount(
            userLoopInfo.additionalCollateralDepositedAmount,
            collateralDecimals
        ),
        netApy: userLoopInfo.netApy
    };
}

function convertParsedLendingVault(vault: ParsedLendingVault, decimals: number): ParsedLendingVault {
    return {
        ...vault,
        cumulativePrincipalDeposited: lamportsToUiAmount(vault.cumulativePrincipalDeposited, decimals),
        lpSupply: lamportsToUiAmount(vault.lpSupply, decimals),
        maxEarlyUnstakeFee: bpsToUiDecimals(vault.maxEarlyUnstakeFee)
    };
}

function convertStrategyTerms(data: StrategyTerms): StrategyTerms {
    const parsedTerms: Record<string, [StrategyDuration, number][]> = {};
    for (const [mint, durations] of Object.entries(data)) {
        parsedTerms[mint] = durations.map(([duration, apy]) => [duration, bpsToUiDecimals(apy)]);
    }
    return parsedTerms;
}

function convertVaultTerms<T extends ParsedLtvData | ParsedCollateralTerms | ParsedMarketInformationInitArgs>(
    data: T
): T {
    if ("terms" in data) {
        const parsedTerms: Record<string, number> = {};
        for (const [strategyDuration, term] of Object.entries(data.terms)) {
            const key = serializeStrategyDuration(JSON.parse(strategyDuration));
            parsedTerms[key] = bpsToUiDecimals(term);
        }
        return {
            ...data,
            terms: parsedTerms
        } as T;
    }
    const base: T = {
        ...data,
        ltv: bpsToUiDecimals(data.ltv),
        liquidationThreshold: bpsToUiDecimals(data.liquidationThreshold)
    };

    return base;
}
function convertPendingTerms(pendingTerms: ParsedTimeLock, decimals: number): ParsedTimeLock {
    let addCollateralUpdate = pendingTerms.update[VaultUpdateActionType.AddCollateral];
    let updateLtvUpdate = pendingTerms.update[VaultUpdateActionType.UpdateLtv];
    let updateStrategySettings = pendingTerms.update[VaultUpdateActionType.UpdateStrategySettings];

    if (addCollateralUpdate) {
        let { collateralTerms, updateMarketInformationParams } = addCollateralUpdate;
        collateralTerms = convertVaultTerms(collateralTerms);

        updateMarketInformationParams = {
            ...updateMarketInformationParams,
            ...convertVaultTerms(updateMarketInformationParams)
        };
        addCollateralUpdate = {
            collateralTerms,
            updateMarketInformationParams
        };
    }
    if (updateLtvUpdate) {
        updateLtvUpdate = {
            ...updateLtvUpdate,
            ltv: bpsToUiDecimals(updateLtvUpdate.ltv),
            liquidationThreshold: bpsToUiDecimals(updateLtvUpdate.liquidationThreshold)
        };
    }

    if (updateStrategySettings) {
        updateStrategySettings = {
            ...updateStrategySettings,
            originationsEnabled: updateStrategySettings.originationsEnabled,
            originationCap: lamportsToUiAmount(updateStrategySettings.originationCap, decimals),
            originationFee: bpsToUiDecimals(updateStrategySettings.originationFee),
            interestFee: bpsToUiDecimals(updateStrategySettings.interestFee),
            principalFee: bpsToUiDecimals(updateStrategySettings.principalFee),
            liquidityBuffer: bpsToUiDecimals(updateStrategySettings.liquidityBuffer)
        };
    }

    return {
        ...pendingTerms,
        update: {
            ...pendingTerms.update,
            [VaultUpdateActionType.AddCollateral]: addCollateralUpdate,
            [VaultUpdateActionType.UpdateLtv]: updateLtvUpdate,
            [VaultUpdateActionType.UpdateStrategySettings]: updateStrategySettings
        }
    };
}

function convertUserVaultStake(
    stake: ParsedUserVaultStake,
    principalMint: string,
    getDecimals: GetDecimals
): ParsedUserVaultStake {
    return {
        ...stake,
        amountStaked: lamportsToUiAmount(stake.amountStaked, getDecimals(principalMint))
    };
}

function convertLendingVaultMetadata(
    metadata: LendingVaultMetadata | undefined,
    decimals: number
): LendingVaultMetadataExpanded | undefined {
    if (!metadata) return undefined;

    return {
        ...metadata,
        depositCap: metadata?.depositCap ? lamportsToUiAmount(metadata.depositCap, decimals) : undefined,
        isGenesisVault: metadata.tags?.includes("genesis") ?? false
    };
}

function convertLendingVaultInfo(lendingVault: LendingVaultInfo, getDecimals: GetDecimals): LendingVaultInfo {
    const decimals = getDecimals(lendingVault.vault.principalMint);

    const vault = convertParsedLendingVault(lendingVault.vault, decimals);

    const pendingTerms = lendingVault.pendingTerms.map((pendingTerms) => convertPendingTerms(pendingTerms, decimals));

    const terms = convertStrategyTerms(lendingVault.vaultStrategy.terms);

    const vaultMetadata = convertLendingVaultMetadata(lendingVault?.vaultMetadata, decimals);

    const vaultStrategy: StrategyInfoExpanded = {
        strategy: convertStrategy(lendingVault.vaultStrategy.strategy, decimals),
        terms,
        externalYieldInfo: lendingVault.vaultStrategy.externalYieldInfo
            ? convertExternalYieldInfo(lendingVault.vaultStrategy.externalYieldInfo, decimals)
            : lendingVault.vaultStrategy.externalYieldInfo
    };
    // previously set as a placeholder, we needed StrategyInfoExpanded
    vaultStrategy.strategy.fixedApy = calculateFixedStrategyApy(vaultStrategy.strategy);
    vaultStrategy.strategy.wAvgApy = calculateWAvgApy(vaultStrategy);
    return {
        vault,
        vaultMetadata,
        vaultStrategy: vaultStrategy,
        pendingTerms
    };
}

const FALLBACK_TOKEN_LIST_METADATA: TokenListMetadata = {
    mint: "",
    decimals: 0,
    isPrincipal: false,
    isCollateral: false,
    name: "",
    symbol: "",
    imageUrl: "",
    assetType: 0,
    tokenProgram: "",
    numLuts: undefined,
    tags: [],
    custodian: ""
};

function convertLendingVaultLtvInfo(
    lendingVault: LendingVaultInfo,
    marketInfo: MarketInformationWithLtvInfoResponse | undefined,
    strategyDurations: StrategyDuration[] | undefined,
    getMetadata: GetMetadata
): Record<string, VaultLtvInfos> | undefined {
    if (!marketInfo || !strategyDurations) return undefined;

    const ltvInfos: Record<string, VaultLtvInfos> = {};
    const strategyTerms = lendingVault.vaultStrategy.terms;

    for (const marketLtvInfo of marketInfo.ltvInfo) {
        const collateralMint = marketLtvInfo.assetIdentifier;
        const lendVaultTerms = strategyTerms[collateralMint];

        const durationToLtvInfo: Record<string, VaultLtvInfo> = {};

        for (const duration of strategyDurations) {
            const key = serializeStrategyDuration(duration);
            const matchingTerm = lendVaultTerms?.find(
                ([d]) => d.duration === duration.duration && d.durationType === duration.durationType
            );
            const correspondingLendVaultApy = matchingTerm ? matchingTerm[1].toString() : DISABLED_APY;

            durationToLtvInfo[key] = {
                apy: correspondingLendVaultApy,
                marketInfo: {
                    ltv: marketLtvInfo.ltv,
                    liquidationThreshold: marketLtvInfo.liquidationThreshold,
                    oracleAccount: marketLtvInfo.oracleAccount,
                    oracleType: marketLtvInfo.oracleType
                }
            };
        }

        ltvInfos[collateralMint] = {
            durationToLtvInfo,
            collateralMetadata: getMetadata(collateralMint) ?? FALLBACK_TOKEN_LIST_METADATA
        };
    }

    return ltvInfos;
}

function convertLendingVaultAllocation(
    allocation: LendingVaultAllocation,
    principalDecimals: number
): LendingVaultAllocation {
    return {
        ...allocation,
        wAvgApy: bpsToUiDecimals(allocation.wAvgApy),
        totalPrincipalDeployed: lamportsToUiAmount(allocation.totalPrincipalDeployed, principalDecimals)
    };
}

export function calculateTotalInterestAccrued(
    strategy: StrategyInfoExpanded | undefined,
    options?: {
        includeStrategyInterest?: boolean;
        includeExternalInterest?: boolean;
        includeCumulativeInterest?: boolean;
    }
): number {
    if (!strategy) return 0;

    const now = Math.round(getUnixTs());
    let totalInterest = options?.includeCumulativeInterest
        ? Math.max(strategy.strategy?.cumulativeInterestAccrued ?? 0, 0)
        : 0;

    // Calculate strategy interest if included
    if (options?.includeStrategyInterest) {
        const interestPerSecond = Math.max(strategy.strategy?.interestPerSecond ?? 0, 0);
        const timeDelta = Math.max(0, now - (strategy.strategy?.lastAccruedTimestamp ?? now));
        const strategyInterest = interestPerSecond * timeDelta;
        totalInterest += strategyInterest;
    }

    // Calculate external interest if included
    if (options?.includeExternalInterest) {
        const externalBalance = Math.max(strategy.externalYieldInfo?.balance ?? 0, 0);
        const externalYieldAmount = Math.max(strategy.strategy?.externalYieldAmount ?? 0, 0);
        const externalInterest = Math.max(externalBalance - externalYieldAmount, 0);
        totalInterest += externalInterest;
    }

    return totalInterest;
}

export function calculateFixedStrategyApy(strategy: LendingStrategy): number {
    const interestPerSecond = strategy.interestPerSecond;

    const yearlyInterest = interestPerSecond * TIME.YEAR;

    const principal = strategy.currentDeployedAmount || 1;
    const annualRate = yearlyInterest / principal;

    const fixedApyMinusFee = annualRate * (1 - strategy.interestFee);

    return fixedApyMinusFee;
}
