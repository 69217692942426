import { useCallback, useState } from "react";

import { Icon, IconButton, Row, Text, useAppPalette } from "@bridgesplit/ui";
import { Drawer, drawerClasses } from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";

import { NAV_ICON_BUTTON_SX } from "./constants";
import NavOptions from "./NavOptions";
import { useNavOptions } from "./util";

export default function MobileMenu() {
    const { paperBackground } = useAppPalette();

    const [open, setOpen] = useState(false);
    const close = useCallback(() => setOpen(false), []);
    const navOptions = useNavOptions({ isMobile: true });

    return (
        <>
            {!!navOptions.length && (
                <IconButton sx={NAV_ICON_BUTTON_SX} onClick={() => setOpen(true)} jsxIcon={<MenuOutlined />} />
            )}
            <Drawer
                anchor="left"
                open={!!open}
                sx={{
                    [`.${drawerClasses.paper}`]: { background: paperBackground, width: "70vw", maxWidth: "300px" }
                }}
                onClose={close}
            >
                <Row spaceBetween sx={{ p: 2 }}>
                    <Row spacing={1}>
                        <Text>
                            <Icon type="logo-outer" />
                        </Text>
                    </Row>

                    <IconButton border={false} icon="reject" onClick={close} />
                </Row>
                <NavOptions options={navOptions} />
            </Drawer>
        </>
    );
}
