import {
    LoopscalePointsAction,
    StrategyExpanded,
    usePortfolioRewardsUtils,
    useLoanMultipliersUtil,
    ExternalPointReward
} from "@bridgesplit/abf-react";
import {
    Column,
    FONT_SIZES,
    Icon,
    IconWithBackground,
    MARGIN_FI_LOGO,
    RewardIcon,
    RewardImage,
    Row,
    StatColumn,
    StatProps,
    StatWithIcon,
    Text,
    Tooltip,
    useAppPalette
} from "@bridgesplit/ui";
import { formatMultiplier, formatNum, formatPercent, Hidable } from "@bridgesplit/utils";
import { Divider } from "@mui/material";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { calculateStrategyUtilization } from "app/components/strategy";
import { calculateActiveStrategyDailyPoints } from "app/components/portfolio/stats";

import { ExternalRewardsDisplay, TooltipContent } from "./common";
import { useRewardsColor } from "./util";

export function RewardsStrategyApy({ strategyExpanded }: { strategyExpanded: StrategyExpanded }) {
    const { getStrategyRewards } = usePortfolioRewardsUtils();
    const { loanMultipliers } = useLoanMultipliersUtil();
    const { prime } = useAppPalette();
    const { utilization, apyWeighted: strategyApyWeighted } = calculateStrategyUtilization(strategyExpanded);
    const color = useRewardsColor(loanMultipliers, strategyExpanded.principalMetadata.mint, [
        LoopscalePointsAction.Lend,
        LoopscalePointsAction.IdleCap
    ]);

    const isIdleYield = isStrategyMarginFi(strategyExpanded);
    const externalRewards = isIdleYield ? [ExternalPointReward.MarginFi] : undefined;

    const apyFixed = strategyExpanded.strategy.fixedApy;
    const apyVariable = strategyExpanded?.externalYieldInfo?.apy;

    const { mint } = strategyExpanded.principalMetadata;
    const lendMultiplier = loanMultipliers?.[mint]?.[LoopscalePointsAction.Lend]?.multiplier;
    const idleCapMultiplier = loanMultipliers?.[mint]?.[LoopscalePointsAction.IdleCap]?.multiplier ?? 1;

    const isBoosted = color === prime;
    const strategyRewards = getStrategyRewards(strategyExpanded.strategy.address);
    const customDailyPoints = calculateActiveStrategyDailyPoints(strategyRewards);

    return (
        <Tooltip
            reverseColors
            title={
                <StrategyRateAndRewardTooltip
                    apyFixed={apyFixed}
                    utilization={utilization}
                    lendMultiplier={lendMultiplier}
                    allocation={1 - utilization}
                    apyVariable={apyVariable}
                    idleMultiplier={idleCapMultiplier}
                    totalDailyRewards={customDailyPoints}
                    netApy={strategyApyWeighted}
                    isBoosted={isBoosted}
                    isIdleCapEligible={isIdleYield}
                />
            }
        >
            <Row spacing={0.5}>
                <ExternalRewardsDisplay
                    metadata={strategyExpanded.principalMetadata}
                    loopscalePointsAction={LoopscalePointsAction.Lend}
                    externalPointRewards={externalRewards}
                />
                <Text underline>{formatPercent(strategyApyWeighted)} APY</Text>
            </Row>
        </Tooltip>
    );
}

export function isStrategyMarginFi(strategyExpanded: StrategyExpanded | undefined) {
    if (!strategyExpanded) return false;
    return (
        strategyExpanded.externalYieldInfo?.externalYieldSource === ExternalYieldSource.MarginFi &&
        strategyExpanded.externalYieldInfo.balance > 0
    );
}

type StrategyRateAndRewardTooltipProps = {
    isBoosted: boolean;
    isIdleCapEligible: boolean;
} & TooltipLoansProps &
    TooltipIdleCapitalProps &
    TooltipSummaryProps;

function StrategyRateAndRewardTooltip(props: StrategyRateAndRewardTooltipProps) {
    const {
        apyFixed,
        utilization,
        lendMultiplier,
        allocation,
        apyVariable,
        idleMultiplier,
        totalDailyRewards,
        netApy,
        isBoosted,
        isIdleCapEligible
    } = props;

    return (
        <TooltipContent>
            <TooltipLoans apyFixed={apyFixed} utilization={utilization} lendMultiplier={lendMultiplier} />
            <Divider />
            {isIdleCapEligible && (
                <>
                    <TooltipIdleCapital
                        allocation={allocation}
                        apyVariable={apyVariable}
                        idleMultiplier={idleMultiplier}
                    />
                    <Divider />
                </>
            )}

            <TooltipSummary totalDailyRewards={totalDailyRewards} netApy={netApy} isBoosted={isBoosted} />
        </TooltipContent>
    );
}
interface TooltipLoansProps {
    apyFixed: number | undefined;
    utilization: number | undefined;
    lendMultiplier: number | undefined;
}

function TooltipLoans({ apyFixed, utilization, lendMultiplier }: TooltipLoansProps) {
    const { secondary } = useAppPalette();

    const stats: Hidable<StatProps>[] = [
        {
            value: formatPercent(utilization),
            caption: "Utilization"
        },
        {
            value: formatMultiplier(lendMultiplier),
            caption: "Lend boost",
            hide: !lendMultiplier
        },
        {
            value: formatPercent(apyFixed),
            caption: "APY (fixed rate)"
        }
    ].filter((s) => !s.hide);
    return (
        <Column spacing={2}>
            <Column spacing={1}>
                <Row spaceBetween>
                    <Text>Loans</Text>
                    <IconWithBackground size={FONT_SIZES.body1} sx={{ background: secondary, color: "white" }}>
                        <Icon type="logo-icon-filled" />
                    </IconWithBackground>
                </Row>
                <Row>
                    <Text color="caption" variant="caption">
                        Set low APYs to ensure high utilization and maximize your rewards
                    </Text>
                </Row>
            </Column>
            <StatColumn stats={stats} captionVariant="body1" />
        </Column>
    );
}

interface TooltipIdleCapitalProps {
    allocation: number | undefined;
    apyVariable: number | undefined;
    idleMultiplier: number | undefined;
}

function TooltipIdleCapital({ allocation, idleMultiplier, apyVariable }: TooltipIdleCapitalProps) {
    const { secondary } = useAppPalette();
    const stats: Hidable<StatProps>[] = [
        {
            value: formatPercent(allocation),
            caption: "Allocation"
        },
        {
            value: formatMultiplier(idleMultiplier),
            caption: "Idle capital boost",
            hide: !idleMultiplier
        },
        {
            value: formatPercent(apyVariable),
            caption: "APY (variable)"
        }
    ].filter((s) => !s.hide);
    return (
        <Column spacing={2}>
            <Column spacing={1}>
                <Row spaceBetween>
                    <Text>Idle capital</Text>
                    <Row spacing={1}>
                        <IconWithBackground size={FONT_SIZES.body1} sx={{ background: secondary, color: "white" }}>
                            <Icon type="logo-icon-filled" />
                        </IconWithBackground>
                        <RewardImage src={MARGIN_FI_LOGO} />
                    </Row>
                </Row>
                <Row>
                    <Text color="caption" variant="caption">
                        Earn mrgn rewards and variable rate APY while waiting for loans
                    </Text>
                </Row>
            </Column>
            <StatColumn stats={stats} captionVariant="body1" />
        </Column>
    );
}

interface TooltipSummaryProps {
    totalDailyRewards: number | undefined;
    netApy: number | undefined;
}
function TooltipSummary({ totalDailyRewards, netApy, isBoosted }: TooltipSummaryProps & { isBoosted: boolean }) {
    const stats: StatProps[] = [
        {
            value: (
                <StatWithIcon
                    value={formatNum(totalDailyRewards)}
                    icon={<RewardIcon hasMultiplier={isBoosted} />}
                    captionColor="body"
                />
            ),
            caption: "Daily rewards"
        },
        {
            value: formatPercent(netApy),
            caption: "Net APY",
            textColor: netApy && netApy > 0 ? "success" : "disabled"
        }
    ];
    return <StatColumn stats={stats} captionVariant="body1" />;
}
