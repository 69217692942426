import { TokenInputWithPrice } from "app/components/common";
import { COPY } from "app/constants";
import { Column } from "@bridgesplit/ui";
import { useActiveWallet } from "@bridgesplit/abf-react";

import { useVaultWithdrawContext } from "./VaultWithdrawContext";
import { useVaultContext } from "../VaultContext";
import { useVaultWithdrawBalance } from "./util";

export default function VaultWithdrawAmount() {
    const { form, setForm } = useVaultWithdrawContext();
    const { vaultExpanded } = useVaultContext();
    const { BalanceDisplay } = useVaultWithdrawBalance();
    const { activeWallet } = useActiveWallet();

    return (
        <Column spacing={2}>
            <TokenInputWithPrice
                label={`You ${COPY.VAULT_WITHDRAW_CTA.toLowerCase()}`}
                value={form.amountPrincipal}
                setValue={(amount: number | undefined) => {
                    if (form.amountPrincipal === amount) return;
                    setForm({
                        amountPrincipal: amount,
                        isMaxWithdraw: false
                    });
                }}
                metadata={vaultExpanded?.principalMetadata}
                selectToken={null}
                belowInput={<BalanceDisplay maxText={"Available"} hideRefresh={!activeWallet} />}
            />
        </Column>
    );
}
