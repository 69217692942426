import { filterTruthy } from "@bridgesplit/utils";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";

import { useTokenListMetadataByMints } from "../reducers";
import { AbfUserWithPermissionsAndWallets, LoanEventActionType, ParsedLoanEventExpanded } from "../types";

export type TokenListExpandedMeta<T> = T & {
    metadata: TokenListMetadata;
};

export function useExpandedTokenListMetadata<T>(
    data: T[] | undefined,
    metadataKey: (keyof T & string) | ((t: T) => string)
): TokenListExpandedMeta<T>[] | undefined {
    const mappedMints =
        typeof metadataKey === "string"
            ? (data?.map((d) => d[metadataKey]).filter((d) => typeof d === "string") as string[] | undefined)
            : data?.map((t) => metadataKey(t));

    const { getMetadata, isLoading: metaLoading } = useTokenListMetadataByMints(mappedMints);

    const dataWithMetadata = data
        ?.map((d) => {
            const metadata =
                typeof metadataKey === "string" ? getMetadata(d[metadataKey] as string) : getMetadata(metadataKey(d));

            if (!metadata) return null;

            return {
                ...d,
                metadata
            };
        })
        .filter(filterTruthy) as TokenListExpandedMeta<T>[] | undefined;

    if (metaLoading) return undefined;
    return dataWithMetadata;
}

export function createDefaultUser(walletPubkey: string | undefined) {
    const defaultUser: AbfUserWithPermissionsAndWallets = {
        wallet: walletPubkey,
        beta_borrow_access: true,
        beta_lend_access: true
    };

    return defaultUser;
}

export function getPastLoanCollateralMap(events: ParsedLoanEventExpanded[]): Map<string, number> | undefined {
    const sortedEvents = events.sort((a, b) => b.eventTime - a.eventTime);
    // Get add/remove collateral events for this specific mint
    const addEvents = sortedEvents.filter(
        (event) => event.action === LoanEventActionType.AddCollateral && event.eventAssetMetadata?.mint
    );

    const removeEvents = sortedEvents.filter(
        (event) => event.action === LoanEventActionType.RemoveCollateral && event.eventAssetMetadata?.mint
    );

    if (addEvents.length === 0) {
        return undefined;
    }

    const collateralBalances = new Map<string, { amount: number; metadata: TokenListMetadata }>();

    // Step 1: Add all deposits
    for (const event of addEvents) {
        const metadata = event.eventAssetMetadata;
        if (!metadata) continue;

        const mint = metadata.mint;
        const existing = collateralBalances.get(mint);

        if (existing) {
            existing.amount += event.amount;
        } else {
            collateralBalances.set(mint, {
                amount: event.amount,
                metadata
            });
        }
    }

    // Process remove events from newest to oldest, starting from the second to remove collateral event

    for (let i = 1; i < removeEvents.length; i++) {
        const event = removeEvents[i];
        const metadata = event.eventAssetMetadata;
        if (!metadata) continue;

        const mint = metadata.mint;

        const balance = collateralBalances.get(mint);
        if (balance) {
            balance.amount -= event.amount;
            if (balance.amount <= 0) {
                collateralBalances.delete(mint);
            }
        }
    }

    // If there are no collateral balances, return undefined
    if (collateralBalances.size === 0) {
        return undefined;
    }

    // Convert to the expected return type
    const result = new Map<string, number>();
    for (const [mintKey, { amount }] of collateralBalances.entries()) {
        result.set(mintKey, amount);
    }

    return result;
}
