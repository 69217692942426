import { TokenInputWithPrice } from "app/components/common";
import { COPY } from "app/constants";

import { useVaultDepositContext } from "./VaultDepositContext";
import { useVaultContext } from "../VaultContext";
import { useDepositUpdateAmount, useVaultDepositBalance } from "./util";

export default function VaultDepositAmount() {
    const { form } = useVaultDepositContext();
    const { vaultExpanded } = useVaultContext();
    const { BalanceDisplay } = useVaultDepositBalance();
    const updateAmount = useDepositUpdateAmount();
    return (
        <TokenInputWithPrice
            label={`You ${COPY.VAULT_DEPOSIT_CTA.toLowerCase()}`}
            value={form.amount}
            setValue={updateAmount}
            metadata={vaultExpanded?.principalMetadata}
            selectToken={null}
            belowInput={<BalanceDisplay />}
        />
    );
}
