import { QuestionToggleProps } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";
import { LoopRouteType } from "@bridgesplit/abf-sdk";

import { useBaseFaq } from "./base";
import { useLoopContext } from "../../LoopContext";

export function usePerpsFaq(): QuestionToggleProps[] | undefined {
    const base = useBaseFaq();
    const { loopExpanded } = useLoopContext();
    const isShortVault = loopExpanded?.loopVault.tags?.some((tag) => tag.toLocaleLowerCase().includes("short"));
    const principalSymbol = loopExpanded?.principalToken.symbol;
    const collateralSymbol = loopExpanded?.collateralToken.symbol;
    const leverageMultiplier = loopExpanded?.loopVault.maxLeverage;
    const platform = (() => {
        switch (loopExpanded?.loopVault.routeType) {
            case LoopRouteType.Jup:
                return "Jupiter";
            case LoopRouteType.Flash:
                return "Flash Trade";
            default:
                return "";
        }
    })();
    const baseApy = formatPercent(loopExpanded?.loopVault.collateralApyPct);

    const collateral: QuestionToggleProps = {
        label: `What is ${collateralSymbol} and how does this vault earn yield?`,
        answer: [
            `${collateralSymbol} is ${platform}'s liquidity provider token. It powers ${platform}'s perps product and currently earns about ${baseApy}% APY from fees.`,
            `You'll earn yield from ${collateralSymbol}'s price increases due to earned fees or from appreciation of its underlying tokens. This vault allows you to amplify your ${collateralSymbol} exposure with up to ${leverageMultiplier}x leverage for higher potential returns.`
        ]
    };

    if (isShortVault && Array.isArray(collateral.answer)) {
        collateral.answer.push(
            `This vault is a short position, meaning you are betting that the price of ${collateralSymbol} will go down. If it does, you will earn yield from the decrease in value of the ${collateralSymbol}.`
        );
    }

    const risks: QuestionToggleProps = {
        label: "What are the risks?",
        answer: [
            `Market volatility: ${collateralSymbol}'s value can swing based on its underlying assets and ${platform}'s trading activity.`,
            `Changing rates: While our rates are fixed short-term, they do adjust. If borrowing costs outpace ${collateralSymbol} yields for an extended period, you could lose a portion of your original deposit.`,
            "Insufficient liquidity: If the amount of capital becomes limited, it may affect your ability to manage your position.",
            "Smart contract risk: As with any DeFi product, there's always some level of smart contract risk."
        ]
    };

    if (isShortVault && Array.isArray(risks.answer)) {
        risks.answer.push(
            `Principal Risk: ${principalSymbol}'s value can change with market conditions. Should ${principalSymbol}'s price outpace the price of ${collateralSymbol} then there is risk of loss.`
        );
    }

    if (!base) return undefined;
    const { intro, uniqueFeatures, management } = base;

    return [intro, collateral, risks, uniqueFeatures, management];
}
