import { StatRow, Column, Row, Text, StatProps, hideScrollbarSx } from "@bridgesplit/ui";
import { filterUndefined, formatDate, formatPercent } from "@bridgesplit/utils";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { COPY } from "app/constants";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { Divider } from "@mui/material";
import { OriginationCapStatus } from "@bridgesplit/abf-react";

import { SettingsChange } from "./type";

export function SettingsChangeStats({
    settingsChanges
}: {
    settingsChanges: { executionTimestamp: number; changes: SettingsChange }[];
}) {
    return (
        <Column spacing={1}>
            <Text variant="h4">Settings changes</Text>
            <Divider />
            {settingsChanges.map((change, index) => (
                <Row key={change.executionTimestamp} sx={{ overflowX: "auto", ...hideScrollbarSx }}>
                    <StatRow
                        sx={{ minWidth: "max-content" }}
                        loading={false}
                        captionVariant="body2"
                        captionColor="primary"
                        stats={generateStatsFromSettingsChange(change)}
                    />
                    {index !== settingsChanges.length - 1 && <Divider />}
                </Row>
            ))}
        </Column>
    );
}

function generateStatsFromSettingsChange(change: { executionTimestamp: number; changes: SettingsChange }): StatProps[] {
    const stats = [
        {
            key: "execution",
            caption: "Execution",
            getValue: () => <Text variant="body2">{formatDate(change.executionTimestamp, "relative")}</Text>
        },
        {
            key: "externalYieldSource",
            caption: "Idle Deposits",
            getValue: () =>
                change.changes.externalYieldSource
                    ? generateTransitionValue(
                          ExternalYieldSource[change.changes.externalYieldSource[0]],
                          ExternalYieldSource[change.changes.externalYieldSource[1]]
                      )
                    : undefined
        },
        {
            key: "interestFee",
            caption: COPY.INTEREST_FEE,
            getValue: () =>
                change.changes.interestFee
                    ? generateTransitionValue(
                          formatPercent(change.changes.interestFee[0]),
                          formatPercent(change.changes.interestFee[1])
                      )
                    : undefined
        },
        {
            key: "liquidityBuffer",
            caption: COPY.LIQUIDITY_BUFFER,
            getValue: () =>
                change.changes.liquidityBuffer
                    ? generateTransitionValue(
                          formatPercent(change.changes.liquidityBuffer[0]),
                          formatPercent(change.changes.liquidityBuffer[1])
                      )
                    : undefined
        },
        {
            key: "originationFee",
            caption: COPY.ORIGINATION_FEE,
            getValue: () =>
                change.changes.originationFee
                    ? generateTransitionValue(
                          formatPercent(change.changes.originationFee[0]),
                          formatPercent(change.changes.originationFee[1])
                      )
                    : undefined
        },
        {
            key: "originationCap",
            caption: COPY.MAX_ORIGINATION_SIZE,
            getValue: () =>
                change.changes.originationCap
                    ? generateTransitionValue(change.changes.originationCap[0], change.changes.originationCap[1])
                    : undefined
        },
        {
            key: "originationsEnabled",
            caption: COPY.ORIGINATION_CAP_STATUS,
            getValue: () =>
                change.changes.originationsEnabled
                    ? generateTransitionValue(
                          change.changes.originationsEnabled[0]
                              ? OriginationCapStatus.Accepting
                              : OriginationCapStatus.Closed,
                          change.changes.originationsEnabled[1]
                              ? OriginationCapStatus.Accepting
                              : OriginationCapStatus.Closed
                      )
                    : undefined
        }
    ]
        .map((stat) => ({
            key: stat.key,
            caption: stat.caption,
            value: stat.getValue()
        }))
        .filter((stat) => filterUndefined(stat.value))
        .map(({ caption, value }) => ({ caption, value }));

    return stats;
}

function generateTransitionValue(originalValue: string | number, newValue: string | number) {
    return (
        <Row sx={{ minWidth: "max-content" }} spacing={0.5}>
            <Text variant="body2" color="caption">
                {originalValue}
            </Text>
            <Text color="disabled" variant="body2">
                <ArrowForwardOutlined />
            </Text>
            <Text variant="body2">{newValue}</Text>
        </Row>
    );
}
