import VaultLoanAllocation from "./allocation/VaultLoanAllocation";
import VaultOverview from "./overview/VaultOverview";
import MarketParams from "./params/MarketParams";

export default function VaultInfo() {
    return (
        <>
            <VaultOverview />
            <VaultLoanAllocation />
            <MarketParams />
        </>
    );
}
