import { StatColumn, StatProps } from "@bridgesplit/ui";
import { formatUsd, getUnixTs } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import {
    BsMetaUtil,
    getVaultBalanceAvailableToWithdraw,
    getVaultTvl,
    LoopscalePointsAction,
    useCurrentTime,
    DenominationDisplay,
    getVaultExternalPointRewards
} from "@bridgesplit/abf-react";

import { RewardsLendVaultApy } from "../../../points";
import { useVaultContext } from "../../VaultContext";

export default function OverviewStats() {
    const { vaultExpanded, selectedDenomination } = useVaultContext();
    const { now } = useCurrentTime(1_000);

    const formatValue = (amount: number | undefined) => {
        const usdPrice = vaultExpanded?.principalOracle.usdPrice;
        if (!usdPrice || !amount) return undefined;

        return selectedDenomination === DenominationDisplay.PrincipalBasis
            ? BsMetaUtil.formatAmount(vaultExpanded.principalMetadata, amount)
            : formatUsd(amount * usdPrice);
    };

    const tvl = getVaultTvl(vaultExpanded, getUnixTs());
    const availableLiquidity = vaultExpanded && getVaultBalanceAvailableToWithdraw(vaultExpanded, now);
    const stats: StatProps[] = [
        {
            value: (
                <RewardsLendVaultApy
                    isFeatured={true}
                    apy={vaultExpanded?.vaultStrategy.strategy.wAvgApy}
                    loopscalePointsAction={LoopscalePointsAction.LiquidStake}
                    metadata={vaultExpanded?.principalMetadata}
                    externalPointRewards={getVaultExternalPointRewards(vaultExpanded)}
                />
            ),
            caption: "APY"
        },
        { value: formatValue(tvl), caption: COPY.TVL_TERM, hidden: !tvl },
        { value: formatValue(availableLiquidity), caption: "Available liquidity", hidden: !availableLiquidity }
    ].filter((stat) => !stat.hidden);

    return <StatColumn loading={!vaultExpanded} stats={stats} />;
}
