import { Column, TooltipText } from "@bridgesplit/ui";

import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useSetBorrowerStrategyDuration } from "./util";
import { SelectStrategyDuration } from "../../common";

export default function BorrowSetTerm() {
    return (
        <Column spacing={0.5}>
            <TooltipText helpText="Lock in your APY for a fixed duration" variant="body2">
                Fixed rate for
            </TooltipText>
            <SelectTerm />
        </Column>
    );
}

function SelectTerm() {
    const { form, strategyDurations } = useMarketBorrowContext();
    const { setStrategyDuration } = useSetBorrowerStrategyDuration();

    return (
        <SelectStrategyDuration
            strategyDurations={strategyDurations}
            strategyDuration={form.strategyDuration}
            setStrategyDuration={setStrategyDuration}
        />
    );
}
