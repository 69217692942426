import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { IS_SANDBOX } from "@bridgesplit/utils";

export interface RewardsFilter {
    feVisible: boolean;
    activeOnly: boolean;
}

export interface UserPoints {
    points: number;
    pps: number;
    timestamp: number;
    betaPoints: number;
}
export interface UserPointsCalculationInfo {
    basePoints: UserPoints;
    referralPoints: UserPoints;
}

export interface UserPastPoints {
    basePoints: number;
    referralBonusPoints: number;
}

export interface UserPointsPerSecond {
    basePointsPerSecond: number;
    referralPointsPerSecond: number;
}
export interface UserWaitlistInfo {
    userRank: number | null; // null if user not on WL
    totalUsers: number;
}

export interface PointMultiplier {
    reward: LoopscalePointsAction;
    multiplier: number;
    mint: string;
    name: string;
}

export enum PointsRewardType {
    SignUp = 0,
    BetaSignup = 1,
    FollowTwitter = 2,
    JoinDiscord = 3,
    CommunityEngagement = 4,
    AcceptReferral = 7
}

export interface PointsReward {
    reward: RewardValue;
    rewardName: string;
    pointsPerSecond: number;
    startTime: number;
    endTime: number | null;
    feRewardLabel: string;
    feVisible: boolean;
    feDisabled: boolean;
    feUrl: string;
    feContinuous: boolean;
}

export interface UserPointsReward {
    id: number;
    userIdentifier: string;
    reward: PointsRewardType;
    startTime: number;
    endTime: number | null;
    multiplier: number;
}

export interface CommunityRewardInfo {
    reward: PointsReward;
    communityPointsReward: CommunityPointsReward;
    userPointsReward: UserPointsReward | null;
}

export enum CommunityMultiplierType {
    Ovols = 0
}

export interface CommunityMultiplier {
    communityMultiplier: CommunityMultiplierType;
    multiplier: number;
}

export interface CommunityPointsReward {
    reward: PointsRewardType;
    mint: string;
    communityRewardType: CommunityRewardsType;
    threshold: number;
    rewardImage: string;
    bonusThreshold: number;
    bonusAmount: number;
}

export interface CommunityRewardClaim {
    reward: number;
    userIdentifier: string;
    attemptedClaim: boolean;
}

export enum CommunityRewardsType {
    Token = 0,
    Nft = 1,
    OrcaLp = 2
}

export enum ExternalPointSource {
    MarginFi = 0,
    Loopscale = 1,
    Jupiter = 2,
    Meteora = 3,
    HubSol = 4,
    Solayer = 5,
    Adrastea = 6,
    LoopscaleHidden = 7
}

//Cleaner rewards set up
export enum ExternalPointReward {
    MarginFi = 0,
    Jupiter = 2,
    Meteora = 3,
    HubSol = 4,
    Solayer = 5,
    Adrastea = 6
}

export type RewardValue = PointsRewardType | LoopscalePointsAction;

export enum LoopscalePointsAction {
    Borrow = IS_SANDBOX ? 17 : 35,
    Lend = IS_SANDBOX ? 18 : 36,
    IdleCap = IS_SANDBOX ? 19 : 37,
    LiquidStake = IS_SANDBOX ? 45 : 79,
    ThirtyDayVest = IS_SANDBOX ? 46 : 80,
    SixtyDayVest = IS_SANDBOX ? 47 : 81,
    NintyDayVest = IS_SANDBOX ? 48 : 82,
    OneHundredEightyDayVest = IS_SANDBOX ? 49 : 83
}

//Response from ROUTE_PORTFOLIO_REWARDS
export interface PortfolioRewards {
    strategies: Record<string, StrategyRewards>;
    loans: Record<string, LedgerRewards>;
    vaultStakes: Record<string, LedgerRewards>;
}
export interface StrategyRewards {
    ledgerRewards: ParsedLedgerReward[];
    externalYieldRewards?: ExternalYieldReward;
}
export interface LedgerRewards {
    ledgerRewards: ParsedLedgerReward[];
}
export interface ParsedLedgerReward {
    rewardId: number;
    isActive: boolean;
    pointsPerSecond: number;
    accruedTimeStamp: number;
    lastUpdateValue: number;
    isLenderReward: boolean;
    usdValue: number;
}

export interface ExternalYieldReward {
    rewardId: number;
    pointsPerSecond: number;
    accruedTimeStamp: number;
    strategyAddress: string;
    lastUpdateValue: number;
    usdValue: number;
}

//Used in ROUTE_PORTFOLIO_REWARDS
export interface PortfolioRewardFilter {
    loanAddresses: string[];
    strategyAddresses: string[];
    stakeAddresses: string[];
    isActive?: boolean;
}

export interface CollateralPointsData {
    eligibleMultipliers: LoopscalePointsAction[];
    showRewards: boolean;
    getAdditionalPoints: (pointSources: LoopscalePointsAction[]) => number;
}

export interface MultiplierInfo {
    pointSourceToAdditionalMultipliers: Record<LoopscalePointsAction, number>; // PointSource (Lend : 4x)
    name: string;
}

export interface UseRewardsCalculationProps {
    externalPointSources: ExternalPointSource[];
    loopscalePointsAction?: LoopscalePointsAction;
    metadata?: TokenListMetadata[];
    loopLeverage?: number;
}

export interface TokenSourceData {
    eligibleMultipliers: LoopscalePointsAction[];
    showRewards: boolean;
}

export interface PointsInfo {
    externalPointSource: ExternalPointSource;
    multiplier: number | undefined;
    caption: string;
}
export type PointsInfoDetails = Omit<PointsInfo, "multiplier">;

export enum LabelVisibility {
    Hidden = "hidden", // Hides entire component
    ShowBasic = "basic", // Shows just text
    ShowFull = "full" // Shows text + rewards icon
}
