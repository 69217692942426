import { useState } from "react";

import { Button, DialogWrapper, LabelWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import {
    DEFAULT_STRATEGY_DURATION,
    getLoopSlippage,
    getLoopSlippageTooltip,
    useStrategyDurations
} from "@bridgesplit/abf-react";
import { formatDurationWithTypeShorthand, StrategyDuration } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { percentDecimalsToUi, percentUiToDecimals } from "@bridgesplit/utils";

import { HeaderWithClose, SelectStrategyDuration, Slippage } from "../../common";

export default function WindSettingsDialog() {
    const { getData, close } = useAppDialog();

    const data = getData(AppDialog.WindSettings);

    const [slippageUi, setSlippageUi] = useState<number | undefined>(
        percentDecimalsToUi(data?.form?.percentSlippageDecimals)
    );
    const [strategyDurationState, setStrategyDuration] = useState<StrategyDuration | undefined>(
        data?.form?.strategyDuration
    );

    const { strategyDurations } = useStrategyDurations();

    const strategyDuration = strategyDurationState ?? DEFAULT_STRATEGY_DURATION;

    return (
        <DialogWrapper>
            <HeaderWithClose header={`${COPY.LOOP_TERM} settings`} close={close} />
            <LabelWrapper
                label="Slippage"
                tooltip={data ? getLoopSlippageTooltip(data?.loopExpanded, "wind") : undefined}
            >
                <Slippage.Input
                    presets={getLoopSlippage(data?.loopExpanded).slippagePresetsUi}
                    value={slippageUi}
                    setValue={(val) => setSlippageUi(val)}
                />
            </LabelWrapper>
            <LabelWrapper
                label="Custom duration"
                tooltip={`Duration to lock your borrow APY for (defaults to ${formatDurationWithTypeShorthand(
                    DEFAULT_STRATEGY_DURATION.duration,
                    DEFAULT_STRATEGY_DURATION.durationType
                )})`}
            >
                <SelectStrategyDuration
                    strategyDurations={strategyDurations}
                    strategyDuration={strategyDurationState}
                    setStrategyDuration={setStrategyDuration}
                />
            </LabelWrapper>

            <Button
                disabled={!slippageUi || !strategyDuration}
                variant="contained"
                onClick={() => {
                    if (!slippageUi || !strategyDuration) return;
                    data?.setForm({
                        ...data?.form,
                        percentSlippageDecimals: percentUiToDecimals(slippageUi),
                        strategyDuration
                    });
                    close();
                }}
            >
                Save settings
            </Button>
        </DialogWrapper>
    );
}
