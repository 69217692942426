import { useEffect } from "react";

import { SMALL_MAX_WIDTH } from "@bridgesplit/ui";
import {
    LendingVaultExpanded,
    MarketGuideMode,
    OnboardingStep,
    useLendVaultByAddress,
    useUserOnboardingStep
} from "@bridgesplit/abf-react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { AppDialog, useAppDialog } from "app/utils";

import { MobileCtaWrapper, Page, SideCardWrapper } from "../wrappers";
import { VaultCard } from "./VaultCard";
import { VAULT_DETAIL_QUERY } from "./constants";
import { useVaultContext, VaultProvider } from "./VaultContext";
import { VaultDetailHeader, VaultInfo } from "./info";

export default function VaultDetail() {
    const { address } = useParams<{ address: string }>();
    const { data } = useLendVaultByAddress(address);
    useOpenVaultGuideOnLoad({ vaultExpanded: data });

    return (
        <Page maxWidth={SMALL_MAX_WIDTH}>
            <VaultProvider vaultAddress={address} vaultExpanded={data}>
                <Content header={<VaultDetailHeader />} card={<VaultCard />}>
                    <VaultInfo />
                </Content>
            </VaultProvider>
        </Page>
    );
}

function Content({
    header,
    children,
    card
}: {
    header: React.ReactNode;
    children: React.ReactNode;
    card: React.ReactNode;
}) {
    const isMobile = useMediaQuery(VAULT_DETAIL_QUERY.below);
    const { isActionOpen, setIsActionOpen } = useVaultContext();

    if (isMobile)
        return (
            <MobileCtaWrapper card={card} open={isActionOpen} setOpen={setIsActionOpen}>
                {header}
                {children}
            </MobileCtaWrapper>
        );

    return (
        <>
            {header}
            <SideCardWrapper breakpoint={VAULT_DETAIL_QUERY} sideCard={card}>
                {children}
            </SideCardWrapper>
        </>
    );
}

function useOpenVaultGuideOnLoad({ vaultExpanded }: { vaultExpanded: LendingVaultExpanded | undefined }) {
    const { open } = useAppDialog();
    const { steps, isLoading } = useUserOnboardingStep([OnboardingStep.LendVault]);

    useEffect(() => {
        if (isLoading || !vaultExpanded) return;

        if (!steps.includes(OnboardingStep.LendVault)) {
            return open(AppDialog.MarketGuide, {
                mode: MarketGuideMode.LendVault,
                isForced: true,
                vaultExpanded: vaultExpanded
            });
        }
    }, [isLoading, vaultExpanded, open, steps]);
}
