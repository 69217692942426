import {
    ExternalYieldSourceArgs,
    MarketInformation,
    MarketInformationAssetData,
    MarketInformationLtvInfo,
    OracleQuote,
    OracleType,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";
import { SortDirection } from "@bridgesplit/utils";

import { StrategyInfoExpanded } from "./strategy";
import { MarketInformationWithLtvInfoResponse } from "./oracle";
import { DepositTermSummary } from "../reducers";

export interface LendingVaultExpanded extends LendingVaultInfo {
    principalMetadata: TokenListMetadata;
    principalOracle: OracleQuote;
    ltvInfos: Record<string, VaultLtvInfos> | undefined; // Key is collateral mint address.
}

// VaultLtvInfos is a custom front end type used as a join between strategyTerms and marketInformation.
// Market information is not available until time lock executes. If a ltvInfo has a collateral, but no market information, market information properties will be undefined.
export interface VaultLtvInfos {
    collateralMetadata: TokenListMetadata;
    durationToLtvInfo: Record<string, VaultLtvInfo>; //Key is the serialized strategyDuration
}

export interface VaultLtvInfo {
    apy?: string; //If no APY is set, it is disabled, this APY comes from stratgy terms
    marketInfo: VaultLtvMarketInfo | undefined;
}

export interface VaultLtvMarketInfo {
    ltv: number;
    liquidationThreshold: number;
    oracleAccount: string;
    oracleType: OracleType;
}

export interface UserVaultPositionExpanded {
    vaultExpanded: LendingVaultExpanded;
    address: string;
    amountAvailable: UserVaultLpBalance;
    index: number;
    totalAmount: UserVaultLpBalance;
    active: boolean;
    depositTerms: DepositTermSummary; //User specific deposit terms. E.g if a user has never deposited, the deposit terms will be undefined, if a user deposits and withdraws, the deposit terms will be the deposit terms for the user's last deposit.
}

export interface UserVaultPnl {
    currentPnl?: number;
    lifetimePnl?: number;
    mostRecentDepositOrWithdrawalTimestamp?: number;
}

export interface UserVaultLpBalance {
    amountLp: number;
    amountPrincipal: number;
    amountUsd: number;
}

export interface LendingVaultInfo {
    vault: ParsedLendingVault;
    vaultMetadata?: LendingVaultMetadataExpanded;
    vaultStrategy: StrategyInfoExpanded;
    pendingTerms: ParsedTimeLock[];
}

export interface LendingVaultsInfoResponse {
    lendVaults: LendingVaultInfo[];
    total: number;
}

export interface VaultEscrowAccount {
    liquidityBufferCbps: number;
    originationFeeCbps: number;
    interestFeeCbps: number;
    originationCap: number;
    netAssetValue: number;
    interestAccruedValue: number;
    interestPerSecond: number;
    lastAccruedTimestamp: number;
    feesClaimable: number;
    currentPrincipalDeployed: number;
    activeLoanCount: number;
    wAvgApy: number;
    cumulativePrincipalOriginated: number;
    cumulativeInterestEarned: number;
    totalActiveLoanCount: number;
    lastInteractedTime: number;
    lastInteractedTxn: string;
}

export interface ParsedLendingVault {
    address: string;
    nonce: string;
    manager: string;
    principalMint: string;
    lpMint: string;
    lpSupply: number;
    cumulativePrincipalDeposited: number;
    maxEarlyUnstakeFee: number;
    tags?: string; //json
    depositsEnabled: boolean;
}

export interface ParsedTimeLock {
    address: string;
    vaultAddress: string;
    initializationTimestamp: number;
    executionTimestamp: number;
    update: ParsedTermsUpdate;
    marketInformationAddress?: string;
}

export enum VaultUpdateActionType {
    UpdateStrategyMarketInformation = "updateStrategyMarketInformation",
    AddCollateral = "addCollateral",
    UpdateLtv = "updateLtv",
    RemoveCollateral = "removeCollateral",
    UpdateApy = "updateApy",
    UpdateStrategySettings = "updateStrategySettings",
    UpdateVaultSettings = "updateVaultSettings"
}

export type ParsedTermsUpdate = {
    [VaultUpdateActionType.UpdateStrategyMarketInformation]: string | null;
    [VaultUpdateActionType.AddCollateral]: ParsedAddCollateralTimelockArgs | null;
    [VaultUpdateActionType.UpdateLtv]: ParsedMarketInformationUpdateArgs | null;
    [VaultUpdateActionType.RemoveCollateral]: ParsedCollateralTerms | null;
    [VaultUpdateActionType.UpdateApy]: ParsedCollateralTerms | null;
    [VaultUpdateActionType.UpdateStrategySettings]: ParsedUpdateStrategySettingsParams | null;
    [VaultUpdateActionType.UpdateVaultSettings]: ParsedUpdateVaultSettingsParams | null;
};

export interface ParsedAddCollateralTimelockArgs {
    collateralTerms: ParsedCollateralTerms;
    updateMarketInformationParams: ParsedMarketInformationInitArgs;
}

export interface ParsedUpdateStrategySettingsParams {
    originationsEnabled?: boolean;
    liquidityBuffer?: number;
    interestFee?: number;
    originationFee?: number;
    principalFee?: number;
    originationCap?: number;
    marketInformation?: string;
    externalYieldSourceArgs?: ExternalYieldSourceArgs;
}

export interface ParsedUpdateVaultSettingsParams {
    maxEarlyUnstakeFee: number;
}

export interface ParsedCollateralTerms {
    collateralIndex: number;
    mint: string;
    terms: Record<string, number>;
}

export interface ParsedCollateralTermsExpanded extends ParsedCollateralTerms {
    metadata: TokenListMetadata | undefined;
    liquidationLtvUi: number;
    ltvUi: number;
}

export interface LendingVaultMetadata {
    name: string;
    description: string;
    managerName: string;
    managerImage: string;
    depositCap?: number;
    tags?: string[];
}

export interface LendingVaultMetadataExpanded extends LendingVaultMetadata {
    isGenesisVault: boolean;
}

export interface ParsedMarketInformationInitArgs {
    assetIdentifier: string;
    decimals: number;
    liquidationThreshold: number;
    ltv: number;
    maxAge: number;
    maxUncertainty: number;
    oracleAccount: string;
    oracleType: number;
    quoteMint: string;
    removeCollateral: boolean;
}

export interface MarketInformationsFilter {
    principalMints: string[];
    addresses: string[];
    verified: boolean; //When verified is true we query the presets market information table
}

export interface ParsedMarketInformation {
    marketInformation: MarketInformation;
    marketInformationAssetInfo: MarketInformationAssetData[];
}

// Fix the function to use the correct property names
export function convertParsedMarketInformationToLtvInfo(
    marketInformation: ParsedMarketInformation
): MarketInformationLtvInfo[] {
    return marketInformation.marketInformationAssetInfo.map((asset) => ({
        marketInformationAddress: marketInformation.marketInformation.address,
        ...asset
    }));
}

export function converParsedMarketInformationToMarketInformationWithLtvInfo(
    marketInformation: ParsedMarketInformation
): MarketInformationWithLtvInfoResponse {
    return {
        marketInformation: marketInformation.marketInformation,
        ltvInfo: convertParsedMarketInformationToLtvInfo(marketInformation)
    };
}

export interface ParsedMarketInformationUpdateArgs {
    assetIdentifier: string;
    oracleAccount?: string;
    maxUncertainty?: number;
    maxAge?: number;
    decimals?: number;
    oracleType?: number;
    ltv?: number;
    liquidationThreshold?: number;
    quoteMint?: string;
    removeCollateral?: boolean;
}

export interface ParsedLtvData {
    principalMint: string;
    ltv: number;
    liquidationThreshold: number;
}

enum TimelockStatus {
    Pending,
    Executed,
    Cancelled
}

export interface LendingVaultTimelockFilter {
    vaultAddresses?: string[];
    timelockStatus?: TimelockStatus;
    page: number;
    pageSize: number;
    sortDirection: SortDirection;
}

export interface LendingVaultsTimelockResponse {
    timeLocks: ParsedTimeLock[];
    total: number;
}

export interface LendVaultInfoFilter {
    vaultAddresses?: string[];
    principalMints?: string[];
    lpMints?: string[];
    depositsEnabled?: boolean;
}

export interface LendVaultsPagination {
    principalMints?: string[];
    sortType: LendVaultSortType;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface LendVaultsPagination {
    principalMints?: string[];
    sortType: LendVaultSortType;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}
export type FetchLendVaultsInfoParams = LendVaultsPagination & LendVaultInfoFilter;

export interface LendVaultAllocation {
    collateralMint: string;
    activeLoanCount: number;
    totalPrincipalDeployed: number;
    wAvgApy: number;
}

export enum LendVaultSortType {
    TotalDeposits,
    WAvgApy
}

export interface FetchLendVaultsHistoryParams {
    vaultAddress: string;
    timeLookback: number;
}

export interface LendingVaultsHistory {
    id: number;
    vaultAddress: string;
    timestamp: number;
    lpSupply: number;
    netAssetValue: number;
    cumulativeLoanVolume: number;
    cumulativeLoanCount: number;
    principalDeployed: number;
    wAvgApy: number;
}

export interface LendingVaultAllocationExpanded extends LendingVaultAllocation {
    collateralMetadata: TokenListMetadata;
}

export interface LendingVaultAllocation {
    collateralMint: string;
    activeLoanCount: number;
    totalPrincipalDeployed: number;
    wAvgApy: number;
}

export type UserLendingVaultFilter = FetchLendVaultsInfoParams & {
    status: StakeStatus;
    stakeDuration?: ParsedAllowedStakeDuration;
};

export enum StakeStatus {
    Active,
    Unstaked
}

export enum ParsedAllowedStakeDuration {
    ZeroDays,
    OneMonth,
    ThreeMonths,
    SixMonths,
    OneYear
}

export interface UserVaultStakePositionsResponse {
    positions: ParsedUserVaultStake[];
    total: number;
}

export interface UserGenesisAccessResponse {
    genesisAccess: boolean;
}

export interface ParsedUserVaultStake {
    stakeAddress: string;
    address: string;
    vaultAddress: string;
    amountStaked: number;
    startTime: number;
    maxUnstakeFee: number;
    endTime: number;
    wAvgApy: number;
    unstakeTime?: number;
    unstakeFeeApplied?: number;
}

export enum DenominationDisplay {
    PrincipalBasis = "PRINCIPAL_BASIS",
    UsdBasis = "USD_BASIS"
}
