import VaultWithdrawAmount from "./VaultWithdrawAmount";
import { VaultWithdrawProvider } from "./VaultWithdrawContext";
import VaultWithdrawCta from "./VaultWithdrawCta";
import VaultWithdrawStats from "./VaultWithdrawStats";

export function VaultWithdraw() {
    return (
        <VaultWithdrawProvider>
            <VaultWithdrawAmount />
            <VaultWithdrawStats />
            <VaultWithdrawCta />
        </VaultWithdrawProvider>
    );
}
