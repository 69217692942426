import { QuestionToggleProps } from "@bridgesplit/ui";
import { BsMetaUtil, isMeteora } from "@bridgesplit/abf-react";

import { useBaseFaq } from "./base";
import { useLoopContext } from "../../LoopContext";

export function useMeteoraFaq(): QuestionToggleProps[] | undefined {
    const base = useBaseFaq();
    const { loopExpanded } = useLoopContext();

    if (!loopExpanded) return undefined;
    if (isMeteora(loopExpanded)) {
        const poolName = `${BsMetaUtil.getSymbol(loopExpanded.tokenA)}-${BsMetaUtil.getSymbol(loopExpanded.tokenB)}`;

        const symbol = BsMetaUtil.getSymbol(loopExpanded.collateralToken);

        const collateral: QuestionToggleProps = {
            label: `What is ${symbol} and how does this vault earn yield?`,
            answer: [
                `MLP (Meteora Liquidity Provider) tokens represent your share in Meteora's ${poolName} Dynamic Pool. You'll earn yield from trading fees by providing AMM liquidity and lending idle capital to external protocols.`,
                `You'll also earn multiplied Meteora points since your ${BsMetaUtil.getSymbol(
                    loopExpanded.principalToken
                )} deposit will be leveraged multiple times.`
            ]
        };
        const risks: QuestionToggleProps = {
            label: "What are the risks?",
            answer: [
                `Changing rates: While our rates are fixed short-term, they do adjust. If borrowing costs outpace Meteora ${poolName} yields for an extended period, you could lose a portion of your original deposit.`,
                "Insufficient liquidity: If the amount of capital becomes limited, it may affect your ability to manage your position.",
                "Smart contract risk: As with any DeFi product, there's always some level of smart contract risk."
            ]
        };

        if (!base) return undefined;
        const { intro, uniqueFeatures, management } = base;

        return [intro, collateral, risks, uniqueFeatures, management];
    }
    return undefined;
}
