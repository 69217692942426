import { PageBase } from "./Page";

export function AppFallback() {
    return (
        <PageBase hideFooter>
            <AppFallbackContent />
        </PageBase>
    );
}

export function AppFallbackContent() {
    return null;
}
