import { Fragment, ReactNode } from "react";

import {
    Column,
    JLP_LOGO,
    MARGIN_FI_LOGO,
    RewardImage,
    Row,
    SOLANA_HUB_LOGO,
    StatProps,
    StatWithIcon,
    Text,
    METEORA_SVG,
    Icon,
    useAppPalette,
    SpanFlex,
    SOLAYER_LOGO,
    ADRASTEA_LOGO,
    TextVariant,
    TextColor,
    MEDIA
} from "@bridgesplit/ui";
import {
    PointsInfo,
    LoopscalePointsAction,
    ExternalPointSource,
    POINTS_SOURCE_INFO,
    ExternalPointReward,
    useLoanMultipliersUtil
} from "@bridgesplit/abf-react";
import { BoltOutlined } from "@mui/icons-material";
import { formatNum, formatPercent } from "@bridgesplit/utils";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";
import { useMediaQuery } from "@mui/material";

import { getExternalPointsRewardIcon } from "./external-point-rewards/ExternalPointReward";
import { useRewardsColor } from "./util";

export interface CollateralInfo {
    pointSource: ExternalPointSource[];
    collateralPointBonus: Map<LoopscalePointsAction, number>;
    name: string;
}

interface TooltipContentProps {
    children: React.ReactNode;
    width?: string;
}
export function TooltipContent({ children, width = "250px" }: TooltipContentProps): JSX.Element {
    return (
        <Column spacing={2} padding={1} sx={{ width }}>
            {children}
        </Column>
    );
}

export function useCreateMultiplierLoops(multipliers: PointsInfo[]): StatProps[] {
    return multipliers.map((multiplier) => {
        return {
            value: <StatWithIcon value={`${formatNum(multiplier.multiplier ?? 1)}x`} />,
            caption: multiplier.caption
        };
    });
}

export function useCreateCollateralStats(multipliers: PointsInfo[] | undefined, isBoosted: boolean) {
    const { prime, secondary } = useAppPalette();
    if (!multipliers) return undefined;
    return multipliers.map((multiplier) => {
        return {
            value: (
                <StatWithIcon
                    value={`${formatNum(multiplier.multiplier ?? 1)}x`}
                    icon={
                        isBoosted ? (
                            <Text sx={{ color: isBoosted ? prime : secondary }}>
                                <BoltOutlined />
                            </Text>
                        ) : undefined
                    }
                />
            ),
            caption: multiplier.caption
        };
    });
}

export function getIconForSource(source: ExternalPointSource): ReactNode {
    switch (source) {
        case ExternalPointSource.MarginFi:
            return <RewardImage src={MARGIN_FI_LOGO} />;
        case ExternalPointSource.Loopscale:
            return <Icon type="points" />;
        case ExternalPointSource.Jupiter:
            return <RewardImage src={JLP_LOGO} />;
        case ExternalPointSource.Meteora:
            return <RewardImage src={METEORA_SVG} />;
        case ExternalPointSource.HubSol:
            return <RewardImage src={SOLANA_HUB_LOGO} />;
        case ExternalPointSource.Solayer:
            return <RewardImage src={SOLAYER_LOGO} />;
        case ExternalPointSource.Adrastea:
            return <RewardImage src={ADRASTEA_LOGO} />;
    }
}

interface PointSourceMapProps {
    pointSources: ExternalPointSource[];
    background?: string;
    isLoading?: boolean;
}

export function PointSourceMap({ pointSources, background, isLoading }: PointSourceMapProps): JSX.Element {
    return (
        <Row spacing={1}>
            {pointSources.map((point) => (
                <Fragment key={point}>
                    <PointSourceIcon source={point} background={background} isLoading={isLoading} />
                </Fragment>
            ))}
        </Row>
    );
}
interface PointSourceIconProps {
    source: ExternalPointSource;
    background?: string;
    isLoading?: boolean;
}

function PointSourceIcon({ source, background }: PointSourceIconProps): JSX.Element {
    if (source === ExternalPointSource.Loopscale) {
        return (
            <Text sx={{ color: background }} variant="body1">
                {getIconForSource(POINTS_SOURCE_INFO[source].externalPointSource)}
            </Text>
        );
    }

    return <SpanFlex>{getIconForSource(POINTS_SOURCE_INFO[source].externalPointSource)}</SpanFlex>;
}

// New common components

export function CaptionWithIcon({
    caption,
    icon,
    variant = "body1",
    iconColor = "caption"
}: {
    caption: string;
    variant?: TextVariant;
    icon: React.ReactNode;
    iconColor?: string;
}) {
    return (
        <Row spacing={1}>
            <Text sx={{ color: iconColor }} variant="body1">
                {icon}
            </Text>
            <Text variant={variant} color="caption">
                {caption}
            </Text>
        </Row>
    );
}

export function ExternalRewardsDisplay({
    metadata,
    loopscalePointsAction,
    externalPointRewards
}: {
    metadata: TokenListMetadata | undefined;
    loopscalePointsAction: LoopscalePointsAction;
    externalPointRewards: ExternalPointReward[] | undefined;
}) {
    const { loanMultipliers, isLoading } = useLoanMultipliersUtil();
    const color = useRewardsColor(loanMultipliers, metadata?.mint, [loopscalePointsAction]);

    if (isLoading) return null;

    return (
        <>
            <Text sx={{ color }}>
                <Icon type="points" />
            </Text>
            <Text color="caption">
                {externalPointRewards?.map((reward, index) => (
                    <Fragment key={index}>{getExternalPointsRewardIcon(reward)}</Fragment>
                ))}
            </Text>
        </>
    );
}

export function RateDisplay({
    apy,
    variant,
    color,
    isFeatured
}: {
    apy: number | undefined;
    variant: TextVariant;
    color: TextColor;
    isFeatured?: boolean;
}) {
    const customDigits = 3;
    const fixedWidth = "6ch";
    const isMobile = useMediaQuery(MEDIA.LG.below);

    if (isFeatured || isMobile) {
        return (
            <Row>
                <Text svgColor="disabled" variant={variant} underline color={color}>
                    {formatPercent(apy, { customDigits })}
                </Text>
            </Row>
        );
    }

    return (
        <Row sx={{ alignItems: "flex-end" }}>
            <Text
                svgColor="disabled"
                variant={variant}
                underline
                color={color}
                sx={{ display: "inline", width: fixedWidth }}
            >
                {formatPercent(apy, { customDigits })}
            </Text>
        </Row>
    );
}
