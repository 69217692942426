export enum TransactionType {
    AMMBuy = 0,
    AmmSell = 1,
    EnglishAuction = 2,
    OTC = 3,
    DutchAuction = 4
}

export enum ExternalPricingSource {
    BAXUS = 0,
    CoinGecko = 1
}

export enum PriceFetchType {
    Spot,
    Twap
}

export interface OHLC {
    open: number;
    high: number;
    low: number;
    close: number;
    transactions: AssetTransaction[];
    startTime: number;
    endTime: number;
}

export interface AssetTransaction {
    transactionType?: TransactionType;
    timestamp: number;
}

export interface FetchPricingDataFilter {
    assetMints: string[];
    sources?: number[];
    quoteAssets?: string[];
    beforeSeconds?: number;
    afterSeconds?: number;
    intervalSeconds: number;
}

export type OhlcPricingData = Record<string, Record<number, Record<string, OHLC[]>>>;
// asset mint -> external pricing source -> quote asset -> OHLC

export type PricingData = Record<string, Record<number, Record<string, ExternalPrice[]>>>;
// asset mint -> external pricing source -> quote asset -> OHLC

export interface QuoteAsset {
    id: number;
    assetIdentifier: string;
    name: string;
    symbol: string;
    extraMetadata?: string;
    externalUri?: string;
}

export interface ExternalPrice {
    assetMint: string;
    externalPricingSource: ExternalPricingSource;
    quoteAsset: string;
    timestamp: number;
    price: number;
    transactionType: TransactionType;
}

export type PricingDataWithQuote = {
    prices: ExternalPrice[];
    quote: QuoteAsset;
};

export type LatestPrice = {
    price: number;
    time: number;
    quote: QuoteAsset;
};
