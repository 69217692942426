import { useCallback, useEffect, useMemo } from "react";

import { TokenPositionExpanded, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import {
    calculatePercentChange,
    formatNum,
    formatPercent,
    lamportsToUiAmount,
    Result,
    roundToDecimals,
    TIME
} from "@bridgesplit/utils";
import {
    tickIndexToPrice,
    priceToTickIndex,
    getNearestValidTickIndexFromTickIndex,
    Percentage,
    sqrtPriceX64ToPrice
} from "@orca-so/whirlpool-sdk";
import BN from "bn.js";
import Decimal from "decimal.js";
import {
    calculateHealthRatioFromLtv,
    calculateLoanHealth,
    EstimatedRefinanceInfo,
    getZcLedger,
    getZcLoanCollateral,
    getZcLoanLiquidationThreshold,
    getZcLoanLtv,
    MAX_TICK_INDEX,
    MIN_TICK_INDEX,
    WhirlpoolOffchain
} from "@bridgesplit/abf-react";

import { useWhirlpoolContext } from "./WhirlpoolContext";
import { PRICE_RANGE_DECIMALS } from "./common";
import { getPositionData, getPoolData } from "./orca";
import { YieldData } from "./types";

export function useInitPositionData() {
    const { positionData, setPositionData, whirlpoolPosition, setInitialWithdrawQuote } = useWhirlpoolContext();

    useEffect(() => {
        if (positionData !== undefined) return;
        (async () => {
            const data = await getPositionData(whirlpoolPosition);
            setPositionData(data?.positionData ?? null);
            setInitialWithdrawQuote(data?.quote);
        })();
    }, [positionData, setInitialWithdrawQuote, setPositionData, whirlpoolPosition]);
}

export function useInitPoolData() {
    const { poolData, setPoolData, whirlpoolPosition } = useWhirlpoolContext();

    useEffect(() => {
        if (poolData !== undefined) return;
        (async () => {
            const data = await getPoolData(whirlpoolPosition);
            setPoolData(data);
        })();
    }, [poolData, setPoolData, whirlpoolPosition]);
}

export function isMaxTickIndex(tickIndex: number, tickSpacing: number) {
    const maxTickIndex = getNearestValidTickIndexFromTickIndex(MAX_TICK_INDEX, tickSpacing);
    return tickIndex === maxTickIndex;
}

export function isMinTickIndex(tickIndex: number, tickSpacing: number) {
    const minTickIndex = getNearestValidTickIndexFromTickIndex(MIN_TICK_INDEX, tickSpacing);
    return tickIndex === minTickIndex;
}

export function flipPriceIfNeeded(price: number | undefined, isFlippedPrice: boolean) {
    if (!price) return price;
    return isFlippedPrice ? roundToDecimals(1 / price, PRICE_RANGE_DECIMALS) : price;
}

export function useWhirlpoolFormatPrice() {
    const { isFlippedPrice, whirlpoolPosition } = useWhirlpoolContext();
    return useCallback(
        (tickIndex: number | undefined, decimals = PRICE_RANGE_DECIMALS) => {
            if (tickIndex === undefined) return undefined;

            const price = getPriceFromTick(tickIndex, whirlpoolPosition);
            const flippedPrice = flipPriceIfNeeded(price, isFlippedPrice);
            return formatNum(flippedPrice, { customDecimals: decimals });
        },
        [isFlippedPrice, whirlpoolPosition]
    );
}

export function useFormatWhirlpoolPercentChangeFromCurrent() {
    const { whirlpoolPosition, isFlippedPrice } = useWhirlpoolContext();
    return useCallback(
        (tickIndex: number | undefined, currentTickIndex: number | undefined) => {
            if (tickIndex === undefined || currentTickIndex === undefined) return undefined;

            const price = getPriceFromTick(tickIndex, whirlpoolPosition);
            const currentPrice = getPriceFromTick(currentTickIndex, whirlpoolPosition);

            let percentChange = calculatePercentChange(currentPrice, price);
            if (isFlippedPrice) {
                percentChange = -percentChange;
            }
            let formatted = formatPercent(percentChange);
            if (percentChange > 0) formatted = `+${formatted}`;
            return formatted;
        },
        [isFlippedPrice, whirlpoolPosition]
    );
}

export const getPriceFromTick = (tickIndex: number, whirlpoolPosition: WhirlpoolPositionExpanded) => {
    try {
        if (isMaxTickIndex(tickIndex, whirlpoolPosition.whirlpool.tickSpacing)) return Infinity;
        if (isMinTickIndex(tickIndex, whirlpoolPosition.whirlpool.tickSpacing)) return 0;
        return tickIndexToPrice(
            tickIndex,
            whirlpoolPosition.tokenA.decimals,
            whirlpoolPosition.tokenB.decimals
        ).toNumber();
    } catch (error) {
        Result.err(error);
        return 0;
    }
};

export function getTickFromPrice(price: number, whirlpoolPosition: WhirlpoolPositionExpanded) {
    try {
        if (price === Infinity) return MAX_TICK_INDEX;
        if (price === 0) return MIN_TICK_INDEX;
        const roundedTick = priceToTickIndex(
            new Decimal(price),
            whirlpoolPosition.tokenA.decimals,
            whirlpoolPosition.tokenB.decimals
        );
        return getNearestValidTickIndexFromTickIndex(roundedTick, whirlpoolPosition.whirlpool.tickSpacing);
    } catch (error) {
        Result.err(error);
        return MIN_TICK_INDEX;
    }
}

export const getTokenUsdPrice = (token: TokenPositionExpanded, amount: BN) =>
    lamportsToUiAmount(amount.toNumber(), token.decimals) * token.usdPrice;

// Calculate denominator (1/decimalValue)
export function getSlippageTolerance(slippagePercent: number) {
    const denominator = Math.round(1 / slippagePercent);
    const percent = new Percentage(new BN(1), new BN(denominator));
    return percent;
}

export function useWhirlpoolRefinanceInfo() {
    const { loanExpanded } = useWhirlpoolContext();
    const loanLtv = getZcLoanLtv(loanExpanded) ?? 0;
    const loanCollateralMint = getZcLoanCollateral(loanExpanded)?.loanCollateral.metadata?.mint ?? "";
    const loanLiquidationThreshold = getZcLoanLiquidationThreshold(loanExpanded) ?? 0;

    const refinanceInfo: EstimatedRefinanceInfo = {
        ltv: loanLtv,
        liquidationThreshold: loanLiquidationThreshold,
        collateralMint: loanCollateralMint
    };

    return { refinanceInfo };
}

export function useWhirlpoolLtv(positionUsdValue: number | undefined) {
    const {
        loanExpanded,
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();

    const firstLedger = getZcLedger(loanExpanded);
    const { refinanceInfo } = useWhirlpoolRefinanceInfo();

    const totalDebtUsd = (loanExpanded?.principalUsdPrice ?? 0) * (firstLedger?.ledgerDebt.total ?? 0);
    const ltv = positionUsdValue !== undefined ? totalDebtUsd / positionUsdValue : 1;

    const maxLtvWithSlippage = refinanceInfo?.ltv ? refinanceInfo.ltv * (1 + slippagePercentDecimals) : 1;
    const insufficientCollateral = refinanceInfo && ltv > maxLtvWithSlippage;
    const health = calculateHealthRatioFromLtv(ltv, refinanceInfo?.liquidationThreshold);
    const previousHealth = calculateLoanHealth(loanExpanded);

    return {
        ltv,
        refinanceInfo,
        insufficientCollateral,
        health,
        isLoading: positionUsdValue === undefined,
        previousHealth
    };
}

export function useInitialWhirlpoolRatio() {
    const { whirlpoolPosition, poolData, positionData } = useWhirlpoolContext();
    return useMemo(() => {
        if (!poolData || !positionData) return undefined;
        return calculateDepositRatios(
            poolData?.sqrtPrice,
            whirlpoolPosition,
            positionData.tickLowerIndex,
            positionData.tickUpperIndex
        );
    }, [poolData, whirlpoolPosition, positionData]);
}

//  https://github.com/everlastingsong/solsandbox/blob/23cc81160e5d66376da7464fbaf9645d4d2abb76/orca/whirlpool/whirlpools_sdk/78a_deposit_ratio.ts
export function calculateDepositRatios(
    sqrtPrice: BN,
    { tokenA, tokenB }: WhirlpoolPositionExpanded,
    lowerTickIndex: number,
    upperTickIndex: number
): { ratioA: number; ratioB: number } | undefined {
    try {
        const price = sqrtPriceX64ToPrice(sqrtPrice, tokenA.decimals, tokenB.decimals).toNumber();

        const lowerSqrtPrice = Math.sqrt(tickIndexToPrice(lowerTickIndex, tokenA.decimals, tokenB.decimals).toNumber());
        const upperSqrtPrice = Math.sqrt(tickIndexToPrice(upperTickIndex, tokenA.decimals, tokenB.decimals).toNumber());
        const clampedCurrentSqrtPrice = Math.min(Math.max(lowerSqrtPrice, Math.sqrt(price)), upperSqrtPrice);

        const depositA = 1 / clampedCurrentSqrtPrice - 1 / upperSqrtPrice;
        const depositB = clampedCurrentSqrtPrice - lowerSqrtPrice;

        const depositAValueInB = depositA * price;
        const depositBValueInB = depositB;
        const totalValueInB = depositAValueInB + depositBValueInB;

        const ratioA = depositAValueInB / totalValueInB;
        const ratioB = depositBValueInB / totalValueInB;

        return { ratioA, ratioB };
    } catch (error) {
        return undefined;
    }
}

export function getYield({
    positionLiquidity,
    poolLiquidity,
    whirlpoolOffchain,
    positionUsdValue
}: {
    positionUsdValue: number;
    positionLiquidity: BN;
    poolLiquidity: BN;
    whirlpoolOffchain: WhirlpoolOffchain;
}): YieldData {
    const liquidityShare = new Decimal(positionLiquidity.toString()).div(poolLiquidity.toString()).toNumber();
    const positionYield = liquidityShare * parseFloat(whirlpoolOffchain.feesUsdc24h);
    const positionYieldOverTvl24h = positionYield / positionUsdValue;
    const positionYieldOverTvlAnnual = positionYieldOverTvl24h * (TIME.YEAR / TIME.DAY);

    return { positionYieldOverTvl24h, positionYieldOverTvlAnnual, positionUsdValue };
}
