import { ReactNode, useMemo } from "react";

import { colorToAlpha } from "@bridgesplit/utils";
import {
    Box,
    ButtonBaseProps,
    CircularProgress,
    Button as MuiButton,
    ButtonBase as MuiButtonBase,
    ButtonProps as MuiButtonProps,
    IconButton as MuiIconButton,
    IconButtonProps as MuiIconButtonProps,
    ToggleButton as MuiToggleButton,
    ToggleButtonProps as MuiToggleButtonProps,
    ToggleButtonGroup as MuiToggleButtonGroup,
    styled
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import {
    BORDER_RADIUS,
    FONT_WEIGHTS,
    INPUT_HEIGHT,
    SPACING,
    SPACING_PX,
    useAppPalette,
    useTextColorMap
} from "../theme";
import { SxType } from "../types";
import { Icon, IconType } from "./icons";
import { Span, Text, TextColor, TextProps, TextVariant } from "./typography";
import { Column, Row } from "./containers";
import { TrackingInfo, useUiTrack } from "../tracking";

export interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    loading?: boolean;
    color?: "primary" | "secondary" | "error" | "info" | "success";
    variant?: "text" | "default" | "outlined" | "contained";
    width?: string;
    textProps?: Omit<TextProps, "children">;
    height?: number;
    tracking?: TrackingInfo;
}

export function Button({
    children,
    variant = "default",
    loading,
    width,
    height: heightProps,
    color = "primary",
    sx,
    disabled: propsDisabled,
    textProps,
    onClick,
    tracking,
    ...props
}: ButtonProps) {
    const height = heightProps ?? 40;
    const textPropsWithSx = { ...textProps };
    delete textPropsWithSx?.sx;
    const { background: backgroundColor, isDarkMode, textDisabled, inputBorder } = useAppPalette();
    const getColor = useTextColorMap();
    const baseColor = getColor(color);
    const graphicColor = getColor(color, true);

    const trackEvent = useUiTrack();

    const textSx: SxType =
        variant === "text"
            ? {
                  height: heightProps ?? "min-content",
                  minHeight: heightProps ?? "min-content",
                  py: 0,
                  px: 1.5,
                  mx: -1.5,
                  fontWeight: FONT_WEIGHTS.bold
              }
            : {};

    const { background, text, outline, hoverAlpha } = useMemo(() => {
        switch (variant) {
            case "contained":
                return {
                    background: graphicColor,
                    text: color === "secondary" ? "white" : backgroundColor,
                    outline: undefined,
                    hoverAlpha: 0.7
                };
            case "default":
                return {
                    background: colorToAlpha(baseColor, isDarkMode ? 0.1 : 0.05),
                    text: baseColor,
                    outline: undefined,
                    hoverAlpha: 0.3
                };
            case "text":
                return {
                    background: "none",
                    text: baseColor,
                    outline: undefined,
                    hoverAlpha: isDarkMode ? 0.1 : 0.05
                };
            case "outlined":
                return {
                    background: undefined,
                    text: baseColor,
                    outline: inputBorder,
                    hoverAlpha: 0.1
                };
            default:
                return { background: undefined, text: undefined, outline: undefined, hoverAlpha: undefined };
        }
    }, [backgroundColor, baseColor, graphicColor, color, inputBorder, isDarkMode, variant]);
    const disabled = propsDisabled || loading;

    return (
        <MuiButton
            color={color}
            disableElevation
            variant={variant === "default" ? "text" : variant}
            disabled={disabled}
            onClick={(e) => {
                if (tracking) trackEvent(tracking);
                if (onClick) onClick(e);
            }}
            sx={{
                position: "relative",
                ":hover": {
                    backgroundColor: colorToAlpha(baseColor, hoverAlpha),
                    border: outline ? `1px solid ${outline}` : undefined
                },
                cursor: disabled ? "not-allowed" : undefined,
                px: 1.5,
                backgroundColor: background,
                opacity: disabled && variant !== "contained" ? 0.5 : 1,
                border: outline ? `1px solid ${outline}` : undefined,
                minHeight: `${height}px`,
                maxHeight: `${height}px`,
                width: width ? width : undefined,
                maxWidth: width ? width : undefined,
                minWidth: width ? width : undefined,
                overflow: "hidden",
                borderRadius: BORDER_RADIUS,
                ...textSx,
                ...sx
            }}
            {...props}
        >
            <CircularProgress
                sx={{
                    transition: "opacity 0.2s",
                    opacity: loading ? 1 : 0,
                    position: "absolute",
                    left: 0,
                    right: 0,
                    margin: "auto"
                }}
                color="inherit"
                size="1rem"
            />
            <Text
                sx={{
                    color: disabled ? textDisabled : text,
                    textTransform: "initial",
                    opacity: loading ? 0.1 : 1,
                    transition: "opacity 0.2s",
                    fontWeight: FONT_WEIGHTS.bold,
                    ...textProps?.sx
                }}
                {...textPropsWithSx}
            >
                {children}
            </Text>
        </MuiButton>
    );
}

export const Link = styled(RouterLink)({
    color: "inherit",
    textDecoration: "none",
    display: "inherit",
    textDecorationColor: "currentColor"
});

export const ExternalLink = styled("a")({
    color: "inherit",
    textDecoration: "none",
    display: "inline",
    textDecorationColor: "currentColor"
});

export function TextExternalLink({
    href,
    children,
    sx,
    isPrimary
}: {
    href: string;
    children: ReactNode;
    sx?: SxType;
    isPrimary?: boolean;
}) {
    return (
        <ExternalLink
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                color: ({ palette }) => (isPrimary ? palette.secondary.main : palette.primary.main),
                opacity: 1,
                transition: "opacity 0.2s",
                "&:hover": {
                    opacity: 0.7
                },
                ...sx
            }}
        >
            <Span>{children}</Span>
        </ExternalLink>
    );
}

export function ConditionalLink({
    to,
    children,
    sx,
    disabled
}: {
    to?: string;
    disabled?: boolean;
    children: ReactNode;
    sx?: SxType;
}) {
    if (!to || disabled) {
        return <Box sx={{ cursor: disabled ? "not-allowed" : undefined, ...sx }}>{children}</Box>;
    }
    return (
        <Link sx={{ cursor: to ? "pointer" : "default", ...sx }} to={to || "#"}>
            {children}
        </Link>
    );
}

export interface IconButtonProps extends Omit<MuiIconButtonProps, "color"> {
    icon?: IconType;
    jsxIcon?: ReactNode;
    background?: "always" | "hover" | "never";
    textVariant?: TextVariant;
    color?: "primary" | "success" | "error";
    textColor?: TextColor;
    border?: boolean;
}
export function IconButton({
    children,
    jsxIcon,
    background = "hover",
    textVariant = "h3",
    color = "primary",
    textColor,
    icon,
    sx,
    border = true,
    ...props
}: IconButtonProps) {
    const baseColor = useTextColorMap()(color);
    return (
        <MuiIconButton
            disableRipple={background === "never"}
            color={color}
            sx={{
                width: border ? undefined : "max-content",
                p: border ? undefined : 0.5,
                border: border ? `1px solid ${colorToAlpha(baseColor, 0.1)}` : undefined,
                background: background === "always" ? colorToAlpha(baseColor, 0.05) : undefined,
                ":hover": { background: background !== "never" ? colorToAlpha(baseColor, 0.1) : undefined },
                ...sx
            }}
            {...props}
        >
            <Text variant={textVariant} color={textColor ?? color}>
                {!!icon && <Icon type={icon as IconType} />}
                {jsxIcon}
            </Text>

            {children}
        </MuiIconButton>
    );
}

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({}));

const ToggleButton = styled(MuiToggleButton)(() => ({
    fontSize: "16px",
    minHeight: INPUT_HEIGHT + "px",
    height: INPUT_HEIGHT + "px",
    maxHeight: INPUT_HEIGHT + "px",
    padding: SPACING_PX,
    gap: `${SPACING / 2}px`,
    fontWeight: FONT_WEIGHTS.normal,
    textTransform: "none",
    svg: {
        fontSize: "inherit"
    },
    borderRadius: BORDER_RADIUS
}));

export type ToggleOption<T> = {
    label?: ReactNode;
    value: NonNullable<T>;
    disabled?: boolean;
    toggleProps?: Partial<MuiToggleButtonProps>;
};
export type ToggleButtonProps<T> = {
    value: T | undefined;
    setValue: (val: T) => void;
    options: ToggleOption<T>[];
    sx?: SxType;
    onClick?: () => void;
    allowNull?: boolean;
};

export function ToggleButtons<T extends string | number>({
    value,
    setValue,
    options,
    sx,
    onClick,
    allowNull
}: ToggleButtonProps<T>) {
    return (
        <ToggleButtonGroup
            onClick={onClick}
            sx={sx}
            fullWidth
            value={value}
            onChange={(_, val) => {
                if (val === null && !allowNull) return;
                setValue(val);
            }}
            exclusive
        >
            {options.map((o, i) => (
                <ToggleButton
                    {...o.toggleProps}
                    disabled={o.disabled}
                    key={o.value.toString() + i}
                    fullWidth
                    value={o.value}
                >
                    {o.label ?? o.value}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

type ToggleButtonsProps<T> = {
    value: T | undefined;
    setValue: (val: T) => void;
    options: ToggleOption<T>[];
    containerSx?: SxType;
    onClick?: () => void;
    size?: "medium" | "small";
    variant?: "contained" | "default";
};

export function ToggleSpacedButtons<T extends string | number>({
    value,
    setValue,
    options,
    containerSx,
    onClick,
    buttonSx,
    variant = "default",
    size = "medium"
}: ToggleButtonsProps<T> & { buttonSx?: SxType }) {
    const { textSecondary, border, cardBackground, secondary } = useAppPalette();
    return (
        <Row spacing={1} onClick={onClick} sx={containerSx}>
            {options.map((o, i) => {
                const isSelected = value === o.value;
                return (
                    <Button
                        disableRipple
                        variant={(() => {
                            if (variant === "default") {
                                if (isSelected) return "default";
                                return "outlined";
                            }

                            return isSelected ? "contained" : "outlined";
                        })()}
                        onClick={() => setValue(o.value)}
                        disabled={o.disabled}
                        key={o.value.toString() + i}
                        width="max-content"
                        sx={{
                            borderRadius: "100px",
                            px: 2,
                            border,
                            background: (() => {
                                if (variant === "contained") {
                                    if (isSelected) {
                                        return secondary;
                                    }
                                    return cardBackground;
                                }

                                return undefined;
                            })(),
                            ...buttonSx
                        }}
                        value={o.value}
                        height={size === "small" ? 30 : 40}
                        textProps={{
                            variant: size === "small" ? "body2" : "h4",
                            sx: {
                                color:
                                    variant === "default" && !isSelected
                                        ? textSecondary
                                        : variant === "contained" && isSelected
                                        ? "white"
                                        : undefined
                            }
                        }}
                        color={variant === "contained" && isSelected ? "secondary" : "primary"}
                    >
                        {o.label ?? o.value}
                    </Button>
                );
            })}
        </Row>
    );
}

export function ToggleTextButtons<T extends string | number>({
    value,
    setValue,
    options,
    containerSx,
    onClick
}: ToggleButtonsProps<T>) {
    return (
        <Row spacing={1} onClick={onClick} sx={containerSx}>
            {options.map((o, i) => (
                <Button
                    variant="text"
                    onClick={() => setValue(o.value)}
                    disabled={o.disabled}
                    key={o.value.toString() + i}
                    width="max-content"
                    sx={{ opacity: value === o.value ? 1 : 0.5 }}
                    value={o.value}
                >
                    {o.label ?? o.value}
                </Button>
            ))}
        </Row>
    );
}

export function ErrorWrapper({
    errorMessage,
    children,
    fullWidth
}: {
    errorMessage: string | undefined;
    children: ReactNode;
    fullWidth?: boolean;
}) {
    const showError = !!errorMessage;
    return (
        <Column sx={{ width: fullWidth ? "100%" : undefined }}>
            <Text
                variant="body2"
                color="error"
                sx={{
                    mb: showError ? 0.5 : 0,
                    opacity: showError ? 1 : 0,
                    transition: "opacity 0.3s",
                    height: showError ? undefined : "0px"
                }}
            >
                {errorMessage}
            </Text>
            {children}
        </Column>
    );
}

interface TextButtonProps extends Omit<ButtonProps, "variant" | "color" | "textProps"> {
    to?: string;
    underlineLink?: boolean;
    color?: TextColor;
    variant?: TextVariant;
}
export function TextButton({ variant, color = "body", to, underlineLink, width, ...props }: TextButtonProps) {
    const rawColor = useTextColorMap()(color);

    const button = (
        <Button
            disableRipple
            sx={{ p: 0, m: 0, ":hover": { background: "none", opacity: 0.6 }, verticalAlign: "baseline", ...props.sx }}
            textProps={{
                color,
                underlineLink: underlineLink ? "always" : "never",
                variant,
                isLink: true,
                sx: { wordBreak: "break-all", color: rawColor }
            }}
            width={width ?? "max-content"}
            variant="text"
            {...props}
        />
    );
    if (!to) return button;
    return <ConditionalLink to={to}>{button}</ConditionalLink>;
}

export function ButtonBase({
    sx,
    disableRipple = true,
    px = 1,
    py = 0.5,
    ...props
}: ButtonBaseProps & { px?: number; py?: number }) {
    const { hoverBackground } = useAppPalette();
    return (
        <MuiButtonBase
            disableRipple={disableRipple}
            sx={{
                ":hover": { background: hoverBackground },
                gap: 1,
                mx: -px,
                my: -py,
                px,
                py,
                borderRadius: BORDER_RADIUS,
                ...sx
            }}
            tabIndex={0}
            {...props}
        />
    );
}

type ToggleChevronProps = Omit<IconButtonProps, "jsxIcon" | "children" | "textColor"> & { expanded: boolean };
export function ToggleChevron({ expanded, ...props }: ToggleChevronProps) {
    return (
        <IconButton
            {...props}
            textColor="disabled"
            jsxIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            border={props.border ?? false}
        />
    );
}
