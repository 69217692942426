import { StatColumn, StatProps } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { BsMetaUtil } from "@bridgesplit/abf-react";

import { useVaultContext } from "../../VaultContext";

export default function ParamsStats() {
    const { vaultExpanded } = useVaultContext();

    const stats: StatProps[] = [
        {
            value: formatPercent(vaultExpanded?.vaultStrategy.strategy.liquidityBuffer),
            caption: COPY.LIQUIDITY_BUFFER_TERM,
            tooltip: COPY.LIQUIDITY_BUFFER_TERM_TOOLTIP
        },
        {
            value: BsMetaUtil.formatAmount(
                vaultExpanded?.principalMetadata,
                vaultExpanded?.vaultStrategy.strategy.originationCap
            ),
            caption: "Max loan size"
        }
    ];

    return <StatColumn loading={!vaultExpanded} stats={stats} />;
}
