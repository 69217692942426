import { ReactNode, useCallback, useMemo, useState } from "react";

import { Column, Text, ToggleSpacedButtons, IconButton, Row, useResetWithCoolDown } from "@bridgesplit/ui";
import { RoleView, useAbfFetches } from "@bridgesplit/abf-react";
import { Refresh } from "@mui/icons-material";
import { COPY } from "app/constants";

import { BorrowExistingLoans, BorrowCollateralOverview } from "../borrow";
import { LendExistingStrategies } from "../lend";
import { useMarketContext } from "../common";

enum Open {
    Collateral,
    Loans,
    Strategies
}

const OPEN_DETAILS: Record<Open, { label: string; component: ReactNode }> = {
    [Open.Collateral]: { label: "Eligible collateral", component: <BorrowCollateralOverview /> },
    [Open.Loans]: { label: "My loans", component: <BorrowExistingLoans /> },
    [Open.Strategies]: {
        label: `My ${COPY.STRATEGY_TERM_PLURAL.toLowerCase()}`,
        component: <LendExistingStrategies />
    }
};

export default function MarketPositions() {
    const [openState, setOpen] = useState<Open | undefined>();
    const { view } = useMarketContext();

    const options = useMemo(() => {
        return [Open.Loans, Open.Strategies];
    }, []);

    const open = openState ?? (view === RoleView.Lend ? Open.Strategies : Open.Loans);
    const { component, label } = OPEN_DETAILS[open];

    return (
        <Column spacing={2}>
            <Row spaceBetween>
                {options.length > 1 ? (
                    <ToggleSpacedButtons
                        value={open}
                        setValue={setOpen}
                        options={options.map((value) => ({
                            value,
                            label: OPEN_DETAILS[value].label
                        }))}
                    />
                ) : (
                    <Text variant="h4"> {label} </Text>
                )}
                <PositionsRefresh />
            </Row>
            {component}
        </Column>
    );
}

function PositionsRefresh() {
    const reset = useResetWithCoolDown();
    const { resetUserAssetsApi, resetLoanApi, resetLendingStrategyApi } = useAbfFetches();

    const refresh = useCallback(() => {
        resetUserAssetsApi();
        resetLoanApi();
        resetLendingStrategyApi();
    }, [resetUserAssetsApi, resetLoanApi, resetLendingStrategyApi]);

    return <IconButton sx={{ p: 0.5 }} textVariant="body2" onClick={() => reset(refresh)} jsxIcon={<Refresh />} />;
}
