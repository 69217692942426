import { SendableTransaction, TransactionStatus } from "@bridgesplit/utils";
import { Commitment } from "@solana/web3.js";

export type TransactionWithIdentifier = {
    identifier: string;
    transaction: SendableTransaction;
    transactionActions?: TransactionAction[];
};
export type SendableTransactionWithIdentifier = {
    identifier: string;
    transaction: string;
    transactionType: TransactionType;
    transactionActions: TransactionAction[];
};

export interface TransactionWithIdentifiersAndSetup {
    setupTransactions: TransactionWithIdentifier[];
    transactions: TransactionWithIdentifier[];
}

// Transactions to send in parallel
export interface ParallelTransactionsBatch {
    transactions: TransactionWithIdentifier[];
    commitmentLevel?: Commitment;
    allowFailure?: boolean;
    bundle?: BundleOptions;
    skipBundleSim?: boolean;
}
export interface SendableParallelTransactionsBatch {
    transactions: SendableTransactionWithIdentifier[];
    commitmentLevel?: Commitment;

    // allow failure, defaults to true. If false future batches won't be sent if there are any failures in this batch
    allowFailure?: boolean;
}

export interface SendTransactionsInterface {
    batches: SendableParallelTransactionsBatch[];
    mpcIdentifier?: string;
}

export enum BundleOptions {
    Priority = 0,
    Jito = 1,
    JitoAndPriority = 2
}

export interface TransactionResult {
    signature: string;
    status: TransactionStatus;
    identifier: string;
    error?: string;
}

export type OrderedTransactions = ParallelTransactionsBatch[];

export interface AccountWithOwner {
    account: string;
    owner: string;
    mint: string;
}

export interface AccountWithOwnerWithBalance extends AccountWithOwner {
    balance: number;
}

export interface TransactionBalanceChange extends AccountWithOwner {
    newBalance: number;
    previousBalance: number;
    change: number;
}

export interface AccountChangeSummary extends AccountWithOwner {
    change: number;
}

export enum TransactionType {
    Legacy = 0,
    V0 = 1
}

export interface WalletSignedMessage {
    signature: string;
    message: string;
}

export interface TransactionAction {
    // required transaction status to execute action
    transactionStatus: TransactionStatus;
    action: TransactionActionData;
}

export enum TransactionActionType {
    WriteLoopWind = "WriteLoopWind",
    WriteLoopUnwind = "WriteLoopUnwind",
    WriteVaultDepositAndStake = "WriteVaultDepositAndStake",
    WriteVaultUnstakeAndWithdraw = "WriteVaultUnstakeAndWithdraw"
}

export type LoopWindActionMetadata = {
    loanAddress: string;
    mint: string;
    owner: string;
    leverageMultiplier: number;
};

export type LoopUnwindActionMetadata = {
    loanAddress: string;
    mint: string;
    owner: string;
};

type BaseVaultActionMetadata = {
    userWallet: string;
    principalMint: string;
    vaultAddress: string;
    stakeAccount: string;
    stakeDurationType: number;
};

export type VaultDepositAndStakeActionMetadata = BaseVaultActionMetadata & {
    isNewDeposit: boolean;
};

export type VaultUnstakeAndWithdrawActionMetadata = BaseVaultActionMetadata & {
    isFullWithdrawal: boolean;
};

type TransactionActionData =
    | { [TransactionActionType.WriteLoopWind]: LoopWindActionMetadata }
    | { [TransactionActionType.WriteLoopUnwind]: LoopUnwindActionMetadata }
    | { [TransactionActionType.WriteVaultDepositAndStake]: VaultDepositAndStakeActionMetadata }
    | { [TransactionActionType.WriteVaultUnstakeAndWithdraw]: VaultUnstakeAndWithdrawActionMetadata };
