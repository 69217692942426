import {
    useRepayLoanTransaction,
    getLoanInterestDue,
    LoanInterestDueInfo,
    LoanInterestDueParams,
    useAbfFetches,
    summarizeLedgerAccount
} from "@bridgesplit/abf-react";
import { LOADING_ERROR, NullableRecord, Result, filterNullableRecord, uiAmountToLamports } from "@bridgesplit/utils";
import { TrackTransactionEvent } from "app/types";
import { RepayLoanTxnParams } from "@bridgesplit/abf-sdk";
import { trackSubmitRepay } from "app/hooks";

import { useTransactionSender } from "../../../transactions";
import { MakeRepaymentParams } from "./types";

export function useRepay(props: MakeRepaymentParams) {
    const { loanExpanded, payment, isFullRepay } = props;
    const send = useTransactionSender();
    const { resetLoanApi, resetMarketsPublicApi } = useAbfFetches();

    function resetRepay() {
        resetLoanApi(); // reset loan state
        resetMarketsPublicApi(); // reset orderbook quotes to include repaid amount
    }

    const repay = useRepayLoanTransaction();

    async function repayLoan() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);

        const { principalToPay, interestToPay } = payment;

        const params: RepayLoanTxnParams = {
            loan: loanExpanded.loan.address,
            repayParams: {
                amount: uiAmountToLamports(principalToPay + interestToPay, loanExpanded.principalMetadata.decimals),
                ledgerIndex: 0, // TODO: update when multiple ledgers are supported
                repayAll: isFullRepay
            }
        };

        return await send(repay, params, {
            description: "Repaying loan",
            sendOptions: { refetch: () => resetRepay() },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitRepay,
                params: trackSubmitRepay(params, loanExpanded.principalMetadata)
            }
        });
    }

    return {
        repay: () => repayLoan()
    };
}

export function calculateNewLoanInterestDue(params: NullableRecord<LoanInterestDueParams>): LoanInterestDueInfo {
    if (!filterNullableRecord(params)) {
        const originalLedgers = summarizeLedgerAccount(params.ledgerAccount);

        return {
            repaymentAmount: params.repaymentAmount ?? 0,
            principalToPay: 0,
            interestToPay: 0,
            newTotalInterestOutstanding: originalLedgers.interestOutstanding,
            isInterestRecalculated: false,
            newTotalInterestDue: originalLedgers.interestDue
        };
    }

    const newLoanInterest = getLoanInterestDue(params);

    return newLoanInterest;
}
