import { useMemo } from "react";

import {
    Collateral,
    MarketExpanded,
    useAuth,
    useOraclePrices,
    useSummarizedUserAssets,
    useTokenListQuery,
    useTokenListMetadataByMint,
    usePresetMarketInfos
} from "@bridgesplit/abf-react";
import { NullableRecord, filterNullableRecord } from "@bridgesplit/utils";

import { CollateralMarket } from "./types";

export function useCollateralMarkets(principalMint: string) {
    // directly use token list to prevent principal mint from being filtered out
    const tokens = useTokenListQuery().data?.filter((t) => t.isCollateral);

    const { getMarketByCollateralAndPrincipalMint } = usePresetMarketInfos({
        filter: {
            principalMints: [principalMint]
        }
    });

    const principalMetadata = useTokenListMetadataByMint(principalMint);

    const { getOracle } = useOraclePrices(tokens?.map((t) => t.mint));

    const { balanceMap: userBalanceMap, isLoading } = useSummarizedUserAssets({
        includeLpPositions: true
    });
    const { isAuthenticated } = useAuth();

    const collateral = useMemo(() => {
        if (!tokens || (!userBalanceMap && isAuthenticated)) return undefined;
        return tokens
            .map((t): NullableRecord<Collateral> => {
                const oracle = getOracle(t.mint) ?? null;
                const userBalance = userBalanceMap?.get(t.mint);
                return {
                    metadata: t,
                    oracle,
                    usdPrice: oracle?.usdPrice ?? 0,
                    amount: userBalance?.available ?? 0,
                    usdValue: userBalance?.availableUsd ?? 0
                };
            })
            .filter(filterNullableRecord);
    }, [getOracle, isAuthenticated, tokens, userBalanceMap]);

    return useMemo(() => {
        if (!collateral || !userBalanceMap || isLoading) return undefined;

        return collateral
            .map(({ metadata, usdValue, amount }): NullableRecord<CollateralMarket> => {
                const market = getMarketByCollateralAndPrincipalMint(metadata.mint, principalMint);
                return {
                    principalMetadata,
                    metadata,
                    oracle: getOracle(metadata.mint) ?? null,
                    markets: market ? [market.ltvInfo] : undefined,
                    usdValue,
                    userBalance: amount
                };
            })
            .filter(filterNullableRecord);
    }, [
        collateral,
        getMarketByCollateralAndPrincipalMint,
        getOracle,
        isLoading,
        principalMetadata,
        principalMint,
        userBalanceMap
    ]);
}

export function useMarketSummaryStats(markets: MarketExpanded[] | undefined) {
    return useMemo(() => {
        if (!markets) return undefined;
        let principalUtilized = 0;
        let principalOriginated = 0;
        let availableDeposits = 0;

        for (const { principalStats, strategyStats, usdPrice } of markets) {
            const price = usdPrice ?? 0;
            principalOriginated += principalStats.principalOriginated * price;
            principalUtilized += principalStats.principalUtilized * price;
            availableDeposits += strategyStats.totalDeposits * price;
        }

        const totalDeposits = principalUtilized + availableDeposits;
        return { principalUtilized, principalOriginated, totalDeposits, availableDeposits };
    }, [markets]);
}
