import { createRows, Image, Row, Table, TableColumn, Text } from "@bridgesplit/ui";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { getOracleMeta } from "app/components/common";
import { formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { TermsColumn, VaultSummarizedTerms } from "./type";
import { CollateralInfo } from "./common";

export default function TermsTable({
    terms,
    visibleColumns
}: {
    terms: VaultSummarizedTerms[] | undefined;
    visibleColumns: Set<TermsColumn>;
}) {
    return (
        <Table
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: "allocation"
            }}
            emptyPlaceholder="No terms"
            loading={!terms}
            rows={createRows(terms, (s) => s.collateralMetadata.mint)}
            columns={columns.filter((c) => visibleColumns.has(c.dataIndex))}
        />
    );
}

const columns: (TableColumn<VaultSummarizedTerms> & { dataIndex: TermsColumn })[] = [
    {
        dataIndex: TermsColumn.Collateral,
        title: "Collateral",
        render: ({ data }) => <CollateralInfo metadata={data.collateralMetadata} />,
        sortBy: ({ data }) => BsMetaUtil.getSymbolUnique(data.collateralMetadata)
    },
    {
        dataIndex: TermsColumn.Apy,
        title: "APY",
        align: "right",
        render: ({ data }) => <> {formatPercent(data.apy)} </>,
        sortBy: ({ data }) => data.apy ?? 0
    },
    {
        dataIndex: TermsColumn.Ltv,
        title: "LTV",
        align: "right",
        render: ({ data }) => <> {formatPercent(data.ltv)} </>,
        sortBy: ({ data }) => data.ltv
    },
    {
        dataIndex: TermsColumn.LiquidationThreshold,
        title: (
            <Text sx={{ minWidth: "max-content" }} color="caption" variant="body2">
                {COPY.LIQUIDATION_THRESHOLD_TERM}
            </Text>
        ),
        toolTip: COPY.LIQUIDATION_THRESHOLD_TOOLTIP,
        align: "right",
        render: ({ data }) => <> {formatPercent(data.liquidationThreshold)} </>,
        sortBy: ({ data }) => data.liquidationThreshold
    },
    {
        dataIndex: TermsColumn.Oracle,
        title: "Oracle",
        align: "right",
        render: ({ data }) => {
            const meta = getOracleMeta(data.oracleType);
            if (!meta) return null;
            return (
                <Row spacing={1}>
                    <Image src={meta.image} size="20px" variant="circle" />
                    <Text>{meta.name}</Text>
                </Row>
            );
        },
        sortBy: ({ data }) => data.oracleType
    }
];
