import { ReactNode, createContext, useCallback, useContext, useState } from "react";

import { DispatchType } from "@bridgesplit/react";
import { useStrategyDurations } from "@bridgesplit/abf-react";
import { StrategyDuration } from "@bridgesplit/abf-sdk";

import { initialLendForm, MarketLendForm } from "./type";
import { useMarketContext } from "../common";

type ContextState = {
    form: MarketLendForm;
    setForm: DispatchType<MarketLendForm>;
    resetForm: () => void;
    strategyDurations: StrategyDuration[] | undefined;
    isTemplateBased: boolean;
};
const MarketLendContext = createContext<ContextState | null>(null);

export function useMarketLendContext() {
    const context = useContext(MarketLendContext);
    if (!context) {
        throw new Error("useMarketLendContext must be used within a MarketLendProvider");
    }
    return context;
}

export function MarketLendProvider({ children }: { children: ReactNode }) {
    const { isDialog } = useMarketContext();
    const [form, setForm] = useState<MarketLendForm>(initialLendForm);

    const resetForm = useCallback(() => {
        setForm(initialLendForm);
    }, [setForm]);

    const { strategyDurations } = useStrategyDurations();

    return (
        <MarketLendContext.Provider
            value={{
                form,
                setForm,
                resetForm,
                strategyDurations,
                isTemplateBased: isDialog
            }}
        >
            {children}
        </MarketLendContext.Provider>
    );
}
