import { StatColumn, StatProps, TextVariant } from "@bridgesplit/ui";
import { formatUsd } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useVaultContext } from "../VaultContext";
import { useVaultWithdrawContext } from "./VaultWithdrawContext";

const variant: TextVariant = "body1";
export default function VaultWithdrawStats() {
    const { form } = useVaultWithdrawContext();
    const { vaultExpanded, userPosition } = useVaultContext();
    const usdValue = vaultExpanded?.principalOracle.getUsdAmount(form.amountPrincipal);

    if (!form.amountPrincipal || !userPosition) return null;

    const postWithdrawValue = form.isMaxWithdraw
        ? 0
        : Math.max(0, userPosition.amountAvailable.amountUsd - (usdValue ?? 0));

    const stats: StatProps[] = [
        {
            caption: COPY.USER_POSITION_TERM,
            value: [formatUsd(userPosition.amountAvailable.amountUsd), formatUsd(postWithdrawValue)]
        }
    ];

    return <StatColumn variant={variant} stats={stats} />;
}
