import { StatProps } from "@bridgesplit/ui";
import { formatPercent, formatUsd } from "@bridgesplit/utils";

import { PortfolioTableStats } from "./common";
import { usePortfolioStats } from "./util";

export default function LendVaultStats() {
    const lendVaultStats = usePortfolioStats()?.lendVaultStats;

    const stats: StatProps[] = [
        {
            value: formatUsd(lendVaultStats?.totalValueUsd),
            caption: "Total supplied"
        },
        {
            value: formatPercent(lendVaultStats?.wAvgApy),
            caption: "APY"
        }
    ];

    return <PortfolioTableStats stats={stats} loading={!lendVaultStats} />;
}
