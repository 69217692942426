import { Row, Tooltip } from "@bridgesplit/ui";
import { Divider } from "@mui/material";

import { BaseApy } from "./BaseApy";
import { ExternalRewardsDisplay, RateDisplay, TooltipContent } from "./common";
import { RewardsDailyPoints } from "./daily-rewards/RewardsDailyPoints";
import { RewardsExternalPointsRewards } from "./external-point-rewards/RewardsExternalPointsReward";
import { RewardsLendApyProps } from "./types";

export function RewardsLendApy(props: RewardsLendApyProps) {
    return (
        <Tooltip title={<RewardsLendTooltip {...props} />} reverseColors>
            <Row spacing={0.5}>
                <ExternalRewardsDisplay {...props} />
                <RateDisplay apy={props.apy} variant="body1" color="body" />
            </Row>
        </Tooltip>
    );
}

function RewardsLendTooltip(props: RewardsLendApyProps) {
    return (
        <TooltipContent>
            <BaseApy {...props} />
            <Divider />
            <RewardsDailyPoints {...props} />
            <Divider />
            <RewardsExternalPointsRewards {...props} />
        </TooltipContent>
    );
}
