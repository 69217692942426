import { LoopscalePointsAction, useLoanMultipliersUtil, useRewardsPointsPerSecond } from "@bridgesplit/abf-react";
import { StatProps, StatColumn, IconWithBackground, Icon } from "@bridgesplit/ui";
import { formatNum } from "@bridgesplit/utils";
import { TokenListMetadata } from "@bridgesplit/abf-sdk";

import { CaptionWithIcon } from "../common";
import { useRewardTagColor } from "../util";

// Rewards daily points display supports loops

export interface RewardsCustomPointsProps {
    loopscalePointsAction: LoopscalePointsAction;
    metadata: TokenListMetadata | undefined;
    customDailyPoints: number | undefined;
}
export function RewardsDailyCustomPoints({
    loopscalePointsAction,
    metadata,
    customDailyPoints
}: RewardsCustomPointsProps) {
    const { isLoading: loanMultipliersLoading } = useLoanMultipliersUtil();
    const { isLoading: rewardPointsPerSecondLoading } = useRewardsPointsPerSecond();

    const loading = loanMultipliersLoading || rewardPointsPerSecondLoading;

    const stats: StatProps[] = [
        {
            caption: (
                <RewardsDailyCustomPointsCaption loopscalePointsAction={loopscalePointsAction} metadata={metadata} />
            ),
            value: formatNum(customDailyPoints)
        }
    ];

    return <StatColumn stats={stats} loading={loading} />;
}

function RewardsDailyCustomPointsCaption({
    loopscalePointsAction,
    metadata
}: Omit<RewardsCustomPointsProps, "customDailyPoints">) {
    const { loanMultipliers } = useLoanMultipliersUtil();
    const tagColor = useRewardTagColor(loanMultipliers, metadata?.mint, [loopscalePointsAction]);

    return (
        <CaptionWithIcon
            icon={
                <IconWithBackground tagColor={tagColor} size={20}>
                    <Icon type="points" />
                </IconWithBackground>
            }
            caption={`Daily points`}
        />
    );
}
