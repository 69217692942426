import { useMemo } from "react";

import {
    MIN_SOL_BALANCE,
    NullableRecord,
    WRAPPED_SOL_MINT,
    bsMath,
    filterNullableRecord,
    lamportsToUiAmount
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useTokensByWalletQuery } from "../reducers";
import { PriceFetchType, TokenBalanceExpanded } from "../types";
import { useActiveWallet } from "./wallet";
import { useWhirlpoolPositions, whirlpoolPositionToToken } from "./whirlpool";
import { useOraclePrices } from "./pricing";

type AssetParams = {
    skip?: boolean;
    fullSolBalance?: boolean;
    includeLpPositions?: boolean;
};

// Get the available assets for user to deposit
export function useUserAvailableAssets({ skip, fullSolBalance, includeLpPositions }: AssetParams) {
    const { activeWallet, user } = useActiveWallet();

    const walletAssets = useWalletAvailableAssets(activeWallet, {
        skip: !activeWallet || skip,
        fullSolBalance,
        includeLpPositions
    });

    const isNotAuthenticated = useMemo(() => !activeWallet && !user, [activeWallet, user]);

    const data = useMemo(() => {
        if (isNotAuthenticated) return [];
        return walletAssets;
    }, [isNotAuthenticated, walletAssets]);

    const isLoading = useMemo(() => {
        if (isNotAuthenticated) return false;
        return walletAssets === undefined;
    }, [isNotAuthenticated, walletAssets]);

    return { data, isLoading };
}

export function useWalletAvailableAssets(wallet: string | undefined, params: AssetParams) {
    const { data: rawData, isLoading: tokensLoading } = useTokensByWalletQuery(wallet ?? skipToken, {
        skip: !wallet || params?.skip
    });

    const possibleWpNfts = rawData?.tokens
        .filter((t) => t.tokenAmount.uiAmount === 1 && !(t.mint in rawData.metadataMap))
        .map((m) => m.mint);
    const { data: wp, isLoading: wpLoading } = useWhirlpoolPositions(possibleWpNfts, {
        skip: !params?.includeLpPositions
    });

    const whirlpoolsAsTokens = wp?.map(whirlpoolPositionToToken);

    if (tokensLoading || (wpLoading && params?.includeLpPositions)) return undefined;
    return rawData?.tokens
        .map(
            ({
                mint,
                tokenAmount: { uiAmount: uiAmountRaw, amount, decimals }
            }): NullableRecord<TokenBalanceExpanded> => {
                const metadata = rawData ? rawData.metadataMap?.[mint] : undefined;

                const uiAmount = uiAmountRaw !== null ? uiAmountRaw : lamportsToUiAmount(parseInt(amount), decimals);
                const uiAmountAdjusted =
                    !params?.fullSolBalance && mint === WRAPPED_SOL_MINT
                        ? Math.max(0, bsMath.tokenSub(uiAmount, MIN_SOL_BALANCE, decimals))
                        : uiAmount;

                return {
                    key: mint,
                    metadata,
                    amount: uiAmountAdjusted
                };
            }
        )
        .concat(...(whirlpoolsAsTokens ?? []))
        .filter(filterNullableRecord)
        .filter(({ amount }) => !!amount);
}

export function useWalletBalanceByMint(
    wallet: string | undefined,
    mint: string | undefined,
    params: { skip?: boolean; includeStakedSol?: boolean; fullSolBalance?: boolean }
) {
    const assets = useWalletAvailableAssets(wallet, params);
    const data = assets?.find((a) => a.metadata.mint === mint);

    return { data, isLoading: !assets };
}

export function useUserEscrowsAndWalletSummarized(options?: {
    skipWallet?: boolean;
    customWallet?: string;
    includeStakedSol?: boolean;
}): TokenBalanceExpanded[] | undefined {
    const { activeWallet } = useActiveWallet();
    const wallet = options?.customWallet ?? activeWallet;
    const walletAssets = useWalletAvailableAssets(wallet, {
        skip: options?.skipWallet || !wallet
    });

    if (!walletAssets) return undefined;

    const amountMap = new Map<string, TokenBalanceExpanded>();

    for (const asset of walletAssets) {
        const prevAmount = amountMap.get(asset.metadata.mint)?.amount ?? 0;
        amountMap.set(asset.metadata.mint, { ...asset, amount: prevAmount + asset.amount });
    }

    return Array.from(amountMap.values());
}

export function useUserUnrecognizedAssets(skip?: boolean) {
    const { activeWallet } = useActiveWallet();

    const { data: rawData, isLoading } = useTokensByWalletQuery(activeWallet ?? skipToken, {
        skip: !activeWallet || skip
    });

    const data = rawData?.tokens.filter((t) => !(t.mint in rawData.metadataMap));

    return { data, isLoading };
}

export function useSummarizedUserAssets(params: AssetParams) {
    const { data: balances, isLoading } = useUserAvailableAssets(params);
    const { getUsdPrice } = useOraclePrices(
        balances?.map((b) => b.key),
        PriceFetchType.Twap
    );

    const balanceMap = useMemo(() => {
        if (!balances) return undefined;

        const balanceMap = balances.reduce((map, curr) => {
            // use the metadata key for things like wp
            const key = (() => {
                if (curr.whirlpoolPosition) return curr.whirlpoolPosition.whirlpoolAddress;
                return curr.metadata.mint;
            })();
            const prev = map.get(key) ?? { available: 0, availableUsd: 0 };
            const currentUsdValue = (getUsdPrice(key) ?? 0) * curr.amount;

            if (curr.whirlpoolPosition) {
                map.set(key, {
                    available: prev.available + 1,
                    availableUsd: Math.max(prev.availableUsd, curr.whirlpoolPosition.totalPrice ?? 0)
                });
            } else {
                map.set(key, {
                    available: prev.available + curr.amount,
                    availableUsd: prev.availableUsd + currentUsdValue
                });
            }
            return map;
        }, new Map<string, { available: number; availableUsd: number }>());

        return balanceMap;
    }, [balances, getUsdPrice]);

    return { balanceMap, balances, isLoading };
}
