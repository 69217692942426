import { createApi } from "@reduxjs/toolkit/query/react";
import { ExternalYieldVault } from "@bridgesplit/abf-sdk";
import { useDispatch } from "react-redux";

import { ROUTE_GET_EXTERNAL_YIELD_INFO } from "../constants";
import { abfSerializeQueryArgs, marketsBaseQuery } from "./util";

const EXTERNAL_YIELD_API = "ExternalYield";
const USER_MARGINFI_TAG = "UserMarginFi";

// NOTE: `useExternalYieldInterestQuery` lives in napoleonApi.ts
export const externalYieldApi = createApi({
    reducerPath: "externalYieldApi",
    baseQuery: marketsBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [EXTERNAL_YIELD_API, USER_MARGINFI_TAG],
    endpoints: (builder) => ({
        externalYieldInfo: builder.query<ExternalYieldVault[], void>({
            query() {
                return {
                    url: ROUTE_GET_EXTERNAL_YIELD_INFO,
                    method: "GET"
                };
            }
        })
    })
});

export const useExternalYieldApi = () => {
    const api = externalYieldApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchExternalYieldInfo] = api.externalYieldInfo.useLazyQuery();

    return {
        resetExternalYieldApi: () =>
            dispatch(externalYieldApi.util.invalidateTags([EXTERNAL_YIELD_API, USER_MARGINFI_TAG])),
        fetchExternalYieldInfo
    };
};

export const { useExternalYieldInfoQuery } = externalYieldApi;
