import { StrategyDuration } from "@bridgesplit/abf-sdk";
import { Result } from "@bridgesplit/utils";

export function serializeStrategyDuration(strategyDuration: StrategyDuration) {
    const duration = strategyDuration.duration;
    const durationType = strategyDuration.durationType;
    return JSON.stringify({ duration, durationType });
}

export function deserializeStrategyDuration(serializedStrategyDuration: string): Result<StrategyDuration> {
    const strategyDuration = JSON.parse(serializedStrategyDuration) as StrategyDuration;
    if (strategyDuration.duration === undefined || strategyDuration.durationType === undefined) {
        return Result.errFromMessage("Invalid duration");
    }
    return Result.ok(strategyDuration);
}

export function deserializeStrategyDurationOrUndefined(
    serializedStrategyDuration: string
): StrategyDuration | undefined {
    return deserializeStrategyDuration(serializedStrategyDuration).unwrapOrUndefined();
}

export function isStrategyDurationSame(a: StrategyDuration | undefined, b: StrategyDuration | undefined) {
    if (!a || !b) return false;

    return serializeStrategyDuration(a) === serializeStrategyDuration(b);
}
