import { createContext, useCallback, useContext, useState } from "react";

import { DispatchType } from "@bridgesplit/react";

import { VaultWithdrawForm } from "./type";

interface VaultWithdrawContextState {
    form: VaultWithdrawForm;
    setForm: DispatchType<VaultWithdrawForm>;
    resetWithdrawForm: () => void;
}

const VaultWithdrawContext = createContext<VaultWithdrawContextState | null>(null);

export function useVaultWithdrawContext(): VaultWithdrawContextState {
    const context = useContext(VaultWithdrawContext);
    if (context === null) {
        throw new Error("useVaultWithdrawContext must be used within a VaultWithdrawProvider");
    }
    return context;
}

export function VaultWithdrawProvider({ children }: { children: React.ReactNode }) {
    const [form, setForm] = useState<VaultWithdrawForm>({ amountPrincipal: undefined, isMaxWithdraw: false });

    const resetWithdrawForm = useCallback(() => {
        setForm({ amountPrincipal: undefined, isMaxWithdraw: false });
    }, []);

    return (
        <VaultWithdrawContext.Provider value={{ form, setForm, resetWithdrawForm }}>
            {children}
        </VaultWithdrawContext.Provider>
    );
}
