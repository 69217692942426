import { useMemo } from "react";

import { useActiveWallet } from "@bridgesplit/abf-react";
import { useUserPublicKey } from "@bridgesplit/react";

import { WalletConnectError } from "./type";

export function useConnectingWalletErrors() {
    const { isLoading } = useActiveWallet();

    const pubkey = useUserPublicKey();

    const error: WalletConnectError = useMemo(() => {
        if (isLoading) return WalletConnectError.None;
        if (!pubkey) return WalletConnectError.NotConnected;

        return WalletConnectError.None;
    }, [isLoading, pubkey]);

    return { error, isLoading };
}
