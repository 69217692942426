import {
    LoopVaultInfo,
    OracleQuote,
    MeteoraLoopMetadata,
    UserLoopInfo,
    StrategyDuration,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";

import { BestQuote, BorrowCap, MarketPrincipalStats } from "./market";
import { AbfLoanExpanded } from "./loan";

export interface LoopExpandedBase {
    collateralToken: TokenListMetadata;
    principalToken: TokenListMetadata;
    vaultIdentifier: string;
    loopVault: LoopVaultInfo;
    principalOracle: OracleQuote;
    collateralOracle: OracleQuote;
    principalStats: MarketPrincipalStats;
    borrowCap: BorrowCap;
    maxCollateralAmount: number;
    aumLimit?: number;
}

export interface LoopExpandedMeteora extends LoopExpandedBase {
    depositType: LoopTransferType.CollateralOnly;
    withdrawType: LoopTransferType.PrincipalOnly;
    meteoraPool: MeteoraLoopMetadata;
    tokenA: TokenListMetadata;
    tokenB: TokenListMetadata;
}

export interface LoopExpandedStabble extends LoopExpandedBase {
    depositType: LoopTransferType.CollateralOnly;
    withdrawType: LoopTransferType.PrincipalOnly;
}

export interface LoopExpandedJupiter extends LoopExpandedBase {
    depositType: LoopTransferType.CollateralOnly;
    withdrawType: LoopTransferType.CollateralOnly;
}

export type LoopExpanded = LoopExpandedMeteora | LoopExpandedJupiter | LoopExpandedStabble;

export enum LoopTransferType {
    CollateralOnly,
    PrincipalOnly
}

export interface LoopPositionExpanded {
    loanAddress: string;
    userLoopInfo: UserLoopInfo;
    loanExpanded: AbfLoanExpanded;
    loopExpanded: LoopExpanded;
}

export interface BestLoopQuoteQuery {
    loopVault: string;
    collateralAmount: number;
    leverageMultiplier: number;
    duration: StrategyDuration;
}

export interface BestLoopQuote {
    bestQuote: BestQuote;
    netApyPct: number;
}

export interface LoopQuoteExpanded extends BestLoopQuote {
    collateralAmount: number;
    leveragedCollateralAmount: number;
    principalAmount: number;
    leverageMultiplier: number;
}

export type ExternalWindQuoteParams = {
    loopExpanded: LoopExpanded | undefined;
    slippagePercentDecimals: number;
    principalAmount: number | undefined;
    skip?: boolean;
};

export type ExternalUnwindQuoteParams = {
    loopPositionExpanded: LoopPositionExpanded | undefined;
    slippagePercentDecimals: number;
    skip?: boolean;
    disablePoll?: boolean;
};

export interface LoopPositionStats {
    netPositionValue: number;
    wAvgApy: number;
}
