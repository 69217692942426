import { getUnixTs } from "@bridgesplit/utils";

import { AbfLoanExpanded, Ledger, LedgerExpanded } from "../types";
import { getSimpleInterest } from "./rrule";

/**
 * Previously, loan duration used an incorrect constant for YEAR in seconds,
 * which resulted in a minor difference for calculating ledgers from a schedule
 * @returns the seconds in a year used for a loan's APY calc
 */

export function summarizeLedgerAccount(ledgerAccount: LedgerExpanded | Ledger | undefined) {
    const endTime = ledgerAccount?.endTime ?? 0;

    const now = getUnixTs();

    const timeRemaining = endTime - now;

    // handle overpaid principal
    const principalDue = ledgerAccount ? Math.max(ledgerAccount.principalDue, ledgerAccount.principalRepaid) : 0;
    const principalPaid = ledgerAccount?.principalRepaid ?? 0;
    const principalOutstanding = Math.max(principalDue - principalPaid, 0);

    const interestDue = ledgerAccount?.interestDue ?? 0;
    const interestPaid = ledgerAccount?.interestRepaid ?? 0;
    const interestOutstanding = Math.max(interestDue - interestPaid, 0);

    const totalDue = interestDue + principalDue;
    const totalPaid = principalPaid + interestPaid;
    const apy = ledgerAccount?.apy ?? 0;
    const totalOutstanding = principalOutstanding + interestOutstanding;

    const sideMultiplier = timeRemaining < 0 ? -1 : 1;
    const interestRemaining = getSimpleInterest({
        principal: principalOutstanding,
        apy,
        loanDuration: sideMultiplier * timeRemaining,
        subtractPrincipal: true
    });
    const accruedInterestOutstanding = interestOutstanding - sideMultiplier * interestRemaining;
    const accruedTotalOutstanding = principalOutstanding + accruedInterestOutstanding;

    return {
        interestDue,
        principalDue,
        interestPaid,
        principalPaid,
        totalDue,
        endTime,
        apy,
        totalPaid,
        accruedTotalOutstanding,
        totalOutstanding,
        principalOutstanding,
        accruedInterestOutstanding,
        interestOutstanding
    };
}

export function calculateNewInterestDueForSimpleLoan({
    loanExpanded,
    repaymentAmount,
    ledgerAccount,
    paymentTime = getUnixTs()
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    repaymentAmount: number | undefined;
    ledgerAccount: LedgerExpanded | Ledger | undefined;
    paymentTime?: number;
}): number {
    if (!loanExpanded || !repaymentAmount || !ledgerAccount) return 0;

    // Get final payment time offset from the ledger account
    const ledgerDueTime = ledgerAccount?.endTime ?? 0;
    if (paymentTime > ledgerDueTime) {
        const additionalInterestDue = calculateInterestDueForLoanInGracePeriod({
            loanExpanded,
            ledgerAccount,
            paymentTime
        });
        return ledgerAccount.interestDue + additionalInterestDue; // if in grace period, add interest due
    } else {
        const ledgerSummary = summarizeLedgerAccount(ledgerAccount);
        const interestSaved = calculateNewInterestSavedForSimpleLoan({
            repaymentAmount,
            apy: ledgerAccount.apy,
            principalOutstanding: ledgerSummary.principalOutstanding,
            finalPaymentTimeOffset: ledgerAccount.endTime
        });
        return Math.max(0, ledgerAccount.interestDue - interestSaved);
    }
}

function calculateInterestDueForLoanInGracePeriod({
    loanExpanded,
    ledgerAccount,
    paymentTime
}: {
    loanExpanded: AbfLoanExpanded;
    ledgerAccount: LedgerExpanded | Ledger;
    paymentTime: number;
}) {
    const timeInGracePeriod = paymentTime - ledgerAccount.endTime;
    const interestAccruedTime = Math.max(timeInGracePeriod, 0);

    const ledgerSummary = summarizeLedgerAccount(ledgerAccount);

    if (interestAccruedTime > 0) {
        const principalOutstanding = ledgerSummary.principalOutstanding;

        const newInterestDue = getSimpleInterest({
            principal: principalOutstanding,
            apy: loanExpanded.borrowerWAvgApy,
            loanDuration: interestAccruedTime,
            subtractPrincipal: true
        });

        return newInterestDue;
    } else {
        return 0;
    }
}

function calculateNewInterestSavedForSimpleLoan({
    repaymentAmount,
    apy,
    principalOutstanding,
    finalPaymentTimeOffset
}: {
    repaymentAmount: number;
    apy: number;
    principalOutstanding: number;
    finalPaymentTimeOffset: number;
}) {
    const timeLeftUntilLoanEnd = finalPaymentTimeOffset - getUnixTs();

    const principalRepaymentAmount = Math.min(principalOutstanding, repaymentAmount);
    const futureInterestSaved = getSimpleInterest({
        principal: principalRepaymentAmount,
        apy,
        loanDuration: timeLeftUntilLoanEnd,
        subtractPrincipal: true
    });
    return futureInterestSaved;
}
