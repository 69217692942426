import { useCallback } from "react";

import { Row, Span, TextSkeleton } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { Settings } from "@mui/icons-material";
import { useAbfFetches } from "@bridgesplit/abf-react";
import { formatPercent } from "@bridgesplit/utils";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";

import { useWindContext } from "./WindContext";
import { useLoopContext } from "../LoopContext";
import { RefreshButton, SettingsButton } from "../../common";

export default function WindTopButtons() {
    const { open } = useAppDialog();
    const { form, setForm } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const reset = useReset();

    return (
        <Row spacing={1}>
            <SettingsButton
                disabled={!loopExpanded}
                onClick={() => {
                    if (!loopExpanded) return;
                    open(AppDialog.WindSettings, { loopExpanded, form, setForm });
                }}
            >
                <Settings />
                {form.percentSlippageDecimals === undefined ? (
                    <TextSkeleton variant="caption" width="30px" />
                ) : (
                    <Span>{formatPercent(form.percentSlippageDecimals ?? 0, { minimumSignificantDigits: 1 })}</Span>
                )}
                <Span>{formatDurationWithTypeShorthand(form.strategyDuration)}</Span>
            </SettingsButton>
            <RefreshButton refresh={reset} />
        </Row>
    );
}

function useReset() {
    const { resetNapoleonApi, resetJupiterSwapApi, resetMeteoraApi, resetUserAssetsApi } = useAbfFetches();

    return useCallback(() => {
        resetNapoleonApi();
        resetJupiterSwapApi();
        resetMeteoraApi();
        resetUserAssetsApi();
    }, [resetNapoleonApi, resetJupiterSwapApi, resetMeteoraApi, resetUserAssetsApi]);
}
