import { useCallback, useState } from "react";

import { LendVaultSortType, StakeStatus, UserLendingVaultFilter, LendVaultsPagination } from "@bridgesplit/abf-react";
import { useLocalStorage } from "@bridgesplit/react";
import { SortDirection, SortOrder } from "@bridgesplit/utils";
import { PaginationController } from "@bridgesplit/ui";

const DEFAULT_SORT_SIDE = SortDirection.Desc;
const DEFAULT_SORT_TYPE = LendVaultSortType.TotalDeposits;

export function useLendingVaultPagination() {
    const [sortDirection, setSortDirection] = useLocalStorage<SortDirection>("LendVaultsOrder", DEFAULT_SORT_SIDE);
    const [sortType, setSortType] = useLocalStorage<LendVaultSortType>("LendVaultsSort", DEFAULT_SORT_TYPE);
    const [page, setPage] = useState<number>(0);
    const [principalMint, setPrincipalMint] = useState<string | undefined>(undefined);

    const tableController: PaginationController = {
        page,
        setPage,
        order: sortDirection === SortDirection.Asc ? SortOrder.Asc : SortOrder.Desc,
        setOrder: (order) => setSortDirection(order === SortOrder.Asc ? SortDirection.Asc : SortDirection.Desc),
        orderBy: sortType.toString(),
        setOrderBy: (orderBy) => {
            const order = parseInt(orderBy);
            if (!Object.values(LendVaultSortType).includes(order)) return;

            setSortType(order as LendVaultSortType);
        },
        resetPage: () => setPage(0)
    };

    const params: LendVaultsPagination = {
        principalMints: principalMint ? [principalMint] : [],
        sortType,
        sortDirection,
        page,
        pageSize: 5
    };

    const reset = useCallback(() => {
        setSortDirection(DEFAULT_SORT_SIDE);
        setSortType(DEFAULT_SORT_TYPE);
        setPage(0);
    }, [setSortDirection, setSortType, setPage]);

    return {
        page,
        params,
        tableController,
        reset,
        principalMint,
        setPrincipalMint,
        setSortDirection,
        setSortType
    };
}

export function useLendingPositionPagination() {
    const [status, setStatus] = useState<StakeStatus>(StakeStatus.Active);
    const [sortDirection, setSortDirection] = useLocalStorage<SortDirection>("LendVaultsOrder", DEFAULT_SORT_SIDE);
    const [sortType, setSortType] = useLocalStorage<LendVaultSortType>("LendVaultsSort", DEFAULT_SORT_TYPE);
    const [page, setPage] = useState<number>(0);
    const [principalMint, setPrincipalMint] = useState<string | undefined>(undefined);
    const [vaultAddresses, setVaultAddresses] = useState<string[]>([]);
    const [lpMints, setLpMints] = useState<string[]>([]);
    const [depositsEnabled, setDepositsEnabled] = useState<boolean | undefined>(undefined);

    const tableController: PaginationController = {
        page,
        setPage,
        order: sortDirection === SortDirection.Asc ? SortOrder.Asc : SortOrder.Desc,
        setOrder: (order) => setSortDirection(order === SortOrder.Asc ? SortDirection.Asc : SortDirection.Desc),
        orderBy: sortType.toString(),
        setOrderBy: (orderBy) => {
            const order = parseInt(orderBy);
            if (!Object.values(LendVaultSortType).includes(order)) return;

            setSortType(order as LendVaultSortType);
        },
        resetPage: () => setPage(0)
    };

    const params: UserLendingVaultFilter = {
        vaultAddresses,
        principalMints: principalMint ? [principalMint] : [],
        lpMints,
        depositsEnabled,
        sortType,
        sortDirection,
        page,
        pageSize: 5,
        status
    };

    const reset = useCallback(() => {
        setStatus(StakeStatus.Active);
        setSortDirection(DEFAULT_SORT_SIDE);
        setSortType(DEFAULT_SORT_TYPE);
        setPage(0);
        setVaultAddresses([]);
        setLpMints([]);
        setDepositsEnabled(undefined);
    }, [setSortDirection, setSortType, setPage, setStatus]);

    return {
        page,
        params,
        tableController,
        reset,
        principalMint,
        setStatus,
        setPrincipalMint,
        setSortDirection,
        setSortType,
        setVaultAddresses,
        setLpMints,
        setDepositsEnabled
    };
}
