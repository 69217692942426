import { useEffect, useMemo } from "react";

import {
    isStrategyDurationSame,
    RateMarketType,
    serializeStrategyDuration,
    useExternalYieldVaults,
    useRatesHistoryQuery
} from "@bridgesplit/abf-react";
import { percentUiToDecimals, TIME } from "@bridgesplit/utils";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { useMarketContext } from "../common";
import { useMarketLendContext } from "./MarketLendContext";
import { useMarketCollateral } from "../util";

export function useInitializeMarketLendCollateral() {
    const {
        form: { collateralInitializedForMint },
        setForm
    } = useMarketLendContext();
    const supportedCollateral = useMarketCollateral({ requireMarketInformation: true });
    const { principalMint } = useMarketContext();

    const defaultMints = useMemo(() => {
        if (!supportedCollateral) return undefined;
        return supportedCollateral.map((c) => c.mint);
    }, [supportedCollateral]);

    useEffect(() => {
        if (!principalMint || !defaultMints?.length || collateralInitializedForMint === principalMint) return;

        setForm((prev) => ({
            ...prev,
            collateralInitializedForMint: principalMint,
            collateral: defaultMints
        }));
    }, [defaultMints, setForm, principalMint, collateralInitializedForMint]);
}

export function useInitializeMarketLendYieldSource() {
    const { principalMint } = useMarketContext();
    const { getYieldVault, isLoading } = useExternalYieldVaults();
    const { form, setForm } = useMarketLendContext();
    const yieldVault = getYieldVault(principalMint);

    useEffect(() => {
        if (isLoading || yieldVault?.yieldSource === form.yieldSource) return;

        setForm((prev) => ({
            ...prev,
            yieldSource: ExternalYieldSource.None
        }));
    }, [principalMint, yieldVault, isLoading, setForm, form.yieldSource]);
}

export function useInitializeMarketLendPresets() {
    const { principalMint } = useMarketContext();
    const {
        form: { strategyDurationToApy },
        setForm,
        strategyDurations
    } = useMarketLendContext();

    // fine the init presets are based on collateral not principal
    useEffect(() => {
        if (!principalMint || !strategyDurations?.length || !!strategyDurationToApy.size) return;

        setForm((prev) => ({
            ...prev,
            strategyDurationToApy: new Map(
                strategyDurations?.map((r) => [serializeStrategyDuration(r), undefined]) ?? []
            )
        }));
    }, [principalMint, strategyDurations, setForm, strategyDurationToApy.size]);
}

export function useLendMarketRates() {
    const { principalMint, durationToMinApy } = useMarketContext();
    const {
        strategyDurations,
        form: { strategyDurationToApy }
    } = useMarketLendContext();
    const { data: rates, isLoading: queryLoading } = useRatesHistoryQuery(
        { principalMints: principalMint ? [principalMint] : [], timeLookback: TIME.DAY },
        { skip: !principalMint }
    );
    const idleApy = useExternalYieldVaults().getYieldVault(principalMint)?.apy ?? 0;

    const isLoading = queryLoading || !durationToMinApy;
    const externalRates = rates?.[RateMarketType.KaminoMain];

    const externalRate = useMemo(() => {
        if (!externalRates) return undefined;
        let totalBorrow = 0;
        let totalLend = 0;
        for (const rate of externalRates) {
            totalBorrow += percentUiToDecimals(rate.borrowApy);
            totalLend += percentUiToDecimals(rate.lendApy);
        }
        const averageBorrow = totalBorrow / externalRates.length;
        const averageLend = totalLend / externalRates.length;

        const averageRate = (averageBorrow + averageLend) / 2;
        return { averageRate, averageBorrow, averageLend };
    }, [externalRates]);

    const nonZeroMarketMinApy = useMemo(() => {
        const selectedDurations = strategyDurations?.filter((d) =>
            strategyDurationToApy.has(serializeStrategyDuration(d))
        );
        const apyRange = durationToMinApy
            ?.filter((bestOffer) => selectedDurations?.some((selected) => isStrategyDurationSame(selected, bestOffer)))
            .map((o) => o?.apy ?? 0)
            .filter((apy) => apy > 0);
        const marketMinApy = apyRange?.length ? Math.min(...apyRange) : undefined;
        return marketMinApy;
    }, [durationToMinApy, strategyDurationToApy, strategyDurations]);

    const defaultRate = useMemo(() => {
        if (isLoading) return undefined;

        // Ignore if lend > borrow rate (due to farming rewards)
        if (externalRate && externalRate.averageBorrow > externalRate.averageLend) {
            return Math.max(externalRate.averageRate, idleApy);
        }
        if (!nonZeroMarketMinApy) {
            return undefined;
        }
        return Math.max(idleApy, nonZeroMarketMinApy);
    }, [externalRate, isLoading, nonZeroMarketMinApy, idleApy]);

    return { defaultRate, externalRate, marketMinApy: nonZeroMarketMinApy, isLoading };
}
