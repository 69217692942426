import { useState } from "react";

import { SortOrder } from "@bridgesplit/utils";
import {
    Box,
    TableRow as MuiTableRow,
    TableCell,
    TableSortLabel,
    styled,
    Table,
    TableBody,
    TableHead,
    TableRow
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { genericsMemo } from "@bridgesplit/react";

import { FONT_SIZES, SPACING_PX, useAppPalette } from "../../theme";
import { ConditionalLink } from "../buttons";
import { TooltipText } from "../typography";
import { InternalTableProps, TABLE_CTA_COLUMN_KEY, TableColumn } from "./types";
import { RowsSkeleton, TABLE_ROW_HEIGHT } from "./common";

export default genericsMemo(function InternalTable<T>({ tableProps, paginationController }: InternalTableProps<T>) {
    const { rows, columns, onRowNavigatePath, onRowClick, preventEmptyRows, loading, paginationProps } = tableProps;
    const { order, orderBy, resetPage, setOrder, setOrderBy } = paginationController;
    const { divider } = useAppPalette();

    const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();

    const rowsPerPage = paginationProps?.pageSize ?? 5;
    const emptyRows = !preventEmptyRows ? rowsPerPage - (rows?.length ?? 0) : 0;

    const hoverEnabled = !!onRowClick || !!onRowNavigatePath;
    const styles = useInternalTableStyles();

    const getAlign = (column: TableColumn<T>) => {
        return column.align ?? "left";
    };

    const getFlexAlign = (column: TableColumn<T>) => {
        if (column.dataIndex === TABLE_CTA_COLUMN_KEY || column.align === "right") return "flex-end";
        if (column.align === "center") return "center";
        return "flex-start";
    };

    const TableHeaderTitle = ({ column }: { column: TableColumn<T> }) => {
        if (typeof column.title !== "string") {
            return <>{column.title} </>;
        }
        return (
            <TooltipText
                tooltipProps={{ placement: column.align === "center" ? "top" : column.align }}
                containerSx={{ width: "100%" }}
                helpText={column.toolTip}
                color="caption"
                variant="body2"
                sx={{ width: "100%", whiteSpace: "nowrap", justifyContent: getFlexAlign(column) }}
            >
                {column.title}
            </TooltipText>
        );
    };
    const ColumnHeaders = () => (
        <TableHead>
            <StyledTableRow>
                {columns.map((column) => {
                    const isSelected = orderBy === column.dataIndex || orderBy === column.apiSortKey;
                    const sortAllowed = column.sortBy || column.apiSortKey;
                    const direction = order === SortOrder.Desc ? "desc" : "asc";

                    return (
                        <TableCell
                            key={column.dataIndex}
                            align={getAlign(column)}
                            sortDirection={isSelected ? direction : false}
                            sx={{
                                ...styles.tableHeaderCellSx,
                                width: column.width ?? "auto"
                            }}
                        >
                            {sortAllowed ? (
                                <TableSortLabel
                                    active={isSelected}
                                    direction={direction}
                                    onClick={() => {
                                        // if already selected, user is swapping directions
                                        if (isSelected) {
                                            setOrder(order === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
                                        } else {
                                            setOrderBy(column.apiSortKey ?? column.dataIndex);
                                        }
                                        resetPage();
                                    }}
                                    sx={{ maxHeight: "1rem" }}
                                >
                                    <TableHeaderTitle column={column} />
                                    {orderBy === column.dataIndex && (
                                        <Box component="span" sx={{ ...visuallyHidden, fontSize: FONT_SIZES.body1 }}>
                                            {SortOrder.Desc ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    )}
                                </TableSortLabel>
                            ) : (
                                <TableHeaderTitle column={column} />
                            )}
                        </TableCell>
                    );
                })}
            </StyledTableRow>
        </TableHead>
    );

    // use this pseudo element instead of a border to allow for a "margin"
    const RowDivider = ({ index }: { index: number }) => (
        <Box
            sx={{
                position: "absolute",
                top: "-1px",
                // divider gets duplicated column number times since it is in table data
                opacity: 1 / columns.length,
                left: index === 0 ? SPACING_PX : 0,
                right: index === columns.length - 1 ? SPACING_PX : 0,
                height: "1px",
                backgroundColor: divider
            }}
        />
    );

    if (rows === undefined || loading) {
        return (
            <Table sx={styles.tableBodySx} aria-label="inline table">
                <ColumnHeaders />
                <TableBody sx={{ width: "100%" }}>
                    <TableRow>
                        <TableCell colSpan={columns.length} sx={{ p: 0, border: "none" }}>
                            <RowsSkeleton rowsPerPage={rowsPerPage} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    return (
        <Table sx={styles.tableBodySx} aria-label="inline table">
            <ColumnHeaders />

            <TableBody>
                {rows.map((row, i) => (
                    <StyledTableRow
                        onMouseEnter={() => setHoveredRowIndex(i)}
                        onMouseLeave={() => setHoveredRowIndex(undefined)}
                        sx={{
                            position: "relative"
                        }}
                        key={i}
                    >
                        {columns.map((column, j) => {
                            const isDisabled = tableProps.isRowDisabled?.(row);
                            const isCta = !isDisabled && column.dataIndex === TABLE_CTA_COLUMN_KEY;
                            const isHoverable = hoverEnabled && !isDisabled;
                            return (
                                <TableCell
                                    align={getAlign(column)}
                                    key={`${i}-${j}`}
                                    onClick={() => {
                                        if (!isCta) onRowClick?.(row);
                                    }}
                                    width={column.width ?? "auto"}
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        opacity: isDisabled ? 0.4 : 1,
                                        cursor: isHoverable ? "pointer" : "default",
                                        filter: isDisabled ? "saturate(0)" : undefined,
                                        pointerEvents: isDisabled ? "none" : "auto",
                                        whiteSpace: "nowrap",
                                        backgroundColor: (() => {
                                            if (hoveredRowIndex === i && isHoverable) return divider;
                                            return undefined;
                                        })()
                                    }}
                                >
                                    <RowDivider index={j} />
                                    <ConditionalLink
                                        sx={{
                                            paddingLeft: j === 0 ? 1 : 2,
                                            paddingRight: j === columns.length - 1 ? 1 : 2,
                                            py: 0.5,
                                            fontSize: FONT_SIZES.body1,
                                            justifyContent: getFlexAlign(column),
                                            display: "flex",
                                            minWidth: "fit-content",
                                            height: "60px",
                                            alignItems: "center"
                                        }}
                                        to={isCta || !isHoverable ? undefined : onRowNavigatePath?.(row)}
                                    >
                                        <>{column.render(row, i)}</>
                                    </ConditionalLink>
                                </TableCell>
                            );
                        })}
                    </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                    <StyledTableRow sx={{ height: `${TABLE_ROW_HEIGHT * emptyRows}px` }}>
                        <TableCell colSpan={6} />
                    </StyledTableRow>
                )}
            </TableBody>
        </Table>
    );
});

function useInternalTableStyles() {
    const tableBodySx = {
        minWidth: "fit-content",
        overflow: "scroll",
        td: { border: "none" },
        tr: { backgroundColor: "transparent" },
        th: {
            border: "none",
            pt: 0.5,
            pb: 1.5,
            ":first-of-type": {
                paddingLeft: 1
            },
            ":last-of-type": {
                paddingRight: 1
            }
        }
    };

    const tableHeaderCellSx = {
        paddingTop: 2,
        paddingBottom: 2
    };

    const tableHeaderTitleTooltipSx = {
        color: "inherit",
        fontSize: FONT_SIZES.body1,
        position: "relative",
        top: "2px",
        left: "2px"
    };

    return {
        tableBodySx,
        tableHeaderCellSx,
        tableHeaderTitleTooltipSx
    };
}

const StyledTableRow = styled(MuiTableRow)(() => ({
    border: "none"
}));
