import {
    deserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransaction,
    TransactionsWithDataGenerateReturn,
    VersionedTransactionResponse
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    LoopWindTransactionInput,
    LoopUnwindTransactionInput,
    TXN_UNWIND_LOOP,
    TXN_WIND_LOOP
} from "../types";
import { createMarketsInstance } from "./auth";

export async function getLoopWindTransaction(
    authentication: AbfTransactionHeaders,
    arg: LoopWindTransactionInput
): Promise<TransactionsWithDataGenerateReturn<{ loanAddress: string }>> {
    const api = createMarketsInstance(authentication);
    const response = await api.post<{ transaction: VersionedTransactionResponse; loanAddress: string }>(
        TXN_WIND_LOOP,
        arg
    );
    const data = response.data;

    const transaction = deserializeVersionedTransaction(data.transaction);
    return {
        transactions: [transaction],
        loanAddress: data.loanAddress
    };
}

export async function getLoopUnwindTransaction(authentication: AbfTransactionHeaders, arg: LoopUnwindTransactionInput) {
    return [await fetchAndDeserializeVersionedTransaction(createMarketsInstance(authentication), TXN_UNWIND_LOOP, arg)];
}
