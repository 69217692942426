import { useMemo } from "react";

import {
    isPastNow,
    bsMath,
    getUnixTs,
    TIME,
    convertAnyDate,
    formatDate,
    SortDirection,
    filterTruthy,
    bpsToUiDecimals,
    isBeforeNow,
    lamportsToUiAmount
} from "@bridgesplit/utils";
import { useMemoizedKeyMap } from "@bridgesplit/ui";
import { UncertaintyType } from "@bridgesplit/abf-sdk";

import { useLoanInfosQuery } from "../reducers";
import {
    BsMetaUtil,
    calculateHealthRatio,
    getLoanTotalDebtForLedger,
    isLiquidateEvent,
    isRepayEvent,
    summarizeLedgerAccount,
    useAbfTypesToUiConverter
} from "../utils";
import {
    LoanFilter,
    AbfLoanExpanded,
    LoanStatus,
    LoanFilterApi,
    LoanPaginationInfo,
    LoanCollateralInfoExpanded,
    LoanInfo,
    LedgerExpanded,
    LoanInfoResponse,
    AssetTypeIdentifier,
    Ledger,
    LoanInfoSorting,
    LoanExpanded,
    LoanExpandedBase,
    LoanEventActionType,
    ParsedRepayPrincipalMetadata,
    ParsedLoanEventExpanded,
    LiquidationType,
    PriceFetchType,
    PastLoanCollateral,
    LoanInfoExpanded,
    LoanFilterType,
    LoanStatusType
} from "../types";
import { useOraclePrices } from "./pricing";
import { useWhirlpoolPositions } from "./whirlpool";
import { LOAN_REFINANCE_GRACE_BEFORE_DEFAULT } from "../constants";
import { getPastLoanCollateralMap } from "./utils";

export type LoanInfosParams = {
    loanFilter: LoanFilter;
    // require explicit null to avoid ts error
    pagination?: LoanPaginationInfo | null;
    skip?: boolean;
};

export type LoanInfoResult = {
    data: AbfLoanExpanded[] | undefined;
    totalLoans: number | undefined;
    isLoading: boolean;
    isFetching: boolean;
};

export function convertLoanStatusTypeToFilterType(loanStatusType: LoanStatusType): LoanFilterType | undefined {
    switch (loanStatusType) {
        case LoanStatusType.ActiveOnly:
            return LoanFilterType.Active;
        case LoanStatusType.InactiveOnly:
            return LoanFilterType.Closed;
        case LoanStatusType.All:
            return undefined;
    }
}

export function useLoanInfos({ loanFilter: loanFilterRaw, pagination, ...params }: LoanInfosParams): LoanInfoResult {
    const loanFilter = useMemo((): LoanFilterApi => {
        return {
            lenders: loanFilterRaw.lenders,
            loanAddresses: loanFilterRaw.loanAddresses ?? [],
            borrowers: loanFilterRaw.borrowers ?? [],
            filterType: convertLoanStatusTypeToFilterType(pagination?.loanStatusType ?? LoanStatusType.All),
            principalMints: pagination?.principalMint ? [pagination.principalMint] : [],
            pageSize: pagination?.pageSize,
            page: pagination?.page,
            sortSide: pagination?.sortSide ?? SortDirection.Asc,
            sortType: pagination?.sortType ?? LoanInfoSorting.StartTime,
            orderFundingTypes: loanFilterRaw.orderFundingTypes ?? []
        };
    }, [loanFilterRaw, pagination]);

    const skip = params?.skip;
    const {
        data: rawData,
        isLoading: queryLoading,
        isFetching: loanInfosFetching
    } = useLoanInfosQuery(loanFilter, { skip });

    const loans = rawData ? Object.values(rawData.loanInfos) : undefined;

    const mints = useMemo(() => {
        if (!rawData) return undefined;
        return Array.from(
            new Set(Object.values(rawData?.loanInfos ?? {}).flatMap((l) => l.ledgers.map((l) => l.principalMint)))
        );
    }, [rawData]);

    const whirlpoolMints = useMemo(() => {
        if (!rawData) return undefined;

        return Object.values(rawData.loanInfos).flatMap((loan) =>
            loan.collateral.filter((c) => c.assetType === AssetTypeIdentifier.OrcaPosition).map((c) => c.assetMint)
        );
    }, [rawData]);

    const { data: whirlpools, isFetching: whirlpoolsFetching } = useWhirlpoolPositions(whirlpoolMints);
    const positionToWhirlpool = useMemoizedKeyMap(whirlpools, (w) => w.position.positionMint);

    const { tokensLoading, getMetadata, convertLoanEvents, convertLoanCollateralInfo, convertLoanLedgerExpanded } =
        useAbfTypesToUiConverter(mints);
    const { getUsdPrice, isLoading: pricesLoading } = useOraclePrices(mints, PriceFetchType.Twap);

    const isFetching = loanInfosFetching || whirlpoolsFetching;
    const isLoading = queryLoading || tokensLoading || pricesLoading || isFetching;

    const data: AbfLoanExpanded[] | undefined = useMemo(() => {
        if (isLoading || !loans) return undefined;

        const validLoans = loans
            .map((loanInfoResponse: LoanInfoResponse): AbfLoanExpanded | undefined => {
                // Used to determine wether or not we should use past or current ledgers
                const loanClosed = loanInfoResponse.loan.closed;

                const defaultLedger = getRawZcLedger(loanInfoResponse);

                const principalMint = defaultLedger?.principalMint;
                const principalMetadata = principalMint ? getMetadata(principalMint) : null;

                if (!principalMetadata) {
                    return undefined;
                }

                const loanExpanded: LoanExpanded = {
                    ...loanInfoResponse.loan,
                    refinanceEnabled: true //Hardcoded for now in case support is needed for later
                };

                const matrixUpdates = loanInfoResponse.matrixUpdates;

                const parsedLoanEvents: ParsedLoanEventExpanded[] = convertLoanEvents(loanInfoResponse, matrixUpdates);

                // Transform LoanCollateral[] into LoanCollateralInfoExpanded[]

                const loanCollateralInfoExpanded: LoanCollateralInfoExpanded[] = loanInfoResponse.collateral
                    .map((collateralRaw) => {
                        const assetIdentifier = collateralRaw.assetIdentifier;
                        const metadata = getMetadata(assetIdentifier);

                        if (!metadata) return undefined;
                        const collateralInfo = convertLoanCollateralInfo(
                            collateralRaw,
                            getUsdPrice(collateralRaw.assetIdentifier, UncertaintyType.Subtract),
                            positionToWhirlpool,
                            metadata
                        );

                        return collateralInfo;
                    })
                    .filter(filterTruthy);

                const pastLoanCollateralMap = getPastLoanCollateralMap(parsedLoanEvents);

                // Extract keys from Map properly - Object.keys doesn't work on Map objects
                const eventMints: string[] = pastLoanCollateralMap ? Array.from(pastLoanCollateralMap.keys()) : [];

                const pastLoanCollateralItems: PastLoanCollateral[] = eventMints
                    .map((mint) => {
                        const amount = pastLoanCollateralMap?.get(mint) ?? 0;
                        const metadata = getMetadata(mint);

                        if (!metadata) {
                            return undefined;
                        }

                        const whirlpool = positionToWhirlpool?.get(metadata.mint);

                        const usdPrice = getUsdPrice(mint, UncertaintyType.Subtract);

                        return {
                            usdPrice: usdPrice ?? 0,
                            amount: lamportsToUiAmount(amount, metadata.decimals),
                            metadata: metadata,
                            assetType: metadata.assetType,
                            whirlpoolPosition: whirlpool || undefined
                        };
                    })
                    .filter(filterTruthy);

                // Create the initial expanded loan
                const lenders = [...new Set(loanInfoResponse.ledgers.map((entry) => entry.strategy))];

                const loanInfo: LoanInfoExpanded = {
                    loan: loanExpanded,
                    ledger: [], // written to later
                    pastLedgers: [], // written to later
                    collateral: loanCollateralInfoExpanded,
                    events: parsedLoanEvents,
                    pastCollateral: pastLoanCollateralItems
                };

                const loanExpandedBase: LoanExpandedBase = {
                    ...loanInfo,
                    index: 0, //Overriden later
                    status: LoanStatus.OngoingLoan, //Overriden later
                    borrower: loanInfoResponse.loan.borrower,
                    lenders: lenders,
                    borrowerWAvgApy: 0 //written to later
                };

                const abfLoanInfo: AbfLoanExpanded = {
                    ...loanExpandedBase,
                    principalMetadata: principalMetadata,
                    principalUsdPrice: getUsdPrice(principalMint, UncertaintyType.Add)
                };

                const ledger = loanInfoResponse.ledgers
                    .map((ledger) => ({
                        ...ledger,
                        ledgerDebt: getLoanTotalDebtForLedger(abfLoanInfo, {
                            ...ledger,
                            apy: bpsToUiDecimals(ledger.apy) // convert apy here for calculations
                        }),
                        isFilled:
                            ledger.principalDue + ledger.interestDue === ledger.principalRepaid + ledger.interestRepaid
                    }))
                    .map((ledger) => convertLoanLedgerExpanded(ledger, principalMetadata));

                const pastLedgers = loanInfoResponse.pastLedgers
                    .map((pastLedger) => ({
                        ...pastLedger,
                        ledgerDebt: getLoanTotalDebtForLedger(abfLoanInfo, {
                            ...pastLedger,
                            apy: bpsToUiDecimals(pastLedger.apy)
                        }),
                        isFilled:
                            pastLedger.principalDue + pastLedger.interestDue ===
                            pastLedger.principalRepaid + pastLedger.interestRepaid
                    }))
                    .map((pastLedger) => convertLoanLedgerExpanded(pastLedger, principalMetadata));

                abfLoanInfo.ledger = ledger;
                abfLoanInfo.pastLedgers = pastLedgers;

                abfLoanInfo.borrowerWAvgApy = calculateBorrowerWeightedAvgApy(loanClosed ? pastLedgers : ledger);

                return abfLoanInfo;
            })
            .filter(filterTruthy)
            .map((loanExpanded, index) => ({
                ...loanExpanded,
                index,
                status: getLoanStatusFromLoan(loanExpanded)
            }));

        return validLoans;
    }, [
        isLoading,
        loans,
        getMetadata,
        convertLoanEvents,
        getUsdPrice,
        convertLoanCollateralInfo,
        positionToWhirlpool,
        convertLoanLedgerExpanded
    ]);

    return {
        data: isLoading ? undefined : data,
        totalLoans: rawData?.totalCount,
        isLoading,
        isFetching
    };
}
export function getZcLoanEndTime(loanInfo: LoanInfo | undefined) {
    if (!loanInfo) {
        return undefined;
    }
    const firstLedger = loanInfo.ledger[0];
    return firstLedger?.endTime;
}
export function getLoanSoldTime(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.events.find((e) => e.originalLender)?.eventTime;
}

export function isLoanActive(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    return [LoanStatus.OngoingLoan].includes(loanExpanded.status) || isLoanInRefinanceGrace(loanExpanded);
}
export function isLoanInRefinanceGrace(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    return [LoanStatus.RefinanceGrace].includes(loanExpanded.status);
}

export function calculateAmountRepaid(metaData: ParsedRepayPrincipalMetadata) {
    const principalDelta =
        metaData.postRepaymentPrincipalLedgerState.principalRepaid -
        metaData.preRepaymentPrincipalLedgerState.principalRepaid;

    const interestDelta =
        metaData.postRepaymentPrincipalLedgerState.interestRepaid -
        metaData.preRepaymentPrincipalLedgerState.interestRepaid;

    return principalDelta + interestDelta;
}

export function isLoanComplete(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo) return false;
    return loanInfo.loan.closed;
}
export function isLoanWaitingLiquidation(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo) return false;

    const refinanceGrace = loanInfo.loan.refinanceEnabled ? LOAN_REFINANCE_GRACE_BEFORE_DEFAULT : 0;

    if (!isBeforeNow((getZcLoanEndTime(loanInfo) ?? 0) + refinanceGrace)) {
        const ledgersPastDue = loanInfo?.ledger.filter((data) => !isLedgerInRefinanceGrace(loanInfo, data));
        return ledgersPastDue?.length > 0;
    }

    return false;
}

export function isLoanDefaulted(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo) return false;
    return loanInfo.status === LoanStatus.DefaultedLoan;
}

export function isLoanLiquidated(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo) return false;

    if (loanInfo.events.some((e) => isLiquidateEvent(e.action))) {
        return true;
    }

    return false;
}

// can be called on a fully liquidated loan, on a loan thats in temporary default standing
export function getLoanDefaultedDate(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo || !isLoanDefaulted(loanInfo)) return undefined;

    if (loanInfo.loan.closed) return loanInfo.pastLedgers.reduce((prev, curr) => Math.max(prev, curr.endTime), 0);

    return loanInfo.ledger.reduce((prev, curr) => Math.max(prev, curr.endTime), 0);
}

function getFullLiquidationEvent(events: ParsedLoanEventExpanded[]): ParsedLoanEventExpanded | undefined {
    const liquidationEvents = events.filter((e) => isLiquidateEvent(e.action));
    const fullLiquidationEvent = liquidationEvents.find(
        (e) => e.actionMetadata[LoanEventActionType.Liquidate].liquidationType === LiquidationType.FullLiquidation
    );
    return fullLiquidationEvent;
}

function getMaxRepaidEventTimeFromEvents(loanInfo: AbfLoanExpanded) {
    const events = loanInfo.events;

    const fullLiquidationEvent = getFullLiquidationEvent(events);
    if (fullLiquidationEvent?.eventTime) {
        return fullLiquidationEvent.eventTime;
    }

    const repaidEvents = events.filter((e) => isRepayEvent(e.action));
    return Math.max(...repaidEvents.map((e) => e.eventTime));
}

export function getLoanCompletedDate(loanInfo: AbfLoanExpanded | undefined) {
    if (!loanInfo || !isLoanComplete(loanInfo)) return undefined;
    return getMaxRepaidEventTimeFromEvents(loanInfo);
}

export function getMostRecentPaymentDate(loanInfo: AbfLoanExpanded | undefined) {
    const lastLedger = loanInfo?.ledger.filter((l) => l.isFilled)?.pop();
    if (!lastLedger || !loanInfo) return undefined;
    return lastLedger.endTime + loanInfo.loan.startTime;
}

export function getLoanMissedPayments(loan: AbfLoanExpanded) {
    return loan.ledger.filter((l) => isPastNow(l.endTime + loan.loan.startTime) && !l.isFilled);
}

export function getLoanPaymentsInGrace(loan: AbfLoanExpanded) {
    return loan.ledger.filter((l) => {
        const dueTimeWithGrace = l.endTime + TIME.DAY * 2;
        const now = getUnixTs();
        return !l.isFilled && dueTimeWithGrace > now;
    });
}

export function isLedgerInRefinanceGrace(loan: AbfLoanExpanded | undefined, ledger: LedgerExpanded | undefined) {
    if (!loan || !ledger || (ledger && ledger.isFilled)) return false;
    const isRefinanceGrace = ledger.endTime + LOAN_REFINANCE_GRACE_BEFORE_DEFAULT > getUnixTs();
    const isRefinancePeriod = loan.loan.refinanceEnabled && ledger.endTime < getUnixTs();

    if (isRefinanceGrace && isRefinancePeriod) return true;

    return false;
}

export function formatLoanDuration(loanExpanded: AbfLoanExpanded) {
    const [start, end] = [loanExpanded.loan.startTime, getZcLoanEndTime(loanExpanded)];
    const shortDuration =
        (end || 0) - start < TIME.DAY && convertAnyDate(start).getDate() === convertAnyDate(end).getDate();
    return shortDuration
        ? `${formatDate(end, "date")} ${formatDate(start, "time")} - ${formatDate(end, "time")}`
        : `${formatDate(start, "date")} - ${formatDate(end, "date")}`;
}

// Uses first ledgerIndexToLtvInfo since only single asset collateral supported
export function calculateLoanHealth(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded)
        return {
            health: 0,
            ltv: 0,
            loanCollateralValue: 0,
            totalOwedValue: 0,
            liquidationThreshold: 0
        };

    const loanCollateralValue = getLoanCollateralUsdValue(loanExpanded);
    const totalLedgersDebt = summarizeLedgerAccount(getZcLedger(loanExpanded)).accruedTotalOutstanding;
    const totalOwedValue = bsMath.tokenAMul(
        totalLedgersDebt,
        loanExpanded.principalUsdPrice ?? 0,
        loanExpanded.principalMetadata?.decimals ?? 0
    );

    const liquidationThreshold = getZcLoanLiquidationThreshold(loanExpanded);

    const ltv = loanCollateralValue > 0 ? totalOwedValue / loanCollateralValue : 0;

    const health = calculateHealthRatio(totalOwedValue, loanCollateralValue, liquidationThreshold);

    return {
        health,
        ltv,
        loanCollateralValue,
        totalOwedValue,
        liquidationThreshold
    };
}

export function getLoanCollateralUsdValue(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return 0;

    return loanExpanded.collateral
        .map((collateral) =>
            bsMath.tokenAMul(
                collateral.loanCollateral.amount,
                collateral.loanCollateral.usdPrice ?? 0,
                collateral.loanCollateral.metadata?.decimals ?? 0
            )
        )
        .reduce((acc, value) => bsMath.add(acc, value) ?? 0, 0);
}
export function canLenderReclaimCollateral(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;

    if (!isLoanLiquidated(loanExpanded) && !isLoanDefaulted(loanExpanded)) return false;
    return true;
}

export function isLoanFullyLiquidated(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    const fullLiquidationEvent = getFullLiquidationEvent(loanExpanded.events);
    return !!fullLiquidationEvent;
}

export function getLoanStatusFromLoan(loanExpanded: AbfLoanExpanded): LoanStatus {
    const firstLedger = getZcLedger(loanExpanded);
    if (isLedgerInRefinanceGrace(loanExpanded, firstLedger)) return LoanStatus.RefinanceGrace;
    if (isLoanWaitingLiquidation(loanExpanded) || isLoanFullyLiquidated(loanExpanded)) return LoanStatus.DefaultedLoan;
    if (isLoanComplete(loanExpanded)) return LoanStatus.RepaidLoan;

    return LoanStatus.OngoingLoan;
}

export function getLoanOrcaCollateral(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.collateral.find((c) => !!c.loanCollateral.whirlpoolPosition && !!c.loanCollateral.amount) as
        | LoanCollateralInfoExpanded
        | undefined;
}
export function getLoanStrategyIdentifier(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return undefined;
    return loanExpanded.loan.nonce;
}
export function getZcLedgerStrategyIdentifier(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return undefined;
    const firstLedger = getZcLedger(loanExpanded);
    if (!firstLedger) return undefined;
    return firstLedger.strategy;
}

export function getZcLoanCollateral(loanExpanded: AbfLoanExpanded | undefined): LoanCollateralInfoExpanded | undefined {
    if (!loanExpanded?.collateral || loanExpanded.collateral.length === 0) {
        return undefined;
    }

    return loanExpanded.collateral[0];
}

export function getZcLoanLtv(loanExpanded: AbfLoanExpanded | undefined): number | undefined {
    if (!loanExpanded) return undefined;
    const firstLedger = getZcLedger(loanExpanded);
    if (!firstLedger) return undefined;

    return firstLedger.ltvRatios[0];
}
export function getZcLoanPastLtv(loanExpanded: AbfLoanExpanded | undefined): number | undefined {
    if (!loanExpanded) return undefined;
    const firstLedger = getZcPastLedger(loanExpanded);
    if (!firstLedger) return undefined;

    return firstLedger.ltvRatios[0];
}
export function getZcLoanLiquidationThreshold(loanExpanded: AbfLoanExpanded | undefined): number | undefined {
    if (!loanExpanded) return undefined;
    const firstLedger = getZcLedger(loanExpanded);
    if (!firstLedger) return undefined;

    return firstLedger.lqtRatios[0];
}

export function getZcLoanPastLiquidationThreshold(loanExpanded: AbfLoanExpanded | undefined): number | undefined {
    if (!loanExpanded) return undefined;
    const firstLedger = getZcPastLedger(loanExpanded);
    if (!firstLedger) return undefined;

    return firstLedger.lqtRatios[0];
}

export function getZcLedger(loanExpanded: AbfLoanExpanded | undefined): LedgerExpanded | undefined {
    if (!loanExpanded) return undefined;

    // AbfLoanExpanded case
    if (!loanExpanded.ledger || loanExpanded.ledger.length === 0) {
        return undefined;
    }
    return loanExpanded.ledger.find((l) => l.ledgerIndex === 0);
}

export function getZcPastLedger(loanExpanded: AbfLoanExpanded | undefined): LedgerExpanded | undefined {
    if (!loanExpanded) return undefined;
    if (!loanExpanded.pastLedgers || loanExpanded.pastLedgers.length === 0) {
        return undefined;
    }
    return loanExpanded.pastLedgers.find((l) => l.ledgerIndex === 0);
}

export function getPastOrPresentZcLedger(loanExpanded: AbfLoanExpanded | undefined) {
    return isLoanComplete(loanExpanded) ? getZcPastLedger(loanExpanded) : getZcLedger(loanExpanded);
}

// This function is used to process the loan info response. If there is no ledger info, we default to past ledgers.
export function getRawZcLedger(loanExpanded: LoanInfoResponse | undefined): Ledger | undefined {
    if (!loanExpanded) return undefined;

    return (
        loanExpanded.ledgers.find((l) => l.ledgerIndex === 0) ||
        loanExpanded.pastLedgers.find((l) => l.ledgerIndex === 0)
    );
}

export function getZcLedgerFromLoanInfo(loanInfo: LoanInfo | undefined): LedgerExpanded | undefined {
    if (!loanInfo?.ledger || loanInfo.ledger.length === 0) {
        return undefined;
    }

    return loanInfo.ledger[0];
}

export function getZcLenders(loanExpanded: AbfLoanExpanded | undefined): string[] | undefined {
    if (!loanExpanded) return undefined;
    if (loanExpanded.lenders) return loanExpanded?.lenders;

    return loanExpanded.ledger.map((l) => l.strategy);
}

export function getZcCollateral(loanExpanded: AbfLoanExpanded | undefined): LoanCollateralInfoExpanded | undefined {
    if (!loanExpanded?.collateral || loanExpanded.collateral.length === 0) {
        return undefined;
    }

    return loanExpanded.collateral[0];
}

export function calculateZcLoanBorrowerApy(loanExpanded: AbfLoanExpanded | undefined): number | undefined {
    if (!loanExpanded?.ledger || loanExpanded.ledger.length === 0) {
        return undefined;
    }

    const firstLedger = getZcLedger(loanExpanded);
    if (!firstLedger) return undefined;

    return firstLedger.apy;
}

export function isLoanBorrower(loanExpanded: AbfLoanExpanded | undefined, user: string | undefined) {
    if (!loanExpanded || !user) return false;
    return loanExpanded.borrower === user;
}

// Only supports single asset collateral
export function getLoanName(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return "";
    const principalName = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);

    if (isLoanComplete(loanExpanded)) {
        if (!loanExpanded.pastCollateral.length) return principalName;
        const collateralName = BsMetaUtil.getSymbol(loanExpanded.pastCollateral[0].metadata);
        return `${principalName}/${collateralName}`;
    }
    if (!loanExpanded.collateral.length) return principalName;
    const collateralName = BsMetaUtil.getSymbol(loanExpanded.collateral[0].loanCollateral.metadata);
    return `${principalName}/${collateralName}`;
}

export function useLoanFromParams(loanAddress: string | undefined): {
    loanExpanded: AbfLoanExpanded | undefined;
    isLoading: boolean;
    notFound: boolean;
} {
    const {
        data: loans,
        isLoading,
        isFetching
    } = useLoanInfos({
        loanFilter: {
            loanAddresses: loanAddress ? [loanAddress] : [],
            lenders: [],
            borrowers: [],
            custodians: [],
            principalMints: [],
            saleEvents: false,
            sortSide: SortDirection.Asc,
            orderFundingTypes: [],
            ignoreRefinanced: false
        } satisfies LoanFilter,
        pagination: null,
        skip: !loanAddress
    });

    const loanExpanded = loans?.[0];
    return {
        loanExpanded,
        isLoading,
        notFound: !loanExpanded && !isLoading && !isFetching && !!loanAddress
    };
}

export function getUnclaimedAssets(
    loanExpanded: AbfLoanExpanded | undefined
): LoanCollateralInfoExpanded[] | undefined {
    // Only supports unclaimed assets for closed loans
    if (!loanExpanded?.loan.closed) {
        return loanExpanded?.collateral.filter((c) => c.loanCollateral.amount);
    }
    return undefined;
}

// Will need to use principal prices for multi-principal loans
function calculateBorrowerWeightedAvgApy(ledgers: Ledger[]): number {
    if (!ledgers.length) return 0;

    const totalPrincipalDue = ledgers.reduce((sum, ledger) => sum + ledger.principalDue, 0);
    if (totalPrincipalDue === 0) return 0;

    return ledgers.reduce((sum, ledger) => sum + (ledger.apy * ledger.principalDue) / totalPrincipalDue, 0);
}
