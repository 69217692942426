import { useMemo } from "react";

import {
    combineCollections,
    filterNullableRecord,
    filterTruthy,
    getUnixTs,
    lamportsToUiAmount,
    NullableRecord,
    SortDirection,
    TIME
} from "@bridgesplit/utils";
import { useMemoizedKeyMap } from "@bridgesplit/ui";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
    ExternalPointReward,
    LendingVaultAllocationExpanded,
    LendingVaultExpanded,
    LendVaultInfoFilter,
    LendVaultSortType,
    LendVaultsPagination,
    ParsedUserVaultStake,
    StakeStatus,
    UserLendingVaultFilter,
    UserVaultPnl,
    UserVaultPositionExpanded
} from "../types/";
import {
    useTokenListMetadataByMints,
    useLendingVaultAllocationsQuery,
    useLendingVaultInfosQuery,
    useLendingVaultPositionsQuery,
    useUserPositionDepositTermsQuery,
    DepositTermSummary,
    useUserGenesisAccessQuery
} from "../reducers";
import { useOraclePrices } from "./pricing";
import { useAbfTypesToUiConverter } from "../utils/ui";
import { useMarketOracleInfos, useStrategyDurations } from "./markets";
import { useCurrentTime } from "./points";
import { useActiveWallet } from "./wallet";

type Params = {
    skip?: boolean;
    pagination: LendVaultsPagination;
    filter: LendVaultInfoFilter;
};

export function useLendVaults({ pagination, filter, skip: skipParam }: Params) {
    const query = useLendingVaultInfosQuery({ ...pagination, ...filter }, { skip: skipParam });
    const principalMints = query.data?.lendVaults.map((v) => v.vault.principalMint);
    const collateralMints = query.data?.lendVaults.flatMap((v) => Object.keys(v.vaultStrategy.terms));

    const { strategyDurations } = useStrategyDurations();

    const { getMetadata, isLoading: metadataLoading } = useTokenListMetadataByMints(
        combineCollections([principalMints, collateralMints])
    );

    const { data: marketData } = useMarketOracleInfos({
        filter: {
            principalMints: principalMints ?? [],
            addresses: query.data?.lendVaults?.map((v) => v.vaultStrategy.strategy.marketInformation) ?? [],
            verified: false
        }
    });

    const { getOracle, isLoading: oracleLoading } = useOraclePrices(principalMints);
    const isFetching = query.isFetching;
    const { convertLendingVaultInfo, convertLendingVaultLtvInfo, tokensLoading } =
        useAbfTypesToUiConverter(principalMints);
    const isLoading = metadataLoading || oracleLoading || query.isLoading || tokensLoading;

    const data = useMemo(() => {
        if (!query.data || isLoading) return undefined;

        return query.data.lendVaults
            .map((vaultInfoRaw): NullableRecord<LendingVaultExpanded> => {
                const vaultInfo = convertLendingVaultInfo(vaultInfoRaw);

                // Find matching market information for this vault
                const vaultMarketInfo = marketData?.find(
                    (info) => info.marketInformation.address === vaultInfo.vaultStrategy.strategy.marketInformation
                );

                // Updated terms processing for new structure
                const ltvInfos = convertLendingVaultLtvInfo(
                    vaultInfoRaw,
                    vaultMarketInfo,
                    strategyDurations,
                    getMetadata
                );

                return {
                    ...vaultInfo,
                    principalMetadata: getMetadata(vaultInfo.vault.principalMint),
                    principalOracle: getOracle(vaultInfo.vault.principalMint),
                    ltvInfos
                };
            })
            .filter(filterNullableRecord);
    }, [
        query.data,
        isLoading,
        convertLendingVaultInfo,
        marketData,
        convertLendingVaultLtvInfo,
        strategyDurations,
        getMetadata,
        getOracle
    ]);

    return {
        data,
        totalDataCount: query.data?.total,
        isLoading,
        isFetching
    };
}

export function useLendVaultByAddress(vaultAddress: string | undefined) {
    const { data: allVaults } = useLendVaults({
        pagination: {
            page: 0,
            pageSize: 1,
            sortType: LendVaultSortType.TotalDeposits,
            sortDirection: SortDirection.Asc
        },
        filter: { vaultAddresses: vaultAddress ? [vaultAddress] : undefined },
        skip: !vaultAddress
    });
    return { data: allVaults?.find((v) => v.vault.address === vaultAddress) };
}

export function getVaultLpVirtualPrice(vaultExpanded: LendingVaultExpanded | undefined, currentTimestamp: number) {
    if (!vaultExpanded) return undefined;
    const netAssetValue = getVaultNetAssetValue(vaultExpanded, currentTimestamp);
    const lpSupply = vaultExpanded.vault.lpSupply;
    return netAssetValue ? netAssetValue / lpSupply : undefined;
}

export function getVaultNetAssetValue(
    vaultExpanded: LendingVaultExpanded | undefined,
    currentTimestamp: number,
    options: { ignoreFees: boolean } = { ignoreFees: false }
) {
    if (!vaultExpanded) return undefined;
    const { ignoreFees = false } = options;
    const principalDeployed = vaultExpanded.vaultStrategy.strategy.currentDeployedAmount;
    const interestPerSecond = vaultExpanded.vaultStrategy.strategy.interestPerSecond;
    const principalTokenBalance = vaultExpanded.vaultStrategy.strategy.tokenBalance;
    const outstandingInterestAmount = vaultExpanded.vaultStrategy.strategy.outstandingInterestAmount;
    const timeElapsed = Math.max(0, currentTimestamp - vaultExpanded.vaultStrategy.strategy.lastAccruedTimestamp);
    const interestEarned = interestPerSecond * timeElapsed + outstandingInterestAmount;
    const externalYieldBalance = vaultExpanded.vaultStrategy.externalYieldInfo?.balance ?? 0;
    const tvl = principalDeployed + interestEarned + externalYieldBalance + principalTokenBalance;
    if (ignoreFees) return tvl;
    const fees = getCurrentVaultFees(vaultExpanded, currentTimestamp);
    return Math.max(0, tvl - fees);
}

export function getVaultTvl(vaultExpanded: LendingVaultExpanded | undefined, currentTimestamp: number) {
    return getVaultNetAssetValue(vaultExpanded, currentTimestamp, { ignoreFees: true });
}

function convertToUserVaultPosition({
    userPosition,
    vaultExpanded,
    currentTimestamp,
    index,
    active,
    depositTerms
}: {
    userPosition: ParsedUserVaultStake;
    vaultExpanded: LendingVaultExpanded;
    currentTimestamp: number;
    index: number;
    active: boolean;
    depositTerms: DepositTermSummary;
}): UserVaultPositionExpanded {
    const amountAvailable =
        userPosition.amountStaked -
        (calculateEarlyUnstakeFee({ userPosition, vaultExpanded, currentTimestamp })?.feeLp ?? 0);
    const totalAmount = userPosition.amountStaked;

    const lpPrice = getVaultLpVirtualPrice(vaultExpanded, currentTimestamp);

    const totalValuePrincipal = totalAmount * (lpPrice ?? 0);
    const totalValueUsd = totalValuePrincipal * vaultExpanded.principalOracle.usdPrice;

    const amountAvailablePrincipal = amountAvailable * (lpPrice ?? 0);
    const amountAvailableUsd = amountAvailablePrincipal * vaultExpanded.principalOracle.usdPrice;

    return {
        index,
        address: userPosition.address,
        vaultExpanded,
        amountAvailable: {
            amountLp: amountAvailable,
            amountUsd: amountAvailableUsd,
            amountPrincipal: amountAvailablePrincipal
        },
        totalAmount: {
            amountLp: totalAmount,
            amountUsd: totalValueUsd,
            amountPrincipal: totalValuePrincipal
        },
        active,
        depositTerms
    };
}

export function calculateEarlyUnstakeFee({
    userPosition,
    vaultExpanded,
    currentTimestamp
}: {
    userPosition: ParsedUserVaultStake;
    vaultExpanded: LendingVaultExpanded;
    currentTimestamp: number;
}) {
    if ((userPosition.unstakeTime ?? 0) !== 0) {
        return { feeLp: userPosition.unstakeFeeApplied };
    }

    const maxEarlyUnstakeFee = vaultExpanded.vault.maxEarlyUnstakeFee;
    const timeLeftInStake = Math.max(0, userPosition.endTime - currentTimestamp);
    const totalStakeTime = userPosition.endTime - userPosition.startTime;
    if (totalStakeTime === 0) return { feeLp: 0 };
    const fee = maxEarlyUnstakeFee * (timeLeftInStake / totalStakeTime);
    const feeLp = fee * userPosition.amountStaked;

    return { feeLp };
}

export function useUserVaultPositionByAddress(vaultExpanded: LendingVaultExpanded | undefined) {
    const { data, isLoading, isFetching } = useUserLendingVaultPositionInfo({
        pagination: {
            page: 0,
            pageSize: 1,
            sortType: LendVaultSortType.TotalDeposits,
            sortDirection: SortDirection.Asc,
            status: StakeStatus.Active
        },
        filter: {
            status: StakeStatus.Active,
            vaultAddresses: vaultExpanded ? [vaultExpanded.vault.address] : undefined
        },
        skip: !vaultExpanded
    });

    if (!data?.length || !vaultExpanded) {
        return { data: undefined, isLoading, isFetching };
    }

    const userPosition = data.find((p) => p.vaultExpanded.vault.address === vaultExpanded.vault.address);

    return { data: userPosition, isLoading, isFetching };
}

type UserPositionParams = {
    skip?: boolean;
    pagination: UserLendingVaultFilter;
    filter?: Omit<UserLendingVaultFilter, "page" | "pageSize" | "sortType" | "sortDirection">;
};

// Fetches all user vault positions
export function useUserVaultPositionByVault(vaultExpanded: LendingVaultExpanded | undefined) {
    const { activeWallet } = useActiveWallet();

    const filter: UserLendingVaultFilter = {
        vaultAddresses: vaultExpanded ? [vaultExpanded.vault.address] : undefined,
        sortType: LendVaultSortType.WAvgApy,
        sortDirection: SortDirection.Asc,
        page: 0,
        pageSize: 1,
        status: StakeStatus.Active
    };

    const query = useUserLendingVaultPositionInfo({
        pagination: filter,
        skip: !vaultExpanded || !activeWallet
    });

    return query;
}

export function useUserLendingVaultPositions(pagination: UserLendingVaultFilter, skip?: boolean) {
    const { activeWallet } = useActiveWallet();

    const query = useUserLendingVaultPositionInfo({
        pagination,
        skip: skip || !activeWallet,
        filter: pagination
    });

    if (!activeWallet) return { data: [], isLoading: false, isFetching: false };

    return query;
}

export function useUserLendingVaultPositionInfo({ pagination, skip, filter }: UserPositionParams) {
    const { convertUserVaultStake } = useAbfTypesToUiConverter(filter?.principalMints ?? []);

    const query = useLendingVaultPositionsQuery(
        {
            ...pagination
        },
        {
            refetchOnMountOrArgChange: true,
            skip: skip
        }
    );

    const vaultAddresses = query.data?.positions?.map((p) => p.vaultAddress);

    const {
        data: vaults,
        isLoading: vaultsLoading,
        isFetching: vaultsFetching
    } = useLendVaults({
        skip: skip,
        pagination: pagination,
        filter: { vaultAddresses }
    });

    const {
        data: depositTermsResponse,
        isLoading: depositTermsLoading,
        isFetching: depositTermsFetching
    } = useUserPositionDepositTermsQuery({ vaultAddresses }, { skip: skip });

    const addressToVault = useMemoizedKeyMap(vaults, (v) => v.vault.address);

    const now = getUnixTs();

    const data: UserVaultPositionExpanded[] | undefined = useMemo(() => {
        if (!query.data?.positions || !depositTermsResponse || !vaults) return undefined;

        const positions = query.data.positions
            .map((position, index) => {
                if (!addressToVault?.get(position.vaultAddress) || !depositTermsResponse?.[position.vaultAddress])
                    return null;

                const vaultExpanded = addressToVault.get(position.vaultAddress);
                if (!vaultExpanded) return null;

                const convertedPosition = convertToUserVaultPosition({
                    userPosition: convertUserVaultStake(position, vaultExpanded.vault.principalMint),
                    vaultExpanded,
                    currentTimestamp: now,
                    index,
                    active: pagination.status === StakeStatus.Active,
                    depositTerms: depositTermsResponse[position.vaultAddress]
                });

                return convertedPosition;
            })
            .filter(filterTruthy);
        return positions;
    }, [
        query.data?.positions,
        depositTermsResponse,
        vaults,
        addressToVault,
        convertUserVaultStake,
        now,
        pagination.status
    ]);

    const isLoading = query.isLoading || vaultsLoading || depositTermsLoading;
    const isFetching = query.isFetching || vaultsFetching || depositTermsFetching;

    return {
        data: isLoading ? undefined : data,
        isLoading,
        isFetching,
        totalDataCount: query.data?.total
    };
}

export function useLendingVaultAllocation(vaultAddress: string | undefined, principalMint: string | undefined) {
    const query = useLendingVaultAllocationsQuery(vaultAddress ? { vaultAddress } : skipToken, {
        skip: !vaultAddress || !principalMint
    });

    const collateralMints = query.data?.map((d) => d.collateralMint);
    const mints = combineCollections([collateralMints, [principalMint]]);
    const { getMetadata, isLoading: metadataLoading } = useTokenListMetadataByMints(mints);
    const { convertLendingVaultAllocation } = useAbfTypesToUiConverter(mints);

    const isLoading = query.isLoading || metadataLoading;
    const data = useMemo(() => {
        if (isLoading) return undefined;
        return query.data
            ?.map((rawAllocation): NullableRecord<LendingVaultAllocationExpanded> => {
                const collateralMetadata = getMetadata(rawAllocation.collateralMint);
                const principalMetadata = getMetadata(principalMint);
                const allocation = convertLendingVaultAllocation(rawAllocation, principalMetadata?.decimals ?? 0);
                return {
                    ...allocation,
                    collateralMetadata
                };
            })
            .filter(filterNullableRecord);
    }, [isLoading, query.data, getMetadata, principalMint, convertLendingVaultAllocation]);
    return { data, isLoading };
}

export function getVaultIdleBalance(
    vaultExpanded: LendingVaultExpanded,
    currentTimestamp: number,
    options: { ignoreFees: boolean } = { ignoreFees: false }
) {
    let totalIdleBalance = 0;
    if (vaultExpanded.vaultStrategy.externalYieldInfo) {
        totalIdleBalance += vaultExpanded.vaultStrategy.externalYieldInfo.balance;
    }
    totalIdleBalance += vaultExpanded.vaultStrategy.strategy.tokenBalance;
    if (options.ignoreFees) return totalIdleBalance;
    return Math.max(0, totalIdleBalance - getCurrentVaultFees(vaultExpanded, currentTimestamp));
}

export function getVaultBalanceAvailableToWithdraw(vaultExpanded: LendingVaultExpanded, currentTimestamp: number) {
    return getVaultIdleBalance(vaultExpanded, currentTimestamp, { ignoreFees: true });
}

export function getVaultInterestAccrued(vaultExpanded: LendingVaultExpanded, currentTimestamp: number) {
    return (
        vaultExpanded.vaultStrategy.strategy.interestPerSecond *
        (currentTimestamp - vaultExpanded.vaultStrategy.strategy.lastAccruedTimestamp)
    );
}

export function getCurrentVaultFees(vaultExpanded: LendingVaultExpanded, currentTimestamp: number) {
    const interestAccruedValue = getVaultInterestAccrued(vaultExpanded, currentTimestamp);
    return (
        interestAccruedValue * vaultExpanded.vaultStrategy.strategy.interestFee +
        vaultExpanded.vaultStrategy.strategy.feeClaimable
    );
}

export function getVaultExternalPointRewards(
    vaultExpanded: LendingVaultExpanded | undefined
): ExternalPointReward[] | undefined {
    if (!vaultExpanded) return undefined;
    const externalYieldSource = vaultExpanded.vaultStrategy.strategy.externalYieldSource;

    if (externalYieldSource === 1) {
        return [ExternalPointReward.MarginFi];
    }
    return undefined;
}

export function isRecentVaultDepositOrWithdrawal(mostRecentDepositOrWithdrawalTimestamp: number | undefined): boolean {
    if (!mostRecentDepositOrWithdrawalTimestamp) return false;

    const isRecent = Math.max(getUnixTs() - mostRecentDepositOrWithdrawalTimestamp, 0) < MIN_TIME_FOR_VAULT_PNL_PENDING;

    return isRecent;
}

export const MIN_TIME_FOR_VAULT_PNL_PENDING = TIME.MINUTE * 15;

export function useUserVaultPnlFromPosition(
    userPosition: UserVaultPositionExpanded | undefined
): UserVaultPnl | undefined {
    const { now } = useCurrentTime();
    const vaultAddress = userPosition?.vaultExpanded?.vault.address;

    const depositTerms = vaultAddress ? userPosition?.depositTerms : undefined;

    const currentPnl = calculateVaultPositionPnl(userPosition, now);
    const historicalPnl = calculateVaultPositionHistoricalPnl(userPosition?.vaultExpanded, depositTerms);

    const lifetimePnl = historicalPnl !== undefined ? historicalPnl + (currentPnl ?? 0) : undefined;

    const userVaultPnl: UserVaultPnl = {
        currentPnl,
        lifetimePnl,
        mostRecentDepositOrWithdrawalTimestamp: depositTerms?.mostRecentDepositOrWithdrawalTimestamp
    };

    return userVaultPnl;
}

function calculateVaultPositionPnl(userPosition: UserVaultPositionExpanded | undefined, currentTimestamp: number) {
    const depositTerms = userPosition?.depositTerms;
    const vaultExpanded = userPosition?.vaultExpanded;

    if (!depositTerms?.currentFlows || !userPosition || !vaultExpanded) return undefined;

    const virtualPrice = getVaultLpVirtualPrice(vaultExpanded, currentTimestamp);
    if (!virtualPrice) return undefined;

    const positionValue = virtualPrice * userPosition.totalAmount.amountLp;
    const netFlows = lamportsToUiAmount(
        depositTerms.currentFlows.withdrawals - depositTerms.currentFlows.deposits,
        vaultExpanded.principalMetadata.decimals
    );

    return positionValue + netFlows;
}

function calculateVaultPositionHistoricalPnl(
    vaultExpanded: LendingVaultExpanded | undefined,
    depositTerms: DepositTermSummary | undefined
) {
    if (!depositTerms?.lifetimeCapitalFlows || !vaultExpanded) return undefined;

    return lamportsToUiAmount(
        depositTerms.lifetimeCapitalFlows.withdrawals - depositTerms.lifetimeCapitalFlows.deposits,
        vaultExpanded.principalMetadata.decimals
    );
}

export function useUserGenesisAccess() {
    const { activeWallet } = useActiveWallet();

    const { data: hasGenesisAccess, isLoading } = useUserGenesisAccessQuery(undefined, {
        skip: !activeWallet
    });

    return { hasGenesisAccess, isLoading };
}
