import {
    CreateCustomStrategyTxnParams,
    DepositCollateralParams,
    formatDurationWithTypeShorthand,
    getDurationFromIndex,
    RefinanceLoanTxnParams,
    RepayLoanTxnParams,
    StrategyDuration,
    TokenListMetadata
} from "@bridgesplit/abf-sdk";
import { bpsToPercent, lamportsToUiAmount } from "@bridgesplit/utils";
import { AbfLoanExpanded, CreateLoanTxnParams, formatLeverage, LoopWindParams } from "@bridgesplit/abf-react";
import {
    OrderSource,
    SubmitEarnData,
    SubmitRepayData,
    SubmitTopupData,
    TrackMarketBorrowData,
    TrackMarketLendData,
    TrackRefinanceData
} from "app/types";

export function trackSubmitMarketBorrowOrder({
    params,
    duration,
    source
}: {
    params: CreateLoanTxnParams;
    duration: StrategyDuration;
    source: OrderSource;
}): TrackMarketBorrowData {
    return {
        strategyAddress: params.strategy,
        apy: params.expectedLoanValues.expectedApy,
        ltv: params.expectedLoanValues.expectedLtv,
        collateral: [
            {
                assetMint: params.collateralMint,
                assetIdentifier: params.collateralIdentifier,
                assetType: params.collateralType,
                totalAmount: params.collateralAmount
            }
        ],
        principalMint: params.principalMint,
        principalAmount: params.principalAmount,
        duration: formatDurationWithTypeShorthand(duration.duration, duration.durationType),
        source
    };
}

export function trackSubmitMarketLend(
    params: CreateCustomStrategyTxnParams,
    offerTerms: FormattedOfferTerms
): TrackMarketLendData {
    return {
        offerTerms,
        principalMint: params.principalMint,
        amount: params.amount
    };
}

export function trackSubmitRepay(params: RepayLoanTxnParams, metadata: TokenListMetadata): SubmitRepayData {
    return {
        loanAddress: params.loan,
        amountUi: lamportsToUiAmount(params.repayParams.amount, metadata.decimals),
        amount: params.repayParams.amount,
        ledgerIndex: params.repayParams.ledgerIndex
    };
}

export function trackSubmitRefinance(
    params: RefinanceLoanTxnParams,
    loanExpanded: AbfLoanExpanded,
    ledgerIndex: number
): TrackRefinanceData {
    const ledger = loanExpanded.ledger[ledgerIndex];

    return {
        strategyAddress: params.newStrategy,
        oldStrategyAddress: params.oldStrategy,
        principalMint: params.principalMint,
        collateral: loanExpanded.collateral.map((collateralInfo) => ({
            assetMint: collateralInfo.loanCollateral.assetMint,
            assetIdentifier: collateralInfo.loanCollateral.assetIdentifier,
            assetType: collateralInfo.loanCollateral.assetType,
            totalAmount: collateralInfo.loanCollateral.amount
        })),
        principalAmount: ledger.principalDue - ledger.principalRepaid,
        duration: formatDurationWithTypeShorthand(getDurationFromIndex(params.refinanceParams.durationIndex)),
        apy: bpsToPercent(ledger.apy),
        ltv: ledger.ltvRatios.map((ltv) => bpsToPercent(ltv))
    };
}

export function trackSubmitEarnVault({ params }: { params: LoopWindParams }): SubmitEarnData {
    return {
        loopExpanded: params.loopExpanded,
        vaultIdentifier: params.loopExpanded.vaultIdentifier,
        collateralAmount: params.collateralAmount,
        leverageMultiplier: formatLeverage(params.leverageMultiplier),
        strategyAddress: params.strategyAddress,
        principalRequested: params.principalRequested,
        apy: bpsToPercent(params.expectedLoanValues.expectedApy),
        ltv: bpsToPercent(params.expectedLoanValues.expectedLtv[0]),
        liquidationThreshold: bpsToPercent(params.expectedLoanValues.expectedLiquidationThreshold[0]),
        userPublicKey: params.userPublicKey
    };
}

export interface FormattedOfferTerms {
    collateral: string[];
    terms: {
        [key: string]: number;
    };
}

export function presetFormToMixpanelFormat(
    collateral: string[],
    strategyDurationToApy: Map<string, number | undefined>
): FormattedOfferTerms {
    const terms: { [key: string]: number } = {};

    for (const [duration, apy] of strategyDurationToApy.entries()) {
        if (apy) {
            terms[duration] = apy;
        }
    }

    return {
        collateral,
        terms
    };
}

export function trackSubmitTopup(
    params: DepositCollateralParams,
    metadata: TokenListMetadata,
    ledgerIndex: number
): SubmitTopupData {
    return {
        loanAddress: params.loan,
        collateralMint: params.depositMint,
        amount: lamportsToUiAmount(params.amount, metadata.decimals),
        decimals: metadata.decimals,
        expectedApy: bpsToPercent(params.expectedLoanValues.expectedApy),
        expectedLtv: params.expectedLoanValues.expectedLtv.map(bpsToPercent),
        expectedLiquidationThreshold: params.expectedLoanValues.expectedLiquidationThreshold.map(bpsToPercent),
        ledgerIndex: ledgerIndex
    };
}
