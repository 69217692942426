import { PortfolioTab } from "app/utils";

export const PORTFOLIO_BASE = "/portfolio";

// separate out routing in case portfolio routing is split later
const getPortfolioSuffix = (tab: PortfolioTab) => `${PORTFOLIO_BASE}?open=${tab}`;
export const PORTFOLIO_OVERVIEW_SLUG = PORTFOLIO_BASE;
export const PORTFOLIO_OFFERS_SLUG = getPortfolioSuffix(PortfolioTab.Orders);
export const PORTFOLIO_LENDER_LOANS_SLUG = getPortfolioSuffix(PortfolioTab.Loans);
export const PORTFOLIO_BORROWER_LOANS_SLUG = getPortfolioSuffix(PortfolioTab.Loans);
export const PORTFOLIO_STRATEGIES_SLUG = getPortfolioSuffix(PortfolioTab.Strategies);
export const PORTFOLIO_REQUESTS_SLUG = getPortfolioSuffix(PortfolioTab.Requests);
export const PORTFOLIO_ACTIVITY_SLUG = getPortfolioSuffix(PortfolioTab.Activity);

export const ASSET_SLUG = "/asset";
export const LOAN_SLUG = "/loan";
export const REQUEST_SLUG = "/request";
export const CREATE_BORROW_REQUEST_SLUG = REQUEST_SLUG + "/create";

export const MAKE_OFFER_SLUG = "/make-offer";
export const SYNDICATED_OFFER_SLUG = "/syndicate";

export const CUSTODIANS_SLUG = "/issuers";
export const ALL_CUSTODIANS_SLUG = CUSTODIANS_SLUG + "/all";
export const EXPLORE_REQUESTS_SLUG = "/requests/explore";

export const EXPLORE_COLLATERAL_SLUG = "/collateral";

export const MARKET_SLUG = "/market";
export const BORROW_SLUG = "/borrow";
export const EARN_SLUG = "/earn";
export const LEND_SLUG = "/lend";
export const LOOPS_SLUG = "/loops";
export const MARKETS_SLUG = "/markets";
export const LOOP_DETAIL_SLUG = LOOPS_SLUG;
export const VAULT_MANAGER_SLUG = "/manager";
export const LEND_VAULT_DETAIL_SLUG = "/vault";
export const STRATEGY_LOANS_SLUG = "/position/loans";

export const SIGNUP_SLUG = "/signup";

const ACCOUNTS_BASE = "/account";
export const CUSTODIAN_ADMIN_PERMISSIONS_SLUG = "/permissions";
export const ORGANIZATION_SETTINGS_SLUG = "/organization";
export const ORGANIZATION_CREATE_SLUG = ACCOUNTS_BASE + "/create";
export const ACCOUNT_SETTINGS_SLUG = ACCOUNTS_BASE;
export const PRIME_SLUG = `${ACCOUNT_SETTINGS_SLUG}?open=prime`;
export const ACCOUNT_BANKS_SLUG = ACCOUNT_SETTINGS_SLUG + "?open=banks";
export const ACCOUNT_PERMISSIONS_SLUG = ACCOUNT_SETTINGS_SLUG + "?open=permissions";
export const ACCOUNT_WALLETS_SLUG = ACCOUNT_SETTINGS_SLUG + "?open=wallets";

export const CUSTODIAN_CODE_INVITE_SLUG = "/invite";
export const CUSTODIAN_SPLASH_SLUG = "/join";

export const BANK_TRANSFERS_SLUG = "/bank/transfers";

export const REWARDS_SLUG = "/rewards";
export const WAITLIST_SLUG = REWARDS_SLUG;

export const TWITTER_SLUG = "/twitter";

export const DISCORD_SLUG = "/discord";
