import { TokenListMetadata, ExternalYieldInfo, StrategyDuration, MarketInformationLtvInfo } from "@bridgesplit/abf-sdk";

export interface StrategyExpanded extends StrategyInfoExpanded {
    totalBalance: number; // is amount available to withdraw
    principalMetadata: TokenListMetadata;
    collateral: StrategyCollateralExpanded[];
    principalUsdPrice: number;
}

export interface StrategyLoanStats {
    // active loans
    activeLoanCount: number;
    wAvgApy: number;
    principalDeployed: number;
    interestAccrued: number;
    // total loans
    totalPrincipalOriginated: number;
    totalInterestEarned: number;
    totalLoanCount: number;
}

export interface StrategyCollateralExpanded {
    metadata: TokenListMetadata;
    durationAndApys: Record<string, number>;
    ltvInfo: LtvInfo;
}

export interface StrategyInfo {
    strategy: LendingStrategy; //Correlates with 'Strategy' on the BE, but unable to use similar name due to strategy.ts export
    terms: StrategyTerms;
    externalYieldInfo?: ExternalYieldInfo;
}

export interface StrategyInfoExpanded {
    strategy: LendingStrategyExpanded;
    terms: StrategyTerms;
    externalYieldInfo?: ExternalYieldInfo;
}

export type StrategyTerms = Record<string, [StrategyDuration, number][]>; //Key is mint, value is a tuple of StrategyDuration and apy

export type MintsToLtvInfo = { [mint: string]: LtvInfo };

export interface LtvInfo {
    ltv: number;
    liquidationThreshold: number;
}

export type CollateralWithTerms = {
    metadata: TokenListMetadata;
    //  all LTV infos if viewing across mutliple strateg
} & (
    | { allLtvInfos: MarketInformationLtvInfo[] }
    // single LTV info if strategy already exists
    | { ltvInfo: LtvInfo }
);

export enum OriginationCapStatus {
    Accepting = "Accepting",
    Closed = "Closed"
}

export type MintToDurationAndApys = { [mint: string]: [StrategyDuration, number][] };

export interface LendingStrategyFilter {
    userAddress?: string;
    principalMints?: string[];
    addresses?: string[];
    showArchived?: boolean;
}

export interface LendingStrategy {
    id: number;
    address: string;
    version: number;
    nonce: string;
    bump: number;
    principalMint: string;
    tokenBalance: number;
    lender: string;
    originationsEnabled: boolean;
    externalYieldSource: number;
    interestPerSecond: number;
    lastAccruedTimestamp: number;
    liquidityBuffer: number;
    interestFee: number;
    principalFee: number; //in cbps
    originationFee: number; //in cbps
    originationCap: number; //in lamports
    externalYieldAmount: number;
    currentDeployedAmount: number;
    outstandingInterestAmount: number;
    feeClaimable: number;
    cumulativePrincipalOriginated: number;
    cumulativeInterestAccrued: number;
    cumulativeLoanCount: number;
    activeLoanCount: number;
    marketInformation: string;
    closed: boolean;
    lastInteractedTime: number;
    lastInteractedTxn: string;
    createdAt: number;
}

export interface LendingStrategyExpanded extends LendingStrategy {
    fixedApy: number;
    wAvgApy: number;
}

export const STRATEGY_TEMPLATE_ALL_IDENTIFIER = "all";
export const STRATEGY_TEMPLATE_CUSTOMIZE_IDENTIFIER = "custom";
